import { useReporting } from '../../../../hooks/app/useReporting';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import PaymentReport from './PaymentReport';
import { useNotification } from '../../../../hooks/Notification';
import { ReportingContext } from '../ReportingContext';
import {
  DateRangeFilter,
  FilterValue,
  Widget,
  WidgetChartType,
  ReportFilters,
  Filters,
  ReportFilterDropdown,
} from '@oolio-group/domain';
import { View } from 'react-native';
import { ContainerStyles } from '../styles/Component.styles';
import {
  DateRangeFilterInput,
  HelperText,
  DropDownFilter,
  ReportWidget,
} from '../types';
import { buildFilterObject, setReportStates } from '../reportsHelper';
import * as storage from '../../../../storage/interface';
import { CubejsApi } from '@cubejs-client/core';

const PaymentContainer: React.FC = () => {
  const { showNotification } = useNotification();
  const { cubejsApi, filters: allFilters } = useContext(ReportingContext);
  const [selectedFilters, setSelectedFilters] = useState<FilterValue>({});
  const [reportProps, setReportProps] = useState<ReportWidget>({
    updateCount: 0,
    widgets: [],
  });
  const [dateRangeFilters, setDateRangeFilters] =
    useState<DateRangeFilterInput>();

  const styles = ContainerStyles();

  const { reportMetadata, error, getReportMetadata } = useReporting(cubejsApi);

  const ddFilters = useMemo(() => {
    if (allFilters) {
      return reportMetadata?.filters?.map((filter: ReportFilterDropdown) => {
        return {
          ...filter,
          values: allFilters[filter.key as string as keyof Filters],
        } as DropDownFilter;
      });
    }
  }, [reportMetadata?.filters, allFilters]);

  const { widgets, updateCount } = reportProps;
  const setWidgets = (updatedWidgets: Widget[]) => {
    setReportProps(prev => ({ ...prev, widgets: updatedWidgets }));
  };

  useEffect(() => {
    allFilters &&
      getReportMetadata &&
      getReportMetadata(HelperText.PAYMENT_SUMMARY);
  }, [allFilters, getReportMetadata]);

  useEffect(() => {
    if (reportMetadata?.widgets) {
      setReportStates(
        reportMetadata?.widgets,
        HelperText.PAYMENT_SUMMARY,
        WidgetChartType.TABLE,
        setWidgets,
        setSelectedFilters,
        setDateRangeFilters,
      );
    }
  }, [reportMetadata?.widgets]);

  useEffect((): void => {
    if (error) {
      showNotification({
        error: true,
        message: error,
      });
    }
  }, [error, showNotification]);

  const updateFilters = useCallback((filter: string, value: string[]) => {
    setSelectedFilters(filters => {
      return {
        ...filters,
        [filter]: value,
      };
    });
  }, []);

  const updateDateRangeFilters = useCallback((value: DateRangeFilterInput) => {
    const toSet = buildFilterObject(value);
    setDateRangeFilters(toSet);
  }, []);

  const resetFilters = useCallback(() => {
    setSelectedFilters({});
  }, []);

  const saleChannelOptions = allFilters?.salesChannels.map(({ id, name }) => ({
    value: id,
    label: name,
  }));

  const updateReport = useCallback(() => {
    delete dateRangeFilters?.startDate;
    delete dateRangeFilters?.endDate;
    delete dateRangeFilters?.startTime;
    delete dateRangeFilters?.endTime;

    const reportFilters: ReportFilters = {
      filters: {
        ...selectedFilters,
      },
      dateRangeFilters: [dateRangeFilters as DateRangeFilter],
    };

    storage.setItem(HelperText.PAYMENT_SUMMARY, reportFilters);

    const updatedWidgets = reportProps.widgets.map(widget => {
      return {
        ...widget,
        query: {
          ...widget?.query,
          filters: {
            ...selectedFilters,
          },
          dateRangeFilters: [dateRangeFilters as DateRangeFilter],
        },
      } as Widget;
    });

    setReportProps(prev => ({
      updateCount: prev.updateCount + 1,
      widgets: updatedWidgets,
    }));
  }, [dateRangeFilters, selectedFilters, reportProps.widgets]);

  const loading = widgets && widgets.length > 0 ? false : true;

  return (
    <View style={styles.pageStyle}>
      <PaymentReport
        selectedFilters={selectedFilters}
        filterOptions={ddFilters || ([] as DropDownFilter[])}
        onUpdateFilters={updateFilters}
        resetFilters={resetFilters}
        onPressUpdateReport={updateReport}
        dateRangeFilter={dateRangeFilters || ({} as DateRangeFilterInput)}
        updateDateRangeFilters={updateDateRangeFilters}
        options={{ loading }}
        widgets={widgets}
        cubejsApi={cubejsApi as CubejsApi}
        updateCount={updateCount}
        saleChannelOptions={saleChannelOptions}
        allFilters={allFilters || ({} as Filters)}
      />
    </View>
  );
};

export default PaymentContainer;
