import { StyleSheet } from 'react-native';
import theme from '../../../../common/default-theme';

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    overflow: 'hidden',
  },
  filters: {
    width: 220,
    paddingVertical: 20,
    paddingHorizontal: 10,
    backgroundColor: theme.colors.white,
    ...theme.shadow.light,
  },
  tableContainer: {
    flex: 1,
    padding: 20,
    overflow: 'scroll',
  },
});

export default styles;
