/* eslint-disable react-native/no-inline-styles */
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { ScrollView, View, Text, StyleSheet } from 'react-native';
import { useTranslation } from '@oolio-group/localization';
import Modal from '../Modal';
import TitleBar from '../../TitleBar/TitleBar';
import IconButton from '../../Button/IconButton';
import * as styles from './ItemAvailability.style';
import { useFela } from 'react-fela';
import { useModal } from '@oolio-group/rn-use-modal';
import {
  Page,
  Product,
  UpdateProductAvailabilityInput,
  DEFAULT_ENTITY_ID,
} from '@oolio-group/domain';
import { useSession } from '../../../../src/hooks/app/useSession';
import { keyBy, orderBy, uniqBy, isEmpty } from 'lodash';
import Button from '../../Button/Button';
import { IMap } from '../../../../src/screens/BackOffice/Reports/types';
import {
  inventoryProductFragment,
  useProducts,
} from '../../../../src/hooks/app/products/useProducts';
import TreatPicker from '../../Shared/Select/Picker';
import Search from '../../Shared/Search/Search';
import defaultTheme from '../../../common/default-theme';
import ItemRow from './ItemRow';

export interface ProductInventory {
  id: string;
  name: string;
  isBeingTracked?: boolean;
  availableQuantity: number;
  isAvailable?: boolean;
}

interface ItemAvailabilityProps {
  pages: Page[];
  onSave: (productsInventory: IMap<ProductInventory>) => void;
  productsInventory: IMap<ProductInventory>;
}

const ItemAvailability: React.FC<ItemAvailabilityProps> = ({
  pages,
  productsInventory: productsInventoryProps,
  onSave,
}) => {
  const { translate } = useTranslation();
  const { css } = useFela();
  const { closeModal } = useModal();
  const [session] = useSession();
  const [selectedPageId, setSelectedPageId] = useState('');
  const [searchValue, setSearchValue] = useState('');
  const [productsInventory, setProductsInventory] = useState<
    IMap<ProductInventory>
  >({});

  const updatedProductsOnSave = useRef<
    UpdateProductAvailabilityInput[] | undefined
  >([]);

  const originalProductQuantitiesRef = useRef<Record<string, ProductInventory>>(
    {},
  );

  const currentStoreId = session?.currentStore?.id ?? '';
  const deviceId = session?.device?.id || '';

  const { updateProductsAvailability, syncProductsAvailabilityEvent, loading } =
    useProducts(undefined, inventoryProductFragment);

  const pageMaps = useMemo(() => keyBy(pages, 'id'), [pages]) as IMap<Page>;

  useEffect(() => {
    const updatedProducts = updatedProductsOnSave.current;
    if (!loading && !updatedProducts) {
      closeModal();
      updatedProductsOnSave.current = [];
    } else if (!loading && updatedProducts && updatedProducts.length) {
      closeModal();
      syncProductsAvailabilityEvent({
        products: updatedProducts,
        deviceId,
        storeId: currentStoreId || '',
      });
      updatedProductsOnSave.current = [];
    }
  }, [
    closeModal,
    currentStoreId,
    deviceId,
    loading,
    syncProductsAvailabilityEvent,
  ]);

  const onPressSaveButton = async () => {
    const input: UpdateProductAvailabilityInput[] = Object.values(
      productsInventory,
    ).map(x => {
      const isAvailable =
        x?.availableQuantity === 0 && x?.isBeingTracked
          ? false
          : x?.isAvailable;
      return {
        id: x.id,
        storesInventory: {
          [currentStoreId]: {
            isAvailable,
            availableQuantity: +(x.availableQuantity || 0),
            isBeingTracked: x?.isBeingTracked,
          },
        },
      };
    });
    onSave(productsInventory);
    updateProductsAvailability(input);
    updatedProductsOnSave.current = input;
  };

  const onChangeProductInventory = useCallback(
    (id: string, key: keyof ProductInventory, value: string | boolean) => {
      setProductsInventory(pre => {
        return {
          ...pre,
          [id]: {
            ...pre[id],
            id,
            [key]: value,
            isAvailable:
              key === 'availableQuantity' && value === '0'
                ? false
                : pre?.[id]?.isAvailable,
          },
        };
      });
    },
    [],
  );

  const onChangeAvailability = useCallback(
    (id: string, value: string | boolean) => {
      setProductsInventory(pre => {
        return {
          ...pre,
          [id]: {
            ...pre[id],
            id,
            isAvailable:
              +pre?.[id]?.availableQuantity === 0 && pre?.[id]?.isBeingTracked
                ? false
                : Boolean(value),
          },
        };
      });
    },
    [],
  );

  const sortProductItems = useCallback((items: ProductInventory[]) => {
    return orderBy(items, ['isBeingTracked', 'name'], ['desc', 'asc']);
  }, []);

  const getAllProductInPage = useCallback(
    (page: Page): ProductInventory[] => {
      const { products = [], variants = [] } = page;
      const allProducts = variants
        .map(variant => variant.products)
        .flat()
        .concat(products) as Product[];

      return allProducts.map(({ id, name, storesInventory }) => {
        const isAvailable =
          storesInventory?.[currentStoreId]?.availableQuantity === 0 &&
          storesInventory?.[currentStoreId]?.isBeingTracked
            ? false
            : storesInventory?.[currentStoreId]?.isAvailable;
        return {
          id,
          name,
          isBeingTracked: storesInventory?.[currentStoreId]?.isBeingTracked,
          availableQuantity: +(
            storesInventory?.[currentStoreId]?.availableQuantity || 0
          ),
          isAvailable,
        };
      });
    },
    [currentStoreId],
  );

  const inventoryProducts = useMemo<ProductInventory[]>(() => {
    if (!selectedPageId) return [];
    let formattedProducts: ProductInventory[];
    if (selectedPageId === DEFAULT_ENTITY_ID) {
      formattedProducts = Object.values(pageMaps)
        .map(getAllProductInPage)
        .flat();
    } else {
      formattedProducts = getAllProductInPage(pageMaps[selectedPageId]);
    }
    return sortProductItems(uniqBy(formattedProducts, 'id'));
  }, [getAllProductInPage, pageMaps, selectedPageId, sortProductItems]);

  useEffect(() => {
    const productInventoryMaps = keyBy(inventoryProducts, 'id');
    originalProductQuantitiesRef.current = {
      ...originalProductQuantitiesRef.current,
      ...productInventoryMaps,
    };
  }, [inventoryProducts]);

  const filteredProducts = useMemo(
    () =>
      inventoryProducts.filter(product =>
        product.name.toLowerCase().includes(searchValue.toLowerCase()),
      ),
    [inventoryProducts, searchValue],
  );

  const pageOptions = useMemo(
    () =>
      [
        {
          label: translate('functionMaps.itemAvailability.allPages'),
          value: DEFAULT_ENTITY_ID,
        },
      ].concat(pages.map(p => ({ label: p.name, value: p.id }))),
    [pages, translate],
  );

  useEffect(() => {
    setProductsInventory({
      ...productsInventoryProps,
      ...keyBy(inventoryProducts, 'id'),
    });
  }, [inventoryProducts, productsInventoryProps]);

  useEffect(() => {
    if (
      pageOptions?.length > 0 &&
      !selectedPageId &&
      isEmpty(productsInventory)
    ) {
      const firstPage = pageOptions[0]?.value;
      setSelectedPageId(firstPage);
    }
  }, [
    inventoryProducts,
    pageOptions,
    productsInventory,
    productsInventoryProps,
    selectedPageId,
  ]);

  const hasDataChanged = Object.keys(productsInventory).length > 0;

  const titleLeft = (
    <IconButton
      icon="times"
      containerStyle={css(styles.cancelButtonStyle)}
      onPress={closeModal}
    />
  );

  return (
    <View style={css(styles.modalContainer)}>
      <TitleBar
        testID="item-availability-header"
        primary
        title={translate('functionMaps.itemAvailability.title')}
        titleLeft={titleLeft}
        containerStyle={css(styles.headerStyle)}
      />
      <Modal
        contentStyle={css(styles.modalStyle)}
        customBodyStyle={styles.customBodyStyle}
        showCloseButton={false}
      >
        <View style={{ flex: 1 }}>
          <View style={css(styles.searchView)}>
            <View style={headerStyles.container}>
              <TreatPicker
                testID="select-page"
                selectedValue={selectedPageId}
                options={pageOptions}
                onValueChange={setSelectedPageId}
                containerStyle={headerStyles.dropdown}
              />
              <Search
                testID="search-items"
                onChangeText={setSearchValue}
                placeholder={translate('functionMaps.itemAvailability.search')}
                containerStyle={headerStyles.searchContainer}
              />
            </View>
          </View>
          <View
            style={{
              flexDirection: 'row',
              backgroundColor: defaultTheme.colors.grey1,
              height: 38,
              alignItems: 'center',
              marginBottom: defaultTheme.spacing.s,
            }}
          >
            <View style={{ alignItems: 'center', flex: 0.75 }}>
              <Text style={headerStyles.headerText}>
                {translate('functionMaps.itemAvailability.available')}
              </Text>
            </View>
            <View style={{ marginLeft: defaultTheme.spacing.s, flex: 1 }}>
              <Text style={headerStyles.headerText}>
                {translate('functionMaps.itemAvailability.allItems')}
              </Text>
            </View>
            <View style={{ width: 100 }}>
              <Text style={headerStyles.headerText}>
                {translate('functionMaps.itemAvailability.trackItem')}
              </Text>
            </View>
            <View style={{ width: 140 }}>
              <Text style={headerStyles.headerText}>
                {translate('functionMaps.itemAvailability.quantity')}
              </Text>
            </View>
          </View>
          <ScrollView
            style={css(styles.scrollBodyStyle)}
            showsVerticalScrollIndicator={false}
          >
            {filteredProducts.map(item => {
              const updatedValue = { ...item, ...productsInventory[item.id] };
              return (
                <ItemRow
                  key={item.id}
                  item={updatedValue}
                  onChange={onChangeProductInventory}
                  onChangeAvailability={onChangeAvailability}
                />
              );
            })}
          </ScrollView>
        </View>
        <View>
          <Button
            testID={'save-item-info-btn'}
            secondary={true}
            key={0}
            title={translate('functionMaps.itemAvailability.saveChanges')}
            size="small"
            containerStyle={{
              backgroundColor: hasDataChanged
                ? defaultTheme.colors.green
                : defaultTheme.colors.grey1,
              marginTop: defaultTheme.spacing.s,
            }}
            labelStyle={{
              color: hasDataChanged
                ? defaultTheme.colors.white
                : defaultTheme.colors.grey5,
            }}
            onPress={onPressSaveButton}
            loading={loading}
            disabled={!hasDataChanged}
            fluid
          />
        </View>
      </Modal>
    </View>
  );
};

export default ItemAvailability;

const headerStyles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 20,
  },
  dropdown: {
    width: 140,
  },
  searchContainer: {
    flex: 1,
    marginLeft: 10,
  },
  saveButtonContainer: {
    marginHorizontal: 0,
  },
  headerText: {
    color: defaultTheme.colors.grey5,
  },
});
