import React, { useCallback, useState, useMemo } from 'react';
import { View, Text, ViewStyle } from 'react-native';
import {
  VictoryChart,
  VictoryAxis,
  VictoryBar,
  VictoryVoronoiContainer,
  VictoryTooltip,
  VictoryStack,
  VictoryLegend,
} from 'victory-native';
import { Styles } from '../../styles/Component.styles';
import { IMap } from '../../types';
import {
  CHART_CONTAINER_STYLE,
  CHART_Y_AXIS_STYLE,
  BAR_CHART_STYLE,
  CHART_TOOLTIP_STYLE,
  CHART_LEGEND_STYLE,
  CHART_X_AXIS_STYLE,
  LEGEND_BOTTOM_GAP,
  TOOLTIP_GAP,
} from '../../reportsHelper';
import theme from '../../../../../common/default-theme';

interface StackedBarChartProps {
  chartName: string;
  width: string;
  legends: IMap<string | number>[];
  data: IMap<string | number>[][];
  colorScale: string[];
}

export const StackedBarChart: React.FC<StackedBarChartProps> = ({
  chartName,
  legends,
  data,
  colorScale,
}) => {
  const [boundingRect, setBoundingRect] = useState({ width: 0, height: 0 });
  const { width: vw } = theme.useResponsiveDimensions();
  const graphRef = useCallback(node => {
    if (node !== null) {
      node.getBoundingClientRect &&
        setBoundingRect(node.getBoundingClientRect());
    }
  }, []);

  const styles = Styles();

  const chartData = useMemo(() => {
    return data;
  }, [data]);

  const chartMargin =
    CHART_CONTAINER_STYLE.marginLeft + CHART_CONTAINER_STYLE.marginRight;
  const chartStyle = {
    ...CHART_CONTAINER_STYLE,
    width: vw - chartMargin,
  };

  const legendWidth = legends.length * 35;
  const legendX = boundingRect.width / 2 - legendWidth;
  const legendY = boundingRect.height - LEGEND_BOTTOM_GAP;

  return (
    <View style={chartStyle as ViewStyle} ref={graphRef}>
      <Text style={styles.chartTitleStyle}>{chartName}</Text>
      <VictoryChart
        width={boundingRect.width}
        height={boundingRect.height ? boundingRect.height : 0}
        domainPadding={{ x: 20, y: 50 }}
        containerComponent={
          <VictoryVoronoiContainer
            labels={({ datum }) => {
              if (datum.text)
                return `${datum.x}\n${datum.text}\t${datum.percent || datum.y}`;
              else return '';
            }}
            labelComponent={
              <VictoryTooltip
                dy={TOOLTIP_GAP}
                style={CHART_TOOLTIP_STYLE.style}
                flyoutStyle={CHART_TOOLTIP_STYLE.flyoutStyle}
              />
            }
          />
        }
      >
        <VictoryAxis style={CHART_X_AXIS_STYLE} fixLabelOverlap={true} />
        <VictoryAxis
          dependentAxis
          orientation="left"
          style={CHART_Y_AXIS_STYLE}
          tickFormat={t => {
            if (t < 1e-4) return 0;
            return t >= 1000 ? `${t / 1000}k` : t;
          }}
        />
        <VictoryStack key={`${chartName}-stack`} colorScale={colorScale}>
          {chartData.map((dataArr, stackI) => {
            return (
              <VictoryBar
                barWidth={20}
                key={`${chartName}-bar-${stackI}`}
                data={dataArr}
                style={{ ...BAR_CHART_STYLE, data: {} }}
              />
            );
          })}
        </VictoryStack>
        <VictoryLegend
          style={CHART_LEGEND_STYLE.style}
          x={legendX}
          y={legendY}
          orientation="horizontal"
          symbolSpacer={CHART_LEGEND_STYLE.symbolSpace}
          gutter={CHART_LEGEND_STYLE.legendSpace}
          colorScale={colorScale}
          data={legends}
        />
      </VictoryChart>
    </View>
  );
};
