/* eslint-disable react-native/no-inline-styles */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Linking, Platform, Text, TouchableOpacity, View } from 'react-native';
import { App } from '@oolio-group/domain';
import { useModal } from '@oolio-group/rn-use-modal';
import { useTranslation } from '@oolio-group/localization';
import { useNavigation, useNavigationState } from '@react-navigation/native';
import { capitalCase } from 'change-case';
import { tokenUtility } from '../../../state/tokenUtility';
import { navigateToLockScreen } from '../../../state/navigation';
import { useIntercom } from '../../../hooks/Intercom/useIntercom';
import useOfficeUserAuthorization from '../../../hooks/app/users/useOfficeUserAuthorization';
import { analyticsService } from '../../../analytics/AnalyticsService';
import theme from '../../../common/default-theme';
import styles from './Header.styles';
import Icon from '../../Icon/Icon';
import Logo from '../../Logo/Logo';
import { SetupGuide } from './SetupGuide';
import Gradient from '../../Gradient/Gradient';
import ButtonIcon from '../../Shared/TreatButton/ButtonIcon';
import ReportIssueModal from '../../Shared/Modals/ReportIssue/ReportIssueModal';
import { URL_WHATSNEW } from '../../../constants';

interface RouteState {
  index?: number;
  key: string;
  name?: string;
  params?: {
    [key: string]: string;
  };
  state?: RouteState;
  routeNames?: string[];
  routes?: RouteState[];
}

const initialState: string[] = [];

function getFullRoutePath(route: RouteState): string[] {
  if (route?.state?.routes) {
    const idx = route?.state?.index || 0;
    const subRoute = route?.state?.routes[idx];
    const routeName = route?.state?.routes[idx]?.name || '';
    const screenName = route?.state?.routes[idx]?.params?.screen || '';
    const paths = getFullRoutePath(subRoute as RouteState) || [];
    if (subRoute?.params?.title) {
      return [...paths];
    } else {
      return [routeName, screenName, ...paths] as string[];
    }
  } else {
    const title: string = route?.params?.title || '';
    return [title];
  }
}

const Header: React.FC = () => {
  const Intercom = useIntercom();
  const { showModal } = useModal();
  const navigation = useNavigation();
  const { translate } = useTranslation();
  const { canAccessPOSApp } = useOfficeUserAuthorization();

  const [currentRoute, setCurrentRoute] = useState(initialState);
  const route = useNavigationState(
    state => state.routes[state.routes.length - 1],
  );

  useEffect(() => {
    if (route.state) {
      if (Platform.OS === 'web') {
        Intercom.update();
      }
      const routePaths = getFullRoutePath(route as RouteState);
      const filteredRoutePaths = routePaths.filter(
        (path, idx) => !!path && routePaths.indexOf(path) === idx,
      );

      const santisedRoutePaths = filteredRoutePaths.map(path => {
        let result = '';
        switch (path) {
          case 'VenuesStores':
            result = 'Venues & Stores';
            break;
          case 'TaxesFees':
            result = 'Taxes';
            break;
          case 'ProductTypes':
            result = 'Reporting Groups';
            break;
          case 'VoidsRefunds':
            result = 'Voids & Refunds';
            break;
          case 'OnlineOrderIntegrationsSettings':
            result = 'Online Ordering';
            break;
          case 'OnlineOrderIntegrationStoreSettings':
            result = 'Online Store';
            break;
          case 'OolioReservationIntegrationsSettings':
            result = 'Oolio Reservations';
            break;
          default:
            result = path?.replace?.('Tab', '').replace?.('Beta', '') ?? '';
            break;
        }
        return result;
      });

      const refactorRoutePaths = santisedRoutePaths.map((path, idx) => {
        return idx === 0 ? path : `/ ${path}`;
      });
      setCurrentRoute(refactorRoutePaths);

      const getRouteString = () => {
        let level1Route = '';
        let level2Route = '';
        refactorRoutePaths.map((route, idx) => {
          if (idx === 0) {
            level1Route = route;
          } else {
            level2Route = route;
          }
        });
        return level1Route + level2Route;
      };

      Intercom.logEvent('navigation', { route: getRouteString() });
    }
  }, [route, Intercom]);

  const onPressPOSButton = useCallback(async () => {
    analyticsService.capture('Navigated to POS');
    tokenUtility.setTokenInfo({
      activeApp: App.POS_APP,
    });
    navigation.reset(navigateToLockScreen());
  }, [navigation]);

  const onPressReportIssue = useCallback(() => {
    showModal(<ReportIssueModal />);
    analyticsService.capture('report_bug', {
      state: 'pressed',
    });
  }, [showModal]);

  const onPressHome = useCallback(() => {
    navigation.navigate('Home', { screen: 'Overview' });
  }, [navigation]);

  const onPressWhatsNew = useCallback(() => {
    analyticsService.capture("Pressed What's New");
    if (Platform.OS === 'web') {
      window.open(URL_WHATSNEW, '_blank');
    } else {
      Linking.openURL(URL_WHATSNEW);
    }
  }, []);

  const renderBreadcrumbs = () => {
    return currentRoute.map((route, idx) => {
      if (idx === 0) {
        return (
          <View key={`view-${idx}`}>
            <Text testID="menu-title">{capitalCase(route)}</Text>
          </View>
        );
      } else {
        return (
          <View key={`view-${idx}`}>
            <Text testID={`subMenu-title-${idx}`} style={{ marginLeft: 6 }}>
              {`/ ${capitalCase(route)}`}
            </Text>
          </View>
        );
      }
    });
  };

  const accessPOSApp = useMemo(() => canAccessPOSApp(), [canAccessPOSApp]);

  return (
    <View style={styles.headerContainer}>
      <View style={styles.lhs}>
        <TouchableOpacity testID="app-logo-btn" onPress={onPressHome}>
          <Logo color="primary" width={40} height={40} />
        </TouchableOpacity>
        <View style={styles.breadcrumbContainer}>{renderBreadcrumbs()}</View>
      </View>
      <View style={styles.rhs}>
        <SetupGuide />
        <ButtonIcon
          testID="report-issue"
          icon="bug"
          size={38}
          type="neutralLight"
          onPress={onPressReportIssue}
        />
        <ButtonIcon
          testID="btn-whatsNew"
          icon="megaphone"
          size={38}
          type="neutralLight"
          onPress={onPressWhatsNew}
          containerStyle={styles.btnWhatsNew}
        />
        {accessPOSApp && (
          <TouchableOpacity
            testID="pos-app-btn"
            onPress={onPressPOSButton}
            style={{ width: 100 }}
          >
            <Gradient style={styles.buttonContainer}>
              <Text testID="pos-app-btn-text" style={styles.buttonText}>
                {translate('button.pos')}
              </Text>
              <Icon name="arrow-right" size={20} color={theme.colors.white} />
            </Gradient>
          </TouchableOpacity>
        )}
      </View>
    </View>
  );
};

export default Header;
