import React, { useState, useCallback, useMemo, useEffect } from 'react';
import { View, StyleSheet } from 'react-native';
import {
  User,
  UpdateUserRequest,
  DEFAULT_USER_LANGUAGE,
} from '@oolio-group/domain';
import {
  isValidEmail,
  isValidNumber,
  isValidPinNumber,
} from '../../../../../../utils/validator';
import {
  useTranslation,
  getAvailableLanguages,
  getCountry,
  getCountryCodeByPhoneCode,
} from '@oolio-group/localization';
import { useNotification } from '../../../../../../hooks/Notification';
import { useRoute } from '@react-navigation/native';
import { useUsers } from '../../../../../../hooks/app/useUsers';
import { Operation } from '../../../../../../types/Operation';
import { useNavigation } from '@react-navigation/native';
import ConfirmationDialog from '../../../../../../components/Modals/ConfirmationDialog';
import { useModal } from '@oolio-group/rn-use-modal';
import { getDiffValues } from '@oolio-group/client-utils';
import { stripProperties } from '../../../../../../utils/stripObjectProps';
import { userUtility } from '../../../../../../state/userUtility';
import theme from '../../../../../../common/default-theme';
import Section from '../../../../../../components/Office/Section/Section';
import InputText from '../../../../../../components/Shared/Inputs/InputText';
import InputEmail from '../../../../../../components/Shared/Inputs/InputEmail';
import InputPhone from '../../../../../../components/Shared/Inputs/InputPhone';
import InputPassword from '../../../../../../components/Shared/Inputs/InputPassword';
import InputToggle from '../../../../../../components/Shared/Inputs/InputToggle';
import TreatPicker from '../../../../../../components/Shared/Select/Picker';
import ScreenLayout from '../../../../../../components/Office/ScreenLayout/ScreenLayout';

type UserWithPhoneCountryCode = User & { phoneCountryCode: string };

export const General: React.FC = () => {
  const { translate } = useTranslation();
  const { showNotification } = useNotification();
  const route = useRoute();
  const { showModal, closeModal } = useModal();
  const navigation = useNavigation();
  const [userData, setUserData] = useState<UserWithPhoneCountryCode>(
    {} as UserWithPhoneCountryCode,
  );
  const params = route.params as {
    userId: string;
  };

  const isUKPhone = userData?.phoneCountryCode === 'GB';

  const userId = params.userId || '';

  const {
    users,
    loading,
    error,
    updateUser,
    operation,
    deleteUser,
    deletedUserId,
  } = useUsers();

  useEffect(() => {
    if (error) {
      showNotification({
        error: true,
        message: error as string,
      });
    }
  }, [error, showNotification]);

  useEffect(() => {
    if (userId && users[userId] && !error && !loading) {
      const user = users[userId];
      // mobile no from API is something like this +61 1111333222
      const [_phoneCode, _phoneNumber] = user.phone.split(' ');
      setUserData({
        ...user,
        phoneCountryCode: getCountryCodeByPhoneCode(_phoneCode) || '',
        phone: _phoneNumber || '',
        pin: '',
      } as UserWithPhoneCountryCode);
    }
  }, [userId, users, error, loading]);

  useEffect(() => {
    if (!error && !loading && operation === Operation.UPDATE) {
      showNotification({
        success: true,
        message: translate('backOfficeSettings.successfullySaved'),
      });
    }
  }, [error, loading, operation, showNotification, translate]);

  const onChange = useCallback((prop, value): void => {
    setUserData(form => ({
      ...form,
      [prop]: value,
    }));
  }, []);

  const getFormattedPhone = () => {
    if (userData.phoneCountryCode) {
      return `${getCountry(userData.phoneCountryCode).phone} ${userData.phone}`;
    }
    return userData.phone;
  };

  const formValidationStatus: Record<string, boolean> = {
    firstName: !!userData.firstName,
    email: isValidEmail(userData.email),
    pin: userData.pin ? isValidPinNumber(userData.pin) : true,
    phone: userData.phone ? isValidNumber(getFormattedPhone()) : false,
  };

  const onPressSave = async (): Promise<void> => {
    if (
      Object.keys(formValidationStatus).some(
        _key => formValidationStatus[_key] === false,
      )
    ) {
      showNotification({
        error: true,
        message: 'Please check the values provided',
      });
    } else {
      const updatedValues = getDiffValues<
        Partial<User>,
        | 'preferredLanguage'
        | 'email'
        | 'firstName'
        | 'lastName'
        | 'phone'
        | 'skipPin'
      >(
        [
          'email',
          'firstName',
          'lastName',
          'preferredLanguage',
          'phone',
          'skipPin',
        ],
        users[userId],
        { ...userData, phone: getFormattedPhone() },
        { preferredLanguage: DEFAULT_USER_LANGUAGE },
      );
      if (Object.keys(updatedValues).length > 0 || userData.pin.length > 0) {
        updateUser(
          stripProperties(
            {
              ...updatedValues,
              id: userData.id,
              ...(userData.pin.length > 0 && { pin: userData.pin }),
            },
            '__typename',
          ) as UpdateUserRequest,
        );
        userUtility.updateOfficeUserAttr(userData.id, {
          ...(userData.name && { name: userData.name }),
          email: userData.email,
        });
      }
    }
  };

  useEffect(() => {
    if (!error && !loading && operation === Operation.DELETE && deletedUserId) {
      showNotification({
        success: true,
        message: translate('backOfficeSettings.deletedSuccessfully'),
      });
      closeModal();
      navigation.navigate('ManageUsersTab');
    }
  }, [
    deletedUserId,
    error,
    loading,
    operation,
    showNotification,
    translate,
    navigation,
    closeModal,
  ]);

  const onPressDelete = useCallback((): void => {
    showModal(
      <ConfirmationDialog
        title={translate('dialog.deleteTitle')}
        message={translate('backOfficeUsers.deleteUserMessage')}
        onConfirm={() => userId && deleteUser(userId)}
      />,
    );
  }, [userId, deleteUser, translate, showModal]);

  const localeOptions = useMemo(
    () =>
      getAvailableLanguages().map(locale => ({
        value: locale.languageTag,
        label: locale.label,
      })),
    [],
  );

  return (
    <ScreenLayout
      loading={loading}
      onSave={onPressSave}
      onDelete={onPressDelete}
      onDeleteDisabled={userData.isOwner}
      title={translate('navigation.users', {
        appName: translate('appName'),
      })}
    >
      <Section
        layoutWidth="small"
        title={translate('backofficeManageRoles.roleDetails')}
      >
        <View style={theme.forms.row}>
          <InputText
            testID="firstName"
            title={translate('form.firstName')}
            placeholder={translate('form.firstName')}
            value={userData.firstName || ''}
            onChangeText={onChange.bind(null, 'firstName')}
            containerStyle={theme.forms.inputHalf}
            errorMessage={
              !formValidationStatus.firstName ? 'Enter first name' : undefined
            }
          />
          <InputText
            testID="lastName"
            title={translate('form.lastName')}
            placeholder={translate('form.lastName')}
            value={userData.lastName || ''}
            onChangeText={onChange.bind(null, 'lastName')}
            containerStyle={theme.forms.inputHalf}
          />
        </View>
        <View style={theme.forms.row}>
          <InputEmail
            testID="email"
            title={translate('form.email')}
            placeholder={translate('form.email')}
            value={userData.email || ''}
            onChangeText={onChange.bind(null, 'email')}
            containerStyle={theme.forms.inputHalf}
            errorMessage={
              !formValidationStatus.email ? 'Enter valid email' : undefined
            }
          />
          <InputPhone
            testID="phone-number"
            title={translate('form.phone')}
            placeholder={translate(
              isUKPhone ? 'form.phoneNumberHintUK' : 'form.phoneNumberHint',
            )}
            value={userData.phone || ''}
            onChangeText={onChange.bind(null, 'phone')}
            defaultCountry={userData.phoneCountryCode}
            onPressCountry={(country: string): void =>
              onChange('phoneCountryCode', country)
            }
            containerStyle={theme.forms.inputHalf}
          />
        </View>
        <View style={theme.forms.row}>
          <TreatPicker
            testID="select-language"
            title={translate('form.preferredLanguage')}
            options={localeOptions}
            selectedValue={userData.preferredLanguage}
            onValueChange={onChange.bind(null, 'preferredLanguage')}
            containerStyle={theme.forms.inputHalf}
          />
        </View>
        <View style={theme.forms.row}>
          <InputPassword
            testID="userPin"
            title={translate('form.pin')}
            placeholder={translate('form.pin')}
            value={userData.pin || ''}
            maxLength={6}
            onChangeText={onChange.bind(null, 'pin')}
            containerStyle={theme.forms.inputHalf}
            errorMessage={
              !formValidationStatus.pin ? 'Enter valid pin' : undefined
            }
            // infoText={translate('backOfficeUsers.pinDigitLength')}
          />
          <InputToggle
            type="checkbox"
            testID="toggle-pin-required"
            title={translate('backOfficeUsers.askForPin')}
            isToggled={!userData.skipPin}
            onToggle={onChange.bind(null, 'skipPin', !userData.skipPin)}
            containerStyle={styles.inputPin}
          />
        </View>
      </Section>
    </ScreenLayout>
  );
};

const styles = StyleSheet.create({
  inputPin: {
    width: 260,
    marginTop: 'auto',
  },
});
