import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { View, Text } from 'react-native';
import { DefaultPaymentTypes, Order, OrderStatus } from '@oolio-group/domain';
import {
  useIsFocused,
  useNavigation,
  useRoute,
} from '@react-navigation/native';
import { useModal } from '@oolio-group/rn-use-modal';
import { useCurrency, useTranslation } from '@oolio-group/localization';
import { useLazyQuery, useQuery } from '@apollo/client/react/hooks';
import { orderBy } from 'lodash';
import { GET_ALL_ORDER_TYPES } from '../../../../graphql/orders';
import { usePrinting } from '../../../../hooks/PrintingProvider';
import { useNotification } from '../../../../hooks/Notification';
import { useOrdersHistory } from '../../../../hooks/app/orders/useOrdersHistory';
import { parseApolloError } from '../../../../utils/errorHandlers';
import { mapOrdersToOrdersView } from '../../../../utils/OnAccountHistoryHelper';
import styles from '../Customers.styles';
import ScreenLayout from '../../../../components/POS/ScreenLayout/ScreenLayout';
import Search from '../../../../components/Shared/Search/Search';
import TreatButton from '../../../../components/Shared/TreatButton/TreatButton';
import OnAccountOrdersTable, { OnAccountItem } from './OnAccountOrdersTable';
import SetAmountKeypad from '../../../../components/Modals/SetAmountKeypad/SetAmountKeypad';
import { analyticsService } from '../../../../analytics/AnalyticsService';
import { postSalesObservableForCustomerBalance } from '../../../../hooks/app/usePostSalesNavigation';
import ButtonActions from '../../../../components/Shared/TreatButton/ButtonActions';
import CustomerStatementActionModal from './CustomerStatementAction';
import { SEND_CUSTOMER_STATEMENT } from '../../../../graphql/customer';
import { sumDecimals } from '@oolio-group/order-helper';
import OrderPreview from '../../../../components/POS/SidePanels/OrderPreview';
import { useNetInfo } from '@react-native-community/netinfo';
import { useSession } from '../../../../hooks/app/useSession';

const itemsPerPage = 10;

export enum DateRangeFilter {
  'Today' = 'T',
  'Yesterday' = 'Y',
  'Custom' = 'C',
}

interface OnAccountRouteParams {
  key: string;
  name: string;
  params: {
    name: string;
    email: string;
    customerId: string;
    currentBalance: number;
  };
}

const OnAccountOrders: React.FC = () => {
  const isFocussed = useIsFocused();
  const navigation = useNavigation();
  const [session] = useSession();
  const { translate } = useTranslation();
  const { formatCurrency } = useCurrency();
  const { showModal, closeModal } = useModal();
  const [selectedOrder, setSelectedOrder] = useState<string | null>(null);
  const { showNotification } = useNotification();
  const route = useRoute<OnAccountRouteParams>();
  const netInfo = useNetInfo();
  const isOffline = netInfo?.isConnected === false;

  const [searchText, setSearchFilter] = useState<string>('');
  const [selectedPage, setSelectedPage] = useState<number>(1);

  const {
    loading,
    orders,
    error: ordersDataError,
    getPaginatedOrders,
    getOrderById,
    onFetchMore: onFetchMoreOrders,
  } = useOrdersHistory();

  const { data: orderTypes, error: orderTypesError } =
    useQuery(GET_ALL_ORDER_TYPES);

  const [
    sendCustomerStatement,
    { data: statementRes, loading: statementLoading },
  ] = useLazyQuery(SEND_CUSTOMER_STATEMENT, {
    fetchPolicy: 'no-cache',
  });

  const { printBill, reprintKitchenDocket } = usePrinting();

  useEffect(() => {
    if (isFocussed) {
      getPaginatedOrders({
        status: OrderStatus.ON_ACCOUNT,
        customerId: route.params.customerId,
      });
    }
  }, [getPaginatedOrders, isFocussed, route.params.customerId]);

  useEffect((): void => {
    if (ordersDataError) {
      showNotification({
        error: true,
        message: ordersDataError,
      });
    }
  }, [ordersDataError, showNotification]);

  useEffect(() => {
    if (orderTypesError) {
      showNotification({
        error: true,
        message: parseApolloError(orderTypesError),
      });
    }
  }, [orderTypesError, showNotification]);

  const onPressPrintReceipt = useCallback(
    async (orderId: string, nthPaymentToPrint?: number) => {
      const order = await getOrderById(orderId);
      if (order) {
        const result = await printBill(order, nthPaymentToPrint);
        if (result && Object.keys(result)?.length > 0 && result.error) {
          showNotification(result);
        }
      }
    },
    [getOrderById, printBill, showNotification],
  );

  const onPressRePrintDocket = useCallback(
    async (orderId: string) => {
      const order = await getOrderById(orderId);
      if (order) {
        const result = await reprintKitchenDocket(order, order.orderItems);
        if (result && Object.keys(result)?.length > 0 && result.error) {
          showNotification(result);
        }
      }
    },
    [getOrderById, reprintKitchenDocket, showNotification],
  );

  const navigateToPayment = useCallback(
    (amount: number, customerId: string, orderId?: string) => {
      navigation.navigate('Orders', {
        screen: 'Payment',
        params: {
          customerAmount: amount,
          customerId: customerId,
          orderId: orderId || '',
        },
      });
    },
    [navigation],
  );

  const onUpdateProcessingAmount = useCallback(
    (customerAmount: number, customerId: string, orderId?: string) => {
      const onSubmitAmount = (amount: number) => {
        if (amount === 0) {
          showNotification({
            message: translate('payment.enterAmount'),
            error: true,
          });
        } else if (amount > customerAmount) {
          showNotification({
            message: translate('payment.checkSetAmountOnCustomer'),
            error: true,
          });
        } else {
          postSalesObservableForCustomerBalance.next(false);
          analyticsService.capture('accounts', {
            event: 'balance_paid',
          });
          navigateToPayment(amount, customerId, orderId);
          closeModal();
        }
      };
      return showModal(
        <SetAmountKeypad
          onSubmit={onSubmitAmount}
          remainingDueAmount={customerAmount}
          processingAmount={customerAmount}
          closeModal={closeModal}
        />,
      );
    },
    [closeModal, navigateToPayment, showModal, showNotification, translate],
  );

  const filteredOrders = useMemo(() => {
    return orderBy(orders, 'createdAt', 'asc');
  }, [orders]);

  const filteredData = useMemo((): OnAccountItem[] => {
    return mapOrdersToOrdersView(
      (filteredOrders || []).filter(x =>
        x.orderNumber?.toLowerCase().includes(searchText?.trim().toLowerCase()),
      ),
      orderTypes ? orderTypes.orderTypes : [],
    );
  }, [filteredOrders, orderTypes, searchText]);

  useEffect(() => {
    if (!statementLoading && statementRes?.sendCustomerStatement) {
      showNotification({
        success: true,
        message: translate('onAccount.statementSuccessMessage'),
      });
      closeModal();
    }
  }, [statementRes, statementLoading, showNotification, translate, closeModal]);

  const sendStatement = useCallback(
    (email: string, startDate: Date, endDate: Date) => {
      analyticsService.capture('statement_sent', {
        organization: session.currentOrganization?.id,
        organizationName: session.currentOrganization?.name,
        customer: route.params.customerId,
        email,
        startDate,
        endDate,
      });
      sendCustomerStatement({
        variables: {
          statementFilter: {
            id: route.params.customerId,
            email,
            from: startDate.valueOf(),
            to: endDate.valueOf(),
          },
        },
      });
    },
    [
      sendCustomerStatement,
      route.params.customerId,
      session.currentOrganization?.id,
      session.currentOrganization?.name,
    ],
  );

  const showCustomerActionModal = useCallback(() => {
    showModal(
      <CustomerStatementActionModal
        email={route.params?.email}
        sendStatement={sendStatement}
      />,
    );
  }, [showModal, route.params?.email, sendStatement]);

  const buttonActions = useMemo(() => {
    return [
      {
        action: showCustomerActionModal,
        label: translate('onAccount.sendStatement'),
      },
    ];
  }, [translate, showCustomerActionModal]);

  const onSelectOrderDetail = useCallback((id: string): void => {
    setSelectedOrder(id);
  }, []);

  const onPressPayBalance = useCallback(
    (order: Order) => {
      const onAccountPayment = order.payments.find(
        p => p.paymentType.name === DefaultPaymentTypes.ON_ACCOUNT,
      );
      if (onAccountPayment) {
        const remainingBalance = sumDecimals([
          onAccountPayment?.amount ?? 0,
          -onAccountPayment?.tendered ?? 0,
        ]);
        navigateToPayment(remainingBalance, order.customer.id, order.id);
      }
    },
    [navigateToPayment],
  );

  const onPageChange = useCallback(
    (page: number) => {
      setSelectedPage(page);
      // calculate to fetch more
      const totalPageCount = Math.ceil(orders.length / itemsPerPage);
      if (page >= totalPageCount - 1) {
        onFetchMoreOrders();
      }
    },
    [orders.length, onFetchMoreOrders],
  );

  return (
    <ScreenLayout
      loading={loading}
      title={route.params.name}
      onBack={() => navigation.goBack()}
    >
      <View style={styles.filters}>
        <Search
          testID="search-orders"
          placeholder={translate('onAccount.searchOrderViaOrderNo')}
          onChangeText={setSearchFilter}
          containerStyle={styles.search}
        />
        <TreatButton
          testID="btn-payBalance"
          type="positive"
          label={translate('onAccount.payBalance')}
          onPress={() =>
            onUpdateProcessingAmount(
              route.params.currentBalance,
              route.params.customerId,
            )
          }
        />
        <ButtonActions
          type="neutral"
          label={translate('enums.OTHER')}
          actions={buttonActions}
          // eslint-disable-next-line react-native/no-inline-styles
          containerStyle={{ marginLeft: 10 }}
        />
      </View>
      <OnAccountOrdersTable
        data={filteredData}
        onPressPrintReceipt={onPressPrintReceipt}
        onPressRePrintDocket={onPressRePrintDocket}
        selectedPage={selectedPage}
        onChangeSelectedPage={onPageChange}
        onPressRow={onSelectOrderDetail}
        isOffline={isOffline}
        itemsPerPage={itemsPerPage}
      />
      <View style={styles.info}>
        <View style={styles.infoAmount}>
          <Text>{translate('onAccount.accountBalance')} </Text>
          <Text testID="customer-balance" style={styles.balance}>
            -{formatCurrency(route.params.currentBalance)}
          </Text>
        </View>
      </View>
      <OrderPreview
        order={{ id: selectedOrder } as OnAccountItem}
        onClose={() => setSelectedOrder(null)}
        fromOnAccount
        onPressPayBalance={onPressPayBalance}
      />
    </ScreenLayout>
  );
};
export default OnAccountOrders;
