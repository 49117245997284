import React, { useCallback } from 'react';
import { useTranslation } from '@oolio-group/localization';
import { Text, View, StyleSheet } from 'react-native';
import { useModal } from '@oolio-group/rn-use-modal';
import theme from '../../common/default-theme';
import TreatButton from '../Shared/TreatButton/TreatButton';

export interface ConfirmationModalProps {
  type?: 'positive' | 'neutral' | 'focus' | 'negative';
  title: string;
  message: string;
  onCancel?: () => void;
  onConfirm?: () => void;
  cancelLabel?: string;
  confirmLabel?: string;
  hideCancel?: boolean;
}

const ConfirmationModal: React.FC<ConfirmationModalProps> = ({
  type,
  title,
  message,
  onCancel,
  onConfirm,
  cancelLabel,
  confirmLabel,
  hideCancel = false,
}) => {
  const { closeModal } = useModal();
  const { translate } = useTranslation();

  const onPressConfirm = useCallback(() => {
    onConfirm && onConfirm();
  }, [onConfirm]);

  const onPressCancel = useCallback(() => {
    onCancel && onCancel();
    closeModal();
  }, [closeModal, onCancel]);

  const getTextColor = (type?: string) => {
    switch (type) {
      case 'positive':
        return theme.colors.states.positive;
      case 'neutral':
        return theme.colors.states.neutral;
      case 'focus':
        return theme.colors.states.focus;
      default:
        return theme.colors.states.negative;
    }
  };

  return (
    <View style={styles.container}>
      <Text style={[styles.title, { color: getTextColor(type || 'negative') }]}>
        {title}
      </Text>
      <Text style={styles.message}>{message}</Text>
      <View style={styles.actions}>
        {!hideCancel && (
          <TreatButton
            testID="confirm-no"
            type="cancel"
            onPress={onPressCancel}
            label={cancelLabel || translate('button.dismiss')}
          />
        )}
        <TreatButton
          testID="confirm-yes"
          type={type || 'negative'}
          onPress={onPressConfirm}
          label={confirmLabel || translate('settings.confirm')}
          containerStyle={styles.btnConfirm}
        />
      </View>
    </View>
  );
};

export default ConfirmationModal;

const styles = StyleSheet.create({
  container: {
    alignSelf: 'center',
    paddingVertical: 30,
    paddingHorizontal: 40,
    width: theme.layoutWidth.s,
    borderRadius: theme.radius.m,
    backgroundColor: theme.colors.light,
  },
  title: {
    fontSize: 16,
    lineHeight: 16,
    fontFamily: theme.fonts.medium,
    marginTop: 10,
    marginBottom: 20,
  },
  message: {
    fontSize: 14,
    lineHeight: 20,
  },
  actions: {
    marginTop: 40,
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  btnConfirm: {
    marginLeft: 10,
  },
});
