import { StyleSheet } from 'react-native';
import theme from '../../../common/default-theme';

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    overflow: 'hidden',
  },
  cartItems: {
    padding: 10,
    marginTop: 4,
    borderRadius: theme.radius.s,
    backgroundColor: theme.colors.white,
  },
  gap: {
    marginTop: 4,
  },
  order: {
    width: 320,
    padding: 10,
    backgroundColor: theme.colors.grey2,
    ...theme.shadow.light,
  },
  orderTitle: {
    height: 44,
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 4,
    paddingHorizontal: 8,
    borderRadius: theme.radius.s,
    backgroundColor: theme.colors.white,
  },
  orderTitleText: {
    fontSize: 14,
    lineHeight: 20,
    color: theme.colors.dark,
    fontFamily: theme.fonts.medium,
  },
  totals: {
    marginTop: 4,
  },
  totalRemaining: {
    padding: 16,
    marginTop: 4,
    justifyContent: 'center',
    borderRadius: theme.radius.s,
    backgroundColor: theme.colors.white,
  },
  content: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  btnLoyalty: {
    flex: 1,
    marginLeft: 10,
  },
});

export default styles;
