import React, { useCallback, useMemo } from 'react';
import {
  DateRangeFilter,
  DateRangeFilterPresets,
  DateRangeGranularity,
  FilterObject,
  Filters,
  FilterValue,
  PaymentSummarySegment,
  DEFAULT_ENTITY_ID,
} from '@oolio-group/domain';
import { TouchableOpacity, View, Text } from 'react-native';
import { DateRangeFilters } from '../UIComponents/DateRangeFilters';
import IconButton from '../../../../components/Button/IconButton';
import Button from '../../../../components/Button/Button';
import { useTranslation } from '@oolio-group/localization';
import { ExportType, ReportTableColumn, ReportType } from '../types';
import Icon from '../../../../components/Icon/Icon';
import MultipleDropDown, {
  MultipleSelectOption,
} from '../../../../components/MultipleSelect/MultipleSelect';
import DropDown, { Option } from '../../../../components/Shared/Select/Select';
import { DateRangeFiltersStyles, Styles } from './styles';
import { useFela } from 'react-fela';
import { TRADING_PERIOD_KEY } from '../reportsHelper';

export interface PaymentHeaderProps {
  dateRangeFilter: DateRangeFilter;
  toggleFilters: () => void;
  columns: {
    all: ReportTableColumn[];
    updateColumns: (columnKeys: string[]) => void;
    selectedColumns: ReportTableColumn[];
  };
  onPressUpdateReport: () => void;
  exportReport: (type: ExportType) => void;
  updateDateRangeFilters: (value: DateRangeFilter) => void;
  onPressPayoutInfo: () => void;
  reportType: ReportType;
  selectedFilters: FilterValue;
  saleChannelOptions?: Option[];
  onUpdateFilters: (filter: string, value: string[]) => void;
  allFilters: Filters;
}

const PaymentHeader: React.FC<PaymentHeaderProps> = ({
  dateRangeFilter,
  onPressUpdateReport,
  exportReport,
  columns,
  toggleFilters,
  updateDateRangeFilters,
  onPressPayoutInfo,
  reportType,
  allFilters,
}) => {
  const { translate } = useTranslation();
  const styles = Styles();
  const { theme } = useFela();
  const dateRangeFilterStyles = DateRangeFiltersStyles();

  const tradingPeriods: FilterObject[] =
    allFilters[TRADING_PERIOD_KEY as keyof Filters];

  const paymentReportGroupByOptions = [
    {
      value: DEFAULT_ENTITY_ID,
      label: translate('backOfficePayments.PaymentMethods'),
    },
    {
      value: DateRangeGranularity.DAY,
      label: translate('backOfficeReportSettings.day'),
    },
    {
      value: DateRangeGranularity.WEEK,
      label: translate('backOfficeReportSettings.week'),
    },
    {
      value: DateRangeGranularity.MONTH,
      label: translate('backOfficeReportSettings.month'),
    },
  ] as Option[];

  const granularityValue = dateRangeFilter?.granularity || DEFAULT_ENTITY_ID;

  const onChangeGroupByValue = useCallback(
    (granularityValue: string) => {
      let dateRangeValue;
      switch (granularityValue as DateRangeGranularity) {
        case DateRangeGranularity.DAY:
          dateRangeValue = DateRangeFilterPresets.TODAY;
          break;
        case DateRangeGranularity.WEEK:
          dateRangeValue = DateRangeFilterPresets.THIS_WEEK;
          break;
        case DateRangeGranularity.MONTH:
          dateRangeValue = DateRangeFilterPresets.THIS_MONTH;
          break;
        default:
          dateRangeValue = DateRangeFilterPresets.TODAY;
          break;
      }
      updateDateRangeFilters({
        ...dateRangeFilter,
        value: dateRangeValue,
        granularity: (granularityValue === DEFAULT_ENTITY_ID
          ? ''
          : granularityValue) as DateRangeGranularity,
      });
    },
    [dateRangeFilter, updateDateRangeFilters],
  );

  const renderColumnItem = useCallback(
    (
      item: MultipleSelectOption,
      isSelected: boolean,
      onChange: (status: boolean, value: string) => void,
    ) => {
      return (
        <Button
          title={item.label}
          labelStyle={styles.columnlabelStyle}
          fluid
          key={item.value}
          onPress={() => onChange(!isSelected, item.value)}
          iconPosition={'left'}
          containerStyle={styles.columnOptionContainerStyle}
          iconContainerStyle={styles.visibleColumnIconContainerStyle}
          icon={isSelected ? 'eye' : 'eye-slash'}
          iconProps={{
            color: theme.colors.success,
            size: 24,
          }}
        />
      );
    },
    [styles, theme],
  );

  const columnOptions = useMemo(
    () =>
      columns.all
        .filter(
          column =>
            column.dataKey !== PaymentSummarySegment.PAYMENT_METHOD_NAME,
        )
        .map(col => ({ label: translate(col.title), value: col.title })),
    [columns.all, translate],
  );

  return (
    <View>
      <View>
        <TouchableOpacity
          style={styles.schedulePayOutWrapper}
          onPress={onPressPayoutInfo}
          testID="payout-schedule-info"
        >
          <View style={styles.schedulePayOutInfo}>
            <IconButton
              icon="info-circle"
              containerStyle={styles.infoIconStyleContainer}
              iconStyle={styles.whiteColorStyle}
              containerSize={38}
              iconSize={25}
            ></IconButton>
            <Text style={styles.whiteColorStyle}>
              {translate('backOfficePayments.paymentSummaryDescription')}
            </Text>
          </View>
          <IconButton
            icon="arrow-right"
            containerStyle={styles.infoIconStyleContainer}
            iconStyle={styles.whiteColorStyle}
            containerSize={38}
            iconSize={25}
          ></IconButton>
        </TouchableOpacity>
      </View>
      <View style={styles.headerWrapper}>
        <View>
          <DropDown
            testID="group-by-selection"
            options={paymentReportGroupByOptions}
            selectedValue={granularityValue}
            containerStyle={styles.groupByDropdownStyle}
            prefixLabel={translate('backOfficeReportSettings.groupBy')}
            onValueChange={onChangeGroupByValue}
          />
        </View>
        <View style={styles.dateSlectionStyle}>
          <DateRangeFilters
            dateRangeFilter={dateRangeFilter}
            updateDateRangeFilters={updateDateRangeFilters}
            reportType={reportType}
            tradingPeriods={tradingPeriods}
            overrideStyles={dateRangeFilterStyles}
          />
        </View>
        <View style={styles.actionButtonsWrapper}>
          <View style={styles.iconStyle}>
            <IconButton
              icon="sliders-v"
              onPress={toggleFilters}
              primary
              containerStyle={styles.filtersButtonStyle}
              iconStyle={styles.filtersIconStyle}
              containerSize={38}
              iconSize={25}
              testID="toggle-fillter"
            ></IconButton>
          </View>
          <MultipleDropDown
            containerStyle={styles.iconStyle}
            showSearch={false}
            values={columnOptions}
            selectedValues={columns.selectedColumns?.map(col => col.title)}
            onValueChange={columns.updateColumns}
            collapsedView={
              <View style={styles.columnsButtonStyle}>
                <Icon
                  size={25}
                  name="table"
                  primary
                  style={styles.columnsIconStyle}
                  disabled={false}
                />
              </View>
            }
            toggleIcon={<></>}
            itemRender={renderColumnItem}
          />
          <View style={styles.iconStyle}>
            <IconButton
              icon="cloud-download"
              primary
              containerStyle={styles.filtersButtonStyle}
              iconStyle={styles.filtersIconStyle}
              containerSize={38}
              iconSize={25}
              testID="download-report"
              onPress={() => exportReport(ExportType.CSV)}
            ></IconButton>
          </View>
          <View style={styles.updateContainerStyle}>
            <Button
              testID="update-report"
              fluid
              title={translate('backOfficePayments.UpdateTable')}
              containerStyle={styles.updateButtonStyle}
              labelStyle={styles.titleStyle}
              onPress={onPressUpdateReport}
            />
          </View>
        </View>
      </View>
    </View>
  );
};

export default PaymentHeader;
