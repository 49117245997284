import React, { useMemo } from 'react';
import { View, Text } from 'react-native';
import {
  PRODUCT_UNITS,
  UnitType,
  LOCALE,
  ImageUploadInput,
  GeneralProductData,
  ProductAllergens,
  NutrientName,
  NutrientUnit,
  NutrientInfo,
  AllergensKey,
  NutrientNameKey,
  NutrientUnitKey,
  FeatureIDs,
} from '@oolio-group/domain';
import { useTranslation } from '@oolio-group/localization';
import { AlternateNamesSection } from './AlternateNamesSection';
import { getAvatarName } from '../../../../../utils/getAvatarName';
import theme from '../../../../../common/default-theme';
import ScreenLayout from '../../../../../components/Office/ScreenLayout/ScreenLayout';
import Section from '../../../../../components/Office/Section/Section';
import Message from '../../../../../components/Office/Message/Message';
import ImagePicker from '../../../../../components/Office/ImagePicker/ImagePicker';
import InputText from '../../../../../components/Shared/Inputs/InputText';
import InputToggle from '../../../../../components/Shared/Inputs/InputToggle';
import SelectMultiple from '../../../../../components/Shared/Select/SelectMultiple';
import TreatPicker from '../../../../../components/Shared/Select/Picker';
import SelectColour from '../../../../../components/Shared/Select/SelectColour';
import { styles } from './GeneralForm.styles';
import Select from '../../../../../components/Shared/Select/Select';
import InputDropdown from '../../../../../components/Shared/Inputs/InputDropdown';
import { formatToDigitsStr } from '@oolio-group/client-utils';
import ButtonIcon from '../../../../../components/Shared/TreatButton/ButtonIcon';
import { useCheckFeatureEnabled } from '../../../../../hooks/app/features/useCheckFeatureEnabled';

const unitsValues: { [key: string]: { label: string; value: string }[] } = {
  Volume: PRODUCT_UNITS[UnitType.Volume].map(unit => ({
    label: unit,
    value: unit,
  })),
  Weight: PRODUCT_UNITS[UnitType.Weight].map(unit => ({
    label: unit,
    value: unit,
  })),
};

interface GeneralFormProps {
  isProduct: boolean;
  isVariantProduct?: boolean;
  productData: GeneralProductData;
  dietaryTagsOptions: { label: string; value: string }[];
  productTypeOptions: { label: string; value: string }[];
  categoryOptions: { label: string; value: string }[];
  printerProfileOptions: { label: string; value: string }[];
  onPressNutrientAction: (action: 'add' | 'remove', index: number) => void;
  onSave: () => void;
  onAddTag: (name: string) => void;
  onChangeMeasures: (prop: string, value: string) => void;
  onChangeAlternateName: (prop: string, value: string, locale: LOCALE) => void;
  onChangeNutrient: (
    key: 'name' | 'unit' | 'value',
    value: NutrientNameKey | NutrientUnitKey | string,
    index: number,
  ) => void;
  onChange: (
    prop: string,
    value: string | boolean | string[] | ImageUploadInput,
  ) => void;
}

export const GeneralForm: React.FC<GeneralFormProps> = ({
  isProduct,
  productData,
  isVariantProduct,
  dietaryTagsOptions,
  productTypeOptions,
  categoryOptions,
  printerProfileOptions,
  onSave,
  onAddTag,
  onChange,
  onChangeMeasures,
  onChangeAlternateName,
  onChangeNutrient,
  onPressNutrientAction,
}: GeneralFormProps) => {
  const { translate } = useTranslation();
  const isFeatureEnabled = useCheckFeatureEnabled();
  const isAllergensEnabled = isFeatureEnabled(FeatureIDs.ALLERGENS);

  const defaultAlternateNames = productData.alternateNames?.find(
    altName => altName.locale === LOCALE.ENGLISH_US,
  );

  const allergenAttributes = useMemo(() => {
    const allergenOptions = Object.keys(ProductAllergens).map(key => ({
      label: ProductAllergens[key as AllergensKey],
      value: key,
    }));

    const nutrientNameOptions = Object.keys(NutrientName).map(key => ({
      label: NutrientName[key as NutrientNameKey],
      value: key,
    }));

    const nutrientUnitOptions = Object.keys(NutrientUnit).map(key => ({
      label: NutrientUnit[key as NutrientUnitKey],
      value: key,
    }));
    return { allergenOptions, nutrientNameOptions, nutrientUnitOptions };
  }, []);

  const getFilteredNutrientUnitOptions = (nutrientName: NutrientNameKey) => {
    let nutrientUnitOptions = allergenAttributes.nutrientUnitOptions;
    if (nutrientName === 'ENERGY') {
      nutrientUnitOptions = nutrientUnitOptions.filter(option =>
        [NutrientUnit.KCAL, NutrientUnit.KJ, NutrientUnit.CAL].includes(
          option.label,
        ),
      );
    } else {
      nutrientUnitOptions = nutrientUnitOptions.filter(
        option => option.label === NutrientUnit.G,
      );
    }
    return nutrientUnitOptions;
  };

  const verifyAndChangeNutrientUnit = (
    value: NutrientNameKey,
    nutrient: NutrientInfo,
    index: number,
  ) => {
    if (value === 'ENERGY' && nutrient.unit === 'G') {
      onChangeNutrient('unit', 'KCAL' as NutrientUnitKey, index);
    } else if (
      value !== 'ENERGY' &&
      ['KCAL', 'KJ', 'CAL'].includes(nutrient.unit)
    ) {
      onChangeNutrient('unit', 'G' as NutrientUnitKey, index);
    }
  };

  const onNutrientNameChange = (
    value: NutrientNameKey | NutrientUnitKey | string,
    nutrient: NutrientInfo,
    index: number,
  ) => {
    onChangeNutrient('name', value, index);
    verifyAndChangeNutrientUnit(value as NutrientNameKey, nutrient, index);
  };

  return (
    <ScreenLayout
      title={`${productData.name || 'Product'} | Oolio`}
      onSave={onSave}
    >
      <Section>
        <View>
          <View style={styles.imageContainer}>
            <ImagePicker
              imageUrl={productData?.imageUrl}
              initials={getAvatarName(productData?.name || '')}
              onComplete={imageRawData =>
                onChange('imageRawData', imageRawData)
              }
            />
          </View>
          <Message
            type="neutral"
            message={translate('avatar.imageSizeDescription', {
              size: '256x256',
            })}
            containerStyle={styles.messageContainer}
          />
        </View>
        <View style={theme.forms.row}>
          <InputText
            testID="input-name"
            title={translate('productSettings.name')}
            value={productData.name || ''}
            placeholder={translate('productSettings.name')}
            onChangeText={value => {
              onChange('name', value);
              onChangeAlternateName('displayName', value, LOCALE.ENGLISH_US);
            }}
            containerStyle={theme.forms.inputHalf}
          />
          <InputText
            testID="input-namePOS"
            title={translate('productSettings.posName')}
            value={defaultAlternateNames?.receipt || ''}
            placeholder={translate('productSettings.posName')}
            onChangeText={value =>
              onChangeAlternateName('receipt', value, LOCALE.ENGLISH_US)
            }
            containerStyle={theme.forms.inputHalf}
          />
        </View>
        <View style={theme.forms.row}>
          <InputText
            testID="input-nameKitchen"
            title={translate('productSettings.kitchenName')}
            value={defaultAlternateNames?.kitchen || ''}
            placeholder={translate('productSettings.kitchenName')}
            onChangeText={value =>
              onChangeAlternateName('kitchen', value, LOCALE.ENGLISH_US)
            }
            containerStyle={theme.forms.inputHalf}
          />
          <SelectMultiple
            testID="select-profile"
            title={translate('productSettings.printerProfile')}
            options={printerProfileOptions}
            selectedValues={productData?.printerProfiles || []}
            onValueChange={value => onChange('printerProfiles', value)}
            containerStyle={theme.forms.inputHalf}
          />
        </View>
        {!isVariantProduct ? (
          <>
            <View style={theme.forms.row}>
              <TreatPicker
                testID="select-reportingGroup"
                title={translate('productSettings.productType')}
                options={[
                  {
                    label: translate(
                      'backOfficeProducts.selectReportingGroupPlaceholder',
                    ),
                    value: '',
                  },
                ].concat(productTypeOptions)}
                selectedValue={productData.productType?.id}
                onValueChange={value => onChange('productType', value)}
                containerStyle={theme.forms.inputHalf}
                editable={!isVariantProduct}
              />
              <TreatPicker
                testID="select-category"
                title="Category"
                options={[
                  {
                    label: translate(
                      'backOfficeProducts.selectCategoryPlaceholder',
                    ),
                    value: '',
                  },
                ].concat(categoryOptions)}
                selectedValue={productData?.category || ''}
                onValueChange={value => onChange('category', value)}
                containerStyle={theme.forms.inputHalf}
                editable={!isVariantProduct}
              />
            </View>
            <View style={theme.forms.row}>
              <SelectColour
                testID="select-colour"
                title={translate('productSettings.productColor')}
                value={productData.color || theme.colors.deepPurple}
                onChange={onChange.bind(null, 'color')}
                containerStyle={theme.forms.inputHalf}
              />
            </View>
          </>
        ) : (
          <></>
        )}
        {isProduct ? (
          <View style={theme.forms.row}>
            <TreatPicker
              testID="select-measure"
              title={translate('productSettings.measuredBy')}
              options={Object.keys(UnitType).map(eachUnit => ({
                label: eachUnit,
                value: eachUnit,
              }))}
              selectedValue={productData.measuredBy?.unitType || UnitType.Units}
              onValueChange={onChangeMeasures.bind(null, 'unitType')}
              containerStyle={theme.forms.inputHalf}
            />
            {productData.measuredBy?.unitType &&
              productData.measuredBy?.unitType !== UnitType.Units && (
                <>
                  <InputText
                    testID="input-size"
                    title={translate('productSettings.defaultSize')}
                    value={
                      productData.measuredBy?.defaultSize
                        ? productData.measuredBy?.defaultSize.toString()
                        : undefined
                    }
                    placeholder={translate('productSettings.defaultSize')}
                    onChangeText={onChangeMeasures.bind(null, 'defaultSize')}
                    maxLength={9}
                    alignText="center"
                    defaultValue={'1'}
                    keyboardType="number-pad"
                    containerStyle={theme.forms.inputThird}
                  />
                  <TreatPicker
                    testID="select-unit"
                    title={translate('productSettings.units')}
                    options={
                      unitsValues[
                        productData.measuredBy?.unitType || UnitType.Volume
                      ]
                    }
                    selectedValue={
                      productData.measuredBy?.units ||
                      unitsValues[
                        productData.measuredBy?.unitType || UnitType.Volume
                      ][0]['value']
                    }
                    onValueChange={onChangeMeasures.bind(null, 'units')}
                    containerStyle={theme.forms.inputThird}
                  />
                </>
              )}
          </View>
        ) : (
          <></>
        )}
      </Section>
      <Section title="Attributes">
        <View style={theme.forms.row}>
          {isProduct && (
            <InputToggle
              testID="toggle-selling"
              isToggled={productData.isSellable}
              title={translate('productSettings.sellProduct')}
              onToggle={onChange.bind(
                null,
                'isSellable',
                !productData.isSellable,
              )}
              containerStyle={theme.forms.inputHalf}
            />
          )}
          {!isVariantProduct && (
            <InputToggle
              testID="toggle-featured"
              isToggled={productData.isFeatured}
              title={translate('productSettings.featuredProduct')}
              onToggle={onChange.bind(
                null,
                'isFeatured',
                !productData.isFeatured,
              )}
              containerStyle={theme.forms.inputHalf}
            />
          )}
        </View>
        <View style={theme.forms.row}>
          {productData.measuredBy?.unitType &&
            productData.measuredBy?.unitType !== UnitType.Units &&
            isProduct && (
              <InputToggle
                testID="toggle-variable-quantity"
                title={translate('productSettings.variableQuantity')}
                isToggled={!!productData.variableQuantity}
                onToggle={onChange.bind(
                  null,
                  'variableQuantity',
                  !productData.variableQuantity,
                )}
                containerStyle={theme.forms.inputHalf}
              />
            )}
        </View>
        <View style={theme.forms.row}>
          <SelectMultiple
            testID="select-tags"
            title={translate('productSettings.dietaryTags')}
            selectedValues={(productData.dietaryTags || []).map(t => t.id)}
            onAddItem={onAddTag}
            options={dietaryTagsOptions || []}
            onValueChange={values => onChange('dietaryTags', values)}
            containerStyle={theme.forms.inputFluid}
          />
        </View>
        {isAllergensEnabled ? (
          <View style={theme.forms.row}>
            <SelectMultiple
              testID="select-allergens"
              title={translate('productSettings.allergenLabels')}
              selectedValues={productData.allergens ?? []}
              options={allergenAttributes.allergenOptions}
              onValueChange={values => onChange('allergens', values)}
              containerStyle={theme.forms.inputFluid}
            />
          </View>
        ) : (
          <></>
        )}
        <View style={theme.forms.row}>
          <InputText
            testID="productDescription"
            multiline
            title={translate('productSettings.description')}
            value={productData.description || ''}
            placeholder={translate('productSettings.addDescription')}
            maxLength={300}
            onChangeText={value => {
              onChange('description', value);
              onChangeAlternateName('description', value, LOCALE.ENGLISH_US);
            }}
            containerStyle={theme.forms.inputFluid}
          />
        </View>
        <View style={theme.forms.row}>
          <InputText
            testID="productRecipe"
            multiline
            title={translate('productSettings.recipe')}
            value={productData.recipe || ''}
            placeholder={translate('productSettings.addRecipe')}
            maxLength={500}
            onChangeText={value => {
              onChange('recipe', value);
            }}
            containerStyle={theme.forms.inputFluid}
          />
        </View>
      </Section>

      <Section title={translate('backOfficeSettings.nutrientValues')}>
        <View style={styles.tableContainer}>
          <View style={theme.tables.header}>
            <Text style={[theme.tables.headerText, styles.nutrient]}>
              {translate('productSettings.nutrient')}
            </Text>
            <Text style={[theme.tables.headerText, styles.avgQtyServe]}>
              {translate('productSettings.avgQtyServe')}
            </Text>
          </View>
        </View>
        <>
          {productData.nutrientInfo?.map((nutrient, index) => {
            return (
              <View style={theme.tables.row} key={nutrient.name + index}>
                <Select
                  testID="select-nutrient"
                  options={allergenAttributes.nutrientNameOptions}
                  selectedValue={nutrient.name}
                  onValueChange={value =>
                    onNutrientNameChange(value, nutrient, index)
                  }
                  isCreate={false}
                  onAddOption={() => ({})}
                  containerStyle={styles.nutrient}
                />
                <InputDropdown
                  testID="nutrient-value-with-unit"
                  placeholder="0"
                  value={String(nutrient.value)}
                  selectedOption={nutrient.unit}
                  options={getFilteredNutrientUnitOptions(nutrient.name)}
                  onChangeText={value =>
                    onChangeNutrient('value', formatToDigitsStr(value), index)
                  }
                  onOptionChange={value =>
                    onChangeNutrient('unit', value as NutrientUnitKey, index)
                  }
                  direction="rtl"
                  alignText="left"
                  extraWidth={90}
                  // eslint-disable-next-line react-native/no-inline-styles
                  containerStyle={{ width: 200 }}
                />
                <ButtonIcon
                  testID={'btn-nutrient-action-remove' + index}
                  icon={'trash-alt'}
                  size={34}
                  type={'negativeLight'}
                  containerStyle={styles.btnRemove}
                  onPress={() => onPressNutrientAction('remove', index)}
                />
              </View>
            );
          })}
        </>
        <ButtonIcon
          testID="btn-nutrient-action-add"
          icon={'plus'}
          size={34}
          type={'positive'}
          containerStyle={styles.btnAdd}
          onPress={() =>
            onPressNutrientAction(
              'add',
              (productData.nutrientInfo?.length ?? 0) + 1,
            )
          }
        />
      </Section>

      <Section title={translate('backOfficeSettings.productIdentifiers')}>
        <View style={theme.forms.row}>
          <InputText
            testID="input-barcode"
            title={translate('productSettings.barcode')}
            value={productData.barcode || ''}
            placeholder={translate('productSettings.barcode')}
            onChangeText={onChange.bind(null, 'barcode')}
            containerStyle={theme.forms.inputHalf}
          />
          <InputText
            testID="input-sku"
            title={translate('productSettings.sku')}
            value={productData.sku || ''}
            placeholder={translate('productSettings.sku')}
            onChangeText={onChange.bind(null, 'sku')}
            containerStyle={theme.forms.inputHalf}
          />
        </View>
        <View style={theme.forms.row}>
          <InputText
            testID="input-plu"
            title={translate('productSettings.plu')}
            value={productData.plu || ''}
            placeholder={translate('productSettings.plu')}
            onChangeText={onChange.bind(null, 'plu')}
            containerStyle={theme.forms.inputHalf}
          />
          <InputText
            testID="input-gtin"
            title={translate('productSettings.gtin')}
            value={productData.gtin || ''}
            placeholder={translate('productSettings.gtin')}
            onChangeText={onChange.bind(null, 'gtin')}
            containerStyle={theme.forms.inputHalf}
          />
        </View>
      </Section>
      <AlternateNamesSection
        alternateNames={productData.alternateNames || []}
        onChangeAlternateName={onChangeAlternateName}
      />
    </ScreenLayout>
  );
};
