import { gql } from '@apollo/client';

export const FRAGMENTS = {
  adjustment: gql`
    fragment Adjustment on Adjustment {
      id
      name
      amount
      adjustmentUnit
      adjustmentType
      allowOnPaymentType
      schedule {
        day
        isActive
        timeSlot {
          startTime
          endTime
        }
      }
      orderTypes
      dateTimeRange {
        startDate
        endDate
        startTime
        endTime
      }
      stores
      doNotIncludeInSalesAmount
      advanceRules {
        applyOn
        guestCount
        purchasedCriteria {
          operator
          itemIds
          qty
        }
        discountedCriteria {
          operator
          itemIds
          qty
        }
      }
      isAdvanceRuleEnabled
      isDiscountRuleEnabled
      discountRuleType
      displayNameOnReceipt
      isDateRangeEnabled
      isScheduledEnabled
      adjustmentCode
    }
  `,
};

export const GET_ADJUSTMENT_BY_ID = gql`
  query adjustment($id: ID!) {
    adjustment(id: $id) {
      ...Adjustment
    }
  }
  ${FRAGMENTS.adjustment}
`;

export const GET_ADJUSTMENTS_BY_VENUE_ID = gql`
  query venueById($id: ID!) {
    venue(id: $id) {
      id
      adjustments {
        ...Adjustment
      }
    }
  }
  ${FRAGMENTS.adjustment}
`;

export const GET_ADJUSTMENTS_BY_ADJUSTMENT_TYPE = gql`
  query adjustmentsByType($input: AdjustmentsTypeInput) {
    adjustmentsByType(input: $input) {
      ...Adjustment
    }
  }
  ${FRAGMENTS.adjustment}
`;

export const DELETE_ADJUSTMENT_BY_ID = gql`
  mutation DeleteAdjustment($id: ID!) {
    deleteAdjustment(adjustmentId: $id)
  }
`;

export const CREATE_OR_UPDATE_ADJUSTMENTS = gql`
  mutation CreateOrUpdateAdjustments($input: UpdateAdjustmentInput) {
    createOrUpdateAdjustments(input: $input) {
      ...Adjustment
    }
  }
  ${FRAGMENTS.adjustment}
`;
