import React, { useEffect, useCallback, useMemo } from 'react';
import { View, Text } from 'react-native';
import { useTranslation } from '@oolio-group/localization';
import { useMenus } from '../../../../../hooks/app/menus/useMenus';
import { useIsFocused } from '@react-navigation/native';
import { useModal } from '@oolio-group/rn-use-modal';
import { useVenues } from '../../../../../hooks/app/useVenues';
import { CreateCatalogueInput, Catalogue } from '@oolio-group/domain';
import { useNotification } from '../../../../../hooks/Notification';
import styles from '../Menu.styles';
import theme from '../../../../../common/default-theme';
import { MenuRow } from '../MenuRow';
import CreateMenuModal from '../Modals/CreateMenu';
import ScreenLayout from '../../../../../components/Office/ScreenLayout/ScreenLayout';
import Section from '../../../../../components/Office/Section/Section';
import CreateButton from '../../../../../components/Office/CreateButton/CreateButton';

export const Menu: React.FC = () => {
  const { translate } = useTranslation();
  const isFocused = useIsFocused();
  const { showModal, closeModal } = useModal();
  const { showNotification } = useNotification();

  const {
    loading,
    getMenus,
    menus: menuMaps,
    // copyMenu,
    createMenu,
    error,
  } = useMenus();

  const { getVenues, venues: venueMaps } = useVenues();
  const venues = useMemo(() => Object.values(venueMaps), [venueMaps]);

  const menus = useMemo(() => {
    return Object.values(menuMaps);
  }, [menuMaps]);

  useEffect(() => {
    isFocused && getMenus();
    isFocused && getVenues();
  }, [getMenus, isFocused, getVenues]);

  const onCreateMenu = useCallback(
    (input: CreateCatalogueInput) => {
      closeModal();
      createMenu(input);
    },
    [closeModal, createMenu],
  );

  const onPressCreateMenu = useCallback((): void => {
    showModal(<CreateMenuModal onCreate={onCreateMenu} venues={venues} />);
  }, [onCreateMenu, showModal, venues]);

  // TODO – Ability to copy a menu

  // const onCopyMenu = useCallback(
  //   (copyId: string) => {
  //     copyMenu(copyId);
  //     closeModal();
  //   },
  //   [closeModal, copyMenu],
  // );

  // const onCopy = useCallback(
  //   (copyId: string, name: string): void => {
  //     showModal(
  //       <ConfirmationModal
  //         title={translate('menus.copyMenu')}
  //         confirmLabel={translate('menus.copyTitle')}
  //         message={translate('menus.copyMenuesMessage', {
  //           name,
  //         })}
  //         onConfirm={() => onCopyMenu(copyId)}
  //       />,
  //     );
  //   },
  //   [showModal, translate, onCopyMenu],
  // );

  useEffect(() => {
    if (!error) return;
    showNotification({ error: true, message: error });
  }, [error, showNotification]);

  return (
    <ScreenLayout loading={loading} title="Menus | Oolio" hideFooter>
      <Section
        title={translate('menus.menusHeading')}
        subtitle={translate('menus.description')}
        layoutWidth="medium"
      >
        {/* eslint-disable-next-line react-native/no-inline-styles */}
        <View style={{ marginBottom: 20 }}>
          <View style={theme.tables.header}>
            <Text style={[theme.tables.headerText, styles.cellName]}>Menu</Text>
            <Text style={[theme.tables.headerText, styles.cellType]}>Type</Text>
            <Text style={[theme.tables.headerText, styles.headerCount]}>
              Venues
            </Text>
          </View>
          <View>
            {menus
              .sort((a, b) => a.catalogueType.localeCompare(b.catalogueType))
              .map((menu: Catalogue, i: number) => (
                <MenuRow key={i} menu={menu} />
              ))}
          </View>
        </View>
        <CreateButton onPress={onPressCreateMenu} />
      </Section>
    </ScreenLayout>
  );
};
