import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  tableContainer: {
    marginBottom: 20,
  },
  cellName: {
    width: 240,
    marginRight: 10,
  },
  headerName: {
    width: 260,
  },
  cellProduct: {
    flex: 1,
    paddingLeft: 12,
    marginRight: 'auto',
  },
  cellCount: {
    width: 70,
    marginRight: 10,
  },
  cellToggle: {
    width: 70,
    marginRight: 'auto',
    alignItems: 'center',
  },
  headerToggle: {
    width: 70,
    marginLeft: 10,
    textAlign: 'center',
  },
  inputToggle: {
    marginTop: 'auto',
  },
});

export default styles;
