import React, {
  FC,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { View, Text, Modal, ScrollView, FlatList } from 'react-native';
import {
  SelectionLimit,
  ProductModifierGroup,
  Modifier,
  DEFAULT_PRODUCT_MODIFIER_GROUP_NAME,
  DEFAULT_MAX_LIMIT_FOR_ZERO_SELECTION,
} from '@oolio-group/domain';
import { useTranslation } from '@oolio-group/localization';
import styles from './RequiredOptionsModal.styles';
import theme from '../../../../common/default-theme';
import modalStyles from '../../../Shared/Modals/Modal/Modal.styles';
import OptionGroupTab from './OptionGroupTab';
import MenuItem from '../../MenuItem/MenuItem';
import TreatButton from '../../../Shared/TreatButton/TreatButton';
import sortBy from 'lodash/sortBy';
import keyBy from 'lodash/keyBy';
import { sumDecimals } from '@oolio-group/order-helper';

export type OptionGroupItem = ProductModifierGroup & { isSelecting: boolean };
export type OptionItem = Modifier & { quantity: number };
export type SelectedOptions = Record<string, OptionItem[]>;
export interface SelectedOptionGroup {
  groupId: string;
  priority: number;
  options: OptionItem[];
}
export interface OptionsModalProps {
  product: string;
  optionGroups: Array<ProductModifierGroup>;
  onCancel: () => void;
  onAddOptionsToItem: (
    selectedOptionGroups: SelectedOptionGroup[],
    defaultOptions: SelectedOptions,
  ) => void;
  selectedOptions?: SelectedOptions;
  selectedOptionGroupId?: string;
}

const addDefaultQuantity = (mod: Modifier): OptionItem => ({
  ...mod,
  quantity: 1,
});

const filterValidQuantity = (item: OptionItem) =>
  item?.quantity && item.quantity >= 1;

const getTotalSelection = (options?: OptionItem[]): number => {
  return sumDecimals((options || []).map(item => item.quantity));
};
const RequiredOptionsModal: FC<OptionsModalProps> = ({
  product,
  optionGroups: optionGroupsProps,
  onAddOptionsToItem,
  onCancel,
  selectedOptions: selectedOptionsProp,
  selectedOptionGroupId: selectedOptionGroupIdProp,
}) => {
  const { translate } = useTranslation();
  const [activeGroupId, setActiveGroupId] = useState('');
  const [selectedOptions, setSelectedOptions] = useState<SelectedOptions>({});
  const flatListRef = useRef<FlatList<OptionGroupItem> | null>(null);

  const optionGroups = useMemo(() => {
    return sortBy(optionGroupsProps, 'priority');
  }, [optionGroupsProps]);

  const optionGroupMaps = useMemo(
    () => keyBy(optionGroups, 'id'),
    [optionGroups],
  );

  const formattedOptionGroups = useMemo<OptionGroupItem[]>(() => {
    return optionGroups.map(item => ({
      ...item,
      isSelecting: item?.id === activeGroupId,
    }));
  }, [activeGroupId, optionGroups]);

  // Helper function to check if a group's selection criteria is met
  const isGroupWithinLimits = useCallback(
    (group?: ProductModifierGroup, optionSelectionsProp?: SelectedOptions) => {
      if (!group) return false;
      if (group?.name === DEFAULT_PRODUCT_MODIFIER_GROUP_NAME) return true;

      const numOfSelectedOptions = getTotalSelection(
        (optionSelectionsProp || selectedOptions)[group.id] || [],
      );
      const { min, max } = group.selectionLimit || {};
      if (group?.isRequired)
        return (
          numOfSelectedOptions >= Math.max(min, 1) &&
          numOfSelectedOptions <= max
        );
      return numOfSelectedOptions <= max;
    },
    [selectedOptions],
  );

  // Generates selection limit/criteria string for option group
  const getSelectionLimitString = useCallback(
    (limit?: SelectionLimit) => {
      const { min = 0, max = 0 } = limit || {};

      if (min === max && min !== 0) {
        return `${translate('modifiers.select')} ${min}`;
      }
      if (min === 0 && max === 0) {
        return translate('modifiers.selectAny');
      }
      if (min === 0 && max !== 0) {
        return translate('modifiers.selectUpTo', {
          n: max,
        });
      }

      return `${translate('modifiers.selectRange', {
        min,
        max,
      })}`;
    },
    [translate],
  );

  // Generates subtitle string for option group selections
  const getGroupSelectionsString = useCallback(
    (group?: ProductModifierGroup) => {
      const groupSelections = selectedOptions[group?.id as string];

      if (!groupSelections) return translate('modifiers.notSelected');

      const names = groupSelections
        .filter(filterValidQuantity)
        .map(option => option.name);
      if (names.length === 0) {
        return group?.isRequired
          ? translate('modifiers.notSelected')
          : translate('modifiers.optional');
      } else {
        return names.join(', ');
      }
    },
    [selectedOptions, translate],
  );

  const activeGroup = useMemo(
    () => optionGroupMaps[activeGroupId],
    [activeGroupId, optionGroupMaps],
  );

  const onPressNextGroup = useCallback(
    (actualSelections?: SelectedOptions) => {
      const nextRequiredGroup = optionGroups.find(
        group => !isGroupWithinLimits(group, actualSelections),
      );
      let nextActiveGroupId;
      if (nextRequiredGroup?.id) {
        nextActiveGroupId = nextRequiredGroup?.id;
      } else {
        const currentIndex = optionGroups.findIndex(
          optionGroup => optionGroup?.id === activeGroupId,
        );
        const maxIndex = optionGroups?.length - 1;
        let nextIndex = currentIndex + 1;
        if (nextIndex > maxIndex) {
          nextIndex = 0;
        }
        nextActiveGroupId = optionGroups[nextIndex]?.id;
      }
      setActiveGroupId(nextActiveGroupId);
    },
    [activeGroupId, isGroupWithinLimits, optionGroups],
  );

  // onPress to add option to components state
  const onSelectOptionItem = useCallback(
    (activeOption: Modifier, quantity: number) => {
      setSelectedOptions(prevOptions => {
        const selectingOptions = prevOptions[activeGroupId] || [];
        const isOptionSelected = selectingOptions.find(
          o => o.id == activeOption.id,
        );
        let updatedSelections: OptionItem[];
        if (isOptionSelected) {
          updatedSelections = selectingOptions.map(option => {
            if (option.id === activeOption.id) {
              return { ...option, quantity: option.quantity + quantity };
            }
            return option;
          });
        } else {
          updatedSelections = selectingOptions.concat({
            ...activeOption,
            quantity,
          });
        }

        const updatedOptions = {
          ...prevOptions,
          [activeGroupId]: updatedSelections,
        };

        const max =
          activeGroup?.selectionLimit?.max ||
          DEFAULT_MAX_LIMIT_FOR_ZERO_SELECTION;

        const numOfOptionSelected = getTotalSelection(updatedSelections);

        if (numOfOptionSelected > max) {
          return prevOptions;
        }

        if (numOfOptionSelected === max) {
          // auto move to next option group
          onPressNextGroup(updatedOptions);
        }

        return updatedOptions;
      });
    },
    [activeGroup?.selectionLimit?.max, activeGroupId, onPressNextGroup],
  );

  const defaultOptionSelections = useMemo(() => {
    return optionGroupsProps.reduce((maps, optionGroup) => {
      const defaultOptions = optionGroup.modifiers
        .filter(mod => mod.isDefault)
        .map(addDefaultQuantity);
      maps[optionGroup.id] = defaultOptions;
      return maps;
    }, {} as SelectedOptions);
  }, [optionGroupsProps]);

  // onPress to add selected options to cart
  const onPressAddToCart = useCallback(() => {
    const updatedOptionGroups: SelectedOptionGroup[] = Object.keys(
      selectedOptions,
    ).map(groupId => ({
      groupId,
      priority: optionGroupMaps[groupId].priority,
      options: selectedOptions[groupId].filter(filterValidQuantity),
    }));
    const sortedData = sortBy(updatedOptionGroups, 'priority');

    onAddOptionsToItem(sortedData, defaultOptionSelections);
  }, [
    defaultOptionSelections,
    onAddOptionsToItem,
    optionGroupMaps,
    selectedOptions,
  ]);

  // Selects the first option group by default
  useEffect(() => {
    if (!optionGroups.length) return;
    setActiveGroupId(optionGroups[0].id || '');
  }, [optionGroups]);

  const isValid = useMemo(() => {
    return optionGroups.every(option => isGroupWithinLimits(option));
  }, [isGroupWithinLimits, optionGroups]);

  const sortedActiveOptions = useMemo(() => {
    return sortBy(activeGroup?.modifiers || [], 'priority');
  }, [activeGroup?.modifiers]);

  const selectingOptionMaps = useMemo(
    () => keyBy(selectedOptions[activeGroupId], 'id'),
    [activeGroupId, selectedOptions],
  );

  useEffect(() => {
    if (selectedOptionsProp === undefined) {
      // Update default options for selection
      setSelectedOptions(defaultOptionSelections);
    } else {
      setSelectedOptions(selectedOptionsProp);
    }
  }, [defaultOptionSelections, selectedOptionsProp]);

  useEffect(() => {
    if (selectedOptionGroupIdProp === undefined) return;
    setActiveGroupId(selectedOptionGroupIdProp);
  }, [selectedOptionGroupIdProp]);

  useEffect(() => {
    if (!activeGroupId) return;
    const selectingIndex = optionGroups.findIndex(x => x.id === activeGroupId);
    flatListRef.current?.scrollToIndex({
      index: selectingIndex,
    });
  }, [activeGroupId, optionGroups]);

  const isUpdateOptions = selectedOptionsProp !== undefined;

  return (
    <Modal
      visible={true}
      transparent={true}
      animationType="fade"
      onDismiss={onCancel}
      onRequestClose={onCancel}
      presentationStyle="overFullScreen"
    >
      <View style={modalStyles.background}>
        <View style={[modalStyles.container, styles.container]}>
          <View style={styles.groups}>
            <View style={styles.product}>
              <Text style={styles.productText}>{product}</Text>
            </View>
            <FlatList
              data={formattedOptionGroups}
              ref={flatListRef}
              renderItem={({ item }) => {
                return (
                  <OptionGroupTab
                    key={item?.id}
                    name={item?.name}
                    subtitle={getGroupSelectionsString(item)}
                    onPress={() => setActiveGroupId(item.id)}
                    status={
                      item?.isSelecting
                        ? 'active'
                        : !item.isRequired
                        ? 'optional'
                        : isGroupWithinLimits(item)
                        ? 'selected'
                        : 'required'
                    }
                  />
                );
              }}
              showsVerticalScrollIndicator={false}
              keyExtractor={item => item.id}
              onScrollToIndexFailed={info => {
                const wait = new Promise(resolve => setTimeout(resolve, 500));
                wait.then(() => {
                  flatListRef.current?.scrollToIndex({
                    index: info.index,
                    animated: true,
                  });
                });
              }}
            />
          </View>
          <View style={styles.body}>
            <View
              style={[
                styles.bodyTitle,
                isGroupWithinLimits(activeGroup) && {
                  backgroundColor: theme.colors.greenLight,
                },
              ]}
            >
              <View
                style={[
                  styles.circle,
                  isGroupWithinLimits(activeGroup) && {
                    backgroundColor: theme.colors.green,
                  },
                ]}
              />
              <Text style={styles.bodyTitleText}>{`${
                activeGroup?.name
              } (${getSelectionLimitString(activeGroup?.selectionLimit)}) ${
                activeGroup?.isRequired
                  ? ''
                  : `– ${translate('modifiers.optional')}`
              }`}</Text>
              <View
                style={[
                  styles.formattingSelection,
                  {
                    backgroundColor: isGroupWithinLimits(activeGroup)
                      ? theme.colors.green
                      : theme.colors.red,
                  },
                ]}
              >
                <Text style={styles.activeText}>
                  {translate('modifiers.selectedModifierOutOfTotal', {
                    selected: getTotalSelection(selectedOptions[activeGroupId]),
                    total:
                      activeGroup?.selectionLimit?.max ||
                      DEFAULT_MAX_LIMIT_FOR_ZERO_SELECTION,
                  })}
                </Text>
              </View>
            </View>
            <ScrollView style={styles.gridScroll}>
              <View style={styles.grid}>
                {sortedActiveOptions.map((option, i) => {
                  const selectedItem = selectingOptionMaps[option.id];
                  const quantity = selectedItem?.quantity || 0;
                  const isSelected = quantity >= 1;
                  const isDefaultOptionDeselected =
                    option?.isDefault && !isSelected;

                  const color = isDefaultOptionDeselected
                    ? theme.colors.states.negative
                    : isSelected
                    ? theme.colors.states.positive
                    : undefined;

                  const isMultiSelection =
                    (activeGroup?.maxSelectionPerOption || 0) > 1;

                  return (
                    <MenuItem
                      key={i}
                      name={option.name}
                      onPressDecrease={() => onSelectOptionItem(option, -1)}
                      disabled={
                        isMultiSelection &&
                        quantity === activeGroup?.maxSelectionPerOption
                      }
                      color={color}
                      quantity={selectedItem?.quantity}
                      isMultiSelection={isMultiSelection}
                      onPress={() =>
                        onSelectOptionItem(
                          option,
                          isMultiSelection ? 1 : quantity == 1 ? -1 : 1,
                        )
                      }
                    />
                  );
                })}
              </View>
            </ScrollView>
            <View style={styles.actions}>
              <TreatButton
                type="negativeLight"
                label={translate('button.cancel')}
                onPress={onCancel}
              />
              <TreatButton
                type="positiveLight"
                label={`${translate('button.next')} ->`}
                onPress={() => onPressNextGroup()}
                containerStyle={styles.nextBtn}
                disabled={!isGroupWithinLimits(activeGroup)}
              />
              <TreatButton
                type="positive"
                onPress={onPressAddToCart}
                containerStyle={styles.btnCart}
                disabled={!isValid}
                label={
                  isUpdateOptions
                    ? translate('cart.update')
                    : translate('cart.addToCart')
                }
              />
            </View>
          </View>
        </View>
      </View>
    </Modal>
  );
};

export default RequiredOptionsModal;
