import { useLazyQuery } from '@apollo/client/react/hooks';
import { QueryLazyOptions, OperationVariables } from '@apollo/client';
import { parseApolloError } from '../../utils/errorHandlers';
import { useCallback, useMemo, useState } from 'react';
import { GENERATE_DETAILED_SUMMARY_REPORT } from '../../graphql/reporting';
import { getError, isLoading } from '../../utils/apolloErrorResponse.util';
import { noopHandler } from '../../utils/errorHandlers';

export interface UseReportExport {
  loading: boolean;
  error: string | undefined;
  reportUrl: string | undefined;
  generateDetailedSummaryReport: (
    options: QueryLazyOptions<OperationVariables> | undefined,
  ) => void;
}

export const useReportExport = (): UseReportExport => {
  const [reportUrl, setReportUrl] = useState<string | undefined>(undefined);

  const onCompleteRequest = useCallback(async data => {
    const key = Object.keys(data)[0];
    setReportUrl(data[key]);
  }, []);

  const [
    generateDetailedSummaryReportRequest,
    generateDetailedSummaryReportResponse,
  ] = useLazyQuery(GENERATE_DETAILED_SUMMARY_REPORT, {
    fetchPolicy: 'no-cache',
    onCompleted: onCompleteRequest,
    onError: noopHandler,
  });

  const RESPONSE_ENTITIES = [generateDetailedSummaryReportResponse];

  const loading = isLoading(RESPONSE_ENTITIES);
  const error = getError(RESPONSE_ENTITIES);

  return useMemo(
    () => ({
      loading,
      error: error ? parseApolloError(error) : undefined,
      reportUrl,
      generateDetailedSummaryReport: generateDetailedSummaryReportRequest,
    }),
    [loading, error, reportUrl, generateDetailedSummaryReportRequest],
  );
};
