import React, { useCallback, useState } from 'react';
import { View, Text, Platform } from 'react-native';
import {
  getAvailableLanguages,
  Locale,
  useLocalization,
  useTranslation,
  getSupportNumber,
} from '@oolio-group/localization';
import { DeviceMode, UpdateDeviceInput } from '@oolio-group/domain';
import * as settings from '../../../../state/preferences';
import { useModal } from '@oolio-group/rn-use-modal';
import { useSession } from '../../../../hooks/app/useSession';
import { useDeviceInfo } from '../../../../hooks/app/useDeviceInfo';
import { useRestart } from '../../../../hooks/app/useRestart';
import { useSettingsSync } from '../../../../hooks/app/useSettingsSync';
import { useDevices } from '../../../../hooks/app/useDevices';
import { useDeviceId } from '../../../../hooks/app/useDeviceId';
import pick from 'lodash/pick';
import styles from '../SettingsScreen.styles';
import ConfirmationModal from '../../../../components/Modals/ConfirmationDialog';
import {
  SettingsIcon,
  SettingsToggle,
  SettingsAction,
  SettingsSelect,
} from '../../../../components/POS/SettingsOptions/SettingsOptions';
import LoadingOverlay from '../../../../components/Shared/Loaders/LoadingOverlay';

const availableLocales = getAvailableLanguages();

const GeneralSettings: React.FC = () => {
  const { showModal } = useModal();
  const { deviceId } = useDeviceId();
  const { translate } = useTranslation();
  const { versionNum } = useDeviceInfo();
  const { restartDevice } = useRestart();
  const [session, setSession] = useSession();
  const { locale: currentLocale } = useLocalization();
  const { loading: loadingSync, syncApp } = useSettingsSync();

  const [showLocales, setShowLocales] = useState<boolean>(false);

  const currentDevice = session?.currentStore?.devices?.find(
    device => device.uuid === deviceId,
  );

  const { updateDevice } = useDevices({
    deviceId: currentDevice?.id,
    storeId: session?.currentStore?.id,
  });

  const sync = (): void => {
    syncApp();
  };

  const changeLanguage = useCallback(
    (locale: Locale): void => {
      settings.setLocale(locale);
      restartDevice();
    },
    [restartDevice],
  );

  const onChangeLocale = useCallback(
    (locale: Locale): void => {
      showModal(
        <ConfirmationModal
          title={translate('settings.languagePopUpPromptHeader')}
          message={translate('settings.languagePopUpPromptBody')}
          onConfirm={(): void => {
            changeLanguage(locale);
          }}
        />,
      );
    },
    [showModal, translate, changeLanguage],
  );

  const toggleCartKeypadSetting = useCallback((): void => {
    setSession({
      ...session,
      settings: {
        ...session.settings,
        showAdvancedCartActionsSetting:
          !session?.settings?.showAdvancedCartActionsSetting,
      },
    });
  }, [session, setSession]);

  const toggleQuickPaymentSetting = useCallback((): void => {
    setSession({
      ...session,
      settings: {
        ...session.settings,
        enableQuickPaymentModeSetting:
          !session?.settings?.enableQuickPaymentModeSetting,
      },
    });
  }, [session, setSession]);

  const toggleSendButton = useCallback((): void => {
    setSession({
      ...session,
      settings: {
        ...session.settings,
        hideSendButton: !session?.settings?.hideSendButton,
      },
    });
  }, [session, setSession]);

  const toggleBarcodeScanningMode = useCallback((): void => {
    setSession({
      ...session,
      settings: {
        ...session.settings,
        enableBarcodeScanningMode:
          !session?.settings?.enableBarcodeScanningMode,
      },
    });
  }, [session, setSession]);

  const updateDeviceProfileOnSession = useCallback(
    (deviceProfileId: string): void => {
      const deviceProfile = session?.currentStore?.deviceProfiles?.find(
        deviceProfile => deviceProfile.id == deviceProfileId,
      );
      if (deviceProfile) {
        const updatedSession = {
          ...session,
          deviceProfile: {
            ...deviceProfile,
            defaultOrderType:
              deviceProfile?.defaultOrderType || deviceProfile?.orderTypes?.[0],
          },
        };
        setSession(updatedSession);
      }
    },
    [session, setSession],
  );

  const onchangeDeviceProfile = useCallback(
    (deviceProfileId: string): void => {
      let input = {
        ...currentDevice,
        deviceProfile: deviceProfileId,
      } as unknown as UpdateDeviceInput;
      if (!input.cashDrawer) input.cashDrawer = 'default';
      if (!input.paymentTerminal) input.paymentTerminal = 'default';
      input = pick(input, [
        'id',
        'name',
        'salesPrefix',
        'returnPrefix',
        'deviceProfile',
        'paymentTerminal',
        'cashDrawer',
      ]) as unknown as UpdateDeviceInput;

      updateDevice(input as unknown as UpdateDeviceInput);
      updateDeviceProfileOnSession(deviceProfileId);
    },
    [currentDevice, updateDevice, updateDeviceProfileOnSession],
  );

  return (
    <View style={styles.container}>
      <View style={styles.section}>
        <Text style={styles.sectionTitle}>
          {translate('backOfficeDeviceProfiles.title')}
        </Text>
        {session?.currentStore?.deviceProfiles
          ?.filter(
            profile =>
              profile.mode === DeviceMode.F_POS || profile.mode === undefined,
          )
          .map((deviceProfile, index) => {
            return (
              <SettingsSelect
                key={index}
                testID={`profile-${index}`}
                title={deviceProfile.name}
                isSelected={session?.deviceProfile?.id == deviceProfile.id}
                onPress={(): void => onchangeDeviceProfile(deviceProfile.id)}
              />
            );
          })}
      </View>
      <View style={styles.section}>
        <Text style={styles.sectionTitle}>
          {translate('storesSettings.preferences')}
        </Text>
        <SettingsToggle
          testID="toggle-sendButton"
          title={translate('settings.hideSendButton')}
          isToggled={session?.settings?.hideSendButton || false}
          onToggle={toggleSendButton}
        />
        <SettingsToggle
          testID="toggle-cartActions"
          title={translate('settings.advancedCartActions')}
          isToggled={session?.settings?.showAdvancedCartActionsSetting || false}
          onToggle={toggleCartKeypadSetting}
        />
        <SettingsToggle
          testID="toggle-quickPayment"
          title={translate('settings.enableQuickPaymentMode')}
          isToggled={session?.settings?.enableQuickPaymentModeSetting || false}
          onToggle={toggleQuickPaymentSetting}
        />
        <SettingsToggle
          testID="toggle-barcodeScanning"
          title={translate('settings.enableBarcodeScanning')}
          isToggled={session?.settings?.enableBarcodeScanningMode || false}
          onToggle={toggleBarcodeScanningMode}
        />
      </View>
      <View style={styles.section}>
        <Text style={styles.sectionTitle}>{translate('payment.other')}</Text>
        <SettingsIcon
          title={translate('settings.language')}
          testID="locales-parent"
          value={currentLocale.label}
          icon={showLocales ? 'angle-up' : 'angle-down'}
          onPress={() => setShowLocales(!showLocales)}
        />
        {showLocales && (
          <View style={styles.locales}>
            {availableLocales.map((locale: Locale, i: number) => {
              return (
                <SettingsSelect
                  key={i}
                  title={locale.label}
                  testID={`locale-${i}`}
                  onPress={onChangeLocale.bind(null, locale)}
                  isSelected={locale.languageTag === currentLocale.languageTag}
                />
              );
            })}
          </View>
        )}
        <SettingsIcon
          testID="support"
          title={translate('settings.contactSupport')}
          value={getSupportNumber(session.currentOrganization?.country)}
        />
        {Platform.OS !== 'web' && (
          <SettingsIcon
            testID="appVersion"
            title={translate('settings.appVersion')}
            value={versionNum}
          />
        )}
        <SettingsAction
          type="neutral"
          testID="btn-sync"
          loading={loadingSync}
          label={translate('settings.syncApp')}
          onPress={sync}
          // eslint-disable-next-line react-native/no-inline-styles
          containerStyle={{ marginTop: 16 }}
        />
        <LoadingOverlay isLoading={loadingSync} testID="Spinner" />
      </View>
    </View>
  );
};

export default GeneralSettings;
