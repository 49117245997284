import { StyleSheet, TextStyle, ViewStyle } from 'react-native';
import theme from '../../../../common/default-theme';

const bgContainer: ViewStyle = {
  height: 40,
  flexDirection: 'row',
  alignItems: 'center',
  borderRadius: theme.radius.s,
  backgroundColor: theme.colors.white,
};

const btn: ViewStyle = {
  ...bgContainer,
  justifyContent: 'center',
  width: 68,
  marginLeft: 4,
};

const text: TextStyle = {
  flex: 1,
  fontSize: 14,
  lineHeight: 20,
  color: theme.colors.black1,
  fontFamily: theme.fonts.medium,
};

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 4,
  },
  orderType: {
    flex: 1,
    ...bgContainer,
  },
  counter: {
    marginHorizontal: 8,
  },
  text: {
    ...text,
  },
  icon: {
    marginRight: 10,
  },
  btnTable: {
    ...btn,
    width: 68,
    paddingHorizontal: 10,
  },
  textTable: {
    ...text,
    textAlign: 'center',
    color: theme.colors.primary,
    textTransform: 'uppercase',
  },
  btnAllergens: {
    ...btn,
    width: 44,
  },
  badge: {
    position: 'absolute',
    top: 6,
    right: 6,
    width: 12,
    height: 12,
    borderRadius: 6,
    backgroundColor: theme.colors.states.focus,
  },
});

export default styles;
