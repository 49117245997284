import React from 'react';
import { DateRangeFilter, FilterObject, Filters } from '@oolio-group/domain';
import { HeaderStyles } from '../../styles/SalesSummary.styles';
import { View } from 'react-native';
import { DateRangeFilters } from '../../UIComponents/DateRangeFilters';
import IconButton from '../../../../../components/Button/IconButton';
import Button from '../../../../../components/Button/Button';
import { useTranslation } from '@oolio-group/localization';
import DropDown from '../../../../../components/Shared/Select/Picker';
import { ExportType, ReportTableColumn, ReportTypeSettings } from '../../types';
import { DateRangeFiltersStyles } from '../../styles/Component.styles';
import { constantCase } from 'change-case';
import MultipleDropDown from '../../../../../components/MultipleSelect/MultipleSelect';
import Icon from '../../../../../components/Icon/Icon';
import { TRADING_PERIOD_KEY } from '../../reportsHelper';

interface HeaderProps {
  allFilters: Filters;
  dateRangeFilter: DateRangeFilter;
  reportTypeSettings: ReportTypeSettings;
  updateReport: () => void;
  exportReport: (type: ExportType) => void;
  toggleFilters: () => void;
  updateDateRangeFilters: (value: DateRangeFilter) => void;
  updateReportType: (value: string) => void;
  columns: {
    all: ReportTableColumn[];
    updateColumns: (columnKeys: string[]) => void;
    selectedColumns: ReportTableColumn[];
  };
}

export const SalesSummaryHeader: React.FC<HeaderProps> = ({
  allFilters,
  dateRangeFilter,
  reportTypeSettings,
  columns,
  updateReport,
  exportReport,
  toggleFilters,
  updateDateRangeFilters,
  updateReportType,
}) => {
  const { translate } = useTranslation();
  const styles = HeaderStyles();
  const dropdownStyles = DateRangeFiltersStyles();

  const tradingPeriods: FilterObject[] =
    allFilters[TRADING_PERIOD_KEY as keyof Filters];

  return (
    <View style={styles.pageStyle}>
      <View style={styles.row1}>
        <View style={styles.basicDropdown}>
          <View style={dropdownStyles.primaryFilter}>
            <DropDown
              testID="toggle-report-type"
              options={reportTypeSettings.options.map(type => ({
                value: type,
                label: constantCase(type, { delimiter: ' ' }),
              }))}
              selectedValue={reportTypeSettings.value}
              containerStyle={dropdownStyles.fieldInputContainerStyle}
              onValueChange={value =>
                updateReportType && updateReportType(value)
              }
            ></DropDown>
          </View>
        </View>
        <View style={styles.dateRangeFilters}>
          <DateRangeFilters
            tradingPeriods={tradingPeriods}
            dateRangeFilter={dateRangeFilter}
            updateDateRangeFilters={updateDateRangeFilters}
            reportType={reportTypeSettings.value}
          ></DateRangeFilters>
        </View>
        <View style={styles.iconStyle}>
          <IconButton
            icon="sliders-v"
            onPress={() => toggleFilters()}
            primary
            containerStyle={styles.filtersButtonStyle}
            iconStyle={styles.filtersIconStyle}
            containerSize={38}
            iconSize={25}
          ></IconButton>
        </View>
        <MultipleDropDown
          containerStyle={styles.iconStyle}
          showSearch={false}
          values={columns.all?.map(col => ({
            label: translate(col.title),
            value: col.title,
          }))}
          selectedValues={columns.selectedColumns?.map(col => col.title)}
          onValueChange={columns.updateColumns}
          collapsedView={
            <View style={styles.columnsButtonStyle}>
              <Icon
                size={25}
                name="table"
                primary
                style={styles.columnsIconStyle}
                disabled={false}
              />
            </View>
          }
          toggleIcon={<></>}
        />
        <View style={styles.updateContainerStyle}>
          <Button
            testID="update-report"
            fluid
            title={translate('backOfficeReports.header.updatedBtn')}
            containerStyle={styles.updateButtonStyle}
            labelStyle={styles.titleStyle}
            onPress={updateReport}
          />
        </View>
        <View style={styles.downloadContainerStyle}>
          <IconButton
            testID="download-report"
            icon="CloudDownload"
            primary
            containerSize={38}
            containerStyle={styles.downloadButtonStyle}
            iconStyle={styles.downloadIconStyle}
            iconSize={25}
            onPress={() => exportReport(ExportType.CSV)}
          ></IconButton>
        </View>
      </View>
    </View>
  );
};
