import React, { useState, useMemo } from 'react';
import { ScrollView, Text, View } from 'react-native';
import {
  Dictionary,
  EXCLUDED_ITEM_STATUSES,
  Icons,
  OrderItem,
  RewardByDiscountProduct,
  Product,
  RewardRule,
  RewardType,
  StyleFn,
} from '@oolio-group/domain';
import TitleBar from '../../TitleBar/TitleBar';
import Modal from '../Modal';
import { useFela } from 'react-fela';
import IconButton from '../../Button/IconButton';
import Button from '../../Button/Button';
import { RewardMap } from '../../../hooks/orders/useRewards';
import { useTranslation } from '@oolio-group/localization';
import { DiscountType } from '@oolio-group/domain';

const TYPE_WITH_ITEM_QUANTITY: RewardType[] = [
  RewardType.FREE_ITEM,
  RewardType.PRODUCT_DISCOUNT,
];

export interface RedeemItemsModalProps {
  onRedeem: (rewardMap: RewardMap) => void;
  rewardMap: RewardMap;
  onBack: (rewardMap: RewardMap) => void;
  reward: RewardRule & Required<{ products: Product[] }>;
  remainingBalance: number;
  orderItems?: OrderItem[];
}

const headerStyle: StyleFn = ({ theme }) => ({
  width: 440,
  marginTop: theme.spacing.small,
  alignSelf: 'center',
});

const backButtonStyle: StyleFn = ({ theme }) => ({
  position: 'absolute',
  left: theme.spacing.big / 2,
});

const customBodyStyle: StyleFn = () => ({
  padding: 0,
});

const containerStyle: StyleFn = () => ({
  width: 440,
  alignSelf: 'center',
  paddingTop: 0,
  paddingBottom: 0,
});

const productContainerStyle: StyleFn = () => ({
  minHeight: 200,
  maxHeight: 400,
});

const productRowStyle: StyleFn = ({ theme, selected }) => ({
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: theme.padding.medium,
  backgroundColor: selected ? theme.colors.successLightest : theme.colors.white,
  borderRadius: 4,
  marginBottom: 4,
});

const controlWrapperStyle: StyleFn = () => ({
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: 70,
});

const controlStyle: StyleFn = ({ theme, disabled }) => ({
  color: disabled ? theme.colors.paragraphLight : theme.colors.grey2,
  ...theme.font14SemiBold,
});

const productNameStyle: StyleFn = ({ theme }) => ({
  ...theme.font14Medium,
});

const RedeemItemsModal: React.FC<RedeemItemsModalProps> = ({
  onRedeem,
  onBack,
  rewardMap,
  reward,
  remainingBalance,
  orderItems,
}) => {
  const { css, theme } = useFela();
  const { translate } = useTranslation();
  const [appliedRewardMap, setAppliedRewardMap] =
    useState<RewardMap>(rewardMap);

  const redemptionMap = useMemo(() => {
    return (appliedRewardMap[reward.id] || []).reduce(
      (map, redemption) => {
        const productId = redemption.productId as string;
        const newRedemption = redemption;

        if (redemption.itemQuantity) {
          // sum item quantity
          newRedemption.itemQuantity =
            (map[productId]?.itemQuantity || 0) + redemption.itemQuantity;
        }

        map[productId] = newRedemption;
        return map;
      },
      {} as Dictionary<{
        quantity: number;
        points: number;
        type: RewardType;
        productId?: string;
        itemQuantity?: number;
      }>,
    );
  }, [appliedRewardMap, reward]);

  const shouldDisableIncrement = (productId: string): boolean => {
    // previous consumption, calculated from the original rewardMap
    const previousConsumption = (rewardMap[reward.id] || []).reduce(
      (total, redemption) =>
        total +
        redemption.points * (redemption.itemQuantity || redemption.quantity),
      0,
    );

    const totalQuantity =
      orderItems
        ?.filter(
          item =>
            item.product.id === productId &&
            !EXCLUDED_ITEM_STATUSES.includes(item.status),
        )
        .reduce((total, item) => total + item.quantity, 0) || 0;

    return (
      // Adjustment.itemQuantity equals item's quantity
      Number(redemptionMap[productId]?.itemQuantity) >= totalQuantity ||
      // balance insufficient
      (appliedRewardMap[reward.id] || []).reduce(
        (acc, redemption) =>
          acc -
          (redemption.itemQuantity || redemption.quantity) * redemption.points,
        remainingBalance + previousConsumption, // accounted with previous consumption
      ) -
        reward.pointsRequired <
        0 ||
      // max discount applied
      isMaximumDiscountAmountReached(
        reward as RewardByDiscountProduct,
        orderItems ?? [],
        appliedRewardMap,
      )
    );
  };

  const shouldDisableDecrement = (productId: string): boolean => {
    return !redemptionMap[productId] || redemptionMap[productId].quantity === 0;
  };

  const processQuantityChange = (quantity: number) => {
    return TYPE_WITH_ITEM_QUANTITY.includes(reward.rewardType)
      ? { quantity: quantity && 1, itemQuantity: quantity } // itemQuantity gets updated, quantity stays as 0 or 1
      : { quantity }; // quantity get updated
  };

  function isMaximumDiscountAmountReached(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    reward: any | RewardByDiscountProduct,
    orderItems: OrderItem[],
    appliedRewardMap: RewardMap,
  ): boolean {
    const { discountAmount, maximumDiscountAmount, discountType } = reward;
    const appliedRewards = appliedRewardMap[reward.id];
    let maximumDiscountReached = false;

    if (discountType === DiscountType.PERCENTAGE) {
      if (!appliedRewards || !maximumDiscountAmount) {
        return false;
      }

      let totalDiscountApplied = 0;

      if (appliedRewards) {
        for (const appliedReward of appliedRewards) {
          const matchingItems = orderItems
            .filter(item => item.product.id === appliedReward.productId)
            ?.slice(0, appliedReward.itemQuantity ?? 1);

          matchingItems.forEach(matchingItem => {
            let basePrice = 0;
            basePrice = matchingItem.unitPrice;
            if (matchingItem.modifiers && matchingItem.modifiers.length > 0) {
              matchingItem.modifiers.forEach(modifier => {
                basePrice += modifier.unitPrice;
              });
            }
            if (matchingItem.quantity > 1) {
              basePrice =
                matchingItem.unitPrice * (appliedReward?.itemQuantity ?? 1);
            }
            totalDiscountApplied += (basePrice * discountAmount) / 100;
            const potentialDiscount =
              (matchingItem.unitPrice * discountAmount) / 100;

            if (
              totalDiscountApplied + potentialDiscount >
              maximumDiscountAmount
            ) {
              maximumDiscountReached = true;
              return true;
            }
          });
        }
      }

      if (maximumDiscountReached) {
        return true;
      }

      return totalDiscountApplied >= maximumDiscountAmount;
    }

    return false;
  }

  const handleChange = (productId: string, quantity: number) => {
    setAppliedRewardMap(previousMap => {
      return {
        ...previousMap,
        [reward.id]: Object.values({
          ...redemptionMap,
          [productId]: {
            points: reward.pointsRequired,
            productId: productId,
            type: reward.rewardType,
            ...processQuantityChange(quantity),
          },
        }),
      };
    });
  };

  const viableProducts = useMemo(() => {
    const productsInCart = orderItems?.map(item => item.product.id);
    return reward.products.filter(product =>
      productsInCart?.includes(product.id),
    );
  }, [reward.products, orderItems]);

  return (
    <View>
      <TitleBar
        primary
        testID="title"
        containerStyle={css(headerStyle)}
        title={translate('customerLoyalty.selectItemsToRedeem')}
        titleLeft={
          <IconButton
            icon={Icons.ArrowLeft}
            onPress={() => onBack(appliedRewardMap)}
            containerStyle={css(backButtonStyle)}
            testID="back-btn"
          />
        }
      />
      <Modal
        customBodyStyle={css(customBodyStyle)}
        contentStyle={css(containerStyle)}
        showCloseButton={false}
      >
        <ScrollView style={css(productContainerStyle)}>
          {viableProducts.map(product => {
            return (
              <View
                key={product.id}
                style={css(
                  productRowStyle({
                    theme,
                    selected: redemptionMap[product.id]?.quantity > 0,
                  }),
                )}
                testID="product-item"
              >
                <Text style={css(productNameStyle)}>{product.name}</Text>
                <View style={css(controlWrapperStyle)}>
                  <IconButton
                    icon={Icons.MinusCircle}
                    iconSize={18}
                    iconStyle={css(
                      controlStyle({
                        theme,
                        disabled: shouldDisableDecrement(product.id),
                      }),
                    )}
                    onPress={() =>
                      handleChange(
                        product.id,
                        (redemptionMap[product.id]?.itemQuantity ||
                          redemptionMap[product.id]?.quantity) - 1,
                      )
                    }
                    disabled={shouldDisableDecrement(product.id)}
                    testID="minus-quantity"
                  />
                  <Text style={css(controlStyle)} testID="quantity-text">
                    {redemptionMap[product.id]?.itemQuantity ||
                      redemptionMap[product.id]?.quantity ||
                      0}
                  </Text>
                  <IconButton
                    icon={Icons.PlusCircle}
                    iconSize={18}
                    iconStyle={css(
                      controlStyle({
                        theme,
                        disabled: shouldDisableIncrement(product.id),
                      }),
                    )}
                    onPress={() =>
                      handleChange(
                        product.id,
                        (redemptionMap[product.id]?.itemQuantity ||
                          redemptionMap[product.id]?.quantity ||
                          0) + 1,
                      )
                    }
                    disabled={shouldDisableIncrement(product.id)}
                    testID="plus-quantity"
                  />
                </View>
              </View>
            );
          })}
        </ScrollView>
        <View>
          <Button
            onPress={() => onRedeem(appliedRewardMap)}
            success
            title={translate('customerLoyalty.redeemItems')}
            testID="redeem-btn"
          />
        </View>
      </Modal>
    </View>
  );
};

export default RedeemItemsModal;
