import { EscPos } from '@tillpos/xml-escpos-helper';
import format from 'date-fns/format';
import { divider } from './printDivider';
import { table, getBorderCharacters, TableUserConfig } from 'table';
import { pascalCase } from 'pascal-case';
import { Session } from '../../state/Session';
import {
  WALK_IN_CUSTOMER,
  Shift,
  DefaultPaymentTypes,
  PaymentTypeSaleSummary,
} from '@oolio-group/domain';
import { formatMoneyValue, translate } from '@oolio-group/localization';
import { isArray, startCase } from 'lodash';
import { generateAddress } from './address';
import { excludeOnAccountPaymentTypeSummary } from '@oolio-group/client-utils';

const BLANK_ROW_ENTRY = ['None'];

const CARD_AND_ONLINE_PAYMENTS: string[] = [
  DefaultPaymentTypes.CARD,
  DefaultPaymentTypes.ONLINE,
];

export const shiftTemplate = `
  <?xml version="1.0" encoding="UTF-8"?>
  <document>
    <align mode="center">
      <!-- Organization logo -->
      <line-feed />
      <!--<image density="d24">{{{orgLogo}}}</image> -->
      <!-- store -->
      <align mode="center">
        <bold>
          <text-line size="0:1">{{storeName}}</text-line>
        </bold>
        <line-feed />
        <text-line>{{storeAddress}}</text-line>
        <text-line>{{storePhone}}</text-line>
      </align>
      <under-line mode="two-point"></under-line>
      <align mode="center">
        <text-line size="0:0">{{{divider}}}</text-line>
      </align>
      <!-- header -->
      <line-feed />
      <align mode="center">
        <text-line>{{header}}</text-line>
      </align>
      <line-feed />
      <align mode="center">
        <text-line size="0:0">{{{divider}}}</text-line>
      </align>
      <line-feed />
      <!-- payments -->
      {{ #payments }}
        <bold>
          <text-line>{{ name }} Payments</text-line>
        </bold>
        <line-feed />
        <align mode="center">
          <text-line>{{ summary }}</text-line>
        </align>
        <line-feed />
        <align mode="center">
          <text-line size="0:0">{{{divider}}}</text-line>
        </align>
        <line-feed />
      {{ /payments }}
      <!-- Tax Summary -->
      <bold>
        <text-line>Tax Summary</text-line>
      </bold>
      <line-feed />
      <align mode="center">
        <text-line>{{taxSummaryDetails}}</text-line>
      </align>
      <line-feed />
      <align mode="center">
        <text-line size="0:0">{{{divider}}}</text-line>
      </align>
      <line-feed />
      <!-- Sales Summary -->
      <bold>
        <text-line>Sales Summary</text-line>
      </bold>
      <line-feed />
      <align mode="center">
        <text-line>{{salesSummaryDetails}}</text-line>
      </align>
      <line-feed />
      <align mode="center">
        <text-line size="0:0">{{{divider}}}</text-line>
      </align>
      <line-feed />
      <!-- Customers -->
      <bold>
        <text-line>Customers</text-line>
      </bold>
      <line-feed />
      <align mode="center">
        <text-line>{{customerTotals}}</text-line>
      </align>
      <line-feed />
      <align mode="center">
        <text-line size="0:0">{{{divider}}}</text-line>
      </align>
      <line-feed />
      <!-- Discounts -->
      {{ #discounts }}
      <bold>
        <text-line>Discounts</text-line>
      </bold>
      <line-feed />
      <align mode="center">
        <text-line size="0:0">{{{discounts}}}</text-line>
      </align>
      <line-feed />
      <align mode="center">
        <text-line size="0:0">{{{divider}}}</text-line>
      </align>
      <line-feed />
      {{ /discounts }}
      <!-- Void Summary -->
      <bold>
        <text-line>Void Summary</text-line>
      </bold>
      <line-feed />
      <align mode="center">
        <text-line>{{voidSummaryDetails}}</text-line>
      </align>
      <line-feed />
      <align mode="center">
        <text-line size="0:0">{{{divider}}}</text-line>
      </align>
      <line-feed />
      <!-- Product Type Summary -->
      <bold>
        <text-line>Product Type Summary</text-line>
      </bold>
      <line-feed />
      <align mode="center">
        <text-line>{{productTypeSummaryDetails}}</text-line>
      </align>
      <line-feed />
      <align mode="center">
        <text-line size="0:0">{{{divider}}}</text-line>
      </align>
      <line-feed />

      <!-- On Account Sales/Payment summary -->
      {{ #onAccountSummary }}
      <bold>
        <text-line>On Account</text-line>
      </bold>
      <line-feed />
      <align mode="center">
        <text-line size="0:0">{{{onAccountSummary}}}</text-line>
      </align>
      <line-feed />
      <align mode="center">
        <text-line size="0:0">{{{divider}}}</text-line>
      </align>
      <line-feed />
      {{ /onAccountSummary }}

      <!-- Shift Summary by hour -->
      {{ #shiftSummaryByHour }}
      <bold>
        <text-line>Hourly Summary</text-line>
      </bold>
      <line-feed />
      <align mode="center">
        <text-line size="0:0">{{{shiftSummaryByHour}}}</text-line>
      </align>
      <line-feed />
      <align mode="center">
        <text-line size="0:0">{{{divider}}}</text-line>
      </align>
      <line-feed />
      {{ /shiftSummaryByHour }}

      <!-- Footer Note -->
      <align mode="center">
        <text-line size="0:0">{{{footerNote}}}</text-line>
      </align>
      <line-feed />
    </align>
    <paper-cut />
  </document>
  `;

export const getPrintableBuffer = (
  shift: Shift,
  session: Session,
  currency: string,
) => {
  const payload = {
    storeName: session.currentStore?.name || session.currentVenue?.name,
    storeAddress: generateAddress(session),
    storePhone: session.currentVenue?.phone,
    header: generateHeaderDetails(shift),
    payments: generatePaymentDetails(shift, currency),
    taxSummaryDetails: generateTaxSummaryDetails(shift, currency),
    salesSummaryDetails: generateSalesSummaryDetails(shift, currency),
    productTypeSummaryDetails: generateProductTypeSummaryDetails(
      shift,
      currency,
    ),
    customerTotals: generateCustomerTotals(shift),
    voidSummaryDetails: generateVoidSummaryDetails(shift, currency),
    footerNote: 'Powered By Oolio',
    divider: divider() + '\n',
    discounts: generateDiscountsDetails(shift, currency),
    shiftSummaryByHour: generateShiftSummaryByHour(shift, currency),
    onAccountSummary: generateOnAccountSalesSummary(shift, currency),
  };

  // Generate buffer
  return EscPos.getBufferFromTemplate(
    shiftTemplate,
    payload,
  ) as unknown as Buffer;
};

// Building print components
/**
 * Money movement details section has two columns
 */
const config: TableUserConfig = {
  columns: {
    0: {
      width: 24,
      alignment: 'left',
      wrapWord: true,
    },
    1: {
      alignment: 'right',
      width: 18,
    },
  },
  border: getBorderCharacters('void'),
  columnDefault: {
    paddingLeft: 0,
    paddingRight: 0,
  },
  drawHorizontalLine: () => {
    return false;
  },
};

export const generateHeaderDetails = (shift: Shift): string => {
  const shiftTable = [
    ['Shift Type', pascalCase(shift.shiftType, { delimiter: ' ' })],
    [
      `Shift Number #${shift.shiftNumber}`,
      format(
        new Date(+(shift.closedAt || shift.createdAt)),
        'hh:mm a dd-MM-yy',
      ),
    ],
    ['Generated On', format(new Date(), 'hh:mm a dd-MM-yy')],
    ['Created By', shift.createdBy?.name],
    ['Closed By', shift.closedBy?.name],
    [
      'Device',
      shift.closedByDevice
        ? shift.closedByDevice.name
        : shift.createdByDevice.name,
    ],
  ];

  return table(shiftTable, config);
};

export const generateShiftSummaryByHour = (
  shift: Shift,
  currency: string,
): string | null => {
  if (shift.store?.showHourlySplit && shift.salesByHour?.length) {
    const salesTotal = shift.salesByHour?.reduce(
      (prevValue, currentValue) => prevValue + currentValue.amount,
      0,
    );
    const salesByHourRow = shift.salesByHour?.map(salesByHour => {
      const timeWithSalesCount = `${salesByHour.hour}(x${salesByHour.count})`;
      return [
        timeWithSalesCount,
        `${formatMoneyValue(+salesByHour.amount, currency)}`,
      ];
    });
    salesByHourRow.push(['Total', formatMoneyValue(salesTotal, currency)]);
    const data = table(salesByHourRow, config);
    return data;
  } else return null;
};

export const generateOnAccountSalesSummary = (
  shift: Shift,
  currency: string,
): string | null => {
  let data = '';
  if (
    shift.onAccountSales?.Payments.length ||
    shift.onAccountSales?.Sales.length
  ) {
    if (shift.onAccountSales?.Payments.length) {
      const paymentTotal = shift.onAccountSales.Payments?.reduce(
        (prevValue, currentValue) => prevValue + currentValue.amount,
        0,
      );
      const onAccountPayments = [
        [
          `Payments (x${shift.onAccountSales?.Payments.length})`,
          formatMoneyValue(paymentTotal, currency),
        ],
      ];
      onAccountPayments.push(
        ...shift.onAccountSales.Payments?.map(sale => {
          const customerNameWithPayment = `- ${startCase(
            sale.customer.name,
          )} (${sale.paymentType.name})`;
          return [
            customerNameWithPayment,
            `${formatMoneyValue(sale.amount, currency)}`,
          ];
        }),
      );
      data = table(onAccountPayments, config);
    }
    if (shift.onAccountSales?.Sales.length) {
      const saleTotal = shift.onAccountSales.Sales?.reduce(
        (prevValue, currentValue) => prevValue + currentValue.amount,
        0,
      );
      const onAccountSales = [
        [
          `Sales (x${shift.onAccountSales?.Sales.length})`,
          formatMoneyValue(saleTotal, currency),
        ],
      ];
      onAccountSales.push(
        ...shift.onAccountSales.Sales?.map(sale => {
          return [
            `- ${startCase(sale.customer.name)}`,
            `${formatMoneyValue(sale.amount, currency)}`,
          ];
        }),
      );
      data = data + table(onAccountSales, config);
    }
  }
  return data ?? null;
};

export const generatePaymentDetails = (
  shift: Shift,
  currency: string,
): Array<{ name: string; summary: string }> => {
  const salesByPaymentType = excludeOnAccountPaymentTypeSummary(
    shift.salesByPaymentType ?? [],
  ) as PaymentTypeSaleSummary[];
  const payments = salesByPaymentType.map(type => ({
    name: type.paymentType.name,
    summary: CARD_AND_ONLINE_PAYMENTS.includes(type.paymentType.name)
      ? table(
          // table for card and online
          [
            ['Transactions Count', type?.salesCount || 0],
            [
              'Total Collected',
              formatMoneyValue(+(type?.amount || 0), currency),
            ],
            ['- Tips', formatMoneyValue(+(type?.tips || 0), currency)],
            ...(type?.paymentSurcharge !== 0
              ? [
                  [
                    '- Payment Surcharges',
                    formatMoneyValue(+(type?.paymentSurcharge || 0), currency),
                  ],
                ]
              : []),
            ...(type?.roundingAmount !== 0
              ? [
                  [
                    '- Rounding',
                    formatMoneyValue(+(type?.roundingAmount || 0), currency),
                  ],
                ]
              : []),
            [
              '- Refunds',
              formatMoneyValue(-(type?.refundAmount || 0), currency),
            ],
            // FIXME: These values are incorrect as of now as they are estimated.
            // We will re-use them once we move to adyen balance.
            // [
            //   'Payment Fees',
            //   formatMoneyValue(-(type?.estimatedFees || 0), currency),
            // ],
            // ['Net Total', formatMoneyValue(+(type?.netDeposit || 0), currency)],
          ],
          config,
        )
      : table(
          // table for others
          [
            ['Transactions Count', type?.salesCount || 0],
            [
              'Total Collected',
              formatMoneyValue(+(type?.amount || 0), currency),
            ],
            ['- Tips', formatMoneyValue(+(type?.tips || 0), currency)],
            ...(type?.paymentSurcharge !== 0
              ? [
                  [
                    '- Payment Surcharges',
                    formatMoneyValue(+(type?.paymentSurcharge || 0), currency),
                  ],
                ]
              : []),
            ...(type?.roundingAmount !== 0
              ? [
                  [
                    '- Rounding',
                    formatMoneyValue(+(type?.roundingAmount || 0), currency),
                  ],
                ]
              : []),
            [
              '- Refunds',
              formatMoneyValue(-(type?.refundAmount || 0), currency),
            ],
            ['Counted', formatMoneyValue(+(type?.totalCounted || 0), currency)],
            [
              'Recorded',
              formatMoneyValue(+(type?.recordedAmount || 0), currency),
            ],
            ['Difference', formatMoneyValue(+(type?.variance || 0), currency)],
            [
              `Money In (x${type?.moneyInCount || 0})`,
              formatMoneyValue(+(type?.moneyIn || 0), currency),
            ],
            [
              `Money Out (x${type?.moneyOutCount || 0})`,
              formatMoneyValue(+(type?.moneyOut || 0), currency),
            ],
          ],
          config,
        ),
  }));

  return payments;
};

export const generateTaxSummaryDetails = (
  shift: Shift,
  currency: string,
): string => {
  const totalTax = shift.taxes.reduce(
    (sum, taxSummary) => +sum + +taxSummary.amount,
    0,
  );

  const taxLines = shift.taxes.map(taxSummary => [
    `${taxSummary.tax?.code} ${
      taxSummary.tax?.rate ? ' (' + taxSummary.tax?.rate + '%)' : ''
    }`,
    formatMoneyValue(+(taxSummary?.amount || 0), currency),
  ]);

  const shiftTable = [
    ...taxLines,
    ['Total', formatMoneyValue(totalTax, currency)],
  ];

  return table(shiftTable, config);
};

export const generateSalesSummaryDetails = (
  shift: Shift,
  currency: string,
): string => {
  const shiftTable = [
    ['Number of Sales', shift.totalSalesCount || 0],
    ['Transactions Count', shift.transactionsCount || 0],
    ['Refunds Count', +(shift.refundCount || 0)],
    ['Gross Sales', formatMoneyValue(+(shift.totalGrossSales || 0), currency)],
    ['- Returns', formatMoneyValue(-(shift.totalGrossRefund || 0), currency)],
    ['- Discounts', formatMoneyValue(-(shift.totalDiscount || 0), currency)],
    ['- Surcharges', formatMoneyValue(+(shift.totalSurcharge || 0), currency)],
    ['Net Sales', formatMoneyValue(+(shift.totalNetAmount || 0), currency)],
    ['Tax Amount', formatMoneyValue(+(shift.totalTax || 0), currency)],
    [
      'Sales Amount',
      formatMoneyValue(+(shift.totalSalesAmount || 0), currency),
    ],
    ['Tips', formatMoneyValue(+(shift.tips || 0), currency)],
    [
      'Cash Rounding',
      formatMoneyValue(+(shift.totalRoundingAmount || 0), currency),
    ],
    [
      'Payment Surcharges',
      formatMoneyValue(+(shift.totalPaymentSurcharge || 0), currency),
    ],
    ['Charges', formatMoneyValue(+(shift.otherCharges || 0), currency)],
    ['Total', formatMoneyValue(+(shift.totalAmount || 0), currency)],
    ['Sales Average', formatMoneyValue(+(shift.salesAverage || 0), currency)],
  ];

  if (shift.totalCostPrice) {
    // if totalCostPrice > 0, display cost of sales and profit
    shiftTable.splice(
      shiftTable.length,
      0,
      [
        'Cost of sales',
        formatMoneyValue(+(shift.totalCostPrice || 0), currency),
      ],
      ['Total Profit', formatMoneyValue(+(shift.profit || 0), currency)],
    );
  }

  return table(shiftTable, config);
};

export const generateProductTypeSummaryDetails = (
  shift: Shift,
  currency: string,
): string => {
  const totalSales =
    shift.salesByProductType?.reduce(
      (sum, summary) => +sum + +summary.amount,
      0,
    ) || 0;

  const summaryLines =
    shift.salesByProductType?.map(summary => [
      `${summary.productType?.name} (x${summary.count || 0})`,
      formatMoneyValue(+summary.amount, currency),
    ]) || [];

  const shiftTable = [
    ...summaryLines,
    ['Total', formatMoneyValue(+totalSales, currency)],
  ];

  return table(shiftTable, config);
};

export const generateCustomerTotals = (shift: Shift): string => {
  const customerCounts = {
    walkIn: 0,
    registered: 0,
  };

  const notRegisteredIndex = shift?.customers?.findIndex(
    customer => customer.id === WALK_IN_CUSTOMER,
  );

  if (notRegisteredIndex !== -1) {
    customerCounts.walkIn = shift?.customers?.[notRegisteredIndex]?.count;
  }

  customerCounts.registered =
    shift?.customers?.reduce((acc, customer, index) => {
      if (index === notRegisteredIndex) return acc;
      else return acc + customer.count;
    }, 0) || 0;

  const shiftTable = [
    ['Walk In', `${customerCounts.walkIn}`],
    ['Registered', `${customerCounts.registered}`],
  ];

  return table(shiftTable, config);
};

export const generateVoidSummaryDetails = (
  shift: Shift,
  currency: string,
): string => {
  let shiftTable = [BLANK_ROW_ENTRY];

  if (
    isArray(shift.voidedAmountByReason) &&
    shift.voidedAmountByReason.length > 0
  ) {
    shiftTable = shift.voidedAmountByReason.map(rowData => [
      translate(`enums.${rowData?.reason}`),
      formatMoneyValue(+rowData.amount, currency),
    ]);
  }

  return table(shiftTable, config);
};

export function generateDiscountsDetails(shift: Shift, currency: string) {
  if (!shift.discountByReward?.length) {
    return null;
  }

  return table(
    shift.discountByReward.map(discount => [
      discount.reward.rewardName,
      `${formatMoneyValue(discount.amount, currency)} (${discount.quantity})`,
    ]),
    config,
  );
}
