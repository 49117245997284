import { StyleSheet } from 'react-native';
import theme from '../../../../common/default-theme';

const styles = StyleSheet.create({
  tableContainer: {
    marginBottom: 20,
  },
  headerName: {
    flex: 1,
  },
  headerCount: {
    width: 102,
    marginRight: 10,
    textAlign: 'center',
  },
  headerUsers: {
    width: 102,
    marginRight: 34,
    textAlign: 'center',
  },
  cellRoleName: {
    flex: 1,
    paddingLeft: 12,
  },
  cellCount: {
    width: 102,
    marginRight: 10,
    paddingVertical: 12,
    textAlign: 'center',
    paddingHorizontal: 10,
    borderRadius: theme.radius.s,
    backgroundColor: theme.colors.grey1,
  },
});

export default styles;
