import { StyleSheet } from 'react-native';
import theme from '../../../../common/default-theme';

const styles = StyleSheet.create({
  container: {
    marginBottom: 8,
    borderRadius: theme.radius.s,
  },
  iconContainer: {
    width: 44,
    height: 44,
    marginRight: 10,
    alignItems: 'center',
    justifyContent: 'center',
  },
  tabTitle: {
    flex: 1,
    alignItems: 'center',
    flexDirection: 'row',
  },
  text: {
    flex: 1,
    color: theme.colors.white,
    fontFamily: theme.fonts.medium,
  },
  parentContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    borderRadius: theme.radius.s,
  },
  activeParent: {
    backgroundColor: theme.colors.white,
  },
  activeText: {
    color: theme.colors.white,
  },
  childrenContainer: {
    marginTop: 8,
    marginBottom: 16,
  },
  child: {
    width: '100%',
    paddingLeft: 54,
    color: theme.colors.grey6,
  },
  childText: {
    lineHeight: 20,
    paddingVertical: 6,
    color: theme.colors.grey6,
  },
  beta: {
    fontSize: 12,
    marginRight: 8,
    paddingVertical: 4,
    paddingHorizontal: 6,
    color: theme.colors.dark,
    textTransform: 'uppercase',
    borderRadius: theme.radius.s,
    fontFamily: theme.fonts.medium,
    backgroundColor: theme.colors.amber,
  },
});

export default styles;
