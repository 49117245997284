import { getLoyaltyUnit } from '@oolio-group/client-utils';
import {
  Icons,
  LoyaltySettings,
  RewardRule,
  StyleFn,
} from '@oolio-group/domain';
import React from 'react';
import { useFela } from 'react-fela';
import { Text, View, ViewStyle } from 'react-native';
import Button from '../../Button/Button';

const containerStyle: StyleFn = ({ theme, active }) => {
  let style: object = {
    width: '100%',
    paddingHorizontal: 16,
    paddingVertical: theme.padding.medium,
    borderRadius: 6,
    backgroundColor: theme.colors.white,
    ...theme.border.borderSolid,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  };
  if (active) {
    style = {
      ...style,
      borderColor: theme.colors.green,
      backgroundColor: theme.colors.successLightest,
    };
  }
  return style;
};

const rewardDescStyle: StyleFn = ({}) => ({
  flexShrink: 1,
});

const buttonGroupStyle: StyleFn = ({ theme }) => ({
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: theme.colors.greyLight,
  marginLeft: theme.padding.medium,
  borderRadius: theme.radius.small,
});

const buttonStyle: StyleFn = () => ({
  backgroundColor: 'transparent',
});

const buttonIconStyle = (disabled: boolean): StyleFn =>
  disabled
    ? ({ theme }) => ({
        color: theme.colors.paragraphLight,
      })
    : ({ theme }) => ({
        color: theme.colors.black,
      });

const descriptionStyle: StyleFn = ({ theme, disabled }) => ({
  flexShrink: 1,
  color: disabled ? theme.colors.textLight : theme.colors.grey2,
  marginTop: theme.padding.small,
  ...theme.font14Medium,
});

const pointStyle: StyleFn = ({ theme, active, disabled }) => ({
  ...theme.font14SemiBold,
  color: active
    ? theme.colors.green
    : disabled
    ? theme.colors.textLight
    : '#2684fc',
});

const additionalInfoStyle: StyleFn = ({ theme }) => ({
  ...theme.font14Medium,
  color: theme.colors.red,
  fontSize: 12,
  marginTop: theme.padding.small,
});

export interface RewardDisplayProps {
  loyaltySettings: Partial<LoyaltySettings>;
  reward: RewardRule;
  quantity: number;
  disabled?: boolean;
  onChange: (reward: RewardRule, quantity?: number) => void;
  style?: ViewStyle;
  additionalInfo?: string;
  multiSteps?: boolean;
}

const RewardDisplay: React.FC<RewardDisplayProps> = ({
  loyaltySettings,
  reward,
  quantity,
  disabled,
  onChange,
  style,
  additionalInfo,
  multiSteps,
}) => {
  const { css } = useFela({ active: quantity > 0, disabled });

  const increaseQuantity = () => {
    if (!disabled) {
      onChange(reward, quantity + 1);
    }
  };

  const decreaseQuantity = () => {
    if (quantity > 0) {
      onChange(reward, quantity - 1);
    }
  };

  return (
    <View style={[css(containerStyle), style]}>
      <View style={css(rewardDescStyle)}>
        <Text style={css(pointStyle)}>{`${
          reward.pointsRequired
        } ${getLoyaltyUnit(reward.pointsRequired, loyaltySettings)}`}</Text>
        <Text style={css(descriptionStyle)}>{reward.rewardName}</Text>
        {additionalInfo && (
          <Text style={css(additionalInfoStyle)} testID="additional-info-text">
            {additionalInfo}
          </Text>
        )}
      </View>
      <View style={css(buttonGroupStyle)}>
        {multiSteps ? (
          <>
            {quantity > 0 && <Text testID="quantity-text">{quantity}</Text>}
            <Button
              containerStyle={css(buttonStyle)}
              testID="next-step-button"
              onPress={() => onChange(reward)}
              disabled={!!disabled}
              icon={Icons.AngleRight}
              size="medium"
              iconProps={{
                style: css(buttonIconStyle(!!disabled)),
              }}
            />
          </>
        ) : (
          <>
            <Button
              containerStyle={css(buttonStyle)}
              testID="decrease-button"
              onPress={decreaseQuantity}
              disabled={quantity <= 0}
              icon={Icons.AngleDown}
              size="medium"
              iconProps={{
                style: css(buttonIconStyle(quantity <= 0)),
              }}
            />
            <Text testID="quantity-text">{quantity}</Text>
            <Button
              containerStyle={css(buttonStyle)}
              testID="increase-button"
              onPress={increaseQuantity}
              disabled={!!disabled}
              icon={Icons.AngleUp}
              size="medium"
              iconProps={{
                style: css(buttonIconStyle(!!disabled)),
              }}
            />
          </>
        )}
      </View>
    </View>
  );
};

export default RewardDisplay;
