import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import LoadingIndicator from '../../../../components/LoadingIndicator/LoadingIndicator';
import { Helmet } from 'react-helmet';
import { Styles } from '../styles/ProductsSummary.styles';
import { View, ScrollView } from 'react-native';
import { useTranslation } from '@oolio-group/localization';
import {
  DateRangeFilter,
  FilterValue,
  Widget,
  WidgetChartType,
  ColumnType,
  Filters,
} from '@oolio-group/domain';
import { ReportHeader } from '../UIComponents/ReportHeader';
import { Table, TableRef } from '../UIComponents/Table';
import { PieChart } from '../UIComponents/PieChart';
import { Filters as ReportFilter } from '../UIComponents/Filters';
import {
  GRANULARITY_FORMATS,
  transformFieldsToTableColumn,
} from '../reportsHelper';
import {
  ReportTableColumn,
  IMap,
  ExportType,
  Search,
  HelperText,
  PivotTableData,
  DropDownFilter,
} from '../types';
import { CubejsApi } from '@cubejs-client/core';

interface ReportProps {
  options: { loading: boolean };
  filterOptions: DropDownFilter[];
  filters: FilterValue;
  search: Search;
  widgets: Widget[];
  dateRangeFilter: DateRangeFilter;
  updateFilters: (filter: string, value: string[]) => void;
  updateSearch: (filter: string, value: string) => void;
  resetFilters: () => void;
  updateReport: () => void;
  updateDateRangeFilters: (value: DateRangeFilter) => void;
  cubejsApi: CubejsApi;
  allFilters: Filters;
  updateCount: number;
}

const TABLE_GRANULARITY_FORMATS: IMap<string> = {
  ...GRANULARITY_FORMATS,
  day: 'MMM dd, yyyy',
};

export const TaxesReport: React.FC<ReportProps> = ({
  options: { loading },
  filters,
  filterOptions,
  widgets,
  dateRangeFilter,
  search,
  updateFilters,
  updateSearch,
  updateReport,
  resetFilters,
  updateDateRangeFilters,
  cubejsApi,
  allFilters,
  updateCount,
}) => {
  const { translate } = useTranslation();
  const styles = Styles();
  const [showFilters, setShowFilters] = useState<boolean>(false);

  const toggleFilters = useCallback(() => {
    setShowFilters(value => !value);
  }, []);

  const tableWidget =
    widgets.find(widget => widget.chartType === WidgetChartType.TABLE) ||
    ({} as Widget);

  const [selectedColumns, setSelectedColumns] = useState<ReportTableColumn[]>(
    [],
  );

  const tableColumns: ReportTableColumn[] = useMemo(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const columns: any[] = [];
    if (tableWidget.query?.dimensions)
      columns.push(
        ...transformFieldsToTableColumn(tableWidget.query?.dimensions).map(
          col => ({
            ...col,
            type: ColumnType.STRING,
          }),
        ),
      );

    if (tableWidget.query?.measures)
      columns.push(
        ...transformFieldsToTableColumn(tableWidget.query?.measures),
      );
    return columns;
  }, [tableWidget.query]);

  useEffect(() => {
    setSelectedColumns(
      (tableColumns || []).filter(column => column.showByDefault),
    );
  }, [tableColumns]);

  const updateColumns = useCallback(
    (columnKeys: string[]) => {
      setSelectedColumns(
        tableColumns.filter(col => columnKeys.includes(col.title)),
      );
    },
    [tableColumns],
  );

  const tableWidgetRef = useRef<TableRef>({} as TableRef);
  const exportReport = useCallback((type = ExportType.CSV) => {
    if (type === ExportType.CSV)
      tableWidgetRef.current.exportData &&
        tableWidgetRef.current.exportData(`TaxesReport-${Date.now()}.csv`);
  }, []);

  const pieDataTransform = useCallback(
    (data: PivotTableData): PivotTableData => {
      const toReturn: PivotTableData = [];
      const pieWidget = widgets.find(
        widget => widget.name === 'Taxable Vs NonTaxable Sales',
      );
      const measures = pieWidget?.query.measures;

      if (Array.isArray(data)) {
        data.forEach(row => {
          measures?.forEach(measure => {
            toReturn.push({
              nameKey: translate(measure.text),
              valueKey: row[measure.key] || '0.00',
            });
          });
        });
      }

      return toReturn;
    },
    [translate, widgets],
  );

  return (
    <>
      <Helmet>
        <title>
          {translate('navigation.generalSettingsPageTitle', {
            appName: translate('appName'),
          })}
        </title>
      </Helmet>

      <ScrollView scrollEnabled={!showFilters} style={styles.pageStyle}>
        {showFilters ? (
          <View style={styles.filtersStyle}>
            <ReportFilter
              toggleFilters={toggleFilters}
              filterOptions={filterOptions}
              filters={filters}
              updateFilters={updateFilters}
              resetFilters={resetFilters}
            ></ReportFilter>
          </View>
        ) : null}
        <View style={styles.mainSectionStyle}>
          <View style={styles.headersStyle}>
            <ReportHeader
              allFilters={allFilters}
              toggleFilters={toggleFilters}
              updateReport={updateReport}
              exportReport={exportReport}
              filters={filters}
              search={search}
              updateSearch={updateSearch}
              columns={{ all: tableColumns, updateColumns, selectedColumns }}
              updateFilters={updateFilters}
              dateRangeFilter={dateRangeFilter}
              updateDateRangeFilters={updateDateRangeFilters}
              translationParentKey={'backOfficeTaxesReport'}
            />
          </View>
          <View style={styles.chartRowStyle}>
            {loading ? (
              <LoadingIndicator />
            ) : (
              <>
                {widgets
                  .filter(widget => widget.chartType !== WidgetChartType.TABLE)
                  .map(widget => {
                    let renderComp;

                    if (widget.name === 'Taxable Vs NonTaxable Sales') {
                      const pieKeys = {
                        name: 'nameKey',
                        value: 'valueKey',
                      };
                      renderComp = (
                        <PieChart
                          widget={widget}
                          helper={HelperText.TAXES}
                          keys={pieKeys}
                          dataTransformationFn={pieDataTransform}
                          columnType={widget.query.measures[0]?.type}
                          cubejsApi={cubejsApi}
                          updateCount={updateCount}
                        />
                      );
                    } else {
                      const pieKeys = {
                        name: widget.query.dimensions[0].key,
                        value: widget.query.measures[0].key,
                      };
                      renderComp = (
                        <PieChart
                          widget={widget}
                          helper={HelperText.TAXES}
                          keys={pieKeys}
                          legendWidth={30}
                          columnType={widget.query.measures[0]?.type}
                          cubejsApi={cubejsApi}
                          updateCount={updateCount}
                        />
                      );
                    }

                    return renderComp;
                  })}
              </>
            )}
          </View>
          <View style={styles.tableStyle}>
            {loading ? (
              <LoadingIndicator />
            ) : (
              <Table
                widget={tableWidget}
                columns={selectedColumns}
                helper={HelperText.TAXES}
                pageSize={5}
                granularityFormats={TABLE_GRANULARITY_FORMATS}
                ref={tableWidgetRef}
                filters={filters}
                cubejsApi={cubejsApi}
                updateCount={updateCount}
              ></Table>
            )}
          </View>
        </View>
      </ScrollView>
    </>
  );
};

export default TaxesReport;
