import { Icons, OrderItem } from '@oolio-group/domain';
import { useModal } from '@oolio-group/rn-use-modal';
import React from 'react';
import { StyleSheet, View } from 'react-native';
import CartProduct from '../../POS/Cart/CartProduct/CartProduct';
import TitleBar from '../../TitleBar/TitleBar';
import { PickerModal, PickerOption } from '../Picker/Picker';
import defaultTheme from '../../../common/default-theme';
import IconButton from '../../Button/IconButton';

interface SwitchCourseProp {
  title?: string;
  testID?: string;
  options: Array<PickerOption>;
  onSelect: (value: string) => void;
  value: string;
  item: OrderItem;
}

export const SwitchCourse: React.FC<SwitchCourseProp> = ({
  title,
  options,
  onSelect,
  value,
  testID,
  item,
}) => {
  const { closeModal } = useModal();
  return (
    <View style={styles.container} testID={testID}>
      <TitleBar
        primary
        title={title}
        titleLeft={
          <IconButton
            icon={Icons.Times}
            onPress={closeModal}
            iconColor={defaultTheme.colors.black1}
            containerStyle={styles.iconContainerStyle}
          />
        }
        testID="title"
        containerStyle={styles.titleContainer}
        labelStyle={styles.labelContainerStyle}
      />
      <View style={styles.itemContainer}>
        <CartProduct minimised testID="product" product={item} isSelected />
      </View>
      <PickerModal
        value={value}
        onSelect={value => {
          onSelect(value);
          closeModal();
        }}
        options={options}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  itemContainer: {
    padding: defaultTheme.spacing.m,
    backgroundColor: defaultTheme.colors.white,
    borderRadius: defaultTheme.radius.m,

    marginBottom: 8,
  },
  titleContainer: {
    backgroundColor: defaultTheme.colors.white,

    marginBottom: 8,
  },
  iconContainerStyle: {
    position: 'absolute',
    top: 10,
    left: 10,
  },
  container: {
    width: 320,
    borderRadius: defaultTheme.radius.m,
    alignSelf: 'center',
    justifyContent: 'center',
  },
  labelContainerStyle: {
    color: defaultTheme.colors.black1,
    fontSize: defaultTheme.fontSize.m,
  },
});
