import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  filters: {
    marginBottom: 20,
    flexDirection: 'row',
    alignItems: 'center',
  },
  search: {
    flex: 1,
    marginRight: 10,
  },
  headerCategory: {
    width: 228,
    marginRight: 10,
  },
  cellCategory: {
    flex: 1,
    marginRight: 10,
    paddingLeft: 12,
  },
  // Details
  btnTranslate: {
    marginLeft: 10,
    marginTop: 'auto',
  },
  tableContainer: {
    marginTop: 20,
    zIndex: -1,
  },
  icon: {
    paddingLeft: 12,
  },
  cellProduct: {
    flex: 1,
    paddingLeft: 12,
  },
});

export default styles;
