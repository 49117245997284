import React, { useCallback } from 'react';
import { View, ScrollView, Platform, Linking } from 'react-native';
import { NavMenuProps } from '../../../../state/navigation';
import NavigationTab from '../NavigationTab/NavigationTab';
import { useIntercom } from '../../../../hooks/Intercom/useIntercom';
import { useSession } from '../../../../hooks/app/useSession';
import { useTranslation } from '@oolio-group/localization';

export interface NavigationMenuProps {
  active?: string;
  menu: Array<NavMenuProps>;
  onNavigate?: (route: string) => void;
  collapsed: boolean;
}

const NavigationMenu: React.FC<NavigationMenuProps> = ({
  menu,
  active,
  onNavigate,
  collapsed,
}) => {
  const activeMenu = active ? active.split('::')[0] : undefined;
  const selectedChild = active ? active.split('::')[1] : undefined;
  const { translate } = useTranslation();
  const [session] = useSession();
  const Intercom = useIntercom();

  const onPressSupport = useCallback(() => {
    const IntercomUser = {
      email: session?.user?.email || '',
      userId: session?.user?.id || '',
      name: session?.user?.name || '',
      customAttributes: {
        app: session?.activeApp || '',
      },
      companies: [
        {
          ...Platform.select({
            web: {
              companyId: session?.currentOrganization?.id || '',
            },
            native: {
              id: session?.currentOrganization?.id || '',
            },
          }),
          name: session?.currentOrganization?.name || '',
          customAttributes: {
            venue: session?.currentVenue?.name || '',
            store: session?.currentStore?.name || '',
            abn: session?.currentOrganization?.businessIdentifier || '',
          },
        },
      ],
    };
    Intercom.start(IntercomUser, Intercom.show);
  }, [Intercom, session]);

  const handleOnPress = useCallback(
    (tab: NavMenuProps, route: string) => {
      if (!tab.openLink) {
        onNavigate && onNavigate(route);
        return;
      }

      if (Platform.OS === 'web') {
        window.open(tab.openLink as string, '_blank');
      } else {
        Linking.openURL(tab.openLink as string);
      }
    },
    [onNavigate],
  );

  return (
    <ScrollView showsVerticalScrollIndicator={false}>
      <View>
        {menu.map((tab, i) => {
          return (
            <NavigationTab
              key={i}
              active={activeMenu === tab.title}
              title={tab.title}
              icon={tab.icon}
              subnav={tab.children}
              subroute={tab.title === activeMenu ? tab.route : []}
              onPress={route => handleOnPress(tab, route)}
              onSelectSubNav={onNavigate}
              selected={
                selectedChild
                  ? selectedChild
                  : tab.route && tab.route.length > 0
                  ? tab.route?.[0]?.routing
                  : tab.children[0]
              }
              collapsed={collapsed}
              beta={tab.beta}
              externalLink={!!tab.openLink}
            />
          );
        })}
        <NavigationTab
          active={false}
          onPress={onPressSupport}
          icon="comment-alt-message"
          title={translate('drawer.support')}
          collapsed={collapsed}
        />
      </View>
    </ScrollView>
  );
};

export default NavigationMenu;
