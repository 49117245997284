import { StyleSheet } from 'react-native';
import theme from '../../common/default-theme';
import scale from '../../common/theme';

export const styles = StyleSheet.create({
  popupButton: {
    minWidth: 44,
    height: 44,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: theme.radius.s,
    backgroundColor: theme.colors.blue,
  },
  backgroundStyle: {
    width: '100%',
    position: 'absolute',
    height: scale.deviceHeight,
  },
  container: {
    width: 240,
    backgroundColor: theme.colors.white,
  },
  // eslint-disable-next-line react-native/no-color-literals
  arrowHidden: {
    width: 0,
    height: 0,
  },
  titleText: {
    fontSize: 14,
    lineHeight: 20,
    paddingTop: 10,
    paddingBottom: 12,
    paddingHorizontal: 12,
    color: theme.colors.primary,
    fontFamily: theme.fonts.medium,
  },
  optionsContainer: {
    padding: 10,
    borderRadius: theme.radius.l,
    backgroundColor: theme.colors.white,
    ...theme.shadow.strong,
  },
  optionContainer: {
    height: 44,
    alignItems: 'center',
    borderRadius: theme.radius.s,
    justifyContent: 'space-between',
    shadowOpacity: 0,
  },
  optionLabelStyle: {
    fontSize: 14,
    textTransform: 'none',
    color: theme.colors.black1,
    fontFamily: theme.fonts.medium,
  },
});

export default styles;
