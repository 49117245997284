import React, { useState, useCallback, useEffect } from 'react';
import { View, Text, KeyboardAvoidingView, StyleSheet } from 'react-native';
import { Customer } from '@oolio-group/domain';
import {
  isValidEmail,
  isValidName,
  isValidNumber,
} from '../../../utils/validator';
import { useTranslation, usePhoneNumber } from '@oolio-group/localization';
import { useNotification } from '../../../hooks/Notification';
import { useModal } from '@oolio-group/rn-use-modal';
import { isIos } from '../../../common/theme';
import { useSession } from '../../../hooks/app/useSession';
import theme from '../../../common/default-theme';
import InputText from '../../Shared/Inputs/InputText';
import InputEmail from '../../Shared/Inputs/InputEmail';
import InputPhone from '../../Shared/Inputs/InputPhone';
import TreatButton from '../../Shared/TreatButton/TreatButton';
import { DEFAULT_COUNTRY_CODE } from '../../../constants';
import { useCustomers } from '../../../hooks/orders/useCustomers';
export interface AddCustomerProps {
  name?: string;
  customer?: Customer;
  // If orderId provided (in open orders screen), the customer can be assigned to a specific order (useCart will use this order id)
  orderId?: string;
  isEditing?: boolean;
  assignCustomerToOrder?: (customer: Customer) => void;
  onSuccess?: () => void;
}

const styles = StyleSheet.create({
  container: {
    ...theme.shadow.strong,
    borderRadius: theme.radius.l,
    backgroundColor: theme.colors.white,
    overflow: 'hidden',
    width: 590,
    height: 540,
    alignSelf: 'center',
  },

  title: {
    paddingTop: 40,
    paddingBottom: 30,
    paddingHorizontal: 42,
  },

  titleText: {
    fontSize: 16,
    lineHeight: 16,
    fontFamily: theme.fonts.medium,
    color: theme.colors.states.neutral,
  },

  contentContainer: {
    flex: 1,
    paddingHorizontal: 30,
  },

  subAddressContainerText: {
    width: 150,
  },

  pinCodeAddressContainerText: {
    width: 100,
  },

  formInputContainerStyle: {
    width: 280,
  },

  actions: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    marginTop: 40,
    marginBottom: 30,
    paddingHorizontal: 30,
    backgroundColor: theme.colors.white,
  },
});

interface CustomerForm {
  id?: string;
  firstName: string;
  lastName: string;
  line1: string;
  line2: string;
  email: string;
  city: string;
  suburb: string;
  postalCode: string;
  country: string;
  phone: string;
}

const AddCustomer: React.FC<AddCustomerProps> = ({
  name,
  customer,
  isEditing = false,
  assignCustomerToOrder,
  onSuccess,
}) => {
  const { translate } = useTranslation();
  const { showNotification } = useNotification();
  const { closeModal } = useModal();
  const [errorMessage, setErrorMessage] = useState('');
  const { getFullFormattedPhoneNumber } = usePhoneNumber();
  const [session] = useSession();

  const { country = DEFAULT_COUNTRY_CODE } = session?.currentOrganization || {};

  const {
    line1 = '',
    line2 = '',
    city = '',
    suburb = '',
    postalCode = '',
    isoCountryCode = country,
  } = customer?.preferredAddress || {};

  const [form, setForm] = useState<CustomerForm>({
    phone: customer?.phoneNumber || '',
    email: customer?.email || '',
    line1,
    line2,
    city,
    suburb,
    postalCode,
    country: isoCountryCode,
    firstName:
      isEditing && customer?.firstName
        ? customer.firstName
        : name?.split(' ')[0] ?? '',
    lastName:
      isEditing && customer?.firstName
        ? customer.lastName
        : name?.split(' ')[1] ?? '',
  });

  const isUK = form.country === 'GB';

  const { addNewCustomer, updateCustomer, error, loading } = useCustomers();

  const isPhoneValid = isValidNumber(
    getFullFormattedPhoneNumber(form.country, form.phone),
  );

  useEffect(() => {
    if (error) {
      showNotification({
        error: true,
        message: error,
      });
    }
  }, [error, showNotification]);

  useEffect(() => {
    if (errorMessage !== '') {
      showNotification({ error: true, message: errorMessage });
    }
  }, [errorMessage, showNotification, translate]);

  const onChangeCountry = useCallback((country: string) => {
    setForm(form => ({
      ...form,
      country,
    }));
  }, []);

  const onChangeFormInput = useCallback(
    (prop: string, value: string) => {
      setForm(form => ({
        ...form,
        [prop]: value,
      }));
      if (errorMessage) {
        setErrorMessage('');
      }
    },
    [errorMessage],
  );

  const onAddNewCustomer = useCallback(async () => {
    const { firstName, lastName, email, phone } = form;
    if (firstName === '' && lastName === '') {
      setErrorMessage(translate('customer.emptyNameMessage'));
    } else if (email === '' && phone.length === 0) {
      setErrorMessage(translate('customer.emptyEmailandPhoneMessage'));
      return;
    } else if (email && !isValidEmail(email)) {
      setErrorMessage(translate('customer.invalidEmailMessage'));
      return;
    } else if (phone && !isPhoneValid) {
      setErrorMessage(translate('customer.invalidPhoneMessage'));
      return;
    } else {
      const preferredAddress = {
        line1: form.line1,
        line2: form.line2,
        city: form.city,
        suburb: form.suburb,
        postalCode: form.postalCode,
        isoCountryCode: form.country,
      };
      const newCustomer = await addNewCustomer({
        firstName,
        lastName,
        email,
        phone: getFullFormattedPhoneNumber(form.country, form.phone),
        phoneNumber: form.phone,
        preferredAddress,
      });
      if (newCustomer?.id) {
        onSuccess && onSuccess();
        assignCustomerToOrder && assignCustomerToOrder(newCustomer);
        closeModal();
      }
    }
  }, [
    form,
    isPhoneValid,
    translate,
    addNewCustomer,
    getFullFormattedPhoneNumber,
    onSuccess,
    assignCustomerToOrder,
    closeModal,
  ]);

  const onEditCustomer = useCallback(async () => {
    const { firstName, lastName, email, phone } = form;
    if (firstName === '' && lastName === '') {
      setErrorMessage(translate('customer.emptyNameMessage'));
    } else if (!email && !phone) {
      setErrorMessage(translate('customer.emptyEmailandPhoneMessage'));
      return;
    } else if (email && !isValidEmail(email)) {
      setErrorMessage(translate('customer.invalidEmailMessage'));
      return;
    } else if (phone && !isPhoneValid) {
      setErrorMessage(translate('customer.invalidPhoneMessage'));
      return;
    } else {
      const preferredAddress = {
        line1: form.line1,
        line2: form.line2,
        city: form.city,
        suburb: form.suburb,
        postalCode: form.postalCode,
        isoCountryCode: form.country,
      };
      const updatedCustomer = await updateCustomer({
        id: customer?.id ?? '',
        firstName,
        lastName,
        email,
        phone: getFullFormattedPhoneNumber(form.country, form.phone),
        phoneNumber: form.phone,
        preferredAddress,
      });
      onSuccess && onSuccess();
      assignCustomerToOrder &&
        updatedCustomer &&
        assignCustomerToOrder(updatedCustomer);
      closeModal();
    }
  }, [
    form,
    isPhoneValid,
    translate,
    updateCustomer,
    customer,
    getFullFormattedPhoneNumber,
    onSuccess,
    assignCustomerToOrder,
    closeModal,
  ]);

  const onSave = useCallback(async () => {
    if (isEditing) {
      onEditCustomer();
    } else {
      onAddNewCustomer();
    }
  }, [isEditing, onEditCustomer, onAddNewCustomer]);

  return (
    <KeyboardAvoidingView behavior={isIos ? 'padding' : undefined}>
      <View style={styles.container}>
        <View style={styles.title}>
          <Text
            style={[
              styles.titleText,
              {
                color: isEditing
                  ? theme.colors.states.neutral
                  : theme.colors.states.positive,
              },
            ]}
          >
            {translate(
              isEditing ? 'customer.editCustomer' : 'customer.addNewCustomer',
            )}
          </Text>
        </View>
        <View style={styles.contentContainer}>
          <View style={theme.forms.row}>
            <InputText
              title={translate('form.firstName')}
              value={form.firstName}
              onChangeText={text => onChangeFormInput('firstName', text)}
              isVerified={isValidName(form.firstName)}
              placeholder={'First Name'}
              containerStyle={theme.forms.inputHalf}
              testID="customer-first-name"
            />
            <InputText
              title={translate('form.lastName')}
              value={form.lastName}
              onChangeText={text => onChangeFormInput('lastName', text)}
              isVerified={isValidName(form.lastName)}
              placeholder={'Last Name'}
              containerStyle={theme.forms.inputHalf}
              testID="customer-last-name"
            />
          </View>
          <View style={theme.forms.row}>
            <InputPhone
              testID="customer-phone"
              title={translate('form.phoneNumber')}
              value={form.phone}
              onChangeText={text => onChangeFormInput('phone', text)}
              placeholder={translate(
                isUK ? 'form.phoneNumberHintUK' : 'form.phoneNumberHint',
              )}
              containerStyle={theme.forms.inputHalf}
              onPressCountry={onChangeCountry}
              defaultCountry={form.country}
            />
            <InputEmail
              title={translate('common.emailAddress')}
              value={form.email}
              onChangeText={text => onChangeFormInput('email', text)}
              placeholder={'Email'}
              containerStyle={theme.forms.inputHalf}
              testID="customer-email"
            />
          </View>

          <View style={theme.forms.row}>
            <InputText
              title={translate(
                isUK ? 'form.streetAddress' : 'form.addressLine1',
              )}
              value={form.line1}
              onChangeText={onChangeFormInput.bind(null, 'line1')}
              placeholder={'Address Line 1'}
              containerStyle={
                !isUK ? theme.forms.inputHalf : theme.forms.inputFluid
              }
              testID="customer-address-1"
            />
            {!isUK ? (
              <InputText
                title={translate('form.addressLine2')}
                value={form.line2}
                onChangeText={onChangeFormInput.bind(null, 'line2')}
                placeholder={'Address Line 2'}
                containerStyle={theme.forms.inputHalf}
                testID="customer-address-1"
              />
            ) : null}
          </View>
          <View style={theme.forms.row}>
            <InputText
              title={translate(isUK ? 'form.town' : 'form.city')}
              value={form.city}
              onChangeText={onChangeFormInput.bind(null, 'city')}
              placeholder={translate(isUK ? 'form.town' : 'form.city')}
              containerStyle={theme.forms.inputHalf}
              testID="customer-suburb"
            />
            <InputText
              title={translate('form.postCode')}
              value={form.postalCode}
              onChangeText={onChangeFormInput.bind(null, 'postalCode')}
              placeholder={translate(
                isUK ? 'form.postalCodeHintUK' : 'form.postalCodeHint',
              )}
              containerStyle={theme.forms.inputHalf}
              testID="customer-postcode"
            />
          </View>
        </View>
        <View style={styles.actions}>
          <TreatButton
            type="cancel"
            testID="btn-dismiss"
            disabled={loading}
            onPress={closeModal}
            label={translate('button.dismiss')}
          />
          <TreatButton
            type={isEditing ? 'neutral' : 'positive'}
            testID="btn-save"
            onPress={onSave}
            label={
              isEditing
                ? translate('button.saveChanges')
                : translate('productSettings.create')
            }
            // eslint-disable-next-line react-native/no-inline-styles
            containerStyle={{ marginLeft: 10 }}
            isLoading={loading}
          />
        </View>
      </View>
    </KeyboardAvoidingView>
  );
};

export default AddCustomer;
