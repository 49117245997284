import React, { FC, useCallback, useMemo, useState } from 'react';
import {
  View,
  Text,
  TouchableOpacity,
  KeyboardAvoidingView,
} from 'react-native';
import { useModal } from '@oolio-group/rn-use-modal';
import { useCurrency } from '@oolio-group/localization';
import { addToValue } from '../../../../utils/keypadHelper';
import styles from './NumberPad.styles';
import theme from '../../../../common/default-theme';
import Icon from '../../../Icon/Icon';
import TreatButton from '../../TreatButton/TreatButton';
import SegmentControl from '../../SegmentControl/SegmentControl';
import DenominationInput from './Denominations';

export interface NumberPadProps {
  title: string;
  amount: string;
  showDenominations?: boolean;
  onCancel?: () => void;
  onSubmit?: (value: number) => void;
}

const NumberPad: FC<NumberPadProps> = ({
  title,
  amount,
  showDenominations,
  onCancel,
  onSubmit,
}: NumberPadProps) => {
  const { closeModal } = useModal();
  const { formatCurrency, decimalSeparator, unFormatCurrency } = useCurrency();

  const [value, setValue] = useState<string>(amount);
  const [inputType, setInputType] = useState<string>('total');

  const numbers = useMemo(() => {
    return ['1', '2', '3', '4', '5', '6', '7', '8', '9'];
  }, []);

  const inputTabs = useMemo(() => {
    return [
      {
        label: 'Total',
        value: 'total',
      },
      {
        label: 'Currency',
        value: 'currency',
      },
    ];
  }, []);

  const onPressClear = useCallback(() => {
    setValue('');
  }, []);

  const onPressCancel = useCallback(() => {
    if (onCancel) {
      onPressClear();
      onCancel();
    } else {
      closeModal();
    }
  }, [closeModal, onCancel, onPressClear]);

  const onPressNumber = useCallback(
    (key: string): void => {
      setValue(value => addToValue(value, key, decimalSeparator));
    },
    [decimalSeparator],
  );

  const onChangeInputType = useCallback((e: string) => {
    setInputType(e);
    setValue('0');
  }, []);

  const onPressSubmit = useCallback(() => {
    onSubmit && onSubmit(unFormatCurrency(value));
  }, [onSubmit, value, unFormatCurrency]);

  return (
    <View style={styles.background}>
      <View style={styles.container}>
        <KeyboardAvoidingView behavior="position">
          <View style={styles.header}>
            <TouchableOpacity style={styles.btnModal} onPress={onPressCancel}>
              <Icon name="arrow-left" size={20} color={theme.colors.black1} />
            </TouchableOpacity>
            <Text numberOfLines={1} style={styles.title}>
              {title}
            </Text>
          </View>
          <View style={styles.body}>
            <View style={styles.input}>
              <Text style={styles.inputText}>{formatCurrency(+value)}</Text>
              <TouchableOpacity style={styles.btnClear} onPress={onPressClear}>
                <Icon
                  name="times-circle"
                  size={20}
                  color={theme.colors.white}
                />
              </TouchableOpacity>
            </View>
            {showDenominations ? (
              <SegmentControl
                secondary
                tabs={inputTabs}
                onPress={e => onChangeInputType(e)}
                selectedTab={inputType}
                containerStyle={styles.segments}
              />
            ) : null}
            {inputType === 'currency' && showDenominations ? (
              <DenominationInput onChange={setValue} />
            ) : (
              <View>
                <View style={styles.numpad}>
                  {numbers.map((number, i) => (
                    <TouchableOpacity
                      key={i}
                      style={styles.btnNumber}
                      onPress={() => onPressNumber(number)}
                    >
                      <Text style={styles.btnNumberText}>{number}</Text>
                    </TouchableOpacity>
                  ))}
                </View>
                <View style={styles.numpad}>
                  <TouchableOpacity
                    onPress={() => onPressNumber('0')}
                    style={[styles.btnNumber, styles.btnZero]}
                  >
                    <Text style={styles.btnNumberText}>0</Text>
                  </TouchableOpacity>
                  <TouchableOpacity
                    style={styles.btnNumber}
                    onPress={() => onPressNumber(decimalSeparator)}
                  >
                    <Text style={styles.btnNumberText}>{decimalSeparator}</Text>
                  </TouchableOpacity>
                </View>
              </View>
            )}
            <View style={styles.actions}>
              <TreatButton
                type="positive"
                label="Confirm"
                onPress={onPressSubmit}
              />
            </View>
          </View>
        </KeyboardAvoidingView>
      </View>
    </View>
  );
};

export default NumberPad;
