import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import LoadingIndicator from '../../../../components/LoadingIndicator/LoadingIndicator';
import { Helmet } from 'react-helmet';
import { Styles } from '../styles/ProductsSummary.styles';
import { View, ScrollView } from 'react-native';
import { useTranslation } from '@oolio-group/localization';
import {
  DateRangeFilter,
  Filters,
  FilterValue,
  Widget,
  WidgetChartType,
} from '@oolio-group/domain';
import { ReportHeader } from '../UIComponents/ReportHeader';
import { Table, TableRef } from '../UIComponents/Table';
import { Filters as ReportFilter } from '../UIComponents/Filters';
import { BarChart } from '../UIComponents/BarChart';
import {
  GRANULARITY_FORMATS,
  transformFieldsToTableColumn,
} from '../reportsHelper';
import {
  ReportTableColumn,
  IMap,
  ExportType,
  Search,
  PivotTableData,
  HelperText,
  DropDownFilter,
} from '../types';
import { upperCase } from 'upper-case';
import { columnStyleOptions } from '../../../../components/DataGrid/DataGrid';
import { CubejsApi } from '@cubejs-client/core';

interface ReportProps {
  options: { loading: boolean };
  filterOptions: DropDownFilter[];
  filters: FilterValue;
  search: Search;
  widgets: Widget[];
  dateRangeFilter: DateRangeFilter;
  updateFilters: (filter: string, value: string[]) => void;
  updateSearch: (filter: string, value: string) => void;
  resetFilters: () => void;
  updateReport: () => void;
  updateDateRangeFilters: (value: DateRangeFilter) => void;
  cubejsApi: CubejsApi;
  allFilters: Filters;
  updateCount: number;
}

const TABLE_GRANULARITY_FORMATS: IMap<string> = {
  ...GRANULARITY_FORMATS,
  day: 'MMM dd, yyyy',
};

export const ProductsSummaryReport: React.FC<ReportProps> = ({
  options: { loading },
  filters,
  filterOptions,
  widgets,
  dateRangeFilter,
  search,
  updateFilters,
  updateSearch,
  updateReport,
  resetFilters,
  updateDateRangeFilters,
  cubejsApi,
  allFilters,
  updateCount,
}) => {
  const { translate } = useTranslation();
  const styles = Styles();
  const [showFilters, setShowFilters] = useState<boolean>(false);

  const toggleFilters = useCallback(() => {
    setShowFilters(value => !value);
  }, []);

  const tableWidget =
    widgets.find(widget => widget.chartType === WidgetChartType.TABLE) ||
    ({} as Widget);

  const [selectedColumns, setSelectedColumns] = useState<ReportTableColumn[]>(
    [],
  );

  const tableColumns: ReportTableColumn[] = useMemo(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const columns: any[] = [];
    if (tableWidget.query?.dimensions)
      columns.push(
        ...transformFieldsToTableColumn(tableWidget.query?.dimensions).map(
          field => ({
            ...field,
            style: columnStyleOptions.REGULAR,
            dataKey: field.title,
          }),
        ),
      );

    if (tableWidget.query?.measures)
      columns.push(
        ...transformFieldsToTableColumn(tableWidget.query?.measures).map(
          field => ({
            ...field,
            dataKey: field.title,
          }),
        ),
      );
    return columns;
  }, [tableWidget.query]);

  useEffect(() => {
    setSelectedColumns(
      (tableColumns || []).filter(column => column.showByDefault),
    );
  }, [tableColumns]);

  const updateColumns = useCallback(
    (columnKeys: string[]) => {
      setSelectedColumns(
        tableColumns.filter(col => columnKeys.includes(col.title)),
      );
    },
    [tableColumns],
  );

  const tableWidgetRef = useRef<TableRef>({} as TableRef);
  const exportReport = useCallback((type = ExportType.CSV) => {
    if (type === ExportType.CSV)
      tableWidgetRef.current.exportData &&
        tableWidgetRef.current.exportData(
          `ProductsSummaryReport-${Date.now()}.csv`,
        );
  }, []);

  const tableDataTransformationFn = useCallback(
    (
      pivotTableData: PivotTableData,
      columns: ReportTableColumn[],
    ): PivotTableData => {
      const aggregatedRevenue = pivotTableData.reduce(
        (total, row) => total + +row['OrderItems.revenue'],
        0,
      );
      const aggregatedQuantity = pivotTableData.reduce(
        (total, row) => total + +row['OrderItems.quantity'],
        0,
      );
      return pivotTableData.map(row => {
        return columns.reduce((acc, { key, title }) => {
          switch (title) {
            case 'backOfficeProductsSummary.SalesPercent':
              return {
                ...acc,
                [title]:
                  aggregatedRevenue === 0
                    ? 0
                    : ((100 * +row[key]) / aggregatedRevenue).toFixed(2) || 0,
              };
            case 'backOfficeProductsSummary.QuantityPercent':
              return {
                ...acc,
                [title]:
                  aggregatedQuantity === 0
                    ? 0
                    : ((100 * +row[key]) / aggregatedQuantity).toFixed(2) || 0,
              };
            default:
              return { ...acc, [title]: row[key] || '' };
          }
        }, {});
      });
    },
    [],
  );

  return (
    <>
      <Helmet>
        <title>
          {translate('navigation.generalSettingsPageTitle', {
            appName: translate('appName'),
          })}
        </title>
      </Helmet>

      <ScrollView scrollEnabled={!showFilters} style={styles.pageStyle}>
        {showFilters ? (
          <View style={styles.filtersStyle}>
            <ReportFilter
              toggleFilters={toggleFilters}
              filterOptions={filterOptions}
              filters={filters}
              updateFilters={updateFilters}
              resetFilters={resetFilters}
            ></ReportFilter>
          </View>
        ) : null}
        <View style={styles.mainSectionStyle}>
          <View style={styles.headersStyle}>
            <ReportHeader
              allFilters={allFilters}
              toggleFilters={toggleFilters}
              updateReport={updateReport}
              exportReport={exportReport}
              filters={filters}
              search={search}
              updateSearch={updateSearch}
              columns={{ all: tableColumns, updateColumns, selectedColumns }}
              updateFilters={updateFilters}
              dateRangeFilter={dateRangeFilter}
              updateDateRangeFilters={updateDateRangeFilters}
              translationParentKey={'backOfficeProductsSummary'}
            />
          </View>
          <View style={styles.chartRowStyle}>
            {loading ? (
              <LoadingIndicator />
            ) : (
              <>
                {widgets
                  .filter(widget => widget.chartType !== WidgetChartType.TABLE)
                  .map(widget => {
                    let renderComp;
                    switch (widget.chartType) {
                      case WidgetChartType.BAR:
                        const barKeys = {
                          name: widget.query.dimensions[0].key,
                          value: widget.query.measures[0].key,
                        };
                        renderComp = (
                          <BarChart
                            widget={widget}
                            helper={HelperText.PRODUCTS_SUMMARY}
                            keys={barKeys}
                            widgetName={upperCase(
                              translate(
                                `backOfficeProductsSummary.${widget.name}`,
                              ),
                            )}
                            cubejsApi={cubejsApi}
                            updateCount={updateCount}
                          />
                        );
                        break;
                    }

                    return renderComp;
                  })}
              </>
            )}
          </View>
          <View style={styles.tableStyle}>
            {loading ? (
              <LoadingIndicator />
            ) : (
              <Table
                widget={tableWidget}
                columns={selectedColumns}
                helper={HelperText.PRODUCTS_SUMMARY}
                pageSize={5}
                granularityFormats={TABLE_GRANULARITY_FORMATS}
                ref={tableWidgetRef}
                filters={filters}
                dataTransformationFn={tableDataTransformationFn}
                cubejsApi={cubejsApi}
                updateCount={updateCount}
              ></Table>
            )}
          </View>
        </View>
      </ScrollView>
    </>
  );
};

export default ProductsSummaryReport;
