/* eslint-disable react-native/no-inline-styles */
import React, { useCallback, useEffect, useState } from 'react';
import { View, StyleSheet } from 'react-native';
import {
  useTranslation,
  getCountry,
  getCountryCodeByPhoneCode,
  Timezone,
  TIMEZONES_MAP,
  getBusinessIdentifierType,
} from '@oolio-group/localization';
import { DEFAULT_COUNTRY_CODE } from '../../../constants';
import { ImageUploadInput, UpdateOrganizationInput } from '@oolio-group/domain';
import { useNotification } from '../../../hooks/Notification';
import { useSession } from '../../../hooks/app/useSession';
import { useOrganization } from '../../../hooks/app/useOrganization';
import { Address } from '@oolio-group/domain';
import {
  isValidDomain,
  isValidEmail,
  isValidName,
  isValidBusinessName,
  isValidNumber,
  isNotEmpty,
  isValidBusinessIdentifier,
  isValidPostCode,
} from '../../../utils/validator';
import { getAvatarName } from '../../../utils/getAvatarName';
import theme from '../../../common/default-theme';
import InputText from '../../../components/Shared/Inputs/InputText';
import InputPhone from '../../../components/Shared/Inputs/InputPhone';
import InputEmail from '../../../components/Shared/Inputs/InputEmail';
import TreatPicker from '../../../components/Shared/Select/Picker';
import ImagePicker from '../../../components/Office/ImagePicker/ImagePicker';
import SelectAddress from '../../../components/Shared/Select/SelectAddress';
import Message from '../../../components/Office/Message/Message';
import Section from '../../../components/Office/Section/Section';
import ScreenLayout from '../../../components/Office/ScreenLayout/ScreenLayout';
import { capitalCase } from 'change-case';

interface FormData {
  // Organisation
  businessName: string;
  businessIdentifier: string;
  currency: string;
  website: string;
  // Contact
  contactName: string;
  phone: string;
  email: string;
  phoneCountryCode: string; // used for component internal identification
  // Location
  line1: string;
  line2?: string;
  city: string;
  suburb: string;
  state: string;
  postalCode: string;
  country: string;
  timeZone: string;
  address?: Address;
  logoUrl?: string;
  imageRawData?: ImageUploadInput;
}

const initialFormState: FormData = {
  businessName: '',
  businessIdentifier: '',
  website: '',
  contactName: '',
  phone: '',
  email: '',
  line1: '',
  line2: '',
  postalCode: '',
  country: '',
  state: '',
  suburb: '',
  city: '',
  timeZone: '',
  currency: '',
  phoneCountryCode: '',
  imageRawData: undefined,
};

export const GeneralSettingsScreen: React.FC = () => {
  const [session] = useSession();
  const { translate } = useTranslation();
  const { showNotification } = useNotification();
  const [hasChanges, setHasChanges] = useState<boolean>(false);
  const [form, setForm] = useState<FormData>(initialFormState);
  const {
    organization,
    error,
    updateOrganization,
    getOrganizationById,
    loading,
  } = useOrganization();

  const businessIdentifierType = getBusinessIdentifierType(form?.country || '');

  const isUK = form?.country === 'GB';
  const isUKPhone = form?.phoneCountryCode === 'GB';

  const optionalFields = ['line2', 'suburb', 'website'];

  useEffect(() => {
    if (session.currentOrganization?.id) {
      getOrganizationById(session.currentOrganization.id);
    }
  }, [session, getOrganizationById]);

  useEffect((): void => {
    if (organization) {
      const [_phoneCode, _phoneNumber] = organization.phone.split(' ');
      setForm({
        // Organisation
        logoUrl: organization?.logoUrl || '',
        businessName: organization.name || '',
        businessIdentifier: organization.businessIdentifier || '',
        currency: organization.currencyCode || '',
        website: organization.website || '',
        // Contact
        phoneCountryCode: getCountryCodeByPhoneCode(_phoneCode) || '',
        phone: _phoneNumber || '',
        contactName: organization?.contactName || '',
        timeZone: organization.timezone || '',
        email: organization.email || '',
        // Address
        line1: organization.address?.line1 || '',
        line2: organization.address?.line2,
        state: organization.address?.state || '',
        suburb: organization.address?.suburb || '',
        city: organization.address?.city || '',
        postalCode: organization.address?.postalCode || '',
        country: organization.country || '',
      });
    }
  }, [organization, session]);

  useEffect((): void => {
    if (error) {
      showNotification({
        error: true,
        message: error,
      });
    }
  }, [error, showNotification]);

  const getFormattedPhone = () => {
    if (form.phoneCountryCode) {
      return `${getCountry(form.phoneCountryCode).phone} ${form.phone}`;
    }
    return form.phone;
  };

  const formValidationStatus: Record<string, boolean> = {
    // Organisation Details
    businessName: isValidBusinessName(form.businessName),
    businessIdentifier: isValidBusinessIdentifier(
      form.businessIdentifier,
      organization?.country,
    ),
    currency: isNotEmpty(form.currency),
    website: isValidDomain(form.website),
    // Contact Details
    contactName: isValidName(form.contactName),
    phone: isValidNumber(getFormattedPhone()),
    email: isValidEmail(form.email),
    // Location Details
    line1: isNotEmpty(form.line1),
    line2: isNotEmpty(form.line2),
    city: isNotEmpty(form.city),
    state: isNotEmpty(form.state),
    postalCode: isValidPostCode(form.postalCode, organization?.country),
    country: isNotEmpty(form.country),
    timeZone: isNotEmpty(form.timeZone),
  };

  const onChangeCountry = useCallback((_countryCode: string) => {
    const defaultTimezone = Object.values(TIMEZONES_MAP)
      .sort((t1, t2) => t1.utcOffsetStr.localeCompare(t2.utcOffsetStr))
      .find((_tz: Timezone) => {
        return _tz.country === _countryCode;
      });
    const selectedCountry = getCountry(_countryCode);
    setForm(_form => ({
      ..._form,
      country: _countryCode,
      timeZone: defaultTimezone?.name || '',
      currency: selectedCountry?.currency || '',
    }));
    setHasChanges(true);
  }, []);

  const onSelectAddress = useCallback(
    (address: Address) => {
      if (address) {
        const country = getCountry(
          address.isoCountryCode || DEFAULT_COUNTRY_CODE,
        ).code;
        setForm(form => ({
          ...form,
          address,
          line1: address.line1,
          line2: address.line2,
          suburb: address.suburb || '',
          city: address.city,
          state: address.state,
          postalCode: address.postalCode,
          country,
        }));
        onChangeCountry(country);
        setHasChanges(true);
      }
    },
    [onChangeCountry],
  );

  const onChange = useCallback(
    (prop: string, value: string | ImageUploadInput): void => {
      setHasChanges(true);
      setForm(form => ({
        ...form,
        [prop]: value,
      }));
    },
    [],
  );

  const onPressSave = async (): Promise<void> => {
    if (
      organization &&
      Object.keys(formValidationStatus).every(_key => {
        if (optionalFields.includes(_key) && !form[_key as keyof FormData])
          return true;
        return formValidationStatus[_key] === true;
      })
    ) {
      const payload: UpdateOrganizationInput = {
        id: organization.id,
        phone: getFormattedPhone(),
        email: form.email,
        name: form.businessName,
        businessIdentifier: form.businessIdentifier,
        website: form.website,
        contactName: form.contactName,
        address: {
          line1: form.line1,
          line2: form.line2,
          city: form.city,
          state: form.state,
          suburb: form.suburb,
          postalCode: form.postalCode,
          country: getCountry(form.country).name,
        },
        country: form.country,
        currencyCode: form.currency,
        timezone: form.timeZone,
        ...(form.imageRawData && {
          imageRawData: {
            base64: form.imageRawData.base64,
            name: form.imageRawData.name,
            type: form.imageRawData.type,
          },
        }),
      };
      const status = await updateOrganization(payload);
      if (status) {
        showNotification({
          success: true,
          message: translate('generalSettings.organizationUpdateSuccess'),
        });
      }
    } else {
      Object.keys(form).map(_key => {
        if (
          !optionalFields.includes(_key) &&
          formValidationStatus[_key] === false
        ) {
          showNotification({
            error: true,
            message: `Invalid ${capitalCase(_key)}`,
          });
        }
      });
    }
    setHasChanges(false);
  };

  return (
    <ScreenLayout
      loading={loading}
      onSave={() => onPressSave()}
      hideFooter={loading || !hasChanges}
      title={translate('navigation.generalSettingsPageTitle', {
        appName: translate('appName'),
      })}
    >
      <Section layoutWidth="small">
        <View>
          <View style={styles.imageContainer}>
            <ImagePicker
              imageUrl={form?.logoUrl}
              initials={getAvatarName(form?.businessName || '')}
              onComplete={imageRawData =>
                onChange('imageRawData', imageRawData)
              }
            />
          </View>
          <Message
            type="neutral"
            message="We recommend an image size of at least 256✗256."
            containerStyle={{
              width: theme.layoutWidth.s,
              marginBottom: 40,
            }}
          />
        </View>
        <View style={theme.forms.row}>
          <InputText
            testID="organization-name"
            value={form.businessName || ''}
            title={translate('form.organizationName')}
            placeholder={translate('form.organizationName')}
            onChangeText={onChange.bind(null, 'businessName')}
            autoCapitalize="words"
            maxLength={50}
            errorMessage={
              !formValidationStatus.businessName
                ? 'Enter valid business name'
                : undefined
            }
            isVerified={formValidationStatus.businessName}
            containerStyle={theme.forms.inputHalf}
          />
          <InputText
            testID="business-number"
            value={form.businessIdentifier || ''}
            title={translate('form.businessNumber', {
              type: businessIdentifierType,
            })}
            placeholder={translate('form.businessNumber', {
              type: businessIdentifierType,
            })}
            onChangeText={onChange.bind(null, 'businessIdentifier')}
            autoCapitalize="words"
            maxLength={11}
            errorMessage={
              !formValidationStatus.businessIdentifier
                ? 'Enter valid identifier'
                : undefined
            }
            isVerified={formValidationStatus.businessIdentifier}
            containerStyle={theme.forms.inputHalf}
          />
        </View>
        <View style={theme.forms.row}>
          <TreatPicker
            testID="select-currency"
            title={translate('form.currency')}
            selectedValue={form.currency}
            options="currencies"
            onValueChange={onChange.bind(null, 'currency')}
            containerStyle={theme.forms.inputHalf}
          />
          <InputText
            testID="website"
            value={form.website || ''}
            title={translate('form.website')}
            placeholder={translate('form.website')}
            onChangeText={onChange.bind(null, 'website')}
            autoCapitalize="words"
            maxLength={50}
            errorMessage={
              !formValidationStatus.website && form.website
                ? 'Enter valid website'
                : undefined
            }
            isVerified={formValidationStatus.website}
            containerStyle={theme.forms.inputHalf}
          />
        </View>
      </Section>
      <Section title="Contact Details" layoutWidth="small">
        <View style={theme.forms.row}>
          <InputText
            testID="contact-name"
            value={form.contactName || ''}
            title={translate('form.contactName')}
            placeholder={translate('form.contactName')}
            onChangeText={onChange.bind(null, 'contactName')}
            autoCapitalize="words"
            maxLength={50}
            errorMessage={
              !formValidationStatus.contactName ? 'Enter valid name' : undefined
            }
            containerStyle={theme.forms.inputHalf}
          />
          <InputPhone
            testID="phone-number"
            value={form.phone || ''}
            title={translate('form.phoneNumber')}
            placeholder={translate(
              isUKPhone ? 'form.phoneNumberHintUK' : 'form.phoneNumberHint',
            )}
            onPressCountry={(code: string): void => {
              onChange('phoneCountryCode', code);
            }}
            defaultCountry={form.phoneCountryCode}
            onChangeText={onChange.bind(null, 'phone')}
            containerStyle={theme.forms.inputHalf}
            maxLength={12}
          />
        </View>
        <View style={theme.forms.row}>
          <InputEmail
            testID="email-address"
            title={translate('common.emailAddress')}
            value={form.email || ''}
            placeholder={translate('common.emailPlaceholder')}
            onChangeText={onChange.bind(null, 'email')}
            errorMessage={
              !formValidationStatus.email
                ? 'Enter valid email address'
                : undefined
            }
            containerStyle={theme.forms.inputFluid}
          />
        </View>
      </Section>
      <Section title="Location Details" layoutWidth="small">
        <View style={theme.forms.row}>
          <SelectAddress
            title="Search Address"
            testID="business-address"
            placeholder={translate(
              isUK
                ? 'signup.businessAddressHintUK'
                : 'signup.businessAddressHint',
            )}
            onChangeAddress={onSelectAddress}
            address={form.address as Address}
            containerStyle={theme.forms.inputFluid}
          />
        </View>
        <View style={[theme.forms.row, { zIndex: -1 }]}>
          <InputText
            testID="street-address"
            value={form.line1 || ''}
            title={translate(isUK ? 'form.streetAddress' : 'form.addressLine1')}
            placeholder={translate('form.streetAddress')}
            onChangeText={onChange.bind(null, 'line1')}
            autoCapitalize="words"
            maxLength={50}
            errorMessage={
              !formValidationStatus.line1 ? 'Enter valid address' : undefined
            }
            containerStyle={
              !isUK ? theme.forms.inputHalf : theme.forms.inputFluid
            }
          />
          {!isUK ? (
            <InputText
              testID="street-address"
              value={form.line2 || ''}
              title={translate('form.addressLine2')}
              placeholder="Optional"
              onChangeText={onChange.bind(null, 'line2')}
              autoCapitalize="words"
              maxLength={50}
              containerStyle={theme.forms.inputHalf}
            />
          ) : null}
        </View>
        <View style={[theme.forms.row, { zIndex: -1 }]}>
          <InputText
            testID="city"
            value={form.city || ''}
            title={translate(isUK ? 'form.town' : 'form.city')}
            placeholder={translate(isUK ? 'form.town' : 'form.city')}
            onChangeText={onChange.bind(null, 'city')}
            autoCapitalize="words"
            maxLength={50}
            errorMessage={
              !formValidationStatus.city ? 'Enter valid city' : undefined
            }
            containerStyle={theme.forms.inputHalf}
          />
          <InputText
            testID="state"
            value={form.state || ''}
            title={translate(isUK ? 'form.county' : 'form.state')}
            placeholder={translate(isUK ? 'form.county' : 'form.state')}
            onChangeText={onChange.bind(null, 'state')}
            autoCapitalize="words"
            maxLength={50}
            errorMessage={
              !formValidationStatus.state ? 'Enter valid state' : undefined
            }
            containerStyle={{ width: 150 }}
          />
          <InputText
            testID="postalCode"
            value={form.postalCode || ''}
            title={translate('form.postalCode')}
            placeholder={translate(
              isUK ? 'form.postalCodeHintUK' : 'form.postalCodeHint',
            )}
            onChangeText={onChange.bind(null, 'postalCode')}
            errorMessage={
              !formValidationStatus.postalCode ? 'Error' : undefined
            }
            keyboardType="numeric"
            containerStyle={{ width: 90 }}
          />
        </View>
        <View style={[theme.forms.row, { zIndex: -1 }]}>
          <TreatPicker
            testID="select-country"
            title={translate('form.country')}
            selectedValue={form.country}
            options="countries"
            onValueChange={onChangeCountry}
            containerStyle={theme.forms.inputHalf}
          />
          <TreatPicker
            testID="select-timezone"
            title={translate('form.timeZone')}
            selectedValue={form.timeZone}
            options="timezones"
            onValueChange={onChange.bind(null, 'timeZone')}
            containerStyle={theme.forms.inputHalf}
          />
        </View>
      </Section>
    </ScreenLayout>
  );
};

const styles = StyleSheet.create({
  imageContainer: {
    width: 540,
    marginBottom: 20,
    alignItems: 'center',
    justifyContent: 'center',
  },
});
