import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import LoadingIndicator from '../../../../components/LoadingIndicator/LoadingIndicator';
import { Helmet } from 'react-helmet';
import { Styles } from '../styles/ProductsSummary.styles';
import { View, ScrollView } from 'react-native';
import { useCurrency, useTranslation } from '@oolio-group/localization';
import {
  DateRangeFilter,
  FilterValue,
  Widget,
  WidgetChartType,
  NO_DATA,
  ColumnType,
  Filters,
  DateRangeGranularity,
} from '@oolio-group/domain';
import { ReportHeader } from '../UIComponents/ReportHeader';
import { Table, TableRef } from '../UIComponents/Table';
import { PieChart } from '../UIComponents/PieChart';
import { Filters as ReportFilter } from '../UIComponents/Filters';
import {
  getHourRangeIn12HrsFormat,
  GRANULARITY_FORMATS,
  transformFieldsToTableColumn,
} from '../reportsHelper';
import {
  ReportTableColumn,
  IMap,
  ExportType,
  Search,
  HelperText,
  PivotTableData,
  DropDownFilter,
  LineChartType,
} from '../types';
import {
  LineChart,
  LineChartData,
  LineChartRow,
} from '../UIComponents/LineChart';
import { CubejsApi } from '@cubejs-client/core';
import getHours from 'date-fns/getHours';
import keyBy from 'lodash/keyBy';

interface ReportProps {
  options: { loading: boolean };
  filterOptions: DropDownFilter[];
  filters: FilterValue;
  search: Search;
  widgets: Widget[];
  dateRangeFilter: DateRangeFilter;
  updateFilters: (filter: string, value: string[]) => void;
  updateSearch: (filter: string, value: string) => void;
  resetFilters: () => void;
  updateReport: () => void;
  updateDateRangeFilters: (value: DateRangeFilter) => void;
  cubejsApi: CubejsApi;
  allFilters: Filters;
  updateCount: number;
}

const TABLE_GRANULARITY_FORMATS: IMap<string> = {
  ...GRANULARITY_FORMATS,
  day: 'MMM dd, yyyy',
};

const granularity = DateRangeGranularity.HOUR;

export const AdjustmentsReport: React.FC<ReportProps> = ({
  options: { loading },
  filters,
  filterOptions,
  widgets,
  dateRangeFilter,
  search,
  updateFilters,
  updateSearch,
  updateReport,
  resetFilters,
  updateDateRangeFilters,
  cubejsApi,
  allFilters,
  updateCount,
}) => {
  const { translate } = useTranslation();
  const { appendCurrency } = useCurrency();
  const styles = Styles();
  const [showFilters, setShowFilters] = useState<boolean>(false);

  const toggleFilters = useCallback(() => {
    setShowFilters(value => !value);
  }, []);

  const tableWidget =
    widgets.find(widget => widget.chartType === WidgetChartType.TABLE) ||
    ({} as Widget);

  const [selectedColumns, setSelectedColumns] = useState<ReportTableColumn[]>(
    [],
  );

  const tableColumns: ReportTableColumn[] = useMemo(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const columns: any[] = [];
    if (tableWidget.query?.dimensions)
      columns.push(
        ...transformFieldsToTableColumn(tableWidget.query?.dimensions).map(
          col => ({
            ...col,
            type: ColumnType.STRING,
          }),
        ),
      );

    if (tableWidget.query?.measures)
      columns.push(
        ...transformFieldsToTableColumn(tableWidget.query?.measures),
      );
    return columns;
  }, [tableWidget.query]);

  useEffect(() => {
    setSelectedColumns(
      (tableColumns || []).filter(column => column.showByDefault),
    );
  }, [tableColumns]);

  const updateColumns = useCallback(
    (columnKeys: string[]) => {
      setSelectedColumns(
        tableColumns.filter(col => columnKeys.includes(col.title)),
      );
    },
    [tableColumns],
  );

  const tableWidgetRef = useRef<TableRef>({} as TableRef);
  const exportReport = useCallback((type = ExportType.CSV) => {
    if (type === ExportType.CSV)
      tableWidgetRef.current.exportData &&
        tableWidgetRef.current.exportData(
          `AdjustmentsReport-${Date.now()}.csv`,
        );
  }, []);

  const lineChartDataTransformationFn = useCallback(
    (serializedData: PivotTableData): LineChartData[] => {
      const nameKey = 'OrderAdjustments.name';

      const translatedData: PivotTableData = serializedData.map(data => ({
        ...data,
        [nameKey]: `${data[nameKey]}`,
      }));

      const nameMap = keyBy(translatedData, nameKey);
      const names = Object.keys(nameMap);

      const nameBlankHrs: Record<string, Array<LineChartRow>> = names.reduce(
        (acc, name) => {
          return {
            ...acc,
            [name]: Array.from({ length: 24 }).map((_, i) => ({
              x: i,
              y: 0,
            })),
          };
        },
        {} as Record<string, LineChartRow[]>,
      );

      const dataByName = translatedData.reduce(
        (acc: Record<string, Record<string, Record<string, number>>>, row) => {
          const name = row[nameKey] as string;
          const createdHour = row['OrderAdjustments.createdAt.hour'] as string;
          const dateHour = getHours(new Date(createdHour));

          return {
            ...acc,
            [name]: {
              ...(acc[name] || {}),
              [dateHour]: {
                amount:
                  (((acc[name] || {})[dateHour] || {}).amount || 0) +
                  (+row['OrderAdjustments.totalAdjustmentAmount'] || 0),
              },
            },
          };
        },
        {},
      );

      return names.map(name => {
        const nameData = dataByName[name];
        return nameBlankHrs[name].map(defaultHrData => {
          const { start, end } = getHourRangeIn12HrsFormat(
            defaultHrData.x as number,
          );

          const lineData: LineChartRow = {
            x: `${start} - ${end}`,
            y: 0,
            text: name as string,
          };

          if (nameData[defaultHrData.x + '']) {
            lineData.y = Math.abs(nameData[defaultHrData.x + ''].amount);
            lineData.labelText = `${name} (${start})\nAmount: ${appendCurrency(
              `${nameData[defaultHrData.x + ''].amount}`,
            )}`;
          } else {
            lineData.labelText = `${name} (${start})\n${NO_DATA}`;
          }

          return lineData;
        });
      });
    },
    [appendCurrency],
  );

  return (
    <>
      <Helmet>
        <title>
          {translate('navigation.generalSettingsPageTitle', {
            appName: translate('appName'),
          })}
        </title>
      </Helmet>

      <ScrollView scrollEnabled={!showFilters} style={styles.pageStyle}>
        {showFilters ? (
          <View style={styles.filtersStyle}>
            <ReportFilter
              toggleFilters={toggleFilters}
              filterOptions={filterOptions}
              filters={filters}
              updateFilters={updateFilters}
              resetFilters={resetFilters}
            ></ReportFilter>
          </View>
        ) : null}
        <View style={styles.mainSectionStyle}>
          <View style={styles.headersStyle}>
            <ReportHeader
              allFilters={allFilters}
              toggleFilters={toggleFilters}
              updateReport={updateReport}
              exportReport={exportReport}
              filters={filters}
              search={search}
              updateSearch={updateSearch}
              columns={{ all: tableColumns, updateColumns, selectedColumns }}
              updateFilters={updateFilters}
              dateRangeFilter={dateRangeFilter}
              updateDateRangeFilters={updateDateRangeFilters}
              translationParentKey={'backOfficeAdjustmentsReport'}
            />
          </View>
          <View style={styles.chartRowStyle}>
            {loading ? (
              <LoadingIndicator />
            ) : (
              <>
                {widgets
                  .filter(widget => widget.chartType !== WidgetChartType.TABLE)
                  .map(widget => {
                    let renderComp;

                    if (widget.name === 'DISCOUNTS VS. SURCHARGES') {
                      const pieKeys = {
                        name: widget.query.dimensions[0].key,
                        value: widget.query.measures[0].key,
                      };
                      renderComp = (
                        <PieChart
                          widget={widget}
                          helper={HelperText.ADJUSTMENTS}
                          keys={pieKeys}
                          columnType={widget.query.measures[0]?.type}
                          cubejsApi={cubejsApi}
                          updateCount={updateCount}
                        />
                      );
                    } else {
                      widget.query.dateRangeFilters =
                        widget.query.dateRangeFilters?.map(dateRangeFilter => ({
                          ...dateRangeFilter,
                          key: 'OrderAdjustments.createdAt',
                          granularity: granularity,
                        }));

                      const dateRangeFilters = widget.query
                        .dateRangeFilters as DateRangeFilter[];

                      const lineKeys = widget.query.measures.map(measure => ({
                        name: `${dateRangeFilters[0].key}.${granularity}`,
                        value: measure.key,
                        type: (measure.type || ColumnType.NUMBER) as ColumnType,
                      }));

                      renderComp = (
                        <LineChart
                          widget={widget}
                          helper={HelperText.ADJUSTMENTS}
                          keys={lineKeys}
                          type={LineChartType.BASIC}
                          dataTransformationFn={lineChartDataTransformationFn}
                          cubejsApi={cubejsApi}
                          updateCount={updateCount}
                          columnType={widget.query.measures[0].type}
                        />
                      );
                    }

                    return renderComp;
                  })}
              </>
            )}
          </View>
          <View style={styles.tableStyle}>
            {loading ? (
              <LoadingIndicator />
            ) : (
              <Table
                widget={tableWidget}
                columns={selectedColumns}
                helper={HelperText.ADJUSTMENTS}
                pageSize={5}
                granularityFormats={TABLE_GRANULARITY_FORMATS}
                ref={tableWidgetRef}
                filters={filters}
                cubejsApi={cubejsApi}
                updateCount={updateCount}
              ></Table>
            )}
          </View>
        </View>
      </ScrollView>
    </>
  );
};

export default AdjustmentsReport;
