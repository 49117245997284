import React, { FC, useCallback, useMemo, useRef, useState } from 'react';
import { View, TouchableOpacity } from 'react-native';
import { useTranslation } from '@oolio-group/localization';
import { useNetInfo } from '@react-native-community/netinfo';
import Popover, { PopoverPlacement } from 'react-native-popover-view';
import { useSettingsSync } from '../../../hooks/app/useSettingsSync';
import { analyticsService } from '../../../analytics/AnalyticsService';
import { snakeCase } from 'change-case';
import styles from './SystemStatus.styles';
import theme from '../../../common/default-theme';
import selectStyles from '../../Shared/Select/Select.styles';
import Icon from '../../Icon/Icon';
import SystemStatusRow, { STATES, SystemStatusAction } from './SystemStatusRow';

const SystemStatus: FC = () => {
  const touchable = useRef(null);

  const [showOptions, setShowOptions] = useState<boolean>(false);

  const netInfo = useNetInfo();
  const { syncApp } = useSettingsSync();
  const { translate } = useTranslation();

  const onToggleOptions = useCallback(() => {
    setShowOptions(!showOptions);
    analyticsService.capture('system_status_pressed');
  }, [showOptions]);

  const onPressAction = useCallback((name: string, action?: () => void) => {
    analyticsService.capture('system_status_action', {
      action: snakeCase(name),
    });
    action && action();
  }, []);

  const onPressSync = useCallback(() => {
    syncApp();
    setShowOptions(false);
  }, [syncApp]);

  const OPTIONS: SystemStatusAction[] = useMemo(
    () => [
      {
        testID: 'status-internet',
        label: translate('common.internet'),
        type: netInfo.isConnected ? STATES.POSITIVE : STATES.NEGATIVE,
      },
      {
        testID: 'btn-syncData',
        label: translate('settings.syncApp'),
        type: STATES.NEUTRAL,
        action: onPressSync,
      },
    ],
    [netInfo.isConnected, onPressSync, translate],
  );

  const isAllGood = OPTIONS.every(option => option.type !== STATES.NEGATIVE);

  return (
    <View>
      <View>
        {isAllGood ? <View style={styles.marker} /> : null}
        <TouchableOpacity
          ref={touchable}
          testID="btn-system"
          onPress={onToggleOptions}
          style={[
            styles.button,
            {
              backgroundColor: isAllGood
                ? theme.colors.teritiary2
                : theme.colors.states.negative,
            },
          ]}
        >
          <Icon name="heart-rate" size={20} color={theme.colors.white} />
        </TouchableOpacity>
      </View>
      <Popover
        from={touchable}
        isVisible={showOptions}
        placement={PopoverPlacement.BOTTOM}
        onRequestClose={() => setShowOptions(false)}
        backgroundStyle={selectStyles.bgStyle}
        popoverStyle={selectStyles.optionsContainer}
      >
        <View style={styles.optionsBg}>
          {OPTIONS.map((option, i) => (
            <SystemStatusRow
              key={i}
              testID={option.testID}
              type={option.type}
              label={option.label}
              action={() => onPressAction(option.label, option.action)}
            />
          ))}
        </View>
      </Popover>
    </View>
  );
};

export default SystemStatus;
