import { StyleSheet } from 'react-native';
import theme from '../../../../common/default-theme';
import { CART_FONT_SIZE } from '../Cart.styles';

const cartTextStyle = {
  fontSize: CART_FONT_SIZE,
  lineHeight: 20,
  paddingVertical: 4,
  color: theme.colors.dark,
  fontFamily: theme.fonts.medium,
};

const styles = StyleSheet.create({
  container: {
    minHeight: 28,
    marginBottom: 4,
    flexDirection: 'row',
    alignItems: 'flex-start',
  },
  adjustmentMargin: {
    marginLeft: 32,
  },
  variation: {
    marginLeft: 32,
    paddingHorizontal: 10,
    borderRadius: theme.radius.s,
    backgroundColor: theme.colors.white,
  },
  quantity: {
    minWidth: 28,
    height: '100%',
    marginRight: 4,
    borderRadius: theme.radius.s,
    backgroundColor: theme.colors.white,
  },
  quantityText: {
    textAlign: 'center',
    ...cartTextStyle,
  },
  name: {
    flex: 1,
    width: '100%',
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    paddingHorizontal: 10,
    borderRadius: theme.radius.s,
    backgroundColor: theme.colors.white,
  },
  nameText: {
    flex: 1,
    ...cartTextStyle,
  },
  priceText: {
    minWidth: 60,
    marginLeft: 10,
    textAlign: 'right',
    ...cartTextStyle,
  },
  voidReason: {
    marginLeft: 32,
    paddingHorizontal: 10,
    borderRadius: theme.radius.s,
    backgroundColor: theme.colors.states.negativeXL,
  },
  voidView: {
    backgroundColor: theme.colors.states.negativeXL,
  },
  voidText: {
    color: theme.colors.states.negative,
    textDecorationLine: 'line-through',
  },
  paidView: {
    opacity: 0.6,
    backgroundColor: theme.colors.states.positiveXL,
  },
  paidText: {
    color: theme.colors.states.positive,
  },
  selectedView: {
    backgroundColor: theme.colors.states.neutral,
  },
  selectedText: {
    color: theme.colors.white,
  },
  partialSelectedView: {
    backgroundColor: theme.colors.blueLight,
  },
  partialSelectedText: {
    color: theme.colors.dark,
  },
  seatText: {
    fontSize: CART_FONT_SIZE,
    lineHeight: 20,
    paddingLeft: 6,
    paddingVertical: 4,
    color: theme.colors.grey6,
    fontFamily: theme.fonts.medium,
  },
  firedText: {
    lineHeight: 20,
    paddingRight: 8,
    paddingVertical: 4,
    color: theme.colors.green,
  },
});

export default styles;
