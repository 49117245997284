import React from 'react';
import { ModifierGroupsTab } from './OptionsGroups/OptionsGroups';
import { ModifiersTab } from './Modifiers/Modifiers';
import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs';
import { MaterialTopTabBarProps } from '@react-navigation/material-top-tabs/src/types';
import TabBar from '../../../../components/TabBar/TabBar';

const Tab = createMaterialTopTabNavigator();

const OptionsTabs: React.FC = () => {
  return (
    <Tab.Navigator
      tabBar={(props: MaterialTopTabBarProps): React.ReactNode => (
        <TabBar tabBar={props} showBack={false} />
      )}
      initialRouteName="OptionGroupsTab"
    >
      <Tab.Screen
        name="OptionGroupsTab"
        component={ModifierGroupsTab}
        options={{ tabBarLabel: 'Option Groups' }}
      />
      <Tab.Screen
        name="ModifierTab"
        component={ModifiersTab}
        options={{ tabBarLabel: 'Modifiers' }}
      />
    </Tab.Navigator>
  );
};

export default OptionsTabs;
