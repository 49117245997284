import React, { FC, useCallback } from 'react';
import { StyleSheet, View, Text, TouchableOpacity } from 'react-native';
import Icon from '../../../../../components/Icon/Icon';
import theme from '../../../../../common/default-theme';
import { useTranslation } from '@oolio-group/localization';

export interface ProductListProps {
  page: number;
  dataLength: number;
  pageLength: number;
  onPageChange: (page: number) => void;
}

const styles = StyleSheet.create({
  footerContainer: {
    paddingTop: 16,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  footerText: {
    paddingLeft: 12,
    color: theme.colors.grey6,
  },
  paginationContainer: {
    width: 82,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  btnPagination: {
    width: 36,
    height: 36,
    borderRadius: 36,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.colors.grey1,
  },
});

const ProductListFooter: FC<ProductListProps> = ({
  page,
  onPageChange,
  dataLength,
  pageLength,
}) => {
  const { translate } = useTranslation();
  const pages = Math.ceil(dataLength / 10);

  const fetchNextPage = useCallback(() => {
    onPageChange(page + 1);
  }, [onPageChange, page]);

  const handlePressBack = useCallback(() => {
    onPageChange(page - 1);
  }, [onPageChange, page]);

  function getPaginationText() {
    const start = page === 1 ? 1 : (page - 1) * 10 + 1;
    return `${start}-${start + pageLength - 1}`;
  }

  return (
    <View style={styles.footerContainer}>
      <Text style={styles.footerText}>
        {translate('backOfficeProducts.pagination', {
          pageLength: getPaginationText(),
          dataLength: dataLength,
        })}
      </Text>
      <View style={styles.paginationContainer}>
        <TouchableOpacity
          disabled={page === 1}
          onPress={handlePressBack}
          style={styles.btnPagination}
        >
          <Icon
            size={20}
            name="arrow-left"
            color={page === 1 ? theme.colors.grey4 : theme.colors.black}
          />
        </TouchableOpacity>
        <TouchableOpacity
          disabled={page >= pages}
          style={styles.btnPagination}
          onPress={fetchNextPage}
        >
          <Icon
            size={20}
            name="arrow-right"
            color={page >= pages ? theme.colors.grey4 : theme.colors.black}
          />
        </TouchableOpacity>
      </View>
    </View>
  );
};

export default ProductListFooter;
