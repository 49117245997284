import { BehaviorSubject, Observable } from 'rxjs';
import {
  UserRoleDef,
  User,
  App,
  Role,
  ResourceOperation,
  Resource,
  ClientAppPermission,
  RolePermissions,
  Worklog,
} from '@oolio-group/domain';
import keyBy from 'lodash/keyBy';
import difference from 'lodash/difference';

export interface PosUser {
  id: string;
  name: string;
  pin: string;
  skipPin?: boolean;
  isWorking?: boolean;
  lastWorklog?: Worklog;
}

// TODO: do encryption on email for office user while storing
export type OfficeUser = Pick<User, 'name' | 'email' | 'id' | 'skipPin'> & {
  recent: boolean;
  active: boolean;
}; // recent tells which user logged in recently

export interface UserActivity {
  posUser?: PosUser; // active POS user (under a selected store)
  recentUserId?: string; // recent user id (can be pos / office)
  officeUsers: Record<string, OfficeUser>; // to display recent office users on screen
}

const INIT_STATE: UserActivity = {
  posUser: undefined,
  recentUserId: undefined,
  officeUsers: {},
};

export class UserUtility {
  private _userActivity = new BehaviorSubject<UserActivity>(INIT_STATE);

  setUserActivity(activity: UserActivity) {
    this._userActivity.next(activity);
  }

  clearUserActivity(): void {
    this._userActivity.next(INIT_STATE);
  }

  get userActivity() {
    return this._userActivity.value;
  }

  get retrieveUserActivity$(): Observable<UserActivity> {
    return this._userActivity.asObservable();
  }

  /**
   * Use this to set POS user
   * @param user
   */
  setPosUser(user: PosUser): void {
    const prevData = this.userActivity;

    this._userActivity.next({
      ...prevData,
      posUser: user,
      recentUserId: user.id,
      officeUsers: {
        ...Object.keys(prevData.officeUsers).reduce(
          (acc, officeUserId) => ({
            ...acc,
            [officeUserId]: {
              ...prevData.officeUsers[officeUserId],
              recent: prevData.officeUsers[officeUserId].recent,
              active:
                prevData.officeUsers[officeUserId].recent === true &&
                user.id === officeUserId,
            },
          }),
          {},
        ),
      },
    });
  }

  clearPosUser(): void {
    const prevData = this.userActivity;
    this._userActivity.next({
      ...prevData,
      posUser: undefined,
      officeUsers: {
        ...Object.keys(prevData.officeUsers).reduce(
          (acc, officeUserId) => ({
            ...acc,
            [officeUserId]: {
              ...prevData.officeUsers[officeUserId],
              active: false,
            },
          }),
          {},
        ),
      },
    });
  }

  /**
   * Use this when new user logged-in to office
   * @param user
   */
  addOfficeUser(user: Pick<User, 'name' | 'email' | 'id'>): void {
    const prevData = this.userActivity;
    this._userActivity.next({
      ...prevData,
      officeUsers: {
        ...Object.keys(prevData.officeUsers).reduce(
          (acc, officeUserId) => ({
            ...acc,
            [officeUserId]: {
              ...prevData.officeUsers[officeUserId],
              recent: false,
              active: false,
            },
          }),
          {},
        ),
        [user.id]: { ...user, recent: true, active: true } as OfficeUser,
      },
      recentUserId: user.id,
    });
  }

  updateOfficeUserAttr(
    userId: string,
    attrs: Partial<Pick<User, 'name' | 'email'>>,
  ): void {
    const prevData = this.userActivity;

    this._userActivity.next({
      ...prevData,
      officeUsers: {
        ...Object.keys(prevData.officeUsers).reduce((acc, officeUserId) => {
          if (userId === officeUserId) {
            return {
              ...acc,
              [officeUserId]: {
                ...prevData.officeUsers[officeUserId],
                ...attrs,
              },
            };
          }
          return acc;
        }, prevData.officeUsers),
      },
    });
  }

  /**
   * Use this to render recent office user
   * @returns
   */
  get recentOfficeUser(): OfficeUser {
    return Object.values(this.userActivity.officeUsers).find(
      officeUser => officeUser.recent,
    ) as OfficeUser;
  }

  get posUser() {
    return this.userActivity.posUser;
  }

  get recentUserId() {
    return this.userActivity.recentUserId;
  }
}

export const userUtility = new UserUtility();

/**
 * Computes application access based on userRoles `apps` attributes
 *
 * @param rolesById
 * @param userRoles
 * @param app
 * @returns boolean
 *
 * @example refer to test cases, which covers most of scenarios
 */
export const computeAppAccess = (
  rolesById: Record<string, Role>,
  userRoles: UserRoleDef[],
  app: App,
): boolean => {
  return userRoles
    .map(userRole => {
      const role = rolesById[userRole.role.id];
      const apps = userRole.apps;
      if (apps) {
        return apps[app];
      } else if (role && role.apps) {
        return role.apps[app] || false;
      }
      return false;
    })
    .some(Boolean);
};

/**
 *
 * @param resourcesWithOperations - Array of individual resources with a set of expected operations to perform on respective resources
 * @param cumulativeUserPermissions - Net of user permissions from all user-roles and overrides(to be added)
 * @returns boolean
 *
 * @example refer to test cases, which covers most of scenarios
 */
export const canPerformOn = (
  resourcesWithOperations: ResourceWithOperation[],
  cumulativeUserPermissions: ComputedUserPermissions,
): boolean => {
  return resourcesWithOperations.every(resource => {
    if (Array.isArray(resource.doOperations)) {
      return resource.doOperations.every(operation => {
        return (
          cumulativeUserPermissions[resource.onResource] as ResourceOperation[]
        ).includes(operation);
      });
    }
    return cumulativeUserPermissions[resource.onResource];
  });
};

/**
 *
 * @param resources - Array of individual resources
 * @param cumulativeUserPermissions - Net of user permissions from all user-roles and overrides(to be added)
 * @returns boolean
 *
 * @example refer to test cases, which covers most of scenarios
 */
export const canPerformOperationsOn = (
  resources: Resource[],
  cumulativeUserPermissions: ComputedUserPermissions,
  operations: boolean | ResourceOperation[],
): boolean => {
  return resources.every(resource => {
    if (Array.isArray(operations)) {
      return operations.every(operation => {
        return (
          cumulativeUserPermissions[resource] as ResourceOperation[]
        ).includes(operation);
      });
    }
    return cumulativeUserPermissions[resource] === operations;
  });
};

export const mapAllowedResourceWiseOperations = (
  permissions: Record<string, RolePermissions>,
  systemPermissionsById: Record<string, ClientAppPermission>,
  prevPermissions: ComputedUserPermissions,
): ComputedUserPermissions => {
  return Object.values(permissions).reduce((acc, resource) => {
    const systemPermission = systemPermissionsById[resource.id];
    const systemResource = systemPermission.resource;
    const operations = resource?.operations;
    if (typeof acc[systemResource] !== 'undefined') {
      if (Array.isArray(systemPermission.operation)) {
        if ((operations as ResourceOperation[])?.length > 0) {
          const newOps = difference(
            operations as ResourceOperation[],
            acc[systemResource] as ResourceOperation[],
          );
          acc[systemResource] = [
            ...(acc[systemResource] as ResourceOperation[]),
            ...newOps,
          ];
        }
      } else if (typeof operations === 'boolean') {
        acc[systemResource] =
          typeof acc[systemResource] === 'boolean'
            ? operations || acc[systemResource]
            : operations;
      }
    } else if (typeof operations !== 'undefined') {
      acc[systemResource] = operations;
    }
    return acc;
  }, prevPermissions);
};

/**
 *
 * Note: This must not support any kind of role or location filters
 *
 * @param systemPermissionsById
 * @param rolesById
 * @param userRoles
 * @returns
 *
 * @example refer to test cases
 */
export const getUserCumulativePermissions = (
  systemPermissionsById: Record<string, ClientAppPermission>,
  rolesById: Record<string, Role>,
  userRoles: UserRoleDef[],
): ComputedUserPermissions => {
  if (Object.keys(rolesById || {}).length === 0) {
    return {};
  }
  if (Object.keys(systemPermissionsById || {}).length === 0) {
    return {};
  }
  return userRoles.reduce((acc, userRole) => {
    const permissions = Object.assign(
      keyBy(rolesById[userRole.role.id]?.permissions || [], 'id'),
      keyBy(userRole.overridePermissions, 'id'),
    );

    return mapAllowedResourceWiseOperations(
      permissions,
      systemPermissionsById,
      acc,
    );
  }, {});
};

export type ComputedUserPermissions = Partial<
  Record<Resource, ResourceOperation[] | boolean>
>;

export interface ResourceWithOperation {
  doOperations?: ResourceOperation[];
  onResource: Resource;
}

export type UserWithUserRoles = User & { roles: UserRoleDef[] } & Partial<{
    storeId: string;
  }>;

export interface StoreUsers {
  store: {
    id: string;
    users: UserWithUserRoles[];
  };
}

export type LocationWiseRolePermissions = Record<
  string, // venueId
  Record<
    string, // storeId
    Record<
      string, // roleId
      ComputedUserPermissions
    >
  >
>;
