import theme from '../../../common/default-theme';
import { StyleSheet } from 'react-native';

export const styles = StyleSheet.create({
  screenContainer: {
    flex: 1,
    paddingHorizontal: 20,
    alignContent: 'center',
    backgroundColor: theme.colors.white,
  },
  sectionContainer: {
    width: '100%',
    maxWidth: 800,
    marginBottom: 60,
    alignSelf: 'center',
    marginHorizontal: 'auto',
  },
  sectionTitleContainer: {
    paddingHorizontal: 12,
  },
  sectionTitle: {
    fontSize: 18,
    lineHeight: 18,
    fontFamily: theme.fonts.medium,
  },
  sectionSubtitle: {
    marginTop: 8,
    fontSize: 14,
    lineHeight: 20,
    color: theme.colors.grey6,
  },
  sectionContent: {
    marginTop: 20,
  },
  quickLinksSection: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  quickLinksBtn: {
    width: '24%',
    paddingVertical: 15,
    paddingHorizontal: 15,
    justifyContent: 'space-between',
    backgroundColor: theme.colors.black,
    borderRadius: theme.radius.m,
    flexDirection: 'row',
  },
  quickLinksBtnLabel: {
    lineHeight: 20,
    color: theme.colors.white,
  },
  snapshotStats: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  snapshotStatContainer: {
    width: '49%',
    paddingVertical: 26,
    paddingHorizontal: 36,
    borderRadius: theme.radius.m,
    backgroundColor: theme.colors.grey1,
  },
  snapshotStatTitleText: {
    flexDirection: 'row',
    color: theme.colors.black,
    justifyContent: 'space-between',
  },
  snapshotStatTitle: {
    lineHeight: 18,
  },
  snapshotStatSubtitle: {
    color: theme.colors.grey6,
  },
  statContainer: {
    flexDirection: 'row',
    color: theme.colors.black,
    justifyContent: 'space-between',
  },
  statValue: {
    marginTop: 26,
    fontSize: 24,
    lineHeight: 34,
    color: theme.colors.green,
    fontFamily: theme.fonts.semibold,
  },
  statLabel: {
    marginTop: 6,
    fontSize: 14,
    lineHeight: 16,
    color: theme.colors.grey6,
  },
  cardRow: {
    flexWrap: 'wrap',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  cardContainer: {
    width: '49%',
    marginBottom: 20,
    paddingVertical: 26,
    paddingHorizontal: 36,
    borderRadius: theme.radius.m,
    backgroundColor: theme.colors.grey1,
  },
  cardIconContainer: {
    width: 44,
    height: 44,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: theme.radius.m,
    backgroundColor: theme.colors.black,
  },
  cardTitle: {
    fontSize: 16,
    marginTop: 26,
    lineHeight: 24,
    color: theme.colors.black,
  },
  cardSubtitle: {
    marginTop: 8,
    fontSize: 14,
    lineHeight: 20,
    color: theme.colors.grey6,
  },
});

export default styles;
