import { BehaviorSubject, Observable } from 'rxjs';
import {
  Colors,
  OnboardingNavigationRoutes,
  OnboardingSection,
  OnBoardingStatus,
  StyleFn,
  OnBoardingCheckListItem,
} from '@oolio-group/domain';

export type OnboardingActionListUI = UIOnboardingStatus & {
  action: Function;
  label: string;
  isCompleted: boolean;
  icon?: string;
  textStyle?: StyleFn;
  iconColor?: Colors;
  order: number;
};

export const ONBOARDING_DISPLAY_UTIL: Record<
  OnboardingSection,
  { translationKey: string; navigateTo: OnboardingNavigationRoutes }
> = {
  [OnboardingSection.VIEW_VENUE_SETUP]: {
    translationKey: 'setupGuide.reviewVenueDetails',
    navigateTo: OnboardingNavigationRoutes.NAVIGATE_TO_VENUE,
  },
  [OnboardingSection.VIEW_ADD_PAYMENT_TYPE]: {
    translationKey: 'setupGuide.setupPaymentTypes',
    navigateTo: OnboardingNavigationRoutes.NAVIGATE_TO_PAYMENT_TYPES,
  },
  [OnboardingSection.VIEW_ADD_TAXES]: {
    translationKey: 'setupGuide.setupTaxes',
    navigateTo: OnboardingNavigationRoutes.NAVIGATE_TO_TAXES,
  },
  [OnboardingSection.VIEW_STORE_AND_REGISTER_SETUP]: {
    translationKey: 'setupGuide.reviewStorePOSSettings',
    navigateTo: OnboardingNavigationRoutes.NAVIGATE_TO_STORE_REGISTER,
  },
  [OnboardingSection.ADD_A_PAGE_AND_PRODUCT]: {
    translationKey: 'setupGuide.addFirstProduct',
    navigateTo: OnboardingNavigationRoutes.NAVIGATE_TO_PRODUCTS,
  },
};

export interface UIOnboardingStatus {
  areaName: string;
  sectionKey: OnboardingSection;
  checklist: OnBoardingCheckListItem[];
  order: number;
}

export type UIOnboardingStatusMap = Record<string, UIOnboardingStatus[]>;

export const mapOnboardingUIModel = (
  input: OnBoardingStatus,
): UIOnboardingStatusMap => {
  return input.areas.reduce((acc, area) => {
    return {
      ...acc,
      [area.name]: area.sections.map(section => {
        return {
          areaName: area.name,
          sectionKey: section.key,
          checklist: section.checklist,
          order: section.order,
        } as UIOnboardingStatus;
      }),
    };
  }, {});
};

export class OnboardingUtility {
  private subject = new BehaviorSubject<OnBoardingStatus | undefined>(
    undefined,
  );

  setOnboardingInfo(data: OnBoardingStatus) {
    this.subject.next(data);
  }

  clearOnboardingInfo() {
    this.subject.next(undefined);
  }

  get getOnboardingInfo$(): Observable<OnBoardingStatus | undefined> {
    return this.subject.asObservable();
  }

  complete() {
    this.subject.complete();
  }
}

export const onboardingUtility = new OnboardingUtility();
