import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { View, Text } from 'react-native';
import { useTranslation } from '@oolio-group/localization';
import { useModal } from '@oolio-group/rn-use-modal';
import { useNotification } from '../../../../../hooks/Notification';
import {
  CreatePrinterProfileInput,
  LOCALE,
  PrinterProfile,
  PrinterProfileType,
} from '@oolio-group/domain';
import ConfirmationDialog from '../../../../../components/Modals/ConfirmationDialog';
import { usePrinterProfiles } from '../../../../../hooks/app/usePrinterProfiles';
import { pick } from 'lodash';
import { isNotEmpty } from '@oolio-group/client-utils';
import theme from '../../../../../common/default-theme';
import styles from './PrinterProfiles.styles';
import ScreenLayout from '../../../../../components/Office/ScreenLayout/ScreenLayout';
import Section from '../../../../../components/Office/Section/Section';
import CreateButton from '../../../../../components/Office/CreateButton/CreateButton';
import InputText from '../../../../../components/Shared/Inputs/InputText';
import TreatPicker from '../../../../../components/Shared/Select/Picker';
import ButtonIcon from '../../../../../components/Shared/TreatButton/ButtonIcon';
import SelectMultiple from '../../../../../components/Shared/Select/SelectMultiple';
import Search from '../../../../../components/Shared/Search/Search';
import InputToggle from '../../../../../components/Shared/Inputs/InputToggle';
import { useOrderTypes } from '../../../../../hooks/app/orderTypes/useOrderTypes';
import { useFocusEffect } from '@react-navigation/native';

interface OrderTypeOption {
  label: string;
  value: string;
}
interface PrinterProfileRowProps {
  index: number;
  printerProfile: PrinterProfile;
  onChange: (
    index: number,
    id: string,
    key: string,
    value: string | string[] | boolean,
  ) => void;
  onDeletePrinterProfile: (index: number, id: string) => void;
  orderTypeOptions: OrderTypeOption[];
}

const PrinterProfileRow: React.FC<PrinterProfileRowProps> = ({
  printerProfile,
  onDeletePrinterProfile,
  onChange,
  index,
  orderTypeOptions,
}: PrinterProfileRowProps) => {
  const { translate } = useTranslation();
  const { showModal, closeModal } = useModal();
  const localeList = Object.values(LOCALE).map(locale => ({
    label: translate(locale),
    value: locale,
  }));

  const onPressDelete = useCallback((): void => {
    if (printerProfile.id === undefined) {
      onDeletePrinterProfile(index, printerProfile.id);
    } else {
      showModal(
        <ConfirmationDialog
          title={translate('dialog.deleteTitle')}
          message={translate('dialog.deleteConfirmation', {
            label: printerProfile.name,
          })}
          onConfirm={() => {
            onDeletePrinterProfile(index, printerProfile.id);
            closeModal();
          }}
        />,
      );
    }
  }, [
    showModal,
    closeModal,
    onDeletePrinterProfile,
    translate,
    printerProfile,
    index,
  ]);
  return (
    <View testID="table-row" style={theme.tables.row}>
      <InputText
        testID="printer-profile-name"
        value={printerProfile.name}
        placeholder={translate('backOfficePrinterProfiles.printerProfileName')}
        onChangeText={onChange.bind(null, index, printerProfile?.id, 'name')}
        containerStyle={styles.cellProfile}
      />
      <SelectMultiple
        testID="select-profile-order-types"
        options={orderTypeOptions}
        selectedValues={printerProfile.orderTypes || []}
        onValueChange={onChange.bind(
          null,
          index,
          printerProfile?.id,
          'orderTypes',
        )}
        containerStyle={styles.cellProfile}
      />
      <TreatPicker
        testID={'printer-profile-locale'}
        options={localeList}
        onValueChange={onChange.bind(null, index, printerProfile?.id, 'locale')}
        selectedValue={printerProfile.locale}
        containerStyle={styles.cellProfile}
      />
      <InputToggle
        type="checkbox"
        testID="toggle-singleItemPrinting"
        isToggled={printerProfile.singleItemPrinting || false}
        onToggle={() => {
          onChange(
            index,
            printerProfile?.id,
            'singleItemPrinting',
            !printerProfile.singleItemPrinting,
          );
        }}
        containerStyle={styles.cellSingleItemPrinting}
      />
      <ButtonIcon
        type="negativeLight"
        icon="trash-alt"
        onPress={onPressDelete}
        containerStyle={styles.cellDelete}
      />
    </View>
  );
};

type FormState = Record<string, PrinterProfile & { isChanged: boolean }>;

export const PrinterProfiles: React.FC = () => {
  const { showNotification } = useNotification();
  const { translate } = useTranslation();
  const [form, setForm] = useState<FormState>({});
  const [newPrinterProfiles, setNewPrinterProfiles] = useState<
    CreatePrinterProfileInput[]
  >([]);

  const [deletedPrinterProfileId, setDeletedPrinterProfileId] =
    useState<string>('');

  const [searchString, setSearchString] = useState<string>('');

  const {
    loading: printerProfileLoading,
    error,
    printerProfiles,
    getPrinterProfiles,
    createPrinterProfiles,
    createdPrinterProfileIds,
    updatePrinterProfiles,
    updatedPrinterProfileIds,
    deletePrinterProfile,
    isPrinterProfileDeleted,
  } = usePrinterProfiles();

  const {
    getOrderTypes,
    loading: orderTypesLoading,
    orderTypes,
  } = useOrderTypes({ fetchPolicy: 'network-only' });

  const loading = printerProfileLoading || orderTypesLoading;

  const { closeModal } = useModal();

  useFocusEffect(
    useCallback(() => {
      getPrinterProfiles();
      getOrderTypes();
    }, [getOrderTypes, getPrinterProfiles]),
  );

  useEffect(() => {
    if (printerProfiles) {
      setForm(printerProfiles as FormState);
    }
  }, [printerProfiles]);

  useEffect(() => {
    if (isPrinterProfileDeleted && deletedPrinterProfileId) {
      setForm(prev => {
        const tempForm = { ...prev };
        delete tempForm[deletedPrinterProfileId];
        return tempForm;
      });
    }
  }, [deletedPrinterProfileId, isPrinterProfileDeleted]);

  useEffect((): void => {
    if (error) {
      showNotification({
        error: true,
        message: error,
      });
    }
  }, [error, showNotification]);

  const orderTypeOptions = useMemo(
    () =>
      (orderTypes || []).map(o => ({
        value: o.code,
        label: o.name,
      })),
    [orderTypes],
  );

  const onDeletePrinterProfile = useCallback(
    (index: number, id: string): void => {
      if (!id) {
        const currentPrinterProfilesCount = Object.keys(form).length;
        const updatedPrinterProfiles = newPrinterProfiles;
        newPrinterProfiles.splice(index - currentPrinterProfilesCount, 1);
        setNewPrinterProfiles([...updatedPrinterProfiles]);
      } else {
        setDeletedPrinterProfileId(id);
        deletePrinterProfile(id);
      }
    },
    [deletePrinterProfile, newPrinterProfiles, form],
  );

  useEffect((): void => {
    if (isPrinterProfileDeleted) {
      closeModal();
      showNotification({
        success: true,
        message: translate(
          'backOfficePrinterProfiles.printerProfileDeletedSuccessfully',
        ),
      });
    }
  }, [isPrinterProfileDeleted, showNotification, translate, closeModal]);

  useEffect((): void => {
    if (updatedPrinterProfileIds && updatedPrinterProfileIds.length > 0) {
      showNotification({
        success: true,
        message: translate(
          `${
            updatedPrinterProfileIds.length > 1
              ? 'backOfficePrinterProfiles.printerProfilesUpdatedSuccessfully'
              : 'backOfficePrinterProfiles.printerProfileUpdatedSuccessfully'
          }`,
        ),
      });
    }
  }, [updatedPrinterProfileIds, showNotification, translate]);

  useEffect((): void => {
    if (createdPrinterProfileIds && createdPrinterProfileIds.length > 0) {
      showNotification({
        success: true,
        message: translate(
          `${
            createdPrinterProfileIds.length > 1
              ? 'backOfficePrinterProfiles.printerProfilesCreatedSuccessfully'
              : 'backOfficePrinterProfiles.printerProfileCreatedSuccessfully'
          }`,
        ),
      });
      setNewPrinterProfiles([]);
    }
  }, [
    createdPrinterProfileIds,
    showNotification,
    translate,
    setNewPrinterProfiles,
  ]);

  const onChangePrinterProfile = useCallback(
    (
      index: number,
      id: string,
      key: string,
      value: string | string[] | boolean,
    ): void => {
      if (!id) {
        const currentPrinterProfilesCount = Object.keys(form).length;
        const updatedPrinterProfiles = newPrinterProfiles;
        updatedPrinterProfiles[index - currentPrinterProfilesCount] = {
          ...updatedPrinterProfiles[index - currentPrinterProfilesCount],
          [key]: value,
          printerProfileType: PrinterProfileType.KITCHEN,
        };
        setNewPrinterProfiles([...updatedPrinterProfiles]);
      } else {
        setForm(form => ({
          ...form,
          [id]: {
            ...form[id],
            printerProfileType: PrinterProfileType.KITCHEN,
            isChanged: true,
            [key]: value,
          },
        }));
      }
    },
    [form, newPrinterProfiles],
  );

  const onPressSave = useCallback((): void => {
    const printerProfilesToUpdate = Object.values(form)
      .filter(printerProfile => printerProfile.isChanged)
      .map(printerProfile =>
        pick(printerProfile, [
          'id',
          'name',
          'printerProfileType',
          'locale',
          'orderTypes',
          'singleItemPrinting',
        ]),
      );

    const checkProfileName = (
      printerProfile: CreatePrinterProfileInput | PrinterProfile,
    ) => printerProfile.name && isNotEmpty(printerProfile.name);

    const isNameValid =
      newPrinterProfiles.every(checkProfileName) &&
      printerProfilesToUpdate.every(checkProfileName);

    if (!isNameValid) {
      showNotification({
        error: true,
        message: translate('backOfficePrinterProfiles.enterPrinterProfileName'),
      });
    } else {
      if (newPrinterProfiles.length > 0)
        createPrinterProfiles(newPrinterProfiles);
      if (printerProfilesToUpdate.length > 0)
        updatePrinterProfiles(printerProfilesToUpdate);
    }
  }, [
    createPrinterProfiles,
    updatePrinterProfiles,
    newPrinterProfiles,
    showNotification,
    translate,
    form,
  ]);

  const onPressCreateNew = useCallback(() => {
    setNewPrinterProfiles([
      ...newPrinterProfiles,
      {} as CreatePrinterProfileInput,
    ]);
  }, [newPrinterProfiles]);

  const onSearchTextChange: (value: string) => void = useCallback(
    (value: string) => {
      setSearchString(value);
    },
    [],
  );

  const printerProfilesData = useMemo(() => {
    const result = [
      ...Object.values(form),
      ...(newPrinterProfiles as unknown as PrinterProfile[]),
    ];
    if (searchString) {
      return result.filter(
        ({ id, name }: PrinterProfile) =>
          id && new RegExp(searchString, 'i').test(name),
      );
    }
    return result;
  }, [form, newPrinterProfiles, searchString]);

  return (
    <ScreenLayout
      title="Printer Profiles | Oolio"
      loading={loading}
      onSave={onPressSave}
    >
      <Section
        layoutWidth="medium"
        title={translate('backOfficePrinterProfiles.printerProfiles')}
        subtitle={translate(
          'backOfficePrinterProfiles.printerProfilesDescription',
        )}
      >
        <View style={styles.filtersContainer}>
          <Search
            testID="search-printer-profiles"
            onChangeText={onSearchTextChange}
            containerStyle={styles.searchContainer}
            placeholder={translate('backOfficePrinterProfiles.searchByName')}
          />
          <CreateButton onPress={onPressCreateNew} />
        </View>
        <View style={styles.tableContainer}>
          <View style={theme.tables.header}>
            <Text style={[theme.tables.headerText, styles.headerName]}>
              {translate('backOfficePrinterProfiles.printerProfileName')}
            </Text>
            <Text style={[theme.tables.headerText, styles.headerOrderType]}>
              {translate('backOfficePrinterProfiles.printerProfileOrderType')}
            </Text>
            <Text style={[theme.tables.headerText, styles.headerLocale]}>
              {translate('backOfficePrinterProfiles.printerProfileLocale')}
            </Text>
            <Text
              style={[theme.tables.headerText, styles.headerSingleItemPrinting]}
            >
              {translate('backOfficePrinterProfiles.singleItemPrinting')}
            </Text>
          </View>
          <View>
            {printerProfilesData.map((profile: PrinterProfile, i: number) => (
              <PrinterProfileRow
                key={i}
                index={i}
                printerProfile={profile}
                onChange={onChangePrinterProfile}
                onDeletePrinterProfile={onDeletePrinterProfile}
                orderTypeOptions={orderTypeOptions}
              />
            ))}
          </View>
        </View>
      </Section>
    </ScreenLayout>
  );
};
