import { createStackNavigator } from '@react-navigation/stack';
import React from 'react';
import OptionsTabs from './OptionsTabs';
import OptionGroupTabs from './OptionsGroups/OptionGroup/OptionGroupTabs';

const Stack = createStackNavigator();

const OptionsGroupsStack: React.FC = () => (
  <Stack.Navigator
    headerMode="none"
    initialRouteName="OptionsTabs"
    screenOptions={{
      animationEnabled: true,
      cardOverlayEnabled: false,
      cardShadowEnabled: false,
    }}
  >
    <Stack.Screen component={OptionsTabs} name="OptionsTabs" />
    <Stack.Screen component={OptionGroupTabs} name="CreateModifierGroupTab" />
  </Stack.Navigator>
);

export default OptionsGroupsStack;
