import React, {
  useEffect,
  useState,
  useCallback,
  useMemo,
  useRef,
} from 'react';
import { View, Text, TouchableOpacity } from 'react-native';
import { OrderStatus } from '@oolio-group/domain';
import { useTranslation } from '@oolio-group/localization';
import { useIsFocused, useNavigation } from '@react-navigation/native';
import { useNetInfo } from '@react-native-community/netinfo';
import { filterBy, endOfDay } from '../../../utils/dateHelper';
import { mapOrdersToOrdersView } from '../../../utils/OrderHistoryHelper';
import { useModal } from '@oolio-group/rn-use-modal';
import { CartProvider } from '../../../hooks/CartProvider';
import { usePrinting } from '../../../hooks/PrintingProvider';
import { useNotification } from '../../../hooks/Notification';
import { usePaymentTypes } from '../../../hooks/app/usePaymentTypes';
import { useReportSettings } from '../../../hooks/app/useReportSettings';
import { useOrderTypes } from '../../../hooks/app/orderTypes/useOrderTypes';
import { useOrdersHistory } from '../../../hooks/app/orders/useOrdersHistory';
import format from 'date-fns/format';
import { orderBy } from 'lodash';
import styles from './OrderHistory.styles';
import theme from '../../../common/default-theme';
import ScreenLayout from '../../../components/POS/ScreenLayout/ScreenLayout';
import Icon from '../../../components/Icon/Icon';
import Search from '../../../components/Shared/Search/Search';
import TreatPicker from '../../../components/Shared/Select/Picker';
import ButtonIcon from '../../../components/Shared/TreatButton/ButtonIcon';
import DateRangePicker from '../../../components/POS/Modals/DateRangePicker/DateRangePicker';
import OrderHistoryTable from './Table/OrderHistoryTable';
import OrderPreview from '../../../components/POS/SidePanels/OrderPreview';
import Message from '../../../components/Office/Message/Message';
import { useLazyQuery } from '@apollo/client/react/hooks';
import { GET_MERCHANT_DETAIL_STORE_QUERY } from '../../../graphql/store';
import { useSession } from '../../../hooks/app/useSession';
import { OrderHistoryItem } from '../../POS/Orders/OpenOrders/OpenOrders.types';

export interface PaymentTypeDisplay {
  id: string;
  name: string;
  amount: number;
}

export enum DateRangeFilter {
  'Today' = 'T',
  'Yesterday' = 'Y',
  'Custom' = 'C',
}

interface OrderFilter {
  fromDateTime: number;
  toDateTime: number;
  searchText?: string;
  orderType?: string;
  paymentType?: string;
}

const getValidFilter = (filter: Record<string, string | number>) => {
  return Object.keys(filter).reduce((map, key) => {
    if (filter[key]) map[key] = filter[key];
    return map;
  }, {} as Record<string, string | number>);
};

const itemsPerPage = 10;

const OrderHistory: React.FC = () => {
  const searchTextRef = useRef('');

  const netInfo = useNetInfo();
  const isFocused = useIsFocused();
  const navigation = useNavigation();
  const { translate } = useTranslation();
  const { showModal, closeModal } = useModal();
  const { showNotification } = useNotification();
  const { printBill, reprintKitchenDocket, openCashDrawer } = usePrinting();

  const {
    dateTime,
    getDateTime,
    loading: getDateTimeSettingLoading,
  } = useReportSettings({ fetchPolicy: 'cache-first' });

  const { paymentTypes, status } = usePaymentTypes({
    fetchPolicy: 'cache-first',
  });

  const { getOrderTypes, orderTypes } = useOrderTypes({
    fetchPolicy: 'cache-first',
  });
  const [session] = useSession();

  const [getMerchantCode, { data }] = useLazyQuery(
    GET_MERCHANT_DETAIL_STORE_QUERY,
    {
      fetchPolicy: 'no-cache',
      variables: { id: session.currentStore?.id },
    },
  );

  useEffect(() => {
    isFocused && getMerchantCode();
  }, [getMerchantCode, isFocused]);

  const {
    loading: completedOrderLoading,
    orders: completedOrders,
    getPaginatedOrders: getPaginatedCompletedOrders,
    getOrderById,
    updateCachedRefundOrder,
    onFetchMore: onFetchMoreCompletedOrders,
  } = useOrdersHistory();

  const {
    loading: refundedOrdersLoading,
    orders: refundedOrders,
    getPaginatedOrders: getPaginatedRefundOrders,
    onFetchMore: onFetchMoreRefundedOrders,
  } = useOrdersHistory();

  const [hours, setHours] = useState(0);
  const [, setMonth] = useState('');
  const [minutes, setMinutes] = useState(0);
  const [ready, setReady] = useState(false);
  const [searchText, setSearchText] = useState<string>('');
  const [filter, setFilter] = useState<string | undefined>();
  const [selectedOrder, setSelectedOrder] = useState<OrderHistoryItem | null>(
    null,
  );
  const [dateFilter, setDateFilter] = useState(translate('orderHistory.today'));
  const [orderFilter, setOrderFilter] = useState<OrderFilter>({
    fromDateTime: filterBy('T') as number,
    toDateTime: endOfDay(new Date()),
    orderType: 'all',
    paymentType: 'all',
  });
  const [currentPage, setCurrentPage] = useState(1);

  const isOffline = netInfo?.isConnected === false;

  const onFetchOrderHistory = useCallback(
    (searchText?: string) => {
      orderFilter.searchText =
        searchText !== undefined ? searchText : searchTextRef.current;

      const filter = getValidFilter(
        orderFilter as unknown as Record<string, string | number>,
      );
      if (filter.orderType === 'all') filter.orderType = '';
      if (filter.paymentType === 'all') filter.paymentType = '';

      getPaginatedCompletedOrders({
        status: OrderStatus.COMPLETED,
        ...filter,
      });
      getPaginatedRefundOrders({
        status: OrderStatus.REFUNDED,
        ...filter,
      });
      setCurrentPage(1);
    },
    [getPaginatedCompletedOrders, getPaginatedRefundOrders, orderFilter],
  );

  useEffect(() => {
    getDateTime();
    getOrderTypes();
  }, [getDateTime, getOrderTypes]);

  useEffect(() => {
    if (dateTime) {
      const startingHour = parseInt(dateTime.startTime.split(':')[0]);
      const startingHourMinutes = parseInt(dateTime.startTime.split(':')[1]);
      setHours(startingHour);
      setMinutes(startingHourMinutes);
      setMonth(dateTime.startMonth);
      setOrderFilter(pre => {
        return {
          ...pre,
          fromDateTime: new Date(pre?.fromDateTime as number).setHours(
            startingHour,
            startingHourMinutes,
          ),
        };
      });
      setReady(true);
    }
  }, [dateTime]);

  useEffect(() => {
    if (status.error) {
      showNotification({
        error: true,
        message: status.error,
      });
    }
  }, [status.error, showNotification]);

  useEffect(() => {
    if (!!orderFilter?.fromDateTime && !!orderFilter?.toDateTime) {
      if (filter === DateRangeFilter.Today) {
        setDateFilter(translate('orderHistory.today'));
      } else if (filter === DateRangeFilter.Yesterday) {
        setDateFilter(translate('orderHistory.yesterday'));
      } else
        setDateFilter(
          `${format(
            orderFilter?.fromDateTime.valueOf(),
            'dd/MM/yy',
          )} to ${format(orderFilter?.toDateTime.valueOf(), 'dd/MM/yy')}`,
        );
    }
  }, [filter, orderFilter?.fromDateTime, orderFilter?.toDateTime, translate]);

  const onPressPrintReceipt = useCallback(
    async (orderId: string, nthPaymentToPrint?: number) => {
      const order = await getOrderById(orderId);
      if (order) {
        const result = await printBill(order, nthPaymentToPrint);
        if (result && Object.keys(result)?.length > 0 && result.error) {
          showNotification(result);
        }
      }
    },
    [getOrderById, printBill, showNotification],
  );

  const onPressReprintDocket = useCallback(
    async (orderId: string) => {
      const order = await getOrderById(orderId);
      if (order) {
        const result = await reprintKitchenDocket(order, order.orderItems);
        if (result && Object.keys(result)?.length > 0 && result.error) {
          showNotification(result);
        }
      }
    },
    [getOrderById, reprintKitchenDocket, showNotification],
  );

  const allOrders = useMemo(() => {
    return orderBy(
      [...completedOrders, ...refundedOrders],
      'updatedAt',
      'desc',
    );
  }, [completedOrders, refundedOrders]);

  const filteredData = useMemo((): OrderHistoryItem[] => {
    return mapOrdersToOrdersView(allOrders, orderTypes || []);
  }, [orderTypes, allOrders]);

  const orderTypeOptions = useMemo(
    () =>
      [
        {
          value: 'all',
          label: translate('orderHistory.allOrderTypes') as string,
        },
      ].concat(
        (orderTypes || []).map(o => ({
          value: o.id,
          label: o.name,
        })),
      ),
    [orderTypes, translate],
  );

  const onSelectCustomDates = useCallback(
    (startDate?: Date, endDate?: Date) => {
      setFilter(DateRangeFilter.Custom);
      if (!!startDate && !!endDate) {
        setDateFilter(
          `${format(
            new Date(startDate).setHours(hours, minutes),
            'dd/MM/yy',
          )} to ${format(
            new Date(endDate).setHours(hours, minutes),
            'dd/MM/yy',
          )}`,
        );
        const fromDateTime = new Date(startDate).setHours(hours, minutes);
        const toDateTime =
          startDate.valueOf() === endDate.valueOf()
            ? endOfDay(new Date(endDate.valueOf() + 86400000))
            : endOfDay(new Date(endDate));
        setOrderFilter(pre => ({ ...pre, fromDateTime, toDateTime }));
      }
    },
    [hours, minutes],
  );

  const onPressDateRange = useCallback(
    () =>
      showModal(
        <DateRangePicker
          value={{
            start: new Date(orderFilter?.fromDateTime),
            end: new Date(orderFilter?.toDateTime),
          }}
          onSelectDate={onSelectCustomDates}
        />,
        {
          onBackdropPress: closeModal,
        },
      ),
    [
      closeModal,
      onSelectCustomDates,
      orderFilter?.fromDateTime,
      orderFilter?.toDateTime,
      showModal,
    ],
  );

  const paymentTypeOptions = useMemo(
    () => [
      { value: 'all', label: translate('orderHistory.allPaymentTypes') },
      ...(paymentTypes || []).map(p => ({ value: p.id, label: p.name })),
    ],
    [translate, paymentTypes],
  );

  const onPageChange = useCallback(
    (page: number) => {
      setCurrentPage(page);
      // calculate to fetch more
      const totalPagesRequired = Math.ceil(allOrders.length / itemsPerPage);
      if (page >= totalPagesRequired - 1) {
        onFetchMoreCompletedOrders();
        onFetchMoreRefundedOrders();
      }
    },
    [allOrders.length, onFetchMoreCompletedOrders, onFetchMoreRefundedOrders],
  );

  const onChangeOrderFilter = useCallback(
    (key: keyof OrderFilter, value: string) => {
      setOrderFilter(pre => ({ ...pre, [key]: value }));
      setSelectedOrder(null);
    },
    [],
  );

  const onSelectOrderDetail = useCallback((order: OrderHistoryItem): void => {
    setSelectedOrder(order);
  }, []);

  const onStartSearch = useCallback(
    (text: string) => {
      setSearchText(text);
      onFetchOrderHistory(text);
      setSelectedOrder(null);
    },
    [onFetchOrderHistory],
  );

  useEffect(() => {
    if (!isFocused || !ready) return;
    onFetchOrderHistory();
  }, [onFetchOrderHistory, isFocused, ready]);

  useEffect(() => {
    searchTextRef.current = searchText;
  }, [searchText]);

  const loading =
    completedOrderLoading || refundedOrdersLoading || getDateTimeSettingLoading;

  return (
    <ScreenLayout
      title={translate('navigation.orderHistory')}
      loading={loading}
      onBack={() => navigation.goBack()}
    >
      <View>
        {isOffline ? (
          <Message
            type="negative"
            message={translate('refundOrder.noInternet')}
          />
        ) : null}
        <View style={styles.filters}>
          <TouchableOpacity
            testID="btn-dates"
            disabled={loading}
            onPress={onPressDateRange}
            style={styles.datePicker}
          >
            <Text numberOfLines={1} style={styles.dateLabel}>
              {filter ? dateFilter : translate('orderHistory.today')}
            </Text>
            <Icon name="angle-down" size={20} color={theme.colors.dark} />
          </TouchableOpacity>
          <TreatPicker
            testID="select-orderType"
            options={orderTypeOptions}
            selectedValue={orderFilter?.orderType || ''}
            editable={!isOffline}
            onValueChange={onChangeOrderFilter.bind(null, 'orderType')}
            containerStyle={[styles.dropdown, styles.orderTypeDropdown]}
          />
          <TreatPicker
            testID="select-payType"
            options={paymentTypeOptions}
            selectedValue={orderFilter?.paymentType || ''}
            onValueChange={onChangeOrderFilter.bind(null, 'paymentType')}
            editable={!isOffline}
            containerStyle={styles.dropdown}
          />
          <Search
            testID="search-orders"
            value={searchText}
            onPressEnter={onStartSearch}
            editable={!isOffline}
            placeholder={translate('orderHistory.searchPlaceholder')}
            containerStyle={styles.search}
          />
          <ButtonIcon
            testID="btn-refresh"
            size={44}
            icon="sync"
            type="neutral"
            disabled={loading || isOffline}
            onPress={onFetchOrderHistory.bind(null, searchText)}
          />
        </View>
        <OrderHistoryTable
          data={filteredData}
          isOffline={isOffline}
          onPressRow={onSelectOrderDetail}
          onPressPrintReceipt={onPressPrintReceipt}
          onPressReprintDocket={onPressReprintDocket}
          currentPage={currentPage}
          onPageChange={onPageChange}
          itemsPerPage={itemsPerPage}
        />
      </View>
      <CartProvider>
        <OrderPreview
          order={selectedOrder as OrderHistoryItem}
          onClose={() => setSelectedOrder(null)}
          onCompleteRefund={updateCachedRefundOrder}
          merchantCode={data?.store?.merchantCode}
          openCashDrawer={openCashDrawer}
        />
      </CartProvider>
    </ScreenLayout>
  );
};

export default OrderHistory;
