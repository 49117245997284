import React, { useCallback, useMemo } from 'react';
import Section from '../../../../../components/Office/Section/Section';
import { useCurrency, useTranslation } from '@oolio-group/localization';
import { View } from 'react-native';
import theme from '../../../../../common/default-theme';
import InputToggle from '../../../../../components/Shared/Inputs/InputToggle';
import { DiscountRule } from './DiscountRule';
import { DiscountRuleModal } from './Modals/DiscountRuleModal';
import { useModal } from '@oolio-group/rn-use-modal';
import styles from './Adjustments.style';
import {
  Adjustment,
  AdjustmentUnit,
  Category,
  DiscountRuleCriteria,
  DiscountRuleType,
  Product,
} from '@oolio-group/domain';
import SelectSearch, {
  OptionsType,
} from '../../../../../components/Shared/Select/SelectSearch';
import cloneDeep from 'lodash/cloneDeep';
import Message from '../../../../../components/Office/Message/Message';
import Chip from '../../../../../components/Office/Chip/Chip';

interface AdvanceDiscountRulesProp {
  form: Adjustment;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChangeFormInput: (key: string, value: any) => void;
  categories: Record<string, Category>;
  products: Record<string, Product>;
}

const AdvanceDiscountRules: React.FC<AdvanceDiscountRulesProp> = ({
  form,
  onChangeFormInput,
  categories,
  products,
}) => {
  const { translate } = useTranslation();
  const { showModal } = useModal();
  const { formatCurrency, formatMoneyValue } = useCurrency();

  const getFormattedAmount = useCallback(
    (amount: number, type: AdjustmentUnit) => {
      if (type === AdjustmentUnit.PERCENTAGE)
        return `${formatMoneyValue(amount)}%`;
      else return formatCurrency(amount);
    },
    [formatCurrency, formatMoneyValue],
  );

  const getEntityName = (ids: string[], isCountDisplayed = true) => {
    const getName = (id: string) => {
      const entity = categories[id] || products[id];
      if (!entity) return '';
      return (entity.products?.length || entity.variants?.length) &&
        isCountDisplayed
        ? `${entity.name} - ${
            (entity.products?.length || 0) + (entity.variants?.length || 0)
          }`
        : entity.name;
    };
    return (
      ids
        ?.map(getName)
        .filter(n => n)
        ?.join(', ') || ''
    );
  };

  const isValidItemIds = (itemIds: string[]) => {
    return itemIds.some(id => id in products || id in categories);
  };

  const entityOptions: OptionsType[] = useMemo(() => {
    const allProducts = Object.values(products);
    const allCategories = Object.values(categories);
    return [...allProducts, ...allCategories].map(entity => ({
      title: entity.name,
      subtitle: (entity as Category).products ? 'Category' : 'Product',
      value: entity.id,
    }));
  }, [products, categories]);

  const showDiscountTypeModal = useCallback(() => {
    showModal(<DiscountRuleModal onChangeFormInput={onChangeFormInput} />);
  }, [showModal, onChangeFormInput]);

  const onToggleDiscountRule = () => {
    !form?.isDiscountRuleEnabled &&
      onChangeFormInput(
        'discountRuleType',
        DiscountRuleType.PRODUCT_CATEGORY_RULE,
      );
    onChangeFormInput('isDiscountRuleEnabled', !form.isDiscountRuleEnabled);
  };

  const purchaseItemIds = form.advanceRules?.purchasedCriteria?.itemIds ?? [];

  return (
    <Section
      title={translate('backofficeVenueSettingAdjustments.advancedOptions')}
      layoutWidth="small"
    >
      <>
        <View style={theme.forms.row}>
          <InputToggle
            type="switch"
            testID="discount-rule"
            onToggle={onToggleDiscountRule}
            isToggled={form.isDiscountRuleEnabled ?? false}
            title={translate('backofficeVenueSettingAdjustments.discountRules')}
            containerStyle={theme.forms.inputFluid}
            subtitle={translate(
              'backofficeVenueSettingAdjustments.discountRulesDescription',
            )}
          />
        </View>

        {form.isDiscountRuleEnabled ? (
          <>
            <DiscountRule
              ruleType={form.discountRuleType}
              iconType="angle-down"
              onClick={showDiscountTypeModal}
            />

            <Message
              message={translate(
                'backofficeVenueSettingAdjustments.productOrCategoryDiscountInfoText',
              )}
              type={'neutral'}
              containerStyle={styles.topSpacing}
              icon="info-circle"
            />

            <SelectSearch
              testID="search-product-or-category"
              options={entityOptions}
              selectedOptions={cloneDeep(purchaseItemIds)}
              onChangeOption={val => {
                onChangeFormInput('purchasedCriteria', {
                  ...form.advanceRules?.purchasedCriteria,
                  itemIds: val,
                } as DiscountRuleCriteria);
              }}
              containerStyle={styles.topSpacing}
              placeholder={translate(
                'backofficeVenueSettingAdjustments.productOrCategorySearchBox',
              )}
            />

            <View style={styles.zIndex}>
              {purchaseItemIds?.length > 0 && (
                <View style={styles.selectedItemChipContainer}>
                  {purchaseItemIds.map(id => {
                    const title = getEntityName([id]);
                    if (!title) return null;
                    return (
                      <Chip
                        title={title}
                        icon="times"
                        key={id}
                        onPress={() => {
                          onChangeFormInput('purchasedCriteria', {
                            ...form.advanceRules?.purchasedCriteria,
                            itemIds: purchaseItemIds.filter(
                              itemId => itemId !== id,
                            ),
                          } as DiscountRuleCriteria);
                        }}
                      />
                    );
                  })}
                </View>
              )}
              {form.amount &&
              purchaseItemIds?.length > 0 &&
              isValidItemIds(purchaseItemIds) ? (
                <Message
                  message={translate(
                    'backofficeVenueSettingAdjustments.productOrCategoryDiscountInfoValue',
                    {
                      productsOrCategories: getEntityName(
                        purchaseItemIds,
                        false,
                      ),
                      amount: getFormattedAmount(
                        +form.amount,
                        form.adjustmentUnit,
                      ),
                    },
                  )}
                  type={'neutral'}
                  containerStyle={styles.topSpacing}
                />
              ) : null}
            </View>
          </>
        ) : null}
      </>
    </Section>
  );
};

export default AdvanceDiscountRules;
