import { StyleSheet } from 'react-native';
import theme from '../../../../common/default-theme';

const styles = StyleSheet.create({
  container: {
    paddingVertical: 18,
    paddingHorizontal: 16,
    alignItems: 'center',
    flexDirection: 'row',
    borderBottomWidth: 1,
    borderBottomColor: theme.colors.black1,
  },
  logoContainer: {
    width: 44,
    height: 44,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: theme.radius.s,
    backgroundColor: theme.colors.deepPurple,
  },
  initialsText: {
    fontSize: 18,
    letterSpacing: -0.5,
    color: theme.colors.white,
    textTransform: 'uppercase',
    fontFamily: theme.fonts.bold,
  },
  infoContainer: {
    flex: 1,
    marginRight: 10,
    flexDirection: 'row',
  },
  infoTextContainer: {
    flex: 1,
    marginLeft: 10,
  },
  iconContainer: {
    marginLeft: 6,
    alignItems: 'center',
    justifyContent: 'center',
  },
  infoText: {
    fontSize: 14,
    lineHeight: 20,
    letterSpacing: -0.2,
    color: theme.colors.white,
  },
});

export default styles;
