import React from 'react';
import { View } from 'react-native';
import { IntegrationApps, Order, OrderItemStatus } from '@oolio-group/domain';
import CartNote from '../../../../../components/POS/Cart/CartNote/CartNote';
import CartProduct from '../../../../../components/POS/Cart/CartProduct/CartProduct';

interface CartSectionProps {
  order: Order;
}

const CartSection: React.FC<CartSectionProps> = ({ order }) => {
  const showCancelledOrders =
    order?.integrationInfo?.app === IntegrationApps.OOM;
  return (
    <View>
      {order.orderNote ? (
        <CartNote testID="order-note" note={order.orderNote} />
      ) : null}
      {order.orderItems
        .filter(
          item =>
            showCancelledOrders || item.status !== OrderItemStatus.CANCELLED,
        )
        .map((item, i) => (
          <CartProduct testID={`order-item-${i}`} key={i} product={item} />
        ))}
    </View>
  );
};

export default CartSection;
