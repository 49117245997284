import { useCallback, useMemo, useState, useEffect } from 'react';
import { useSession } from './useSession';
import { useOrders } from './orders/useOrders';
import { useUser } from './useUser';
import { NetworkStatus } from '@apollo/client';
import { useSetUserSession } from './useSetUserSession';
import { useNotification } from '../Notification';
import { useTranslation } from '@oolio-group/localization';
import { FeatureIDs } from '@oolio-group/domain';
import { useIntegrationPartners } from './useIntegrationPartners/useIntegrationPartners';
import { syncAppUtility } from '../../state/syncAppUtility';
import { useLoyalty } from './loyalty/useLoyalty';
import { useCheckFeatureEnabled } from './features/useCheckFeatureEnabled';
import { isEqual } from 'lodash';
export interface useSettingsProps {
  syncApp: () => void;
  loading: boolean;
}

export const useSettingsSync = (): useSettingsProps => {
  const [session] = useSession();
  const { refetchOrdersFromServer } = useOrders();
  const { user, refetchUser, networkStatus: userNetworkStatus } = useUser();
  const { showNotification } = useNotification();
  const { translate } = useTranslation();
  const { setUserSession, user: currentUser } = useSetUserSession();
  const [syncLoading, setSyncLoading] = useState<boolean>(false);
  const { getIntegrationPartnerSettings } = useIntegrationPartners();
  const isFeatureEnabled = useCheckFeatureEnabled();
  const isLoyaltyEnabled = isFeatureEnabled(FeatureIDs.LOYALTY);
  const { getLoyaltyPrograms } = useLoyalty({
    venueId: session.currentVenue?.id,
  });

  const syncApp = useCallback(async () => {
    setTimeout(() => setSyncLoading(true), 100);
    syncAppUtility.syncApp(Date.now());
    await refetchOrdersFromServer();
    refetchUser();
    if (isLoyaltyEnabled) {
      getLoyaltyPrograms();
    }
    if (session.currentStore?.id) {
      getIntegrationPartnerSettings({ store: session.currentStore?.id });
    }
    setTimeout(() => setSyncLoading(false), 100);
  }, [
    getIntegrationPartnerSettings,
    getLoyaltyPrograms,
    isLoyaltyEnabled,
    refetchOrdersFromServer,
    refetchUser,
    session.currentStore?.id,
  ]);

  useEffect(() => {
    if (
      user &&
      syncLoading &&
      !isEqual(user, currentUser) &&
      userNetworkStatus &&
      userNetworkStatus == NetworkStatus.ready
    ) {
      setUserSession(user);
      setSyncLoading(false);
    }
  }, [currentUser, setUserSession, syncLoading, user, userNetworkStatus]);

  useEffect(() => {
    if (userNetworkStatus === NetworkStatus.error && syncLoading) {
      setSyncLoading(false);
      showNotification({
        error: true,
        message: `${translate('settings.syncErrorOffline')}`,
      });
    }
  }, [showNotification, syncLoading, translate, userNetworkStatus]);

  return useMemo(
    () => ({
      syncApp: syncApp,
      loading: syncLoading,
    }),
    [syncApp, syncLoading],
  );
};
