import { StyleSheet } from 'react-native';
import theme from '../../../../common/default-theme';

const styles = StyleSheet.create({
  // eslint-disable-next-line react-native/no-color-literals
  background: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.8)',
  },
  container: {
    width: 400,
  },
  header: {
    marginBottom: 8,
    flexDirection: 'row',
    alignItems: 'center',
    borderRadius: theme.radius.s,
    backgroundColor: theme.colors.white,
    ...theme.shadow.strong,
  },
  btnModal: {
    width: 44,
    height: 44,
    alignItems: 'center',
    justifyContent: 'center',
  },
  title: {
    flex: 1,
    fontSize: 14,
    lineHeight: 20,
    textAlign: 'center',
    color: theme.colors.black1,
    fontFamily: theme.fonts.medium,
    marginRight: 44,
  },
  body: {
    padding: 30,
    borderRadius: theme.radius.s,
    backgroundColor: theme.colors.white,
    ...theme.shadow.strong,
  },
  input: {
    height: 70,
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
    borderRadius: theme.radius.m,
    backgroundColor: theme.colors.teritiary2,
    marginBottom: 20,
  },
  inputText: {
    flex: 1,
    fontSize: 24,
    textAlign: 'center',
    fontFamily: theme.fonts.semibold,
    color: theme.colors.states.focus,
  },
  btnClear: {
    right: 10,
    width: 44,
    height: 44,
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
  },
  actions: {
    marginTop: 20,
  },
  segments: {
    marginBottom: 20,
  },
  numpad: {
    width: '100%',
    flexWrap: 'wrap',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  btnNumber: {
    height: 70,
    width: '32.5%',
    marginVertical: '0.8%',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: theme.radius.s,
    backgroundColor: theme.colors.grey1,
  },
  btnNumberText: {
    fontSize: 18,
    textAlign: 'center',
    fontFamily: theme.fonts.semibold,
  },
  btnZero: {
    flex: 1,
    marginRight: '1.4%',
  },
});

export default styles;
