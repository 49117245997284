import React from 'react';
import { TouchableOpacity, View } from 'react-native';
import { Modifier as ModifierDefault } from '@oolio-group/domain';
import { useCurrency } from '@oolio-group/localization';
import { getBestPriceOfModifier } from '@oolio-group/catalog-helper';
import styles from '../../Options.styles';
import theme from '../../../../../../common/default-theme';
import Icon from '../../../../../../components/Icon/Icon';
import InputText from '../../../../../../components/Shared/Inputs/InputText';
import ButtonIcon from '../../../../../../components/Shared/TreatButton/ButtonIcon';
import Select from '../../../../../../components/Shared/Select/Select';

interface Modifier extends ModifierDefault {
  tax?: string;
  isSelected?: boolean;
  priceAmount?: string;
}

interface ModifierRowProps {
  modifier: Modifier;
  onChangeModifier: (currentId: string, newId: string, index: number) => void;
  availableModifiers: { value: string; label: string }[];
  onDeleteRow: (currentId: string, index: number) => void;
  rowIndex: number;
  onAddModifier: (name: string, modifierIdToReplace: string) => void;
  onChangePrice: (modId: string, value: string) => void;
  drag?: () => void;
  isOnlyShowProductAsOption: boolean;
  isOnlyShowModifierOption: boolean;
}

export const ModifierRow: React.FC<ModifierRowProps> = ({
  modifier,
  onChangeModifier,
  onDeleteRow,
  rowIndex,
  availableModifiers,
  onAddModifier,
  onChangePrice,
  drag,
  isOnlyShowProductAsOption,
}) => {
  const { currencySymbol } = useCurrency();
  // Explanation for below Below logic -
  // Pick modifier's amount for an option, or
  // Take the best price amongst all `pricingGroups`, or
  // Return 0 is nothing matches
  const price =
    (modifier?.price?.amount !== undefined && modifier?.price?.amount !== null
      ? modifier?.price?.amount
      : getBestPriceOfModifier(modifier as ModifierDefault)) || 0;

  return (
    <TouchableOpacity style={theme.tables.row} onLongPress={drag}>
      <View style={theme.tables.disclosure}>
        <Icon name="draggabledots" size={20} color={theme.colors.grey5} />
      </View>
      <Select
        testID="select-options"
        options={availableModifiers}
        selectedValue={modifier.id}
        onValueChange={value => onChangeModifier(modifier.id, value, rowIndex)}
        onAddOption={
          !isOnlyShowProductAsOption
            ? name => onAddModifier(name, modifier.id)
            : undefined
        }
        containerStyle={styles.cellOptions}
      />
      <InputText
        testID="input-price"
        value={price.toString()}
        placeholder="0.00"
        label={currencySymbol}
        onChangeText={val => onChangePrice(modifier.id, val)}
        alignText="right"
        containerStyle={styles.cellPrice}
      />
      <ButtonIcon
        testID="btn-deleteMod"
        type="negativeLight"
        icon="trash-alt"
        onPress={() => onDeleteRow(modifier.id, rowIndex)}
      />
    </TouchableOpacity>
  );
};
