// Design System
// Default Theme File

import { useState, useEffect } from 'react';
import { Dimensions, Platform, ViewStyle, TextStyle } from 'react-native';
import { useReactiveVar } from '@apollo/client/react/hooks';
import { failedPrintJobsCountVar } from '../state/cache';
import { fonts, fontSize, lineHeight, sharedTextStyles } from './typography';
import palette from './colours.json';

// Fixed Consts
const ACTIONS_BAR_HEIGHT = 68;
const PRINT_ERROR_BANNER_HEIGHT = 56;

// Hooks
// To calculate full window width and height as the user resizes their window
const useResponsiveDimensions = () => {
  const [windowDimensions, setWindowDimensions] = useState(
    Dimensions.get('window'),
  );

  useEffect(() => {
    const updateDimensions = () => {
      const newDimensions = Dimensions.get('window');
      setWindowDimensions(newDimensions);
    };

    if (Platform.OS === 'web') {
      window.addEventListener('resize', updateDimensions);
      return () => window.removeEventListener('resize', updateDimensions);
    } else {
      const dimensionSubscription = Dimensions.addEventListener(
        'change',
        updateDimensions,
      );
      return () => dimensionSubscription.remove();
    }
  }, []);

  return windowDimensions;
};

// To calculate the remaining height of the window when print error banner is shown/hidden
const useSafeHeight = () => {
  const { height: vh } = useResponsiveDimensions();
  const [safeHeight, setSafeHeight] = useState(vh - ACTIONS_BAR_HEIGHT);

  const failedPrintJobsCount = useReactiveVar<number>(failedPrintJobsCountVar);

  useEffect(() => {
    if (failedPrintJobsCount > 0) {
      setSafeHeight(vh - (ACTIONS_BAR_HEIGHT + PRINT_ERROR_BANNER_HEIGHT));
    } else {
      setSafeHeight(vh - ACTIONS_BAR_HEIGHT);
    }
  }, [failedPrintJobsCount, vh]);

  return safeHeight;
};

// Colors

const colors = {
  light: palette.white,
  dark: palette.black,
  states: {
    positive: palette.green,
    positiveXL: palette.greenLightest,
    neutral: palette.blue,
    neutralXL: palette.blueLightest,
    focus: palette.orange,
    focusXL: palette.orangeLightest,
    negative: palette.red,
    negativeXL: palette.redLightest,
  },
  pools: {
    allColours: [
      {
        name: 'Pink',
        color: palette.pink,
      },
      {
        name: 'Purple',
        color: palette.purple,
      },
      {
        name: 'Blue',
        color: palette.blue,
      },
      {
        name: 'Light Blue',
        color: palette.lightBlue,
      },
      {
        name: 'Green',
        color: palette.green,
      },
      {
        name: 'Light Green',
        color: palette.lightGreen,
      },
      {
        name: 'Yellow',
        color: palette.yellow,
      },
      {
        name: 'Amber',
        color: palette.amber,
      },
      {
        name: 'Orange',
        color: palette.orange,
      },
      {
        name: 'Deep Orange',
        color: palette.deepOrange,
      },
      {
        name: 'Deep Purple',
        color: palette.deepPurple,
      },
      {
        name: 'Indigo',
        color: palette.indigo,
      },
      {
        name: 'Cyan',
        color: palette.cyan,
      },
      {
        name: 'Teal',
        color: palette.teal,
      },
      {
        name: 'Lime',
        color: palette.lime,
      },
    ],
    products: [
      {
        name: 'Deep Purple',
        color: palette.deepPurple,
      },
      {
        name: 'Pink',
        color: palette.pink,
      },
      {
        name: 'Purple',
        color: palette.purple,
      },
      {
        name: 'Blue',
        color: palette.blue,
      },
      {
        name: 'Light Blue',
        color: palette.lightBlue,
      },
      {
        name: 'Green',
        color: palette.green,
      },
      {
        name: 'Light Green',
        color: palette.lightGreen,
      },
      {
        name: 'Yellow',
        color: palette.yellow,
      },
      {
        name: 'Amber',
        color: palette.amber,
      },
      {
        name: 'Orange',
        color: palette.orange,
      },
      {
        name: 'Deep Orange',
        color: palette.deepOrange,
      },
    ],
    pages: [
      {
        name: 'Teal',
        color: palette.teal,
      },
      {
        name: 'Deep Purple',
        color: palette.deepPurple,
      },
      {
        name: 'Indigo',
        color: palette.indigo,
      },
      {
        name: 'Cyan',
        color: palette.cyan,
      },
      {
        name: 'Lime',
        color: palette.lime,
      },
    ],
    tables: [
      {
        name: 'Occupied',
        color: palette.blue,
      },
      {
        name: 'Inactive',
        color: palette.orange,
      },
      {
        name: 'Done Soon',
        color: palette.green,
      },
      {
        name: 'Split',
        color: palette.purple,
      },
      {
        name: 'Available',
        color: palette.grey3,
      },
    ],
    dataViz: [
      palette.blue,
      palette.amber,
      palette.deepPurple,
      palette.teal,
      palette.redDark,
      palette.greenDark,
      palette.grey5,
    ],
  },
  variations: palette.white,
  modifiers: palette.white,
  functions: palette.black,
  interface1: palette.teritiary1,
  interface2: palette.teritiary2,
  interface3: palette.teritiary3,
  ...palette,
};

// Text Styles

const textStyles = {
  button: {
    dark: {
      ...sharedTextStyles.button,
      color: colors.dark,
    },
    light: {
      ...sharedTextStyles.button,
      color: colors.light,
    },
    cancel: {
      ...sharedTextStyles.button,
      color: palette.grey6,
    },
    disable: {
      ...sharedTextStyles.button,
      color: palette.grey5,
    },
    positive: {
      ...sharedTextStyles.button,
      color: colors.states.positive,
    },
    neutral: {
      ...sharedTextStyles.button,
      color: colors.states.neutral,
    },
    focus: {
      ...sharedTextStyles.button,
      color: colors.states.focus,
    },
    negative: {
      ...sharedTextStyles.button,
      color: colors.states.negative,
    },
  },
  table: {
    header: {
      ...sharedTextStyles.table.regular,
      color: palette.grey6,
    },
    default: {
      ...sharedTextStyles.table.regular,
      color: colors.dark,
    },
    reports: {
      active: {
        ...sharedTextStyles.table.medium,
        color: colors.dark,
      },
      inactive: {
        ...sharedTextStyles.table.medium,
        color: palette.grey6,
      },
    },
  },
  input: {
    title: {
      ...sharedTextStyles.input.title,
      color: palette.grey6,
    },
    subtitle: {
      ...sharedTextStyles.input.subtitle,
      color: palette.grey5,
    },
    label: {
      ...sharedTextStyles.input.label,
      color: palette.grey6,
    },
    value: {
      ...sharedTextStyles.input.value,
      color: palette.black2,
    },
    placeholder: {
      ...sharedTextStyles.input.value,
      color: palette.grey5,
    },
  },
  hotkey: {
    light: {
      ...sharedTextStyles.hotkey,
      color: colors.light,
    },
    dark: {
      ...sharedTextStyles.hotkey,
      color: colors.dark,
    },
    hidden: {
      ...sharedTextStyles.hotkey,
      color: palette.grey5,
    },
  },
  message: {
    light: {
      ...sharedTextStyles.message,
      color: colors.states.focus,
    },
    positive: {
      ...sharedTextStyles.message,
      color: colors.states.positive,
    },
    neutral: {
      ...sharedTextStyles.message,
      color: colors.states.neutral,
    },
    focus: {
      ...sharedTextStyles.message,
      color: colors.states.focus,
    },
    negative: {
      ...sharedTextStyles.message,
      color: colors.states.negative,
    },
  },
  modal: {
    title: {
      positive: {
        ...sharedTextStyles.modal.title,
        color: colors.states.positive,
      },
      neutral: {
        ...sharedTextStyles.modal.title,
        color: colors.states.neutral,
      },
      focus: {
        ...sharedTextStyles.modal.title,
        color: colors.states.focus,
      },
      negative: {
        ...sharedTextStyles.modal.title,
        color: colors.states.negative,
      },
    },
  },
  option: {
    dark: {
      ...sharedTextStyles.option,
      color: colors.dark,
    },
    positive: {
      ...sharedTextStyles.option,
      color: colors.states.positive,
    },
    neutral: {
      ...sharedTextStyles.option,
      color: colors.states.neutral,
    },
    negative: {
      ...sharedTextStyles.option,
      color: colors.states.negative,
    },
    subtitle: {
      ...sharedTextStyles.optionSubtitle,
    },
  },
  segment: {
    active: {
      ...sharedTextStyles.segment,
      color: colors.dark,
    },
    inactive: {
      ...sharedTextStyles.segment,
      color: palette.grey5,
    },
  },
  status: {
    positive: {
      ...sharedTextStyles.status,
      color: colors.states.positive,
    },
    neutral: {
      ...sharedTextStyles.status,
      color: colors.states.neutral,
    },
    focus: {
      ...sharedTextStyles.status,
      color: colors.states.neutral,
    },
    negative: {
      ...sharedTextStyles.status,
      color: colors.states.negative,
    },
  },
  sticker: {
    dark: {
      ...sharedTextStyles.sticker,
      color: colors.states.positive,
    },
    grey: {
      ...sharedTextStyles.sticker,
      color: colors.states.neutral,
    },
    light: {
      ...sharedTextStyles.sticker,
      color: colors.states.neutral,
    },
    amber: {
      ...sharedTextStyles.sticker,
      color: palette.amber,
    },
    blue: {
      ...sharedTextStyles.sticker,
      color: palette.blue,
    },
    green: {
      ...sharedTextStyles.sticker,
      color: palette.green,
    },
    red: {
      ...sharedTextStyles.sticker,
      color: palette.red,
    },
    teal: {
      ...sharedTextStyles.sticker,
      color: palette.teal,
    },
  },
};

// Spacings

const spacing = {
  xxs: 4,
  xs: 8,
  s: 12,
  r: 14,
  m: 16,
  l: 20,
  xl: 30,
  xxl: 40,
};

// Layout Widths

const layoutWidth = {
  xs: 340,
  s: 540,
  m: 800,
  l: 1024,
  full: '100%',
};

// Borders

const border = {
  solid: {
    borderWidth: 1,
    borderColor: palette.grey3,
    borderStyle: 'solid',
  },
  dashed: {
    borderWidth: 1,
    borderColor: palette.grey3,
    borderStyle: 'dashed',
  },
};

// Shadows

const shadow = {
  light: {
    shadowColor: palette.black,
    shadowOpacity: 0.15,
    shadowOffset: { width: 0, height: 0 },
    shadowRadius: 6,
    elevation: 3,
  },
  strong: {
    shadowColor: palette.black,
    shadowOpacity: 0.3,
    shadowOffset: { width: 0, height: 0 },
    shadowRadius: 6,
    elevation: 6,
  },
};

// Border Radii

const radius = {
  s: 4,
  m: 6,
  l: 10,
};

// Containers

const containers = {
  enabled: {
    backgroundColor: palette.white,
    ...border.solid,
  },
  enabledAlt: {
    backgroundColor: palette.grey1,
    ...border.solid,
  },
  active: {
    backgroundColor: palette.white,
    ...border.solid,
    ...shadow.light,
  },
  disabled: {
    backgroundColor: palette.grey1,
  },
  default: {
    backgroundColor: palette.white,
  },
  positive: {
    backgroundColor: colors.states.positiveXL,
  },
  neutral: {
    backgroundColor: colors.states.neutralXL,
  },
  focus: {
    backgroundColor: colors.states.focusXL,
  },
  negative: {
    backgroundColor: colors.states.negativeXL,
  },
  note: {
    backgroundColor: palette.amberLightest,
    borderWidth: 1,
    borderColor: palette.amber,
    borderStyle: 'solid',
  },
};

// Common Styles

const tables = {
  header: {
    height: 38,
    flexDirection: 'row',
    alignItems: 'center',
    paddingHorizontal: 12,
    borderRadius: radius.s,
    backgroundColor: colors.grey1,
  } as ViewStyle,
  headerText: {
    fontSize: 14,
    color: colors.grey6,
  } as TextStyle,
  row: {
    paddingVertical: 12,
    flexDirection: 'row',
    alignItems: 'center',
    borderBottomWidth: 1,
    borderBottomColor: colors.grey3,
  } as ViewStyle,
  disclosure: {
    width: 34,
    height: 34,
    alignItems: 'center',
    justifyContent: 'center',
  } as ViewStyle,
  emptyView: {
    height: 200,
    width: '100%',
    marginTop: 10,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: radius.s,
    backgroundColor: colors.grey1,
  } as ViewStyle,
  emptyText: {
    lineHeight: 20,
    color: colors.grey6,
    textAlign: 'center',
  } as TextStyle,
};

const forms = {
  row: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 20,
  } as ViewStyle,
  inputFluid: {
    flex: 1,
  } as ViewStyle,
  inputHalf: {
    width: 260,
  } as ViewStyle,
  inputThird: {
    width: 120,
  } as ViewStyle,
};

// Icons

const icons = {
  style: {
    dark: colors.dark,
    darkGrey: palette.grey7,
    grey: palette.grey5,
    lightGrey: palette.grey4,
    light: colors.light,
    positive: colors.states.positive,
    neutral: colors.states.neutral,
    focus: colors.states.focus,
    negative: colors.states.negative,
  },
  add: 'plus',
  remove: 'times',
  tick: 'check',
  edit: 'pen',
  more: 'ellipsis-h',
  delete: 'trash-alt',
  clear: 'times-circle',
  backspace: 'backspace',
  settings: 'cog',
  increase: 'plus-circle',
  decrease: 'minus-circle',
  back: 'arrow-left',
  search: 'search',
  drag: 'draggabledots',
  success: 'check-circle',
  info: 'info-circle',
  help: 'question-circle',
  warning: 'exclamation-triangle',
  error: 'exclamation-octagon',
  networkError: 'wifi-slash',
};

export default {
  colors,
  textStyles,
  spacing,
  layoutWidth,
  border,
  shadow,
  radius,
  containers,
  tables,
  forms,
  icons,
  fonts,
  fontSize,
  lineHeight,
  useSafeHeight,
  useResponsiveDimensions,
};
