import { useQuery } from '@apollo/client/react/hooks';
import { useMemo } from 'react';
import { CHANNELS_STORES_PAGES_QUERY } from '../../graphql/settings';
import { parseApolloError } from '../../utils/errorHandlers';
import { Feature } from '@oolio-group/domain';

export interface useAvailabilityOptionsProps {
  venues: {
    value: string;
    label: string;
    stores: { value: string; label: string }[];
    features: Feature[];
  }[];
  pages: { value: string; label: string }[];
  salesChannels: {
    value: string;
    label: string;
    orderTypes: { value: string; label: string }[];
  }[];
  orderTypes: { value: string; label: string }[];
  stores: { value: string; label: string; venue: { id: string } }[];
  loading: boolean;
  error: string | undefined;
  refetchAvailabilityData?: () => void;
}

export function useAvailabilityOptions(): useAvailabilityOptionsProps {
  const {
    loading,
    error,
    data: channelsStoresPagesQuery,
    refetch,
  } = useQuery(CHANNELS_STORES_PAGES_QUERY, {
    fetchPolicy: 'cache-and-network',
  });

  return useMemo(
    () => ({
      venues: channelsStoresPagesQuery?.venues || [],
      pages: channelsStoresPagesQuery?.pages || [],
      salesChannels: channelsStoresPagesQuery?.salesChannels || [],
      orderTypes: channelsStoresPagesQuery?.orderTypes || [],
      stores: channelsStoresPagesQuery?.stores || [],
      loading,
      error: error ? parseApolloError(error) : undefined,
      refetchAvailabilityData: refetch,
    }),
    [
      channelsStoresPagesQuery?.orderTypes,
      channelsStoresPagesQuery?.pages,
      channelsStoresPagesQuery?.salesChannels,
      channelsStoresPagesQuery?.stores,
      channelsStoresPagesQuery?.venues,
      error,
      loading,
      refetch,
    ],
  );
}
