import React, { useCallback } from 'react';
import { View, Text } from 'react-native';
import { PaymentAction, OrderStatus } from '@oolio-group/domain';
import { useCurrency, useTranslation } from '@oolio-group/localization';
import { useCartContext as useCart } from '../../../../hooks/CartProvider';
import {
  OrderPaymentDetails,
  PaymentActionProp,
} from '../../../../screens/POS/Payments/PaymentScreen';
import theme from '../../../../common/default-theme';
import styles from '../PaymentOptions.styles';
import Icon from '../../../Icon/Icon';
import OnHoldOrderOptions from '../OnHoldPaymentActions';
import PaymentHeader from '../PaymentHeader/PaymentHeader';
import TreatButton from '../../../Shared/TreatButton/TreatButton';
import ButtonActions from '../../../Shared/TreatButton/ButtonActions';

export interface PaymentCompleteProps {
  testId?: string;
  showPrintReceiptButton: boolean;
  orderPaymentDetails: OrderPaymentDetails;
  changeDueAmount: number;
  receivedAmount: number;
  loyaltyAction: React.ReactElement | null;
  earnedPointInfo?: string;
  dispatch: React.Dispatch<PaymentActionProp>;
  orderStatus?: OrderStatus;
  onPressPrintReceipt: () => void;
  onPressSendReceipt: () => void;
  onCompleteSale: () => void;
  onTakeNextPayment: () => void;
  onPrintSplit: () => void;
}

const PaymentComplete: React.FC<PaymentCompleteProps> = ({
  orderPaymentDetails,
  onPressPrintReceipt,
  onPressSendReceipt,
  onCompleteSale,
  onTakeNextPayment,
  showPrintReceiptButton,
  changeDueAmount,
  receivedAmount,
  onPrintSplit,
  loyaltyAction,
  earnedPointInfo,
  dispatch,
}) => {
  const {
    isSplitPayment,
    remainingDueAmount,
    isPaymentCompleted,
    totalDueAmount,
    isCustomerBalancePayment,
    isSplitBySeat,
    processingPaymentSeat,
    nthPayment,
  } = orderPaymentDetails;
  const { translate } = useTranslation();
  const { formatCurrency } = useCurrency();
  const { order, updateCart, openOrderCart } = useCart();

  const handleNextStepAction = useCallback(() => {
    if (isPaymentCompleted) {
      onCompleteSale();
    } else {
      onTakeNextPayment();
    }
  }, [isPaymentCompleted, onCompleteSale, onTakeNextPayment]);

  const onCloseSubPaymentScreen = useCallback(() => {
    dispatch({ type: PaymentAction.CANCEL_CURRENT_STEP, payload: {} });
  }, [dispatch]);

  const renderSendReceiptButton = () => (
    <TreatButton
      testID="btn-sendReceipt"
      type="neutralLight"
      height={50}
      label={translate('common.sendReceipt')}
      onPress={onPressSendReceipt}
      // eslint-disable-next-line react-native/no-inline-styles
      containerStyle={{ flex: 1, marginLeft: 5 }}
    />
  );

  return (
    <View style={styles.container}>
      <PaymentHeader
        title={
          isSplitBySeat
            ? translate('payment.splitPaymentBySeat', {
                seat: processingPaymentSeat,
              })
            : nthPayment < 1 && isPaymentCompleted
            ? translate('cdsPaymentScreen.paymentComplete')
            : translate('payment.splitPaymentStep', {
                step: nthPayment,
              })
        }
        onClose={!isPaymentCompleted ? onCloseSubPaymentScreen : undefined}
      />
      <View style={styles.content}>
        <View style={styles.response}>
          <View style={styles.iconSuccess}>
            <Icon name="check" size={30} color={theme.colors.states.positive} />
          </View>
          <Text testID="change-due" style={styles.textPrimary}>
            {changeDueAmount
              ? translate('payment.changeDueAmount', {
                  changeDue: formatCurrency(changeDueAmount || 0),
                })
              : translate('payment.noChangeDue')}
          </Text>
          <Text testID="amount-paid" style={styles.textSecondary}>
            {translate('payment.amountPaid', {
              amount: formatCurrency(receivedAmount || 0),
            })}
          </Text>
          {isPaymentCompleted && earnedPointInfo ? (
            <Text testID="points-earned" style={styles.textSecondary}>
              {earnedPointInfo}
            </Text>
          ) : null}
          {!isPaymentCompleted ? (
            <Text testID="remaining-amt" style={styles.textContext}>
              {translate('payment.remainingAmountOutOfTotal', {
                willRemainingAmount: formatCurrency(remainingDueAmount),
                totalDueAmount: formatCurrency(totalDueAmount),
              })}
            </Text>
          ) : null}
        </View>
        <View>
          <OnHoldOrderOptions
            updateCart={updateCart}
            openOrderCart={openOrderCart}
            order={order}
          />
          <View style={styles.actionRow}>
            <TreatButton
              testID="btn-nextStep"
              height={60}
              type="positive"
              label={
                isPaymentCompleted
                  ? translate('payment.completeSale')
                  : translate('payment.takeNextPayment')
              }
              onPress={handleNextStepAction}
              // eslint-disable-next-line react-native/no-inline-styles
              containerStyle={{ flex: 1 }}
            />
          </View>
          <View style={styles.actionRow}>
            {showPrintReceiptButton ? (
              !isCustomerBalancePayment ? (
                !isSplitPayment ? (
                  <>
                    <TreatButton
                      testID="btn-printReceipt"
                      height={50}
                      type="neutralLight"
                      label={translate('payment.printReceipt')}
                      onPress={onPressPrintReceipt}
                      containerStyle={styles.btnPrintReceipt}
                    />
                    {renderSendReceiptButton()}
                  </>
                ) : isPaymentCompleted ? (
                  <>
                    <ButtonActions
                      testID="btn-printOptions"
                      height={50}
                      type="neutralLight"
                      label="Print Options"
                      actions={[
                        {
                          label: translate('payment.printSplit'),
                          action: onPrintSplit,
                        },
                        {
                          label: translate('common.printReceipt'),
                          action: onPressPrintReceipt,
                        },
                      ]}
                      containerStyle={styles.btnPrintReceipt}
                    />
                    {renderSendReceiptButton()}
                  </>
                ) : (
                  <TreatButton
                    testID="btn-printSplit"
                    height={50}
                    type="neutralLight"
                    label={translate('payment.printSplit')}
                    onPress={onPrintSplit}
                    containerStyle={styles.btnPrintReceipt}
                  />
                )
              ) : null
            ) : null}
            {loyaltyAction}
          </View>
        </View>
      </View>
    </View>
  );
};

export default PaymentComplete;
