import {
  Text,
  View,
  FlatList,
  ViewStyle,
  TextStyle,
  TouchableOpacity,
} from 'react-native';
import React, { useState, ReactElement, useCallback } from 'react';
import Button from '../Button/Button';
import Popover, { PopoverPlacement } from 'react-native-popover-view';
import theme from '../../common/default-theme';
import styles from './ButtonWithActionSheet.styles';
import Icon from '../Icon/Icon';

// FIXME: this restricting the types (not doing this right away as it needs some changes in component)
// this should be a generic type, so that it can be used with any sort of data
export interface ActionList {
  label: string;
  action: () => void;
  icon?: string;
  iconColor?: string;
  textStyle?: TextStyle;
  id?: string;
}

export interface ButtonWithActionSheetProps {
  actionList: ActionList[];
  title?: string;
  scrollEnabled?: boolean;
  maxItemsToShow?: number;
  contentStyle?: ViewStyle;
  containerStyle?: ViewStyle;
  customDropDownStyle?: ViewStyle;
  iconContainerStyle?: ViewStyle;
  btnTextlabelStyle?: TextStyle;
  btnContainerStyle?: ViewStyle;
  iconColor?: string;
  dropDownTitleStyle?: TextStyle;
  arrowVisible?: boolean;
  icon?: string;
  PopoverViewPlacement?:
    | 'top'
    | 'right'
    | 'bottom'
    | 'left'
    | 'center'
    | 'auto';
}

const ButtonWithActionSheet: React.FC<ButtonWithActionSheetProps> = ({
  actionList,
  title,
  scrollEnabled,
  customDropDownStyle,
  iconColor,
  arrowVisible = true,
  containerStyle,
  icon,
  btnTextlabelStyle,
  btnContainerStyle,
}: ButtonWithActionSheetProps) => {
  const [showOptions, setShowOptions] = useState(false);

  const onRequestClosePopover = useCallback(() => {
    setShowOptions(false);
  }, []);

  const closeDropDown = useCallback((): void => {
    setShowOptions(false);
  }, []);

  const onBlur = useCallback(
    (e): void => {
      const targetEvent = (
        e.nativeEvent as unknown as {
          relatedTarget: unknown;
        }
      )?.relatedTarget;
      !targetEvent && closeDropDown();
    },
    [closeDropDown],
  );

  const onToggleOptions = useCallback((): void => {
    setShowOptions(!showOptions);
  }, [showOptions]);

  const handlePress = useCallback(
    (action: Function) => {
      action();
      closeDropDown();
    },
    [closeDropDown],
  );

  const onToggle = useCallback(
    (action: Function) => {
      onToggleOptions();
      setTimeout(() => {
        handlePress(action);
      }, 500);
    },
    [handlePress, onToggleOptions],
  );

  return (
    <View style={containerStyle}>
      <Popover
        isVisible={showOptions}
        placement={PopoverPlacement.AUTO}
        popoverStyle={styles.container}
        backgroundStyle={styles.backgroundStyle}
        onRequestClose={() => onRequestClosePopover()}
        arrowSize={!arrowVisible ? styles.arrowHidden : undefined}
        from={
          <TouchableOpacity
            onBlur={onBlur}
            onPress={onToggleOptions}
            testID="btn-parent"
            style={[styles.popupButton, btnContainerStyle]}
          >
            <Text style={btnTextlabelStyle}>{title}</Text>
            <Icon
              size={20}
              name={icon || 'angle-down'}
              color={iconColor || theme.colors.white}
            />
          </TouchableOpacity>
        }
      >
        <View style={[styles.optionsContainer, customDropDownStyle]}>
          {title && <Text style={styles.titleText}>{title}</Text>}
          <FlatList
            data={actionList}
            scrollEnabled={scrollEnabled}
            renderItem={({ item, index }): ReactElement => {
              return (
                <Button
                  fluid
                  testID="btn-child"
                  key={index}
                  title={item.label}
                  iconPosition={'right'}
                  onPress={() => onToggle(item.action)}
                  labelStyle={
                    (item.icon
                      ? [styles.optionLabelStyle, item.textStyle]
                      : [styles.optionLabelStyle, item.textStyle]) as TextStyle
                  }
                  containerStyle={styles.optionContainer}
                  icon={item.icon || 'angle-right'}
                  iconProps={{
                    color: item.iconColor || theme.colors.grey5,
                    size: 24,
                  }}
                />
              );
            }}
            // FIXME: added this `index` as fallback key, this should be refactored as per above generic type issue and keeping `index` as key should be avoided in future components while building itself.
            keyExtractor={(item: ActionList, index: number) =>
              item.id || index.toString()
            }
          />
        </View>
      </Popover>
    </View>
  );
};

export default ButtonWithActionSheet;
