import { useApolloClient } from '@apollo/client/react/hooks';
import { App, Store, User, Venue } from '@oolio-group/domain';

import { Tracer } from '@oolio-group/tracer-client';
import * as Sentry from '@sentry/browser';
import { useCallback, useState } from 'react';
import { Platform } from 'react-native';
import { GET_USER_QUERY } from '../../graphql/session';
import { Session } from '../../state/Session';
import { onboardingUtility } from '../../state/onboardingUtility';
import { userUtility } from '../../state/userUtility';
import { useIntercom } from '../Intercom/useIntercom';
import { useOrderNumber } from '../orders/useOrderNumber';
import { useDeviceId } from './useDeviceId';
import { useSession } from './useSession';
import { fetchSsoProfile } from '../../utils/sso';

export const useUserProfile = () => {
  const { deviceId: uniqDeviceId } = useDeviceId();
  const { setOrderCounter } = useOrderNumber();
  const [, setSession] = useSession();
  const { register } = useIntercom();
  const [me, setMe] = useState<User>();
  const client = useApolloClient();

  const registerUserToIntercom = useCallback(
    (session: Partial<Session>) => {
      const IntercomUser = {
        email: session?.user?.email || '',
        userId: session?.user?.id || '',
        name: session?.user?.name || '',
        companies: [
          {
            ...Platform.select({
              web: {
                companyId: session?.currentOrganization?.id || '',
              },
              native: {
                id: session?.currentOrganization?.id || '',
              },
            }),
            name: session?.currentOrganization?.name || '',
            customAttributes: {
              venue: session?.currentVenue?.name || '',
              store: session?.currentStore?.name || '',
              abn: session?.currentOrganization?.businessIdentifier || '',
            },
          },
        ],
      };
      register(IntercomUser);
    },
    [register],
  );

  const getMe = useCallback(async () => {
    const response = await client.query<{ me: User }>({
      query: GET_USER_QUERY,
      context: {
        headers: {
          store: '',
          venue: '',
          device: '',
        },
      },
      fetchPolicy: 'no-cache',
    });
    const user = response.data.me;
    const organizations = user?.organizations || [];
    const currentOrganization = organizations?.[0];
    if (currentOrganization) {
      Sentry.setUser({ email: user.email });
      Sentry.setTag('organization', currentOrganization.id);
      Sentry.setTag('organization_name', currentOrganization.name);
    }

    if (currentOrganization?.onboarding) {
      onboardingUtility.setOnboardingInfo(currentOrganization.onboarding);
    } else {
      onboardingUtility.clearOnboardingInfo();
    }

    let currentStore = undefined as unknown as Store;
    const currentVenue = (user?.venues as Venue[])?.find(venue => {
      const store = venue?.stores?.find(store =>
        store.devices.some(device => device.uuid === uniqDeviceId),
      );
      if (store) {
        currentStore = store;
      }
      return !!store;
    });

    const currentDevice = currentStore?.devices?.find(
      device => device.uuid === uniqDeviceId,
    );

    currentDevice &&
      setOrderCounter(currentDevice.previousOrder?.orderNumber || '');

    const defaultDeviceProfile = currentStore?.deviceProfiles?.find(
      deviceProfile =>
        !!currentDevice && deviceProfile.id === currentDevice.deviceProfile?.id,
    );

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let currentDeviceProfile: any;
    if (defaultDeviceProfile) {
      currentDeviceProfile = Object.assign({}, defaultDeviceProfile);
      currentDeviceProfile.defaultOrderType =
        defaultDeviceProfile?.defaultOrderType ||
        defaultDeviceProfile?.orderTypes?.[0];
    }

    const ssoProfile = await fetchSsoProfile();

    const newSession: Session = {
      activeApp: App.BACKOFFICE,
      user,
      currentOrganization,
      availableOrganizations: organizations,
      ssoOrganizations: ssoProfile?.organizations,
      currentVenue,
      currentStore,
      deviceProfile: currentDeviceProfile,
      device: currentDevice,
      settings: {
        showScrollSetting: true,
        showAdvancedCartActionsSetting: true,
      },
    };
    Tracer.getInstance().setMeta({
      user: {
        id: user.id,
        email: user.email,
      },
      organization: currentOrganization.id,
    });
    setSession(newSession);
    registerUserToIntercom(newSession);

    userUtility.addOfficeUser(user);
    setMe(user);
    return user;
  }, [
    client,
    registerUserToIntercom,
    setOrderCounter,
    setSession,
    uniqDeviceId,
  ]);

  return {
    getMe,
    me,
  };
};
