import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  View,
  Text,
  TouchableOpacity,
  Dimensions,
  Platform,
} from 'react-native';
import {
  Order,
  OrderAction,
  OrderPaymentStatus,
  OrderStatus,
  OrderType,
  OrderTypeCode,
  Product,
  Resource,
  Table,
  VoidOrderEvent,
  VoidReason,
} from '@oolio-group/domain';
import { cloneJSON } from '@oolio-group/client-utils';
import { useCurrency, useTranslation } from '@oolio-group/localization';
import { useModal } from '@oolio-group/rn-use-modal';
import format from 'date-fns/format';
import keyBy from 'lodash/keyBy';
import { useSession } from '../../../../../hooks/app/useSession';
import {
  inventoryProductFragment,
  useProducts,
} from '../../../../../hooks/app/products/useProducts';
import usePOSUserAuthorization from '../../../../../hooks/app/users/usePOSUserAuthorization';
import { useCartContext as useCart } from '../../../../../hooks/CartProvider';
import { useSyncItemAvailability } from '../../../../../hooks/app/orders/useSyncItemAvailability';
import { useNotification } from '../../../../../hooks/Notification';
import {
  getAgeFormat,
  getDurationFormat,
} from '../../../../../utils/dateHelper';
import { productQuantitiesToReturnOnVoidOrder } from '../../../../../utils/OpenOrdersHelper';
import { PaymentTypeDisplay } from '../OpenOrders.types';
import { VoidOrderModal } from '../../../../../components/POS/Modals/VoidOrder/VoidOrder';
import styles from '../OpenOrders.styles';
import theme from '../../../../../common/default-theme';
import Icon from '../../../../../components/Icon/Icon';
import Sticker from '../../../../../components/Shared/Sticker/Sticker';
import Pagination from '../../../../../components/POS/Pagination/Pagination';
import ButtonIcon from '../../../../../components/Shared/TreatButton/ButtonIcon';
import TreatButton from '../../../../../components/Shared/TreatButton/TreatButton';
import PrintReceiptButton from '../../../../../components/POS/Buttons/PrintReceipt/PrintReceiptButton';

export interface OrderTypeDisplayProps extends OrderType {
  colorId: number;
  code: string;
}

export interface OpenOrdersDataProps {
  id: string;
  orderNumber: string;
  orderItems: Array<Partial<Product>>;
  createdAt: number;
  updatedAt?: number;
  customer: string;
  staff: string;
  orderNote: string;
  orderType: OrderTypeDisplayProps;
  payTypes: Array<PaymentTypeDisplay>;
  totalValue: number;
  table: Table;
  tokenNumber?: string;
}

export interface OpenOrdersViewProps {
  data: OpenOrdersDataProps[];
  orders: Record<string, Order>;
  onSelectOrder?: (id: string) => void;
  onPressPrintReceipt: (orderId: string, nthPayment?: number) => void;
  onPressRePrintDocket: (orderId: string) => void;
  onVoidOrder: () => void;
  currentPage: number;
  setCurrentPage: (page: number) => void;
}

const OpenOrdersTable: React.FC<OpenOrdersViewProps> = ({
  data,
  orders,
  onSelectOrder,
  onPressPrintReceipt,
  onPressRePrintDocket,
  onVoidOrder: voidOrderCallback,
  currentPage,
  setCurrentPage,
}: OpenOrdersViewProps) => {
  const {
    setCartParams,
    updateCart,
    resetCart,
    clearPriorPendingEvents,
    closeOrderCart,
  } = useCart();
  const [session] = useSession();
  const { translate } = useTranslation();
  const { formatCurrency } = useCurrency();
  const { canI } = usePOSUserAuthorization();
  const { showModal, closeModal } = useModal();
  const { showNotification } = useNotification();
  const { syncItemAvailability } = useSyncItemAvailability();
  const { getProductsFromCache, updateCacheProductQuantities } = useProducts(
    undefined,
    inventoryProductFragment,
  );

  const viewWidth = Dimensions.get('window').width;
  const [isLargeScreen, setIsLargeScreen] = useState(viewWidth >= 1280);

  const currentStoreId = session.currentStore?.id ?? '';

  const onPressNote = useCallback(
    (orderNo: string, note: string) => {
      showModal(
        <View style={styles.noteContainer}>
          <View style={styles.note}>
            <Text style={styles.noteTitle}>{`${translate(
              'orderHistory.order',
            )} ${translate('moneyMovements.note')}: ${orderNo}`}</Text>
            <Text>{note}</Text>
          </View>
          <TreatButton
            testID="btn-closeNote"
            type="cancel"
            onPress={closeModal}
            label={translate('button.dismiss')}
          />
        </View>,
      );
    },
    [closeModal, showModal, translate],
  );

  const onVoidOrder = useCallback(
    async (reason: VoidReason, description: string, selectedOrder: Order) => {
      const order = orders[selectedOrder.id];

      const isAnyPaymentCompleted = (order?.payments || []).some(
        x => x.status == OrderPaymentStatus.COMPLETE,
      );

      if (isAnyPaymentCompleted) {
        return showNotification({
          error: true,
          message: translate('order.voidPartiallyPaid'),
        });
      }

      const allProducts = cloneJSON(keyBy(getProductsFromCache(), 'id'));
      const productQuantities = productQuantitiesToReturnOnVoidOrder(
        allProducts,
        order,
        currentStoreId,
      );

      if (Object.keys(productQuantities).length) {
        const updatedProducts = updateCacheProductQuantities(
          productQuantities,
          currentStoreId,
        );
        syncItemAvailability(updatedProducts);
      }

      clearPriorPendingEvents();
      await updateCart<VoidOrderEvent>(OrderAction.ORDER_VOID, {
        reason,
        description,
        productQuantities: Object.keys(productQuantities).map(key => {
          return { id: key, quantity: productQuantities[key] };
        }),
        inventoryTracked: !!Object.keys(productQuantities).length,
      });

      resetCart();
      voidOrderCallback();
      await closeOrderCart();
    },
    [
      clearPriorPendingEvents,
      closeOrderCart,
      currentStoreId,
      getProductsFromCache,
      orders,
      resetCart,
      showNotification,
      syncItemAvailability,
      translate,
      updateCacheProductQuantities,
      updateCart,
      voidOrderCallback,
    ],
  );

  const onPressVoid = useCallback(
    (orderId: string) => {
      const order = data.find(order => order.id === orderId);
      if (order) {
        const allowVoidOrder = canI([{ onResource: Resource.VOID_ORDERS }], {
          prompt: true,
        });
        if (!allowVoidOrder) return;
        setCartParams(order.id, undefined, undefined, true);
        showModal(
          <VoidOrderModal
            order={order as unknown as Order}
            onCancel={onVoidOrder}
          />,
        );
      }
    },
    [data, setCartParams, showModal, onVoidOrder, canI],
  );

  const timerStatus = useCallback((input: number) => {
    const diff = Date.now() - input;
    return diff <= 600000
      ? 'positiveLight'
      : diff > 600000 && diff <= 900000
      ? 'focusLight'
      : 'negativeLight';
  }, []);

  const pageItems = useMemo(() => {
    return data?.slice((currentPage - 1) * 10, currentPage * 10);
  }, [data, currentPage]);

  useEffect(() => {
    const updateShowLastUpdate = () => {
      const viewWidth = Dimensions.get('window').width;
      setIsLargeScreen(viewWidth >= 1280);
    };

    if (Platform.OS === 'web') {
      window.addEventListener('resize', updateShowLastUpdate);
      return () => window.removeEventListener('resize', updateShowLastUpdate);
    } else {
      const dimensionSubscription = Dimensions.addEventListener(
        'change',
        updateShowLastUpdate,
      );
      return () => dimensionSubscription.remove();
    }
  }, []);

  return (
    <View style={styles.table}>
      <View style={styles.tableHeader}>
        <Text style={[theme.tables.headerText, styles.cellType]}>
          {translate('openOrders.type')}
        </Text>
        <Text style={[theme.tables.headerText, styles.cellAge]}>
          {translate('openOrders.age')}
        </Text>
        <Text style={[theme.tables.headerText, styles.cellOrder]}>
          {translate('openOrders.order')}
        </Text>
        <Text style={[theme.tables.headerText, styles.cellDate]}>
          {translate('openOrders.placedOn')}
        </Text>
        <Text style={[theme.tables.headerText, styles.cellName]}>
          {translate('openOrders.customer')}
        </Text>
        {isLargeScreen ? (
          <Text style={[theme.tables.headerText, styles.cellName]}>
            {translate('openOrders.user')}
          </Text>
        ) : null}
        <Text style={[theme.tables.headerText, styles.cellNote]}>
          {translate('openOrders.notes')}
        </Text>
        {isLargeScreen ? (
          <Text style={[theme.tables.headerText, styles.cellTime]}>
            {translate('openOrders.lastAction')}
          </Text>
        ) : null}
        <Text style={[theme.tables.headerText, styles.headerAmount]}>
          {translate('openOrders.amount')}
        </Text>
      </View>
      <View style={styles.tableBody}>
        {pageItems.map((order: OpenOrdersDataProps, i: number) => {
          const isDineInOrder = order.orderType.code === OrderTypeCode.DINE_IN;
          return (
            <TouchableOpacity
              key={i}
              testID="row-openOrder"
              style={styles.tableRow}
              onPress={onSelectOrder?.bind(null, order.id)}
            >
              <Sticker
                testID="order-type"
                type={isDineInOrder ? 'neutralLight' : 'teal'}
                label={
                  order.table && isDineInOrder
                    ? order?.table.name
                    : order.orderType.code
                }
                containerStyle={styles.cellType}
              />
              <Sticker
                testID="order-age"
                type={timerStatus(order.createdAt)}
                label={getAgeFormat(order.createdAt)}
                containerStyle={styles.cellAge}
              />
              <Text
                testID="order-no"
                style={styles.cellOrder}
                numberOfLines={1}
              >
                {order.orderNumber.slice(0, -9)}
              </Text>
              <Text testID="order-createdAt" style={styles.cellDate}>
                {format(new Date(order.createdAt), 'dd/MM hh:mm aa')}
              </Text>
              <Text
                testID="order-customer"
                numberOfLines={1}
                style={styles.cellName}
              >
                {order.customer}
              </Text>
              {isLargeScreen ? (
                <Text
                  testID="order-user"
                  numberOfLines={1}
                  style={styles.cellName}
                >
                  {order.staff}
                </Text>
              ) : null}
              <Text
                testID="order-note"
                numberOfLines={1}
                style={styles.cellNote}
              >
                {order.orderNote}
              </Text>
              {isLargeScreen ? (
                <Text
                  testID="order-updatedAt"
                  numberOfLines={1}
                  style={styles.cellTime}
                >
                  {getDurationFormat(order.updatedAt)}
                </Text>
              ) : null}
              <Text testID="order-amount" style={styles.cellAmount}>
                {formatCurrency(order.totalValue)}
              </Text>
              <ButtonIcon
                icon="notes"
                type="neutral"
                testID="btn-note"
                disabled={!order.orderNote}
                onPress={() =>
                  onPressNote(order.orderNumber.slice(0, -9), order.orderNote)
                }
                containerStyle={styles.cellNoteBtn}
              />
              <PrintReceiptButton
                options={order.payTypes}
                onPrintReceipt={paymentIndex =>
                  onPressPrintReceipt(order.id, paymentIndex)
                }
                reprintDocket={() => onPressRePrintDocket(order.id)}
                orderStatus={OrderStatus.IN_PROGRESS}
              />
              <ButtonIcon
                icon="times"
                testID="btn-void"
                type="negativeLight"
                onPress={onPressVoid.bind(null, order.id)}
                containerStyle={styles.cellVoid}
              />
              <View style={theme.tables.disclosure}>
                <Icon name="angle-right" size={20} color={theme.colors.grey5} />
              </View>
            </TouchableOpacity>
          );
        })}
      </View>
      {data.length > 10 ? (
        <Pagination
          pageLength={10}
          page={currentPage}
          dataLength={data.length}
          onPageChange={setCurrentPage}
        />
      ) : null}
    </View>
  );
};
export default OpenOrdersTable;
