import {
  AddOrderLoyaltySnapshotEvent,
  AddRewardItemEvent,
  AdjustmentType,
  DiscountType,
  FeatureIDs,
  LoyaltySnapshot,
  OrderAction,
  RemoveRewardItemEvent,
  RewardAdjustment,
  RewardRule,
  RewardType,
  UpdateRewardItemEvent,
} from '@oolio-group/domain';
import { keyBy } from 'lodash';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useCheckFeatureEnabled } from '../app/features/useCheckFeatureEnabled';
import { useCartContext as useCart } from '../CartProvider';
import { getOptionalProperty } from '@oolio-group/client-utils';

export interface RewardMap {
  [id: string]: {
    quantity: number;
    points: number;
    type: RewardType;
    productId?: string;
    itemQuantity?: number;
  }[];
}
export interface UseRewardsInterface {
  (rewardRules: RewardRule[]): {
    rewardMap: RewardMap;
    redeemRewards: (rewardMap: RewardMap) => void;
    updateLoyaltySnapshot: (
      currentBalance: number | undefined,
      pointsEarned: number,
      pointsRedeemed: number,
    ) => void;
    getLoyaltySnapshot: (
      currentBalance: number | undefined,
      pointsEarned: number,
      pointsRedeemed: number,
    ) => LoyaltySnapshot;
  };
}

export const useRewards: UseRewardsInterface = (rewardRules: RewardRule[]) => {
  const [rewardMap, setRewardMap] = useState<RewardMap>({});
  const { order, updateCart } = useCart();
  const isFeatureEnabled = useCheckFeatureEnabled();
  const isLoyaltyEnabled = isFeatureEnabled(FeatureIDs.LOYALTY);
  const rewardRulesMap = useRef<Record<string, RewardRule>>({});

  useEffect(() => {
    rewardRulesMap.current = keyBy(rewardRules, 'id');
  }, [rewardRules]);

  const allAdjustments = useMemo(() => {
    let allAdjustments: {
      adjustment: RewardAdjustment;
      productId?: string;
    }[] = [];
    // order level rewards
    allAdjustments = allAdjustments.concat(
      (
        (order?.adjustments || []).filter(
          adj => adj.adjustmentType == AdjustmentType.REWARD,
        ) as RewardAdjustment[]
      ).map(adjustment => ({ adjustment })),
    );
    // item level rewards
    order?.orderItems?.forEach(orderItem => {
      allAdjustments = allAdjustments.concat(
        (
          (orderItem.adjustments || []).filter(
            adj => adj.adjustmentType == AdjustmentType.REWARD,
          ) as RewardAdjustment[]
        ).map(adjustment => ({
          adjustment,
          productId: orderItem.product.id,
        })),
      );
    });

    return allAdjustments;
  }, [order?.adjustments, order?.orderItems]);

  useEffect(() => {
    if (isLoyaltyEnabled) {
      const newRewardMap: RewardMap = allAdjustments.reduce((acc, curr) => {
        return {
          ...acc,
          [curr.adjustment.id]: [
            ...(acc[curr.adjustment.id] || []),
            {
              points: curr.adjustment.pointsRequired,
              type: rewardRulesMap.current[curr.adjustment.id]?.rewardType,
              productId: curr.productId,
              quantity: curr.adjustment.quantity,
              itemQuantity: curr.adjustment.itemQuantity,
            },
          ],
        };
      }, {} as RewardMap);
      setRewardMap(newRewardMap);
    }
  }, [allAdjustments, isLoyaltyEnabled]);

  const getRewardDiscountValues = (rewardRule: RewardRule) => {
    const result = {
      discountAmount: getOptionalProperty(rewardRule, 'discountAmount'),
      discountType: getOptionalProperty(rewardRule, 'discountType'),
      maximumDiscountAmount: getOptionalProperty(
        rewardRule,
        'maximumDiscountAmount',
      ),
    };

    // Edge Cases,
    // for now only applicable for FREE ITEM reward
    switch (rewardRule.rewardType) {
      case RewardType.FREE_ITEM:
        // discount 100% on item
        result.discountAmount = 100;
        result.discountType = DiscountType.PERCENTAGE;
        result.maximumDiscountAmount = undefined; // uncap
    }

    return result;
  };

  const redeemRewards = useCallback(
    (newRewardMap: RewardMap) => {
      Object.keys(newRewardMap).forEach(id => {
        newRewardMap[id].forEach(redemption => {
          let existingRewardItem: RewardAdjustment;
          if (redemption.productId) {
            const orderItem = order?.orderItems?.find(
              item => item.product.id === redemption.productId,
            );

            existingRewardItem = orderItem?.adjustments?.find(
              adj =>
                adj.id === id && adj.adjustmentType === AdjustmentType.REWARD,
            ) as RewardAdjustment;
          } else {
            existingRewardItem = order?.adjustments?.find(
              adj =>
                adj.adjustmentType === AdjustmentType.REWARD && adj.id === id,
            ) as RewardAdjustment;
          }

          if (existingRewardItem && redemption.quantity > 0) {
            // update quantity
            updateCart<UpdateRewardItemEvent>(
              OrderAction.ORDER_REWARD_UPDATE_QUANTITY,
              {
                rewardId: existingRewardItem.id,
                productId: redemption.productId,
                quantity: redemption.quantity,
                itemQuantity: redemption.itemQuantity,
              },
            );
          } else if (redemption.quantity <= 0) {
            // remove reward
            rewardMap[id] &&
              updateCart<RemoveRewardItemEvent>(
                OrderAction.ORDER_REWARD_REMOVE,
                {
                  rewardId: id,
                  productId: redemption.productId,
                },
              );
          } else {
            // new reward
            const rewardRule = rewardRules.find(reward => reward.id === id);
            rewardRule &&
              updateCart<AddRewardItemEvent>(OrderAction.ORDER_REWARD_ADD, {
                rewardId: rewardRule.id,
                rewardName: rewardRule.rewardName,
                rewardType: rewardRule.rewardType,
                pointsRequired: rewardRule.pointsRequired,
                productId: redemption.productId,
                quantity: redemption.quantity,
                itemQuantity: redemption.itemQuantity,
                ...getRewardDiscountValues(rewardRule),
              });
          }
        });
      });
      setRewardMap(newRewardMap);
    },
    [order, setRewardMap, rewardRules, updateCart, rewardMap],
  );

  const getLoyaltySnapshot = (
    currentBalance: number | undefined,
    pointsEarned: number,
    pointsRedeemed: number,
  ) => {
    const availableBalance =
      (currentBalance || 0) + pointsEarned - pointsRedeemed;

    return {
      pointsEarned,
      availableBalance,
      availableRewards: rewardRules.filter(
        reward => reward.pointsRequired <= availableBalance,
      ),
    } as LoyaltySnapshot;
  };

  const updateLoyaltySnapshot = (
    currentBalance: number | undefined,
    pointsEarned: number,
    pointsRedeemed: number,
  ) => {
    const loyaltySnapshot = getLoyaltySnapshot(
      currentBalance,
      pointsEarned,
      pointsRedeemed,
    );

    updateCart<AddOrderLoyaltySnapshotEvent>(
      OrderAction.ORDER_REWARD_ADD_SNAPSHOT,
      { loyaltySnapshot },
    );
  };

  return {
    rewardMap,
    redeemRewards,
    getLoyaltySnapshot,
    updateLoyaltySnapshot,
  };
};
