import React, { useCallback } from 'react';
import { Text, View } from 'react-native';
import { useTranslation } from '@oolio-group/localization';
import { useModal } from '@oolio-group/rn-use-modal';
import { NO_TAX, Tax, TAX_FREE_CODE } from '@oolio-group/domain';
import { CreateTaxModal } from '../Modals/CreateTaxModal';
import styles from '../Taxes.styles';
import theme from '../../../../../../common/default-theme';
import Section from '../../../../../../components/Office/Section/Section';
import CreateButton from '../../../../../../components/Office/CreateButton/CreateButton';
import InputText from '../../../../../../components/Shared/Inputs/InputText';
import ButtonIcon from '../../../../../../components/Shared/TreatButton/ButtonIcon';

interface TaxToTax {
  id: string;
  name: string;
  code: string;
  rate?: string;
  isActive?: boolean;
  taxes: Tax[];
}

interface TaxesProps {
  taxes: TaxToTax[];
  onChange: (index: number, prop: string, value: string) => void;
  openDeleteModal: (index: number, id: string, name: string) => void;
  onAddTax: (tax: TaxToTax) => void;
  isFeeModal?: boolean;
}

interface TaxesRowProps {
  tax: TaxToTax;
  onDeleteTax: (index: number, id: string, name: string) => void;
  onChange: (index: number, prop: string, value: string) => void;
  index: number;
}

const TaxesRow: React.FC<TaxesRowProps> = ({
  tax,
  onDeleteTax,
  onChange,
  index,
}: TaxesRowProps) => {
  const { translate } = useTranslation();
  const isTaxFree = tax.code === TAX_FREE_CODE;
  const isNoTax = tax.code?.toLowerCase() === NO_TAX.toLowerCase();

  return (
    (tax.taxes.length === 0 && (
      <View testID="row-tax" style={theme.tables.row}>
        <InputText
          testID="input-code"
          value={tax.code}
          placeholder={translate('backOfficeSettings.code')}
          onChangeText={onChange.bind(null, index, 'code')}
          maxLength={5}
          alignText="center"
          editable={!isNoTax}
          containerStyle={styles.cellCode}
        />
        <InputText
          testID="input-name"
          value={tax.name}
          placeholder={translate('backOfficeSettings.taxName')}
          onChangeText={onChange.bind(null, index, 'name')}
          maxLength={50}
          containerStyle={styles.cellName}
        />
        <InputText
          testID="input-rate"
          label="%"
          value={tax.rate}
          placeholder="0.00"
          onChangeText={onChange.bind(null, index, 'rate')}
          keyboardType="numeric"
          maxLength={6}
          editable={!(isTaxFree || isNoTax)}
          alignText="right"
          containerStyle={styles.cellRate}
        />
        <ButtonIcon
          testID="btn-deleteTax"
          icon="trash-alt"
          type={isTaxFree ? 'cancel' : 'negativeLight'}
          disabled={isTaxFree || isNoTax}
          onPress={(): void => onDeleteTax(index, tax.id, tax.name)}
        />
      </View>
    )) ||
    null
  );
};

export const TaxesSection: React.FC<TaxesProps> = ({
  taxes,
  onChange,
  openDeleteModal,
  onAddTax,
}: TaxesProps) => {
  const { showModal } = useModal();
  const { translate } = useTranslation();

  const onCreateNewTax = useCallback((): void => {
    showModal(<CreateTaxModal onAddTax={onAddTax} />);
  }, [showModal, onAddTax]);

  return (
    <Section title={translate('backOfficeSettings.taxes')} layoutWidth="medium">
      <View style={styles.tableContainer}>
        <View style={theme.tables.header}>
          <Text style={[theme.tables.headerText, styles.headerCode]}>
            {translate('backOfficeSettings.code')}
          </Text>
          <Text style={[theme.tables.headerText, styles.headerName]}>
            {translate('backOfficeSettings.taxName')}
          </Text>
          <Text style={[theme.tables.headerText, styles.headerRate]}>
            {translate('backOfficeSettings.rate')}
          </Text>
        </View>
        <View style={styles.tableBody}>
          {taxes.map((tax: TaxToTax, i: number) => (
            <TaxesRow
              key={i}
              tax={tax}
              onDeleteTax={openDeleteModal}
              onChange={onChange}
              index={i}
            />
          ))}
        </View>
        <CreateButton onPress={onCreateNewTax} />
      </View>
    </Section>
  );
};
