import { StyleSheet } from 'react-native';
import theme from '../../../../../common/default-theme';

const styles = StyleSheet.create({
  filtersContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 20,
  },
  dropdownContainer: {
    width: 240,
  },
  searchContainer: {
    flex: 1,
    marginHorizontal: 10,
  },
  tableContainer: {
    marginBottom: 20,
  },
  headerPrinter: {
    flex: 1,
    marginHorizontal: 12,
  },
  cellPrinting: {
    flex: 1,
  },
  rowLight: {
    width: 4,
    height: 28,
    marginRight: 10,
    borderRadius: theme.radius.s,
    backgroundColor: theme.colors.red,
  },
  codeContainer: {
    padding: 40,
    alignItems: 'center',
  },
  code: {
    padding: 20,
    fontSize: 16,
    letterSpacing: 4,
    textTransform: 'uppercase',
    fontFamily: theme.fonts.bold,
    borderRadius: theme.radius.m,
    backgroundColor: theme.colors.grey1,
  },
  info: {
    lineHeight: 20,
    textAlign: 'center',
    marginVertical: 20,
    color: theme.colors.grey6,
  },
  btnReset: {
    backgroundColor: theme.colors.white,
  },
  infoSection: {
    marginBottom: 20,
    paddingHorizontal: 12,
  },
  infoTitle: {
    fontSize: 14,
    lineHeight: 14,
    marginBottom: 6,
    color: theme.colors.grey6,
  },
});

export default styles;
