/* eslint-disable react-native/no-inline-styles */
import React, { useCallback, useMemo, useState, useEffect } from 'react';
import {
  View,
  Text,
  ScrollView,
  TouchableOpacity,
  Platform,
} from 'react-native';
import { Organization, User } from '@oolio-group/domain';
import { useNavigation, useNavigationState } from '@react-navigation/native';
import { useSession } from '../../../hooks/app/useSession';
import { useTranslation } from '@oolio-group/localization';
import { Session } from '../../../state/Session';
import { OfficeNavigationMenuTabs } from '../../../state/navigation';
import NavigationComponent from './NavigationMenu/NavigationMenu';
import NavigationContext from './NavigationContext/NavigationContext';
import Icon from '../../Icon/Icon';
import { useLogout } from '../../../hooks/app/useLogout';
import { useCheckFeatureEnabled } from '../../../hooks/app/features/useCheckFeatureEnabled';
import Search from '../../Shared/Search/Search';
import { useVenues } from '../../../hooks/app/useVenues';
import styles from './Navigation.styles';
import theme from '../../../common/default-theme';
import { analyticsService } from '../../../analytics/AnalyticsService';
import { FEATURES } from '../../../constants';

const Navigation: React.FC = () => {
  const [session, setSession] = useSession();
  const { logout } = useLogout();
  const navigation = useNavigation();
  const { translate } = useTranslation();
  const { searchVenues, getVenues } = useVenues();
  const isFeatureEnabled = useCheckFeatureEnabled();
  const route = useNavigationState(
    state => state.routes[state.routes.length - 1],
  );

  const [collapsed, setCollapsed] = useState(false);
  const [searchString, setSearchString] = useState('');
  const [showSwitcher, setShowSwitcher] = useState(false);
  const [recordSessions, setRecordSessions] = useState(false);

  useEffect(() => {
    async function getRecordSessionsFlag() {
      const isEnabled = await analyticsService.isFeatureEnabled(
        FEATURES.RECORD_SESSIONS,
      );
      setRecordSessions(isEnabled);
    }
    getRecordSessionsFlag();
  }, [recordSessions]);

  useEffect(() => {
    if (recordSessions) {
      analyticsService.stopSessionRecording();
      analyticsService.startSessionRecording();
    }
  }, [recordSessions]);

  const menuItems = useMemo(() => {
    const tabs = OfficeNavigationMenuTabs.filter(item => {
      if (item.feature) {
        return isFeatureEnabled(item.feature.featureId);
      }
      if (
        Platform.OS !== 'web' &&
        (item.title === 'Dashboard' || item.title === 'Reports')
      ) {
        return false;
      }
      return true;
    });

    return tabs;
  }, [isFeatureEnabled]);

  // Calculate current route name to show navigation active state
  // Output example: Account::Overview
  const currentRoute = useMemo(() => {
    if (route.state) {
      const stack = route.state.routes
        ? route.state.routes[route.state.routes.length - 1]
        : null;
      const stackName = stack ? stack.name : '';

      const childRoute =
        stack && stack.state
          ? stack.state.routes[stack.state.routes.length - 1]
          : null;
      const childRouteName = childRoute ? childRoute.name : '';

      return childRouteName ? `${stackName}::${childRouteName}` : stackName;
    }
    return route.name;
  }, [route.state, route.name]);

  const onSwitch = useCallback(() => {
    setShowSwitcher(closeSwitch => !closeSwitch);
  }, []);

  const onToggleSwitcher = useCallback(() => {
    analyticsService.capture('navigation', {
      event: showSwitcher ? 'switcher_opened' : 'switcher_closed',
    });
    setShowSwitcher(!showSwitcher);
    if (!collapsed) {
      setCollapsed(true);
    }
  }, [setShowSwitcher, showSwitcher, setCollapsed, collapsed]);

  const onNavigateToRoute = useCallback(
    (routeName: string) => {
      const [stack, screen] = routeName.split('::');
      navigation.navigate(stack, { screen });
      if (showSwitcher) onSwitch();
    },
    [navigation, onSwitch, showSwitcher],
  );

  const navigationMenu = useMemo(
    () => (
      <NavigationComponent
        menu={menuItems}
        active={currentRoute}
        onNavigate={onNavigateToRoute}
        collapsed={collapsed}
      />
    ),
    [menuItems, currentRoute, onNavigateToRoute, collapsed],
  );

  const {
    currentOrganization,
    availableOrganizations,
    user,
  }: Partial<Session> = session || {};

  const venuesData = useMemo(
    () => searchVenues(searchString),
    [searchString, searchVenues],
  );

  useEffect(() => {
    getVenues();
  }, [getVenues]);

  const activeOrganization = useMemo<Partial<Organization> | undefined>(
    () =>
      (availableOrganizations || []).find(
        x => x.id === currentOrganization?.id,
      ),
    [availableOrganizations, currentOrganization?.id],
  );

  const onPressTrigger = useCallback(() => {
    onSwitch && onSwitch();
  }, [onSwitch]);

  const onPressVenue = useCallback(
    (venueId: string) => {
      analyticsService.capture('navigation', {
        event: 'selected_venue',
      });
      navigation.navigate('Settings', {
        screen: 'VenueSettings',
        params: {
          venueId,
        },
      });
      onPressTrigger();
      setShowSwitcher(false);
      setCollapsed(false);
    },
    [navigation, onPressTrigger],
  );

  const onPressCollapse = useCallback(() => {
    analyticsService.capture('navigation', {
      event: 'collapsed',
    });
    setCollapsed(!collapsed);
    setShowSwitcher(false);
  }, [collapsed]);

  const onPressClose = useCallback(() => {
    analyticsService.capture('navigation', {
      event: 'switcher_closed',
    });
    setShowSwitcher(false);
    setCollapsed(false);
  }, []);

  const onPressLogout = useCallback(async () => {
    analyticsService.capture('navigation', {
      event: 'logged_out',
    });
    await logout();
  }, [logout]);

  const onSwitchOrganization = useCallback(
    orgId => {
      const nextOrg = session.availableOrganizations?.find(
        org => org.id === orgId,
      );
      if (!nextOrg) {
        const newOnboardingOrg = session.ssoOrganizations?.find(
          org => org.id === orgId,
        );
        navigation.navigate('onboarding', {
          orgId,
          orgName: newOnboardingOrg?.name,
          user: session.user?.id,
        });
      } else {
        setSession({
          ...session,
          currentOrganization: nextOrg,
        });
      }
    },
    [navigation, session, setSession],
  );

  const contextSwitcher = useMemo(() => {
    if (!showSwitcher) {
      return null;
    }

    return (
      <View testID="switcher-container" style={styles.switcherContainer}>
        <View style={styles.titleContainer}>
          <Text numberOfLines={1} style={styles.titleText}>
            {activeOrganization?.name || 'Organisation'}
          </Text>
          <TouchableOpacity onPress={onPressClose} style={styles.closeButton}>
            <Icon name="times" size={20} color={theme.colors.black} />
          </TouchableOpacity>
        </View>
        <View style={styles.venuesList}>
          <Search
            testID="search-bar"
            value={searchString}
            onChangeText={setSearchString}
            placeholder={translate('backOfficeVenues.searchVenue')}
          />
          <ScrollView testID="venues-list" style={styles.venues}>
            {venuesData.map(venue => {
              return (
                <View key={venue.id} style={{ marginBottom: 20 }}>
                  <TouchableOpacity
                    onPress={() => onPressVenue(venue.id)}
                    style={styles.venueContainer}
                  >
                    <Text>{venue.name}</Text>
                    <Icon name="angle-right" color={theme.colors.dark} />
                  </TouchableOpacity>
                  {venue.stores.map(store => {
                    return (
                      <View key={store.id} style={styles.storeContainer}>
                        <Text>{store.name}</Text>
                      </View>
                    );
                  })}
                </View>
              );
            })}
          </ScrollView>
          <ScrollView testID="org-list" style={styles.venues}>
            {session.ssoOrganizations?.map(org => {
              if (org.id === activeOrganization?.id) return null;
              return (
                <View key={org.id} style={{ marginBottom: 20 }}>
                  <TouchableOpacity
                    onPress={() => onSwitchOrganization(org.id)}
                    style={styles.venueContainer}
                  >
                    <Text>{org.name}</Text>
                    <Icon name="angle-right" color={theme.colors.dark} />
                  </TouchableOpacity>
                </View>
              );
            })}
          </ScrollView>
          <TouchableOpacity
            testID="logout-btn"
            onPress={onPressLogout}
            style={styles.logoutContainer}
          >
            <Text style={styles.logoutText}>{translate('button.logout')}</Text>
          </TouchableOpacity>
        </View>
      </View>
    );
  }, [
    showSwitcher,
    activeOrganization?.name,
    activeOrganization?.id,
    onPressClose,
    searchString,
    translate,
    venuesData,
    onPressLogout,
    onPressVenue,
    session.ssoOrganizations,
    onSwitchOrganization,
  ]);

  return (
    <>
      {showSwitcher && <View>{contextSwitcher}</View>}
      <View style={[styles.container, { width: collapsed ? 76 : 240 }]}>
        <NavigationContext
          testID="context-switcher"
          user={user as User}
          active={currentOrganization?.id || ''}
          organizations={availableOrganizations || []}
          onPress={onToggleSwitcher}
          collapsed={collapsed}
        />
        <View style={styles.menuContainer}>{navigationMenu}</View>
        <TouchableOpacity
          testID="collapse-button"
          style={styles.collapseButton}
          onPress={onPressCollapse}
        >
          <View style={styles.collapseIcon}>
            <Icon
              testID="collapse-icon"
              name={collapsed ? 'angle-double-right' : 'angle-double-left'}
              color={theme.colors.grey6}
            />
          </View>
          {!collapsed && <Text style={styles.collapseText}>Collapse</Text>}
        </TouchableOpacity>
      </View>
    </>
  );
};

export default Navigation;
