import { createStackNavigator } from '@react-navigation/stack';
import React from 'react';
import { ProductsList } from './Products/ProductList';
import ProductSettingsStack from './ProductSettings/ProductSettingsStack';
import OptionsGroupsStack from './Options/OptionsGroupsStack';
import { PriceLists } from './PriceLists/PriceLists';
import MenuTabs from './Menus/MenuTabs';
import MenuDetailNavigator from './Menus/MenuDetail/MenuDetailNavigator';
import PageDetail from './Menus/Pages/PageTabNavigator';
import Courses from './Courses/Courses';
import CourseTabNavigator from './Courses/CourseTabNavigator';
import ImportProductsTabs from './ImportExport/ImportProductsTabs';
import CategoryTabNavigator from './Categories/CategoriesTabs';
import Categories from './Categories/Categories';
import PriceListSettingsNavigator from './PriceLists/Settings/PriceListSettingsNavigator';
import ScheduleDetail from './Menus/ManageSchedule/ScheduleDetail';

const Stack = createStackNavigator();

const ProductStack: React.FC = () => (
  <Stack.Navigator
    headerMode="none"
    initialRouteName="Manage"
    screenOptions={{
      animationEnabled: true,
      cardOverlayEnabled: false,
      cardShadowEnabled: false,
    }}
  >
    <Stack.Screen component={ProductsList} name="Manage" />
    <Stack.Screen component={ProductSettingsStack} name="ProductSettings" />
    <Stack.Screen component={MenuTabs} name="Menus" />
    <Stack.Screen component={MenuDetailNavigator} name="MenuSettings" />
    <Stack.Screen component={ScheduleDetail} name="ScheduleDetail" />
    <Stack.Screen component={Categories} name="Categories" />
    <Stack.Screen component={CategoryTabNavigator} name="CategorySettings" />
    <Stack.Screen component={OptionsGroupsStack} name="Options" />
    <Stack.Screen component={Courses} name="Courses" />
    <Stack.Screen component={CourseTabNavigator} name="CourseSettings" />
    <Stack.Screen component={ImportProductsTabs} name="ImportProducts" />
    <Stack.Screen component={PriceLists} name="PriceLists" />
    <Stack.Screen
      component={PriceListSettingsNavigator}
      name="PriceListSettings"
    />
    <Stack.Screen component={PageDetail} name="PageDetail" />
  </Stack.Navigator>
);

export default ProductStack;
