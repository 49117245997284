import { useCallback, useEffect, useRef, useState } from 'react';
import {
  App,
  IntegrationPartner,
  RESERVATION_STATUS,
  Reservation,
} from '@oolio-group/domain';
import { POS_IDENTIFIER } from '../../../constants';
import { useSession } from '../useSession';
import { useApolloClient } from '@apollo/client/react/hooks/useApolloClient';
import { GET_INTEGRATION_PARTNERS } from '../useIntegrationPartners/graphql';

export interface ReservationFilters {
  date?: string;
  searchText?: string;
  statuses?: RESERVATION_STATUS[];
}
const POLLING_INTERVAL = 15000;

export const useReservations = () => {
  const [reservations, setReservations] = useState<Reservation[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>('');
  const [session] = useSession();
  const client = useApolloClient();
  const reservationsPoller = useRef<number>();
  const [integrationPartner, setIntegrationPartner] = useState<
    IntegrationPartner | undefined
  >();
  const getIntegrationPartner = useCallback(async () => {
    const result = await client.query({
      query: GET_INTEGRATION_PARTNERS,
      variables: {
        filter: {
          appName: App.OOLIO_RESERVATION,
          store: session.currentStore?.id,
        },
      },
      fetchPolicy: 'network-only',
    });
    const integrationPartner = result?.data?.integrationPartners?.[0];
    if (!integrationPartner) {
      setError('Reservation integration not found for this store');
    }

    setIntegrationPartner(integrationPartner);
  }, [client, session.currentStore?.id]);

  useEffect(() => {
    return () => {
      if (reservationsPoller.current) clearInterval(reservationsPoller.current);
    };
  }, []);

  useEffect(() => {
    getIntegrationPartner();
  }, [getIntegrationPartner]);

  const filterReservationsData = useCallback(
    (data: Reservation[], filters: ReservationFilters): Reservation[] => {
      let result = data;

      if (filters.searchText) {
        const searchText = filters.searchText.toLowerCase();
        result = result.filter(reservation => {
          if (
            searchText &&
            (reservation.phone_number.toLowerCase().includes(searchText) ||
              reservation.reference_code.toLowerCase().includes(searchText) ||
              reservation.full_name.toLowerCase().includes(searchText) ||
              reservation.table_numbers.includes(searchText))
          ) {
            return true;
          }
          return false;
        });
      }
      if (filters.statuses?.length) {
        result = result.filter(reservation => {
          if (
            filters.statuses?.some(
              status =>
                status.toLowerCase() === reservation.status.toLowerCase(),
            )
          ) {
            return true;
          }
          return false;
        });
      }
      return result;
    },
    [],
  );
  const fetchReservations = useCallback(
    async (fromDate: string, toDate: string) => {
      const posLocationId =
        integrationPartner?.preferences?.oolioReservation?.posLocationId;

      if (!posLocationId) {
        return;
      }

      try {
        const url = `${process.env.REACT_APP_RESERVATIONS_API_URL}/reservations/${POS_IDENTIFIER}/${session.currentOrganization?.id}/${posLocationId}?from=${fromDate}&to=${toDate}`;
        const response = await fetch(url, {
          headers: {
            Accept: 'application/json',
          },
        });
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        setReservations(data || []);
        setError('');
      } catch (e) {
        console.error('Fetching error:', e);
        setError('Failed to fetch reservations');
      } finally {
        setLoading(false);
      }
    },
    [integrationPartner, session.currentOrganization?.id],
  );

  const pollReservations = useCallback(
    (fromDate: string, toDate: string) => {
      if (reservationsPoller.current) {
        clearInterval(reservationsPoller.current);
      }
      fetchReservations(fromDate, toDate);
      reservationsPoller.current = setInterval(() => {
        fetchReservations(fromDate, toDate);
      }, POLLING_INTERVAL);
    },
    [fetchReservations],
  );

  return {
    reservations,
    loading,
    error,
    fetchReservations,
    pollReservations,
    filterReservationsData,
  };
};
