import { useMemo } from 'react';
import { StyleSheet } from 'react-native';
import { useFela } from 'react-fela';

export const Styles = () => {
  const { theme } = useFela();
  const rowHeight = 38;

  return useMemo(
    () =>
      StyleSheet.create({
        headerWrapper: {
          backgroundColor: theme.colors.white,
          flexDirection: 'row',
          marginVertical: 10,
          justifyContent: 'space-between',
          alignItems: 'center',
        },
        actionButtonsWrapper: {
          flexDirection: 'row',
        },
        groupByDropdownStyle: {
          width: 250,
          height: rowHeight,
        },
        iconStyle: {
          width: rowHeight,
          alignSelf: 'center',
          backgroundColor: theme.colors.highlighted,
          marginTop: 10,
          marginLeft: 10,
          borderRadius: 5,
        },
        dateSlectionStyle: {
          marginTop: 10,
          marginLeft: 10,
          flexGrow: 1,
        },
        filtersButtonStyle: {
          borderRadius: theme.radius.small,
          color: theme.colors.blue,
          backgroundColor: theme.colors.highlighted,
        },
        columnsButtonStyle: {
          width: 38,
          height: 38,
          borderRadius: theme.radius.small,
          color: theme.colors.blue,
          backgroundColor: theme.colors.highlighted,
          justifyContent: 'center',
          alignItems: 'center',
        },
        filtersIconStyle: {
          color: theme.colors.blue,
        },
        columnsIconStyle: {
          color: theme.colors.blue,
        },
        updateContainerStyle: {
          backgroundColor: theme.colors.white,
          marginTop: 10,
        },
        largeExtraPopoverStyle: {
          width: 300,
        },
        mediumExtraPopoverStyle: {
          width: 192,
        },
        searchStyle: {
          flexGrow: 1,
          height: rowHeight,
          marginLeft: theme.spacing.small,
        },
        allChannelInfo: {
          height: rowHeight,
          width: 192,
          marginLeft: theme.spacing.medium,
        },
        updateButtonStyle: {
          height: theme.button.footerButtonHeight,
          alignSelf: 'center',
          borderRadius: theme.radius.small,
          color: theme.colors.success,
          backgroundColor: theme.colors.successLight,
          marginLeft: 10,
        },
        titleStyle: {
          color: theme.colors.green,
          fontFamily: theme.font.semibold,
          textTransform: 'uppercase',
        },
        schedulePayOutWrapper: {
          flexDirection: 'row',
          justifyContent: 'space-between',
          width: '100%',
          height: rowHeight,
          backgroundColor: theme.colors.blue,
          borderRadius: theme.radius.medium,
          marginTop: theme.spacing.medium,
        },
        schedulePayOutInfo: {
          flexDirection: 'row',
          alignItems: 'center',
        },
        schedulePayOutButtonStyle: {
          backgroundColor: theme.colors.blue,
          height: rowHeight,
          marginVertical: 10,
          flexDirection: 'row',
        },

        schedulePayOutTextStyle: {
          textTransform: 'none',
          color: theme.colors.white,
          textAlign: 'left',
          fontFamily: theme.font.regular,
        },
        infoIconStyleContainer: {
          backgroundColor: theme.colors.bule,
        },
        whiteColorStyle: {
          color: theme.colors.white,
        },
        infoIconStyle: {
          color: theme.colors.white,
        },
        visibleColumnIconContainerStyle: {
          marginRight: 10,
        },
        columnlabelStyle: {
          textTransform: 'capitalize',
        },
        columnOptionContainerStyle: {
          width: '90%',
          justifyContent: 'flex-start',
          alignSelf: 'center',
          borderBottomColor: theme.colors.boxBorder,
          borderBottomWidth: 1,
          borderRadius: 0,
          height: 45,
        },
      }),
    [theme],
  );
};

export const PayOutScheduleStyle = () => {
  const { theme } = useFela();
  return useMemo(
    () =>
      StyleSheet.create({
        containerStyle: {
          borderRadius: theme.radius.large,
          backgroundColor: theme.colors.white,
          width: 612,
          height: 680,
        },
        dismissButtonContainer: {
          height: theme.input.height,
          backgroundColor: theme.colors.blue,
          marginBottom: theme.spacing.small,
          width: 100,
        },
        dismissButtonWrapper: {
          flexDirection: 'row-reverse',
          marginTop: 10,
        },
        dismissButtonStyle: {
          color: theme.colors.white,
          textTransform: 'uppercase',
        },
        groupByDropdownStyle: {
          width: 300,
          height: theme.input.height,
        },
        largeExtraPopoverStyle: {
          width: 300,
        },
        titleTextStyle: {
          color: theme.colors.blue as string,
          paddingLeft: 0,
        },
        payoutScheduleDesc: {
          padding: 10,
        },
        payoutScheduleDescText: {
          color: theme.colors.textLight,
        },
        daySelectionContainer: {
          marginTop: 20,
          marginBottom: 30,
        },
        nextPayoutDayContainer: {
          flexDirection: 'row',
          alignItems: 'center',
          backgroundColor: theme.colors.blue,
          padding: theme.spacing.small,
          borderRadius: theme.radius.small,
        },
        nextPayoutDayText: {
          color: theme.colors.white,
        },

        feeAndSurchargesContainer: {
          padding: theme.spacing.small,
          marginTop: 20,
        },
        feeAndSurchargesText: {
          color: theme.colors.blue,
          fontFamily: theme.font.medium,
          fontSize: theme.fontSize.medium,
        },
        surchargesEnabledContainer: {
          backgroundColor: theme.colors.green,
          padding: theme.spacing.small,
          borderRadius: theme.radius.small,
          flexDirection: 'row',
          alignItems: 'center',
        },
        surchargesEnabledText: {
          color: theme.colors.white,
        },
        scrollView: {
          flex: 1,
          width: '100%',
          zIndex: 0,
        },
        feeRateTableContainer: {
          marginTop: 20,
        },
        feeRateHeaderTable: {
          backgroundColor: theme.colors.greyLight,
          borderRadius: theme.radius.small,
          height: theme.input.height,
        },
        headerCellTextAlignment: {
          textAlign: 'left',
        },
        rowCellTextAlignment: {
          paddingLeft: 15,
        },
      }),
    [theme],
  );
};

export const DateRangeFiltersStyles = () => {
  const { theme } = useFela();
  const rowHeight = 38;

  return useMemo(
    () =>
      StyleSheet.create({
        pageStyle: {
          backgroundColor: theme.colors.white,
          flexDirection: 'row',
          justifyContent: 'space-between',
        },
        dateRangeType: {
          flex: 1,
          fontSize: theme.fontSize.small,
        },
        primaryFilter: {
          flex: 1,
          marginLeft: theme.spacing.small,
        },
        secondaryFilter: {
          flex: 1,
          marginLeft: theme.spacing.small,
        },
        primaryFilterLabel: {
          width: '80%',
        },
        secondaryFilterLabel: {
          width: '80%',
        },
        primaryFilterLabelText: {
          paddingLeft: theme.spacing.medium,
          color: theme.colors.primaryLightest,
          fontFamily: theme.font.medium,
          fontSize: theme.fontSize.medium,
          lineHeight: 24,
          letterSpacing: 0,
        },
        secondaryFilterLabelText: {
          paddingLeft: theme.spacing.medium,
          color: theme.colors.primaryLightest,
          fontFamily: theme.font.medium,
          fontSize: theme.fontSize.medium,
          lineHeight: 24,
          letterSpacing: 0,
        },
        datePopoverStyle: {
          width: 'auto',
        },
        timePopoverStyle: {
          width: 'auto',
        },
        singlePopoverStyle: {
          width: 'auto',
        },
        touchableOpacityStyle: {
          minHeight: rowHeight + 2,
        },
        dropDownContainerStyle: {
          paddingTop: theme.padding.medium,
          paddingBottom: theme.padding.large,
        },
        collapsedViewTextStyle: {
          justifyContent: 'center',
          fontSize: theme.fontSize.smaller,
          letterSpacing: -0.5,
          fontFamily: theme.font.regular,
          paddingHorizontal: theme.spacing.small,
        },
        popoverIconStyle: {
          marginLeft: 'auto',
          marginRight: theme.spacing.small,
        },
        startDate: {
          width: '90%',
          margin: 'auto',
        },
        startDateInput: {
          width: 50,
          height: rowHeight,
        },
        startDateText: {
          paddingHorizontal: theme.padding.large,
        },
        startTimeInput: {
          width: 60,
          height: rowHeight,
        },
        startTimeText: {
          paddingHorizontal: theme.padding.large,
        },
        endDate: {
          width: '90%',
          margin: 'auto',
        },
        endDateInput: {
          width: 50,
          height: rowHeight,
        },
        endDateText: {
          paddingHorizontal: theme.padding.large,
        },
        endTimeInput: {
          width: 60,
          height: rowHeight,
        },
        endTimeDropDown: {
          width: 65,
          height: rowHeight,
        },
        endTimeText: {
          paddingHorizontal: theme.padding.large,
        },
        startTime: {
          margin: 0,
        },
        endTime: {
          margin: 0,
        },
        fieldInputContainerStyle: {
          width: '100%',
          fontSize: theme.fontSize.smaller,
          height: rowHeight,
          marginTop: -theme.spacing.small,
        },
        itemsContainerStyle: {
          width: 178,
        },
        extraPopoverStyle: {
          width: 180,
        },
      }),
    [theme],
  );
};
