import { ErrorPolicy } from '@apollo/client/core';
import {
  useApolloClient,
  useMutation,
  useReactiveVar,
} from '@apollo/client/react/hooks';
import {
  CloseOrderEvent,
  FeatureIDs,
  InitiateOrderEvent,
  InitiateRefundEvent,
  IntegrationApps,
  Order,
  OrderAction,
  OrderEvent,
  OrderItem,
  OrderItemStatus,
  OrderStatus,
} from '@oolio-group/domain';
import { computeOrderState } from '@oolio-group/order-helper';
import { isEmpty, isNil } from 'lodash';
import isEqual from 'lodash/isEqual';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { SYNC_CDS_ORDER_EVENTS } from '../../graphql/syncEvents';
import {
  GET_ORDER,
  GET_ORDER_FRAGMENT,
  ORDER_SAVE,
} from '../../hooks/app/orders/graphql';
import { docketPrintedVar } from '../../state/cache';
import { userUtility } from '../../state/userUtility';
import { canPerformSaveAction } from '../../utils/cart';
import { parseApolloError } from '../../utils/errorHandlers';
import { generateOrderEvent } from '../../utils/orderEventHelper';
import kitchenOrderEvents from '../../utils/printerTemplates/kotEvents';
import { stripProperties } from '../../utils/stripObjectProps';
import { validateOrderEvents } from '../../utils/validateOrderEvents';
import { useCheckFeatureEnabled } from '../app/features/useCheckFeatureEnabled';
import { currentOrderActionObservable } from '../app/orders/ordersObservableUtils';
import { useSalesChannels } from '../app/salesChannels/useSalesChannels';
import { postSalesObservableForLogin } from '../app/usePostSalesNavigation';
import { useSession } from '../app/useSession';
import { useSettings } from '../app/useSettings';
import { extractCounter, useOrderNumber } from './useOrderNumber';
import { useTokenNumber } from './useTokenNumber';

/**
 * Evaluates whether to send the events (pending / new) to server
 * or to keep them in local instance state
 * @param action
 */

const canSyncToServer = (action: OrderAction): boolean => {
  switch (action) {
    case OrderAction.ORDER_SAVE:
    case OrderAction.ORDER_VOID:
      return true;
    default:
      return false;
  }
};

export type OnSaveCompletedCallback = (order: Order | null) => void;

/**
 * Filter removed items (cancelled) from Order.
 * @param orderItems OrderItem
 */
const filterCancelledItems = (orderItems: OrderItem[]): OrderItem[] => {
  return orderItems.filter(item => item.status !== OrderItemStatus.CANCELLED);
};

/**
 * Order management hook
 * Fetches order if orderId argument is given
 * Creates new order if orderId argument is not given
 * Provides updateCart method to update order with new actions
 *
 * Example:
 * ```
 * // create new order
 * const { order, updateCart } = useCart();
 *
 * const onPressAddItem = (item) => {
 *  updateCart(OrderAction.ORDER_ITEM_ADD_EVENT, item);
 * }
 *
 * // fetch order
 * const { order, updateCart, status } = useCart('xxx-xxx-xxx');
 *
 * ```
 * @param orderId
 */
export function useCart() {
  const [session] = useSession();
  const [error, setError] = useState<string>('');
  const params = useRef<{
    orderId?: string;
    orderTypeId?: string;
    tableId?: string;
  }>();

  const [pendingEvents, setPendingEvents] = useState<OrderEvent[]>([]);
  const [currentState, setCurrentState] = useState<Order | undefined>();
  const [originalState, setOriginalState] = useState<Order | undefined>();
  const [isDirty, setIsDirty] = useState<boolean>(false);
  const { generate: generateOrderNumber } = useOrderNumber();
  const { getTokenNumber } = useTokenNumber();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [tokenNumber, setTokenNumber] = useSettings<number>('tokenNumber');
  const onSaveCompleteHandlers = useRef<OnSaveCompletedCallback[]>([]);
  const isFeatureEnabled = useCheckFeatureEnabled();
  const isCdsEnabled = useMemo(() => {
    return Boolean(
      isFeatureEnabled(FeatureIDs.CDS) && session.device?.customerDisplay?.id,
    );
  }, [isFeatureEnabled, session.device?.customerDisplay?.id]);

  const { inStoreSaleChannel, getSalesChannels } = useSalesChannels();

  const [saveOrder] = useMutation<{ saveOrder: Order }>(ORDER_SAVE, {
    fetchPolicy: 'no-cache',
    onCompleted: response => {
      if (params.current?.orderId) {
        currentOrderActionObservable.next({
          orderId: params.current?.orderId,
          lastOrderAction: OrderAction.ORDER_SAVE,
          timestamp: Date.now(),
          isSyncComplete: false,
        });
      }
      const resolvedOrder = response.saveOrder;
      if (onSaveCompleteHandlers.current.length) {
        onSaveCompleteHandlers.current.forEach(callback => {
          callback && callback(resolvedOrder);
        });
        onSaveCompleteHandlers.current = [];
      }
    },
  });
  const [syncCdsEvent] = useMutation(SYNC_CDS_ORDER_EVENTS);
  const [counter, setCounter] = useSettings<number>('orderCounter');
  const docketPrintedEvent = useReactiveVar<OrderEvent | undefined>(
    docketPrintedVar,
  );

  useEffect(() => {
    // check if last printed docket is of this order
    if (
      docketPrintedEvent &&
      docketPrintedEvent?.orderId === currentState?.id
    ) {
      // update the order items docket fired flag
      setCurrentState(previousState => {
        if (previousState && previousState.id === docketPrintedEvent?.orderId) {
          const order = computeOrderState([docketPrintedEvent], previousState);
          saveOrder({ variables: { data: order } });
          return order;
        }
        return previousState;
      });
      // reset last docket printed var
      docketPrintedVar(undefined);
    }
  }, [docketPrintedEvent, saveOrder, currentState?.id]);

  useEffect(() => {
    getSalesChannels();
  }, [getSalesChannels]);

  const client = useApolloClient();

  const getOrderNumberPrefix = useCallback(
    (prevOrder: Order) => {
      const oNum = prevOrder.orderNumber.split('-').splice(1).join('-');
      if (session.device) {
        return (
          session.device.returnPrefix?.split('-')[0] +
          '-' +
          (oNum || prevOrder.orderNumber)
        );
      } else return prevOrder.orderNumber;
    },
    [session.device],
  );

  const initiateRefund = useCallback(
    (prevOrder: Order, reason: string) => {
      setPendingEvents(oldEvents => {
        if (oldEvents.length !== 0) {
          return oldEvents;
        }
        const action = OrderAction.ORDER_REFUND_INITIATE;
        const event = generateOrderEvent<InitiateRefundEvent>(
          action,
          {
            organizationId: session.currentOrganization?.id,
            venueId: session.currentVenue?.id,
            deviceId: session.device?.id,
            storeId: session.currentStore?.id,
            triggeredBy: userUtility.posUser?.id || userUtility?.recentUserId,
          },
          {
            refundOf: prevOrder.id,
            reason,
            refundOrderNumber: getOrderNumberPrefix(prevOrder),
            ...(prevOrder?.integrationInfo?.app === IntegrationApps.OOM && {
              integrationApp: IntegrationApps.OOM,
            }),
          },
        );
        const order = computeOrderState([event], prevOrder);
        order.orderItems = filterCancelledItems(order.orderItems);
        isCdsEnabled &&
          syncCdsEvent({
            variables: {
              input: [event],
            },
          });

        const updated = [event];
        setCurrentState(order);
        setOriginalState(order);
        return updated;
      });
    },
    [
      session.currentOrganization?.id,
      session.currentVenue?.id,
      session.device?.id,
      session.currentStore?.id,
      getOrderNumberPrefix,
      isCdsEnabled,
      syncCdsEvent,
    ],
  );

  const mergeCachedOrderWithEvents = useCallback(
    async (orderId: string, errorPolicy?: ErrorPolicy) => {
      try {
        const response = await client.query<{ order: Order }>({
          query: GET_ORDER,
          variables: { orderId },
          fetchPolicy: 'cache-first',
          returnPartialData: false,
          errorPolicy: errorPolicy ?? 'none',
        });

        if (response.error) {
          setError(parseApolloError(response.error));
        } else if (response.errors?.length) {
          setError(response.errors.map(error => error.message).join(', '));
        } else {
          setError('');
          const order = response.data?.order;
          if (order) {
            let pendingOrderEvents: OrderEvent[] = [];
            setPendingEvents(oldEvents => {
              if (
                [OrderStatus.COMPLETED, OrderStatus.VOID].includes(order.status)
              ) {
                return oldEvents.filter(event => event.orderId !== order.id);
              } else {
                const firstMatchedEvent = oldEvents.find(
                  event => event.orderId === order.id,
                );

                if (firstMatchedEvent)
                  firstMatchedEvent.previous = order.prevEventId;

                pendingOrderEvents = oldEvents.filter(
                  event => event.orderId === order.id,
                );

                return oldEvents;
              }
            });
            if (pendingOrderEvents.length > 0) {
              setCurrentState(computeOrderState(pendingOrderEvents, order));
            } else {
              setCurrentState(order);
            }
            setOriginalState(order);
            return order;
          }
        }
      } catch (error) {
        setError((error as Error)?.message);
      }
    },
    [client],
  );

  const getOrderData = useCallback(
    async (orderId: string) => {
      try {
        let order =
          client.cache.readFragment<Order>({
            id: `Order:${orderId}`,
            fragment: GET_ORDER_FRAGMENT,
            returnPartialData: true,
          }) ?? undefined;
        const isOnAccountOrder = order?.status === OrderStatus.ON_ACCOUNT;
        // On Account check is used when we've disabled order polling from BO
        // If we disabled order polling then we don't have a way to update cache for on account orders
        // On account events calculated on worker so we can't store it to cache
        if (!order || isEmpty(order) || isOnAccountOrder) {
          const response = await client.query<{ order: Order }>({
            query: GET_ORDER,
            variables: { orderId },
            fetchPolicy: isOnAccountOrder ? 'network-only' : 'cache-first',
            returnPartialData: false,
          });
          if (response.error) {
            setError(parseApolloError(response.error));
          } else if (response.errors?.length) {
            setError(response.errors.map(error => error.message).join(', '));
          } else {
            setError('');
            order = response.data?.order;
            return order;
          }
        } else {
          return order;
        }
      } catch (error) {
        setError((error as Error)?.message);
      }
      return undefined;
    },
    [client],
  );

  const incrementTokenNumber = useCallback(async () => {
    const deviceSettings = session?.device;
    const istTokenEnabled = deviceSettings?.isTokenNumberActive;
    let currentTokenNumber = (await getTokenNumber()) as number;
    const range = session?.device?.tokenSettings?.tokenRange;
    if (istTokenEnabled && Number.isInteger(currentTokenNumber)) {
      if (!Number.isInteger(currentTokenNumber)) {
        currentTokenNumber = range?.start || 0;
      } else if (!range?.end) {
        // if end range is undefined
        currentTokenNumber = currentTokenNumber + 1;
      } else if (range?.end && currentTokenNumber < range?.end) {
        // if end range is greater than token number
        currentTokenNumber = currentTokenNumber + 1;
      } else if (range?.end && currentTokenNumber >= range?.end) {
        // if current token number exceeded range
        currentTokenNumber = range.start || 0;
      }
      setTokenNumber(currentTokenNumber);
    } else if (istTokenEnabled && Number.isInteger(range?.start)) {
      setTokenNumber(range?.start as number);
    }
  }, [session, getTokenNumber, setTokenNumber]);

  const resetCart = useCallback(async () => {
    if (!session) {
      return '';
    }
    const event = generateOrderEvent<InitiateOrderEvent>(
      OrderAction.ORDER_INITIATE,
      {
        organizationId: session.currentOrganization?.id,
        venueId: session.currentVenue?.id,
        deviceId: session.device?.id,
        storeId: session.currentStore?.id,
        triggeredBy: userUtility.posUser?.id || userUtility?.recentUserId,
      },
      {
        tableId: params.current?.tableId,
        orderTypeId: params.current?.orderTypeId,
        orderNumber: await generateOrderNumber(),
        salesChannelId: inStoreSaleChannel?.id,
        tokenNumber: await getTokenNumber(),
      },
    );

    const order = computeOrderState([event]);

    setCurrentState(order);
    setOriginalState(order);
    setPendingEvents([event]);
    saveOrder({ variables: { data: order } });
    isCdsEnabled &&
      syncCdsEvent({
        variables: {
          input: [event],
        },
      });

    currentOrderActionObservable.next({
      orderId: order.id,
      lastOrderAction: OrderAction.ORDER_INITIATE,
      lastEventId: event.id,
      timestamp: Date.now(),
      isSyncComplete: false,
    });

    return order.id;
  }, [
    session,
    generateOrderNumber,
    inStoreSaleChannel?.id,
    saveOrder,
    syncCdsEvent,
    isCdsEnabled,
    getTokenNumber,
  ]);

  /**
   * Perform cart update actions
   * Events are stored until the next save point
   */

  const updateCart = useCallback(
    <T extends OrderEvent>(
      action: OrderAction,
      input?: Omit<T, keyof OrderEvent>,
      eventId?: string,
      onSaveCompletedCallback?: OnSaveCompletedCallback,
    ) => {
      /**
       * Using setOrder this way helps avoid add order to dependency list
       * avoiding updateCart changing every time it is called since it modifies order
       */
      let cdsEvent: OrderEvent | undefined = undefined;
      setCurrentState(prevOrder => {
        if (!prevOrder) {
          setError('Actions are not permitted when order is undefined');
          return;
        } else {
          setError('');
        }
        const eventInput = stripProperties({ ...input }, '__typename');
        const event = generateOrderEvent(
          action,
          {
            organizationId: session.currentOrganization?.id,
            venueId: session.currentVenue?.id,
            deviceId: session.device?.id,
            storeId: session.currentStore?.id,
            triggeredBy: userUtility.posUser?.id || userUtility?.recentUserId,
            ...(prevOrder.isOnline && {
              integrationApp: IntegrationApps.DOSHII,
            }),
          },
          {
            ...eventInput,
            orderId: prevOrder?.id,
            previous: prevOrder?.prevEventId,
            ...(prevOrder?.integrationInfo?.app === IntegrationApps.OOM && {
              integrationApp: IntegrationApps.OOM,
            }),
          },
          eventId,
        );

        if (!!cdsEvent) {
          // Fix async issue of callback inside setState sometime run twice for a single updateCart call
          event.id = (cdsEvent as OrderEvent).id;
        }
        const order = computeOrderState([event], prevOrder);
        order.orderItems = filterCancelledItems(order.orderItems);

        isCdsEnabled &&
          !cdsEvent &&
          syncCdsEvent({
            variables: {
              input: [event],
            },
          });
        cdsEvent = event;

        /**
         * Accumulate events until save order action is performed
         * Using setPendingEvents this way helps avoid add pendingEvents to dependency list
         * avoiding updateCart changing every time it is called since it modifies pendingEvents
         */
        setPendingEvents(oldEvents => {
          if (!event.previous && oldEvents.length == 1) {
            event.previous = oldEvents[0].id;
          }
          const updatedEvents = [...oldEvents, event];
          const isSaveAction = action === OrderAction.ORDER_SAVE;

          // if it is a save action and there are no previous events in the current cart state
          //    and cart has some items which are not sent to kitchen(which are to be sent)
          //    and had some payment attempts(for card payment cases) made
          //    then, add save action on the order
          const skipSingleSaveAction =
            isSaveAction &&
            oldEvents.length === 0 &&
            !canPerformSaveAction(order);

          // When there are no changes made to order
          if (skipSingleSaveAction) {
            setIsDirty(true);
            return [];
          } else if (canSyncToServer(action)) {
            setIsDirty(false);
            setOriginalState(order);
            saveOrder({ variables: { data: order } });
            if (extractCounter(order.orderNumber) > (counter || 0)) {
              setCounter(extractCounter(order.orderNumber));
              incrementTokenNumber();
            }

            if (action !== OrderAction.ORDER_SAVE) {
              currentOrderActionObservable.next({
                orderId: order.id,
                lastOrderAction: action,
                lastEventId: event.id,
                timestamp: Date.now(),
                isSyncComplete: false,
              });
            }

            onSaveCompleteHandlers.current.push(() => {
              kitchenOrderEvents.publishToKotUtil({
                orderId: order.id,
                preEvents: updatedEvents,
              });
            });

            onSaveCompletedCallback &&
              onSaveCompleteHandlers.current.push(onSaveCompletedCallback);

            return [];
          } else {
            setIsDirty(true);
          }
          return updatedEvents;
        });
        return order;
      });
    },
    [
      session.currentOrganization?.id,
      session.currentVenue?.id,
      session.device?.id,
      session.currentStore?.id,
      syncCdsEvent,
      saveOrder,
      counter,
      setCounter,
      isCdsEnabled,
      incrementTokenNumber,
    ],
  );

  /**
   * Set current state back to original before performing cart updates
   * This method only discards actions up to the last save point
   */
  const discardChanges = useCallback(() => {
    // ORDER_INITIATE event shouldn't be discarded
    setPendingEvents(old => {
      const firstEvent = old[0];
      if (firstEvent?.action === OrderAction.ORDER_INITIATE) {
        return [firstEvent];
      }
      return [];
    });

    setCurrentState(originalState);
    setIsDirty(false);
  }, [originalState]);

  const recomputeOrderFromServer = useCallback(
    async (orderId: string): Promise<void> => {
      let order: Order;
      if (orderId == currentState?.id) {
        order = currentState;
      } else {
        order = (await getOrderData(orderId)) as Order;
      }
      const events = await validateOrderEvents(order as Order);
      if (order?.status !== OrderStatus.CREATED && events.length) {
        const validOrder = computeOrderState(
          events.concat(pendingEvents.filter(x => x.orderId === order?.id)),
        );
        setCurrentState(validOrder);
        setOriginalState(validOrder);
      }
    },
    [currentState, getOrderData, pendingEvents],
  );

  const setCartParams = useCallback(
    async (
      orderId?: string,
      orderTypeId?: string,
      tableId?: string,
      isExisting?: boolean,
    ): Promise<void> => {
      params.current = {
        orderId,
        orderTypeId: !isNil(orderTypeId)
          ? orderTypeId
          : params.current?.orderTypeId,
        tableId: !isNil(tableId) ? tableId : params.current?.tableId,
      };
      currentOrderActionObservable.next(undefined);
      postSalesObservableForLogin.next(false);
      // fetch order
      if (orderId && isExisting) {
        let order = await getOrderData(orderId);
        // TODO: Verify if this is the right place and time to check order integrity
        const events = await validateOrderEvents(order as Order);
        const filterEvents = pendingEvents.filter(
          event => event.orderId === orderId,
        );
        if (order?.status !== OrderStatus.CREATED && events.length) {
          order = computeOrderState(events.concat(filterEvents));
        }
        setCurrentState(order);
        setOriginalState(order);
        setPendingEvents(filterEvents);
      }
    },
    [getOrderData, pendingEvents],
  );

  const getCartUnSavedStatus = useCallback(() => {
    if (!isDirty) {
      // If we don't have unsaved changes, then we don't need to do anything
      return false;
    }

    if (
      currentState &&
      currentState.status !== OrderStatus.IN_PROGRESS &&
      currentState?.orderItems.length === 0
    ) {
      // If we don't have unsaved changes, then we don't need to do anything
      return false;
    }

    if (currentState && currentState.status === OrderStatus.COMPLETED) {
      // If we have completed order, then we don't need to do anything
      return false;
    }
    return true;
  }, [currentState, isDirty]);

  const clearPriorPendingEvents = useCallback(() => {
    setPendingEvents([]);
  }, []);

  /**
   * @description close the cart and navigate to Idle screen on Customer Display device
   * @param {OrderStatus} status the status of order to close. if IN_PROGRESS, only clear the cart don't do the navigation
   */
  const closeOrderCart = useCallback(
    async (option?: { status?: OrderStatus }) => {
      const event = generateOrderEvent<CloseOrderEvent>(
        OrderAction.ORDER_CLOSE,
        {
          organizationId: session.currentOrganization?.id,
          venueId: session.currentVenue?.id,
          deviceId: session.device?.id,
          storeId: session.currentStore?.id,
          triggeredBy: userUtility.posUser?.id || userUtility?.recentUserId,
        },
        {
          orderId: currentState?.id,
          status: option?.status,
        },
      );
      isCdsEnabled &&
        (await syncCdsEvent({
          variables: {
            input: [event],
          },
        }));
    },
    [
      session.currentOrganization?.id,
      session.currentVenue?.id,
      session.device?.id,
      session.currentStore?.id,
      currentState?.id,
      isCdsEnabled,
      syncCdsEvent,
    ],
  );

  const openOrderCart = useCallback(
    async (orderId: string) => {
      const event = generateOrderEvent(
        OrderAction.ORDER_OPEN,
        {
          organizationId: session.currentOrganization?.id,
          venueId: session.currentVenue?.id,
          deviceId: session.device?.id,
          storeId: session.currentStore?.id,
          triggeredBy: userUtility.posUser?.id || userUtility?.recentUserId,
        },
        {
          orderId: orderId,
        },
      );
      isCdsEnabled &&
        (await syncCdsEvent({
          variables: {
            input: [event],
          },
        }));
    },
    [
      session.currentOrganization?.id,
      session.currentVenue?.id,
      session.device?.id,
      session.currentStore?.id,
      isCdsEnabled,
      syncCdsEvent,
    ],
  );

  const addEventsToCart = useCallback(
    (events: OrderEvent[]) => {
      /**
       * Using setOrder this way helps avoid add order to dependency list
       * avoiding updateCart changing every time it is called since it modifies order
       */
      setCurrentState(prevOrder => {
        if (!prevOrder) {
          setError('Actions are not permitted when order is undefined');
          return;
        } else {
          setError('');
        }

        const order = computeOrderState(events, prevOrder);
        order.orderItems = filterCancelledItems(order.orderItems);

        // Reusing logic from above, to set order state and initiate docket print.
        setIsDirty(false);
        setOriginalState(order);
        saveOrder({ variables: { data: order } });

        if (extractCounter(order.orderNumber) > (counter || 0))
          setCounter(extractCounter(order.orderNumber));

        // Initiate print if the order is Completed.
        if (order.status === OrderStatus.COMPLETED) {
          onSaveCompleteHandlers.current.push(() => {
            kitchenOrderEvents.publishToKotUtil({
              orderId: order.id,
              preEvents: [],
            });
          });
        }

        return order;
      });

      isCdsEnabled &&
        syncCdsEvent({
          variables: {
            input: events,
          },
        });
    },
    [isCdsEnabled, syncCdsEvent, saveOrder, counter, setCounter],
  );

  return {
    status: {
      error,
      // always be false as the queries above are synchronous
      loading: false,
    },
    updateCart,
    discardChanges,
    resetCart,
    clearPriorPendingEvents,
    order: currentState,
    itemsChanged: !isEqual(currentState?.orderItems, originalState?.orderItems),
    getOrderData,
    mergeCachedOrderWithEvents,
    setCartParams,
    isDirty,
    initiateRefund,
    closeOrderCart,
    openOrderCart,
    recomputeOrderFromServer,
    addEventsToCart,
    getCartUnSavedStatus,
  };
}
