import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import LoadingIndicator from '../../../../components/LoadingIndicator/LoadingIndicator';
import { Helmet } from 'react-helmet';
import { Styles } from '../styles/HourlySales.styles';
import { View, ScrollView } from 'react-native';
import { useTranslation } from '@oolio-group/localization';
import {
  DateRangeFilter,
  FilterValue,
  Widget,
  WidgetChartType,
  DateRangeGranularity,
  Filters,
} from '@oolio-group/domain';
import { HourlySalesHeader } from './UIComponents/HourlySalesHeader';
import { Table, TableRef } from '../UIComponents/Table';
import { Filters as ReportFilter } from '../UIComponents/Filters';
import { PieChart } from '../UIComponents/PieChart';
import { MultiBarChart } from '../UIComponents/MultiBarChart';
import {
  GRANULARITY_FORMATS,
  transformFieldsToTableColumn,
  MULTI_BAR_COLOR_SCALE,
} from '../reportsHelper';
import {
  LineChartType,
  ReportTableColumn,
  IMap,
  ExportType,
  PivotTableData,
  HelperText,
  DropDownFilter,
} from '../types';
import { columnStyleOptions } from '../../../../components/DataGrid/DataGrid';
import orderBy from 'lodash/orderBy';
import { format } from 'date-fns-tz';
import { addHours } from 'date-fns';
import { CubejsApi } from '@cubejs-client/core';

interface ReportProps {
  options: { loading: boolean };
  filterOptions: DropDownFilter[];
  filters: FilterValue;
  widgets: Widget[];
  dateRangeFilter: DateRangeFilter;
  updateFilters: (filter: string, value: string[]) => void;
  resetFilters: () => void;
  updateReport: () => void;
  updateDateRangeFilters: (value: DateRangeFilter) => void;
  cubejsApi: CubejsApi;
  allFilters: Filters;
  updateCount: number;
}

export const HourlySales: React.FC<ReportProps> = ({
  options: { loading },
  filters,
  filterOptions,
  widgets,
  dateRangeFilter,
  updateFilters,
  updateReport,
  resetFilters,
  updateDateRangeFilters,
  cubejsApi,
  allFilters,
  updateCount,
}) => {
  const { translate } = useTranslation();
  const styles = Styles();
  const [showFilters, setShowFilters] = useState<boolean>(false);

  const [selectedColumns, setSelectedColumns] = useState<ReportTableColumn[]>(
    [],
  );

  const toggleFilters = useCallback(() => {
    setShowFilters(value => !value);
  }, []);

  const tableWidget =
    widgets.find(widget => widget.chartType === WidgetChartType.TABLE) ||
    ({} as Widget);

  const dateRangeFilters = useMemo(() => {
    if (tableWidget.query?.dateRangeFilters) {
      return tableWidget.query?.dateRangeFilters as DateRangeFilter[];
    } else {
      return [];
    }
  }, [tableWidget.query]);

  const tableGranularity: DateRangeGranularity =
    (tableWidget &&
      tableWidget.query &&
      dateRangeFilters.length > 0 &&
      dateRangeFilters[0].granularity) ||
    DateRangeGranularity.HOUR;

  const tableTimeZone = tableWidget.query?.timezone;

  const tableColumns: ReportTableColumn[] = useMemo(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const columns: any[] = [];

    if (dateRangeFilters.length > 0) {
      columns.push({
        key: `${dateRangeFilters[0].key}.${tableGranularity}`,
        title: dateRangeFilters[0].text,
        flex: 1,
        style: columnStyleOptions.REGULAR,
        showByDefault: true,
      });
    }

    if (tableWidget.query?.measures)
      columns.push(
        ...transformFieldsToTableColumn(tableWidget.query?.measures),
      );
    return columns;
  }, [dateRangeFilters, tableGranularity, tableWidget.query?.measures]);

  const tableWidgetRef = useRef<TableRef>({} as TableRef);
  const exportReport = useCallback((type = ExportType.CSV) => {
    if (type === ExportType.CSV)
      tableWidgetRef.current.exportData &&
        tableWidgetRef.current.exportData(
          `HourlySalesReport-${Date.now()}.csv`,
        );
  }, []);

  const groupDataByGranularity = useCallback(
    (pivotTableData: PivotTableData, timeKey: string, isTable?: boolean) => {
      const hourBasedData: IMap<IMap<string | number>> = {};

      pivotTableData.forEach(rowData => {
        const date = new Date(rowData[timeKey] as string);

        const hourFormat = isTable ? 'HH:mm' : 'HH';

        let hourStr = format(date, hourFormat, { timeZone: tableTimeZone });
        const hourRange = `${hourStr} - ${format(
          addHours(date, 1),
          hourFormat,
          { timeZone: tableTimeZone },
        )}`;

        if (isTable) {
          hourStr = hourRange;
        }

        if (hourBasedData[hourStr] === undefined) {
          hourBasedData[hourStr] = {
            order: parseInt(format(date, 'H', { timeZone: tableTimeZone })),
            range: hourRange,
          };
        }

        for (const key in rowData) {
          if (key !== timeKey) {
            if (hourBasedData[hourStr][key] === undefined) {
              hourBasedData[hourStr][key] = '0.00';
            }

            if (rowData[key]) {
              hourBasedData[hourStr][key] = (
                (parseFloat(hourBasedData[hourStr][key] as string) +
                  parseFloat(`${rowData[key]}`)) as number
              ).toFixed(2);
            }
          }
        }
      });

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const toReturn: any[] = [];

      for (const dateRange in hourBasedData) {
        toReturn.push({
          [timeKey]: dateRange,
          ...hourBasedData[dateRange],
        });
      }

      return orderBy(toReturn, ['order'], ['asc']);
    },
    [tableTimeZone],
  );

  const barDataTransformationFn = useCallback(
    (pivotTableData: PivotTableData): PivotTableData => {
      let toReturn = pivotTableData;

      if (pivotTableData.length > 0) {
        const timeKey = `${dateRangeFilters[0].key}.${tableGranularity}`;
        toReturn = groupDataByGranularity(pivotTableData, timeKey);
      }

      return toReturn;
    },
    [dateRangeFilters, groupDataByGranularity, tableGranularity],
  );

  const tableDataTransformationFn = useCallback(
    (
      pivotTableData: PivotTableData,
      columns: ReportTableColumn[],
    ): PivotTableData => {
      let toReturn = pivotTableData;

      if (pivotTableData.length > 0) {
        const timeKey = columns[0].key;
        toReturn = groupDataByGranularity(pivotTableData, timeKey, true);
      }

      return toReturn;
    },
    [groupDataByGranularity],
  );

  useEffect(() => {
    setSelectedColumns(
      (tableColumns || []).filter(column => column.showByDefault),
    );
  }, [tableColumns]);

  const updateColumns = useCallback(
    (columnKeys: string[]) => {
      setSelectedColumns(
        tableColumns.filter(col => columnKeys.includes(col.title)),
      );
    },
    [tableColumns],
  );

  return (
    <>
      <Helmet>
        <title>
          {translate('navigation.generalSettingsPageTitle', {
            appName: translate('appName'),
          })}
        </title>
      </Helmet>

      <ScrollView scrollEnabled={!showFilters} style={styles.pageStyle}>
        {showFilters ? (
          <View style={styles.filtersStyle}>
            <ReportFilter
              toggleFilters={toggleFilters}
              filterOptions={filterOptions}
              filters={filters}
              updateFilters={updateFilters}
              resetFilters={resetFilters}
            ></ReportFilter>
          </View>
        ) : null}
        <View style={styles.mainSectionStyle}>
          <View style={styles.headersStyle}>
            <HourlySalesHeader
              allFilters={allFilters}
              toggleFilters={toggleFilters}
              updateReport={updateReport}
              exportReport={exportReport}
              updateFilters={updateFilters}
              columns={{ all: tableColumns, updateColumns, selectedColumns }}
              filters={filters}
              dateRangeFilter={dateRangeFilter}
              updateDateRangeFilters={updateDateRangeFilters}
            ></HourlySalesHeader>
          </View>
          <View style={styles.chartRowStyle}>
            {loading ? (
              <LoadingIndicator />
            ) : (
              <>
                {widgets
                  .filter(widget => widget.chartType !== WidgetChartType.TABLE)
                  .map(widget => {
                    let renderComp;

                    const dateRangeFilters = widget.query
                      .dateRangeFilters as DateRangeFilter[];
                    const granularity = dateRangeFilters[0].granularity || '';

                    switch (widget.chartType) {
                      case WidgetChartType.PIE:
                        const pieKeys = {
                          name: widget.query.dimensions[0].key,
                          value: widget.query.measures[0].key,
                        };
                        renderComp = (
                          <PieChart
                            widget={widget}
                            helper={HelperText.HOURLY_SALES}
                            keys={pieKeys}
                            generateTotal={widget.name === 'Payment Type Usage'}
                            columnType={widget.query.measures[0]?.type}
                            cubejsApi={cubejsApi}
                            updateCount={updateCount}
                          />
                        );
                        break;
                      case WidgetChartType.BAR:
                        const barKeys = widget.query.measures.map(
                          (measure: { key: string; text: string }) => ({
                            name: `${dateRangeFilters[0].key}.${granularity}`,
                            value: measure.key,
                            text: translate(measure.text),
                          }),
                        );

                        renderComp = (
                          <MultiBarChart
                            widget={widget}
                            helper={HelperText.HOURLY_SALES}
                            keys={barKeys}
                            type={LineChartType.BASIC}
                            colorScale={MULTI_BAR_COLOR_SCALE}
                            dataTransformationFn={barDataTransformationFn}
                            cubejsApi={cubejsApi}
                            updateCount={updateCount}
                          />
                        );
                        break;
                    }

                    return renderComp;
                  })}
              </>
            )}
          </View>
          <View style={styles.tableStyle}>
            {loading ? (
              <LoadingIndicator />
            ) : (
              <Table
                widget={tableWidget}
                columns={selectedColumns}
                helper={HelperText.HOURLY_SALES}
                pageSize={5}
                granularityFormats={GRANULARITY_FORMATS}
                filters={filters}
                ref={tableWidgetRef}
                dataTransformationFn={tableDataTransformationFn}
                cubejsApi={cubejsApi}
                updateCount={updateCount}
              ></Table>
            )}
          </View>
        </View>
      </ScrollView>
    </>
  );
};

export default HourlySales;
