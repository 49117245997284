import {
  useLazyQuery,
  useMutation,
  useApolloClient,
} from '@apollo/client/react/hooks';
import { useMemo, useState, useCallback } from 'react';
import { parseApolloError, noopHandler } from '../../../utils/errorHandlers';
import { Operation } from '../../../types/Operation';
import { ApolloError } from '@apollo/client';
import {
  IntegrationPartner,
  UpdateIntegrationPartnerInput,
  OrganizationIntegrationsFilter,
  UpdateOnlineOrderStoreSettingsInput,
  CreateIntegrationPartnerInput,
  UploadOnlineStoreFavImageInput,
  IntegrationApps,
  MerchantCodeState,
} from '@oolio-group/domain';
import {
  CREATE_INTEGRATION_PARTNER,
  GET_INTEGRATION_PARTNERS,
  UPDATE_INTEGRATION_PARTNERS,
  UPDATE_ONLINE_ORDER_STORE_SETTINGS,
  UPLOAD_ONLINE_STORE_FAVIMAGE,
  VERIFY_MERCHANT_CODE,
  VERIFY_STORE_SLUG,
} from './graphql';
import { keyBy } from 'lodash';
import { useNotification } from '../../Notification';
import { UPDATE_STORE_STATUS_EVENT } from '../products/graphql';
import { useSession } from '../useSession';
import { setItem } from '../../../storage/interface';
import { INTEGRATION_SETTINGS_KEY } from '../../../types/Common';

export interface UseIntegrationPartnersProps {
  integrationPartners: Record<string, IntegrationPartner>;
  getIntegrationPartnerSettings: (
    filter: OrganizationIntegrationsFilter,
  ) => void;
  createIntegrationPartner: (input: CreateIntegrationPartnerInput) => void;
  updateIntegrationPartnerSettings: (
    input: UpdateIntegrationPartnerInput[],
  ) => void;
  updateOnlineOrderStoreSettings: (
    input: UpdateOnlineOrderStoreSettingsInput,
  ) => void;
  uploadOnlineStoreFavImageFile: (
    input: UploadOnlineStoreFavImageInput,
    integrationId: string,
  ) => void;
  error: string | undefined;
  loading: boolean;
  operation: Operation;
  checkStoreSlugExists: (
    appName: IntegrationApps,
    slug: string,
    storeId: string,
  ) => Promise<boolean>;
  checkMerchantCodeExists: (
    appName: IntegrationApps,
    merchantCode: string,
    merchantStoreRef: string,
    storeId?: string,
  ) => Promise<MerchantCodeState>;
}

export function useIntegrationPartners(): UseIntegrationPartnersProps {
  const [operation, setOperation] = useState<Operation>(Operation.READ);
  const { showNotification } = useNotification();
  const client = useApolloClient();
  const [integrationPartners, setIntegrationPartners] = useState<
    Record<string, IntegrationPartner>
  >({});

  const [session] = useSession();
  const [syncUpdateStoreStatusEvents, syncUpdateStoreStatusEventsResponse] =
    useMutation(UPDATE_STORE_STATUS_EVENT, {
      onError: noopHandler,
    });

  const onCompleteGetRequest = useCallback(async data => {
    if (data?.integrationPartners) {
      setIntegrationPartners(keyBy(data?.integrationPartners, 'id'));
      await setItem(INTEGRATION_SETTINGS_KEY, data?.integrationPartners);
    }
  }, []);

  const [getIntegrationPartners, integrationPartnersResponse] = useLazyQuery(
    GET_INTEGRATION_PARTNERS,
    {
      fetchPolicy: 'cache-and-network',
      onCompleted: onCompleteGetRequest,
      onError: noopHandler,
    },
  );

  const onUpdateReqComplete = useCallback(data => {
    if (data?.updateIntegrationPartners) {
      setIntegrationPartners(keyBy(data?.updateIntegrationPartners, 'id'));
    }
  }, []);

  const onCreateReqComplete = useCallback(data => {
    if (data?.addIntegrationPartner) {
      setIntegrationPartners({
        [data.addIntegrationPartner.id]: data?.addIntegrationPartner,
      });
    }
  }, []);

  const onUploadOnlineStoreFavImageComplete = useCallback(data => {
    if (data?.uploadOnlineStoreFavImage) {
      setIntegrationPartners(integrationPartners => {
        return {
          ...integrationPartners,
          [data.uploadOnlineStoreFavImage.id]: data.uploadOnlineStoreFavImage,
        };
      });
    }
  }, []);

  const [uploadOnlineStoreFavImage, uploadOnlineStoreFavImageResponse] =
    useMutation(UPLOAD_ONLINE_STORE_FAVIMAGE, {
      onError: noopHandler,
      onCompleted: onUploadOnlineStoreFavImageComplete,
    });

  const [updateIntegrationPartners, updateIntegrationPartnersResponse] =
    useMutation(UPDATE_INTEGRATION_PARTNERS, {
      onError: noopHandler,
      onCompleted: onUpdateReqComplete,
    });

  const [createIntegrationPartnerRequest, createIntegrationPartnerResponse] =
    useMutation(CREATE_INTEGRATION_PARTNER, {
      onError: noopHandler,
      onCompleted: onCreateReqComplete,
    });

  const onUpdateOnlineOrdersReqComplete = useCallback(
    data => {
      if (data?.updateOnlineOrderStoreSettings) {
        setIntegrationPartners(
          keyBy(data?.updateOnlineOrderStoreSettings, 'id'),
        );
        showNotification({
          message: 'Settings Updated Successfully',
          success: true,
        });
      }
    },
    [showNotification],
  );

  const [
    updateOnlineOrderStoreSettings,
    updateOnlineOrderStoreSettingsResponse,
  ] = useMutation(UPDATE_ONLINE_ORDER_STORE_SETTINGS, {
    onError: noopHandler,
    onCompleted: onUpdateOnlineOrdersReqComplete,
  });

  const getIntegrationPartnerSettings = useCallback(
    (filter: OrganizationIntegrationsFilter) => {
      getIntegrationPartners({
        variables: { filter },
      });
      setOperation(Operation.READ);
    },
    [getIntegrationPartners],
  );

  const updateIntegrationPartnerSettings = useCallback(
    (input: UpdateIntegrationPartnerInput[]) => {
      updateIntegrationPartners({
        variables: {
          input,
        },
      });
      setOperation(Operation.UPDATE);
    },
    [updateIntegrationPartners],
  );

  const createIntegrationPartner = useCallback(
    (input: CreateIntegrationPartnerInput) => {
      createIntegrationPartnerRequest({
        variables: {
          input,
        },
      });
      setOperation(Operation.UPDATE);
    },
    [createIntegrationPartnerRequest],
  );

  const updateStoreSettings = useCallback(
    (input: UpdateOnlineOrderStoreSettingsInput) => {
      updateOnlineOrderStoreSettings({
        variables: {
          input,
        },
      });
      setOperation(Operation.UPDATE);
      const currTime = new Date().getTime();
      if (input.snoozeTime !== undefined) {
        syncUpdateStoreStatusEvents({
          variables: {
            input: [
              {
                orgId: session.currentOrganization?.id,
                storeId: input.store,
                status: !Boolean(input.snoozeTime),
                timeOfEvent: currTime,
                timeInterval: input.snoozeTime,
                action: 'UPDATE_STORE_STATUS',
                integrationApp: 'DELIVERECT',
              },
            ],
          },
        });
      }
    },
    [
      session.currentOrganization?.id,
      syncUpdateStoreStatusEvents,
      updateOnlineOrderStoreSettings,
    ],
  );

  const uploadOnlineStoreFavImageFile = useCallback(
    (input: UploadOnlineStoreFavImageInput, integrationId: string) => {
      uploadOnlineStoreFavImage({
        variables: {
          input,
          integrationId,
        },
      });
      setOperation(Operation.UPDATE);
    },
    [uploadOnlineStoreFavImage],
  );

  const checkStoreSlugExists = useCallback(
    async (appName: IntegrationApps, slug: string, storeId: string) => {
      const result = await client.query({
        query: VERIFY_STORE_SLUG,
        variables: {
          appName,
          storeSlug: slug,
        },
        context: {
          headers: { store: storeId },
        },
        fetchPolicy: 'network-only',
      });

      if (result?.data?.verifyStoreSlug) {
        return true;
      }

      return false;
    },
    [client],
  );

  const checkMerchantCodeExists = useCallback(
    async (
      appName: IntegrationApps,
      merchantCode: string,
      merchantStoreRef?: string,
      storeId?: string,
    ) => {
      const result = await client.query({
        query: VERIFY_MERCHANT_CODE,
        variables: {
          appName,
          merchantCode: merchantCode,
          merchantStoreRef,
        },
        context: {
          headers: { store: storeId },
        },
        fetchPolicy: 'network-only',
      });

      return result?.data?.verifyMerchantCode as MerchantCodeState;
    },
    [client],
  );

  const error: ApolloError | undefined =
    integrationPartnersResponse.error ||
    updateIntegrationPartnersResponse.error ||
    createIntegrationPartnerResponse.error ||
    updateOnlineOrderStoreSettingsResponse.error ||
    uploadOnlineStoreFavImageResponse.error ||
    syncUpdateStoreStatusEventsResponse.error;

  const loading =
    integrationPartnersResponse.loading ||
    updateIntegrationPartnersResponse.loading ||
    createIntegrationPartnerResponse.loading ||
    updateOnlineOrderStoreSettingsResponse.loading ||
    uploadOnlineStoreFavImageResponse.loading ||
    syncUpdateStoreStatusEventsResponse.loading;

  return useMemo(
    () => ({
      integrationPartners,
      getIntegrationPartnerSettings,
      updateIntegrationPartnerSettings,
      uploadOnlineStoreFavImageFile,
      createIntegrationPartner,
      updateOnlineOrderStoreSettings: updateStoreSettings,
      checkStoreSlugExists,
      checkMerchantCodeExists,
      error: error ? parseApolloError(error) : undefined,
      loading,
      operation,
    }),
    [
      integrationPartners,
      getIntegrationPartnerSettings,
      updateIntegrationPartnerSettings,
      uploadOnlineStoreFavImageFile,
      updateStoreSettings,
      createIntegrationPartner,
      checkStoreSlugExists,
      checkMerchantCodeExists,
      error,
      loading,
      operation,
    ],
  );
}
