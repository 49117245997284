const phoneNumberValidationSchema: { [key: string]: string } = {
  '+61': '^\\+?([0-9]{1,4})\\)?[ ]?([0-9]{9,10})$',
  '+44': '^\\+?([0-9]{1,4})\\)?[ ]?[0]?([0-9]{10,10})$',
  default: '^\\+?([0-9]{1,4})\\)?[ ]?([0-9]{10})$',
};

export const getPhoneNumberValidationSchemaByPhoneCode = (
  phoneCountryCode = 'default',
): string => {
  return (
    phoneNumberValidationSchema[phoneCountryCode] ||
    phoneNumberValidationSchema['default']
  );
};
