import React, { useEffect, useMemo, useState, useRef } from 'react';
import { View, Text } from 'react-native';
import {
  concat,
  intersection,
  keyBy,
  pullAll,
  difference,
  sortBy,
  omit,
  isEqual,
  uniq,
} from 'lodash';
import { useTranslation } from '@oolio-group/localization';
import { useNotification } from '../../../../../hooks/Notification';
import { useNavigation, useRoute } from '@react-navigation/native';
import { useModal } from '@oolio-group/rn-use-modal';
import {
  CourseInfo,
  useCourses,
} from '../../../../../hooks/app/courses/useCourses';
import { usePages } from '../../../../../hooks/app/pages/usePages';
import {
  CreateCourseInput,
  DEFAULT_ENTITY_ID,
  Product,
  UpdateCourseInput,
} from '@oolio-group/domain';
import { useProducts } from '../../../../../hooks/app/products/useProducts';
import { productsFragment } from '../../../../../hooks/app/courses/graphql';
import ProductRow from './ProductRow';
import ConfirmationDialog from '../../../../../components/Modals/ConfirmationDialog';
import ScreenLayout from '../../../../../components/Office/ScreenLayout/ScreenLayout';
import Section from '../../../../../components/Office/Section/Section';
import InputText from '../../../../../components/Shared/Inputs/InputText';
import InputToggle from '../../../../../components/Shared/Inputs/InputToggle';
import SelectSearch, {
  OptionsType,
} from '../../../../../components/Shared/Select/SelectSearch';
import theme from '../../../../../common/default-theme';
import styles from '../Courses.styles';

export enum SelectedItemType {
  Product = 'Product',
  Page = 'Page',
}
export interface AssignedProduct {
  id: string;
  name: string;
}

type KeyInput = 'autoFire' | 'name';

const defaultCourseInfo: Partial<CourseInfo> = {
  name: '',
  autoFire: false,
};

const CourseDetail: React.FC = () => {
  const { translate } = useTranslation();
  const { showNotification } = useNotification();
  const { showModal, closeModal } = useModal();
  const navigation = useNavigation();
  const route = useRoute();

  const [courseInfo, setCourseInfo] =
    useState<Partial<CourseInfo>>(defaultCourseInfo);
  const [assignedProducts, setAssignedProducts] = useState<string[]>([]);
  const originalAssignedProductsRef = useRef<string[]>([]);
  const originalCourseInfoRef = useRef<Partial<CourseInfo>>({});

  const { courseId } = route?.params as {
    courseId: string;
    title: string;
  };
  const {
    error,
    getCourse,
    courseMaps,
    loading: getCourseLoading,
    deleteCourse,
    updateCourses,
    createCourse,
    updateProductsInCourse,
  } = useCourses({
    onUpdateCoursesComplete: handleOnCompleteUpdateCourses,
  });
  const { getPages, pages: pageMaps, loading: getPagesLoading } = usePages();
  const {
    products,
    getAllProducts,
    loading: getProductsLoading,
  } = useProducts(undefined, productsFragment, undefined, 'cache-first');

  const hasCourseInfoChanged = !isEqual(
    courseInfo,
    originalCourseInfoRef.current,
  );
  const hasAssignedProductsChanged = !isEqual(
    assignedProducts.sort(),
    originalAssignedProductsRef.current,
  );

  function handleOnCompleteUpdateCourses() {
    if (!hasAssignedProductsChanged) {
      showNotification({
        message: translate('backOfficeProducts.courseUpdatedSuccessfully', {
          courseName: courseInfo.name,
        }),
        success: true,
      });
      navigation.setParams({
        courseId: courseInfo.id,
        title: courseInfo.name,
      });
    }
  }

  const handleCourseInfoChange = (key: KeyInput, value: string | boolean) => {
    setCourseInfo(pre => ({
      ...pre,
      [key]: value,
    }));
  };

  const handleAssignedProducts = (_: string[], item: OptionsType) => {
    const selectedId = item.value;
    if (item.type === SelectedItemType.Product) {
      setAssignedProducts(preProducts => {
        if (preProducts.includes(selectedId))
          return preProducts.filter(productId => productId !== selectedId);
        return [...preProducts].concat(selectedId);
      });
    } else {
      const allPagesMaps = keyBy(allPages, 'id');
      const productsInPage = (allPagesMaps[selectedId]?.products || []).map(
        product => product.id,
      );

      setAssignedProducts(preProducts => {
        const updatedProducts = [...preProducts];
        const commonProducts = intersection(updatedProducts, productsInPage);
        if (commonProducts.length === productsInPage.length) {
          pullAll(updatedProducts, productsInPage);
          return uniq(updatedProducts);
        }
        const notAssignedProducts = difference(productsInPage, commonProducts);
        return uniq(concat(updatedProducts, notAssignedProducts));
      });
    }
  };

  const handleDeleteItem = (item: AssignedProduct) => {
    setAssignedProducts(preProducts => {
      return preProducts.filter(productId => productId !== item.id);
    });
  };

  const handleSaveCourse = () => {
    if (!courseInfo.name)
      return showNotification({
        error: true,
        message: translate('backOfficeProducts.emptyCourseNameValidation'),
      });
    if (!assignedProducts.length)
      return showNotification({
        error: true,
        message: translate(
          'backOfficeProducts.numberOfAssignedProductsValidation',
        ),
      });

    if (!courseInfo.id) {
      const originals = originalAssignedProductsRef.current;
      return createCourse(courseInfo as CreateCourseInput, {
        productsToAdd: difference(assignedProducts, originals),
        productsToRemove: difference(originals, assignedProducts),
      });
    }
    hasCourseInfoChanged && updateCourses([courseInfo as UpdateCourseInput]);
    if (hasAssignedProductsChanged) {
      const originals = originalAssignedProductsRef.current;
      updateProductsInCourse({
        courseId: courseInfo.id,

        productsToAdd: difference(assignedProducts, originals),
        productsToRemove: difference(originals, assignedProducts),
      });
    }
  };

  const handleDeleteCourse = (courseId: string) => {
    showModal(
      <ConfirmationDialog
        title={
          translate('dialog.deleteTitle') +
          ' ' +
          translate('backOfficeProducts.course')
        }
        message={translate('dialog.deleteConfirmation', {
          label: courseInfo.name,
        })}
        onConfirm={() => {
          deleteCourse(courseId, courseMaps[courseId].name);
          closeModal();
        }}
      />,
    );
  };

  const allPages = useMemo(() => {
    const pages = Object.values(pageMaps);
    if (!pages) return [];
    return pages.map(page => {
      const productOfVariant =
        page.variants?.reduce((allProducts, variant) => {
          if (variant.products) return [...allProducts, ...variant.products];
          return allProducts;
        }, [] as Product[]) || [];
      return {
        ...page,
        products: [...(page.products || []), ...productOfVariant],
      };
    });
  }, [pageMaps]);

  const pagesAndProducts = useMemo<OptionsType[]>(() => {
    const productItems: OptionsType[] = Object.values(products).map(
      product => ({
        type: SelectedItemType.Product,
        value: product.id,
        title: product.name,
        subtitle: translate('backOfficeProductsSummary.productName'),
      }),
    );

    const pageItems: OptionsType[] = allPages.map(page => {
      return {
        type: SelectedItemType.Page,
        value: page.id,
        title: page.name,
        subtitle: translate('backOfficeProducts.page'),
      };
    });
    return [...productItems, ...pageItems];
  }, [allPages, products, translate]);

  const selectedValues = useMemo<string[]>(() => {
    const results: string[] = [];
    const allPagesMaps = keyBy(allPages, 'id');
    pagesAndProducts.forEach(item => {
      const { value: itemId, type } = item;
      if (type === SelectedItemType.Product) {
        assignedProducts.includes(itemId) && results.push(itemId);
      } else {
        const productsInPage = (allPagesMaps[itemId]?.products || []).map(
          product => product.id,
        );
        const isAllProductsAssigned = productsInPage.every(productId =>
          assignedProducts.includes(productId),
        );
        if (!productsInPage.length || !isAllProductsAssigned) return;
        results.push(itemId);
      }
    });
    return results;
  }, [allPages, assignedProducts, pagesAndProducts]);

  const sortedAssignedProducts = useMemo<AssignedProduct[]>(() => {
    return sortBy(
      assignedProducts.map(productId => ({
        id: productId,
        name: products[productId]?.name,
      })),
      product => product.name,
    );
  }, [assignedProducts, products]);

  useEffect(() => {
    if (courseId === DEFAULT_ENTITY_ID) return;
    getCourse(courseId);
  }, [courseId, getCourse]);

  useEffect(() => {
    getPages();
    getAllProducts();
  }, [getAllProducts, getPages]);

  useEffect(() => {
    const tabBarLabel = courseMaps[courseId]?.name;
    if (!tabBarLabel) return;
    navigation.setOptions({ tabBarLabel });
  }, [courseId, courseMaps, navigation]);

  useEffect(() => {
    if (!courseMaps[courseId]) return;
    const { products, ...courseInfo } = courseMaps[courseId];
    const sortedProducts = (products || []).map(product => product.id).sort();
    originalAssignedProductsRef.current = sortedProducts;
    setAssignedProducts(sortedProducts);
    const onlyCourseInfo = omit(courseInfo, '__typename');
    originalCourseInfoRef.current = onlyCourseInfo;
    setCourseInfo(onlyCourseInfo);
  }, [courseId, courseMaps]);

  useEffect(() => {
    if (!error) return;
    showNotification({ error: true, message: error });
  }, [error, showNotification]);

  // const hasDataChanged = hasCourseInfoChanged || hasAssignedProductsChanged;
  const loading = getCourseLoading || getPagesLoading || getProductsLoading;

  return (
    <ScreenLayout
      loading={loading}
      title={`${courseInfo.name || 'Courses'} | Oolio`}
      onSave={handleSaveCourse}
      onDelete={handleDeleteCourse.bind(null, courseInfo.id || '')}
    >
      <Section title={translate('backOfficeProducts.courseDetails')}>
        <View style={theme.forms.row}>
          <InputText
            testID="course-name"
            title={translate('backOfficeProducts.courseName')}
            value={courseInfo?.name}
            placeholder={translate('backOfficeProducts.courseName')}
            onChangeText={handleCourseInfoChange.bind(null, 'name')}
            maxLength={50}
            containerStyle={theme.forms.inputHalf}
          />
          <InputToggle
            testID="toggle-autoFire"
            title={translate('backOfficeProducts.autoFireItems')}
            isToggled={courseInfo?.autoFire || false}
            onToggle={handleCourseInfoChange.bind(
              null,
              'autoFire',
              !courseInfo?.autoFire,
            )}
            containerStyle={[theme.forms.inputHalf, styles.inputToggle]}
          />
        </View>
      </Section>
      <Section title={translate('backOfficeProducts.products')}>
        <SelectSearch
          testID="select-addProduct"
          options={pagesAndProducts}
          selectedOptions={selectedValues}
          onChangeOption={handleAssignedProducts}
          placeholder="Search for products by their name or a page to add..."
          containerStyle={styles.tableContainer}
        />
        {/* eslint-disable-next-line react-native/no-inline-styles */}
        <View style={[styles.tableContainer, { zIndex: -1 }]}>
          <View style={theme.tables.header}>
            <Text style={theme.tables.headerText}>
              {translate('backOfficeProducts.products')}
            </Text>
          </View>
          <View>
            {sortedAssignedProducts.map(
              (product: AssignedProduct, i: number) => (
                <ProductRow
                  key={i}
                  item={product}
                  rowIndex={i}
                  onDelete={handleDeleteItem.bind(null, product)}
                />
              ),
            )}
          </View>
        </View>
      </Section>
    </ScreenLayout>
  );
};

export default CourseDetail;
