/* eslint-disable react-native/no-inline-styles */
import React, { FC, useCallback } from 'react';
import { View, Text, TouchableOpacity } from 'react-native';
import {
  AdjustmentType,
  Modifier,
  OrderItem,
  OrderItemModifier,
  OrderItemStatus,
  VoidReason,
} from '@oolio-group/domain';
import {
  REFUND_ORDER_ITEM,
  computeOrderItemTotal,
  getItemAdjustmentLabel,
} from '@oolio-group/order-helper';
import {
  useCurrency,
  useTranslation,
  useLocalization,
} from '@oolio-group/localization';
import { getLocaleEntity } from '@oolio-group/client-utils';
import styles from './CartProduct.styles';
import theme from '../../../../common/default-theme';
import CartNote from '../CartNote/CartNote';
import { CartSelectionState } from '../Cart';

export interface CartProductProps {
  testID: string;
  product: OrderItem;
  seat?: string;
  isPaid?: boolean;
  isFired?: boolean;
  isSelected?: boolean;
  selectedSubItem?: string;
  minimised?: boolean;
  disabled?: boolean;
  onSelect?: (state: CartSelectionState) => void;
}

interface ProductParentProps {
  product: OrderItem;
  seat?: string;
  isPaid?: boolean;
  isFired?: boolean;
  isVoided: boolean;
  selectedState?: 'full' | 'partial';
  isVariableQty?: boolean;
  disabled?: boolean;
  onPress?: () => void;
}

const ProductParent: FC<ProductParentProps> = ({
  product,
  seat,
  isPaid,
  isFired,
  isVoided,
  isVariableQty,
  selectedState,
  disabled,
  onPress,
}) => {
  const { locale } = useLocalization();
  const { formatCurrency } = useCurrency();

  const getProductName = (orderItem: OrderItem) => {
    const localeEntity = getLocaleEntity(
      orderItem.variant ? orderItem.variant : orderItem.product,
      locale.languageTag,
    );
    const productName =
      orderItem.product?.optionValues?.length > 0
        ? orderItem.product?.name?.split('-')[0]
        : orderItem.product?.name;

    return (
      localeEntity.name ||
      productName ||
      (!orderItem.product?.id ? REFUND_ORDER_ITEM.name : 'Unknown')
    );
  };

  const bgStyle = isVoided
    ? styles.voidView
    : isPaid
    ? styles.paidView
    : selectedState === 'full'
    ? styles.selectedView
    : selectedState === 'partial'
    ? styles.partialSelectedView
    : {};
  const textStyle = isVoided
    ? styles.voidText
    : isPaid
    ? styles.paidText
    : selectedState === 'full'
    ? styles.selectedText
    : selectedState === 'partial'
    ? styles.partialSelectedText
    : {};

  return (
    <TouchableOpacity
      testID="product"
      onPress={onPress}
      style={styles.container}
      disabled={isPaid || disabled}
    >
      <View style={[styles.quantity, bgStyle]}>
        <Text testID="qty" style={[styles.quantityText, textStyle]}>
          {`${product.status === OrderItemStatus.REFUNDED ? '-' : ''}${
            isVariableQty ? '%' : product.quantity
          }`}
        </Text>
      </View>
      <View style={[styles.name, bgStyle]}>
        {isFired ? (
          <Text testID="fired" style={[styles.firedText, textStyle]}>
            ✓
          </Text>
        ) : null}
        <Text
          testID="name"
          numberOfLines={2}
          ellipsizeMode="middle"
          style={[styles.nameText, textStyle]}
        >
          {getProductName(product)}
        </Text>
        <Text testID="price" style={[styles.priceText, textStyle]}>
          {formatCurrency(computeOrderItemTotal(product))}
        </Text>
        {!!seat ? (
          <Text testID="seat" style={[styles.seatText, textStyle]}>
            {seat}
          </Text>
        ) : null}
      </View>
    </TouchableOpacity>
  );
};

const CartProduct: FC<CartProductProps> = ({
  testID,
  product,
  seat,
  isPaid,
  isFired,
  isSelected,
  selectedSubItem,
  minimised,
  disabled,
  onSelect,
}) => {
  const { locale } = useLocalization();
  const { translate } = useTranslation();
  const { formatCurrency } = useCurrency();

  const variations = product.product?.optionValues;
  const isVoided = product.status === OrderItemStatus.VOID;
  const isVariableQty = product.product?.variableQuantity;

  const getSizeText = (product: OrderItem) => {
    const units = product.product.measuredBy.units;
    const defaultSize = product.product.measuredBy.defaultSize;

    const sizeText = `${
      product.quantity * defaultSize
    }${units} NET @ ${formatCurrency(
      product.unitPrice,
    )}/${defaultSize}${units}`;

    return sizeText;
  };

  const getModifierName = (modifier: OrderItemModifier) => {
    const entity = getLocaleEntity(
      modifier as unknown as Modifier,
      locale.languageTag,
    );

    return entity.name || modifier.name;
  };

  const onSelectVariantion = useCallback(
    (variant: string): void => {
      onSelect &&
        onSelect({
          item: product.id,
          variant: product.variant.id,
          selectedVariantKey: variant,
          product: product.product.id,
        });
    },
    [product, onSelect],
  );

  const onPressProduct = useCallback(() => {
    const quantity = product.quantity;
    onSelect &&
      onSelect({
        item: product.id,
        product: product.product?.id,
        variant: product.variant?.id,
        quantity,
      });
  }, [product, onSelect]);

  const onSelectOption = useCallback(
    (modifier: string, modifierGroup: string, tempModifierId: string) => {
      onSelect &&
        onSelect({
          item: product.id,
          modifier,
          product: product.product.id,
          modifierGroup,
          selectedModifierKey: tempModifierId,
        });
    },
    [product, onSelect],
  );

  const isPartiallySelected = isSelected && selectedSubItem != undefined;

  const ParentProduct = (
    <ProductParent
      disabled={disabled}
      product={product}
      seat={seat}
      isPaid={isPaid}
      isFired={isFired}
      isVoided={isVoided}
      selectedState={
        isPartiallySelected ? 'partial' : isSelected ? 'full' : undefined
      }
      isVariableQty={isVariableQty}
      onPress={onPressProduct}
    />
  );

  if (minimised) {
    return ParentProduct;
  }

  return (
    <View testID={testID}>
      {/* Parent */}
      {ParentProduct}
      {/* Product Size */}
      {isVariableQty ? (
        <View
          style={[
            styles.container,
            styles.variation,
            isVoided && styles.voidView,
          ]}
        >
          <Text testID="size" style={styles.nameText}>
            {getSizeText(product)}
          </Text>
        </View>
      ) : null}
      {/* Voids */}
      {isVoided ? (
        <View style={[styles.container, styles.voidReason]}>
          <Text testID="void-reason" style={styles.nameText}>
            {translate('order.itemVoidReason', {
              value: translate(`enums.${product.reason as VoidReason}`),
            })}
          </Text>
        </View>
      ) : null}
      {/* Notes */}
      {product.notes?.length > 0 ? (
        <CartNote testID="note" note={product.notes} productNote={true} />
      ) : null}
      {/* Variations */}
      {variations &&
        variations?.map((variant, i) => {
          const selected = variant.key === selectedSubItem && isSelected;
          const partiallySelected =
            variant.key !== selectedSubItem && isSelected;

          return (
            <TouchableOpacity
              key={`variation-${i}`}
              testID="btn-variant"
              style={[
                styles.container,
                styles.variation,
                isVoided && styles.voidView,
                selected && styles.selectedView,
                partiallySelected && styles.partialSelectedView,
                { opacity: isVoided ? 0.3 : 1 },
              ]}
              onPress={onSelectVariantion.bind(null, variant.key)}
            >
              <Text
                testID="variant"
                style={[
                  styles.nameText,
                  selected && styles.selectedText,
                  partiallySelected && styles.partialSelectedText,
                ]}
              >
                {variant.value}
              </Text>
            </TouchableOpacity>
          );
        })}
      {/* Modifiers */}
      {product.modifiers?.map((modifier, i) => {
        const tempModifierId = `${product.id}-${modifier.id}-${i}`;
        const selected = tempModifierId === selectedSubItem;
        const partiallySelected = isSelected && !selected;

        return (
          <TouchableOpacity
            key={`modifier-${i}`}
            testID="btn-modifier"
            onPress={onSelectOption.bind(
              null,
              modifier.id || '',
              modifier?.modifierGroupId || '',
              tempModifierId,
            )}
            style={[styles.container, { opacity: isVoided ? 0.3 : 1 }]}
          >
            <View
              style={[
                styles.quantity,
                { backgroundColor: theme.colors.white },
                isVoided && modifier.quantity > 1 && styles.voidView,
                selected && modifier.quantity > 1 && styles.selectedView,
                partiallySelected &&
                  modifier.quantity > 1 &&
                  styles.partialSelectedView,
              ]}
            >
              <Text testID="mod-qty" style={styles.quantityText}>
                {modifier.quantity > 1 ? modifier.quantity : ''}
              </Text>
            </View>
            <View
              style={[
                styles.name,
                { backgroundColor: theme.colors.white },
                isVoided && styles.voidView,
                selected && styles.selectedView,
                partiallySelected && styles.partialSelectedView,
              ]}
            >
              <Text
                testID="mod-name"
                style={[
                  styles.nameText,
                  selected && styles.selectedText,
                  partiallySelected && styles.partialSelectedText,
                ]}
              >
                {getModifierName(modifier)}
              </Text>
              <Text
                testID="mod-price"
                style={[
                  styles.priceText,
                  selected && styles.selectedText,
                  partiallySelected && styles.partialSelectedText,
                ]}
              >
                {formatCurrency(
                  (modifier.unitPrice || 0) * (modifier.quantity || 0),
                )}
              </Text>
            </View>
          </TouchableOpacity>
        );
      })}
      {/* Adjustments */}
      {product.discountAmount &&
      product.adjustments &&
      product.adjustments[0].adjustmentType !== AdjustmentType.REWARD ? (
        <View style={[styles.container, styles.adjustmentMargin]}>
          <View
            style={[
              styles.name,
              {
                backgroundColor: isSelected
                  ? theme.colors.blueLight
                  : theme.colors.white,
              },
            ]}
          >
            <Text testID="discount-name" style={styles.nameText}>
              {getItemAdjustmentLabel(product.adjustments)}
            </Text>
            <Text testID="discount-amt" style={styles.priceText}>
              {formatCurrency(-Math.abs(product.discountAmount) || 0)}
            </Text>
          </View>
        </View>
      ) : null}
      {product.surchargeAmount ? (
        <View style={[styles.container, styles.adjustmentMargin]}>
          <View
            style={[
              styles.name,
              {
                backgroundColor: isSelected
                  ? theme.colors.blueLight
                  : theme.colors.white,
              },
            ]}
          >
            <Text testID="surcharge-name" style={styles.nameText}>
              {getItemAdjustmentLabel(product.adjustments)}
            </Text>
            <Text testID="surcharge-amt" style={styles.priceText}>
              {formatCurrency(product.surchargeAmount || 0)}
            </Text>
          </View>
        </View>
      ) : null}
    </View>
  );
};

export default CartProduct;
