/* eslint-disable react-native/no-raw-text */
import { useReactiveVar, useSubscription } from '@apollo/client/react/hooks';
import {
  App,
  Device,
  DeviceEvent,
  DeviceEventActions,
  FeatureIDs,
  IntegrationApps,
  IntegrationPartner,
} from '@oolio-group/domain';
import { useTranslation } from '@oolio-group/localization';
import { ModalProvider } from '@oolio-group/rn-use-modal';
import { Tracer } from '@oolio-group/tracer-client';
import { useNetInfo } from '@react-native-community/netinfo';
import { createDrawerNavigator } from '@react-navigation/drawer';
import { useIsFocused, useNavigation } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import { isEmpty } from 'lodash';
import React, { useCallback, useEffect } from 'react';
import { Dimensions, StyleSheet } from 'react-native';
import { Subscription } from 'rxjs/internal/Subscription';
import { distinctUntilChanged, filter, pluck } from 'rxjs/operators';
import { useProductEvents } from '../../../src/hooks/app/events/useProductEvents';
import theme from '../../common/default-theme';
import Navigation from '../../components/POS/Navigation/Navigation';
import Backdrop from '../../components/Modals/Backdrop';
import {
  DeviceEvents,
  UpdateProductsAvailabilityEvent,
} from '../../graphql/subscriptions';
import { useCheckFeatureEnabled } from '../../hooks/app/features/useCheckFeatureEnabled';
import { useAutoCompleteOrders } from '../../hooks/app/orders/useAutoCompleteOrders';
import { useDeviceInfo } from '../../hooks/app/useDeviceInfo';
import { useLogout } from '../../hooks/app/useLogout';
import useRolesContext from '../../hooks/app/users/useRolesContext';
import { useSession } from '../../hooks/app/useSession';
import { useSwitchPosUserEffect } from '../../hooks/app/useSwitchPosUserEffect';
import { CartProvider } from '../../hooks/CartProvider';
import { useNotification } from '../../hooks/Notification';
import POSUserAuthorizationProvider from '../../hooks/POSUserAuthorizationProvider';
import POSUserRoleProvider from '../../hooks/POSUserRoleProvider';
import { PrintingProvider } from '../../hooks/PrintingProvider';
import {
  failedPrintJobsCountVar,
  isReservationsEnabledVar,
  subscriptionDeviceEvent,
} from '../../state/cache';
import { WORKER_MESSAGES_KEY } from '../../state/preferences';
import { tokenUtility } from '../../state/tokenUtility';
import * as storage from '../../storage/interface';
import { getItem } from '../../storage/interface';
import { INTEGRATION_SETTINGS_KEY } from '../../types/Common';
import { parseApolloError } from '../../utils/errorHandlers';
import {
  WorkerActionResult,
  WorkerActionResultStatus,
} from '../../workers/utils';
import { LoadingScreen } from '../Loading/Loading';
import AssignToDeviceProfile from './AssignToDeviceProfile/AssignToDeviceProfile';
import CustomersStack from './Customers/CustomersStack';
import LockScreen from './LockScreen/LockScreen';
import ManageCash from './ManageCash/ManageCash';
import OnlineOrderAudioNotification from './OnlineOrderAudioNotification';
import { OrderHermes } from './OrderHermes';
import OrderHistory from './OrderHistory/OrderHistory';
import {
  default as OrdersStack,
  default as OrderStack,
} from './Orders/OrdersStack';
import { PrintFailedBanner } from '../../components/POS/PrintingError/PrintFailedBanner';
import SettingsStack from './Settings/SettingsStack';
import Shifts from './Shifts/Shifts';
import * as Unicons from '@oolio-group/react-native-unicons';
import { WorklogEvent } from './WorklogEvent';
import { useOrderTypes } from '../../hooks/app/orderTypes/useOrderTypes';
import { useCatalogue } from '../../hooks/app/catalogue/useCatalogue';
import { AuthState, FEATURES } from '../../constants';
import ReservationsList from './Reservations/List/List';

import { analyticsService } from '../../analytics/AnalyticsService';
import { useIntegrationPartners } from '../../hooks/app/useIntegrationPartners/useIntegrationPartners';

const Drawer = createDrawerNavigator();
const Stack = createStackNavigator();
const { height, width } = Dimensions.get('window');

const POSNavigator: React.FC = () => {
  const { translate } = useTranslation();
  const [session, setSession] = useSession();
  const netInfo = useNetInfo();
  const { showNotification } = useNotification();
  const { productEventsHandler } = useProductEvents();
  const { logout } = useLogout();
  const navigation = useNavigation();
  const currentStoreId = session?.currentStore?.id || '';
  const deviceId = session?.device?.id || '';
  const currentMenuId = session?.deviceProfile?.menu?.id;

  const isFocused = useIsFocused();

  const { fetchRolesSync, updateRoles, rolesById } = useRolesContext();
  const failedPrintJobsCount = useReactiveVar<number>(failedPrintJobsCountVar);

  const { startTimer, stopTimer } = useAutoCompleteOrders();
  const deviceInfo = useDeviceInfo();
  const isValidCurrentDevice =
    session?.device && session?.device?.uuid === deviceInfo.uuid;

  const { getOrderTypes } = useOrderTypes({
    fetchPolicy: 'cache-first',
  });
  // get products belongs to catalogue to update products cached for autoAccept printer online orders
  useCatalogue({ store: currentStoreId, menuId: currentMenuId });

  useEffect(() => {
    getOrderTypes();
  }, [getOrderTypes]);

  const { integrationPartners, getIntegrationPartnerSettings } =
    useIntegrationPartners();

  useEffect(() => {
    getIntegrationPartnerSettings({
      appName: App.OOLIO_RESERVATION,
      // store: session.currentStore?.id,
    });
  }, [getIntegrationPartnerSettings, session.currentStore?.id]);

  useEffect(() => {
    (async () => {
      const result: IntegrationPartner[] | undefined = await getItem(
        INTEGRATION_SETTINGS_KEY,
      );
      const integrationPartner = result?.find(
        setting => setting.appName === IntegrationApps.OOLIO_STORE,
      );
      if (integrationPartner?.preferences?.onlineOrdering?.autoCompleteOrders)
        startTimer();
      else if (
        integrationPartner?.preferences?.onlineOrdering?.autoCompleteOrders ===
        false
      ) {
        stopTimer();
      }
    })();
  }, [startTimer, stopTimer]);

  useEffect(() => {
    return () => {
      stopTimer();
    };
  }, [stopTimer]);

  useSwitchPosUserEffect();

  const unPairCdsFromSession = useCallback(() => {
    const newSession = { ...session };
    (newSession.device as Device).customerDisplay = undefined;
    setSession(newSession);
  }, [setSession, session]);

  const addCdsToSession = useCallback(
    (event: DeviceEvent) => {
      const newSession = { ...session };
      (newSession.device as Device).customerDisplay =
        event.payload?.customerDisplay;
      setSession(newSession);
    },
    [setSession, session],
  );

  const onDeviceEvent = useCallback(
    ({ subscriptionData }) => {
      if (subscriptionData?.data?.['deviceEvents']) {
        const deviceEvent = subscriptionData.data['deviceEvents'][0];
        subscriptionDeviceEvent(deviceEvent);
        switch (deviceEvent.action) {
          case DeviceEventActions.LOGOUT_ACTION:
            logout();
            break;
          case DeviceEventActions.UN_PAIR_CDS_ACTION:
            unPairCdsFromSession();
            break;
          case DeviceEventActions.PAIR_CDS_ACTION:
            addCdsToSession(deviceEvent);
            break;
          default:
            break;
        }
      }
    },
    [logout, unPairCdsFromSession, addCdsToSession],
  );

  const { error: deviceSubscriptionError } = useSubscription(DeviceEvents, {
    variables: {
      storeId: currentStoreId,
      deviceId,
    },
    onSubscriptionData: onDeviceEvent,
  });

  const {
    data: productAvailabilitySubscriptionData,
    error: productAvailabilitySubscriptionError,
  } = useSubscription(UpdateProductsAvailabilityEvent, {
    variables: {
      storeId: currentStoreId,
      deviceId,
    },
  });

  const isReservationsEnabled = useReactiveVar(isReservationsEnabledVar);
  useEffect(() => {
    const integration = Object.values(integrationPartners)[0];
    if (integration) {
      async function checkFeatureFlag() {
        // check if reservations is enabled or not
        const isEnabled = await analyticsService.isFeatureEnabled(
          FEATURES.OOLIO_RESERVATION,
        );
        isReservationsEnabledVar(integration?.isActive && isEnabled);
      }
      checkFeatureFlag();
    }
  }, [integrationPartners]);

  const isFeatureEnabled = useCheckFeatureEnabled();
  const enableFloorView = session?.deviceProfile?.enableFloorView;
  const isTableFeatureEnabled = isFeatureEnabled(
    FeatureIDs.TABLE_MANAGEMENT,
    session.currentVenue?.id,
  );

  const fetchAndUpdateRoles = useCallback(async () => {
    const roles = await fetchRolesSync();
    updateRoles(roles);
  }, [fetchRolesSync, updateRoles]);

  useEffect(() => {
    if (isFocused && isEmpty(rolesById)) {
      fetchAndUpdateRoles();
    }
  }, [fetchAndUpdateRoles, isFocused, rolesById]);

  useEffect((): void => {
    const error =
      productAvailabilitySubscriptionError || deviceSubscriptionError;
    if (error) {
      showNotification({
        error: true,
        message: parseApolloError(error),
      });

      Tracer.getInstance().captureException(
        new Error('Error while initiating subscription'),
        {
          extra: {
            error,
          },
        },
      );
    }
  }, [
    showNotification,
    productAvailabilitySubscriptionError,
    deviceSubscriptionError,
  ]);

  useEffect(() => {
    if (productAvailabilitySubscriptionData) {
      productEventsHandler(
        productAvailabilitySubscriptionData.updateProductsAvailabilityEvent ||
          [],
      );
    }
  }, [productAvailabilitySubscriptionData, productEventsHandler]);

  useEffect(() => {
    if (
      netInfo.isConnected === false &&
      netInfo.isInternetReachable === false
    ) {
      showNotification({
        error: true,
        message: translate('settings.noInternet'),
      });
    }
  }, [netInfo, showNotification, translate]);

  useEffect(() => {
    const subscription: Subscription = tokenUtility.getTokenInfo$
      .pipe(
        distinctUntilChanged((prev, curr) => prev.authState === curr.authState),
        // select authState
        pluck('authState'),
        // emit only when `authState` is `logout`
        filter(authState => authState === AuthState.LOGOUT),
      )
      .subscribe(() => {
        navigation.reset({
          index: 0,
          routes: [{ name: 'LoginTypeSelection' }],
        });
      });

    return () => {
      subscription?.unsubscribe();
    };
  }, [navigation]);

  useEffect(() => {
    (async () => {
      const responses =
        (await storage.getItem<WorkerActionResult[]>(WORKER_MESSAGES_KEY)) ||
        [];
      failedPrintJobsCountVar(
        (responses || []).reduce(
          (acc, item) =>
            item.status === WorkerActionResultStatus.ERROR ? ++acc : acc,
          0,
        ),
      );
    })();
  }, []);

  if (isEmpty(rolesById)) {
    return <LoadingScreen />;
  }

  return (
    <POSUserRoleProvider>
      <ModalProvider
        modalProps={{
          deviceHeight: height,
          deviceWidth: width,
          customBackdrop: <Backdrop />,
          animationInTiming: 50,
          animationOutTiming: 50,
          animationIn: 'fadeIn',
          animationOut: 'fadeOut',
          useNativeDriver: true,
          hideModalContentWhileAnimating: true,
        }}
      >
        <PrintingProvider>
          {isValidCurrentDevice ? (
            <POSUserAuthorizationProvider>
              {failedPrintJobsCount > 0 ? (
                <PrintFailedBanner
                  failedPrintJobsCount={failedPrintJobsCount}
                />
              ) : (
                <></>
              )}
              <CartProvider>
                <Drawer.Navigator
                  initialRouteName="Lock"
                  drawerPosition="right"
                  drawerStyle={styles.drawer}
                  drawerContent={Navigation}
                >
                  <Drawer.Screen
                    name="Orders"
                    component={OrdersStack}
                    options={{
                      title: 'Take Order',
                      drawerLabel: translate('navigation.takeOrder'),
                      drawerIcon: Unicons.UilPlusCircle,
                    }}
                  />
                  <Drawer.Screen
                    name="OrderHistory"
                    component={OrderHistory}
                    options={{
                      title: 'Order History',
                      drawerLabel: translate('navigation.orderHistory'),
                      drawerIcon: Unicons.UilHistory,
                    }}
                  />
                  <Drawer.Screen
                    name="Customers"
                    component={CustomersStack}
                    options={{
                      title: 'Customers',
                      drawerLabel: translate('navigation.customers'),
                      drawerIcon: Unicons.UilUserCircle,
                    }}
                  />
                  {enableFloorView && isTableFeatureEnabled && (
                    <Drawer.Screen
                      name="FloorViewStack"
                      component={OrderStack}
                      options={{
                        title: 'Floor View',
                        drawerLabel: translate('navigation.floorView'),
                        drawerIcon: Unicons.UilLayers,
                        unmountOnBlur: true,
                      }}
                    />
                  )}
                  {isReservationsEnabled && (
                    <Drawer.Screen
                      name="Reservations"
                      component={ReservationsList}
                      options={{
                        title: 'Reservations',
                        drawerLabel: translate('navigation.reservations'),
                        drawerIcon: Unicons.UilCalendarAlt,
                      }}
                    />
                  )}
                  <Drawer.Screen
                    name="Shifts"
                    component={Shifts}
                    options={{
                      title: 'Shifts',
                      drawerLabel: translate('navigation.shifts'),
                      drawerIcon: Unicons.UilStopwatch,
                      unmountOnBlur: true,
                    }}
                  />
                  {session.deviceProfile?.enableCashManagement && (
                    <Drawer.Screen
                      name="ManageMoney"
                      component={ManageCash}
                      options={{
                        title: 'Manage Cash',
                        drawerLabel: translate(
                          'storesSettings.tabNames.manageCash',
                        ),
                        drawerIcon: Unicons.UilBag,
                      }}
                    />
                  )}
                  <Drawer.Screen
                    name="POSSettings"
                    component={SettingsStack}
                    options={{
                      title: 'Settings',
                      drawerLabel: translate('navigation.settings'),
                      drawerIcon: Unicons.UilCog,
                    }}
                  />
                  <Drawer.Screen
                    name="Lock"
                    component={LockScreen}
                    options={{
                      title: 'Lock Screen',
                      drawerLabel: translate('navigation.switchUser'),
                      drawerIcon: Unicons.UilLockAlt,
                      unmountOnBlur: true,
                    }}
                  />
                </Drawer.Navigator>
              </CartProvider>
            </POSUserAuthorizationProvider>
          ) : (
            <Stack.Navigator
              screenOptions={{
                animationEnabled: true,
              }}
              headerMode="none"
            >
              <Stack.Screen
                component={AssignToDeviceProfile}
                name="AssignToDeviceProfile"
              />
            </Stack.Navigator>
          )}
        </PrintingProvider>
      </ModalProvider>
      <OnlineOrderAudioNotification />
      {!session.device?.disablePolling && <OrderHermes />}
      <WorklogEvent />
    </POSUserRoleProvider>
  );
};

export default POSNavigator;

const styles = StyleSheet.create({
  drawer: {
    width: 260,
  },
  errorContainer: {
    padding: 20,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.colors.red,
    zIndex: 999,
  },
  errorText: {
    fontSize: 14,
    color: theme.colors.white,
    fontFamily: theme.fonts.medium,
  },
  errorCount: {
    paddingVertical: 4,
    paddingHorizontal: 8,
    borderRadius: theme.radius.m,
    backgroundColor: theme.colors.black,
  },
});
