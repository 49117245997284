import React, { useCallback, useEffect, useState } from 'react';
import { StyleSheet } from 'react-native';
import { usePhoneNumber, translate } from '@oolio-group/localization';
import {
  isValidEmail,
  isValidName,
  isValidNumber,
  trimString,
} from '../../../../../utils/validator';
import { useNotification } from '../../../../../hooks/Notification';
import { CreateCustomerRequest, Customer } from '@oolio-group/domain';
import { DEFAULT_COUNTRY_CODE } from '../../../../../constants';
import { useSession } from '../../../../../hooks/app/useSession';
import TreatModal from '../../../../../components/Shared/Modals/Modal/Modal';
import InputText from '../../../../../components/Shared/Inputs/InputText';
import InputEmail from '../../../../../components/Shared/Inputs/InputEmail';
import InputPhone from '../../../../../components/Shared/Inputs/InputPhone';

interface CreateCustomerModalProps {
  customerInfo?: Customer;
  onSubmit: (input: CreateCustomerRequest) => Promise<void>;
  onDismiss: () => void;
  loading?: boolean;
}

interface ICustomerForm {
  firstName: string;
  lastName: string;
  email: string;
  countryCode: string;
  phone: string;
}

const CreateCustomerModal: React.FC<CreateCustomerModalProps> = ({
  onDismiss,
  loading,
  onSubmit,
}) => {
  const { getFullFormattedPhoneNumber } = usePhoneNumber();

  const [errorMessage, setErrorMessage] = useState('');
  const { showNotification } = useNotification();
  const [session] = useSession();
  const { country = DEFAULT_COUNTRY_CODE } = session?.currentOrganization || {};

  const [form, setForm] = useState<ICustomerForm>({
    firstName: '',
    lastName: '',
    email: '',
    countryCode: country,
    phone: '',
  });

  const isUK = form.countryCode === 'GB';
  useEffect(() => {
    if (errorMessage !== '') {
      showNotification({
        error: true,
        message: errorMessage,
      });
      setErrorMessage('');
    }
  }, [errorMessage, showNotification]);

  const isPhoneValid = isValidNumber(
    getFullFormattedPhoneNumber(form.countryCode, form.phone),
  );

  const onChangeFormInput = useCallback((prop: string, value: string) => {
    setForm(form => ({
      ...form,
      [prop]: value,
    }));
  }, []);

  const onCreate = useCallback(() => {
    const { firstName, lastName, email, phone } = form;
    if (trimString(firstName) === '') {
      setErrorMessage(
        translate('backOfficeCustomerModal.errorFirstNameMissing'),
      );
      return;
    } else if (email === '' && phone.length === 0) {
      setErrorMessage(translate('customer.emptyEmailandPhoneMessage'));
      return;
    } else if (email && !isValidEmail(email)) {
      setErrorMessage(translate('customer.invalidEmailMessage'));
      return;
    } else if (phone && !isPhoneValid) {
      setErrorMessage(translate('customer.invalidPhoneMessage'));
      return;
    }
    const preferredAddress = {
      isoCountryCode: form.countryCode,
    };

    const customerAccountDetails = {
      currentBalance: 0,
      accountPayment: false,
    };
    const customer = {
      firstName,
      lastName,
      email,
      phone: getFullFormattedPhoneNumber(form.countryCode, form.phone),
      phoneNumber: phone,
      preferredAddress,
      customerAccountDetails,
    };
    onSubmit(customer);
  }, [form, getFullFormattedPhoneNumber, isPhoneValid, onSubmit]);

  return (
    <TreatModal
      type={'positive'}
      title={translate('backOfficeCustomerModal.createTitle')}
      onConfirm={{
        label: translate('backOfficeCustomerModal.createCustomerBtn'),
        action: onCreate,
      }}
      onDismiss={{ action: onDismiss }}
      loading={loading}
    >
      <InputText
        testID="input-first-name"
        title={translate('form.firstName')}
        value={form.firstName}
        placeholder={'Jenny'}
        onChangeText={onChangeFormInput.bind(null, 'firstName')}
        errorMessage={
          isValidName(form.firstName) ? undefined : 'Enter valid name'
        }
        containerStyle={styles.inputContainer}
      />
      <InputText
        testID="input-last-name"
        title={translate('form.lastName')}
        value={form.lastName}
        placeholder={'Murtaugh'}
        onChangeText={onChangeFormInput.bind(null, 'lastName')}
        errorMessage={
          isValidName(form.lastName) ? undefined : 'Enter valid name'
        }
        containerStyle={styles.inputContainer}
      />
      <InputPhone
        testID="input-phone"
        title={translate('form.phoneNumber')}
        value={form.phone}
        placeholder={translate(
          isUK ? 'form.phoneNumberHintUK' : 'form.phoneNumberHint',
        )}
        onChangeText={onChangeFormInput.bind(null, 'phone')}
        onPressCountry={onChangeFormInput.bind(null, 'countryCode')}
        containerStyle={styles.inputContainer}
        defaultCountry={form?.countryCode}
      />
      <InputEmail
        testID="input-email"
        title={translate('common.emailAddress')}
        value={form.email}
        placeholder={'jenny@email.com'}
        onChangeText={onChangeFormInput.bind(null, 'email')}
      />
      {/* <FormInput
        title={translate('backOfficeCustomerModal.address')}
        alignTitle={'left'}
        value={form.line1}
        onChangeText={onChangeFormInput.bind(null, 'line1')}
        verified={isNotEmpty(form.line1)}
        placeholder={'102 flora street Australia'}
      />
      <FormInput
        title={translate('form.suburb')}
        alignTitle={'left'}
        value={form.suburb}
        onChangeText={onChangeFormInput.bind(null, 'suburb')}
        verified={isValidName(form.suburb)}
        placeholder={translate('form.suburb')}
      />
      <FormInput
        title={translate('form.postCode')}
        alignTitle={'left'}
        value={form.postalCode}
        onChangeText={onChangeFormInput.bind(null, 'postalCode')}
        verified={isNotEmpty(form.postalCode)}
        placeholder={'100043'}
        maxLength={6}
      /> */}
    </TreatModal>
  );
};

export default CreateCustomerModal;

const styles = StyleSheet.create({
  inputContainer: {
    marginBottom: 20,
  },
});
