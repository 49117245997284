import {
  Product,
  CreateProductInput,
  CreateVariantProductInput,
  ProductPricingInput,
  DEFAULT_PRICING_GROUP,
  Currency,
  Money,
  OnboardingArea,
  OnboardingCheckList,
  OnboardingSection,
  OnboardingAction,
  Variant,
  IMPORT_ENTITY_STATUS,
  ModifierGroup,
} from '@oolio-group/domain';
import { useNavigation } from '@react-navigation/native';
import React, { useEffect, useMemo, useCallback, useState } from 'react';
import { View, Text } from 'react-native';
import { useNotification } from '../../../../hooks/Notification';
import {
  useCurrency,
  useLocalization,
  useTranslation,
} from '@oolio-group/localization';
import { useVariants } from '../../../../hooks/app/variants/useVariants';
import { useProducts } from '../../../../hooks/app/products/useProducts';
import { PRODUCTS_IN_PRODUCT_LIST_SCREEN } from '../../../../hooks/app/products/graphql';
import { VARIANTS_IN_PRODUCT_LIST_SCREEN } from '../../../../hooks/app/variants/graphql';
import { pricingGroupInfoDetails } from '../../../../graphql/pricingGroups';
import { useTaxes } from '../../../../hooks/app/useTaxes';
import { useProductPricings } from '../../../../hooks/app/productPricings/useProductPricings';
import { usePricingGroups } from '../../../../hooks/app/usePricingGroups';
import { useAvailabilityOptions } from '../../../../hooks/app/useAvailabilityOptions';
import { usePrinterProfiles } from '../../../../hooks/app/usePrinterProfiles';
import { useOnboarding } from '../../../../hooks/app/useOnboarding';
import orderBy from 'lodash/orderBy';
import { useModal } from '@oolio-group/rn-use-modal';
import {
  CreateProductModal,
  CreateProductForm,
} from './Modals/CreateProductModal';
import { useProductTypes } from '../../../../hooks/app/useProductTypes';
import {
  ProductHeaderFilters,
  getProductsBySearchQuery,
  getProductByFilteredCategory,
  getProductByFilteredStore,
  getProductByPrinterProfile,
  getProductByOptionGroups,
} from './ProductHeaderFilters';
import { useIsFocused } from '@react-navigation/native';
import { ProductListItem } from './types';
import {
  CATEGORIES_AS_OPTIONS,
  useCategories,
} from '../../../../hooks/app/categories/useCategories';
import { getLocaleEntity } from '@oolio-group/client-utils';
import sortBy from 'lodash/sortBy';
import ProductListView from './ListView/ProductListView';
import { ProductBulkOptions } from './ProductBulkOptions';
import styles from './ProductList.styles';
import ConfirmationModal from '../../../../components/Modals/ConfirmationDialog';
import ScreenLayout from '../../../../components/Office/ScreenLayout/ScreenLayout';
import Section from '../../../../components/Office/Section/Section';
import CreateButton from '../../../../components/Office/CreateButton/CreateButton';
import ModalWrapper from '../../../../hooks/ModalWrapper';
import MessageAction from '../../../../components/Office/MessageAction/MessageAction';
import { useImportEntityInterval } from '../../../../hooks/app/importEntities/useImportEntityInterval';
import { analyticsService } from '../../../../analytics/AnalyticsService';
import { FEATURES } from '../../../../constants';
import { useModifierGroups } from '../../../../hooks/app/modifierGroups/useModifierGroups';

export type allProductsforDataGridKeys =
  | 'id'
  | 'name'
  | 'productTypeId'
  | 'price'
  | 'tax'
  | 'isSellable'
  | 'isVariant'
  | 'isVariantProduct';

export interface FilterValue {
  searchString: string;
  category: string;
  store: string;
  printerProfilesFilter: string[];
  optionGroups: string[];
}

const defaultFilter: FilterValue = {
  category: '',
  searchString: '',
  store: '',
  printerProfilesFilter: [],
  optionGroups: [],
};
export const getDefaultInfoForProduct = (
  product: Product,
): { price: number; taxId: string; priceId: string; currency: Currency } => {
  const defaultPG = product?.pricingGroups?.filter(
    pricingGroup => pricingGroup.name === DEFAULT_PRICING_GROUP,
  );

  const defaultPrice = defaultPG?.[0]?.['prices'][0];

  return {
    price: defaultPrice?.sellingPrice?.amount || 0,
    priceId: defaultPrice?.id || '',
    taxId: defaultPrice?.sellingTax?.id || '',
    currency: defaultPrice?.sellingPrice?.currency || Currency.AUD,
  };
};

export const ProductsList: React.FC = () => {
  const isFocused = useIsFocused();
  const { translate } = useTranslation();
  const { showNotification } = useNotification();
  const { currencySymbol, currency } = useCurrency();
  const { showModal, closeModal } = useModal();
  const { locale } = useLocalization();
  const { updateOnboardingStatus } = useOnboarding();
  const navigation = useNavigation();
  const [allProductsMap, setAllProductsMap] = useState<{
    [key: string]: ProductListItem;
  }>({});
  const [filter, setFilter] = useState<FilterValue>(defaultFilter);
  const [isImportProductFeatureEnabled, setImportProductFeatureFlag] =
    useState<boolean>(false);

  const {
    category,
    searchString,
    store,
    printerProfilesFilter,
    optionGroups: optionGroupsFilters,
  } = filter;
  const [currentPage, setCurrentPage] = useState(1);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [optionsGroupsData, setOptionsGroupsData] = useState<
    Record<string, ModifierGroup>
  >({});
  const [creatingProduct, setCreatingProduct] = useState<
    Partial<CreateProductForm>
  >({});

  const {
    categoryMaps,
    getCategories,
    error: categoriesError,
    loading: categoriesLoading,
  } = useCategories({
    customFragment: CATEGORIES_AS_OPTIONS,
    fetchPolicy: 'cache-first',
  });

  useEffect(() => {
    async function getImportProductFlag() {
      const isEnabled = await analyticsService.isFeatureEnabled(
        FEATURES.IMPORT_PRODUCT,
      );
      setImportProductFeatureFlag(isEnabled);
    }
    getImportProductFlag();
  }, []);

  const sortedProducts = useMemo(() => {
    // sorting only products and variants
    const productsArray = Object.values(allProductsMap);
    const sortedNonVarProds = orderBy(
      productsArray.filter(item => item && !item.isVariantProduct),
      ['name'],
      ['asc'],
    );
    const allItems: ProductListItem[] = [];
    sortedNonVarProds.forEach(item => {
      allItems.push(item);
    });
    return allItems;
  }, [allProductsMap]);

  const allProductsArray = useMemo(() => {
    const productsArray = Object.values(allProductsMap);
    let allProducts = sortedProducts;
    if (searchString) {
      allProducts = getProductsBySearchQuery(productsArray, searchString);
    }
    if (category) {
      allProducts = getProductByFilteredCategory(allProducts, category);
    }
    if (store) {
      allProducts = getProductByFilteredStore(allProducts, store);
    }
    if (printerProfilesFilter.length > 0) {
      allProducts = getProductByPrinterProfile(
        allProducts,
        printerProfilesFilter,
        allProductsMap,
      );
    }
    if (optionGroupsFilters.length > 0) {
      allProducts = getProductByOptionGroups(
        allProducts,
        optionGroupsFilters,
        allProductsMap,
      );
    }
    return allProducts;
  }, [
    allProductsMap,
    category,
    optionGroupsFilters,
    printerProfilesFilter,
    searchString,
    sortedProducts,
    store,
  ]);

  const selectedProducts = useMemo(() => {
    return allProductsArray.filter(x => x && x.isSelected);
  }, [allProductsArray]);

  const changedProducts = useMemo(() => {
    return allProductsArray.filter(x => x && x.isChanged);
  }, [allProductsArray]);

  const {
    products,
    error: prodErr,
    loading: prodLoading,
    createProduct,
    updateProducts,
    deleteProducts,
    copyProduct,
    bulkUpdateProductsCategory,
    getAllProducts,
  } = useProducts(undefined, PRODUCTS_IN_PRODUCT_LIST_SCREEN);
  const {
    importProductDetailState,
    isImportEntityRunning,
    clearState: clearImportIntervalState,
    getImportProductDetails,
  } = useImportEntityInterval(true);

  const {
    error: optionsGroupsError,
    loading: optionsGroupsLoading,
    modifierGroups: optionsGroups,
    getAllModifierGroups,
  } = useModifierGroups();

  const {
    variants,
    error: variantErr,
    getAllVariants,
    createVariant,
    loading: varLoading,
    updateVariants,
    deleteVariants,
    copyVariant,
    bulkUpdateVariantsCategory,
    deleteVariantProductInCache,
  } = useVariants(undefined, VARIANTS_IN_PRODUCT_LIST_SCREEN);
  const {
    error: errorPP,
    loading: loadingPP,
    update: updateProductPricings,
  } = useProductPricings({ customFragment: PRODUCTS_IN_PRODUCT_LIST_SCREEN });

  const {
    loading: optionsLoading,
    salesChannels,
    stores,
  } = useAvailabilityOptions();

  const {
    defaultPricingGroup,
    getAllPricingGroups,
    error: PGErr,
    loading: PricingGroupLoading,
  } = usePricingGroups(undefined, pricingGroupInfoDetails);

  const { taxesOptions, taxes, error: taxesErr } = useTaxes();

  const {
    productTypes,
    getProductTypes,
    error: productTypesErr,
  } = useProductTypes({ fetchPolicy: 'cache-first' });

  const {
    error: errorPrintProfile,
    loading: loadingPrintProfile,
    printerProfiles,
    getPrinterProfiles,
  } = usePrinterProfiles();

  const error =
    prodErr ||
    variantErr ||
    PGErr ||
    errorPP ||
    taxesErr ||
    productTypesErr ||
    errorPrintProfile ||
    categoriesError ||
    optionsGroupsError;

  const loading =
    optionsLoading ||
    loadingPP ||
    varLoading ||
    prodLoading ||
    loadingPrintProfile ||
    PricingGroupLoading ||
    categoriesLoading ||
    optionsGroupsLoading;

  const productTypeOptions = useMemo(() => {
    return Object.values(productTypes).map(x => ({
      label: x.name,
      value: x.id,
    }));
  }, [productTypes]);

  const printerProfileOptions = useMemo(() => {
    return Object.values(printerProfiles).map(x => ({
      label: x.name,
      value: x.id,
    }));
  }, [printerProfiles]);

  const optionsGroupOptions = useMemo(() => {
    return Object.values(optionsGroupsData).map(x => ({
      label: x.name,
      value: x.id,
    }));
  }, [optionsGroupsData]);

  const categoryOptions = useMemo(() => {
    return sortBy(Object.values(categoryMaps), category =>
      category?.name?.toLowerCase(),
    ).map(category => ({
      label: getLocaleEntity(category, locale.languageTag).name,
      value: category?.id,
    }));
  }, [categoryMaps, locale.languageTag]);

  const unSelectProducts = useCallback(() => {
    if (selectedProducts.length) {
      setAllProductsMap(prev => {
        const tempPrev = { ...prev };
        selectedProducts.forEach(x => {
          tempPrev[x.id] = {
            ...tempPrev[x.id],
            isSelected: false,
          };
        });
        return tempPrev;
      });
    } else {
      setAllProductsMap(prev => {
        const tempPrev = { ...prev };
        allProductsArray.forEach(x => {
          tempPrev[x.id] = {
            ...tempPrev[x.id],
            isSelected: true,
          };
        });
        return tempPrev;
      });
    }
  }, [selectedProducts, allProductsArray]);

  const clearState = useCallback(() => {
    clearImportIntervalState(false);
  }, [clearImportIntervalState]);

  const isImportCompleted = useMemo(() => {
    return (
      [IMPORT_ENTITY_STATUS.COMPLETED, IMPORT_ENTITY_STATUS.FAILED].includes(
        importProductDetailState?.status as IMPORT_ENTITY_STATUS,
      ) || false
    );
  }, [importProductDetailState?.status]);

  useEffect(() => {
    if (isFocused) {
      getProductTypes();
      getAllPricingGroups();
      getPrinterProfiles();
      getImportProductDetails();
    }
  }, [
    getAllPricingGroups,
    getCategories,
    getPrinterProfiles,
    getProductTypes,
    getAllVariants,
    getAllProducts,
    getImportProductDetails,
    isFocused,
  ]);

  useEffect(() => {
    if (!optionsGroupsError && !optionsGroupsLoading && optionsGroups) {
      setOptionsGroupsData(optionsGroups);
    }
  }, [optionsGroupsError, optionsGroups, optionsGroupsLoading]);

  useEffect(() => {
    isFocused && getAllModifierGroups();
  }, [getAllModifierGroups, isFocused]);

  const getProductsList = useCallback(() => {
    getAllVariants();
    getAllProducts();
    getCategories();
  }, [getAllProducts, getAllVariants, getCategories]);

  useEffect(() => {
    getProductsList();
  }, [getProductsList]);

  useEffect(() => {
    if (isImportCompleted) {
      getProductsList();
    }
    //Cleared import message when we redirect to another page
    return () => {
      if (isImportCompleted) {
        clearState();
      }
    };
  }, [getProductsList, isImportCompleted, clearState]);

  useEffect(() => {
    if (error) {
      showNotification({
        error: true,
        message: error,
      });
    }
  }, [error, showNotification]);

  const generateProductItem = useCallback(
    (prod: Product) => {
      const { taxId, price, priceId, currency } =
        getDefaultInfoForProduct(prod);
      return {
        id: prod.id,
        isSellable: prod.isSellable,
        isVariant: false,
        isVariantProduct: false,
        name: prod.name,
        price: price.toString() || '',
        tax: taxId,
        taxName: taxes?.[taxId]?.name || '',
        productTypeName: prod?.productType?.name,
        productTypeId: prod?.productType?.id,
        storeIds: (prod?.stores || [])?.map(x => x.id),
        defaultPriceId: priceId,
        modifierGroups: prod?.modifierGroups || [],
        isCombo: prod?.isCombo || false,
        currency,
        category: prod?.category?.id,
        printerProfiles: prod?.printerProfiles
          ? prod?.printerProfiles.map(item => item.id)
          : [],
        barcode: prod?.barcode,
      } as ProductListItem;
    },
    [taxes],
  );

  useEffect(() => {
    // load product data on load
    const variantsList = Object.values(variants);
    const productList = Object.values(products);
    const productsDataInPageMap = {} as {
      [key: string]: ProductListItem;
    };
    productList.forEach(prod => {
      productsDataInPageMap[prod.id] = generateProductItem(prod);
    });
    variantsList.forEach(variant => {
      productsDataInPageMap[variant.id] = {
        id: variant.id,
        isSellable: false,
        isVariant: true,
        isVariantProduct: false,
        name: variant.name,
        price: '',
        productTypeName: variant?.productType?.name || '',
        productTypeId: variant?.productType?.id,
        tax: '',
        taxName: '',
        isSelected: false,
        storeIds: (variant?.stores || [])?.map(x => x.id),
        dietary: variant.dietaryTags?.map(t => t.id) ?? [],
        allergens: variant.allergens ?? [],
        category: variant?.category?.id,
        productIds: variant.products.map(x => x.id),
        printerProfiles: variant?.printerProfiles
          ? variant?.printerProfiles.map(item => item.id)
          : [],
        barcode: variant?.barcode,
      };
      variant.products.forEach(variantProduct => {
        productsDataInPageMap[variantProduct.id] = {
          ...generateProductItem(variantProduct),
          isVariantProduct: true,
          variantId: variant.id,
          storeIds: (variant?.stores || [])?.map(x => x.id),
        };
      });
    });

    setAllProductsMap(productsDataInPageMap);
  }, [variants, products, currencySymbol, taxes, generateProductItem]);

  const deleteProductItem = useCallback(
    ProductListItemId => {
      const item = allProductsMap[ProductListItemId];
      const selectedProds: string[] = [];
      const selectedVars: string[] = [];
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      let updateVariantProductCallback: any = undefined;
      if (item?.isVariant) {
        item.productIds?.forEach(eachProd => {
          allProductsMap?.[eachProd] &&
            selectedProds.push(allProductsMap[eachProd].id);
        });
        selectedVars.push(item.id);
      } else {
        allProductsMap?.[item.id] &&
          selectedProds.push(allProductsMap[item.id].id);
        if (item.isVariantProduct) {
          updateVariantProductCallback = () =>
            deleteVariantProductInCache(item.id, item.variantId as string);
        }
      }

      selectedProds.length &&
        deleteProducts(selectedProds, updateVariantProductCallback);
      selectedVars.length && deleteVariants(selectedVars);
      closeModal();
    },
    [
      allProductsMap,
      closeModal,
      deleteProducts,
      deleteVariants,
      deleteVariantProductInCache,
    ],
  );

  const navigateToImportTab = useCallback(() => {
    navigation.navigate('ImportProducts');
  }, [navigation]);

  const onDeleteProduct = useCallback(
    (itemId: string, itemName: string): void => {
      showModal(
        <ConfirmationModal
          title={translate('backOfficeProducts.deletePopUpPromptHeader')}
          message={translate('backOfficeProducts.deletePopUpPromptBody', {
            productName: itemName,
          })}
          onConfirm={(): void => deleteProductItem(itemId)}
        />,
      );
    },
    [showModal, translate, deleteProductItem],
  );

  const handleCreateProductRes = useCallback(
    async (
      response: Product | undefined,
      editMore: boolean,
      productForm: CreateProductForm,
    ) => {
      if (response) {
        setShowCreateModal(false);
        setCreatingProduct({});
        // redirect to variant settings for variant
        editMore &&
          navigation.navigate('ProductSettings', {
            productId: response.id,
          });
      } else {
        setCreatingProduct(productForm);
      }
    },
    [navigation],
  );

  const handleCreateVariantRes = useCallback(
    (
      response: Variant | undefined,
      editMore: boolean,
      productForm: CreateProductForm,
    ) => {
      if (response) {
        setShowCreateModal(false);
        setCreatingProduct({});
        // redirect to variant settings for variant
        editMore &&
          navigation.navigate('ProductSettings', {
            productId: response.id,
            isVariant: true,
            screen: 'Variations',
          });
      } else {
        setCreatingProduct(productForm);
      }
    },
    [navigation],
  );

  const onCreateVariant = useCallback(
    async (productInput: CreateProductForm, editMore: boolean) => {
      const response = await createVariant({
        name: productInput.name,
        stores: stores.map(x => x.value),
        salesChannels: salesChannels.map(x => x.value),
        category: productInput.category,
      } as CreateVariantProductInput);
      handleCreateVariantRes(response, editMore, productInput);
    },
    [createVariant, handleCreateVariantRes, salesChannels, stores],
  );

  const onConfirmCreate = useCallback(
    async (productInput: CreateProductForm, editMore: boolean) => {
      if (!defaultPricingGroup.id) {
        showNotification({
          error: true,
          message: translate('productSettings.defaultPricingGroupMissing'),
        });
      }
      if (productInput.isVariant) {
        onCreateVariant(productInput, editMore);
      } else {
        const defaultPricingGroupId = defaultPricingGroup.id;
        const response = await createProduct({
          name: productInput.name,
          isSellable: true,
          variablePricing: false,
          stores: stores.map(x => x.value),
          salesChannels: salesChannels.map(x => x.value),
          category: productInput?.category,
          ...(defaultPricingGroupId && {
            productPricing: {
              pricingGroupId: defaultPricingGroup.id,
              sellingPrice: productInput.sellingPrice,
              taxInclusive: productInput.taxInclusive,
              sellingTax: productInput.sellingTax,
            },
          }),
        } as CreateProductInput);
        updateOnboardingStatus(
          OnboardingArea.CATALOGUES,
          OnboardingSection.ADD_A_PAGE_AND_PRODUCT,
          OnboardingCheckList.PRODUCTS,
          OnboardingAction.CREATE,
        );
        handleCreateProductRes(response, editMore, productInput);
      }
    },
    [
      createProduct,
      defaultPricingGroup?.id,
      handleCreateProductRes,
      onCreateVariant,
      salesChannels,
      showNotification,
      stores,
      translate,
      updateOnboardingStatus,
    ],
  );

  const onSelectToggle = useCallback(productId => {
    setAllProductsMap(prev => {
      const tempData = { ...prev };
      const prodOrVariant = prev[productId];
      tempData[productId].isSelected = !prodOrVariant.isSelected;
      if (prodOrVariant.isVariant) {
        // select variant products
        tempData[productId].productIds?.forEach(x => {
          if (tempData?.[x]) {
            tempData[x].isSelected = prodOrVariant.isSelected;
          }
        });
      }
      return tempData;
    });
  }, []);

  const onConfirmCopyProduct = useCallback(
    async (productInput: CreateProductForm, editMore: boolean) => {
      const { name, category = '', sellingPrice, sellingTax } = productInput;
      if (!productInput.isVariant) {
        const response = await copyProduct({
          name,
          category,
          productType: productInput?.productType ?? '',
          sellingPrice,
          originalProductId: productInput.sourceProductId as string,
          sellingTax,
          defaultPricingGroupId: defaultPricingGroup?.id,
        });
        handleCreateProductRes(response, editMore, productInput);
      } else {
        const response = await copyVariant({
          name,
          productType: productInput?.productType ?? '',
          category,
          originalVariantId: productInput?.sourceProductId as string,
        });
        handleCreateVariantRes(response, editMore, productInput);
      }
    },
    [
      copyProduct,
      copyVariant,
      defaultPricingGroup?.id,
      handleCreateProductRes,
      handleCreateVariantRes,
    ],
  );

  const onCreateOrCopyProduct = useCallback(
    (productInput: CreateProductForm, editMore: boolean) => {
      if (productInput.isCopyMode) {
        onConfirmCopyProduct(productInput, editMore);
        return;
      }
      onConfirmCreate(productInput, editMore);
    },
    [onConfirmCopyProduct, onConfirmCreate],
  );

  const onTriggerCopyProduct = useCallback(
    (originalProduct: ProductListItem) => {
      setCreatingProduct({
        sellingPrice: {
          amount: Number(originalProduct.price),
          currency: originalProduct.currency || Currency.AUD,
        },
        isVariant: originalProduct.isVariant,
        sellingTax: originalProduct.tax,
        productType: originalProduct.productTypeId,
        category: originalProduct?.category,
        isCopyMode: true,
        sourceProductId: originalProduct.id,
      });
      setShowCreateModal(true);
    },
    [],
  );

  const saveProducts = useCallback(() => {
    const updateProductPricingsInput: ProductPricingInput[] = [];
    const productsChanged = changedProducts.filter(x => x && !x.isVariant);
    const variantsChanged = changedProducts.filter(x => x && x.isVariant);
    const productsArray = Object.values(allProductsMap);

    productsChanged.forEach(eachChangedProd => {
      // update product pricing input
      updateProductPricingsInput.push({
        id: eachChangedProd.defaultPriceId,
        sellingPrice: {
          amount: +eachChangedProd.price,
          currency: currency as Currency,
        } as Money,
        sellingTax: eachChangedProd.tax,
        pricingGroupId: defaultPricingGroup?.id,
        product: eachChangedProd.id,
      } as unknown as ProductPricingInput);
    });
    variantsChanged.forEach(eachChangedVariant => {
      if (
        eachChangedVariant.productIds &&
        eachChangedVariant.productIds.length > 0
      ) {
        const variantProductIds = eachChangedVariant.productIds;
        const variantProducts = productsArray.filter(x =>
          variantProductIds.includes(x.id),
        );
        variantProducts.forEach(eachChangedProd => {
          // update product pricing input
          updateProductPricingsInput.push({
            id: eachChangedProd.defaultPriceId,
            sellingPrice: {
              amount: +eachChangedProd.price,
              currency: currency as Currency,
            } as Money,
            sellingTax: eachChangedProd.tax,
            pricingGroupId: defaultPricingGroup?.id,
            product: eachChangedProd.id,
          } as unknown as ProductPricingInput);
        });
      }
    });
    updateProductPricingsInput.length &&
      updateProductPricings(updateProductPricingsInput);
  }, [
    allProductsMap,
    changedProducts,
    currency,
    defaultPricingGroup,
    updateProductPricings,
  ]);

  const onChangeOfItem = useCallback((id, prop, value, variantId) => {
    setAllProductsMap(prevMap => {
      const prevMapWithProductId = {
        ...prevMap,
        [id]: { ...prevMap[id], [prop]: value, isChanged: true },
      };
      if (variantId) {
        return {
          ...prevMapWithProductId,
          [variantId]: {
            ...prevMap[variantId],
            [prop]: value,
            isChanged: true,
          },
        };
      }
      return prevMapWithProductId;
    });
  }, []);

  const onCreateNewProduct = useCallback(() => {
    setShowCreateModal(true);
  }, []);

  const onCloseCreateModal = useCallback(() => {
    setShowCreateModal(false);
    setCreatingProduct({});
  }, []);

  const deleteSelectedItems = useCallback(() => {
    const selectedProds = selectedProducts.filter(x => x && !x.isVariant);
    const selectedVars = selectedProducts.filter(x => x && x.isVariant);
    // if variant is deleted then its related variant products must also auto delete
    selectedVars.forEach(eachVar => {
      eachVar.productIds?.forEach(eachProd => {
        allProductsMap?.[eachProd] &&
          selectedProds.push(allProductsMap[eachProd]);
      });
    });
    // making delete product ids unique and deleting
    selectedProds.length &&
      deleteProducts([...new Set(selectedProds.map(x => x && x.id))]);
    selectedVars.length && deleteVariants(selectedVars.map(x => x && x.id));
    closeModal();
  }, [
    selectedProducts,
    deleteProducts,
    deleteVariants,
    closeModal,
    allProductsMap,
  ]);

  useEffect(() => {
    // auto set category when create a new product with filter category
    setCreatingProduct(productData => ({
      ...productData,
      category,
    }));
  }, [category]);

  useEffect(() => {
    setCurrentPage(1);
  }, [filter]);

  const getImportEntityActionBar = useCallback(() => {
    let actionMessage: {
      message: string;
      type: 'positive' | 'neutral' | 'focus' | 'negative';
    };
    if (isImportEntityRunning) {
      actionMessage = {
        type: 'neutral',
        message: translate('importEntity.inProgressMenuMessage'),
      };
    } else if (
      importProductDetailState?.status === IMPORT_ENTITY_STATUS.COMPLETED
    ) {
      actionMessage = {
        type: importProductDetailState.validationMessage?.length
          ? 'negative'
          : 'positive',
        message: importProductDetailState.validationMessage?.length
          ? translate('importEntity.failedMenuMessage', {
              failedProductCount: importProductDetailState.failedCount,
            })
          : translate('importEntity.successMenuMessage', {
              successProductCount: importProductDetailState.successCount,
              updateProductCount: importProductDetailState?.updateCount,
            }),
      };
    } else {
      actionMessage = {
        type: 'negative',
        message: translate('importEntity.invalidMessage'),
      };
    }
    return actionMessage;
  }, [importProductDetailState, isImportEntityRunning, translate]);

  return (
    <ScreenLayout
      loading={loading && !showCreateModal}
      title={translate('navigation.products', {
        appName: translate('appName'),
      })}
      onSave={saveProducts}
      hideFooter={!changedProducts.length}
    >
      <Section layoutWidth="full">
        {importProductDetailState ? (
          <MessageAction
            message={getImportEntityActionBar()?.message}
            type={getImportEntityActionBar()?.type}
            containerStyle={styles.messageActionContainer}
            onPress={navigateToImportTab}
            onPressClose={clearState}
          />
        ) : (
          <></>
        )}
        <View style={styles.filtersContainer}>
          <ProductHeaderFilters
            categories={categoryOptions}
            storeOptions={stores}
            filter={filter}
            onChangeFilter={setFilter}
            onPressImport={navigateToImportTab}
            isImportProductFeatureEnabled={isImportProductFeatureEnabled}
            printerProfileOptions={printerProfileOptions}
            optionsGroupOptions={optionsGroupOptions}
          />
          {(!selectedProducts.length && (
            <CreateButton onPress={onCreateNewProduct} />
          )) || (
            <ProductBulkOptions
              dataMaps={allProductsMap}
              selectedProductListItems={selectedProducts}
              defaultPricingGroupId={defaultPricingGroup?.id}
              taxOptions={taxesOptions}
              productTypeOptions={productTypeOptions}
              printerProfileOptions={printerProfileOptions}
              updateProducts={updateProducts}
              updateVariants={updateVariants}
              storeOptions={stores}
              deleteSelectedItems={deleteSelectedItems}
              categoryOptions={categoryOptions}
              bulkUpdateProductsCategory={bulkUpdateProductsCategory}
              bulkUpdateVariantsCategory={bulkUpdateVariantsCategory}
              updateProductPricings={updateProductPricings}
            />
          )}
        </View>
        {/* Table */}
        {allProductsArray && allProductsArray.length > 0 ? (
          <ProductListView
            data={allProductsArray}
            dataMapper={allProductsMap}
            onCopy={onTriggerCopyProduct}
            onDelete={onDeleteProduct}
            onChangeItem={onChangeOfItem}
            onSelectItem={onSelectToggle}
            onSelectAll={unSelectProducts}
            selectedProducts={selectedProducts}
            page={currentPage}
            onPageChange={setCurrentPage}
            filter={filter}
          />
        ) : (
          <View style={styles.emptyListView}>
            <Text style={styles.emptyListText}>
              {translate('backOfficeProducts.emptyTable')}
            </Text>
          </View>
        )}
      </Section>
      <ModalWrapper isVisible={showCreateModal}>
        <CreateProductModal
          onCreate={onCreateOrCopyProduct}
          taxesOptions={taxesOptions}
          categoryOptions={categoryOptions}
          createProduct={creatingProduct}
          closeModal={onCloseCreateModal}
          loading={prodLoading || varLoading}
        />
      </ModalWrapper>
    </ScreenLayout>
  );
};
