import { StyleSheet } from 'react-native';
import theme from '../../../../../common/default-theme';

const styles = StyleSheet.create({
  // Table
  tableContainer: {
    paddingBottom: 40,
  },
  // Table Header
  headerContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    borderRadius: theme.radius.s,
    backgroundColor: theme.colors.grey1,
  },
  headerText: {
    fontSize: 14,
    lineHeight: 20,
    textAlign: 'center',
    color: theme.colors.grey6,
  },
  colTextName: {
    width: 272,
    paddingLeft: 44,
    marginRight: 20,
    textAlign: 'left',
  },
  colTextSelling: {
    width: 60,
    marginRight: 20,
  },
  colTextPrice: {
    width: 120,
    marginRight: 20,
  },
  checkbox: {
    width: 38,
    height: 38,
    alignItems: 'center',
    justifyContent: 'center',
  },
  // Table Row
  rowContainer: {
    paddingVertical: 8,
    flexDirection: 'row',
    borderBottomWidth: 1,
    alignItems: 'center',
    borderBottomColor: theme.colors.grey3,
  },
  nameContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    marginRight: 20,
  },
  btn: {
    width: 38,
    height: 38,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: theme.radius.s,
    marginLeft: 10,
  },
  btnExpand: {
    width: 26,
    height: 26,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: theme.radius.s,
    backgroundColor: theme.colors.grey1,
    marginRight: 6,
  },
  sticker: {
    right: 12,
    paddingVertical: 4,
    paddingHorizontal: 8,
    borderRadius: 38,
    position: 'absolute',
    color: theme.colors.white,
    backgroundColor: theme.colors.deepPurple,
    fontSize: 12,
  },
});

export default styles;
