import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { Helmet } from 'react-helmet';
import { Styles } from '../styles/Payment.styles';
import { View, ScrollView } from 'react-native';
import { useTranslation } from '@oolio-group/localization';
import {
  DateRangeFilter,
  FilterValue,
  Widget,
  WidgetChartType,
  PaymentSummarySegment,
  SalesChannelCode,
  DateRangeGranularity,
  ColumnType,
  DefaultPaymentTypes,
  ReportSegment,
  PaymentCardTypes,
  PAYMENT_CARD_TYPES_MAPPING,
  Filters,
} from '@oolio-group/domain';
import { Table, TableRef } from '../UIComponents/Table';
import { Filters as ReportFilter } from '../UIComponents/Filters';
import {
  IMap,
  ExportType,
  ReportTableColumn,
  HelperText,
  DropDownFilter,
  ReportType,
} from '../types';
import {
  convertDateByFormat,
  GRANULARITY_AND_REPORT_TYPE_MAPPER,
  GRANULARITY_FORMATS,
  GROUP_BY_AND_REPORT_TYPE_MAPPER,
  transformFieldsToTableColumn,
} from '../reportsHelper';
import { CubejsApi, ResultSet } from '@cubejs-client/core';
import PaymentHeader from './PaymentHeader';
import { useReporting } from '../../../../../src/hooks/app/useReporting';
import groupBy from 'lodash/groupBy';
import toString from 'lodash/toString';
import ModalWrapper from '../../../../hooks/ModalWrapper';
import PayoutScheduleModal from './PayoutScheduleModal';
import keyBy from 'lodash/keyBy';
import mapValues from 'lodash/mapValues';
import orderBy from 'lodash/orderBy';
import { writeToString } from '@fast-csv/format';
import { buildURI, downloadFile } from '../../../../utils/csv';
import { PickerOption } from '../../../../components/Shared/Select/Picker';

interface PaymentItem {
  [key: string]: string | boolean | PaymentItem[] | undefined;
  sumItems?: PaymentItem[];
}

interface ReportProps {
  options: { loading: boolean };
  filterOptions: DropDownFilter[];
  selectedFilters: FilterValue;
  widgets: Widget[];
  dateRangeFilter: DateRangeFilter;
  onUpdateFilters: (filter: string, value: string[]) => void;
  resetFilters: () => void;
  onPressUpdateReport: () => void;
  updateDateRangeFilters: (value: DateRangeFilter) => void;
  cubejsApi: CubejsApi;
  updateCount: number;
  saleChannelOptions?: PickerOption[];
  allFilters: Filters;
}

const TABLE_GRANULARITY_FORMATS: IMap<string> = {
  ...GRANULARITY_FORMATS,
  day: 'eeee, dd MMM',
};

const generateCardTypeFeeKey = (salesChannel: string, cardType: string) =>
  `${salesChannel}_${cardType}`;

const PaymentReport: React.FC<ReportProps> = ({
  options: { loading },
  selectedFilters,
  filterOptions,
  widgets,
  dateRangeFilter,
  onUpdateFilters,
  onPressUpdateReport,
  resetFilters,
  updateDateRangeFilters,
  cubejsApi,
  updateCount,
  saleChannelOptions,
  allFilters,
}) => {
  const { translate } = useTranslation();
  const styles = Styles();
  const [showFilters, setShowFilters] = useState<boolean>(false);
  const [showPayoutScheduleModal, setShowPayoutScheduleModal] = useState(false);
  const [groupedAndFormatData, setGroupedAndFormatData] = useState<
    PaymentItem[]
  >([]);
  const groupByPeriodsRef = useRef<Function>();
  const {
    loading: reportLoading,
    error,
    widgetData = {},
    getWidgetData,
    getPaymentSettings,
    paymentSettings,
  } = useReporting(cubejsApi);

  const reportType =
    GRANULARITY_AND_REPORT_TYPE_MAPPER[dateRangeFilter.granularity || ''];

  const toggleFilters = useCallback(() => {
    setShowFilters(value => !value);
  }, []);

  const tableWidgetRef = useRef<TableRef>({} as TableRef);

  const tableWidget = useMemo(
    () =>
      widgets.find(widget => widget.chartType === WidgetChartType.TABLE) ||
      ({} as Widget),
    [widgets],
  );

  const [selectedColumns, setSelectedColumns] = useState<ReportTableColumn[]>(
    [],
  );

  const tableColumns: ReportTableColumn[] = useMemo(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const columns: any[] = [];
    if (tableWidget.query?.dimensions)
      columns.push(
        ...transformFieldsToTableColumn(tableWidget.query?.dimensions),
      );
    if (tableWidget.query?.measures)
      columns.push(
        ...transformFieldsToTableColumn(tableWidget.query?.measures),
      );
    return columns.map(item => {
      if (item.dataKey === PaymentSummarySegment.PAYMENT_METHOD_NAME) {
        return {
          ...item,
          title: reportType
            ? 'backOfficePayments.DateRange'
            : 'backOfficePayments.PaymentMethods',
        };
      }
      return item;
    });
  }, [reportType, tableWidget.query?.dimensions, tableWidget.query?.measures]);

  useEffect(() => {
    setSelectedColumns(tableColumns.filter(column => column.showByDefault));
  }, [tableColumns]);

  const updateColumns = useCallback(
    (columnKeys: string[]) => {
      setSelectedColumns(
        tableColumns.filter(col => columnKeys.includes(col.title)),
      );
    },
    [tableColumns],
  );

  const onPressPayoutInfo = useCallback(() => {
    setShowPayoutScheduleModal(true);
  }, []);

  const onClosePayoutModal = useCallback(() => {
    setShowPayoutScheduleModal(false);
  }, []);

  const sumAllKeys = (data: PaymentItem[]) => {
    const sumData = data.reduce((sumItem, item) => {
      Object.keys(item).forEach(key => {
        // Note: exclude empty string case, cause Number('') => 0, which is not correct
        const parsedValue =
          item[key] === '' ? NaN : Number(item[key] as string);
        if (!isNaN(parsedValue)) {
          sumItem[key] = ((sumItem[key] as number) || 0) + parsedValue;
        } else {
          sumItem[key] = item[key] as string;
        }
      });
      return sumItem;
    }, {} as Record<string, number | string>);
    return mapValues(sumData, value => toString(value));
  };

  const groupAndSumAllKeys = useCallback((data: PaymentItem[]) => {
    const groupedData = groupBy(
      data,
      paymentItem =>
        // group by either
        paymentItem[ReportSegment.GROUP_BY_DAY] || // daily
        paymentItem[ReportSegment.GROUP_BY_WEEK] || // weekly
        paymentItem[ReportSegment.GROUP_BY_MONTH], // monthly
      // or ungroup if data is not granularized
    );

    // sum data for each group
    return Object.values(groupedData).map(paymentData =>
      sumAllKeys(paymentData),
    );
  }, []);

  const paymentData = useMemo(() => {
    const rawData = ((
      widgetData[tableWidget.name] as ResultSet
    )?.tablePivot?.() || []) as Record<string, string>[];

    // classify card type to different groups (visa, mc, others,...) using mapping table
    const formattedData = rawData.map(paymentRecord => {
      const cardType = paymentRecord[PaymentSummarySegment.CARD_TYPE];
      if (!cardType) return paymentRecord;
      const validCardType =
        PAYMENT_CARD_TYPES_MAPPING[cardType] || PaymentCardTypes.OTHER;
      return {
        ...paymentRecord,
        [PaymentSummarySegment.CARD_TYPE]: validCardType,
      } as Record<string, string>;
    });

    // group per method and card type
    const groupedData = groupBy(
      formattedData,
      payment =>
        `${payment[PaymentSummarySegment.PAYMENT_METHOD_NAME]}
        _
        ${payment[PaymentSummarySegment.CARD_TYPE]}`,
    );

    // for each group, sum the data
    return Object.values(groupedData)
      .map(group => {
        // do the sum for any group that has more than 1 record
        return groupAndSumAllKeys(group);
      })
      .flat();
  }, [widgetData, tableWidget.name, groupAndSumAllKeys]);

  const feeRateMaps = useMemo(() => {
    return keyBy(paymentSettings?.cardFees, feeSettings =>
      generateCardTypeFeeKey(
        feeSettings.salesChannel?.code,
        feeSettings.cardType,
      ),
    );
  }, [paymentSettings?.cardFees]);

  const groupDataByCardType = useCallback(
    (paymentData: PaymentItem[]) => {
      const groupByPaymentMethodItems = groupBy(
        paymentData,
        paymentItem => paymentItem[PaymentSummarySegment.PAYMENT_METHOD_NAME],
      );

      const payments = Object.keys(groupByPaymentMethodItems).reduce(
        (groupData, paymentMethod: string) => {
          const sumItem = sumAllKeys(groupByPaymentMethodItems[paymentMethod]);
          sumItem[PaymentSummarySegment.PAYMENT_METHOD_NAME] = paymentMethod;
          groupData.push(sumItem);

          if (
            [DefaultPaymentTypes.CARD, DefaultPaymentTypes.ONLINE].includes(
              paymentMethod as DefaultPaymentTypes,
            )
          ) {
            const subItems = groupByPaymentMethodItems[paymentMethod].map(
              (item, index) => {
                const cardTypeCode = item[
                  PaymentSummarySegment.CARD_TYPE
                ] as string;

                const saleChannelCode =
                  paymentMethod === DefaultPaymentTypes.CARD
                    ? SalesChannelCode.IN_STORE
                    : SalesChannelCode.ONLINE;

                const cardTypeRate = (
                  feeRateMaps[
                    generateCardTypeFeeKey(saleChannelCode, cardTypeCode)
                  ] ||
                  feeRateMaps[generateCardTypeFeeKey(saleChannelCode, 'other')]
                )?.feeRate;

                return {
                  ...item,
                  isSubItem: true,
                  [PaymentSummarySegment.PAYMENT_METHOD_NAME]:
                    translate(
                      `backOfficePayments.cardType.${
                        cardTypeCode || 'unknown'
                      }`,
                    ) + (cardTypeRate ? ` (${cardTypeRate}%)` : ''),
                  id: `${cardTypeCode}-${index}`,
                };
              },
            );
            groupData.push(...subItems);
          }
          return groupData;
        },
        [] as PaymentItem[],
      );

      return payments;
    },
    [feeRateMaps, translate],
  );

  const groupByPeriods = useCallback(
    (paymentData: PaymentItem[]) => {
      if (!reportType) {
        return groupDataByCardType(paymentData).concat({
          ...sumAllKeys(paymentData),
          [PaymentSummarySegment.PAYMENT_METHOD_NAME]: translate(
            'backOfficePayments.AllPayments',
          ),
        });
      }

      const groupedData = groupBy(
        paymentData,
        paymentItem =>
          paymentItem[
            GROUP_BY_AND_REPORT_TYPE_MAPPER[reportType as ReportType] as string
          ],
      );

      const allPeriods = Object.keys(groupedData).filter(item => item);
      const sortedPeriod = orderBy(
        allPeriods,
        period => new Date(period),
        'asc',
      );
      const granularity = dateRangeFilter.granularity as DateRangeGranularity;
      return sortedPeriod.reduce((data, period: string) => {
        const sumItem = {
          ...sumAllKeys(groupedData[period as never] as never),
          [PaymentSummarySegment.PAYMENT_METHOD_NAME]: convertDateByFormat(
            period,
            granularity,
            TABLE_GRANULARITY_FORMATS[granularity],
            tableWidget.query?.timezone,
          ),
          subItems: groupDataByCardType(groupedData[period]),
        };
        data.push(sumItem);
        return data;
      }, [] as PaymentItem[]);
    },
    [
      dateRangeFilter.granularity,
      groupDataByCardType,
      reportType,
      tableWidget.query?.timezone,
      translate,
    ],
  );

  const exportReport = useCallback(
    async (type = ExportType.CSV) => {
      if (type === ExportType.CSV) {
        const fileName = `PaymentSummaryReport-${Date.now()}.csv`;
        const formatRowText = tableWidgetRef.current.getRowText;
        const formattedData = paymentData.map(rowData => {
          const cardTypeName = rowData[
            PaymentSummarySegment.CARD_TYPE
          ] as string;
          return {
            ...rowData,
            [PaymentSummarySegment.CARD_TYPE]: cardTypeName
              ? translate(`backOfficePayments.cardType.${cardTypeName}`)
              : '',
          };
        }) as PaymentItem[];

        const sortedDataByPaymentMethod = orderBy(formattedData, [
          paymentItem =>
            paymentItem[GROUP_BY_AND_REPORT_TYPE_MAPPER[reportType]],
          paymentItem => paymentItem[PaymentSummarySegment.PAYMENT_METHOD_NAME],
        ]);

        const csvColumns = selectedColumns.map(column => {
          if (column.dataKey === PaymentSummarySegment.PAYMENT_METHOD_NAME) {
            return {
              ...column,
              title: 'backOfficePayments.PaymentMethods',
            };
          }
          return column;
        });

        csvColumns.splice(1, 0, {
          dataKey: PaymentSummarySegment.CARD_TYPE,
          title: 'backOfficePayments.CardType',
          key: PaymentSummarySegment.CARD_TYPE,
          flex: 1,
        });

        if (reportType) {
          csvColumns.unshift({
            dataKey: GROUP_BY_AND_REPORT_TYPE_MAPPER[reportType],
            title: 'backOfficePayments.DateRange',
            key: GROUP_BY_AND_REPORT_TYPE_MAPPER[reportType],
            type: ColumnType.DATE,
            flex: 1,
          });
        }

        const rows = sortedDataByPaymentMethod.map(rowData => {
          return csvColumns.map(
            col =>
              `${formatRowText(
                rowData,
                col.dataKey || col.key,
                col.type as ColumnType,
              )}`,
          );
        });

        const csvData = await writeToString(rows, {
          delimiter: ',',
          rowDelimiter: '\n',
          quote: '"',
          headers: csvColumns.map(col => `${translate(col.title)}`),
        });
        const uri = buildURI(csvData);
        downloadFile(uri, fileName);
      }
    },
    [paymentData, reportType, selectedColumns, translate],
  );

  useEffect(() => {
    groupByPeriodsRef.current = groupByPeriods;
  }, [groupByPeriods]);

  useEffect(() => {
    tableWidget?.query &&
      getWidgetData(tableWidget, HelperText.PAYMENT_SUMMARY);
  }, [getWidgetData, tableWidget]);

  useEffect(() => {
    getPaymentSettings();
  }, [getPaymentSettings]);

  useEffect(() => {
    if (!groupByPeriodsRef.current) return;
    setGroupedAndFormatData(groupByPeriodsRef.current(paymentData));
  }, [paymentData]);

  return (
    <>
      <Helmet>
        <title>
          {translate('navigation.generalSettingsPageTitle', {
            appName: translate('appName'),
          })}
        </title>
      </Helmet>

      <ScrollView scrollEnabled={!showFilters} style={styles.pageStyle}>
        {showFilters ? (
          <View style={styles.filtersStyle}>
            <ReportFilter
              toggleFilters={toggleFilters}
              filterOptions={filterOptions}
              filters={selectedFilters}
              updateFilters={onUpdateFilters}
              resetFilters={resetFilters}
            ></ReportFilter>
          </View>
        ) : null}
        <View style={styles.mainSectionStyle}>
          <View style={styles.headersStyle}>
            <PaymentHeader
              toggleFilters={toggleFilters}
              onPressUpdateReport={onPressUpdateReport}
              exportReport={exportReport}
              dateRangeFilter={dateRangeFilter}
              updateDateRangeFilters={updateDateRangeFilters}
              columns={{ all: tableColumns, updateColumns, selectedColumns }}
              onPressPayoutInfo={onPressPayoutInfo}
              reportType={reportType || ReportType.DAILY}
              selectedFilters={selectedFilters}
              saleChannelOptions={saleChannelOptions}
              onUpdateFilters={onUpdateFilters}
              allFilters={allFilters}
            />
          </View>
          <View style={styles.tableStyle}>
            <Table
              data={groupedAndFormatData}
              widget={tableWidget}
              columns={selectedColumns}
              helper={HelperText.PAYMENT_SUMMARY}
              granularityFormats={TABLE_GRANULARITY_FORMATS}
              ref={tableWidgetRef}
              cubejsApi={cubejsApi}
              updateCount={updateCount}
              error={error}
              loading={loading || reportLoading}
            />
          </View>
        </View>
      </ScrollView>
      <ModalWrapper isVisible={showPayoutScheduleModal}>
        <PayoutScheduleModal
          onDismiss={onClosePayoutModal}
          paymentSettings={paymentSettings}
        />
      </ModalWrapper>
    </>
  );
};

export default PaymentReport;
