import { createStackNavigator } from '@react-navigation/stack';
import React from 'react';
import UsersTab from './ManageUsers/UsersTab';
import UserSettingsTab from './ManageUsers/UsersSettings/UserSettingsTab';
import { ManageRoles as JobRoles } from './ManageRoles/ManageRoles';
import ManageRolesTab from './ManageRoles/ManageRolesTab';
import { CustomizeUserRole } from './ManageUsers/UsersSettings/Permissions/UserRole/UserRole';

const Stack = createStackNavigator();

const UsersStack: React.FC = () => (
  <Stack.Navigator
    headerMode="none"
    initialRouteName="ManageUsers"
    screenOptions={{
      animationEnabled: true,
      cardOverlayEnabled: false,
      cardShadowEnabled: false,
    }}
  >
    <Stack.Screen component={UsersTab} name="ManageUsers" />
    <Stack.Screen component={UserSettingsTab} name="UserDetails" />
    <Stack.Screen component={JobRoles} name="JobRoles" />
    <Stack.Screen component={ManageRolesTab} name="JobRoleSettings" />
    <Stack.Screen component={CustomizeUserRole} name="CustomizeUserRole" />
  </Stack.Navigator>
);

export default UsersStack;
