import React, {
  useEffect,
  useCallback,
  useState,
  useMemo,
  useContext,
} from 'react';
import { View, Text } from 'react-native';
import {
  ProductPricing as ProductPricingDefault,
  DEFAULT_PRICING_GROUP,
  ProductPricingInput,
  DeleteProductPricingInput,
} from '@oolio-group/domain';
import { useModal } from '@oolio-group/rn-use-modal';
import { useNavigation, useRoute } from '@react-navigation/native';
import { useNotification } from '../../../../../../hooks/Notification';
import { useTranslation, useCurrency } from '@oolio-group/localization';
import { useProductPricings } from '../../../../../../hooks/app/productPricings/useProductPricings';
import { usePricingGroups } from '../../../../../../hooks/app/usePricingGroups';
import { find, keyBy, differenceBy } from 'lodash';
import { Operation } from '../../../../../../types/Operation';
import { ProductsRow } from './ProductsRow';
import { ProductsFilters } from './ProductsFilters';
import { PriceListContext } from '../PriceListSettingsNavigator';
import { stripProperties } from '../../../../../../utils/stripObjectProps';
import { SetPriceModal } from '../../../../../../components/Modals/ProductPrices/SetPrices';
import ConfirmationModal from '../../../../../../components/Modals/ConfirmationDialog';
import theme from '../../../../../../common/default-theme';
import styles from '../../PriceLists.styles';
import ScreenLayout from '../../../../../../components/Office/ScreenLayout/ScreenLayout';
import Section from '../../../../../../components/Office/Section/Section';
import Message from '../../../../../../components/Office/Message/Message';
import InputToggle from '../../../../../../components/Shared/Inputs/InputToggle';

export interface ProductPricing extends ProductPricingDefault {
  isSelected?: boolean;
  isChanged?: boolean;
  isNew?: boolean;
  tax?: string;
  price?: string;
  productId: string;
}

export const Products: React.FC = () => {
  const priceListContext = useContext(PriceListContext);
  const { translate } = useTranslation();
  const { showNotification } = useNotification();
  const route = useRoute();
  const navigation = useNavigation();
  const { unAppendCurrency } = useCurrency();
  const { showModal, closeModal } = useModal();
  const [productPricingData, setProductPricingData] = useState<
    Record<string, ProductPricing>
  >({});
  const [deletedProductPricing, setDeletedProductPricing] = useState<
    DeleteProductPricingInput[]
  >([]);
  const [standardPrices, setDefaultPricing] = useState<
    Record<string, ProductPricing>
  >({});

  const params =
    priceListContext.params ||
    (route.params as {
      pricingGroupId: string;
      screen?: string;
    });

  const {
    error: errorPP,
    loading: loadingPP,
    operation: PPOperation,
    addBulkProductPricings,
    delete: deleteProductPricings,
    update: updateProductPricings,
    deletedIds,
  } = useProductPricings();

  const {
    pricingGroups,
    defaultPricingGroup,
    error: PGErr,
    loading: PGLoading,
    getPricingGroup,
    getAllPricingGroups,
  } = usePricingGroups();

  const pricingGroupId = params?.pricingGroupId || '';
  const isDefault =
    pricingGroups[pricingGroupId]?.name === DEFAULT_PRICING_GROUP;

  useEffect(() => {
    navigation.setParams(params);
  }, [params, navigation]);

  const pricesDataInDb = useMemo(() => {
    if (pricingGroups && pricingGroupId) {
      return keyBy(
        (pricingGroups?.[pricingGroupId]?.prices || []).map(x => ({
          ...x,
          productId: x.product?.id,
        })),
        'productId',
      );
    }
  }, [pricingGroupId, pricingGroups]);

  useEffect(() => {
    if (pricesDataInDb) {
      setProductPricingData(pricesDataInDb);
    }
  }, [pricesDataInDb, pricingGroupId, pricingGroups]);

  useEffect(() => {
    if (defaultPricingGroup) {
      const defaultPricingData = keyBy(
        (defaultPricingGroup?.prices || []).map(x => ({
          ...x,
          productId: x.product?.id,
        })),
        'productId',
      );
      setDefaultPricing(defaultPricingData);
    }
  }, [defaultPricingGroup]);

  const error = PGErr || errorPP;

  const loading = PGLoading || loadingPP;

  const isProductPricingCreated =
    !errorPP && !loadingPP && PPOperation === Operation.CREATE;
  const isProductPricingUpdated =
    !errorPP && !loadingPP && PPOperation === Operation.UPDATE;

  useEffect(() => {
    if (error) {
      showNotification({
        error: true,
        message: error,
      });
    }
  }, [error, showNotification]);

  useEffect(() => {
    if (!loading && !errorPP && PPOperation === Operation.DELETE) {
      showNotification({
        success: true,
        message: translate('pricings.productPricingsDeletedSuccessfully'),
      });
    }
  }, [PPOperation, loading, errorPP, showNotification, translate, deletedIds]);

  useEffect(() => {
    if (isProductPricingCreated) {
      showNotification({
        success: true,
        message: translate('pricings.productPricingCreatedSuccessfully'),
      });
      getAllPricingGroups();
    }
  }, [
    isProductPricingCreated,
    translate,
    showNotification,
    getAllPricingGroups,
  ]);

  const priceListProducts = useMemo(() => {
    return Object.values(productPricingData).filter(
      x => x.product?.id,
    ) as ProductPricing[];
  }, [productPricingData]);

  const selectedProductPrices = useMemo(() => {
    return priceListProducts.filter(x => x.isSelected);
  }, [priceListProducts]);

  const selectAllStatus = useMemo(() => {
    if (find(priceListProducts, { isSelected: true })) {
      return true;
    }
    return false;
  }, [priceListProducts]);

  const onSetPrices = useCallback(
    (newPrice: number) => {
      const tempObj = {} as Record<string, ProductPricing>;
      selectedProductPrices.forEach(x => {
        tempObj[x.productId] = {
          ...x,
          sellingPrice: {
            ...x.sellingPrice,
            amount: newPrice,
          },
          isChanged: true,
        };
      });
      setProductPricingData(prev => {
        return {
          ...prev,
          ...tempObj,
        };
      });
      showNotification({
        success: true,
        message: translate('pricings.operationSetSuccess', {
          value: '$' + newPrice,
        }),
      });
    },
    [selectedProductPrices, showNotification, translate],
  );

  const setPrices = useCallback(() => {
    if (selectedProductPrices.length > 0) {
      showModal(
        <SetPriceModal
          onSubmit={newPrice => {
            onSetPrices(newPrice);
            closeModal();
          }}
          title="set prices"
          mode="set"
        />,
        { onBackdropPress: closeModal },
      );
    } else {
      showNotification({
        error: true,
        message: translate('pricings.noItemSelected'),
      });
    }
  }, [
    showModal,
    closeModal,
    showNotification,
    selectedProductPrices,
    translate,
    onSetPrices,
  ]);

  const onModifyPrices = useCallback(
    (input: number, sign: boolean, percent: boolean) => {
      const tempObj = {} as Record<string, ProductPricing>;
      selectedProductPrices.forEach(x => {
        const currentPrice = x.sellingPrice.amount;
        let updatedPrice = 0;
        if (percent) {
          const priceVariation = (currentPrice * input) / 100;
          updatedPrice = sign
            ? currentPrice + priceVariation
            : currentPrice - priceVariation;
        } else {
          updatedPrice = sign ? currentPrice + input : currentPrice - input;
        }
        tempObj[x.productId] = {
          ...x,
          sellingPrice: {
            ...x.sellingPrice,
            amount: updatedPrice,
          },
          isChanged: true,
        };
      });
      setProductPricingData(prev => {
        return {
          ...prev,
          ...tempObj,
        };
      });
      showNotification({
        success: true,
        message: translate('pricings.operationModifySuccess', {
          value: (sign ? '+' : '-') + input + (percent ? '%' : '$'),
        }),
      });
    },
    [selectedProductPrices, showNotification, translate],
  );

  const modifyPrices = useCallback(() => {
    if (selectedProductPrices.length > 0) {
      showModal(
        <SetPriceModal
          onSubmit={(input, sign, percent) => {
            onModifyPrices(input, sign, percent);
            closeModal();
          }}
          title="modify prices"
          mode="modify"
        />,
        { onBackdropPress: closeModal },
      );
    } else {
      showNotification({
        error: true,
        message: translate('pricings.noItemSelected'),
      });
    }
  }, [
    showModal,
    closeModal,
    showNotification,
    selectedProductPrices,
    translate,
    onModifyPrices,
  ]);

  const onResetPrices = useCallback(() => {
    const tempObj = {} as Record<string, ProductPricing>;
    selectedProductPrices.forEach(x => {
      tempObj[x.productId] = {
        ...x,
        sellingPrice:
          standardPrices[x.product.id]?.sellingPrice || x.sellingPrice,
        isChanged: true,
      };
    });
    setProductPricingData(prev => {
      return {
        ...prev,
        ...tempObj,
      };
    });
    showNotification({
      success: true,
      message: translate('pricings.operationResetSuccess'),
    });
  }, [selectedProductPrices, standardPrices, showNotification, translate]);

  const resetPrices = useCallback(() => {
    if (selectedProductPrices.length > 0) {
      showModal(
        <ConfirmationModal
          title={translate('pricings.resetTitle')}
          confirmLabel={translate('pricings.resetActionTitle')}
          message={translate('pricings.resetMessage')}
          onConfirm={() => {
            onResetPrices();
            closeModal();
          }}
        />,
      );
    } else {
      showNotification({
        error: true,
        message: translate('pricings.noItemSelected'),
      });
    }
  }, [
    showModal,
    closeModal,
    showNotification,
    selectedProductPrices,
    translate,
    onResetPrices,
  ]);

  const onChange = useCallback(
    (id, prop, value) => {
      if (prop === 'sellingPrice') {
        setProductPricingData(prev => {
          const sellingPrice = prev[id]?.sellingPrice;
          return {
            ...prev,
            [id]: {
              ...prev[id],
              costPrice: standardPrices[id].costPrice,
              [prop]: {
                ...sellingPrice,
                amount: unAppendCurrency(value),
              },
              isChanged: true,
            },
          };
        });
      } else if (prop === 'isSelected') {
        setProductPricingData(prev => {
          return {
            ...prev,
            [id]: {
              ...prev[id],
              isSelected: value,
            },
          };
        });
      }
    },
    [standardPrices, unAppendCurrency],
  );

  const selectToggle = useCallback(
    (isSelected): void => {
      const tempObj = {} as Record<string, ProductPricing>;
      priceListProducts.forEach(x => {
        tempObj[x.productId] = {
          ...x,
          isSelected: isSelected,
        };
      });
      setProductPricingData(tempObj);
    },
    [priceListProducts],
  );

  const changedProductPricings = useMemo(() => {
    return priceListProducts.filter(x => x && x.isChanged);
  }, [priceListProducts]);

  useEffect(() => {
    if (isProductPricingUpdated) {
      showNotification({
        success: true,
        message: translate('pricings.productPricingUpdatedSuccessfully'),
      });
      getPricingGroup(pricingGroupId);
    }
  }, [
    isProductPricingUpdated,
    showNotification,
    translate,
    getPricingGroup,
    pricingGroupId,
  ]);

  const addProductPricingData = useCallback(() => {
    const input = Object.values(productPricingData)
      .filter(x => x.isNew)
      .map(x => {
        const productId = x.productId;
        const sellingTax = x.sellingTax?.id;
        const costPrice = x.costPrice;

        const newProductPricing = stripProperties(
          x,
          'isSelected',
          'id',
          'isNew',
          'isChanged',
          '__typename',
          'isActive',
          'pricingGroup',
          'productId',
          'costPrice',
        );

        return {
          productId,
          pricings: [
            {
              pricingGroupId,
              productPricing: {
                ...newProductPricing,
                product: productId,
                pricingGroupId,
                sellingPrice: {
                  ...newProductPricing.sellingPrice,
                  amount: +newProductPricing?.sellingPrice?.amount,
                },
                sellingTax,
                ...(costPrice && {
                  costPrice: {
                    amount: +costPrice.amount,
                    currency: costPrice.currency,
                  },
                }),
              } as ProductPricingInput,
            },
          ],
        };
      });
    if (input.length > 0) {
      addBulkProductPricings(input);
    }
  }, [productPricingData, pricingGroupId, addBulkProductPricings]);

  const deleteProductPricing = useCallback(() => {
    if (deletedProductPricing.length > 0) {
      deleteProductPricings(deletedProductPricing);
    }
  }, [deleteProductPricings, deletedProductPricing]);

  const updateProductPricingsData = useCallback(() => {
    const updateProductPricingsInput = changedProductPricings
      .filter(x => !x.isNew)
      .map(x => ({
        id: x.id,
        sellingPrice: {
          amount: +x.sellingPrice.amount,
          currency: x.sellingPrice.currency,
        },
        ...(x.costPrice && {
          costPrice: {
            amount: +x.costPrice.amount,
            currency: x.costPrice.currency,
          },
        }),
        product: x.product.id,
        pricingGroupId,
      })) as ProductPricingInput[];
    if (updateProductPricingsInput.length > 0) {
      updateProductPricings(updateProductPricingsInput);
    }
  }, [changedProductPricings, updateProductPricings, pricingGroupId]);

  const onPressSave = useCallback((): void => {
    if (
      !changedProductPricings.length &&
      !deletedProductPricing.length &&
      !Object.values(productPricingData).some(x => x.isNew)
    ) {
      showNotification({
        error: true,
        message: translate('pricings.noRecordsChanged'),
      });
    } else {
      deleteProductPricing();
      addProductPricingData();
      updateProductPricingsData();
    }
  }, [
    changedProductPricings,
    deletedProductPricing,
    updateProductPricingsData,
    addProductPricingData,
    deleteProductPricing,
    translate,
    showNotification,
    productPricingData,
  ]);

  const onPressRemoveProduct = useCallback(
    (id: string) => {
      if (isDefault) {
        showNotification({
          error: true,
          message: translate('pricings.defaultPricingGroupError'),
        });
      } else {
        if (pricesDataInDb && pricesDataInDb[id]) {
          setDeletedProductPricing(prev => [
            ...prev,
            { productId: id, pricingGroupId },
          ]);
        }
        setProductPricingData(prev => {
          const temp = { ...prev };
          delete temp[id];
          return temp;
        });
      }
    },
    [translate, isDefault, pricingGroupId, showNotification, pricesDataInDb],
  );

  const onPressAddProduct = useCallback(
    data => {
      const isExistsInState = productPricingData?.[data.product?.id];
      if (isExistsInState?.id) {
        showNotification({
          error: true,
          message: translate('pricings.duplicateProductPricing'),
        });
      } else {
        setProductPricingData(prev => ({
          ...prev,
          [data.productId]: { ...data, isNew: true },
        }));
        const remainingProductPricingToDelete = deletedProductPricing.filter(
          x => x.productId !== data.productId,
        );
        setDeletedProductPricing(remainingProductPricingToDelete);
      }
    },
    [showNotification, translate, productPricingData, deletedProductPricing],
  );

  const onPressAddAllProducts = useCallback(
    (DataArray: ProductPricing[]) => {
      const newData = DataArray.filter(
        (data: { product: { id: string | number } }) =>
          !!!productPricingData?.[data.product?.id],
      );
      const newProductPricingData = newData.reduce(
        (acc, curr) => ({
          ...acc,
          [curr.productId]: { ...curr, isNew: true },
        }),
        {},
      );
      setProductPricingData(prev => ({
        ...prev,
        ...newProductPricingData,
      }));
      const remainingProductPricingToDelete = differenceBy(
        deletedProductPricing,
        newData,
        'productId',
      );
      setDeletedProductPricing(remainingProductPricingToDelete);
    },
    [deletedProductPricing, productPricingData],
  );

  return (
    <ScreenLayout
      loading={loading}
      title={`${
        pricingGroups[pricingGroupId]?.name || 'Price List'
      } Products | Oolio`}
      onSave={onPressSave}
      hideFooter={isDefault}
    >
      <Section layoutWidth={!isDefault ? 'medium' : 'small'}>
        {isDefault ? (
          <Message
            type="neutral"
            message={translate('pricings.defaultPricingText')}
            // eslint-disable-next-line react-native/no-inline-styles
            containerStyle={{ marginBottom: 20 }}
          />
        ) : (
          <ProductsFilters
            setPrices={setPrices}
            modifyPrices={modifyPrices}
            resetPrices={resetPrices}
            defaultPricing={standardPrices}
            isDefault={isDefault}
            onPressAdd={onPressAddProduct}
            onPressAddAll={onPressAddAllProducts}
          />
        )}
        <View style={styles.scheduleTable}>
          <View style={theme.tables.header}>
            {!isDefault && (
              <InputToggle
                testID="toggle-all"
                isToggled={selectAllStatus}
                isPartial={
                  selectedProductPrices.length > 0 &&
                  selectedProductPrices.length !== priceListProducts.length
                }
                onToggle={() => selectToggle(!selectAllStatus)}
                // eslint-disable-next-line react-native/no-inline-styles
                containerStyle={{ marginLeft: -12 }}
              />
            )}
            <Text style={[theme.tables.headerText, styles.headerProduct]}>
              {translate('pricings.productName')}
            </Text>
            <Text style={[theme.tables.headerText, styles.headerPrice]}>
              {translate('pricings.defaultPrice')}
            </Text>
            {!isDefault && (
              <>
                <Text
                  style={[theme.tables.headerText, styles.headerInputPrice]}
                >
                  {translate('pricings.newPrice')}
                </Text>
                <Text style={[theme.tables.headerText, styles.headerPrice]}>
                  {translate('pricings.changePrice')}
                </Text>
                <View style={styles.btnDelete} />
              </>
            )}
          </View>
          <View>
            {priceListProducts.length > 0 ? (
              priceListProducts.map((product, i: number) => (
                <ProductsRow
                  key={`${i}-${product.id}`}
                  rowIndex={i}
                  defaultPricing={standardPrices[product.product?.id]}
                  productPricing={product}
                  isDefault={isDefault}
                  onChange={onChange}
                  onDeleteRow={onPressRemoveProduct}
                />
              ))
            ) : (
              <View style={theme.tables.emptyView}>
                <Text testID="empty-message" style={theme.tables.emptyText}>
                  {translate('pricings.noProductPricingMessage')}
                </Text>
              </View>
            )}
          </View>
        </View>
      </Section>
    </ScreenLayout>
  );
};
