import React, { useCallback, useEffect, useState } from 'react';
import { View } from 'react-native';
import { Money, Currency, DEFAULT_TAX } from '@oolio-group/domain';
import { useTranslation, useCurrency } from '@oolio-group/localization';
import { useNotification } from '../../../../../hooks/Notification';
import { isValidPrice } from '../../../../../utils/validator';
import theme from '../../../../../common/default-theme';
import { Option } from '../../../../../components/Shared/Select/Select';
import TreatPicker from '../../../../../components/Shared/Select/Picker';
import InputText from '../../../../../components/Shared/Inputs/InputText';
import TreatModal from '../../../../../components/Shared/Modals/Modal/Modal';
import InputToggle from '../../../../../components/Shared/Inputs/InputToggle';

export interface CreateProductForm {
  name: string;
  category?: string;
  sellingPrice?: Money;
  sellingTax: string;
  isVariant?: boolean;
  taxInclusive?: boolean;
  productType?: string;
  isCopyMode?: boolean;
  sourceProductId?: string;
}

interface CreateProductModalProps {
  loading: boolean;
  createProduct?: Partial<CreateProductForm>;
  taxesOptions: Option[];
  categoryOptions: Option[];
  closeModal: () => void;
  onCreate: (prodInput: CreateProductForm, editMore: boolean) => void;
}

export const CreateProductModal: React.FC<CreateProductModalProps> = ({
  onCreate,
  taxesOptions,
  createProduct: createProductProp,
  categoryOptions,
  closeModal,
  loading,
}) => {
  const { translate } = useTranslation();
  const { showNotification } = useNotification();
  const { currency, currencySymbol } = useCurrency();

  const defaultTax =
    taxesOptions?.filter(x => x.label === DEFAULT_TAX)?.[0]?.value ||
    taxesOptions?.[0]?.value;

  const [productForm, setProductForm] = useState<CreateProductForm>({
    sellingTax: defaultTax,
    category: '',
    productType: '',
    name: '',
  });

  const sellingPrice = productForm?.sellingPrice?.amount;
  const { isCopyMode } = productForm;

  const onChange = useCallback(
    (prop: string, value) => {
      if (prop === 'sellingPrice') {
        const temp = value.includes(currencySymbol)
          ? value.split(currencySymbol)[1]
          : value;
        value = { currency: currency as Currency, amount: temp };
      }
      setProductForm(prev => {
        return { ...prev, [prop]: value };
      });
    },
    [currencySymbol, currency],
  );

  const onCreateProduct = useCallback(
    editMore => {
      let message = '';
      if (!productForm.name) {
        message = translate('productSettings.pleaseEnterProductName');
      } else if (!productForm.isVariant && !productForm?.sellingPrice?.amount) {
        message = translate(
          'productSettings.pleaseSelectSellingPriceForTheProduct',
        );
      } else if (!productForm.isVariant && !productForm?.sellingTax) {
        message = translate('productSettings.pleaseSelectSellTaxForTheProduct');
      } else if (
        !productForm.isVariant &&
        !isValidPrice(String(productForm.sellingPrice?.amount))
      ) {
        message = translate('form.requiredField', {
          fieldName: 'Selling Price',
        });
      } else {
        let createProdForm = { ...productForm };
        if (!productForm.isVariant) {
          createProdForm = {
            ...productForm,
            taxInclusive: true,
            sellingPrice: {
              amount: +(productForm?.sellingPrice?.amount || 0),
              currency: productForm?.sellingPrice?.currency as Currency,
            },
          };
        }
        onCreate(createProdForm, editMore);
      }
      if (message) {
        showNotification({
          error: true,
          message,
        });
      }
    },
    [onCreate, productForm, showNotification, translate],
  );

  useEffect(() => {
    if (!createProductProp) return;
    setProductForm({
      ...(createProductProp as CreateProductForm),
      sellingTax: createProductProp?.sellingTax || defaultTax,
    });
  }, [defaultTax, createProductProp]);

  return (
    <TreatModal
      size="medium"
      type="positive"
      loading={loading}
      title={translate('productSettings.quickCreateProduct')}
      onConfirm={{
        label: translate('productSettings.create'),
        action: onCreateProduct.bind(null, false),
      }}
      secondaryButton={{
        label: translate('productSettings.editMore'),
        type: 'neutral',
        action: onCreateProduct.bind(null, true),
      }}
      onDismiss={{ action: closeModal }}
    >
      <View style={theme.forms.row}>
        <InputText
          testID="input-name"
          title={translate('productSettings.productName')}
          placeholder={translate('productSettings.productName')}
          value={productForm.name}
          onChangeText={onChange.bind(null, 'name')}
          containerStyle={theme.forms.inputHalf}
        />
        <TreatPicker
          testID="select-category"
          title={translate('backOfficeProducts.selectCategory')}
          options={categoryOptions}
          selectedValue={productForm?.category || ''}
          onValueChange={value => onChange('category', value)}
          containerStyle={theme.forms.inputHalf}
        />
      </View>
      {!productForm.isVariant ? (
        <View style={theme.forms.row}>
          <InputText
            numericOnly
            testID="input-name"
            title={translate('productSettings.sellingPrice')}
            label={currencySymbol}
            placeholder={'0.00'}
            value={sellingPrice ? sellingPrice.toString() : ''}
            onChangeText={onChange.bind(null, 'sellingPrice')}
            containerStyle={theme.forms.inputHalf}
          />
          <TreatPicker
            testID="select-tax"
            title={translate('productSettings.sellingTax')}
            options={taxesOptions}
            selectedValue={productForm.sellingTax}
            onValueChange={onChange.bind(null, 'sellingTax')}
            containerStyle={theme.forms.inputHalf}
          />
        </View>
      ) : null}
      {!isCopyMode ? (
        <View style={theme.forms.row}>
          <InputToggle
            testID="hasVariant-toggle"
            title={translate('productSettings.hasVariants')}
            isToggled={productForm.isVariant || false}
            onToggle={onChange.bind(null, 'isVariant', !productForm.isVariant)}
            containerStyle={theme.forms.inputHalf}
          />
        </View>
      ) : null}
    </TreatModal>
  );
};
