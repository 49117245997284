import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import LoadingIndicator from '../../../../components/LoadingIndicator/LoadingIndicator';
import { Helmet } from 'react-helmet';
import { Styles } from '../styles/SalesFeed.styles';
import { View, ScrollView } from 'react-native';
import { useCurrency, useTranslation } from '@oolio-group/localization';
import {
  DateRangeFilter,
  FilterValue,
  Widget,
  WidgetChartType,
  OrderReceiptInput,
  Filters,
  OrderStatus,
  StyleFn,
} from '@oolio-group/domain';
import { ReportHeader } from '../UIComponents/ReportHeader';
import { Table, TableRef } from '../UIComponents/Table';
import { Filters as ReportFilter } from '../UIComponents/Filters';
import {
  Search,
  IMap,
  ExportType,
  ReportTableColumn,
  HelperText,
  PivotTableData,
  SidePanel,
  DropDownFilter,
  PivotTableRow,
  DisplayLabels,
} from '../types';
import {
  GRANULARITY_FORMATS,
  PREVIEW_COLUMN,
  transformFieldsToTableColumn,
} from '../reportsHelper';
import { SalesFeedReportHelper } from '@oolio-group/analytics-helper';
import SecondarySidePanel from '../../../../components/SecondarySidePanel/SecondarySidePanel';
import { DataPreview } from './UIComponents/DataPreview';

import { useModal } from '@oolio-group/rn-use-modal';
import { noopHandler } from '../../../../utils/errorHandlers';
import { useNotification } from '../../../../hooks/Notification';
import { useMutation } from '@apollo/client/react/hooks';
import OrderInvoice from '../../../../components/Modals/OrderInvoice/OrderInvoice';
import { EMAIL_ORDER_RECEIPT } from '../../../../graphql/orders';
import { CubejsApi } from '@cubejs-client/core';

interface ReportProps {
  options: { loading: boolean };
  filterOptions: DropDownFilter[];
  filters: FilterValue;
  widgets: Widget[];
  search: Search;
  dateRangeFilter: DateRangeFilter;
  updateFilters: (filter: string, value: string[]) => void;
  updateSearch: (filter: string, value: string) => void;
  resetFilters: () => void;
  updateReport: () => void;
  updateDateRangeFilters: (value: DateRangeFilter) => void;
  updateCount: number;
  cubejsApi: CubejsApi;
  allFilters: Filters;
}

const TABLE_GRANULARITY_FORMATS: IMap<string> = {
  ...GRANULARITY_FORMATS,
  day: 'MMM dd, yyyy hh:mm aaa',
};

export const SalesFeedReport: React.FC<ReportProps> = ({
  options: { loading },
  filters,
  filterOptions,
  search,
  widgets,
  dateRangeFilter,
  updateFilters,
  updateSearch,
  updateReport,
  resetFilters,
  updateDateRangeFilters,
  cubejsApi,
  allFilters,
  updateCount,
}) => {
  const { translate } = useTranslation();
  const { appendCurrency } = useCurrency();
  const styles = Styles();
  const [showFilters, setShowFilters] = useState<boolean>(false);
  const [sidePanel, setSidePanel] = useState<SidePanel>({
    show: false,
    data: undefined,
  });

  const { showModal } = useModal();
  const { showNotification } = useNotification();

  const [emailOrderReceipt] = useMutation(EMAIL_ORDER_RECEIPT, {
    onError: noopHandler,
    onCompleted: () => {
      showNotification({
        success: true,
        message: translate('payment.emailOrderReceiptSuccess'),
      });
    },
  });

  const sendOrderReceipt = useCallback(
    (order, email) => {
      if (order?.id) {
        const input: OrderReceiptInput = {
          organizationId: order.organizationId,
          venueId: order.venueId,
          storeId: order.storeId,
          deviceId: order.deviceId,
          orderId: order.id,
          triggeredBy: order.createdById,
          email: email,
        };
        emailOrderReceipt({ variables: { input } });
      }
    },
    [emailOrderReceipt],
  );

  const showPrintModal = useCallback(
    order => {
      showModal(<OrderInvoice order={order} sendReceipt={sendOrderReceipt} />);
    },
    [sendOrderReceipt, showModal],
  );

  const emailReceipt = useCallback(() => {
    if (sidePanel.data) {
      const orderObj = {
        id: sidePanel.data['Orders.orderId'],
        organizationId: sidePanel.data['Orders.organizationId'],
        venueId: sidePanel.data['Orders.venueId'],
        storeId: sidePanel.data['Orders.storeId'],
        deviceId: sidePanel.data['Orders.deviceId'],
        registerId: sidePanel.data['Orders.registerId'],
        createdById: sidePanel.data['Orders.createdById'],
      };
      showPrintModal(orderObj);
    }
  }, [showPrintModal, sidePanel.data]);

  const toggleFilters = useCallback(() => {
    setShowFilters(value => !value);
  }, []);

  const tableWidgetRef = useRef<TableRef>({} as TableRef);
  const exportReport = useCallback((type = ExportType.CSV) => {
    if (type === ExportType.CSV)
      tableWidgetRef.current.exportData &&
        tableWidgetRef.current.exportData(`SalesFeedReport-${Date.now()}.csv`);
  }, []);

  const tableWidget = useMemo(
    () =>
      widgets.find(widget => widget.chartType === WidgetChartType.TABLE) ||
      ({} as Widget),
    [widgets],
  );

  const [selectedColumns, setSelectedColumns] = useState<ReportTableColumn[]>(
    [],
  );

  const tableColumns: ReportTableColumn[] = useMemo(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const columns: any[] = [];
    if (tableWidget.query?.dimensions)
      columns.push(
        ...transformFieldsToTableColumn(tableWidget.query?.dimensions).map(
          dimension => {
            if (dimension.key === 'Orders.orderNumber') {
              dimension.textStyleCallback = (data: PivotTableRow): StyleFn => {
                return ({ theme }) => ({
                  color:
                    data['Orders.orderStatus'] === OrderStatus.VOID
                      ? theme.colors.red
                      : 'inherit',
                });
              };
            }
            return dimension;
          },
        ),
      );

    if (tableWidget.query?.measures)
      columns.push(
        ...transformFieldsToTableColumn(tableWidget.query?.measures),
      );
    return columns;
  }, [tableWidget.query]);

  useEffect(() => {
    setSelectedColumns(tableColumns.filter(column => column.showByDefault));
  }, [tableColumns]);

  const updateColumns = useCallback(
    (columnKeys: string[]) => {
      setSelectedColumns(
        tableColumns.filter(col => columnKeys.includes(col.title)),
      );
    },
    [tableColumns],
  );

  const allColumns = useMemo(() => {
    return [...selectedColumns, PREVIEW_COLUMN];
  }, [selectedColumns]);

  const tableDataTransformationFn = useCallback(
    (pivotTableData: PivotTableData): PivotTableData => {
      return pivotTableData.map(row => {
        row['Orders.customerText'] = row['Orders.customerName'];
        row['OrderPayments.paymentAmount'] = row['OrderPayments.amount'];
        row['Orders.customerName'] = `${row['Orders.customerName']} (${
          row['Orders.uniqCustomers'] || '0'
        })`;
        row['OrderPayments.amount'] = `${
          row['OrderPayments.paymentTypeName']
        } (${appendCurrency(
          (row['OrderPayments.amount'] as string) || '0.00',
        )})`;

        if (row['Orders.orderStatus'] === OrderStatus.VOID) {
          row['OrderPayments.paymentTypeName'] =
            DisplayLabels.VOID_ORDER_PAYMENT_NAME;
          row['OrderPayments.amount'] = DisplayLabels.VOID_ORDER_PAYMENT_NAME;
        }
        return row;
      });
    },
    [appendCurrency],
  );

  const onTableRowPressFn = useCallback(
    item => {
      setSidePanel({ show: !sidePanel.show, data: item });
    },
    [sidePanel],
  );

  const renderContainer = useMemo(
    () => (
      <DataPreview
        timezone={tableWidget.query?.timezone}
        previewData={sidePanel.data}
        cubejsApi={cubejsApi}
      />
    ),
    [sidePanel.data, tableWidget, cubejsApi],
  );

  return (
    <>
      <Helmet>
        <title>
          {translate('navigation.generalSettingsPageTitle', {
            appName: translate('appName'),
          })}
        </title>
      </Helmet>

      <ScrollView
        scrollEnabled={!(showFilters || sidePanel.show)}
        style={styles.pageStyle}
      >
        {showFilters ? (
          <View style={styles.filtersStyle}>
            <ReportFilter
              toggleFilters={toggleFilters}
              filterOptions={filterOptions}
              filters={filters}
              updateFilters={updateFilters}
              resetFilters={resetFilters}
            ></ReportFilter>
          </View>
        ) : null}
        <View style={styles.mainSectionStyle}>
          <View style={styles.headersStyle}>
            <ReportHeader
              allFilters={allFilters}
              filters={filters}
              toggleFilters={toggleFilters}
              updateReport={updateReport}
              search={search}
              updateSearch={updateSearch}
              columns={{ all: tableColumns, updateColumns, selectedColumns }}
              exportReport={exportReport}
              updateFilters={updateFilters}
              dateRangeFilter={dateRangeFilter}
              updateDateRangeFilters={updateDateRangeFilters}
              translationParentKey={'backOfficeSalesFeed'}
              searchTypeOptions={SalesFeedReportHelper.SEARCH_FILTERS}
            />
          </View>
          <View style={styles.tableStyle}>
            {loading ? (
              <LoadingIndicator />
            ) : (
              <Table
                widget={tableWidget}
                columns={allColumns}
                helper={HelperText.SALES_FEED}
                granularityFormats={TABLE_GRANULARITY_FORMATS}
                ref={tableWidgetRef}
                dataTransformationFn={tableDataTransformationFn}
                onTableRowPress={onTableRowPressFn}
                cubejsApi={cubejsApi}
                updateCount={updateCount}
              ></Table>
            )}
          </View>
        </View>
      </ScrollView>
      <SecondarySidePanel
        showPanel={sidePanel.show}
        onPressHideShowPanel={() => onTableRowPressFn(undefined)}
        headerText={translate('backOfficeSalesFeed.rowPreview.panelTitle')}
        renderContainer={renderContainer}
        onPressHeaderRightIcon={emailReceipt}
        headerRightIcon={'envelope'}
      />
    </>
  );
};

export default SalesFeedReport;
