import React, { useCallback, useState } from 'react';
import {
  View,
  Text,
  Platform,
  StatusBar,
  KeyboardAvoidingView,
} from 'react-native';
import { Helmet } from 'react-helmet';
import {
  Address,
  BusinessSize,
  BusinessStatus,
  BusinessType,
  OnboardInput,
} from '@oolio-group/domain';
import {
  TIMEZONES_MAP,
  Timezone,
  getCountry,
  useTranslation,
} from '@oolio-group/localization';
import { useNavigation, useRoute } from '@react-navigation/native';
import { useSignUp } from '../../../hooks/app/useSignUp';
import { useSession } from '../../../hooks/app/useSession';
import { useNotification } from '../../../hooks/Notification';
import { useUserProfile } from '../../../hooks/app/useUserProfile';
import { useAuthentication } from '../../../hooks/app/useAuthentication.web';
import { getSession } from '../../../state/preferences';
import { delay } from '../../../utils/delay';
import { fetchSsoProfile } from '../../../utils/sso';
import { isValidBusinessName } from '../../../utils/validator';
import styles from './OnboardScreen.styles';
import theme from '../../../common/default-theme';
import Logo from '../../../components/Logo/Logo';
import SelectBar from '../../../components/Button/SelectBar';
import Gradient from '../../../components/Gradient/Gradient';
import Select from '../../../components/Shared/Select/Select';
import InputText from '../../../components/Shared/Inputs/InputText';
import InputPhone from '../../../components/Shared/Inputs/InputPhone';
import SelectAddress from '../../../components/Shared/Select/SelectAddress';
import TreatButton from '../../../components/Shared/TreatButton/TreatButton';

const DEFAULT_COUNTRY_CODE = 'AU';
const TIMEZONES: Array<Timezone> = Object.values(TIMEZONES_MAP);

// This screen is only mean to be routed from Oolio portal
// expect to be web only
const Onboard: React.FC = () => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const route = useRoute<any>();
  const navigation = useNavigation();
  const { getMe } = useUserProfile();
  const [, setSession] = useSession();
  const { translate } = useTranslation();
  const { showNotification } = useNotification();
  const { loading, onboardOrganization } = useSignUp();
  const { width, height } = theme.useResponsiveDimensions();

  const [step, setStep] = useState<number>(1);
  const [phoneCountryCode, setPhoneCountryCode] =
    useState<string>(DEFAULT_COUNTRY_CODE);
  const [formData, setFormData] = useState<OnboardInput>({
    organization: route.params['orgId'],
    businessName: route.params['orgName'],
    user: route.params['user'],
    country: DEFAULT_COUNTRY_CODE,
    businessType: BusinessType.CAFETERIA,
    size: BusinessSize.SMALL,
    status: BusinessStatus.NEW_BUSINESS,
    address: undefined as Address | undefined,
  });
  const [isSignupDisabled, setIsSignupDisabled] = useState<boolean>(false);

  const _onSignIn = useCallback((): void => {
    navigation.navigate('LoginTypeSelection');
  }, [navigation]);

  const validateOrganizations = useCallback(async () => {
    try {
      const user = await getMe();
      const activeOrg = user.organizations?.find(
        org => org.id === route.params['orgId'],
      );
      if (activeOrg) {
        // manually get from localstorage to avoid deps loop
        const session = await getSession();
        setSession({
          ...session,
          currentOrganization: activeOrg,
        });
        navigation.navigate('BackOffice');
        return;
      }
      throw new Error('Organization not onboarded');
    } catch (error) {
      const user = await fetchSsoProfile();
      if (
        !user ||
        !user.organizations?.find(
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          (org: any) => org.id === route.params['orgId'],
        )
      ) {
        showNotification({
          message: 'organization not exist',
          error: true,
        });
        _onSignIn();
      }
    }
  }, [
    getMe,
    route.params,
    setSession,
    navigation,
    showNotification,
    _onSignIn,
  ]);

  // Try to finish exchange token
  useAuthentication({
    redirectUrl: location.origin + '/onboarding',
    onAuthorized: validateOrganizations,
  });

  const _onChangeProp = (prop: string, text: string): void => {
    setFormData({ ...formData, [prop]: text });
  };

  const addressProvided = Object.keys(formData.address || {}).length > 0;

  const isValid = (): boolean => {
    const { businessName } = formData;

    const passes = isValidBusinessName(businessName) && addressProvided;
    setIsSignupDisabled(!passes);

    return passes;
  };

  const onboardOrganizationToPos = async (): Promise<void> => {
    if (isValid()) {
      // format phone
      const phone = phoneCountryCode
        ? `${getCountry(phoneCountryCode).phone} ${formData.phone}`
        : formData.phone;

      // TODO: add csurf for this unguarded api
      const result = await onboardOrganization({
        ...formData,
        phone,
      });

      if (result.error) {
        showNotification({ error: true, message: result.error });
      } else if (result.success) {
        showNotification({
          success: true,
          message: translate('signup.signupSuccessMsg'),
        });
        await delay(3000);
        navigation.navigate('LoginTypeSelection');
      }
    }
  };

  const _onNext = (): void => {
    if (isValid()) {
      setStep(step + 1);
    }
  };

  const _onPrev = (): void => {
    setStep(step - 1);
  };

  const onSelectAddress = useCallback((address: Address) => {
    const _countryCode = address?.isoCountryCode || DEFAULT_COUNTRY_CODE;
    const defaultTimezone = TIMEZONES.find((_tz: Timezone) => {
      return _tz.country === _countryCode;
    });
    const selectedCountry = getCountry(_countryCode);

    setFormData(formData => ({
      ...formData,
      address,
      country: _countryCode,
      timezone: defaultTimezone?.name,
      currencyCode: selectedCountry?.currency,
    }));
  }, []);

  const isUKPhone = phoneCountryCode === 'GB';

  return (
    <>
      <Helmet>
        <title>
          {translate('navigation.signupPageTitle', {
            step,
            appName: translate('appName'),
          })}
        </title>
      </Helmet>
      <StatusBar barStyle="light-content" />
      <View style={{ width, height }}>
        <Gradient style={styles.screenContainer}>
          <KeyboardAvoidingView
            behavior={Platform.OS === 'ios' ? 'padding' : 'height'}
          >
            <View style={styles.modalContainer}>
              <Logo type="logotype" color="primary" style={styles.logo} />
              {step === 1 ? (
                <View style={styles.formContainer}>
                  <InputText
                    testID="business-name"
                    title={translate('signup.businessName')}
                    value={formData.businessName}
                    placeholder={translate('signup.storeNameHint')}
                    onChangeText={text => _onChangeProp('businessName', text)}
                    errorMessage={
                      !isValidBusinessName(formData.businessName) &&
                      isSignupDisabled
                        ? 'Enter valid business name'
                        : undefined
                    }
                    isVerified={isValidBusinessName(formData.businessName)}
                    containerStyle={styles.formInput}
                  />
                  <InputPhone
                    testID="phone-number"
                    title={translate('signup.phone')}
                    value={formData.phone || ''}
                    onChangeText={text => _onChangeProp('phone', text)}
                    containerStyle={styles.formInput}
                    placeholder={translate(
                      isUKPhone
                        ? 'form.phoneNumberHintUK'
                        : 'form.phoneNumberHint',
                    )}
                    defaultCountry={phoneCountryCode}
                    onPressCountry={(country: string): void =>
                      setPhoneCountryCode(country)
                    }
                  />
                  <Select
                    testID="business-type"
                    title={translate('signup.businessType')}
                    placeholder="Select..."
                    value={formData.businessType}
                    selectedValue={formData.businessType}
                    options={Object.values(BusinessType).map(value => ({
                      value: value as string,
                      label: translate('enums.' + value),
                    }))}
                    onValueChange={text => _onChangeProp('businessType', text)}
                    containerStyle={styles.formInput}
                  />
                  <SelectAddress
                    title={translate('signup.businessAddress')}
                    testID="business-address"
                    placeholder={translate('signup.businessAddressHint')}
                    onChangeAddress={onSelectAddress}
                    address={formData.address as Address}
                    errorMessage={
                      !addressProvided && isSignupDisabled
                        ? 'Enter valid address'
                        : undefined
                    }
                    containerStyle={styles.formInputAddress}
                  />
                </View>
              ) : (
                <View style={styles.insightsContainer}>
                  <Text style={styles.insightsTitle}>
                    {translate('signup.signupStep2Description')}
                  </Text>
                  <View>
                    <Text style={styles.insightsText}>
                      {translate('signup.storeTypeQuestion', {
                        businessName: formData.businessName,
                      })}
                    </Text>
                    <SelectBar
                      options={[
                        {
                          label: translate(
                            'enums.' + BusinessStatus.EXISTING_BUSINESS,
                          ),
                          value: BusinessStatus.EXISTING_BUSINESS,
                        },
                        {
                          label: translate(
                            'enums.' + BusinessStatus.NEW_BUSINESS,
                          ),
                          value: BusinessStatus.NEW_BUSINESS,
                        },
                      ]}
                      selectedOption={
                        formData.status || BusinessStatus.NEW_BUSINESS
                      }
                      onPress={text => _onChangeProp('status', text)}
                    />
                  </View>
                  <View>
                    <Text style={styles.insightsText}>
                      {translate('signup.storeSizeQuestion', {
                        businessName: formData.businessName,
                      })}
                    </Text>
                    <SelectBar
                      options={[
                        {
                          label: BusinessSize.SMALL,
                          value: BusinessSize.SMALL,
                        },
                        {
                          label: BusinessSize.MEDIUM,
                          value: BusinessSize.MEDIUM,
                        },
                        {
                          label: BusinessSize.BIG,
                          value: BusinessSize.BIG,
                        },
                      ]}
                      onPress={text => _onChangeProp('size', text)}
                      selectedOption={formData.size || BusinessSize.SMALL}
                    />
                  </View>
                </View>
              )}
              <View style={styles.actionsContainer}>
                {step === 1 ? (
                  <>
                    <TreatButton
                      testID="signin-button"
                      label={translate('button.login')}
                      type="primaryLight"
                      onPress={_onSignIn}
                      isLoading={loading}
                      disabled={loading}
                      containerStyle={styles.formButton}
                    />
                    <TreatButton
                      testID="continue-button"
                      label="Continue"
                      type="primary"
                      onPress={_onNext}
                      isLoading={loading}
                      disabled={loading}
                      containerStyle={styles.formButton}
                    />
                  </>
                ) : (
                  <>
                    <TreatButton
                      testID="back-button"
                      label={translate('button.back')}
                      type="primaryLight"
                      onPress={_onPrev}
                      isLoading={loading}
                      disabled={loading}
                      containerStyle={styles.formButton}
                    />
                    <TreatButton
                      testID="signup"
                      label={translate('button.complete')}
                      type="primary"
                      onPress={onboardOrganizationToPos}
                      isLoading={loading}
                      disabled={loading}
                      containerStyle={styles.formButton}
                    />
                  </>
                )}
              </View>
            </View>
          </KeyboardAvoidingView>
        </Gradient>
      </View>
    </>
  );
};

export default Onboard;
