import React, { FC } from 'react';
import { View, Text, StyleSheet } from 'react-native';
import { ResourceOperation } from '@oolio-group/domain';
import { PermissionTreeNode } from '../../../../../state/jobRoleSettingsUtility';
import theme from '../../../../../common/default-theme';
import Icon from '../../../../../components/Icon/Icon';
import PermissionGroupCheckBox from './PermissionGroupCheckBox';

const PermissionGroupHeader: FC<{
  section: PermissionTreeNode;
  isActive?: boolean;
}> = ({ section, isActive }) => {
  const availableOps = Array.from(
    new Set(section.children?.map(e => e.operation).flat()),
  ) as string[];

  return (
    <View style={styles.container}>
      <View style={styles.iconContainer}>
        <Icon
          size={20}
          name={isActive ? 'angle-down' : 'angle-right'}
          color={isActive ? theme.colors.states.neutral : theme.colors.grey5}
        />
      </View>
      <View style={styles.titleView}>
        <Text testID="permissionGrpTitle" style={styles.title}>
          {section.label}
        </Text>
        {!!section.description && (
          <Text style={styles.subtitle}>{section.description}</Text>
        )}
      </View>
      <View style={styles.checkboxes}>
        {(['view', 'update'] as ResourceOperation[]).map((operation, i) => {
          if (availableOps?.includes(operation)) {
            return (
              <PermissionGroupCheckBox
                key={section.id + operation + i}
                permissionGroup={section}
                operation={operation}
              />
            );
          }
          return <View key={section.id + i} style={styles.iconPlaceholder} />;
        })}
      </View>
    </View>
  );
};

export default PermissionGroupHeader;

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    borderBottomWidth: 1,
    borderBottomColor: theme.colors.grey3,
  },
  iconContainer: {
    width: 38,
    height: 38,
    alignItems: 'center',
    justifyContent: 'center',
  },
  iconPlaceholder: {
    width: 60,
    height: 38,
    marginRight: 10,
  },
  titleView: {
    flex: 1,
    paddingVertical: 12,
  },
  title: {
    fontSize: 14,
    color: theme.colors.black1,
  },
  subtitle: {
    fontSize: 12,
    marginTop: 6,
    lineHeight: 20,
    color: theme.colors.grey6,
  },
  checkboxes: {
    paddingRight: 10,
    flexDirection: 'row',
    alignItems: 'center',
  },
});
