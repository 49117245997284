import { getLoyaltyUnit, getOptionalProperty } from '@oolio-group/client-utils';
import {
  Customer,
  LoyaltySettings,
  OrderItem,
  RewardRule,
  StyleFn,
} from '@oolio-group/domain';
import { useCurrency, useTranslation } from '@oolio-group/localization';
import { useModal } from '@oolio-group/rn-use-modal';
import { format } from 'date-fns';
import { isEmpty, isEqual } from 'lodash';
import { RewardMap } from '../../../hooks/orders/useRewards';
import React, { useCallback, useMemo, useState } from 'react';
import { useFela } from 'react-fela';
import { ScrollView, Text, View } from 'react-native';
import Button from '../../Button/Button';
import IconButton from '../../Button/IconButton';
import Gradient from '../../Gradient/Gradient';
import Modal from '../Modal';
import RewardDisplay from './RewardDisplay';
import { useNotification } from '../../../hooks/Notification';
import RedeemItemsModal from './RedeemItemsModal';

const containerStyle: StyleFn = ({ theme }) => ({
  alignSelf: 'center',
  alignItems: 'center',
  borderRadius: theme.radius.large,
  backgroundColor: theme.colors.white,
  paddingVertical: 0,
  paddingHorizontal: 0,
  margin: 0,
  width: 440,
  maxWidth: '100%',
  flexShrink: 1,
});

const customBodyStyle: StyleFn = () => ({
  marginTop: 0,
  paddingTop: 0,
  paddingBottom: 0,
  marginBottom: 0,
  maxHeight: '100%',
});

const emptySectionStyle: StyleFn = ({ theme }) => ({
  backgroundColor: theme.colors.greyLight,
  height: 250,
  padding: theme.padding.xl,
  borderRadius: theme.radius.medium,
  margin: theme.spacing.medium,
  alignItems: 'center',
  justifyContent: 'center',
});

const emptySectionFontStyle: StyleFn = ({ theme }) => ({
  ...theme.font14Medium,
  color: theme.colors.textLight,
});

/* Reward Styles */
const rewardContainerStyle: StyleFn = ({ theme }) => ({
  padding: theme.padding.large,
  paddingBottom: theme.padding.large - theme.spacing.small,
});

const rewardItemStyle: StyleFn = ({ theme }) => ({
  marginBottom: theme.spacing.small,
});

const rewardPlaceholderStyle: StyleFn = ({ theme }) => ({
  backgroundColor: theme.colors.greyLight,
  borderRadius: theme.radius.medium,
  height: 76,
});

const buttonContainerStyle: StyleFn = ({ theme }) => ({
  padding: theme.padding.large,
  paddingTop: 0,
});

/* Heading Styles */
const headingContainer: StyleFn = ({ theme }) => ({
  height: 180,
  alignItems: 'center',
  justifyContent: 'center',
  borderTopLeftRadius: theme.radius.large,
  borderTopRightRadius: theme.radius.large,
  backgroundColor: theme.colors.blue,
});

const closeButtonStyle: StyleFn = ({ theme }) => ({
  backgroundColor: 'transparent',
  position: 'absolute',
  right: 'auto',
  left: theme.spacing.small * 2.5,
  top: theme.spacing.small * 2.5,
  zIndex: 9,
});

const pointBalanceStyle: StyleFn = ({ theme }) => ({
  ...theme.font14Bold,
  fontSize: theme.fontSize.largest,
  color: theme.colors.white,
  lineHeight: 34,
});
const pointAvailableStyle: StyleFn = ({ theme }) => ({
  ...theme.font14Medium,
  color: theme.colors.white,
  opacity: 0.6,
});
const customerNameStyle: StyleFn = ({ theme }) => ({
  ...theme.font14Medium,
  color: theme.colors.white,
  marginTop: theme.padding.large,
  marginBottom: theme.padding.small,
});
const sinceStyle: StyleFn = ({ theme }) => ({
  ...theme.font14Medium,
  color: theme.colors.white,
  fontSize: 12,
  opacity: 0.6,
});

export interface CustomerRewardModalProps {
  customer?: Customer | undefined;
  rewardMap?: RewardMap;
  loyaltySettings: Partial<LoyaltySettings>;
  rewardRules: RewardRule[];
  onRedeem: (rewardMap: RewardMap) => void;
  onEnroll?: () => void;
  enrolling?: boolean;
  onDismiss?: () => void;
  orderItems?: OrderItem[];
}

const CustomerRewardModal: React.FC<CustomerRewardModalProps> = props => {
  const {
    customer,
    rewardMap,
    loyaltySettings,
    rewardRules,
    onRedeem,
    onEnroll,
    enrolling,
    onDismiss,
    orderItems,
  } = props;
  const { translate } = useTranslation();
  const { css, theme } = useFela();
  const { showModal, closeModal } = useModal();
  const [appliedRewardMap, setAppliedRewardMap] = useState<RewardMap>(
    rewardMap || {},
  );
  const { formatCurrency, unAppendCurrency, decimalSeparator } = useCurrency();
  const { showNotification } = useNotification();

  const remainingBalance = useMemo(
    () =>
      Object.keys(appliedRewardMap).reduce((acc, curr) => {
        const redemptions = appliedRewardMap[curr];
        const cost = redemptions.reduce(
          (acc, redemption) =>
            acc +
            redemption.points *
              (redemption.itemQuantity || redemption.quantity),
          0,
        );
        return acc - cost;
      }, customer?.loyaltyPointsBalance || 0),
    [appliedRewardMap, customer],
  );

  const onRewardChange = useCallback(
    (reward: RewardRule, quantity?: number) => {
      // checking if reward with same type applied
      const duplicated = Object.keys(appliedRewardMap).find(
        key =>
          key !== reward.id &&
          appliedRewardMap[key].some(
            redemption =>
              redemption.type === reward.rewardType && redemption.quantity > 0,
          ),
      );

      if (duplicated) {
        showNotification({
          message: translate('customerLoyalty.addDuplicatedReward'),
          error: true,
        });
        return;
      }

      if ('products' in reward) {
        showModal(
          <RedeemItemsModal
            onRedeem={props.onRedeem}
            rewardMap={appliedRewardMap}
            reward={reward}
            remainingBalance={remainingBalance}
            onBack={rewardMap => {
              showModal(
                <CustomerRewardModal {...props} rewardMap={rewardMap} />,
              );
            }}
            orderItems={props.orderItems}
          />,
        );
        return;
      }

      const newRewardMap: RewardMap = {
        ...appliedRewardMap,
        [reward.id]: [
          {
            quantity: quantity || 0,
            points: reward.pointsRequired,
            type: reward.rewardType,
          },
        ],
      };

      setAppliedRewardMap(newRewardMap);
    },
    [
      showNotification,
      appliedRewardMap,
      translate,
      props,
      showModal,
      remainingBalance,
    ],
  );

  const handleClose = () => {
    onDismiss && onDismiss();
    closeModal();
  };

  const handleRedeem = () => {
    onRedeem(appliedRewardMap);
  };

  const hasEnoughBalance = (reward: RewardRule): boolean =>
    remainingBalance >= reward.pointsRequired;

  const hasViableProducts = (reward: RewardRule) => {
    if ('products' in reward) {
      const viableProducts = orderItems?.map(item => item.product.id);
      return reward.products.some(product =>
        viableProducts?.includes(product.id),
      );
    }

    return true;
  };

  const getAdditionalInfo = (reward: RewardRule) => {
    return !hasViableProducts(reward)
      ? translate('customerLoyalty.noRedeemableProducts')
      : undefined;
  };

  const allowRedeem = useMemo(() => {
    if (!rewardMap) return false;

    return Object.keys(appliedRewardMap).some(
      key =>
        // if any of the key is added or modified, allow redeem
        (!rewardMap[key] &&
          appliedRewardMap[key]?.some(redemption => redemption.quantity > 0)) ||
        (rewardMap[key] && isEqual(appliedRewardMap[key], rewardMap[key])),
    );
  }, [rewardMap, appliedRewardMap]);

  const formatBalance = useCallback(
    (value: number) => {
      const currencyString = formatCurrency(value);
      const withoutCurrency = unAppendCurrency(currencyString);
      const withoutDecimal = withoutCurrency.split(decimalSeparator)[0];
      return withoutDecimal.padStart(2, '0');
    },
    [formatCurrency, unAppendCurrency, decimalSeparator],
  );
  const formatSinceDate = (date: number) => {
    return format(date, 'dd/MM/yy');
  };

  const filteredRewards = rewardRules.filter(
    reward => reward.pointsRequired <= (customer?.loyaltyPointsBalance || 0),
  );

  const countForQuantity = (rewardId: string): number => {
    return (
      appliedRewardMap[rewardId]?.reduce(
        (acc, redemption) =>
          (redemption.itemQuantity || redemption.quantity) + acc,
        0,
      ) || 0
    );
  };

  if (isEmpty(loyaltySettings)) return <></>;

  return (
    <Modal
      onDismiss={handleClose}
      contentStyle={css(containerStyle)}
      customBodyStyle={customBodyStyle}
      showCloseButton={false}
    >
      <>
        <Gradient
          style={css(headingContainer)}
          colors={['rgb(48,35,174)', 'rgb(62,130,240)']}
          start={theme.gradient.startAxis}
          end={theme.gradient.endAxis}
          locations={theme.gradient.location}
        >
          <IconButton
            containerStyle={css(closeButtonStyle)}
            icon="times"
            color="white"
            iconSize={25}
            onPress={handleClose}
            testID="dismiss"
          />
          <Text style={css(pointBalanceStyle)} testID="balance">
            {customer?.loyaltyPointsBalance &&
            remainingBalance != customer?.loyaltyPointsBalance
              ? translate('customerLoyalty.remainingOfTotal', {
                  remaining: formatBalance(remainingBalance),
                  total: formatBalance(customer?.loyaltyPointsBalance || 0),
                })
              : formatBalance(remainingBalance)}
          </Text>
          <Text style={css(pointAvailableStyle)}>
            {translate('customerLoyalty.pointsAvailable', {
              unit: getLoyaltyUnit(remainingBalance, loyaltySettings),
            })}
          </Text>
          <Text style={css(customerNameStyle)} testID="customer-name">
            {`${customer?.firstName} ${customer?.lastName}`}
          </Text>
          <Text style={css(sinceStyle)}>
            {customer && !customer?.loyaltyMember // not loyalty
              ? translate('customerLoyalty.notEnrolled')
              : customer?.createdAt // since
              ? translate('customerLoyalty.sinceDate', {
                  date: formatSinceDate(customer.createdAt),
                })
              : 'N/A'}
          </Text>
        </Gradient>
        {customer?.loyaltyMember ? (
          <>
            <ScrollView style={css(rewardContainerStyle)}>
              <View>
                {filteredRewards.length ? (
                  <>
                    {filteredRewards.map(reward => {
                      const multiSteps = getOptionalProperty(
                        reward,
                        'products',
                      );
                      const disabled = multiSteps
                        ? !hasViableProducts(reward)
                        : !hasEnoughBalance(reward);

                      return (
                        <RewardDisplay
                          key={reward.id}
                          reward={reward}
                          loyaltySettings={loyaltySettings}
                          onChange={onRewardChange}
                          quantity={countForQuantity(reward.id)}
                          disabled={disabled}
                          style={css(rewardItemStyle)}
                          multiSteps={multiSteps}
                          additionalInfo={getAdditionalInfo(reward)}
                        />
                      );
                    })}
                    {/* render placeholder in case rewards are lacking */}
                    {filteredRewards.length < 3
                      ? [...Array(3 - filteredRewards.length)].map(
                          (item, idx) => (
                            <View
                              key={idx}
                              style={[
                                css(rewardPlaceholderStyle),
                                css(rewardItemStyle),
                              ]}
                            />
                          ),
                        )
                      : null}
                  </>
                ) : (
                  <View style={[css(emptySectionStyle), css({ margin: 0 })]}>
                    <Text
                      testID="empty-reward"
                      style={css(emptySectionFontStyle)}
                    >
                      {translate('customerLoyalty.noRewardAvailable')}
                    </Text>
                  </View>
                )}
              </View>
            </ScrollView>
            <View style={css(buttonContainerStyle)}>
              <Button
                title={translate('customerLoyalty.redeemRewards')}
                success
                disabled={!allowRedeem}
                onPress={handleRedeem}
                testID="redeem"
              />
            </View>
          </>
        ) : (
          <View>
            <View style={css(emptySectionStyle)}>
              <Text style={css(emptySectionFontStyle)}>
                {translate('customerLoyalty.enrollToEarnAndRedeem')}
              </Text>
            </View>
            <View style={css(buttonContainerStyle)}>
              <Button
                title={translate('customerLoyalty.enrollCustomer')}
                success
                testID="enroll-loyalty"
                onPress={onEnroll}
                loading={enrolling}
              />
            </View>
          </View>
        )}
      </>
    </Modal>
  );
};

export default CustomerRewardModal;
