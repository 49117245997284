import React, { useState, useEffect, useCallback, useMemo } from 'react';
import {
  mapOnboardingUIModel,
  onboardingUtility,
  ONBOARDING_DISPLAY_UTIL,
} from '../../../state/onboardingUtility';
import { Subscription } from 'rxjs';
import { OnboardingNavigationRoutes } from '@oolio-group/domain';
import { useTranslation } from '@oolio-group/localization';
import { noopHandler } from '../../../utils/errorHandlers';
import { useNavigation } from '@react-navigation/native';
import { useSession } from '../../../hooks/app/useSession';
import theme from '../../../common/default-theme';
import ButtonActions, { Action } from '../../Shared/TreatButton/ButtonActions';

interface ActionList extends Action {
  isCompleted?: boolean;
}

const OnboardingCheckList: React.FC = () => {
  const { translate } = useTranslation();
  const navigation = useNavigation();
  const [session] = useSession();
  const [actionList, setActionList] = useState<ActionList[]>([]);

  const navigateTo = useCallback(
    (action: OnboardingNavigationRoutes) => {
      switch (action) {
        case OnboardingNavigationRoutes.NAVIGATE_TO_VENUE:
          if (session?.user?.venues) {
            navigation.navigate('Settings', {
              screen: 'VenueSettings',
              params: {
                venueId: session?.user?.venues[0].id,
              },
            });
          }
          break;
        case OnboardingNavigationRoutes.NAVIGATE_TO_STORE_REGISTER:
          if (session?.user?.venues && session?.user?.venues[0].stores) {
            navigation.navigate('Settings', {
              screen: 'StoreSettings',
              params: {
                screen: 'RegisterProfiles',
                venueId: session?.user?.venues[0].id,
                storeId: session?.user?.venues[0].stores[0].id,
              },
            });
          }
          break;
        case OnboardingNavigationRoutes.NAVIGATE_TO_TAXES:
          navigation.navigate('Settings', { screen: 'TaxesFees' });
          break;
        case OnboardingNavigationRoutes.NAVIGATE_TO_PAYMENT_TYPES:
          navigation.navigate('Settings', { screen: 'PaymentTypes' });
          break;
        case OnboardingNavigationRoutes.NAVIGATE_TO_PAGES:
          navigation.navigate('Products', { screen: 'Pages' });
          break;
        case OnboardingNavigationRoutes.NAVIGATE_TO_PRODUCTS:
          navigation.navigate('Products', { screen: 'Manage' });
          break;
        default:
          noopHandler();
      }
    },
    [navigation, session?.user?.venues],
  );

  useEffect(() => {
    const subscription: Subscription =
      onboardingUtility.getOnboardingInfo$.subscribe(onboarding => {
        if (onboarding) {
          const data = mapOnboardingUIModel(onboarding);
          if (Object.keys(data).length > 0) {
            const steps = Object.values(data)
              .flat()
              .map(section => {
                const isCompleted = section.checklist.every(
                  actionSet => actionSet.status === true,
                );
                return {
                  id: `${section.areaName}-${section.sectionKey}`,
                  ...section,
                  isCompleted,
                  label: translate(
                    ONBOARDING_DISPLAY_UTIL[section.sectionKey].translationKey,
                  ),
                  icon: isCompleted ? 'check' : 'angle-right',
                  iconColor: isCompleted
                    ? theme.colors.states.positive
                    : theme.colors.grey5,
                  action: () =>
                    navigateTo(
                      ONBOARDING_DISPLAY_UTIL[section.sectionKey].navigateTo,
                    ),
                };
              })
              .sort((s1, s2) => s1.order - s2.order);
            setActionList(steps);
          }
        }
      });

    return () => subscription.unsubscribe();
  }, [navigateTo, translate]);

  const allCompleted = useMemo(
    () => actionList.every(x => x.isCompleted === true),
    [actionList],
  );

  return !allCompleted ? (
    <ButtonActions
      type="positiveLight"
      label={translate('setupGuide.title')}
      actions={actionList}
      height={38}
      // eslint-disable-next-line react-native/no-inline-styles
      containerStyle={{ marginRight: 10 }}
    />
  ) : null;
};

export const SetupGuide = OnboardingCheckList;
