import React, { useCallback, useMemo } from 'react';
import { View, ScrollView, Text, FlatList } from 'react-native';
import { PreviewStyles } from '../../styles/Component.styles';
import { useCurrency, useTranslation } from '@oolio-group/localization';
import {
  ColumnType,
  DateRangeGranularity,
  DefaultPaymentTypes,
  PaymentTypeSaleSummary,
  VoidReason,
  WALK_IN_CUSTOMER,
} from '@oolio-group/domain';
import { convertDateByFormat, DAY_FORMAT } from '../../reportsHelper';
import { DisplayLabels, ShiftPreview } from '../../types';
import { useSession } from '../../../../../../src/hooks/app/useSession';
import { capitalCase } from 'change-case';
import startCase from 'lodash/startCase';
import { excludeOnAccountPaymentTypeSummary } from '@oolio-group/client-utils';
import { sumDecimals } from '@oolio-group/order-helper';

interface DataPreviewProps {
  previewData: ShiftPreview | undefined;
}

enum KeyPrefix {
  TRANSLATION = 'backOfficeShifts.rowPreview',
}

interface PreviewTableRow {
  key: string;
  subKey?: string;
  type: ColumnType;
  processedKey?: boolean;
  indent?: boolean;
  highlight?: boolean;
  addNegative?: boolean;
  bold?: boolean;
  hideIfEmpty?: boolean;
}

interface PreviewTable {
  name: string;
  rows: Array<PreviewTableRow | null>;
}

const TABLE_ONE: PreviewTableRow[] = [
  { key: 'shiftNumber', type: ColumnType.STRING },
  { key: 'closedAt', type: ColumnType.DATE },
  { key: 'shiftType', type: ColumnType.STRING },
];

const PAYMENT_ROW_KEYS: PreviewTableRow[] = [
  { key: 'salesCount', type: ColumnType.NUMBER },
  { key: 'amount', type: ColumnType.MONEY },
  { key: 'tips', type: ColumnType.MONEY, indent: true },
  {
    key: 'paymentSurcharge',
    type: ColumnType.MONEY,
    indent: true,
    hideIfEmpty: true,
  },
  {
    key: 'roundingAmount',
    type: ColumnType.MONEY,
    indent: true,
    hideIfEmpty: true,
  },
  {
    key: 'refundAmount',
    type: ColumnType.MONEY,
    indent: true,
    highlight: true,
    addNegative: true,
  },
  { key: 'totalCounted', type: ColumnType.MONEY },
  { key: 'recordedAmount', type: ColumnType.MONEY },
  { key: 'variance', type: ColumnType.MONEY, highlight: true },
  { key: 'moneyIn', type: ColumnType.MONEY, subKey: 'moneyInCount' },
  { key: 'moneyOut', type: ColumnType.MONEY, subKey: 'moneyOutCount' },
];

const TABLES_KEY = {
  salesSummary: 'Sales Summary',
  customers: 'Customers',
};
const CARD_AND_ONLINE = [DefaultPaymentTypes.CARD, DefaultPaymentTypes.ONLINE];
const CARD_AND_ONLINE_PAYMENT_ROW_KEYS: PreviewTableRow[] = [
  { key: 'salesCount', type: ColumnType.NUMBER },
  { key: 'amount', type: ColumnType.MONEY },
  { key: 'tips', type: ColumnType.MONEY, indent: true },
  {
    key: 'paymentSurcharge',
    type: ColumnType.MONEY,
    indent: true,
    hideIfEmpty: true,
  },
  {
    key: 'roundingAmount',
    type: ColumnType.MONEY,
    indent: true,
    hideIfEmpty: true,
  },
  {
    key: 'refundAmount',
    type: ColumnType.MONEY,
    indent: true,
    highlight: true,
    addNegative: true,
  },
  // FIXME: These values are incorrect as of now as they are estimated.
  // We will re-use them once we move to adyen balance.
  // {
  //   key: 'estimatedFees',
  //   type: ColumnType.MONEY,
  //   highlight: true,
  //   addNegative: true,
  // },
  // { key: 'netDeposit', type: ColumnType.MONEY },
];

const TABLES: PreviewTable[] = [
  {
    name: TABLES_KEY.salesSummary,
    rows: [
      { key: 'totalSalesCount', type: ColumnType.NUMBER },
      { key: 'transactionsCount', type: ColumnType.NUMBER },
      { key: 'refundCount', type: ColumnType.NUMBER },
      { key: 'totalGrossSales', type: ColumnType.MONEY },
      {
        key: 'totalGrossRefund',
        type: ColumnType.MONEY,
        indent: true,
        addNegative: true,
        highlight: true,
      },
      {
        key: 'totalDiscount',
        type: ColumnType.MONEY,
        indent: true,
        addNegative: true,
        highlight: true,
      },
      { key: 'totalSurcharge', type: ColumnType.MONEY, indent: true },
      { key: 'totalNetAmount', type: ColumnType.MONEY },
      { key: 'totalTax', type: ColumnType.MONEY },
      { key: 'totalSalesAmount', type: ColumnType.MONEY, bold: true },
      {
        key: 'totalRefundByAmount',
        type: ColumnType.MONEY,
        addNegative: true,
        highlight: true,
        hideIfEmpty: true,
      },
      { key: 'tips', type: ColumnType.MONEY },
      { key: 'totalRoundingAmount', type: ColumnType.MONEY },
      { key: 'totalPaymentSurcharge', type: ColumnType.MONEY },
      { key: 'otherCharges', type: ColumnType.MONEY },
      { key: 'totalAmount', type: ColumnType.MONEY, bold: true },
      { key: 'salesAverage', type: ColumnType.MONEY },
    ],
  },
  {
    name: TABLES_KEY.customers,
    rows: [
      { key: 'walkIn', type: ColumnType.NUMBER, processedKey: true },
      { key: 'registered', type: ColumnType.NUMBER, processedKey: true },
    ],
  },
];

export const DataPreview: React.FC<DataPreviewProps> = ({ previewData }) => {
  const styles = PreviewStyles();
  const { translate } = useTranslation();
  const { formatCurrency } = useCurrency();
  const [session] = useSession();

  const getObjPath = useCallback((obj: object | string, path: string) => {
    return path.split('.').reduce((o, i) => {
      if (o !== undefined && o[i as keyof object] !== undefined)
        return o[i as keyof object];
      else return '';
    }, obj);
  }, []);

  const convertNumberToText = (text: string | number) => {
    return text !== null && text !== '' && !isNaN(text as number)
      ? (text as string)
      : '0.00';
  };

  const getTextByColumn = useCallback(
    (text, type, highlight = false, addNegative = false) => {
      let toReturn: string;
      const sign = addNegative ? -1 : 1;
      switch (type) {
        case ColumnType.MONEY:
          const value = Number(text) * sign;
          toReturn = formatCurrency(value);
          // add positive sign (+) if its a highlighted column with positive value
          toReturn = highlight && value > 0 ? `+${toReturn}` : toReturn;
          break;
        case ColumnType.NUMBER:
          toReturn = convertNumberToText(text * sign);
          break;
        case ColumnType.DATE:
          toReturn = text
            ? convertDateByFormat(
                new Date(text).toISOString(),
                DateRangeGranularity.DAY,
                DAY_FORMAT,
                session.currentOrganization?.timezone,
              )
            : DisplayLabels.SHIFT_NOT_CLOSED;
          break;
        default:
          toReturn = text || '';
          break;
      }
      return toReturn;
    },
    [formatCurrency, session.currentOrganization?.timezone],
  );

  const taxesTotals = useMemo(() => {
    return {
      name: 'All Taxes',
      amount: previewData?.taxes.reduce((a, t) => a + t.amount, 0),
    };
  }, [previewData?.taxes]);

  const salesByHourTotal: string = useMemo(() => {
    return previewData?.store.showHourlySplit
      ? getTextByColumn(
          previewData?.salesByHour?.reduce(
            (prevValue, currentKey) => prevValue + currentKey.amount,
            0,
          ),
          ColumnType.MONEY,
        )
      : '';
  }, [
    previewData?.store.showHourlySplit,
    getTextByColumn,
    previewData?.salesByHour,
  ]);

  const onAccountSalesTotal = useMemo(() => {
    return getTextByColumn(
      previewData?.onAccountSales?.Sales?.reduce(
        (accumulator, s) => sumDecimals([accumulator, s.amount]),
        0,
      ) ?? 0,
      ColumnType.MONEY,
    );
  }, [previewData?.onAccountSales?.Sales, getTextByColumn]);

  const onAccountPaymentTotal = useMemo(() => {
    return getTextByColumn(
      previewData?.onAccountSales?.Payments?.reduce(
        (accumulator, s) => sumDecimals([accumulator + s.amount]),
        0,
      ) ?? 0,
      ColumnType.MONEY,
    );
  }, [previewData?.onAccountSales?.Payments, getTextByColumn]);

  const processed = useMemo(() => {
    const toReturn = {
      walkIn: 0,
      registered: 0,
    };

    if (previewData?.customers) {
      const notRegisteredIndex = previewData?.customers.findIndex(
        customer => customer.id === WALK_IN_CUSTOMER,
      );
      if (notRegisteredIndex !== -1) {
        toReturn.walkIn = previewData?.customers[notRegisteredIndex].count;
      }
      toReturn.registered = previewData?.customers.reduce(
        (acc, customer, index) => {
          if (index === notRegisteredIndex) return acc;
          else return acc + customer.count;
        },
        0,
      );
    }

    return toReturn;
  }, [previewData?.customers]);

  const productTypeTotals = useMemo(() => {
    return {
      name: 'All Product Types',
      amount: previewData?.salesByProductType?.reduce(
        (a, t) => a + t.amount,
        0,
      ),
    };
  }, [previewData?.salesByProductType]);

  const salesByPaymentType = useMemo(() => {
    return excludeOnAccountPaymentTypeSummary(
      previewData?.salesByPaymentType ?? [],
    ) as PaymentTypeSaleSummary[];
  }, [previewData?.salesByPaymentType]);

  const getAdditionalStyle = (data: object | string, row: PreviewTableRow) => {
    if (row.highlight) {
      const rowData = getObjPath(data, row.key);
      const sign = row.addNegative ? -1 : 1;
      return [
        Number(rowData) * sign <= 0
          ? styles.redCellStyle
          : styles.greenCellStyle,
      ];
    } else if (row.bold) {
      return [styles.boldCellStyle];
    }
    return [];
  };

  return (
    <ScrollView style={styles.viewStyle}>
      {previewData && (
        <>
          <View
            testID={'preview-table'}
            key={'preview-table-one'}
            style={styles.firstTableStyle}
          >
            {TABLE_ONE.map((row, rowIndex) => (
              <View
                key={`preview-table-one-row-${rowIndex}`}
                style={styles.rowStyle}
              >
                <View style={styles.cellStyle}>
                  <Text style={styles.textStyle}>
                    {translate(`${KeyPrefix.TRANSLATION}.${row.key}`)}
                  </Text>
                </View>
                <View style={styles.rightAlignCellStyle}>
                  <Text style={[styles.textStyle, styles.valueStyle]}>
                    {getTextByColumn(
                      getObjPath(previewData, row.key),
                      row.type,
                    )}
                  </Text>
                </View>
              </View>
            ))}
          </View>
          {salesByPaymentType.map((paymentData, paymentIndex) => (
            <View
              testID={'preview-table'}
              key={`payment-table-${paymentIndex}`}
              style={styles.tableStyle}
            >
              <View
                key={`payment-table-${paymentIndex}-heading`}
                style={styles.rowStyle}
              >
                <View style={styles.cellStyle}>
                  <Text style={styles.headerStyle}>
                    {`${getObjPath(paymentData, 'paymentType.name')} Payments`}
                  </Text>
                </View>
              </View>
              {(CARD_AND_ONLINE.includes(
                paymentData.paymentType.name as DefaultPaymentTypes,
              )
                ? CARD_AND_ONLINE_PAYMENT_ROW_KEYS
                : PAYMENT_ROW_KEYS
              )
                .filter(row => {
                  if (!row.hideIfEmpty) return true;

                  if (
                    parseFloat(getObjPath(paymentData, row.key) as string) !== 0
                  )
                    return true;
                })
                .map((row, rowIndex) => (
                  <View
                    key={`payment-table-${paymentIndex}-row-${rowIndex}`}
                    style={styles.rowStyle}
                  >
                    <View style={styles.cellStyle}>
                      <Text style={styles.textStyle}>
                        {row.indent && '–– '}
                        {translate(`${KeyPrefix.TRANSLATION}.${row.key}`)}
                        {row.subKey !== undefined &&
                          ` (x${getTextByColumn(
                            getObjPath(paymentData, row.subKey),
                            ColumnType.NUMBER,
                          )})`}
                      </Text>
                    </View>
                    <View style={styles.rightAlignCellStyle}>
                      <Text
                        style={[
                          styles.textStyle,
                          styles.valueStyle,
                          ...getAdditionalStyle(paymentData, row),
                        ]}
                      >
                        {getTextByColumn(
                          getObjPath(paymentData, row.key),
                          row.type,
                          row.highlight,
                          row.addNegative,
                        )}
                      </Text>
                    </View>
                  </View>
                ))}
            </View>
          ))}
          {previewData?.taxes.length ? (
            <View
              testID={'preview-table'}
              key={'tax-table-one'}
              style={styles.tableStyle}
            >
              <View key={'tax-table-heading'} style={styles.rowStyle}>
                <View style={styles.cellStyle}>
                  <Text style={styles.headerStyle}>
                    {translate('backOfficeShiftsReport.TaxSummary')}
                  </Text>
                </View>
              </View>
              {previewData?.taxes.map((taxData, taxIndex) => (
                <View
                  key={`tax-table-${taxIndex}-row-${taxIndex}`}
                  style={styles.rowStyle}
                >
                  <View style={styles.cellStyle}>
                    <Text style={styles.textStyle}>
                      {taxData.tax.name}
                      {taxData.tax.rate ? ` (${taxData.tax.rate}%)` : ''}
                    </Text>
                  </View>
                  <View style={styles.rightAlignCellStyle}>
                    <Text style={[styles.textStyle, styles.valueStyle]}>
                      {getTextByColumn(taxData.amount, ColumnType.MONEY)}
                    </Text>
                  </View>
                </View>
              ))}
              {previewData?.taxes.length > 1 && (
                <View key={'tax-table-total-row'} style={styles.rowStyle}>
                  <View style={styles.cellStyle}>
                    <Text>{taxesTotals.name}</Text>
                  </View>
                  <View style={styles.rightAlignCellStyle}>
                    <Text style={[styles.textStyle, styles.valueStyle]}>
                      {getTextByColumn(taxesTotals.amount, ColumnType.MONEY)}
                    </Text>
                  </View>
                </View>
              )}
            </View>
          ) : (
            <></>
          )}

          {TABLES.map((table, tableIndex) => (
            <View
              testID={'preview-table'}
              key={`preview-table-${tableIndex}`}
              style={styles.tableStyle}
            >
              <View
                key={`preview-table-${tableIndex}-heading`}
                style={styles.rowStyle}
              >
                <View style={styles.cellStyle}>
                  <Text style={styles.headerStyle}>{table.name}</Text>
                </View>
              </View>

              {table.rows
                .filter(
                  row =>
                    !row?.hideIfEmpty ||
                    (row?.hideIfEmpty &&
                      previewData[row.key as keyof ShiftPreview]),
                )
                .map((row, rowIndex) =>
                  row ? (
                    <View
                      key={`preview-table-${tableIndex}-row-${rowIndex}`}
                      style={styles.rowStyle}
                    >
                      <>
                        <View style={styles.cellStyle}>
                          <Text
                            style={[
                              styles.textStyle,
                              ...getAdditionalStyle(previewData, row),
                            ]}
                          >
                            {row.indent == true && '–– '}
                            {translate(`${KeyPrefix.TRANSLATION}.${row.key}`)}
                          </Text>
                        </View>
                        <View style={styles.rightAlignCellStyle}>
                          <Text
                            style={[
                              styles.textStyle,
                              styles.valueStyle,
                              ...getAdditionalStyle(previewData, row),
                            ]}
                          >
                            {getTextByColumn(
                              getObjPath(
                                row.processedKey ? processed : previewData,
                                row.key,
                              ),
                              row.type,
                              row.highlight,
                              row.addNegative,
                            )}
                          </Text>
                        </View>
                      </>
                    </View>
                  ) : (
                    <View
                      key={`preview-table-${tableIndex}-row-${rowIndex}`}
                      style={[styles.rowStyle, styles.gapRowStyle]}
                    />
                  ),
                )}
              {table.name !== TABLES_KEY.customers &&
                previewData['totalCostPrice'] > 0 && (
                  <>
                    <View
                      key={'preview-table-cost-price-row'}
                      style={styles.rowStyle}
                    >
                      <View style={styles.cellStyle}>
                        <Text style={styles.textStyle}>
                          {translate(`${KeyPrefix.TRANSLATION}.totalCostPrice`)}
                        </Text>
                      </View>
                      <View style={styles.rightAlignCellStyle}>
                        <Text style={[styles.textStyle, styles.valueStyle]}>
                          {getTextByColumn(
                            getObjPath(previewData, 'totalCostPrice'),
                            ColumnType.MONEY,
                          )}
                        </Text>
                      </View>
                    </View>
                    <View
                      key={'preview-table-profit-row'}
                      style={styles.rowStyle}
                    >
                      <View style={styles.cellStyle}>
                        <Text style={styles.textStyle}>
                          {translate(`${KeyPrefix.TRANSLATION}.profit`)}
                        </Text>
                      </View>
                      <View style={styles.rightAlignCellStyle}>
                        <Text style={[styles.textStyle, styles.valueStyle]}>
                          {getTextByColumn(
                            getObjPath(previewData, 'profit'),
                            ColumnType.MONEY,
                          )}
                        </Text>
                      </View>
                    </View>
                  </>
                )}
            </View>
          ))}
          {!!previewData.discountByReward &&
          previewData.discountByReward?.length > 0 ? (
            <View
              testID={'preview-table-discount-by-reward'}
              key={'discount-by-reward-table-one'}
              style={styles.tableStyle}
            >
              <View
                key={'discount-by-reward-table-heading'}
                style={styles.rowStyle}
              >
                <View style={styles.cellStyle}>
                  <Text style={styles.headerStyle}>
                    {translate('backOfficeShiftsReport.Discounts')}
                  </Text>
                </View>
              </View>
              {!!previewData.discountByReward &&
              previewData.discountByReward?.length > 0
                ? previewData.discountByReward?.map((discount, typeIndex) => (
                    <View
                      key={`discount-by-reward-table-${typeIndex}-row-${typeIndex}`}
                      style={styles.rowStyle}
                    >
                      <View style={styles.cellStyle}>
                        <Text style={styles.textStyle}>
                          {`${discount.reward.rewardName} (${discount.quantity})`}
                        </Text>
                      </View>
                      <View style={styles.rightAlignCellStyle}>
                        <Text style={[styles.textStyle, styles.valueStyle]}>
                          {getTextByColumn(discount.amount, ColumnType.MONEY)}
                        </Text>
                      </View>
                    </View>
                  ))
                : null}
            </View>
          ) : null}
          {previewData?.voidedAmountByReason.length > 0 && (
            <View
              testID={'preview-table'}
              key={'voided-amount-by-reason-table-one'}
              style={styles.tableStyle}
            >
              <View
                key={'voided-amount-by-reason-table-heading'}
                style={styles.rowStyle}
              >
                <View style={styles.cellStyle}>
                  <Text style={styles.headerStyle}>
                    {translate('backOfficeShiftsReport.VoidSummary')}
                  </Text>
                </View>
              </View>
              {previewData?.voidedAmountByReason.map((voidData, typeIndex) => (
                <View
                  key={`voided-amount-by-reason-table-${typeIndex}-row-${typeIndex}`}
                  style={styles.rowStyle}
                >
                  <View style={styles.cellStyle}>
                    <Text style={styles.textStyle}>
                      {capitalCase(voidData.reason ?? VoidReason.OTHER)}
                    </Text>
                  </View>
                  <View style={styles.rightAlignCellStyle}>
                    <Text style={[styles.textStyle, styles.valueStyle]}>
                      {getTextByColumn(voidData.amount, ColumnType.MONEY)}
                    </Text>
                  </View>
                </View>
              ))}
            </View>
          )}
          {previewData?.salesByProductType?.length ? (
            <View
              testID={'preview-table'}
              key={'product-type-table-one'}
              style={styles.tableStyle}
            >
              <View key={'product-type-table-heading'} style={styles.rowStyle}>
                <View style={styles.cellStyle}>
                  <Text style={styles.headerStyle}>Product Type Summary</Text>
                </View>
              </View>
              {previewData?.salesByProductType.map((productData, typeIndex) => (
                <View
                  key={`product-type-table-${typeIndex}-row-${typeIndex}`}
                  style={styles.rowStyle}
                >
                  <View style={styles.cellStyle}>
                    <Text style={styles.textStyle}>
                      {productData.productType.name}
                      {` (x${Number(
                        getTextByColumn(productData.count, ColumnType.NUMBER),
                      ).toFixed(2)})`}
                    </Text>
                  </View>
                  <View style={styles.rightAlignCellStyle}>
                    <Text style={[styles.textStyle, styles.valueStyle]}>
                      {getTextByColumn(productData.amount, ColumnType.MONEY)}
                    </Text>
                  </View>
                </View>
              ))}
              {previewData?.salesByProductType.length > 1 && (
                <View
                  key={'product-type-table-total-row'}
                  style={styles.rowStyle}
                >
                  <View style={styles.cellStyle}>
                    <Text style={styles.textStyle}>
                      {productTypeTotals.name}
                    </Text>
                  </View>
                  <View style={styles.rightAlignCellStyle}>
                    <Text style={[styles.textStyle, styles.valueStyle]}>
                      {getTextByColumn(
                        productTypeTotals.amount,
                        ColumnType.MONEY,
                      )}
                    </Text>
                  </View>
                </View>
              )}
            </View>
          ) : (
            <></>
          )}

          {previewData.onAccountSales?.Payments?.length ||
          previewData.onAccountSales?.Sales?.length ? (
            <View
              testID={'preview-table'}
              key={'shift-summary-on-account-table-two'}
              style={styles.tableStyle}
            >
              <View
                key={'shift-summary-on-account-table-heading'}
                style={styles.rowStyle}
              >
                <View style={styles.cellStyle}>
                  <Text style={styles.headerStyle}>
                    {translate('backOfficeShifts.rowPreview.onAccountSale')}
                  </Text>
                </View>
              </View>

              {previewData.onAccountSales?.Sales?.length ? (
                <>
                  <View
                    key={'shift-summary-on-account-table-heading'}
                    style={[styles.rowStyle, styles.verticalRowStyle]}
                  >
                    <View style={styles.cellStyle}>
                      <Text style={styles.headerStyle}>
                        {`${translate(
                          'backOfficeShifts.rowPreview.onAccountSaleSummary',
                        )} (✗${previewData.onAccountSales.Sales.length})`}
                      </Text>
                    </View>
                    <View style={styles.rightAlignCellStyle}>
                      <Text style={styles.headerStyle}>
                        {onAccountSalesTotal}
                      </Text>
                    </View>
                  </View>

                  <FlatList
                    data={previewData.onAccountSales.Sales}
                    renderItem={({ item, index }) => {
                      return (
                        <View
                          key={`shift-summary-on-account-table-${index}`}
                          style={styles.rowStyle}
                        >
                          <View style={styles.cellStyle}>
                            <Text style={styles.textStyle}>
                              {startCase(item.customer.name)}
                            </Text>
                          </View>
                          <View style={styles.rightAlignCellStyle}>
                            <Text style={[styles.textStyle, styles.valueStyle]}>
                              {getTextByColumn(item.amount, ColumnType.MONEY)}
                            </Text>
                          </View>
                        </View>
                      );
                    }}
                  />
                </>
              ) : (
                <></>
              )}

              {previewData.onAccountSales?.Payments?.length ? (
                <>
                  <View
                    key={'shift-summary-on-account-table-heading'}
                    style={[styles.rowStyle, styles.verticalRowStyle]}
                  >
                    <View style={styles.cellStyle}>
                      <Text style={styles.headerStyle}>
                        {`${translate(
                          'backOfficeShifts.rowPreview.onAccountPaymentSummary',
                        )} (✗${previewData.onAccountSales?.Payments?.length})`}
                      </Text>
                    </View>
                    <View style={styles.rightAlignCellStyle}>
                      <Text style={styles.headerStyle}>
                        {onAccountPaymentTotal}
                      </Text>
                    </View>
                  </View>

                  <FlatList
                    data={previewData.onAccountSales.Payments}
                    renderItem={({ item, index }) => {
                      return (
                        <View
                          key={`shift-summary-on-account-table-${index}`}
                          style={styles.rowStyle}
                        >
                          <View style={styles.cellStyle}>
                            <Text style={styles.textStyle}>
                              {startCase(item.customer.name)}
                              {` (${item.paymentType.name})`}
                            </Text>
                          </View>
                          <View style={styles.rightAlignCellStyle}>
                            <Text style={[styles.textStyle, styles.valueStyle]}>
                              {getTextByColumn(item.amount, ColumnType.MONEY)}
                            </Text>
                          </View>
                        </View>
                      );
                    }}
                  />
                </>
              ) : (
                <></>
              )}
            </View>
          ) : (
            <></>
          )}

          {previewData.store?.showHourlySplit &&
          previewData?.salesByHour?.length > 0 ? (
            <View
              testID={'preview-table'}
              key={'shift-summary-hour-table-two'}
              style={styles.tableStyle}
            >
              <View
                key={'shift-summary-hour-table-heading'}
                style={styles.rowStyle}
              >
                <View style={styles.cellStyle}>
                  <Text style={styles.headerStyle}>
                    {translate('backOfficeShifts.rowPreview.hourlySummary')}
                  </Text>
                </View>
              </View>
              <FlatList
                data={previewData.salesByHour}
                renderItem={({ item, index }) => {
                  return (
                    <View
                      key={`shift-summary-table-${index}`}
                      style={styles.rowStyle}
                    >
                      <View style={styles.cellStyle}>
                        <Text style={styles.textStyle}>
                          {item.hour}
                          {` (x${item.count})`}
                        </Text>
                      </View>
                      <View style={styles.rightAlignCellStyle}>
                        <Text style={[styles.textStyle, styles.valueStyle]}>
                          {getTextByColumn(item.amount, ColumnType.MONEY)}
                        </Text>
                      </View>
                    </View>
                  );
                }}
                keyExtractor={item => item.hour}
              />
              <View
                key={'shift-summary-table-total-row-heading'}
                style={styles.rowStyle}
              >
                <View style={styles.cellStyle}>
                  <Text style={[styles.textStyle, styles.boldCellStyle]}>
                    {translate('backOfficeShifts.rowPreview.totalAmount')}
                  </Text>
                </View>
                <View style={styles.rightAlignCellStyle}>
                  <Text style={styles.headerStyle}>{salesByHourTotal}</Text>
                </View>
              </View>
            </View>
          ) : (
            <></>
          )}
        </>
      )}
    </ScrollView>
  );
};
