import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import LoadingIndicator from '../../../../components/LoadingIndicator/LoadingIndicator';
import { Helmet } from 'react-helmet';
import { Styles } from '../styles/ProductsSummary.styles';
import { View, ScrollView } from 'react-native';
import { useTranslation } from '@oolio-group/localization';
import {
  DateRangeFilter,
  Filters,
  FilterValue,
  Widget,
  WidgetChartType,
} from '@oolio-group/domain';
import { ReportHeader } from '../UIComponents/ReportHeader';
import { Table, TableRef } from '../UIComponents/Table';
import { Filters as ReportFilter } from '../UIComponents/Filters';
import { PieChart } from '../UIComponents/PieChart';
import { MultiBarChart } from '../UIComponents/MultiBarChart';
import {
  GRANULARITY_FORMATS,
  MULTI_BAR_COLOR_SCALE,
  transformFieldsToTableColumn,
} from '../reportsHelper';
import {
  LineChartType,
  ReportTableColumn,
  IMap,
  ExportType,
  HelperText,
  PivotTableData,
  Search,
  DropDownFilter,
} from '../types';
import { CubejsApi } from '@cubejs-client/core';
interface ReportProps {
  options: { loading: boolean };
  filterOptions: DropDownFilter[];
  filters: FilterValue;
  search: Search;
  widgets: Widget[];
  dateRangeFilter: DateRangeFilter;
  updateFilters: (filter: string, value: string[]) => void;
  updateSearch: (filter: string, value: string) => void;
  resetFilters: () => void;
  updateReport: () => void;
  updateDateRangeFilters: (value: DateRangeFilter) => void;
  cubejsApi: CubejsApi;
  allFilters: Filters;
  updateCount: number;
}

const TABLE_GRANULARITY_FORMATS: IMap<string> = {
  ...GRANULARITY_FORMATS,
  day: 'MMM dd, yyyy',
};

export const SalesByStaffReport: React.FC<ReportProps> = ({
  options: { loading },
  filters,
  filterOptions,
  widgets,
  dateRangeFilter,
  search,
  updateFilters,
  updateSearch,
  updateReport,
  resetFilters,
  updateDateRangeFilters,
  cubejsApi,
  allFilters,
  updateCount,
}) => {
  const { translate } = useTranslation();
  const styles = Styles();
  const [showFilters, setShowFilters] = useState<boolean>(false);
  const [selectedColumns, setSelectedColumns] = useState<ReportTableColumn[]>(
    [],
  );

  const toggleFilters = useCallback(() => {
    setShowFilters(value => !value);
  }, []);

  const tableWidget = useMemo(() => {
    return (
      widgets.find(widget => widget.chartType === WidgetChartType.TABLE) ||
      ({} as Widget)
    );
  }, [widgets]);

  const tableColumns: ReportTableColumn[] = useMemo(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const columns: any[] = [];
    if (tableWidget.query?.dimensions)
      columns.push(
        ...transformFieldsToTableColumn(tableWidget.query?.dimensions),
      );

    if (tableWidget.query?.measures)
      columns.push(
        ...transformFieldsToTableColumn(tableWidget.query?.measures),
      );
    return columns;
  }, [tableWidget.query]);

  const tableWidgetRef = useRef<TableRef>({} as TableRef);
  const exportReport = useCallback((type = ExportType.CSV) => {
    if (type === ExportType.CSV)
      tableWidgetRef.current.exportData &&
        tableWidgetRef.current.exportData(
          `StaffSummaryReport-${Date.now()}.csv`,
        );
  }, []);

  const tableDataTransformationFn = useCallback(
    (
      pivotTableData: PivotTableData,
      columns: ReportTableColumn[],
    ): PivotTableData => {
      const calculateTotals = [
        { title: 'salesByStaff.salesPercentage', from: 'Orders.salesCount' },
        { title: 'salesByStaff.profitsPercentage', from: 'Orders.profit' },
      ];

      const totalsObj: IMap<{ dataKey: string; total: number }> = {};

      calculateTotals.forEach(total => {
        const totalCol = columns.find(col => col.title === total.title);
        totalsObj[total.title] = {
          dataKey: totalCol?.dataKey as string,
          total: 0,
        };
      });

      pivotTableData.forEach(tableRow => {
        calculateTotals.forEach(total => {
          const { title, from } = total;
          totalsObj[title].total =
            (totalsObj[title].total as number) + +tableRow[from];
        });
      });

      return pivotTableData.map(tableRow => {
        calculateTotals.forEach(totalObj => {
          const { title, from } = totalObj;
          const { dataKey, total } = totalsObj[title];

          if (!+tableRow[from] && !total) {
            tableRow[dataKey] = (0).toFixed(2);
          } else {
            tableRow[dataKey] = (
              ((tableRow[from] as number) / total) *
              100
            ).toFixed(2);
          }
        });
        return tableRow;
      });
    },
    [],
  );

  useEffect(() => {
    setSelectedColumns(
      (tableColumns || []).filter(column => column.showByDefault),
    );
  }, [tableColumns]);

  const updateColumns = useCallback(
    (columnKeys: string[]) => {
      setSelectedColumns(
        tableColumns.filter(col => columnKeys.includes(col.title)),
      );
    },
    [tableColumns],
  );

  return (
    <>
      <Helmet>
        <title>
          {translate('navigation.generalSettingsPageTitle', {
            appName: translate('appName'),
          })}
        </title>
      </Helmet>

      <ScrollView scrollEnabled={!showFilters} style={styles.pageStyle}>
        {showFilters ? (
          <View style={styles.filtersStyle}>
            <ReportFilter
              toggleFilters={toggleFilters}
              filterOptions={filterOptions}
              filters={filters}
              updateFilters={updateFilters}
              resetFilters={resetFilters}
            ></ReportFilter>
          </View>
        ) : null}
        <View style={styles.mainSectionStyle}>
          <View style={styles.headersStyle}>
            <ReportHeader
              allFilters={allFilters}
              toggleFilters={toggleFilters}
              updateReport={updateReport}
              exportReport={exportReport}
              filters={filters}
              search={search}
              updateSearch={updateSearch}
              columns={{ all: tableColumns, updateColumns, selectedColumns }}
              updateFilters={updateFilters}
              dateRangeFilter={dateRangeFilter}
              updateDateRangeFilters={updateDateRangeFilters}
              translationParentKey={'salesByStaff'}
            />
          </View>
          <View style={styles.chartRowStyle}>
            {loading ? (
              <LoadingIndicator />
            ) : (
              <>
                {widgets
                  .filter(widget => widget.chartType !== WidgetChartType.TABLE)
                  .map(widget => {
                    let renderComp;
                    switch (widget.chartType) {
                      case WidgetChartType.PIE:
                        const pieKeys = {
                          name: widget.query.dimensions[0].key,
                          value: widget.query.measures[0].key,
                        };
                        renderComp = (
                          <PieChart
                            widget={widget}
                            helper={HelperText.SALES_BY_STAFF}
                            keys={pieKeys}
                            generateTotal={true}
                            columnType={widget.query.measures[0]?.type}
                            cubejsApi={cubejsApi}
                            updateCount={updateCount}
                          />
                        );
                        break;
                      case WidgetChartType.BAR:
                        const lineKeys = widget.query.measures.map(measure => ({
                          name: `${widget.query.dimensions[0].key}`,
                          value: measure.key,
                          text: translate(measure.text),
                        }));
                        renderComp = (
                          <MultiBarChart
                            widget={widget}
                            helper={HelperText.SALES_BY_STAFF}
                            keys={lineKeys}
                            type={LineChartType.BASIC}
                            colorScale={MULTI_BAR_COLOR_SCALE}
                            cubejsApi={cubejsApi}
                            updateCount={updateCount}
                          />
                        );
                        break;
                    }

                    return renderComp;
                  })}
              </>
            )}
          </View>
          <View style={styles.tableStyle}>
            {loading ? (
              <LoadingIndicator />
            ) : (
              <Table
                widget={tableWidget}
                columns={selectedColumns}
                helper={HelperText.SALES_BY_STAFF}
                pageSize={5}
                granularityFormats={TABLE_GRANULARITY_FORMATS}
                ref={tableWidgetRef}
                filters={filters}
                dataTransformationFn={tableDataTransformationFn}
                cubejsApi={cubejsApi}
                updateCount={updateCount}
              />
            )}
          </View>
        </View>
      </ScrollView>
    </>
  );
};

export default SalesByStaffReport;
