import React, { useMemo } from 'react';
import { Text, View } from 'react-native';
import MenuScheduleRow from './MenuScheduleRow';
import commonStyles from './MenuSchedules.styles';
import { useTranslation } from '@oolio-group/localization';
import { Catalogue, CatalogueScheduleItem } from '@oolio-group/domain';
import { FilterValue } from './MenuSchedules';
import theme from '../../../../../common/default-theme';

interface MenuScheduleListProps {
  menuSchedules: Record<string, CatalogueScheduleItem>;
  onUpdateActiveMenu?: (id: string) => void;
  actives?: Record<string, { active: boolean }>;
  filter: FilterValue;
  menus: Record<string, Catalogue>;
}

const MenuScheduleList: React.FC<MenuScheduleListProps> = ({
  menuSchedules,
  onUpdateActiveMenu,
  actives,
  filter,
  menus,
}) => {
  const { translate } = useTranslation();

  const menuSchedulesMap = useMemo(() => {
    return Object.values(menuSchedules)
      .filter(menuSchedule => {
        const checks = [
          filter.store === 'all'
            ? true
            : menuSchedule.stores.map(store => store.id).includes(filter.store),
          filter.menu === 'all' ? true : menuSchedule.menu === filter.menu,
        ];
        return checks.length === 0 || checks.every(check => check === true)
          ? true
          : false;
      })
      .map(menuSchedule => ({
        ...menuSchedule,
        isActive: actives?.[menuSchedule.id]?.active,
      }))
      .sort((a, b) => b.createdAt - a.createdAt);
  }, [actives, menuSchedules, filter?.menu, filter?.store]);

  const menuOptions = useMemo(() => {
    return Object.values(menus).map(menu => ({
      value: menu.id,
      label: menu.name,
    }));
  }, [menus]);

  return (
    <View>
      <View style={theme.tables.header}>
        <Text
          style={[
            theme.tables.headerText,
            commonStyles.listScheduleActiveHeader,
          ]}
        >
          {translate('common.active')}
        </Text>
        <Text
          style={[theme.tables.headerText, commonStyles.listScheduleMenuHeader]}
        >
          {translate('menus.menu')}
        </Text>
        <Text
          style={[
            theme.tables.headerText,
            commonStyles.listScheduleOrderTypesHeader,
          ]}
        >
          {translate('backOfficeProducts.orderTypes')}
        </Text>
        <Text
          style={[
            theme.tables.headerText,
            commonStyles.listScheduleStoresHeader,
          ]}
        >
          {translate('backOfficeProducts.stores')}
        </Text>
      </View>
      <View>
        {menuSchedulesMap.map(menuSchedule => {
          return (
            <MenuScheduleRow
              key={menuSchedule.id}
              menuSchedule={menuSchedule}
              menuOptions={menuOptions}
              onUpdateActiveMenu={onUpdateActiveMenu}
            />
          );
        })}
      </View>
    </View>
  );
};

export default MenuScheduleList;
