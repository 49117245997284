import {
  Order,
  OrderStatus,
  DateFilter,
  IntegrationApps,
} from '@oolio-group/domain';
import orderBy from 'lodash/orderBy';
import { OnlineOrdersDataProps } from '../screens/POS/Orders/OnlineOrders/Table/OnlineOrdersTable';

export const mapOrderToOnlineOrdersView = (
  order: Order,
): OnlineOrdersDataProps => ({
  id: order?.id || '',
  orderNumber: order.orderNumber,
  orderItems: order.orderItems,
  createdAt: order.createdAt,
  updatedAt: order.updatedAt || order.createdAt,
  customer: order?.customer?.firstName
    ? order.customer.firstName + ' ' + order.customer.lastName
    : 'N/A',
  age: '00:00',
  channel: order?.salesChannel?.name || '',
  type: order?.orderType?.name || '',
  orderTypeCode: order?.orderType?.code || '',
  amount: order.totalPaymentAmount,
  status: order.status,
  requiredAt: order?.requiredAt || order.createdAt,
  ...(order?.amountDue && { amountDue: order?.amountDue }),
  ...(order?.orderName && { orderName: order?.orderName }),
});

export const isValidOnlineOrder = (order: Order): boolean => {
  if (order.integrationInfo?.app !== IntegrationApps.OOLIO_STORE) return true;

  if (order.status === OrderStatus.DRAFT) return false;
  return true;
};

export const filterOnlineOrders = (
  orders: Order[],
  filter: {
    searchValue: string;
    status: string;
    day: string;
    salesChannel: string;
  },
): OnlineOrdersDataProps[] => {
  const { searchValue, status, day: selectedDay, salesChannel } = filter;
  return orderBy(
    orders
      .filter(eachOrder => {
        let isStatusQualified = [
          OrderStatus.IN_PROGRESS,
          OrderStatus.COMPLETED,
          OrderStatus.CREATED,
          OrderStatus.CANCELLED,
          OrderStatus.PARTNER_CANCELLED,
        ].includes(eachOrder.status);

        if (!isValidOnlineOrder(eachOrder)) {
          return false;
        }

        let isQualifiedDate = false;

        let isQualifiedSalesChannel = true;

        if (status) {
          isStatusQualified = status === eachOrder.status;
        }

        const dayStart = new Date().setHours(0, 0, 1);
        const endOfDay = new Date().setHours(23, 59, 59, 999);

        if (selectedDay === DateFilter.TODAY) {
          const orderTime = eachOrder.requiredAt || eachOrder.createdAt;
          isQualifiedDate =
            (orderTime && dayStart <= orderTime && orderTime <= endOfDay) ||
            false;
        }

        if (selectedDay === DateFilter.UPCOMING) {
          const orderTime = eachOrder.requiredAt;
          isQualifiedDate = (orderTime && orderTime > endOfDay) || false;
        }

        if (selectedDay === DateFilter.OLDER) {
          const orderTime = eachOrder.requiredAt || eachOrder.createdAt;
          isQualifiedDate = (orderTime && orderTime < dayStart) || false;
        }

        if (salesChannel) {
          isQualifiedSalesChannel =
            eachOrder?.salesChannel?.id === salesChannel;
        }

        if (isStatusQualified && isQualifiedDate && isQualifiedSalesChannel) {
          if (searchValue) {
            // query might be customer name, phone , product name, till id, partner id

            const query = searchValue.toLowerCase();

            const nameMatch =
              `${eachOrder.customer?.firstName} ${eachOrder.customer?.lastName}`
                ?.toLowerCase()
                .includes(query);

            if (nameMatch) {
              return true;
            }

            const phoneMatch = eachOrder?.customer?.phone?.includes(query);

            if (phoneMatch) {
              return true;
            }

            const productNameMatch = eachOrder.orderItems.find(eachItem =>
              eachItem?.product?.name?.toLocaleLowerCase()?.includes(query),
            );

            if (productNameMatch) {
              return true;
            }

            const onlineOrderIdMatch =
              eachOrder?.integrationInfo?.id?.includes(query);

            if (onlineOrderIdMatch) {
              return true;
            }

            const posOrderNumberMatch = eachOrder?.orderNumber
              ?.toLocaleLowerCase()
              ?.includes(query);

            if (posOrderNumberMatch) {
              return true;
            }
          } else {
            return true;
          }
        }
      })
      .map(mapOrderToOnlineOrdersView),
    'createdAt',
    'desc',
  );
};
