import React from 'react';
import {
  Text,
  View,
  StyleSheet,
  TouchableOpacity,
  GestureResponderEvent,
} from 'react-native';
import { useTranslation } from '@oolio-group/localization';
import theme from '../../../common/default-theme';
import Icon from '../../Icon/Icon';

export interface CreateButtonProps {
  onPress?: (event?: GestureResponderEvent) => void;
  buttonText?: string;
}

const CreateButton: React.FC<CreateButtonProps> = ({
  onPress,
  buttonText,
}: CreateButtonProps) => {
  const { translate } = useTranslation();

  return (
    <TouchableOpacity
      onPress={onPress}
      testID="create-button"
      style={styles.container}
    >
      <Text style={styles.text}>
        {buttonText || translate('button.createNew')}
      </Text>
      <View style={styles.icon}>
        <Icon name="plus" size={20} color={theme.colors.white} />
      </View>
    </TouchableOpacity>
  );
};

export default CreateButton;

const styles = StyleSheet.create({
  container: {
    width: 130,
    height: 44,
    paddingLeft: 16,
    flexDirection: 'row',
    alignItems: 'center',
    borderRadius: theme.radius.s,
    backgroundColor: theme.colors.green,
    overflow: 'hidden',
    justifyContent: 'space-between',
  },
  text: {
    fontSize: 14,
    lineHeight: 14,
    color: theme.colors.white,
    fontFamily: theme.fonts.medium,
  },
  icon: {
    width: 44,
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.colors.greenDark,
  },
});
