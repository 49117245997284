import { usePhoneNumber, useTranslation } from '@oolio-group/localization';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { View, KeyboardAvoidingView, Platform } from 'react-native';
import InputPhone from '../../Shared/Inputs/InputPhone';
import InputEmail from '../../Shared/Inputs/InputEmail';
import { useFela } from 'react-fela';
import { Order, StyleFn, Customer } from '@oolio-group/domain';
import { isValidEmail, isValidNumber } from '../../../utils/validator';
import { useNotification } from '../../../hooks/Notification';
import IconButton from '../../Button/IconButton';
import TitleBar from '../../TitleBar/TitleBar';
import { useModal } from '@oolio-group/rn-use-modal';
import { isWeb } from '../../../common/theme';
import Button from '../../Button/Button';
import { styles } from './style';
import { DEFAULT_COUNTRY_CODE } from '../../../constants';
import { analyticsService } from '../../../analytics/AnalyticsService';
import { useCustomers } from '../../../hooks/orders/useCustomers';
import { useSession } from '../../../hooks/app/useSession';

export interface OrderReceiptActionModalProps {
  order: Order;
  changeDue?: number;
  sendReceipt?: (
    order: Order,
    email: string,
    phone?: { countryCode: string; number: string },
  ) => void;
  sendSmsReceipt?: (order: Order, phone: string) => void;
}

interface ReceiptPrintFormProps {
  email: string;
  phone: string;
  countryCode: string;
}

const iconButtonStyle: StyleFn = () => ({
  position: 'absolute',
  left: 15,
});

const keyboardAvoidingViewStyle: StyleFn = () => ({
  flex: 1,
  justifyContent: 'center',
});

const modalStyle: StyleFn = () => ({
  width: 400,
  alignSelf: 'center',
  overflow: 'hidden',
});

const containerStyle: StyleFn = ({ theme }) => ({
  width: 400,
  alignSelf: 'center',
  borderRadius: theme.radius.small,
  marginTop: -theme.spacing.small,
  backgroundColor: theme.colors.white,
  elevation: 5,
  borderColor: theme.colors.boxBorder,
  shadowColor: theme.colors.charcoal,
  shadowOpacity: 0.3,
  shadowRadius: 8,
});

const inputContainerStyle: StyleFn = ({}) => ({
  marginVertical: isWeb ? 30 : 10,
  alignItems: 'center',
  paddingHorizontal: 20,
});

const buttonStyle: StyleFn = () => ({
  marginTop: 40,
});

const OrderReceiptActionModal: React.FC<OrderReceiptActionModalProps> = ({
  order,
  sendReceipt,
}) => {
  const { translate } = useTranslation();
  const [session] = useSession();

  const { closeModal } = useModal();
  const { customerMaps, getCustomerById, loading } = useCustomers();
  const { css } = useFela();
  const { country = DEFAULT_COUNTRY_CODE } = session?.currentOrganization || {};

  const customerDetail = useMemo<Customer | undefined>(() => {
    if (!order?.customer?.id) return;
    return customerMaps[order?.customer?.id as string];
  }, [customerMaps, order?.customer?.id]);

  const [form, setForm] = useState<ReceiptPrintFormProps>({
    email: customerDetail?.email || '',
    phone: customerDetail?.phoneNumber || '',
    countryCode: country,
  });
  const { getFullFormattedPhoneNumber } = usePhoneNumber();

  const { showNotification } = useNotification();
  const onChangeText = (prop: string, text: string): void => {
    setForm(preForm => ({ ...preForm, [prop]: text }));
  };

  useEffect(() => {
    if (customerDetail)
      setForm({
        phone: customerDetail?.phoneNumber || '',
        countryCode: customerDetail.preferredAddress?.isoCountryCode || country,
        email: customerDetail.email || '',
      });
  }, [country, customerDetail]);

  const isUK = form.countryCode === 'GB';

  useEffect(() => {
    if (!customerDetail && order?.customer?.id) {
      getCustomerById(order?.customer?.id);
    }
  }, [customerDetail, getCustomerById, order?.customer?.id]);

  const titleLeft = (
    <IconButton
      containerStyle={css(iconButtonStyle)}
      icon="times"
      onPress={closeModal}
    />
  );

  const isPhoneValid = isValidNumber(
    getFullFormattedPhoneNumber(form.countryCode, form.phone),
  );
  const isEmailValid = isValidEmail(form.email);

  const canSend = isEmailValid || isPhoneValid;

  const _sendReceipt = useCallback(() => {
    if (!form.email && !form.phone) {
      showNotification({
        error: true,
        message: translate('sendReceipt.emptyEmailAndPhoneMsg'),
      });
      return;
    }
    if (sendReceipt) {
      if (form.email) {
        analyticsService.capture('send_receipt', {
          method: 'Email',
        });

        if (!isEmailValid) {
          showNotification({
            error: true,
            message: translate('sendReceipt.invalidEmailMsg'),
          });
          return;
        }
      } else if (form.phone) {
        analyticsService.capture('send_receipt', {
          method: 'SMS',
        });
      }
      closeModal();
      sendReceipt(order, form.email, {
        countryCode: form.countryCode,
        number: form.phone,
      });
    }
  }, [
    form.email,
    form.phone,
    form.countryCode,
    sendReceipt,
    showNotification,
    translate,
    closeModal,
    order,
    isEmailValid,
  ]);

  return (
    <KeyboardAvoidingView
      behavior={Platform.OS === 'ios' ? 'padding' : undefined}
      style={css(keyboardAvoidingViewStyle)}
    >
      <View style={css(modalStyle)}>
        <TitleBar titleLeft={titleLeft} primary title={'Send Receipt'} />
        <View style={css(containerStyle)}>
          <View style={css(inputContainerStyle)}>
            <InputPhone
              containerStyle={css(styles.phoneTextInputStyle)}
              testID="phone-number"
              placeholder={translate(
                isUK ? 'form.phoneNumberHintUK' : 'form.phoneNumberHint',
              )}
              value={form.phone}
              defaultCountry={form.countryCode}
              onPressCountry={onChangeText.bind(null, 'countryCode')}
              onChangeText={text => onChangeText('phone', text)}
            />
            <InputEmail
              testID="email-address"
              containerStyle={[css(styles.phoneTextInputStyle)]}
              placeholder={translate('common.emailPlaceholder')}
              value={form.email || ''}
              onChangeText={text => onChangeText('email', text)}
            />

            <Button
              title={translate('button.sendReceipt')}
              success
              disabled={!canSend}
              size="medium"
              testID="send-receipt"
              containerStyle={css(buttonStyle)}
              onPress={() => {
                _sendReceipt();
              }}
              loading={loading}
            />
          </View>
        </View>
      </View>
    </KeyboardAvoidingView>
  );
};

export default OrderReceiptActionModal;
