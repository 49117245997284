import { useCallback } from 'react';
import {
  Adjustment,
  OrderAction,
  UpdateOrderAdjustmentsEvent,
} from '@oolio-group/domain';
import { useCartContext as useCart } from '../CartProvider';
import { isScheduleAdjustmentsAppliedInCart } from '@oolio-group/order-helper';

interface UseAdjustmentScheduleReturn {
  applyScheduleAdjustments: (adjustments: Adjustment[]) => void;
}

export const useAdjustmentSchedule = (): UseAdjustmentScheduleReturn => {
  const { updateCart, order } = useCart();

  const applyScheduleAdjustments = useCallback(
    (adjustments: Adjustment[]) => {
      const isScheduleAdjustmentAppliedInCart =
        isScheduleAdjustmentsAppliedInCart(
          adjustments,
          order?.adjustments || [],
        );
      if (isScheduleAdjustmentAppliedInCart) return;
      updateCart<UpdateOrderAdjustmentsEvent>(
        OrderAction.ORDER_UPDATE_ADJUSTMENTS,
        {
          adjustments: adjustments,
        },
      );
    },
    [order?.adjustments, updateCart],
  );

  return {
    applyScheduleAdjustments,
  };
};
