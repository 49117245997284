import { useTranslation } from '@oolio-group/localization';
import {
  CardFee,
  DayOfWeek,
  Icons,
  PaymentSettings,
  SalesChannelCode,
  StyleFn,
} from '@oolio-group/domain';
import React, { useCallback, useMemo, useState } from 'react';
import { View, Text, ViewStyle, ScrollView } from 'react-native';
import Modal from '../../../../components/Modals/Modal';
import IconButton from '../../../../components/Button/IconButton';
import Button from '../../../../components/Button/Button';
import { PayOutScheduleStyle } from './styles';
import DropDown from '../../../../components/Shared/Select/Select';
import { DAYS_IN_WEEK } from '@oolio-group/client-utils';
import capitalize from 'lodash/capitalize';
import DataGrid, {
  alignment,
  columnForDataGrid,
  columnStyleOptions,
} from '../../../../components/DataGrid/DataGrid';
import { useFela } from 'react-fela';

const customBodyStyle: StyleFn = ({}) => ({
  marginTop: 0,
  paddingTop: 0,
  paddingBottom: 0,
  flex: 1,
});

export interface PayoutScheduleModalProps {
  onDismiss: () => void;
  paymentSettings?: PaymentSettings;
}

const weekDays = DAYS_IN_WEEK.map(day => ({
  label: capitalize(day),
  value: day,
}));

const PayoutScheduleModal: React.FC<PayoutScheduleModalProps> = ({
  onDismiss,
  paymentSettings,
}) => {
  const { translate } = useTranslation();
  const { theme } = useFela();
  const [selectedPayoutDay, setSelectedPayoutDay] = useState(DayOfWeek.MONDAY);
  const styles = PayOutScheduleStyle();
  const onChangePayoutDay = useCallback((selectedValue: string) => {
    setSelectedPayoutDay(selectedValue as DayOfWeek);
  }, []);

  const surchargeEnabled = paymentSettings?.surchargeEnabled;

  const nextPayoutDay = paymentSettings?.payoutSchedule?.[selectedPayoutDay];

  const formatCardFees = useCallback(
    (cardFees?: CardFee[]) => {
      return (cardFees || []).map(cardFee => ({
        ...cardFee,
        salesChannelName: cardFee.salesChannel.name,
        cardTypeName: translate(
          `backOfficePayments.cardType.${cardFee.cardType}`,
        ),
        feeRate: `${cardFee.feeRate}%`,
      }));
    },
    [translate],
  );

  const inStoreCardFees = paymentSettings?.cardFees?.filter(
    cardFee => cardFee.salesChannel?.code === SalesChannelCode.IN_STORE,
  );

  const onlineCardFees = paymentSettings?.cardFees?.filter(
    cardFee => cardFee.salesChannel?.code === SalesChannelCode.ONLINE,
  );

  const COLUMNS: columnForDataGrid[] = useMemo(
    () => [
      {
        columnName: translate('backOfficePayments.Channel'),
        columnDataKey: 'salesChannelName',
        columnStyle: columnStyleOptions.REGULAR,
        cellAlignmentStyle: alignment.LEFT,
      },
      {
        columnName: translate('backOfficePayments.PaymentMethods'),
        columnDataKey: 'cardTypeName',
        columnStyle: columnStyleOptions.REGULAR,
        cellAlignmentStyle: alignment.LEFT,
        style: {
          paddingLeft: 28,
        },
      },
      {
        columnName: translate('backOfficePayments.Fees'),
        columnDataKey: 'feeRate',
        columnStyle: columnStyleOptions.REGULAR,
        cellAlignmentStyle: alignment.LEFT,
        style: {
          paddingLeft: 28,
        },
      },
    ],
    [translate],
  );

  return (
    <Modal
      contentStyle={styles.containerStyle}
      customBodyStyle={customBodyStyle}
      showCloseButton={false}
      headerTextStyle={styles.titleTextStyle as ViewStyle}
      title={translate('backOfficePayments.PayoutSchedule')}
    >
      <View style={styles.payoutScheduleDesc}>
        <Text style={styles.payoutScheduleDescText}>
          {translate('backOfficePayments.PayoutScheduleDesc')}
        </Text>
      </View>
      <ScrollView
        contentContainerStyle={styles.scrollView}
        showsVerticalScrollIndicator={false}
      >
        <View style={styles.daySelectionContainer}>
          <DropDown
            testID="group-by-selection"
            options={weekDays}
            selectedValue={selectedPayoutDay}
            containerStyle={styles.groupByDropdownStyle}
            onValueChange={onChangePayoutDay}
            prefixLabel={translate('backOfficePayments.BusinessDay')}
          ></DropDown>
        </View>
        <View style={styles.nextPayoutDayContainer}>
          <IconButton icon={Icons.ArrowRight} />
          <Text style={styles.nextPayoutDayText}>
            {translate('backOfficePayments.PayoutDayDesc', {
              payoutDay: capitalize(nextPayoutDay),
            })}
          </Text>
        </View>

        <View style={styles.feeAndSurchargesContainer}>
          <Text style={styles.feeAndSurchargesText}>
            {translate('backOfficePayments.FeesAndSurcharges')}
          </Text>
        </View>

        <View
          style={[
            styles.surchargesEnabledContainer,
            !surchargeEnabled && { backgroundColor: theme.colors.redNegative },
          ]}
        >
          <IconButton icon={surchargeEnabled ? Icons.Check : Icons.Times} />
          <Text style={styles.surchargesEnabledText} testID="surcharge-enabled">
            {surchargeEnabled
              ? translate('backOfficePayments.SurchargesEnabled')
              : translate('backOfficePayments.SurchargesDisabled')}
          </Text>
        </View>
        <View style={styles.feeRateTableContainer}>
          <DataGrid
            columns={COLUMNS}
            headerCellTextAlignment={styles.headerCellTextAlignment}
            headerStyle={styles.feeRateHeaderTable}
            rowCellTextAlignment={styles.rowCellTextAlignment}
            data={[
              ...formatCardFees(inStoreCardFees),
              ...formatCardFees(onlineCardFees),
            ]}
          />
        </View>
      </ScrollView>
      <View style={styles.dismissButtonWrapper}>
        <Button
          key={'dismiss'}
          testID={'dismiss'}
          containerStyle={styles.dismissButtonContainer}
          labelStyle={styles.dismissButtonStyle}
          title={translate('button.dismiss')}
          onPress={onDismiss}
        />
      </View>
    </Modal>
  );
};

export default PayoutScheduleModal;
