import React, { useEffect, useCallback, useState, useMemo } from 'react';
import { View, Text, TouchableOpacity } from 'react-native';
import {
  ModifierGroup as ModifierGroupDefault,
  DEFAULT_ENTITY_ID,
  UpdateModifierGroupInput,
  AlternateName,
} from '@oolio-group/domain';
import { useModal } from '@oolio-group/rn-use-modal';
import { useNavigation, useIsFocused } from '@react-navigation/native';
import { useTranslation } from '@oolio-group/localization';
import { encodeAlternateNameValuesToBase64 } from '@oolio-group/client-utils';
import { useNotification } from '../../../../../hooks/Notification';
import { useModifierGroups } from '../../../../../hooks/app/modifierGroups/useModifierGroups';
import { Operation } from '../../../../../types/Operation';
import { stripProperties } from '../../../../../utils/stripObjectProps';
import theme from '../../../../../common/default-theme';
import styles from '../Options.styles';
import TranslationModal from '../TranslationModal';
import ScreenLayout from '../../../../../components/Office/ScreenLayout/ScreenLayout';
import Section from '../../../../../components/Office/Section/Section';
import CreateButton from '../../../../../components/Office/CreateButton/CreateButton';
import ButtonIcon from '../../../../../components/Shared/TreatButton/ButtonIcon';
import Icon from '../../../../../components/Icon/Icon';
import ConfirmationModal from '../../../../../components/Modals/ConfirmationDialog';

interface ModifierGroup extends ModifierGroupDefault {
  isSelected?: boolean;
}

export const ModifierGroupsTab: React.FC = () => {
  const { translate } = useTranslation();
  const { showNotification } = useNotification();
  const navigation = useNavigation();
  const { showModal, closeModal } = useModal();
  const isFocused = useIsFocused();
  const [optionsGroupsData, setOptionsGroupsData] = useState<
    Record<string, ModifierGroup>
  >({});

  const {
    error,
    loading,
    modifierGroups: optionsGroups,
    operation,
    updateModifierGroups: updateOptionsGroups,
    getAllModifierGroups,
    cloneModifierGroup,
    createdIds,
  } = useModifierGroups();

  useEffect(() => {
    isFocused && getAllModifierGroups();
  }, [getAllModifierGroups, isFocused]);

  useEffect(() => {
    if (error) {
      showNotification({
        error: true,
        message: error,
      });
    }
  }, [error, showNotification]);

  useEffect(() => {
    if (!loading && !error && operation === Operation.DELETE) {
      closeModal();
      showNotification({
        success: true,
        message: translate('modifiers.modifierGroupsDeletedSuccessfully'),
      });
    }
  }, [loading, operation, closeModal, showNotification, translate, error]);

  useEffect(() => {
    if (!loading && !error && operation === Operation.UPDATE) {
      closeModal();
      showNotification({
        success: true,
        message: translate('modifiers.modifierGroupsSavedSuccessfully'),
      });
    }
  }, [loading, operation, closeModal, showNotification, translate, error]);

  useEffect(() => {
    if (
      !loading &&
      !error &&
      operation === Operation.CREATE &&
      createdIds.length
    ) {
      closeModal();
      showNotification({
        success: true,
        message: translate('modifiers.modifierGroupCopiedSuccessfully'),
      });
      // navigate to edit modifier group page
      navigation.navigate('CreateModifierGroupTab', {
        modifierGroupId: createdIds[0],
      });
    }
  }, [
    loading,
    operation,
    closeModal,
    showNotification,
    translate,
    error,
    createdIds,
    navigation,
  ]);

  useEffect(() => {
    if (!error && !loading && optionsGroups) {
      setOptionsGroupsData(optionsGroups);
    }
  }, [error, loading, optionsGroups]);

  const optionsGroupsArray = useMemo(() => {
    return Object.values(optionsGroupsData || {});
  }, [optionsGroupsData]);

  const onCreateOptionsGroup = useCallback((): void => {
    navigation.navigate('CreateModifierGroupTab', {
      modifierGroupId: DEFAULT_ENTITY_ID,
    });
  }, [navigation]);

  const onPressCopy = useCallback(
    (id: string): void => {
      if (id && optionsGroups[id]) {
        const selectedOptionGroup = optionsGroups[id];
        showModal(
          <ConfirmationModal
            type="neutral"
            title={translate('modifiers.copyModifierGroup')}
            confirmLabel={translate('modifiers.copy')}
            message={translate('modifiers.modifierGroupCopyDiscription', {
              name: selectedOptionGroup.name,
            })}
            onConfirm={() => {
              cloneModifierGroup({
                id: selectedOptionGroup.id,
              });
            }}
          />,
        );
      }
    },
    [showModal, translate, optionsGroups, cloneModifierGroup],
  );

  const onConfirmTranslations = useCallback(
    (id: string, alternateNames: AlternateName[]) => {
      const optGroups = optionsGroupsArray.filter(x => x && x.id == id);
      if (optGroups?.length) {
        const optGroupsModified: UpdateModifierGroupInput[] = stripProperties(
          optGroups,
          '__typename',
        );
        updateOptionsGroups(
          optGroupsModified.map(x => ({
            id: x.id,
            name: x.name,
            alternateNames: encodeAlternateNameValuesToBase64(alternateNames),
          })) as unknown as UpdateModifierGroupInput[],
        );
        closeModal();
      }
    },
    [optionsGroupsArray, closeModal, updateOptionsGroups],
  );

  const openTranslationModal = useCallback(
    (id: string) => {
      if (id) {
        const modifierAltNames = optionsGroups?.[id]?.alternateNames || [];
        showModal(
          <TranslationModal
            alternateNames={modifierAltNames}
            id={id}
            onConfirm={onConfirmTranslations}
          />,
        );
      }
    },
    [showModal, optionsGroups, onConfirmTranslations],
  );

  const getOptionsText = (group: ModifierGroup) => {
    if (group.products && group.products.length > 0) {
      return group.products?.map(m => m.name).join(', ');
    } else {
      return group.modifiers?.map(p => p.name).join(', ');
    }
  };

  return (
    <ScreenLayout hideFooter loading={loading} title="Options Groups | Oolio">
      <Section
        title={translate('modifiers.optionGroups')}
        subtitle={translate('modifiers.optionGroupsDescription')}
        layoutWidth="medium"
      >
        <View style={styles.tableContainer}>
          <View style={theme.tables.header}>
            <Text style={[theme.tables.headerText, styles.headerName]}>
              {translate('modifiers.optionGroup')}
            </Text>
            <Text style={theme.tables.headerText}>
              {translate('modifiers.options')}
            </Text>
          </View>
          {optionsGroupsArray.length > 0 ? (
            <View>
              {optionsGroupsArray.map((group, i: number) => (
                <TouchableOpacity
                  key={i}
                  testID="row-group"
                  style={theme.tables.row}
                  onPress={(): void =>
                    navigation.navigate('CreateModifierGroupTab', {
                      title: group.name,
                      modifierGroupId: group.id,
                    })
                  }
                >
                  <Text style={styles.cellName}>{group.name}</Text>
                  <Text style={styles.cellOptions} numberOfLines={1}>
                    {getOptionsText(group)}
                  </Text>
                  <ButtonIcon
                    testID="btn-translations"
                    type="neutralLight"
                    icon="english-to-chinese"
                    onPress={() => openTranslationModal(group.id)}
                  />
                  <ButtonIcon
                    icon="copy"
                    type="neutralLight"
                    testID="btn-copy"
                    onPress={() => onPressCopy(group.id)}
                    containerStyle={styles.cellCopy}
                  />
                  <View style={theme.tables.disclosure}>
                    <Icon
                      size={20}
                      name="angle-right"
                      color={theme.colors.grey5}
                    />
                  </View>
                </TouchableOpacity>
              ))}
            </View>
          ) : (
            <View style={styles.emptyListView}>
              <Text testID="empty-text" style={styles.emptyListText}>
                {translate('modifiers.noModifierGroupsMessage')}
              </Text>
            </View>
          )}
        </View>
        <CreateButton onPress={onCreateOptionsGroup} />
      </Section>
    </ScreenLayout>
  );
};
