import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  filtersContainer: {
    height: 44,
    marginBottom: 20,
    flexDirection: 'row',
    alignItems: 'center',
  },
  searchContainer: {
    flex: 1,
    marginRight: 10,
  },
  headerName: {
    width: 188,
  },
  headerPhone: {
    width: 130,
    textAlign: 'right',
  },
  cellName: {
    width: 200,
    paddingLeft: 12,
  },
  cellEmail: {
    flex: 1,
    paddingLeft: 10,
    alignItems: 'center',
  },
  cellPhone: {
    width: 140,
    textAlign: 'right',
    paddingRight: 10,
  },
});

export default styles;
