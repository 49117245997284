import { Organization, User } from '@oolio-group/domain';
import React, { useCallback, useMemo } from 'react';
import { Text, View, TouchableOpacity } from 'react-native';
import Icon from '../../../Icon/Icon';
import styles from './NavigationContext.styles';
import theme from '../../../../common/default-theme';

export interface NavigationContextProps {
  testID?: string;
  active?: string;
  user?: Pick<User, 'id' | 'name' | 'email'>;
  organizations: Array<Pick<Organization, 'id' | 'name'>>;
  onLogout?: () => void;
  onPress?: () => void;
  onSelectOrganization?: (orgId: string) => void;
  collapsed: boolean;
}

const NavigationContext: React.FC<NavigationContextProps> = ({
  testID,
  user,
  active,
  onPress,
  organizations,
  collapsed,
}: NavigationContextProps) => {
  const activeOrganization = useMemo<Partial<Organization> | undefined>(
    () => organizations.find(x => x.id === active),
    [organizations, active],
  );

  const onPressTrigger = useCallback(() => {
    onPress && onPress();
  }, [onPress]);

  const initials = useMemo(() => {
    const [first, last] = (activeOrganization?.name || '').split(' ');
    return last
      ? `${first.charAt(0)}${last.charAt(0)}`
      : `${first.substring(0, 2)}`;
  }, [activeOrganization?.name]);

  return (
    <TouchableOpacity testID={testID} onPress={onPressTrigger}>
      <View style={styles.container}>
        <View style={styles.logoContainer}>
          <Text style={styles.initialsText}>{initials}</Text>
        </View>
        {!collapsed && (
          <View style={styles.infoContainer}>
            <View style={styles.infoTextContainer}>
              <Text testID="org-name" numberOfLines={1} style={styles.infoText}>
                {activeOrganization?.name || 'Organisation'}
              </Text>
              <Text
                testID="user-name"
                numberOfLines={1}
                style={[styles.infoText, { color: theme.colors.grey6 }]}
              >
                {user?.name || 'User Name'}
              </Text>
            </View>
            <View style={styles.iconContainer}>
              <Icon name="angle-down" color={theme.colors.grey6} />
            </View>
          </View>
        )}
      </View>
    </TouchableOpacity>
  );
};

export default NavigationContext;
