import React from 'react';
import { View, TouchableOpacity } from 'react-native';
import { Course } from '@oolio-group/domain';
import styles from '../Courses.styles';
import theme from '../../../../../common/default-theme';
import Icon from '../../../../../components/Icon/Icon';
import InputText from '../../../../../components/Shared/Inputs/InputText';
import InputToggle from '../../../../../components/Shared/Inputs/InputToggle';
import ButtonIcon from '../../../../../components/Shared/TreatButton/ButtonIcon';

interface CourseRowProps {
  course: Course;
  onChange: (value: Course) => void;
  onEditCourse: (id: string) => void;
  onDrag?: () => void;
  isErrorName?: boolean;
}

export type FieldKeys = 'name' | 'autoFire';

const CoursesRow: React.FC<CourseRowProps> = ({
  course,
  onChange,
  onEditCourse,
  onDrag,
  isErrorName,
}) => {
  const handleChange = (key: FieldKeys, value: string | boolean) => {
    onChange({ ...course, [key]: value });
  };

  return (
    <View testID="row-course" style={theme.tables.row}>
      <TouchableOpacity style={theme.tables.disclosure} onLongPress={onDrag}>
        <Icon name="draggabledots" size={20} color={theme.colors.grey5} />
      </TouchableOpacity>
      <InputText
        testID={`course-name-${course.id}`}
        maxLength={24}
        value={course.name}
        placeholder={'Add course...'}
        onChangeText={(value: string) => handleChange('name', value)}
        errorMessage={isErrorName ? 'Enter valid name' : undefined}
        containerStyle={styles.cellName}
      />
      <InputText
        testID="input-count"
        value={course?.products?.length.toString() || '0'}
        placeholder="0"
        editable={false}
        alignText="center"
        containerStyle={styles.cellCount}
      />
      <InputToggle
        testID="toggle-autoFire"
        isToggled={course.autoFire}
        onToggle={() => handleChange('autoFire', !course.autoFire)}
        containerStyle={styles.cellToggle}
      />
      <ButtonIcon
        testID="btn-edit"
        icon="pen"
        type="neutralLight"
        onPress={() => onEditCourse(course.id)}
      />
    </View>
  );
};

export default CoursesRow;
