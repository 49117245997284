import { StyleSheet } from 'react-native';
import theme from '../../../common/default-theme';

export const CART_FONT_SIZE = 13;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    maxWidth: 320,
    padding: 6,
  },
  cart: {
    flex: 1,
    padding: 8,
    borderRadius: theme.radius.s,
    backgroundColor: theme.colors.white,
    overflow: 'hidden',
  },
  itemsContainer: {
    flex: 1,
    width: '100%',
    overflow: 'hidden',
  },
  grow: {
    flexGrow: 1,
  },
  items: {
    paddingBottom: 8,
  },
  cartButtons: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: 4,
  },
  btnCartPrimary: {
    flex: 1,
  },
  onHoldContainer: {
    paddingTop: 6,
    borderTopWidth: 4,
    borderTopColor: theme.colors.states.negative,
  },
  onHoldText: {
    textAlign: 'center',
    textTransform: 'capitalize',
    fontFamily: theme.fonts.medium,
    color: theme.colors.states.negative,
  },
  separator: {
    height: 0,
    marginVertical: 4,
    borderTopWidth: 2,
    borderStyle: 'dotted',
    borderTopColor: theme.colors.dark,
  },
  emptyCart: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    paddingHorizontal: 40,
  },
  emptyCartText: {
    fontSize: 14,
    lineHeight: 20,
    textAlign: 'center',
    color: theme.colors.grey6,
  },
});

export default styles;
