import React, { useCallback, useState } from 'react';
import { View } from 'react-native';
import { useTranslation } from '@oolio-group/localization';
import { Modifier as ModifierAlias } from '@oolio-group/domain';
import TreatPicker from '../../../../../components/Shared/Select/Picker';
import Search from '../../../../../components/Shared/Search/Search';
import CreateButton from '../../../../../components/Office/CreateButton/CreateButton';
import styles from '../Options.styles';

interface Modifier extends ModifierAlias {
  isChanged?: boolean;
}

interface LabelFilter {
  value: string;
  label: string;
}

interface ModifierRowProps {
  modifierList: Modifier[];
  modifierGroups: LabelFilter[];
  onFilterChange: (modifierData: Modifier[]) => void;
  onPressCreate: () => void;
}

export const getModifiersBySearchQuery = (
  modifiers: Modifier[],
  searchQuery: string,
): Modifier[] => {
  return modifiers.filter(modifier =>
    modifier.name.toLowerCase().includes(searchQuery.toLowerCase()),
  );
};

export const getModifiersByFilteredModifierGroup = (
  modifiers: Modifier[],
  selectedModifierGroup: string,
): Modifier[] => {
  return modifiers.filter(
    modifier =>
      modifier.modifierGroups?.filter(
        modGroup => modGroup.id == selectedModifierGroup,
      ).length,
  );
};

export const ModifierHeaderFilters: React.FC<ModifierRowProps> = ({
  modifierList,
  modifierGroups,
  onFilterChange,
  onPressCreate,
}: ModifierRowProps) => {
  const [searchString, setSearchString] = useState('');
  const [selectedModifierGroup, setSelectedModifierGroup] = useState('');
  const { translate } = useTranslation();

  const PageData = useCallback(
    (searchString, selectedModifierGroup) => {
      let modifiersDataInPage: Modifier[] = modifierList;
      if (searchString) {
        modifiersDataInPage = getModifiersBySearchQuery(
          modifiersDataInPage,
          searchString,
        );
      }
      if (selectedModifierGroup) {
        modifiersDataInPage = getModifiersByFilteredModifierGroup(
          modifiersDataInPage,
          selectedModifierGroup,
        );
      }
      return modifiersDataInPage;
    },
    [modifierList],
  );

  const onSearchTextChange = useCallback(
    (value: string) => {
      setSearchString(value);
      onFilterChange(PageData(value, selectedModifierGroup));
    },
    [selectedModifierGroup, onFilterChange, PageData],
  );

  const onModifierGroupChange = useCallback(
    (value: string) => {
      setSelectedModifierGroup(value);
      onFilterChange(PageData(searchString, value));
    },
    [searchString, onFilterChange, PageData],
  );

  return (
    <View style={styles.filters}>
      <TreatPicker
        testID="select-group"
        options={[
          {
            label: translate('modifiers.allGroups'),
            value: '',
          },
        ].concat(...(modifierGroups || []))}
        selectedValue={selectedModifierGroup}
        onValueChange={onModifierGroupChange}
        containerStyle={styles.cellName}
      />
      <Search
        testID="search-modifiers"
        placeholder={translate('modifiers.searchModifierByName')}
        onChangeText={onSearchTextChange}
        containerStyle={styles.cellOptions}
      />
      <CreateButton onPress={onPressCreate} />
    </View>
  );
};
