import { User, CreateUserRequest } from '@oolio-group/domain';
import React, { useEffect, useState, useCallback } from 'react';
import { View, Text, TouchableOpacity } from 'react-native';
import { useTranslation } from '@oolio-group/localization';
import { useNavigation } from '@react-navigation/native';
import { useUsers } from '../../../../hooks/app/useUsers';
import { useNotification } from '../../../../hooks/Notification';
import { CreateUserModal } from './CreateUser/CreateUserModal';
import { Operation } from '../../../../types/Operation';
import { useIsFocused } from '@react-navigation/native';
import Section from '../../../../components/Office/Section/Section';
import CreateButton from '../../../../components/Office/CreateButton/CreateButton';
import Search from '../../../../components/Shared/Search/Search';
import Icon from '../../../../components/Icon/Icon';
import theme from '../../../../common/default-theme';
import styles from './ManageUsers.styles';
import ScreenLayout from '../../../../components/Office/ScreenLayout/ScreenLayout';
import { useModal } from '@oolio-group/rn-use-modal';

interface UserRowProps {
  userData: User;
}

export const UsersRow: React.FC<UserRowProps> = ({
  userData,
}: UserRowProps) => {
  const navigation = useNavigation();

  const navigateToUserDetails = useCallback(() => {
    navigation.navigate('UserDetails', {
      userId: userData.id,
    });
  }, [navigation, userData.id]);

  return (
    <TouchableOpacity testID="usersRow" onPress={navigateToUserDetails}>
      <View style={theme.tables.row}>
        <Text style={styles.cellName}>
          {userData.firstName} {userData.lastName}
        </Text>
        <Text style={styles.cellEmail}>
          {userData.email} {userData.isOwner && ' ★'}
        </Text>
        <Text numberOfLines={1} style={styles.cellPhone}>
          {userData.phone}
        </Text>
        <View style={theme.tables.disclosure}>
          <Icon name="angle-right" size={20} color={theme.colors.grey4} />
        </View>
      </View>
    </TouchableOpacity>
  );
};

export const ManageUser: React.FC = () => {
  const isFocused = useIsFocused();
  const navigation = useNavigation();
  const { translate } = useTranslation();
  const { showModal, closeModal } = useModal();
  const { showNotification } = useNotification();
  const {
    searchUsers,
    refetch,
    error,
    loading,
    createUser,
    createdUserId,
    operation,
  } = useUsers();
  const [usersData, setUsersData] = useState<User[]>([]);
  const [searchString, setSearchString] = useState<string>('');
  const [selectedEditMore, setSelectedEditMore] = useState<boolean>(false);
  const [createdFlag, setCreatedFlag] = useState(false);

  const onSearchTextChange = useCallback((value: string) => {
    setSearchString(value);
  }, []);

  const onPressCreate = useCallback(() => {
    showModal(
      <CreateUserModal
        onDismiss={closeModal}
        createUser={(input: CreateUserRequest, isEditMore: boolean) => {
          setSelectedEditMore(isEditMore);
          setCreatedFlag(false);
          createUser(input);
          closeModal();
        }}
      />,
    );
  }, [closeModal, createUser, showModal]);

  useEffect(() => {
    if (isFocused) {
      refetch();
      setSearchString('');
    }
  }, [isFocused, refetch, setSearchString]);

  useEffect(() => {
    const searchResult = searchUsers(searchString);
    setUsersData(searchResult);
  }, [searchUsers, searchString]);

  useEffect(() => {
    if (
      createdUserId &&
      !error &&
      !loading &&
      operation === Operation.CREATE &&
      !createdFlag
    ) {
      showNotification({
        success: true,
        message: translate('backOfficeUsers.userCreatedSuccessfully'),
      });
      setCreatedFlag(true);
      closeModal();
      if (selectedEditMore) {
        navigation.navigate('UserDetails', {
          userId: createdUserId,
        });
      }
    }
  }, [
    createdUserId,
    error,
    loading,
    operation,
    selectedEditMore,
    showNotification,
    translate,
    navigation,
    createdFlag,
    closeModal,
  ]);

  useEffect(() => {
    if (error) {
      showNotification({
        error: true,
        message: error,
      });
    }
  }, [error, showNotification]);

  return (
    <ScreenLayout
      loading={loading}
      hideFooter={true}
      title={translate('navigation.users', {
        appName: translate('appName'),
      })}
    >
      <Section
        layoutWidth="medium"
        title={translate('backOfficeUsers.manageUsers')}
      >
        <View style={styles.filtersContainer}>
          <Search
            testID="search-customers"
            onChangeText={onSearchTextChange}
            placeholder={translate(
              'backOfficeUsers.SearchUsersByNameEmailOrPhone',
            )}
            containerStyle={styles.searchContainer}
          />
          <CreateButton onPress={onPressCreate} />
        </View>
        <View>
          <View style={theme.tables.header}>
            <Text
              numberOfLines={1}
              style={[theme.tables.headerText, styles.headerName]}
            >
              {translate('form.name')}
            </Text>
            <Text style={[theme.tables.headerText, styles.cellEmail]}>
              {translate('form.email')}
            </Text>
            <Text style={[theme.tables.headerText, styles.headerPhone]}>
              {translate('form.phone')}
            </Text>
            <View style={theme.tables.disclosure} />
          </View>
          {usersData.map((user: User, i: number) => (
            <UsersRow userData={user} key={i} />
          ))}
        </View>
      </Section>
    </ScreenLayout>
  );
};
