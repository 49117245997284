import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import LoadingIndicator from '../../../../components/LoadingIndicator/LoadingIndicator';
import { Helmet } from 'react-helmet';
import { Styles } from '../styles/ProductsSummary.styles';
import { View, ScrollView } from 'react-native';
import { useTranslation } from '@oolio-group/localization';
import {
  DateRangeFilter,
  Filters,
  FilterValue,
  Worklog,
  DateRangeGranularity,
  DateRangeFilterType,
} from '@oolio-group/domain';
import { ReportHeader } from '../UIComponents/ReportHeader';
import { Table, TableRef } from '../Shift/UIComponents/Table';
import { Filters as ReportFilter } from '../UIComponents/Filters';
import {
  GRANULARITY_FORMATS,
  WORKLOG_REPORT_COLUMNS,
  convertDateByFormat,
} from '../reportsHelper';
import {
  IMap,
  ExportType,
  Search,
  ReportTableColumn,
  DropDownFilter,
} from '../types';
import { useSession } from '../../../../../src/hooks/app/useSession';

interface ReportProps {
  options: { loading: boolean };
  filterOptions: DropDownFilter[];
  filters: FilterValue;
  search: Search;
  dateRangeFilter: DateRangeFilter;
  worklogs: Worklog[];
  updateFilters: (filter: string, value: string[]) => void;
  resetFilters: () => void;
  updateDateRangeFilters: (value: DateRangeFilter) => void;
  updateReport: () => void;
  updateSearch: (filter: string, value: string) => void;
  allFilters: Filters;
}

const TABLE_GRANULARITY_FORMATS: IMap<string> = {
  ...GRANULARITY_FORMATS,
  day: 'dd/MM/yy hh:mm aaa',
};

export const DAY_FORMAT = 'dd/MM/yyyy hh:mm aaa';

export const WorklogReport: React.FC<ReportProps> = ({
  options: { loading },
  filters,
  search,
  filterOptions,
  dateRangeFilter,
  worklogs,
  updateFilters,
  resetFilters,
  updateDateRangeFilters,
  updateReport,
  updateSearch,
  allFilters,
}) => {
  const { translate } = useTranslation();
  const [session] = useSession();
  const styles = Styles();
  const [showFilters, setShowFilters] = useState<boolean>(false);

  const formatMillisecondsToDayFormat = useCallback(
    (timeInMillis: number): string => {
      return convertDateByFormat(
        new Date(timeInMillis).toISOString(),
        DateRangeGranularity.DAY,
        DAY_FORMAT,
        session.currentOrganization?.timezone,
      );
    },
    [session],
  );

  const toggleFilters = useCallback(() => {
    setShowFilters(value => !value);
  }, []);

  const [selectedColumns, setSelectedColumns] = useState<ReportTableColumn[]>(
    [],
  );

  const summaryData = useMemo(() => {
    const toReturn = {
      table: {
        columns: WORKLOG_REPORT_COLUMNS,
        data: [] as IMap<string | number>[],
      },
    };

    if (worklogs?.length) {
      worklogs.forEach(worklog => {
        toReturn.table.data.push({
          userName: worklog.user?.name ?? '',
          clockInTime: formatMillisecondsToDayFormat(worklog.clockInTime),
          clockOutTime: worklog.clockOutTime
            ? formatMillisecondsToDayFormat(worklog.clockOutTime)
            : '',
          breakTime: worklog.breakTime,
          totalTime: worklog.totalTime,
          workTime: worklog.workTime,
        });
      });
    }

    return toReturn;
  }, [worklogs, formatMillisecondsToDayFormat]);

  const tableWidgetRef = useRef<TableRef>({} as TableRef);
  const exportReport = useCallback((type = ExportType.CSV) => {
    if (type === ExportType.CSV)
      tableWidgetRef.current.exportData &&
        tableWidgetRef.current.exportData(`AttendanceReport-${Date.now()}.csv`);
  }, []);

  useEffect(() => {
    setSelectedColumns(
      summaryData.table.columns.filter(column => column.showByDefault),
    );
  }, [summaryData.table.columns]);

  const updateColumns = useCallback(
    (columnKeys: string[]) => {
      setSelectedColumns(
        summaryData.table.columns.filter(col => columnKeys.includes(col.title)),
      );
    },
    [summaryData.table.columns],
  );

  return (
    <>
      <Helmet>
        <title>
          {translate('navigation.generalSettingsPageTitle', {
            appName: translate('appName'),
          })}
        </title>
      </Helmet>

      <ScrollView scrollEnabled={!showFilters} style={styles.pageStyle}>
        {showFilters ? (
          <View style={styles.filtersStyle}>
            <ReportFilter
              toggleFilters={toggleFilters}
              filterOptions={filterOptions}
              filters={filters}
              updateFilters={updateFilters}
              resetFilters={resetFilters}
            ></ReportFilter>
          </View>
        ) : null}
        <View style={styles.mainSectionStyle}>
          <View style={styles.headersStyle}>
            <ReportHeader
              allFilters={allFilters}
              filters={filters}
              search={search}
              updateSearch={updateSearch}
              updateFilters={updateFilters}
              toggleFilters={toggleFilters}
              updateReport={updateReport}
              exportReport={exportReport}
              dateRangeFilter={dateRangeFilter}
              updateDateRangeFilters={updateDateRangeFilters}
              columns={{
                all: summaryData.table.columns,
                updateColumns,
                selectedColumns,
              }}
              translationParentKey={'backOfficeWorklogs'}
              dateRanges={[
                DateRangeFilterType.FILTER_BY_PRESET,
                DateRangeFilterType.CUSTOM_DATES,
              ]}
            />
          </View>
          <View style={styles.tableStyle}>
            {loading ? (
              <LoadingIndicator />
            ) : (
              <Table
                data={summaryData.table.data}
                columns={selectedColumns}
                pageSize={10}
                granularityFormats={TABLE_GRANULARITY_FORMATS}
                ref={tableWidgetRef}
              ></Table>
            )}
          </View>
        </View>
      </ScrollView>
    </>
  );
};

export default WorklogReport;
