import { StyleSheet } from 'react-native';
import theme from '../../../../common/default-theme';

const styles = StyleSheet.create({
  tableContainer: {
    marginBottom: 20,
  },
  cellName: {
    width: 300,
    marginLeft: 10,
    marginRight: 'auto',
  },
  // Schedule Table
  scheduleTable: {
    zIndex: -1,
  },
  btnAddSlot: {
    zIndex: -1,
    marginTop: 20,
  },
  headerDay: {
    width: 262,
    marginRight: 10,
  },
  headerTime: {
    width: 100,
    marginRight: 10,
    textAlign: 'center',
  },
  cellDay: {
    flex: 1,
    marginRight: 10,
  },
  cellTime: {
    width: 100,
    marginRight: 10,
  },
  // Products Filters
  filters: {
    marginBottom: 20,
    flexDirection: 'row',
    alignItems: 'center',
  },
  search: {
    flex: 1,
    marginRight: 10,
  },
  // Products Table
  headerProduct: {
    flex: 1,
  },
  headerPrice: {
    width: 100,
    textAlign: 'center',
  },
  headerInputPrice: {
    width: 120,
    marginHorizontal: 10,
    textAlign: 'center',
  },
  cellProduct: {
    flex: 1,
    marginLeft: 12,
    marginRight: 10,
  },
  cellPrice: {
    width: 120,
    marginRight: 10,
  },
  cellPriceView: {
    width: 100,
    height: 44,
    marginRight: 10,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: theme.radius.s,
    backgroundColor: theme.colors.grey1,
  },
  cellPriceText: {
    textAlign: 'right',
  },
  btnDelete: {
    width: 34,
  },
});

export default styles;
