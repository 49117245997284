import { StyleSheet } from 'react-native';
import theme from '../../../../common/default-theme';

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: theme.colors.white,
  },
  filtersContainer: {
    marginBottom: 40,
    flexDirection: 'row',
    alignItems: 'center',
  },
  listContainer: {
    flex: 1,
    paddingHorizontal: 30,
  },
  footerContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
    backgroundColor: theme.colors.white,
    paddingVertical: 20,
    paddingHorizontal: 30,
    ...theme.shadow.strong,
  },
  messageActionContainer: {
    marginVertical: 10,
  },
  saveButton: {
    width: 100,
    height: 40,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: theme.radius.s,
    backgroundColor: theme.colors.states.positive,
  },
  saveText: {
    color: theme.colors.white,
    textTransform: 'uppercase',
    fontFamily: theme.fonts.medium,
  },
  // Empty List
  emptyListView: {
    height: 200,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: theme.radius.s,
    backgroundColor: theme.colors.grey1,
  },
  emptyListText: {
    textAlign: 'center',
    color: theme.colors.grey6,
  },
});

export default styles;
