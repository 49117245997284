import React, { useCallback, useMemo } from 'react';
import { OfficeUser, PosUser } from '../../../state/userUtility';
import { WorklogStatus } from '@oolio-group/domain';
import {
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
  ViewStyle,
} from 'react-native';
import Icon from '../../../components/Icon/Icon';
import theme from '../../../common/default-theme';

const styles = StyleSheet.create({
  container: {
    width: 120,
    height: 120,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: theme.radius.m,
    backgroundColor: theme.colors.grey1,
    paddingHorizontal: 16,
  },
  avatarContainer: {
    width: 60,
    height: 60,
    marginBottom: 12,
    borderRadius: 30,
    alignItems: 'center',
    justifyContent: 'center',
  },
  avatarText: {
    fontSize: 18,
    color: theme.colors.white,
    fontFamily: theme.fonts.bold,
    textTransform: 'uppercase',
  },
  activeIcon: {
    width: 18,
    height: 18,
    borderRadius: 18,
    borderWidth: 1,
    borderColor: theme.colors.grey1,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.colors.states.positive,
    position: 'absolute',
    top: 0,
    right: 0,
  },
  breakIcon: {
    width: 18,
    height: 18,
    borderRadius: 18,
    borderWidth: 1,
    borderColor: theme.colors.grey1,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.colors.states.negative,
    position: 'absolute',
    top: 0,
    right: 0,
  },
});

export interface UserProfileTileProps {
  idx?: number;
  disabled?: boolean;
  alwaysActive?: boolean;
  user: OfficeUser | PosUser;
  onSelectUser: (user: OfficeUser | PosUser) => void;
  containerStyle?: ViewStyle;
}

const UserProfileTileComponent: React.FC<UserProfileTileProps> = ({
  idx,
  user,
  onSelectUser,
  disabled,
  alwaysActive,
  containerStyle,
}) => {
  const onPressUser = useCallback(() => {
    onSelectUser(user);
  }, [onSelectUser, user]);

  const userWorking =
    alwaysActive ||
    ((user as PosUser)?.isWorking == true &&
      (user as PosUser)?.lastWorklog &&
      !disabled);

  const isUserOnBreak =
    (user as PosUser)?.lastWorklog?.status == WorklogStatus.IN_BREAK;

  const initials = useMemo(() => {
    const [firstName, lastName] = (user.name || '').split(' ');
    return lastName
      ? `${firstName.charAt(0)}${lastName.charAt(0)}`
      : `${firstName.substring(0, 2)}`;
  }, [user.name]);

  const getColor = (userIndex: number) => {
    const colors = theme.colors.pools.products;
    if (userIndex < colors.length) {
      return colors[userIndex].color;
    } else {
      const m = Math.floor(userIndex % colors.length);
      return colors[m].color;
    }
  };

  return (
    <TouchableOpacity
      style={[styles.container, containerStyle]}
      onPress={onPressUser}
    >
      <View
        style={[
          styles.avatarContainer,
          { backgroundColor: idx ? getColor(idx) : theme.colors.primary },
        ]}
      >
        <Text style={styles.avatarText}>{initials}</Text>
        {userWorking && (
          <View style={styles.activeIcon}>
            <Icon name="check" color={theme.colors.white} size={12} />
          </View>
        )}
        {isUserOnBreak && (
          <View style={styles.breakIcon}>
            <Icon name="minus" color={theme.colors.white} size={12} />
          </View>
        )}
      </View>
      <Text testID="username" numberOfLines={1}>
        {user.name}
      </Text>
    </TouchableOpacity>
  );
};

const UserProfileTile = React.memo(UserProfileTileComponent);
UserProfileTile.displayName = 'UserProfileTile';

export default UserProfileTile;
