import { VoidReason } from '@oolio-group/domain';
import { useTranslation } from '@oolio-group/localization';
import { useModal } from '@oolio-group/rn-use-modal';
import { RenderProps, StyleFn } from '@oolio-group/domain';
import React, { useCallback, useMemo, useRef } from 'react';
import { FelaComponent, useFela } from 'react-fela';
import {
  StyleSheet,
  View,
  FlatList,
  ListRenderItemInfo,
  ScrollView,
} from 'react-native';
import { ButtonProps } from '../../Button/Button';
import IconButton from '../../Button/IconButton';
import { OrderItem } from '@oolio-group/domain';
import Button from '../../Button/Button';
import CartProduct from '../../POS/Cart/CartProduct/CartProduct';
import Title from '../../Title/Title';

const closeButtonStyle: StyleFn = ({ theme }) => ({
  color: '#000',
  position: 'absolute',
  left: theme.spacing.big / 2,
});

const optionLabelStyle: StyleFn = ({ theme }) => ({
  textTransform: undefined,
  letterSpacing: -0.5,
  fontSize: theme.fontSize.medium,
  fontFamily: theme.font.regular,
});

const cartItemsStyle: StyleFn = () => ({
  overflow: 'hidden',
});

const scrollStyle: StyleFn = ({ theme }) => ({
  flex: 1,
  marginHorizontal: -(theme.spacing.small - 7),
  paddingHorizontal: theme.spacing.small - 7,
  paddingTop: theme.spacing.small - 8,
});

const itemContainerStyle: StyleFn = ({ theme }) => ({
  padding: theme.padding.medium,
  backgroundColor: theme.colors.white,
  marginBottom: 10,
});

const titleBarStyle: StyleFn = ({ theme }) => ({
  backgroundColor: theme.colors.black,
  height: 44,
  minHeight: 30,
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  marginBottom: theme.spacing.small,
  marginTop: theme.spacing.small,
  textTransform: 'uppercase',
  textAlign: 'center',
  borderRadius: theme.radius.small,
  paddingHorizontal: theme.padding.medium,
});

const titleBarTextStyle: StyleFn = ({ theme }) => ({
  color: theme.colors.white,
  fontSize: theme.fontSize.small + 1,
  fontFamily: theme.font.semibold,
});

const CloseButton: React.FC<ButtonProps> = ({ onPress }) => (
  <FelaComponent style={closeButtonStyle}>
    {({ style }: RenderProps) => (
      <IconButton
        icon="times"
        onPress={onPress}
        iconColor={'white'}
        containerStyle={style}
      />
    )}
  </FelaComponent>
);

export interface ReasonsProps {
  reasons: string[];
}

export type PickerOption = {
  reason: VoidReason;
  label: string;
};

export type PickerSelectFn = (value: PickerOption['reason']) => void;
export interface CancelOrderModalMapProps {
  orderId: string;
  item: OrderItem;
  onSubmit?: (orderItem: OrderItem, reason: VoidReason) => void;
}

const styles = StyleSheet.create({
  container: {
    width: 400,
    alignSelf: 'center',
    height: '70%',
  },
  fullWidth: {
    width: '100%',
  },
});

export const CancelOrderItemModalMap: React.FC<CancelOrderModalMapProps> = ({
  item,
  onSubmit,
}) => {
  const { translate } = useTranslation();
  const { closeModal } = useModal();
  const flatListRef = useRef<FlatList<OrderItem>>(null);
  const { css } = useFela();
  const reasonOptions = useMemo(
    () =>
      Object.values(VoidReason).map(value => ({
        value,
        label: translate(`enums.${value}`),
      })),
    [translate],
  );
  const optionButtonStyle: StyleFn = ({ theme }) => ({
    justifyContent: 'space-between',
    marginBottom: theme.spacing.small / 2,
    paddingLeft: theme.padding.small * 3,
    ...theme.shadow30,
  });

  const PickerOption: React.FC<PickerOption & { onPress: PickerSelectFn }> = ({
    reason,
    label,
    onPress,
  }) => {
    const { css } = useFela();
    return (
      <FelaComponent style={optionButtonStyle}>
        {({ style }: RenderProps) => (
          <Button
            testID={`reason-${reason}`}
            fluid
            raised
            title={label}
            icon="AngleRight"
            iconPosition="right"
            containerStyle={style}
            labelStyle={css(optionLabelStyle)}
            iconProps={{ primary: true }}
            onPress={onPress.bind(null, reason)}
          />
        )}
      </FelaComponent>
    );
  };
  const onPressSubmit = useCallback(
    (reason: VoidReason) => {
      if (item) {
        onSubmit && onSubmit(item, reason);
        closeModal();
      }
    },
    [onSubmit, closeModal, item],
  );

  const renderItem = useCallback(({ item }: ListRenderItemInfo<OrderItem>) => {
    return <CartProduct testID="product" product={item} />;
  }, []);

  return (
    <View style={styles.container}>
      <View style={css(titleBarStyle)}>
        <CloseButton onPress={closeModal} />
        <Title labelStyle={css(titleBarTextStyle)}>
          {translate('order.voidOrderItem')}
        </Title>
      </View>
      <View testID="list" style={css(cartItemsStyle)}>
        <FlatList
          testID="orderItemList"
          data={[item]}
          ref={flatListRef}
          renderItem={renderItem}
          keyExtractor={item => item.id}
          style={css(itemContainerStyle)}
          showsVerticalScrollIndicator={false}
        />
      </View>
      <ScrollView style={css(scrollStyle)}>
        <View testID="reasons">
          {reasonOptions.map((reasonOption, index) => (
            <PickerOption
              key={`void-reason-${index}`}
              reason={reasonOption.value}
              label={reasonOption.label}
              onPress={onPressSubmit}
            />
          ))}
        </View>
      </ScrollView>
    </View>
  );
};
