import React, { useCallback, useEffect, useState } from 'react';
import { View, Text, StyleSheet } from 'react-native';
import { useNotification } from '../../../../../../hooks/Notification';
import { useNavigation, useRoute } from '@react-navigation/native';
import { KitchenDisplay, UpdateKitchenDisplayInput } from '@oolio-group/domain';
import { useTranslation } from '@oolio-group/localization';
import pick from 'lodash/pick';
import { useKitchenDisplay } from '../../../../../../hooks/app/useKitchenDisplay';
import theme from '../../../../../../common/default-theme';
import ScreenLayout from '../../../../../../components/Office/ScreenLayout/ScreenLayout';
import Section from '../../../../../../components/Office/Section/Section';
import InputText from '../../../../../../components/Shared/Inputs/InputText';
import TreatPicker from '../../../../../../components/Shared/Select/Picker';
import TreatButton from '../../../../../../components/Shared/TreatButton/TreatButton';

export const KitchenDisplayDetails: React.FC = () => {
  const route = useRoute();
  const { showNotification } = useNotification();
  const { translate } = useTranslation();
  const [form, setForm] = useState<KitchenDisplay>({} as KitchenDisplay);
  const navigation = useNavigation();

  const params = route.params as {
    storeId: string;
    kitchenDisplayId: string;
  };

  const storeId = params.storeId;
  const kitchenDisplayId = params.kitchenDisplayId;

  const {
    kitchenDisplay,
    deleteKitchenDisplay,
    updateKitchenDisplay,
    resetKitchenDisplayCode,
    resetedKitchenDisplayCode,
    deletedKitchenDisplay,
    updatedKitchenDisplayId,
    loading: devicesLoading,
    resetStateData,
    error: devicesError,
  } = useKitchenDisplay({
    kitchenDisplayId,
    storeId,
  });

  const loading = devicesLoading;
  const error = devicesError;

  useEffect(() => {
    if (kitchenDisplayId && kitchenDisplay) {
      setForm(kitchenDisplay);
    }
  }, [kitchenDisplay, kitchenDisplayId]);

  useEffect((): void => {
    if (error) {
      showNotification({
        error: true,
        message: error,
      });
    }
  }, [error, showNotification]);

  // on change events
  const onChange = useCallback((prop: string, value: string): void => {
    setForm(form => ({
      ...form,
      [prop]: value,
    }));
  }, []);

  const onSave = useCallback((): void => {
    if (!form.name) {
      showNotification({
        error: true,
        message: translate('kitchenDisplay.fieldsMissing'),
      });
      return;
    }

    const input = pick(form, ['id', 'name']) as UpdateKitchenDisplayInput;
    updateKitchenDisplay(input as UpdateKitchenDisplayInput);
  }, [form, showNotification, translate, updateKitchenDisplay]);

  useEffect(() => {
    if (updatedKitchenDisplayId) {
      showNotification({
        success: true,
        message: translate('kitchenDisplay.displayUpdatedSuccessfully'),
      });
    }
  }, [updatedKitchenDisplayId, showNotification, translate]);

  useEffect(() => {
    if (deletedKitchenDisplay) {
      showNotification({
        success: true,
        message: translate('backOfficeSettings.deleteInfo', {
          name: form?.name,
        }),
      });
      navigation.navigate('StoreSettings', {
        storeId: form?.store?.id,
        screen: 'KitchenDisplays',
      });
      resetStateData();
    }
  }, [
    deletedKitchenDisplay,
    navigation,
    showNotification,
    form,
    translate,
    resetStateData,
  ]);

  useEffect(() => {
    if (resetedKitchenDisplayCode) {
      showNotification({
        success: true,
        message: translate('backOfficeSettings.deviceCodeResetSuccessful', {
          name: form?.name,
        }),
      });
      navigation.navigate('StoreSettings', {
        storeId: form?.store?.id,
        screen: 'KitchenDisplays',
      });
      resetStateData();
    }
  }, [
    resetedKitchenDisplayCode,
    navigation,
    showNotification,
    form,
    translate,
    resetStateData,
  ]);
  return (
    <ScreenLayout
      loading={loading}
      onSave={onSave}
      onDelete={() => deleteKitchenDisplay(form.id)}
    >
      <Section title={translate('kitchenDisplay.displaySettings')}>
        <View style={theme.forms.row}>
          <InputText
            testID="input-name"
            title={translate('kitchenDisplay.displayName')}
            value={form.name}
            placeholder={translate('kitchenDisplay.displayName')}
            onChangeText={onChange.bind(null, 'name')}
            containerStyle={theme.forms.inputHalf}
            autoCapitalize="words"
          />
          <TreatPicker
            testID="select-type"
            editable={false}
            title={translate('kitchenDisplay.displayType')}
            options={[
              {
                value: 'Prep',
                label: 'Prep Station',
              },
            ]}
            onValueChange={onChange.bind(null, 'prep')}
            containerStyle={theme.forms.inputHalf}
          />
        </View>
      </Section>

      {form.uuid ? (
        <Section title={translate('backOfficeDevices.deviceInfo')}>
          <View style={styles.infoSection}>
            <Text style={styles.infoTitle}>
              {translate('backOfficeDevices.details')}
            </Text>
            <Text>{form.details || 'Unknown'}</Text>
          </View>
          <View style={styles.infoSection}>
            <Text style={styles.infoTitle}>
              {translate('backOfficeDevices.uuid')}
            </Text>
            <Text>{form.uuid || 'Unknown'}</Text>
          </View>
          <View style={theme.forms.row}>
            <TreatButton
              testID="btn-reset"
              label={translate('kitchenDisplay.logout')}
              onPress={() => resetKitchenDisplayCode(kitchenDisplayId)}
              type="negative"
            />
          </View>
        </Section>
      ) : (
        <></>
      )}
    </ScreenLayout>
  );
};

const styles = StyleSheet.create({
  infoSection: {
    marginBottom: 20,
    paddingHorizontal: 12,
  },
  infoTitle: {
    fontSize: 14,
    lineHeight: 14,
    marginBottom: 6,
    color: theme.colors.grey6,
  },
});
