import { StyleFn } from '@oolio-group/domain';
import { isAndroid, isWeb } from '../../../common/theme';

export const headerStyle: StyleFn = () => ({
  width: 580,
  height: 44,
  alignSelf: 'center',
  marginHorizontal: 8,
});

export const cancelButtonStyle: StyleFn = () => ({
  position: 'absolute',
  left: 10,
});

export const modalContainer: StyleFn = () => ({
  flex: 1,
  justifyContent: 'center',
  alignItems: 'center',
});

export const modalStyle: StyleFn = ({ theme }) => ({
  width: 580,
  height: 590,
  borderRadius: 6,
  justifyContent: 'flex-start',
  margin: 0,
  paddingTop: 0,
  paddingBottom: theme.spacing.small,
});

export const searchView: StyleFn = () => ({
  height: 58,
});

export const customBodyStyle: StyleFn = ({ theme }) => ({
  paddingVertical: 0,
  marginVertical: theme.spacing.small,
  flex: 1,
});

export const scrollBodyStyle: StyleFn = ({}) => ({
  maxHeight: '85%',
  flex: 1,
});

export const selectBarContainerStyle: StyleFn = ({ theme }) => ({
  borderRadius: theme.radius.small,
  height: theme.input.height,
  margin: 1,
  flex: undefined,
  alignSelf: 'stretch',
  alignItems: 'center',
});
export const selectBarLabelStyle: StyleFn = ({ theme }) => ({
  fontSize: 14,
  letterSpacing: -0.5,
  fontFamily: theme.font.medium,
});
export const selectBarItemStyle: StyleFn = () => ({
  margin: 2,
});

export const columnContainerStyle: StyleFn = ({ theme }) => ({
  backgroundColor: theme.colors.greyLight,
  borderRadius: theme.radius.small,
  borderBottomWidth: 0,
  paddingVertical: theme.padding.medium + 5,
});

export const headerCellTextAlignment: StyleFn = () => ({
  textAlign: 'left',
});

export const rowCellTextAlignment: StyleFn = () => ({
  justifyContent: 'flex-start',
});

export const dataRowStyle: StyleFn = ({ theme }) => ({
  padding: isWeb ? 0 : theme.padding.small,
  paddingHorizontal: theme.padding.large - 4,
  top: isAndroid ? -5 : 0,
});

export const tableHeaderTextStyle: StyleFn = ({ theme }) => ({
  color: theme.colors.paragraph,
  fontSize: theme.fontSize.small,
  fontFamily: theme.font.medium,
  lineHeight: 32,
});

//Table row

export const tableRowCells: StyleFn = () => ({
  flex: 1,
});

export const toggleCell: StyleFn = () => ({
  width: 100,
  alignItems: 'flex-end',
});

export const checkBoxCell: StyleFn = () => ({
  width: 100,
  alignItems: 'center',
});

export const quantityCell: StyleFn = () => ({
  width: 180,
  alignItems: 'center',
});

export const tableRowCellsText: StyleFn = ({ theme }) => ({
  fontFamily: theme.font.regular,
  fontSize: theme.fontSize.small,
  letterSpacing: -0.4,
  lineHeight: 20,
  flexWrap: 'wrap',
});

export const checkIconContainer: StyleFn = ({ theme }) => ({
  borderColor: theme.colors.green,
  borderWidth: 2,
  height: 18,
  width: 18,
});

export const unCheckContainer: StyleFn = ({ theme }) => ({
  borderColor: theme.colors.paragraphLight,
  borderWidth: 2,
  height: 18,
  width: 18,
});

export const checkIconStyle: StyleFn = ({ theme }) => ({
  color: theme.colors.green,
});

export const textInput: StyleFn = () => ({
  width: 50,
  height: 28,
  paddingLeft: 0,
});
export const textInputText: StyleFn = () => ({
  width: 50,
  height: 28,
  paddingLeft: 0,
  textAlign: 'center',
});

export const toggleContainerStyle: StyleFn = () => ({
  width: 50,
});

export const rowStyle: StyleFn = ({ theme }) => ({
  minHeight: 55,
  flexDirection: 'row',
  alignItems: 'center',
  paddingLeft: theme.spacing.small + 5,
  borderBottomColor: theme.colors.boxBorder,
  borderBottomWidth: 1,
});
