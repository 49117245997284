import { StyleSheet } from 'react-native';
import theme from '../../../common/default-theme';

const styles = StyleSheet.create({
  container: {
    backgroundColor: theme.colors.black,
  },
  menuContainer: {
    flex: 1,
    paddingTop: 20,
    paddingHorizontal: 16,
  },
  collapseButton: {
    height: 60,
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
    paddingHorizontal: 16,
    backgroundColor: theme.colors.black,
    borderTopWidth: 1,
    borderTopColor: theme.colors.black1,
  },
  collapseIcon: {
    width: 44,
    height: 44,
    alignItems: 'center',
    justifyContent: 'center',
  },
  collapseText: {
    marginLeft: 10,
    color: theme.colors.grey6,
  },
  // Switcher
  switcherContainer: {
    flex: 1,
    width: 280,
    ...theme.shadow.strong,
    backgroundColor: theme.colors.white,
  },
  titleContainer: {
    paddingVertical: 18,
    borderBottomWidth: 1,
    paddingHorizontal: 16,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderBottomColor: theme.colors.grey3,
  },
  titleText: {
    fontSize: 16,
    lineHeight: 24,
    paddingLeft: 12,
    fontFamily: theme.fonts.medium,
  },
  closeButton: {
    width: 44,
    height: 44,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: theme.radius.s,
    backgroundColor: theme.colors.grey1,
  },
  venuesList: {
    flex: 1,
    paddingVertical: 20,
    paddingHorizontal: 16,
  },
  venues: {
    flex: 1,
    marginVertical: 20,
  },
  venueContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingVertical: 8,
    paddingHorizontal: 12,
    borderRadius: theme.radius.s,
    justifyContent: 'space-between',
    backgroundColor: theme.colors.grey1,
  },
  storeContainer: {
    flexDirection: 'row',
    borderBottomWidth: 1,
    paddingHorizontal: 12,
    paddingVertical: 16,
    borderBottomColor: theme.colors.grey3,
  },
  logoutContainer: {
    height: 44,
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: theme.radius.s,
    backgroundColor: theme.colors.red,
  },
  logoutText: {
    color: theme.colors.white,
    textTransform: 'uppercase',
    fontFamily: theme.fonts.medium,
  },
});

export default styles;
