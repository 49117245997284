import React, {
  useRef,
  useMemo,
  useState,
  useEffect,
  useCallback,
} from 'react';
import { View, Platform } from 'react-native';
import {
  Product,
  Customer,
  FeatureIDs,
  ProductMaps,
} from '@oolio-group/domain';
import { useTranslation } from '@oolio-group/localization';
import { useModal } from '@oolio-group/rn-use-modal';
import { convertToBoolean } from '@oolio-group/client-utils';
import { getOnAccountPayment } from '@oolio-group/order-helper';
import { useReactiveVar } from '@apollo/client/react/hooks';
import { useRoute, useNavigation } from '@react-navigation/native';
import { AppScreen } from '../../../../types/AppScreen';
import { useSession } from '../../../../hooks/app/useSession';
import { useLoyalty } from '../../../../hooks/app/loyalty/useLoyalty';
import { useCartContext as useCart } from '../../../../hooks/CartProvider';
import { useCatalogue } from '../../../../hooks/app/catalogue/useCatalogue';
import { useCheckFeatureEnabled } from '../../../../hooks/app/features/useCheckFeatureEnabled';
import { failedPrintJobsCountVar } from '../../../../state/cache';
import { syncAppUtility } from '../../../../state/syncAppUtility';
import { analyticsService } from '../../../../analytics/AnalyticsService';
import styles from './TakeOrder.styles';
import TakeOrderContainer from './TakeOrder';
import OrdersSegmentTabs from '../OrdersSegmentTabs';
import ButtonIcon from '../../../../components/Shared/TreatButton/ButtonIcon';
import ScreenLayout from '../../../../components/POS/ScreenLayout/ScreenLayout';
import ConfirmationModal from '../../../../components/Modals/ConfirmationDialog';
import ButtonActions from '../../../../components/Shared/TreatButton/ButtonActions';
import AssignCustomer from '../../../../components/POS/AssignCustomer/AssignCustomer';
import NewOrderButton from '../../../../components/POS/NewOrderButton/NewOrderButton';
import SearchProduct from '../../../../components/POS/Modals/SearchProduct/SearchProduct';
import { useCustomers } from '../../../../hooks/orders/useCustomers';
import KeyEvent from 'react-native-keyevent';
import NoBarcodeMatchModal from '../../../../components/Modals/NoBarcodeMatch/NoBarcodeMatch';

interface RouteParams {
  orderType: string;
  table: string;
  id: string;
  searchedProduct: Product;
  isCompleted: boolean;
  isExisting: boolean;
  showSpinner?: boolean;
}

const TakeOrderLayout: React.FC = () => {
  const assignCustomerFromTakeOrder =
    useRef<(customer: Customer) => void | null>(null);
  const pressPrintReceiptFromTakeOrder = useRef<Function>(null);
  const handleSelectProductFromTakeOrder = useRef<Function>(null);
  const unassignCustomerFromOrder = useRef<(arg: null) => void | null>(null);
  const newOrderButtonOnSaveDiscardModalFromTakeOrder = useRef<Function>(null);

  const route = useRoute();
  const [session] = useSession();
  const {
    customerMaps,
    getCustomerById,
    loading: customerLoading,
  } = useCustomers();
  const navigation = useNavigation();
  const { translate } = useTranslation();
  const { showModal, closeModal } = useModal();
  const isFeatureEnabled = useCheckFeatureEnabled();
  const { order, isDirty, getCartUnSavedStatus } = useCart();
  const { loyaltySettings, getLoyaltyPrograms, rewardRules } = useLoyalty({
    venueId: session.currentVenue?.id,
    fetchPolicy: 'cache-first',
  });

  const isCartUnSaved = getCartUnSavedStatus();

  const params = route.params as RouteParams;
  const currentStoreId = session?.currentStore?.id;
  const orderId = order?.id;

  const [selectedMenuId, setSelectedMenuId] = useState('');

  const isOrderComplete = convertToBoolean(params?.isCompleted);
  const failedPrintJobsCount = useReactiveVar<number>(failedPrintJobsCountVar);

  useEffect(() => {
    if (session?.deviceProfile?.menu?.id) {
      setSelectedMenuId(session?.deviceProfile?.menu?.id);
    }
  }, [session?.deviceProfile?.menu?.id]);

  useEffect(() => {
    const isLoyaltyEnabled = isFeatureEnabled(FeatureIDs.LOYALTY);
    if (isLoyaltyEnabled) {
      getLoyaltyPrograms();
    }
  }, [isFeatureEnabled, getLoyaltyPrograms]);

  const assignedCustomer = useMemo(
    () => customerMaps[order?.customer?.id || ''],
    [customerMaps, order?.customer?.id],
  );

  const {
    productMaps,
    variantMaps,
    menus: menuOptions,
    loading,
    allPageItemMaps,
    allNestedPages,
    sortedMenuItems,
    refetch,
    getAllMenus,
  } = useCatalogue({ store: currentStoreId, menuId: selectedMenuId });

  const onHandleSelectProduct = (selectedProduct: Product) => {
    if (handleSelectProductFromTakeOrder?.current)
      handleSelectProductFromTakeOrder.current(selectedProduct);
  };

  const onPressSearch = useCallback(() => {
    analyticsService.capture('search_product');
    let selectedProduct: Product;
    const onSubmit = (product: Product) => {
      selectedProduct = product;
    };
    const onCloseModalCompleted = () => {
      if (selectedProduct) onHandleSelectProduct(selectedProduct);
    };
    showModal(
      <SearchProduct onSelectProduct={onSubmit} allProducts={productMaps} />,
      {
        onBackdropPress: closeModal,
        onModalHide: onCloseModalCompleted,
      },
    );
  }, [showModal, productMaps, closeModal]);

  const barcodeMaps: ProductMaps = useMemo(() => {
    const barcodeProductMaps: ProductMaps = {};
    Object.entries(productMaps).forEach(([, product]) => {
      if (product.barcode) {
        barcodeProductMaps[product.barcode] = product;
      }
    });
    return barcodeProductMaps;
  }, [productMaps]);

  const [scannedBarcode, setScannedBarcode] = useState<string[]>([]);

  const onScanBarcode = useCallback(
    (barcodeSearchString: string) => {
      if (barcodeSearchString) {
        const result: Product | undefined = barcodeMaps[barcodeSearchString];
        if (result) {
          onHandleSelectProduct(result);
        } else {
          showModal(
            <NoBarcodeMatchModal
              title={translate('barcodeModal.title')}
              message={translate('barcodeModal.message')}
              onSearch={onPressSearch}
            />,
          );
        }
      }
    },
    [barcodeMaps, onPressSearch, showModal, translate],
  );

  useEffect(() => {
    if (!session?.settings?.enableBarcodeScanningMode) {
      return;
    }
    const lastChar = scannedBarcode[scannedBarcode.length - 1];
    if (lastChar === '\r' || lastChar === 'Enter') {
      //complete string of barcode digits
      const combinedBarcode = scannedBarcode.slice(0, -1).join('');
      onScanBarcode(combinedBarcode);
      setScannedBarcode([]);
    }
  }, [
    scannedBarcode,
    onScanBarcode,
    session?.settings?.enableBarcodeScanningMode,
  ]);

  useEffect(() => {
    if (!session?.settings?.enableBarcodeScanningMode) {
      return;
    }
    if (Platform.OS === 'ios') {
      KeyEvent.onKeyUpListener((keyEvent: { pressedKey: string }) => {
        setScannedBarcode(scannedBarcode => [
          ...scannedBarcode,
          keyEvent.pressedKey,
        ]);
      });

      return () => {
        KeyEvent.removeKeyUpListener();
      };
    }
    if (Platform.OS === 'web') {
      const onKeyDown = (event: KeyboardEvent) => {
        /*
          This will remove the focus from the other element
          so that only barcode scan event triggered
        */
        if (document.activeElement !== document.body) {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          (document.activeElement as any)?.blur();
        }
        setScannedBarcode(scannedBarcode => [...scannedBarcode, event.key]);
      };
      addEventListener('keydown', onKeyDown);
      return () => {
        removeEventListener('keydown', onKeyDown);
      };
    }
  }, [onScanBarcode, session?.settings?.enableBarcodeScanningMode]);

  useEffect(() => {
    if (!session?.settings?.enableBarcodeScanningMode) {
      return;
    }
    if (scannedBarcode.length > 0) {
      const interval = setInterval(() => {
        setScannedBarcode([]);
      }, 1000);
      return () => clearInterval(interval);
    }
  }, [scannedBarcode, session?.settings?.enableBarcodeScanningMode]);

  const onPressPrintReceipt = useCallback(async () => {
    if (pressPrintReceiptFromTakeOrder?.current)
      pressPrintReceiptFromTakeOrder.current();
  }, []);

  const confirmPrintingWithUnSavedData = useCallback(() => {
    showModal(
      <ConfirmationModal
        title={translate('cart.printWithUnSavedItems.title')}
        message={translate('cart.printWithUnSavedItems.message')}
        onConfirm={(): void => {
          onPressPrintReceipt();
          closeModal();
        }}
      />,
    );
  }, [showModal, onPressPrintReceipt, translate, closeModal]);

  const onAssignCustomerToOrder = useCallback(
    customer => {
      if (!getOnAccountPayment(order?.payments ?? []) && !isOrderComplete) {
        analyticsService.capture('assign_customer', {
          location: 'Take Order',
        });
        if (assignCustomerFromTakeOrder?.current) {
          assignCustomerFromTakeOrder?.current(customer);
        }
      }
    },
    [isOrderComplete, order?.payments],
  );

  const onUnAssignCustomerToOrder = useCallback(() => {
    if (unassignCustomerFromOrder?.current)
      unassignCustomerFromOrder.current(null);
  }, []);

  const newOrderButtonOnSaveDiscardModal = useCallback(
    (callback?: Function) => {
      if (newOrderButtonOnSaveDiscardModalFromTakeOrder?.current)
        newOrderButtonOnSaveDiscardModalFromTakeOrder.current(callback);
    },
    [],
  );

  const onPressFloorView = useCallback(() => {
    navigation.navigate('FloorView');
  }, [navigation]);

  const onPressPrintOrder = useCallback(
    (hasOrders: boolean) => {
      analyticsService.capture('print_receipt', {
        location: 'Action Bar',
      });

      if (hasOrders) {
        if (isDirty) {
          confirmPrintingWithUnSavedData();
        } else {
          onPressPrintReceipt();
        }
      }
    },
    [confirmPrintingWithUnSavedData, isDirty, onPressPrintReceipt],
  );

  const HeaderActionsRight = useMemo(() => {
    const hasOrders = (order?.orderItems || []).length > 0;
    const enableFloorView = session?.deviceProfile?.enableFloorView;
    const isTableFeatureEnabled = isFeatureEnabled(
      FeatureIDs.TABLE_MANAGEMENT,
      session.currentVenue?.id,
    );

    return (
      <>
        {enableFloorView && isTableFeatureEnabled && (
          <ButtonIcon
            testID="btn-floorView"
            size={44}
            icon="crockery"
            type="interface"
            onPress={onPressFloorView}
            containerStyle={styles.btnGap}
          />
        )}
        <ButtonIcon
          testID="btn-searchProducts"
          size={44}
          icon="search"
          type="interface"
          onPress={onPressSearch}
          containerStyle={styles.btnGap}
        />
        <View style={styles.btnPrint}>
          {failedPrintJobsCount ? <View style={styles.indicator} /> : null}
          <ButtonActions
            testID="btn-printOrder"
            height={44}
            icon="print"
            label="Print"
            type="interface"
            disabled={!failedPrintJobsCount && !hasOrders}
            actions={[
              {
                id: 'btn-printCurrentReceipt',
                label: translate('order.header.printCurrentReceipt'),
                disabled: !hasOrders,
                action: () => onPressPrintOrder(hasOrders),
              },
              {
                id: 'btn-viewPrintingQueue',
                label: translate('order.header.viewPrintingQueue', {
                  count: failedPrintJobsCount,
                }),
                action: () => navigation.navigate('PrintJobs'),
              },
            ]}
          />
        </View>
        <NewOrderButton
          isCartUnsaved={isCartUnSaved}
          onSave={newOrderButtonOnSaveDiscardModal}
        />
      </>
    );
  }, [
    order?.orderItems,
    session?.deviceProfile?.enableFloorView,
    session.currentVenue?.id,
    isFeatureEnabled,
    onPressFloorView,
    onPressSearch,
    failedPrintJobsCount,
    translate,
    isCartUnSaved,
    newOrderButtonOnSaveDiscardModal,
    onPressPrintOrder,
    navigation,
  ]);

  const HeaderActionsLeft = useMemo(() => {
    const customerName = assignedCustomer
      ? `${assignedCustomer?.firstName} ${assignedCustomer?.lastName}`
      : translate('button.addCustomer');

    if (!orderId) {
      return <></>;
    }

    return (
      <AssignCustomer
        secondary
        orderId={orderId}
        name={customerName}
        assignedCustomer={assignedCustomer}
        payments={undefined}
        loyaltySettings={loyaltySettings}
        rewardRules={rewardRules}
        onAssign={onAssignCustomerToOrder}
        onUnassign={onUnAssignCustomerToOrder}
        containerStyles={styles.inputCustomer}
        loading={customerLoading}
      />
    );
  }, [
    assignedCustomer,
    translate,
    orderId,
    loyaltySettings,
    rewardRules,
    onAssignCustomerToOrder,
    onUnAssignCustomerToOrder,
    customerLoading,
  ]);

  useEffect(() => {
    const subscription = syncAppUtility.getSubscriptionState$.subscribe(() => {
      refetch();
    });
    return () => {
      subscription.unsubscribe();
    };
  }, [refetch]);

  useEffect(() => {
    getAllMenus();
  }, [getAllMenus]);

  useEffect(() => {
    if (!assignedCustomer && order?.customer?.id) {
      getCustomerById(order.customer.id);
    }
  }, [assignedCustomer, getCustomerById, order?.customer?.id]);

  const tabs = useMemo(() => {
    return (
      <OrdersSegmentTabs
        activeScreen={AppScreen.NEW_ORDER}
        previousScreen={AppScreen.NEW_ORDER}
      />
    );
  }, []);

  return (
    <ScreenLayout
      title="Order"
      hideNewOrder
      loading={loading}
      scrollEnabled={false}
      actionsLeft={HeaderActionsLeft}
      actionsRight={HeaderActionsRight}
      tabs={tabs}
    >
      <TakeOrderContainer
        menuId={selectedMenuId}
        onChangeMenuId={setSelectedMenuId}
        onAssignCustomer={assignCustomerFromTakeOrder}
        unAssignCustomer={unassignCustomerFromOrder}
        newOrderButtonOnSaveCallback={
          newOrderButtonOnSaveDiscardModalFromTakeOrder
        }
        handleSelectProductFromSearch={handleSelectProductFromTakeOrder}
        pressPrintReceiptCallback={pressPrintReceiptFromTakeOrder}
        testID={'TakeOrderScreen'}
        menus={menuOptions}
        allPageItemMaps={allPageItemMaps}
        allVariants={variantMaps}
        allProducts={productMaps}
        allNestedPages={allNestedPages}
        sortedMenuItems={sortedMenuItems}
        assignedCustomer={assignedCustomer}
      />
    </ScreenLayout>
  );
};

export default TakeOrderLayout;
