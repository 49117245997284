import React from 'react';
import {
  View,
  Text,
  ViewStyle,
  TouchableOpacity,
  StyleSheet,
} from 'react-native';
import theme from '../../../common/default-theme';
import Icon from '../../../components/Icon/Icon';

interface InputToggleProps {
  testID: string;
  isToggled: boolean;
  isPartial?: boolean;
  onToggle?: () => void;
  title?: string;
  subtitle?: string;
  containerStyle?: ViewStyle | ViewStyle[];
  type?: 'checkbox' | 'switch' | 'eye' | 'radio';
  disabled?: boolean;
  partialIconColor?: string;
  direction?: 'ltr' | 'rtl';
}

const getToggleIcon = (
  type: string,
  isToggled?: boolean,
  isPartial?: boolean,
) => {
  switch (type) {
    case 'switch':
      if (isToggled) {
        return 'toggle-on';
      } else {
        return 'toggle-off';
      }
    case 'eye':
      if (isToggled) {
        return 'eye';
      } else {
        return 'eye-slash';
      }
    case 'radio':
      if (isToggled) {
        return 'check-circle';
      } else {
        return 'circle';
      }
    default:
      if (isPartial) {
        return 'minus-square-full';
      } else if (isToggled) {
        return 'check-square';
      } else {
        return 'square-full';
      }
  }
};

const InputToggle: React.FC<InputToggleProps> = ({
  testID,
  type = 'checkbox',
  title,
  subtitle,
  onToggle,
  isToggled,
  isPartial,
  containerStyle,
  disabled,
  partialIconColor = '',
  direction = 'ltr',
}: InputToggleProps) => {
  return (
    <View style={containerStyle}>
      <TouchableOpacity
        testID={testID}
        disabled={disabled}
        onPress={onToggle}
        style={[
          styles.container,
          {
            backgroundColor: disabled
              ? theme.colors.grey1
              : isPartial
              ? theme.colors.transparent
              : isToggled
              ? theme.colors.states.positiveXL
              : undefined,
          },
          // eslint-disable-next-line react-native/no-inline-styles
          {
            flexDirection: direction === 'rtl' ? 'row-reverse' : 'row',
          },
        ]}
      >
        <View style={styles.iconContainer}>
          <Icon
            size={20}
            name={getToggleIcon(type, isToggled, isPartial)}
            color={
              disabled
                ? theme.colors.grey4
                : isPartial
                ? partialIconColor || theme.colors.states.neutral
                : isToggled
                ? theme.colors.states.positive
                : theme.colors.grey5
            }
          />
        </View>
        {!!title && (
          <View style={styles.textContainer}>
            <Text style={styles.title} numberOfLines={1}>
              {title}
            </Text>
            {!!subtitle && <Text style={styles.subtitle}>{subtitle}</Text>}
          </View>
        )}
      </TouchableOpacity>
    </View>
  );
};

export default InputToggle;

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    borderRadius: theme.radius.s,
  },
  iconContainer: {
    width: 44,
    height: 44,
    alignItems: 'center',
    justifyContent: 'center',
    alignSelf: 'flex-start',
  },
  textContainer: {
    paddingRight: 12,
    flex: 1,
  },
  title: {
    lineHeight: 44,
  },
  subtitle: {
    color: theme.colors.grey6,
    paddingBottom: 16,
    lineHeight: 20,
  },
});
