import React, { useCallback, useMemo } from 'react';
import { pascalCase } from 'change-case';
import { Text, View, TouchableOpacity, Platform } from 'react-native';
import { WithFeature } from '../../../features/WithFeature';
import Icon from '../../../Icon/Icon';
import styles from './NavigationTab.styles';
import theme from '../../../../common/default-theme';
import { analyticsService } from '../../../../analytics/AnalyticsService';
import { NavMenuProps } from '../../../../state/navigation';

export interface NavigationTabProps {
  title: string;
  subnav?: string[];
  subroute?: NavMenuProps['route'];
  active?: boolean;
  selected?: string;
  icon: string;
  onPress?: (route: string) => void;
  onSelectSubNav?: (route: string) => void;
  collapsed: boolean;
  beta?: boolean;
  externalLink?: boolean;
}

const PASCAL_AND_CAMEL_CASE_REGEX = new RegExp('(?<=[a-z])(?=[A-Z])', 'g');

const NavigationTab: React.FC<NavigationTabProps> = ({
  icon,
  title,
  active,
  subroute,
  selected,
  collapsed,
  beta,
  externalLink,
  onPress = () => undefined,
  onSelectSubNav = () => undefined,
}: NavigationTabProps) => {
  const renderSubRoutes = useMemo(() => {
    return subroute?.filter(route => !(Platform.OS !== 'web' && route.webOnly));
  }, [subroute]);

  const firstChild = renderSubRoutes ? renderSubRoutes[0] : null;
  const defaultRoute = firstChild
    ? `${pascalCase(title)}::${pascalCase(firstChild.routing)}`
    : title;

  const onPressTab = useCallback(
    (route: string) => {
      analyticsService.capture('navigation', {
        event: 'nav_item_pressed',
        tab: title,
      });
      onPress(route);
    },
    [onPress, title],
  );

  const onPressSubTab = useCallback(
    (route: string, title: string) => {
      analyticsService.capture('navigation', {
        event: 'nav_item_pressed',
        tab: title,
      });

      onSelectSubNav(route);
    },
    [onSelectSubNav],
  );

  const titleFormatted = useMemo(() => {
    // Converting "helloText" and "HelloText"
    // to "hello Text" and "Hello Text"
    let text = title.replace(PASCAL_AND_CAMEL_CASE_REGEX, ' ');

    if (beta) text = text.replace('Beta', '').trim();
    if (externalLink) text += ' ↗';

    return text;
  }, [title, externalLink, beta]);

  return (
    <View testID="navigation-tab" style={styles.container}>
      <TouchableOpacity
        testID="parent-button"
        onPress={() => onPressTab(defaultRoute)}
        style={[styles.parentContainer, active && styles.activeParent]}
      >
        <View style={styles.iconContainer}>
          <Icon
            size={20}
            name={icon}
            color={active ? theme.colors.black : theme.colors.white}
          />
        </View>
        {!collapsed && (
          <View style={styles.tabTitle}>
            <Text
              style={[
                styles.text,
                {
                  color: active ? theme.colors.black : theme.colors.white,
                },
              ]}
            >
              {titleFormatted}
            </Text>
            {beta ? <Text style={styles.beta}>New</Text> : null}
          </View>
        )}
      </TouchableOpacity>
      {!collapsed && renderSubRoutes && renderSubRoutes.length > 1 && (
        <View testID="links-container" style={styles.childrenContainer}>
          {(renderSubRoutes || []).map((child, i) => {
            const route = `${pascalCase(title)}::${pascalCase(child.routing)}`;

            return (
              <WithFeature key={i} feature={child.feature}>
                <TouchableOpacity
                  key={i}
                  testID={`link-${i}`}
                  style={styles.child}
                  onPress={() => onPressSubTab(route, title)}
                >
                  <Text
                    style={[
                      styles.childText,
                      {
                        color:
                          selected === child.routing
                            ? theme.colors.white
                            : theme.colors.grey6,
                      },
                    ]}
                  >
                    {child.title}
                  </Text>
                </TouchableOpacity>
              </WithFeature>
            );
          })}
        </View>
      )}
    </View>
  );
};

NavigationTab.defaultProps = {
  subnav: [],
};

export default NavigationTab;
