import React, { useCallback, useEffect, useState } from 'react';
import { View, Platform, StyleSheet, StatusBar } from 'react-native';
import { Helmet } from 'react-helmet';
import { App } from '@oolio-group/domain';
import { useTranslation } from '@oolio-group/localization';
import {
  RouteProp,
  useFocusEffect,
  useNavigation,
  useRoute,
} from '@react-navigation/native';
import {
  useNotification,
  useNotificationsList,
} from '../../../hooks/Notification';
import { useSession } from '../../../hooks/app/useSession';
import usePOSUserRoles from '../../../hooks/app/users/usePOSUserRoles';
import { useAuthentication } from '../../../hooks/app/useAuthentication';
import usePOSUserAuthorization from '../../../hooks/app/users/usePOSUserAuthorization';
import { OfficeUser, PosUser, userUtility } from '../../../state/userUtility';
import { AppScreen } from '../../../types/AppScreen';
import { auth0Config } from '../../../constants';
import theme from '../../../common/default-theme';
import PosUserPin from './PosUserPin';
import ActiveUsers from './ActiveUsers';
import LoadingScreen from '../../Loading/Loading';
import Gradient from '../../../components/Gradient/Gradient';
import { NotificationList } from '../../../components/Notification/NotificationList';

enum UserActiveScreen {
  OFFICE_USERS = 'office users',
  OFFICE_USER_LOGIN = 'office user login',
  OFFICE_NEW_USER_LOGIN = 'office new user login',
  POS_USERS = 'pos users',
  POS_USER_LOGIN = 'pos user login',
}

export const LockScreen: React.FC = () => {
  const [session] = useSession();
  const navigation = useNavigation();
  const { translate } = useTranslation();
  const notifications = useNotificationsList();
  const { canUserAccessPOSApp } = usePOSUserAuthorization();
  const { width, height } = theme.useResponsiveDimensions();
  const { showNotification, closeNotification } = useNotification();
  const {
    fetchStoreUsers,
    loading,
    error,
    users: posUsers,
  } = usePOSUserRoles();

  const { defaultSaleScreen } = session.deviceProfile || {};

  const [currentChosenUser, setCurrentChosenUser] = useState<
    OfficeUser | PosUser
  >();
  const route =
    useRoute<
      RouteProp<
        { Lock: { app: App; notAutoRedirect: boolean; skipInit: boolean } },
        'Lock'
      >
    >();
  const [userScreen, setUserScreen] = useState<UserActiveScreen>();

  const { login } = useAuthentication({
    redirectUrl: auth0Config.redirectUrl,
    onAuthorized: async () => undefined,
  });

  const loadParams = useCallback(() => {
    if (route.params && route.params.app && !userScreen) {
      // the default user screen loaded will be based on params or the current active application
      // If there is backoffice login session -> allow user to login
      // else request a Back Office login session
      if (route.params.app === App.BACKOFFICE) {
        login().then(() => {
          if (Platform.OS !== 'web') {
            navigation.navigate('BackOffice');
          }
        });
      } else if (route.params.app === App.POS_APP) {
        setUserScreen(UserActiveScreen.POS_USERS);
      }
    } else if (!userScreen) {
      setUserScreen(UserActiveScreen.POS_USERS);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [route.params, userScreen]);

  useFocusEffect(loadParams);

  const onPressGotoOffice = useCallback(async () => {
    // if same user -> navigate
    // if not same user, navigate to last recent active back office user
    // if no backoffice user at all , do logout!
    setCurrentChosenUser(undefined);
    await login();
    if (Platform.OS !== 'web') {
      navigation.navigate('BackOffice');
    }
  }, [login, navigation]);

  const onSelectGoToPOS = useCallback(() => {
    // To lock screen
    setUserScreen(UserActiveScreen.POS_USERS);
    setCurrentChosenUser(undefined);
  }, []);

  const onPosUserVerificationComplete = useCallback(
    async (user: PosUser) => {
      userUtility.setPosUser({
        id: user?.id,
        name: user?.name,
        pin: user?.pin,
      });
      setCurrentChosenUser(undefined);
      setUserScreen(undefined);

      const screen = {
        name: defaultSaleScreen ? AppScreen[defaultSaleScreen] : 'Orders',
        params: { showSpinner: true },
      };

      navigation.navigate('Orders', {
        screen,
      });
    },
    [defaultSaleScreen, navigation],
  );

  const onSelectPosUser = useCallback(
    (user: OfficeUser | PosUser) => {
      // To lock screen
      if (user.skipPin) {
        onPosUserVerificationComplete(user as PosUser);
      } else {
        setUserScreen(UserActiveScreen.POS_USER_LOGIN);
        setCurrentChosenUser(user);
      }
    },
    [onPosUserVerificationComplete],
  );

  useEffect(() => {
    if (error) {
      showNotification({
        error: true,
        message: error,
      });
    }
  }, [error, showNotification]);

  const canLoad = useCallback(async () => {
    if (userUtility.posUser !== undefined) {
      if (
        canUserAccessPOSApp(userUtility.posUser.id) &&
        !route.params?.notAutoRedirect
      ) {
        onPosUserVerificationComplete(userUtility.posUser);
      }
    }
  }, [
    onPosUserVerificationComplete,
    canUserAccessPOSApp,
    route.params?.notAutoRedirect,
  ]);

  useEffect(() => {
    canLoad();
  }, [canLoad]);

  const getStoreUsersForCurrentLocation = useCallback(() => {
    if (session?.currentStore?.id && !route?.params?.skipInit) {
      fetchStoreUsers(session.currentStore.id);
    }
  }, [session?.currentStore?.id, fetchStoreUsers, route?.params?.skipInit]);

  useFocusEffect(getStoreUsersForCurrentLocation);

  let renderContent: null | React.ReactNode = null;
  if (userScreen === UserActiveScreen.POS_USERS) {
    renderContent = (
      <ActiveUsers
        users={posUsers}
        onSelectedUser={onSelectPosUser}
        btnAttrs={{
          title: translate('interimLockScreen.pos.gotoOffice'),
          onPress: onPressGotoOffice,
        }}
      />
    );
  } else if (
    userScreen === UserActiveScreen.POS_USER_LOGIN &&
    currentChosenUser
  ) {
    renderContent = (
      <PosUserPin
        onBack={onSelectGoToPOS}
        onVerificationComplete={onPosUserVerificationComplete}
        user={currentChosenUser as PosUser}
      />
    );
  }

  if (loading) {
    return <LoadingScreen />;
  }

  return (
    <>
      <Helmet>
        <title>{translate('navigation.switchUser')}</title>
      </Helmet>
      <StatusBar barStyle="light-content" />
      <View style={{ width, height }}>
        <Gradient style={styles.content}>{renderContent}</Gradient>
      </View>
      <NotificationList
        notifications={notifications}
        onCloseNotification={closeNotification}
      />
    </>
  );
};

export default LockScreen;

const styles = StyleSheet.create({
  content: {
    flexGrow: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
});
