import { StyleSheet } from 'react-native';
import theme from '../../../../common/default-theme';

const styles = StyleSheet.create({
  cellName: {
    width: 200,
    marginRight: 10,
  },
  cellType: {
    flex: 1,
  },
  headerCount: {
    width: 80,
    marginRight: 22,
    textAlign: 'center',
  },
  cellCount: {
    minWidth: 80,
    paddingVertical: 10,
    paddingHorizontal: 16,
    textAlign: 'center',
    borderRadius: theme.radius.s,
    fontFamily: theme.fonts.medium,
    backgroundColor: theme.colors.grey1,
  },
  rowLight: {
    width: 4,
    height: 28,
    marginRight: 8,
    borderRadius: 2,
    backgroundColor: theme.colors.red,
  },
});

export default styles;
