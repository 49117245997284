import React, { useEffect, useState } from 'react';
import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs';
import { MaterialTopTabBarProps } from '@react-navigation/material-top-tabs/src/types';
import TabBar from '../../../../components/TabBar/TabBar';
import { useTranslation } from '@oolio-group/localization';
import { Menu } from './ManageMenu/ManageMenu';
import { MenuSchedules } from './ManageSchedule/MenuSchedules';
import { analyticsService } from '../../../../analytics/AnalyticsService';
import { FEATURES } from '../../../../constants';

const Tab = createMaterialTopTabNavigator();

const MenuTabs: React.FC = () => {
  const { translate } = useTranslation();
  const [isMenuScheduleEnabled, setIsMenuScheduleEnabled] = useState<boolean>();
  useEffect(() => {
    async function getMenuScheduleFlag() {
      const isEnabled = await analyticsService.isFeatureEnabled(
        FEATURES.MENU_SCHEDULE,
      );
      setIsMenuScheduleEnabled(isEnabled);
    }
    getMenuScheduleFlag();
  }, []);

  return (
    <Tab.Navigator
      tabBar={(props): React.ReactNode => (
        <TabBar tabBar={props as MaterialTopTabBarProps} showBack={false} />
      )}
      initialRouteName="ManageMenu"
    >
      <Tab.Screen
        name="ManageMenu"
        component={Menu}
        options={{
          tabBarLabel: translate('backOfficeProducts.tabNames.menu'),
        }}
      />
      {isMenuScheduleEnabled ? (
        <Tab.Screen
          name="MenuSchedules"
          component={MenuSchedules}
          options={{
            tabBarLabel: translate('backOfficeProducts.tabNames.schedule'),
          }}
        />
      ) : null}
    </Tab.Navigator>
  );
};

export default MenuTabs;
