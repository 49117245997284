import React from 'react';
import { CreateModifierGroup } from './OptionGroup';
import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs';
import { MaterialTopTabBarProps } from '@react-navigation/material-top-tabs/src/types';
import TabBar from '../../../../../../components/TabBar/TabBar';
import { useRoute } from '@react-navigation/native';

const Tab = createMaterialTopTabNavigator();

const OptionGroupTabs: React.FC = () => {
  const route = useRoute();
  const params = route.params as { modifierGroupId: string };
  return (
    <Tab.Navigator
      tabBar={(props: MaterialTopTabBarProps): React.ReactNode => (
        <TabBar tabBar={props} />
      )}
      initialRouteName="CreateModifierGroup"
    >
      <Tab.Screen
        name="CreateModifierGroup"
        component={CreateModifierGroup}
        options={{ tabBarLabel: 'Create New' }}
        initialParams={params}
      />
    </Tab.Navigator>
  );
};

export default OptionGroupTabs;
