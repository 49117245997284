import { Platform, StyleSheet } from 'react-native';
import scale from '../../../common/theme';
import theme from '../../../common/default-theme';

const isWeb = Platform.OS === 'web';

const styles = StyleSheet.create({
  inputContainer: {
    height: 44,
    maxHeight: 44,
    borderRadius: 6,
    overflow: 'hidden',
    paddingRight: 12,
    backgroundColor: theme.colors.transparent,
    zIndex: 2,
    flexDirection: 'row',
    alignItems: 'center',
  },
  inputTitleText: {
    fontSize: 14,
    lineHeight: 14,
    marginBottom: 6,
    paddingLeft: 12,
    color: theme.colors.grey6,
  },
  errorText: {
    paddingTop: 6,
    paddingLeft: 12,
    color: theme.colors.red,
  },
  accessoryContainer: {
    position: 'absolute',
    right: 1,
    bottom: 1,
    width: 42,
    height: 42,
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 1,
    backgroundColor: theme.colors.white,
    flexDirection: 'row',
  },
  accessory: {
    position: 'absolute',
    right: 1,
    bottom: 1,
    width: 42,
    height: 42,
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 1,
    backgroundColor: theme.colors.white,
  },
  bgStyle: {
    width: '100%',
    position: 'absolute',
    height: scale.deviceHeight,
  },
  optionsContainer: {
    width: 260,
    backgroundColor: theme.colors.white,
    borderRadius: theme.radius.s,
  },
  optionsPopup: {
    maxHeight: 300,
    paddingVertical: 10,
    borderWidth: isWeb ? 0 : 2,
    borderColor: theme.colors.grey3,
    borderRadius: theme.radius.m,
    backgroundColor: theme.colors.white,
    ...theme.shadow.light,
  },
  search: {
    marginHorizontal: 10,
    marginBottom: 10,
  },
  option: {
    paddingVertical: 12,
    paddingHorizontal: 20,
    flexDirection: 'row',
    alignItems: 'center',
  },
  optionValue: {
    flex: 1,
  },
  optionText: {
    fontSize: 14,
    paddingLeft: 8,
    fontFamily: theme.fonts.medium,
  },
  optionCreateText: {
    color: theme.colors.green,
    fontFamily: theme.fonts.medium,
  },
  optionCreate: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: theme.colors.greenLightest,
    borderRadius: theme.radius.s,
    marginHorizontal: 10,
    height: 44,
    padding: 12,
  },
  valueText: {
    fontSize: 14,
    paddingHorizontal: 12,
    flex: 1,
  },
  placeholder: {
    paddingLeft: 12,
    color: theme.colors.grey5,
  },
  values: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  valueContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    marginLeft: 4,
    borderRadius: 3,
    paddingVertical: 8,
    paddingHorizontal: 10,
    backgroundColor: theme.colors.blueLight,
  },
  value: {
    fontSize: 14,
    color: theme.colors.blue,
    fontFamily: theme.fonts.medium,
  },
  seperator: {
    height: 1,
    marginVertical: 10,
    backgroundColor: theme.colors.grey3,
  },
  labelContainer: {
    height: '100%',
    borderRightWidth: 1,
    paddingHorizontal: 12,
    backgroundColor: theme.colors.grey1,
    borderRightColor: theme.colors.grey3,
    alignItems: 'center',
    justifyContent: 'center',
    borderTopLeftRadius: 6,
    borderBottomLeftRadius: 6,
    marginRight: 1,
  },
  labelText: {
    fontSize: 14,
    color: theme.colors.grey6,
  },
  colourValue: {
    marginRight: 8,
    flexDirection: 'row',
    alignItems: 'center',
    paddingLeft: 12,
  },
  colourContainer: {
    width: 22,
    height: 22,
    marginRight: 8,
    borderRadius: 28,
  },
  prefixLabelContainerStyle: {
    backgroundColor: theme.colors.grey1,
    height: '100%',
    alignItems: 'center',
    flexDirection: 'row',
    borderBottomLeftRadius: theme.radius.s,
    borderTopLeftRadius: theme.radius.s,
  },
  prefixLabelTextStyle: {
    color: theme.colors.grey5,
    paddingHorizontal: theme.spacing.m,
  },
  btnSelect: {
    marginLeft: 5,
    marginRight: 20,
  },
});

export default styles;
