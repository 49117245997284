import React, {
  useRef,
  useMemo,
  useState,
  useEffect,
  useCallback,
} from 'react';
import { View, Text, ViewStyle, FlatList, ScrollView } from 'react-native';
import {
  Course,
  DEFAULT_ENTITY_ID,
  OrderItem,
  OrderItemStatus,
  OrderType,
  OrderTypeCode,
  RewardAdjustment,
  AdjustmentType,
  DEFAULT_TABLE_ABBREVIATION,
  OrderPaymentStatus,
} from '@oolio-group/domain';
import {
  getAdjustmentValue,
  getItemRewardValue,
} from '@oolio-group/order-helper';
import { useTranslation } from '@oolio-group/localization';
import { useSession } from '../../../../hooks/app/useSession';
import { usePreviousValue } from '@oolio-group/hooks';
import { CartRewardSelectionState, CartSelectionState } from '../Cart';
import differenceWith from 'lodash/differenceWith';
import { useRoute } from '@react-navigation/native';
import sortBy from 'lodash/sortBy';
import styles from '../Cart.styles';
import CartNew from '../CartNew/CartNew';
import CartNote from '../CartNote/CartNote';
import CartName from '../CartName/CartName';
import CartSeats from '../CartSeats/CartSeats';
import CartCourse from '../CartCourse/CartCourse';
import CartReward from '../CartReward/CartReward';
import CartProduct from '../CartProduct/CartProduct';
import CartTotalItems, { CartOrderTotalItems } from './CartTotalItems';

export interface CartItemProps {
  orderGuestCount?: number;
  orderNote?: string;
  orderIdentifier?: string;
  deliveryNote?: string;
  items?: OrderItem[];
  rewardItems?: RewardAdjustment[];
  courses?: Course[];
  containerStyle?: ViewStyle;
  scrollToBottom?: boolean;
  selectedItem?: CartSelectionState;
  isRefundOrder?: boolean;
  selectedItemIds?: string[];
  isCoursesEnabled?: boolean;
  readonlyCourse?: boolean;
  orderType?: OrderType;
  subTotal?: number;
  selectedReward?: CartRewardSelectionState;
  seats?: { id: string; title: string }[];
  selectedSeatNumber?: string;
  extraItems?: OrderItem[];
  orderTotals?: CartOrderTotalItems;
  onToggleAutoFire?: (courseId: string) => void;
  onSelectItem?: (state: CartSelectionState) => void;
  onDeselectItem?: () => void;
  onIncrementSeatNumber?: () => void;
  setSelectedSeatNumber?: (seatNumber?: string) => void;
  onSwitchCourseItem?: (courseId: string, orderItemId: string) => void;
  onSelectReward?: (state: CartRewardSelectionState) => void;
}

const CartItems: React.FC<CartItemProps> = ({
  items: itemsProp = [],
  rewardItems,
  orderGuestCount = 0,
  orderNote,
  orderIdentifier,
  containerStyle,
  selectedItem,
  scrollToBottom,
  courses = [],
  selectedItemIds,
  selectedSeatNumber: selectedSeatNumberProp,
  isCoursesEnabled,
  readonlyCourse,
  orderType,
  deliveryNote,
  subTotal,
  selectedReward,
  seats,
  extraItems = [],
  orderTotals,
  onSelectItem,
  onDeselectItem,
  onToggleAutoFire,
  onIncrementSeatNumber,
  setSelectedSeatNumber: onSeatNumberSelect,
  onSwitchCourseItem,
  onSelectReward,
}) => {
  const previousItems = useRef<OrderItem[]>([]);
  const scrollViewRef = useRef<ScrollView>(null);
  const flatListRef = useRef<FlatList<OrderItem> | FlatList<Course>>(null);

  const route = useRoute();
  const [session] = useSession();
  const { translate } = useTranslation();

  const [collapseCourses, setCollapseCourses] = useState<
    Record<string, boolean>
  >({});
  const [selectedSeatNumber, setSelectedSeatNumber] = useState<
    string | undefined
  >(undefined);

  const isSeatManagementEnabled =
    session?.deviceProfile?.enableSeatManagement &&
    orderType?.code === OrderTypeCode.DINE_IN;

  const showSeats = isSeatManagementEnabled && orderGuestCount > 0;

  const itemsBySeats = selectedSeatNumber
    ? itemsProp.filter(
        orderItem => String(orderItem.seatNumber) === selectedSeatNumber,
      )
    : itemsProp;

  const items = itemsBySeats
    .concat(extraItems)
    .filter(item => item.status !== OrderItemStatus.CANCELLED);

  const previousLength = usePreviousValue(items?.length);
  const previousLastItemModifiers = usePreviousValue(
    (items && items.length > 0 && items[items?.length - 1].modifiers) || null,
  );
  const { id: orderId } = (route.params || {}) as {
    id: string;
  };

  const scrollToEnd = () => {
    setTimeout(() => {
      flatListRef && flatListRef.current?.scrollToEnd();
    }, 100);
  };

  const scrollToIndex = (index: number) => {
    if (!flatListRef.current) return;
    flatListRef.current.scrollToIndex({ index });
  };

  useEffect(() => {
    if (
      !isCoursesEnabled &&
      ((scrollToBottom &&
        items &&
        previousLength &&
        items?.length > previousLength) ||
        (scrollToBottom &&
          items &&
          items?.length > 0 &&
          previousLastItemModifiers &&
          items[items?.length - 1].modifiers?.length >
            previousLastItemModifiers.length &&
          items[items?.length - 1].modifiers != previousLastItemModifiers))
    ) {
      scrollToEnd();
      return;
    } else {
      return;
    }
  }, [
    scrollToBottom,
    items,
    previousLength,
    previousLastItemModifiers,
    isCoursesEnabled,
  ]);

  const oldItems = [...items, ...(rewardItems || [])].filter(
    item => item.saved,
  );

  const handleCollapseCourse = useCallback(
    (id: string, status: boolean) => {
      if (status === collapseCourses[id]) return;
      setCollapseCourses(preState => ({
        ...preState,
        [id]: status,
      }));
    },
    [collapseCourses],
  );

  const handleCoursePressHeader = useCallback(
    (courseId: string) => {
      const allowSwitchOrderItemStatus = [
        OrderItemStatus.CREATED,
        OrderItemStatus.ON_HOLD,
      ];
      const selectOrderItem = items.find(
        item => item.id === selectedItem?.item,
      ) as OrderItem;

      onDeselectItem?.();
      if (
        !selectOrderItem ||
        !allowSwitchOrderItemStatus.includes(selectOrderItem.status)
      )
        return;

      const defaultCourseId = selectOrderItem.course?.id || DEFAULT_ENTITY_ID;
      if (courseId === defaultCourseId) return;

      onSwitchCourseItem && onSwitchCourseItem(selectOrderItem?.id, courseId);
    },
    [items, onDeselectItem, onSwitchCourseItem, selectedItem?.item],
  );

  useEffect(() => {
    if (!orderId) return;
    setCollapseCourses({});
  }, [orderId]);

  useEffect(() => {
    setSelectedSeatNumber(selectedSeatNumberProp);
  }, [selectedSeatNumberProp]);

  useEffect(() => {
    if (!isCoursesEnabled || !courses.length) return;

    const recentUpsertItems = differenceWith(
      items,
      previousItems.current,
      (preItem, nextItem) => {
        return (
          preItem.id === nextItem.id &&
          preItem?.product?.id === nextItem.product.id &&
          preItem.modifiers?.length === nextItem.modifiers?.length
        );
      },
    );

    if (recentUpsertItems.length) {
      const focusProduct = recentUpsertItems[0].product;
      const courseIndex = Math.max(
        courses.findIndex(course => focusProduct.course?.id === course.id),
        0,
      );
      scrollToIndex(courseIndex);
      handleCollapseCourse(courses[courseIndex].id, false);
      previousItems.current = items;
    }
  }, [isCoursesEnabled, courses, items, handleCollapseCourse]);

  const renderItem = useCallback(
    (item: OrderItem | RewardAdjustment, index: number) => {
      const internalRender = () => {
        if (isRewardItem(item)) {
          // renders for RewardItem
          return (
            <CartReward
              id={item.id}
              key={item.id}
              name={item.name}
              quantity={item.quantity}
              amount={getAdjustmentValue(subTotal || 0, [item])}
              testID={`cart-item-${index}`}
              onSelectReward={onSelectReward}
              isSelected={selectedReward?.reward === item.id}
            />
          );
        } else {
          // renders for OrderItem
          let isSelected = selectedItem?.item === item.id;

          if (!isSelected) {
            const isItemSelected = selectedItemIds?.find(
              itemId => itemId === item.id,
            );
            isSelected = isItemSelected ? true : false;
          }

          const selectedSubItem =
            selectedItem?.selectedModifierKey ||
            selectedItem?.selectedVariantKey;

          const SeatNumberLabel = showSeats
            ? `@${String(item?.seatNumber || DEFAULT_TABLE_ABBREVIATION)}`
            : undefined;

          const reward = item.adjustments?.find(
            adj => adj.adjustmentType === AdjustmentType.REWARD,
          ) as RewardAdjustment;

          return (
            <>
              <CartProduct
                testID={`cart-item-${index}`}
                product={item}
                isSelected={isSelected}
                isFired={item.itemFired}
                isPaid={item.paymentStatus === OrderPaymentStatus.COMPLETE}
                seat={SeatNumberLabel}
                onSelect={onSelectItem}
                selectedSubItem={selectedSubItem}
              />
              {reward ? (
                <CartReward
                  id={reward.id}
                  key={reward.id}
                  name={reward.name}
                  quantity={reward.itemQuantity || reward.quantity}
                  amount={getItemRewardValue(item, [reward])}
                  testID={`cart-item-${index}`}
                  onSelectReward={onSelectReward}
                  isSelected={
                    selectedReward?.reward === reward.id &&
                    selectedReward?.productId === item.product.id
                  }
                  productId={item.product.id}
                />
              ) : null}
            </>
          );
        }
      };

      const newLabel = !isCoursesEnabled &&
        (oldItems?.length || 0) > 0 &&
        oldItems?.length === index && <CartNew />;

      return (
        <>
          {newLabel}
          {internalRender()}
        </>
      );
    },
    [
      isCoursesEnabled,
      oldItems?.length,
      subTotal,
      onSelectReward,
      selectedReward?.reward,
      selectedReward?.productId,
      selectedItem?.item,
      selectedItem?.selectedModifierKey,
      selectedItem?.selectedVariantKey,
      showSeats,
      onSelectItem,
      selectedItemIds,
    ],
  );

  const renderCourseItem = useCallback(
    (course: Course) => {
      const selectedProducts = items.filter(item => {
        if (course.id === DEFAULT_ENTITY_ID) return !item.course?.id;
        return item.course?.id === course.id;
      });

      if (!selectedProducts.length) return null;

      const firedItemsCount = selectedProducts.reduce((total, item) => {
        if (item.itemFired && item.status !== OrderItemStatus.VOID)
          total += item.quantity;
        return total;
      }, 0);

      const totalItemsCount = selectedProducts.reduce((total, item) => {
        if (item.status !== OrderItemStatus.VOID) total += item.quantity;
        return total;
      }, 0);

      const name =
        !readonlyCourse && firedItemsCount > 0
          ? `${course.name} (${firedItemsCount}/${totalItemsCount})`
          : course.name;

      const newItems = selectedProducts?.filter(item => !item.saved);

      const savedUnfiredItems = selectedProducts?.filter(
        item =>
          item.saved &&
          !item.itemFired &&
          item.paymentStatus !== OrderPaymentStatus.COMPLETE,
      );

      const firedItems = selectedProducts?.filter(
        item =>
          item.saved &&
          item.itemFired &&
          item.paymentStatus !== OrderPaymentStatus.COMPLETE,
      );

      const paidItems = selectedProducts?.filter(
        item => item.paymentStatus === OrderPaymentStatus.COMPLETE,
      );

      const showDashedLine =
        (savedUnfiredItems.length || firedItems.length) && newItems.length;

      return (
        <CartCourse
          name={name}
          id={course.id}
          autoFire={course.autoFire}
          onToggleAutoFire={onToggleAutoFire}
          readonlyCourse={readonlyCourse}
          disabled={firedItemsCount === totalItemsCount}
          isCollapsed={collapseCourses[course.id]}
          onCollapseCourse={handleCollapseCourse}
          onPressCourseHeader={handleCoursePressHeader}
        >
          {newItems?.map((item, index) => (
            <View key={item.id}>{renderItem(item, index)}</View>
          ))}
          {Boolean(showDashedLine) && <View style={styles.separator} />}
          {savedUnfiredItems?.map((item, index) => (
            <View key={item.id}>{renderItem(item, index)}</View>
          ))}
          {firedItems?.map((item, index) => (
            <View key={item.id}>{renderItem(item, index)}</View>
          ))}
          {paidItems?.map((item, index) => (
            <View key={item.id}>{renderItem(item, index)}</View>
          ))}
        </CartCourse>
      );
    },
    [
      items,
      readonlyCourse,
      onToggleAutoFire,
      collapseCourses,
      handleCollapseCourse,
      handleCoursePressHeader,
      renderItem,
    ],
  );

  const onPressSeatNumber = (seatNumber?: string) => {
    if (seatNumber == '+') {
      onIncrementSeatNumber && onIncrementSeatNumber();
      return;
    }
    onSeatNumberSelect && onSeatNumberSelect(seatNumber);
    setSelectedSeatNumber(seatNumber);
  };

  const isRewardItem = (
    item: OrderItem | RewardAdjustment,
  ): item is RewardAdjustment => {
    return (
      'adjustmentType' in item && item.adjustmentType === AdjustmentType.REWARD
    );
  };

  const itemsToRender: Array<OrderItem | RewardAdjustment> = useMemo(() => {
    if (rewardItems && rewardItems.length > 0) {
      return [...items, ...rewardItems].sort(
        (itemA, itemB) => (itemA.createdAt || 0) - (itemB.createdAt || 0),
      );
    }
    return sortBy(items, item => item.saved !== true);
  }, [items, rewardItems]);

  return (
    <View
      testID="cart-items-container"
      style={[styles.itemsContainer, containerStyle]}
    >
      {orderIdentifier ? (
        <CartName testID="order-name" name={orderIdentifier} />
      ) : null}
      {showSeats ? (
        <CartSeats
          numberOfSeat={orderGuestCount || 0}
          onSelectSeatNumber={onPressSeatNumber}
          selectedSeatNumber={selectedSeatNumber}
          seats={seats}
        />
      ) : null}
      {orderNote ? <CartNote testID="note-order" note={orderNote} /> : null}
      {deliveryNote ? (
        <CartNote
          testID="note-delivery"
          note={translate('order.deliveryNote', { value: deliveryNote })}
        />
      ) : null}
      {items.length >= 1 ? (
        <ScrollView
          ref={scrollViewRef}
          showsVerticalScrollIndicator={false}
          onContentSizeChange={() =>
            scrollViewRef.current?.scrollToEnd({ animated: true })
          }
          contentContainerStyle={styles.grow}
        >
          <View style={styles.grow}>
            <View
              testID={isCoursesEnabled ? 'order-courseItems' : 'order-items'}
              style={styles.items}
            >
              {isCoursesEnabled
                ? courses.map(course => {
                    return renderCourseItem(course);
                  })
                : itemsToRender.map((item, i) => {
                    return renderItem(item, i);
                  })}
            </View>
            {orderTotals ? <CartTotalItems {...orderTotals} /> : undefined}
          </View>
        </ScrollView>
      ) : (
        <View style={styles.emptyCart}>
          <Text style={styles.emptyCartText}>
            {translate('cart.emptyCart', {
              context: selectedSeatNumber
                ? translate('payment.seat').toLowerCase()
                : translate('onlineOrders.cart').toLowerCase(),
            })}
          </Text>
        </View>
      )}
    </View>
  );
};

export default React.memo(CartItems);
