import { useSubscription } from '@apollo/client/react/hooks';
import { App, DeviceEventActions, FeatureIDs } from '@oolio-group/domain';
import { ModalProvider } from '@oolio-group/rn-use-modal';
import { useIsFocused, useNavigation } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import React, { useCallback, useEffect, useState } from 'react';
import { Dimensions } from 'react-native';
import { Subscription, distinctUntilChanged, pluck } from 'rxjs';
import Backdrop from '../../components/Modals/Backdrop';
import Layout from '../../components/Office/Layout/Layout';
import { DeviceEvents } from '../../graphql/subscriptions';
import { useCheckFeatureEnabled } from '../../hooks/app/features/useCheckFeatureEnabled';
import { useLogout } from '../../hooks/app/useLogout';
import useOfficeUserAuthorization from '../../hooks/app/users/useOfficeUserAuthorization';
import useOfficeUserRoles from '../../hooks/app/users/useOfficeUserRoles';
import useRolesContext from '../../hooks/app/users/useRolesContext';
import { useUserRoles } from '../../hooks/app/users/useUserRoles';
import { useSession } from '../../hooks/app/useSession';
import { navigateToLockScreen } from '../../state/navigation';
import { onboardingUtility } from '../../state/onboardingUtility';
import {
  getOnboardingStatus,
  setOnboardingStatus,
} from '../../state/preferences';
import { userUtility } from '../../state/userUtility';
import { noopHandler } from '../../utils/errorHandlers';
import { LoadingScreen } from '../Loading/Loading';
import AccountStack from './Account/AccountStack';
import CustomerStack from './Customers/CustomerStack';
import DashboardStack from './Dashboard/DashboardStack';
import FeaturesStack from './Features/FeaturesAndIntegrationsStack';
import LoyaltyStack from './Loyalty/LoyaltyStack';
import ProductStack from './Products/ProductsStack';
import ReportsStack from './Reports/ReportsStack';
import SettingsStack from './Settings/SettingsStack';
import UsersStack from './Users/UsersStack';
import { tokenUtility } from '../../state/tokenUtility';
import { AuthState, FEATURES } from '../../constants';
import { analyticsService } from '../../analytics/AnalyticsService';
import { isAdvanceDiscountEnabledVar } from '../../state/cache';

const Stack = createStackNavigator();
const { height, width } = Dimensions.get('window');

const BackOfficeNavigator: React.FC = () => {
  const navigation = useNavigation();
  const [session, setSession] = useSession();
  const { canAccessBackOffice, canAccessPOSApp } = useOfficeUserAuthorization();
  const { logout } = useLogout();
  const isFocused = useIsFocused();
  const isFeatureEnabled = useCheckFeatureEnabled();
  const isLoyaltyEnabled = isFeatureEnabled(FeatureIDs.LOYALTY);

  const [isReady, setIsReady] = useState(false);
  const [authState, setAuthState] = useState<AuthState>(AuthState.LOADING);

  const { getUserRoles } = useUserRoles();
  const { updateUserRoles } = useOfficeUserRoles();
  const { fetchRolesSync, updateRoles } = useRolesContext();

  const restoreOnboarding = useCallback(async () => {
    // on page reload, we need to restore the onboarding state
    const onboarding = await getOnboardingStatus();
    if (onboarding) {
      onboardingUtility.setOnboardingInfo(onboarding);
    }
  }, []);

  useEffect(() => {
    async function getAdvanceDiscountFlag() {
      const isEnabled = await analyticsService.isFeatureEnabled(
        FEATURES.CATEGORY_DISCOUNT,
      );
      isAdvanceDiscountEnabledVar(isEnabled);
    }
    getAdvanceDiscountFlag();
  }, []);

  useEffect(() => {
    restoreOnboarding();
  }, [restoreOnboarding]);

  useEffect(() => {
    const subscription: Subscription =
      onboardingUtility.getOnboardingInfo$.subscribe(onboarding => {
        setOnboardingStatus(onboarding);
      });

    return () => subscription.unsubscribe();
  }, []);

  const onDeviceEvent = useCallback(
    ({ subscriptionData }) => {
      if (subscriptionData?.data?.['deviceEvents']) {
        const deviceEvent = subscriptionData.data['deviceEvents'][0];
        switch (deviceEvent.action) {
          case DeviceEventActions.LOGOUT_ACTION:
            setSession({
              ...session,
              currentVenue: undefined,
              currentStore: undefined,
              device: undefined,
            });
            break;
          default:
            break;
        }
      }
    },
    [session, setSession],
  );

  useSubscription(DeviceEvents, {
    variables: {
      storeId: session?.currentStore?.id,
      deviceId: session?.device?.id,
    },
    shouldResubscribe: true,
    onSubscriptionData: onDeviceEvent,
  });

  const canLoad = useCallback(
    async (hasBackOfficeAccess = false, hasPOSAccess = false) => {
      const userActivity = userUtility.userActivity;
      const recentOfficeUser = userUtility.recentOfficeUser;
      if (hasBackOfficeAccess || hasPOSAccess) {
        if (hasBackOfficeAccess) {
          if (
            recentOfficeUser &&
            recentOfficeUser.id === userActivity.recentUserId &&
            recentOfficeUser.active
          ) {
            // if recent user is active then do nothing
            noopHandler();
          } else {
            // if recent user is not active, then redirect to interim screen
            navigation.reset(navigateToLockScreen(App.BACKOFFICE));
          }
        } else {
          // if recent user is POS user and has posUser object, redirect to POS app
          navigation.reset(navigateToLockScreen());
        }
      } else {
        // if no user access, do logout
        await logout();
      }
    },
    [logout, navigation],
  );

  useEffect(() => {
    if (isFocused && isReady) {
      canLoad(canAccessBackOffice(), canAccessPOSApp());
    }
  }, [canLoad, isFocused, canAccessBackOffice, canAccessPOSApp, isReady]);

  const fetchAndUpdateRoles = useCallback(
    async (userId: string) => {
      const user = await getUserRoles(userId);
      const roles = await fetchRolesSync();
      updateRoles(roles);
      updateUserRoles(user);
      setIsReady(true);
    },
    [getUserRoles, updateUserRoles, fetchRolesSync, updateRoles],
  );

  useEffect(() => {
    if (authState === AuthState.LOGGED_IN) {
      const recentOfficeUser = userUtility.recentOfficeUser;
      if (recentOfficeUser?.active) {
        fetchAndUpdateRoles(recentOfficeUser.id);
      } else {
        navigation.reset(navigateToLockScreen(App.POS_APP, true));
      }
    }
  }, [authState, fetchAndUpdateRoles, navigation]);

  useEffect(() => {
    const subscription: Subscription = tokenUtility.getTokenInfo$
      .pipe(
        distinctUntilChanged((prev, curr) => prev.authState === curr.authState),
        // select authState
        pluck('authState'),
      )
      .subscribe(authState => {
        setAuthState(authState || AuthState.LOADING);
        if (authState === AuthState.LOGOUT) {
          navigation.reset({
            index: 0,
            routes: [{ name: 'LoginTypeSelection' }],
          });
        }
      });

    return () => {
      subscription?.unsubscribe();
    };
  }, [navigation]);

  if (!isReady) {
    return <LoadingScreen />;
  }

  //TODO: get features
  return (
    <ModalProvider
      modalProps={{
        deviceHeight: height,
        deviceWidth: width,
        customBackdrop: <Backdrop />,
      }}
    >
      <Layout>
        <Stack.Navigator
          headerMode="none"
          initialRouteName="Home"
          screenOptions={{
            cardOverlayEnabled: false,
            cardShadowEnabled: false,
          }}
        >
          <Stack.Screen component={AccountStack} name="Home" />
          <Stack.Screen component={DashboardStack} name="Dashboard" />
          <Stack.Screen component={SettingsStack} name="Settings" />
          <Stack.Screen component={ReportsStack} name="Reports" />
          <Stack.Screen component={ProductStack} name="Products" />
          <Stack.Screen component={UsersStack} name="Users" />
          <Stack.Screen component={CustomerStack} name="Customers" />
          <Stack.Screen component={FeaturesStack} name="Features" />
          {isLoyaltyEnabled && (
            <Stack.Screen component={LoyaltyStack} name="Loyalty" />
          )}
        </Stack.Navigator>
      </Layout>
    </ModalProvider>
  );
};

export default BackOfficeNavigator;
