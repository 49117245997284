import React, { useEffect, useCallback, useState, useMemo } from 'react';
import { Widget, DateRangeGranularity } from '@oolio-group/domain';
import { View, Text, ViewStyle } from 'react-native';
import { useReporting } from '../../../../hooks/app/useReporting';
import { CubejsApi, ResultSet } from '@cubejs-client/core';
import LoadingIndicator from '../../../../components/LoadingIndicator/LoadingIndicator';
import { useNotification } from '../../../../hooks/Notification';
import {
  VictoryChart,
  VictoryAxis,
  VictoryBar,
  VictoryVoronoiContainer,
  VictoryGroup,
  VictoryLegend,
  VictoryTooltip,
} from 'victory-native';
import { Styles } from '../styles/Component.styles';
import { IMap, LineChartType, PivotTableData, DisplayLabels } from '../types';
import {
  convertDateByFormat,
  GRANULARITY_FORMATS,
  BAR_CHART_STYLE,
  CHART_CONTAINER_STYLE,
  CHART_LEGEND_STYLE,
  CHART_X_AXIS_STYLE,
  CHART_Y_AXIS_STYLE,
  CHART_TOOLTIP_STYLE,
  LEGEND_BOTTOM_GAP,
  TOOLTIP_GAP,
  arePropsEqual,
} from '../reportsHelper';
import theme from '../../../../common/default-theme';

interface MultiBarChartProps {
  widget: Widget;
  helper: string;
  keys: IMap<string>[];
  type: LineChartType;
  colorScale: string[];
  dataTransformationFn?: (data: PivotTableData) => PivotTableData;
  cubejsApi: CubejsApi;
  updateCount: number;
}

const MultiBarChartComponent: React.FC<MultiBarChartProps> = ({
  widget,
  helper,
  keys,
  type,
  colorScale,
  dataTransformationFn,
  cubejsApi,
}) => {
  const { showNotification } = useNotification();
  const { width: vw } = theme.useResponsiveDimensions();

  const { loading, error, widgetData, getWidgetData } = useReporting(cubejsApi);

  const [boundingRect, setBoundingRect] = useState({ width: 0, height: 0 });
  const graphRef = useCallback(node => {
    if (node !== null) {
      node.getBoundingClientRect &&
        setBoundingRect(node.getBoundingClientRect());
    }
  }, []);

  const styles = Styles();

  useEffect((): void => {
    if (error) {
      showNotification({
        error: true,
        message: error,
      });
    }
  }, [error, showNotification]);

  useEffect(() => {
    widget.query && getWidgetData(widget, helper);
  }, [widget, getWidgetData, helper]);

  const data = useMemo(() => {
    if (!widgetData || !widget?.name || !widgetData[widget.name])
      return {} as ResultSet;
    return widgetData[widget.name] as ResultSet;
  }, [widgetData, widget]);

  const chartMargin =
    CHART_CONTAINER_STYLE.marginLeft + CHART_CONTAINER_STYLE.marginRight;
  const chartStyle = {
    ...CHART_CONTAINER_STYLE,
    width: vw - chartMargin,
  };

  const serializedData = useMemo(() => {
    let result = data.tablePivot && data.tablePivot();
    if (dataTransformationFn && result) {
      result = dataTransformationFn(result);
    }
    return result || [];
  }, [data, dataTransformationFn]);
  const chartData: {
    x: string | number | boolean;
    y: number;
    text?: string;
    range?: string;
  }[][] = keys.map(() => []);
  if (serializedData.length > 0) {
    serializedData.forEach(row => {
      keys.forEach((key, index) => {
        chartData[index].push({
          x:
            typeof row[key.name] === 'string' && row[key.name] !== ''
              ? row[key.name]
              : DisplayLabels.BLANK_LABEL,
          y:
            typeof row[key.value] === 'string' && row[key.value] !== ''
              ? parseFloat(row[key.value] as string)
              : 0,
          text: key.text,
          range: row.range ? (row.range as string) : (row[key.name] as string),
        });
      });
    });
  } else {
    keys.forEach((key, index) => {
      chartData[index].push({ x: DisplayLabels.NO_RECORD, y: 0 });
    });
  }

  const widgetDateRangeFilters = widget.query.dateRangeFilters || undefined;
  const granularity =
    (widgetDateRangeFilters && widgetDateRangeFilters[0].granularity) ||
    DateRangeGranularity.DAY;

  const convertLabelText = (label: string) => {
    let text = label;

    if (type === LineChartType.DATE_RANGE) {
      text = convertDateByFormat(
        label,
        granularity,
        GRANULARITY_FORMATS[granularity],
      );
    }

    return text;
  };

  const legends = keys.map(key => ({
    name: key.text,
  }));

  const legendWidth = legends.length * 35;
  const legendX = boundingRect.width / 2 - legendWidth;
  const legendY = boundingRect.height - LEGEND_BOTTOM_GAP;

  return (
    <View style={chartStyle as ViewStyle} ref={graphRef}>
      {loading ? (
        <LoadingIndicator />
      ) : (
        <VictoryChart
          width={boundingRect.width}
          height={boundingRect.height ? boundingRect.height : 0}
          containerComponent={
            <VictoryVoronoiContainer
              labels={({ datum }) => {
                if (datum.range && datum.text) {
                  return `${convertLabelText(datum.range)}\n${datum.text}: ${
                    datum.y
                  }`;
                } else {
                  return `${convertLabelText(datum.x)}\n${datum.y}`;
                }
              }}
              labelComponent={
                <VictoryTooltip
                  dy={TOOLTIP_GAP}
                  style={CHART_TOOLTIP_STYLE.style}
                  flyoutStyle={CHART_TOOLTIP_STYLE.flyoutStyle}
                />
              }
            />
          }
        >
          <VictoryAxis style={CHART_X_AXIS_STYLE} fixLabelOverlap={true} />
          <VictoryAxis
            dependentAxis
            orientation="left"
            style={CHART_Y_AXIS_STYLE}
            tickFormat={t => {
              if (t < 1e-4) return 0;
              return t >= 1000 ? `${t / 1000}k` : t;
            }}
          />
          <VictoryGroup offset={8} style={{ data: { width: 7 } }}>
            {chartData.map((chart, index) => (
              <VictoryBar
                key={`bar-chart-${index}`}
                colorScale={colorScale}
                style={{
                  labels: BAR_CHART_STYLE.labels,
                }}
                data={chart}
                events={[
                  {
                    target: 'data',
                    eventHandlers: {
                      onMouseOver: () => {
                        return [
                          {
                            target: 'data',
                            mutation: props => {
                              return {
                                style: Object.assign({}, props.style, {
                                  fill: '#fec108',
                                }),
                              };
                            },
                          },
                        ];
                      },
                      onMouseOut: () => {
                        return [
                          {
                            target: 'data',
                            mutation: props => {
                              return {
                                style: Object.assign({}, props.style, {
                                  fill: colorScale[index],
                                }),
                              };
                            },
                          },
                        ];
                      },
                    },
                  },
                ]}
              />
            ))}
          </VictoryGroup>
          <VictoryLegend
            style={CHART_LEGEND_STYLE.style}
            x={legendX}
            y={legendY}
            orientation="horizontal"
            symbolSpacer={CHART_LEGEND_STYLE.symbolSpace}
            gutter={CHART_LEGEND_STYLE.legendSpace}
            colorScale={colorScale}
            data={legends}
          />
        </VictoryChart>
      )}
      <Text style={styles.chartTitleStyle}>{widget.name}</Text>
    </View>
  );
};

export const MultiBarChart = React.memo(MultiBarChartComponent, arePropsEqual);
