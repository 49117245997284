import { StyleSheet } from 'react-native';
import theme from '../../../../../common/default-theme';

const styles = StyleSheet.create({
  filtersContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 20,
  },
  searchContainer: {
    flex: 1,
    marginRight: 10,
  },
  tableContainer: {
    width: '100%',
    marginTop: 20,
  },
  rowText: {
    paddingHorizontal: 12,
    color: theme.colors.black1,
  },
  cellName: {
    width: 200,
  },
  cellStoreName: {
    flex: 1,
  },
  cellAmount: {
    width: 104,
    textAlign: 'right',
    fontVariant: ['tabular-nums'],
  },
  cellScheduleHeader: {
    width: 104,
    textAlign: 'center',
    alignItems: 'center',
    justifyContent: 'center',
  },
  cellDisclosure: {
    width: 34,
  },
  columnContainerStyle: {
    backgroundColor: theme.colors.grey1,
    borderRadius: theme.radius.s,
    borderBottomWidth: 0,
    marginTop: theme.spacing.s,
  },
  removeButton: {
    width: 38,
    height: 38,
    alignItems: 'flex-end',
    justifyContent: 'center',
    borderRadius: theme.radius.s,
    marginLeft: 20,
    marginTop: 20,
  },
  addButton: {
    marginTop: 10,
    flex: 1,
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
  },
  topSpacing: {
    marginTop: 20,
  },
  messageActionLabel: {
    textAlign: 'center',
  },
  zIndex: {
    zIndex: -1,
  },
  selectedItemChipContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  selectedItemText: {
    color: theme.colors.white,
    marginLeft: 10,
  },
});

export default styles;
