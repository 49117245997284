import React from 'react';
import { View, Text } from 'react-native';
import { Order, Address, Courier, OrderTypeCode } from '@oolio-group/domain';
import format from 'date-fns/format';
import { useTranslation } from '@oolio-group/localization';
import styles from './OnlineOrderSidePanel.styles';

interface OrderDetailsSectionProps {
  order: Order;
}

interface DetailsTileProps {
  title: string;
  value: string;
  testID: string;
}

export const OrderDetailRow: React.FC<DetailsTileProps> = ({
  title,
  value,
  testID,
}) => {
  if (!value) return null;

  return (
    <View style={styles.row}>
      <Text style={styles.rowTitle}>{title}</Text>
      <Text testID={testID} style={styles.rowValue}>
        {value}
      </Text>
    </View>
  );
};

const getAddress = (address?: Address): string => {
  const addressResult: string[] = Object.values(address || {});
  return addressResult.join(', ');
};

const getDriverName = (courier?: Courier): string => {
  const { firstName, lastName } = courier || {};
  if (!firstName && !lastName) return '';
  return `${firstName} ${lastName}`;
};

const DetailsSection: React.FC<OrderDetailsSectionProps> = ({ order }) => {
  const { translate } = useTranslation();

  const isDineIn = order?.orderType?.code === OrderTypeCode.DINE_IN;
  const detailsData: DetailsTileProps[] = [
    {
      testID: 'order-placedOn',
      title: translate('onlineOrders.placedOn'),
      value: order.placedAt
        ? format(new Date(order.placedAt), 'dd/MM/yyyy hh:mm aa')
        : order.createdAt
        ? format(new Date(order.createdAt), 'dd/MM/yyyy hh:mm aa')
        : '',
    },
    {
      testID: 'order-dueAt',
      title: translate('onlineOrders.dueAt'),
      value: isDineIn
        ? 'N/A'
        : order.requiredAt
        ? format(new Date(order.requiredAt), 'dd/MM/yyyy hh:mm aa')
        : '',
    },
    {
      testID: 'order-deliverBy',
      title: translate('onlineOrders.deliveryBy'),
      value: order?.courier?.deliveryBy
        ? `${order.courier.deliveryBy} (${order?.orderType?.name})`
        : '',
    },
    {
      testID: 'order-customer',
      title: translate('onlineOrders.customerName'),
      value: `${order?.customer?.firstName || 'N/A'} ${
        order?.customer?.lastName || ''
      }`,
    },
    {
      testID: 'order-phone',
      title: translate('onlineOrders.customerPhone'),
      value: `${order?.customer?.phone || 'N/A'}`,
    },
    {
      testID: 'order-address',
      title: translate('onlineOrders.address'),
      value: getAddress(
        order.shippingAddress || order?.customer?.preferredAddress,
      ),
    },
    {
      testID: 'order-driver',
      title: translate('onlineOrders.deliveryDriverName'),
      value: getDriverName(order?.courier),
    },
    {
      testID: 'order-driverPhone',
      title: translate('onlineOrders.deliveryDriverPhone'),
      value: order?.courier?.phoneNumber || '',
    },
  ];

  return (
    <View testID="order-details">
      {detailsData.map((orderData, index) => (
        <OrderDetailRow
          testID={orderData.testID}
          title={orderData.title}
          value={orderData.value}
          key={index + orderData.value}
        />
      ))}
    </View>
  );
};

export default DetailsSection;
