import { StyleSheet } from 'react-native';
import theme from '../../../common/default-theme';

export const styles = StyleSheet.create({
  reservationContainer: {
    flexDirection: 'row',
    overflow: 'hidden',
  },
  reservationHeader: {
    flexDirection: 'row',
    padding: 12,
    borderRadius: theme.radius.s,
    backgroundColor: theme.colors.grey1,
    borderBottomWidth: 1,
    borderColor: theme.colors.grey1,
    margin: 20,
  },
  row: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingVertical: 12,
    paddingHorizontal: 16,
    borderColor: theme.colors.grey7,
    backgroundColor: theme.colors.white,
    marginBottom: 8,
    borderRadius: theme.radius.s,
  },
  guests: {
    maxWidth: 80,
    marginLeft: 16,
  },
  guestsText: {
    color: theme.colors.primary,
    fontFamily: theme.fonts.regular,
    alignItems: 'center',
    justifyContent: 'center',
    paddingHorizontal: 12,
    paddingVertical: 6,
    borderRadius: theme.radius.s,
    backgroundColor: theme.colors.lightBlueLight,
  },
  table: {
    flex: 1,
    justifyContent: 'flex-start',
    marginLeft: 64,
    flexDirection: 'column',
    maxWidth: 150,
  },
  tableNumberText: {
    fontFamily: theme.fonts.regular,
    fontSize: 16,
    color: theme.colors.black1,
  },
  tableAreaText: {
    fontFamily: theme.fonts.regular,
    color: theme.colors.grey7,
    paddingBottom: 2,
    maxWidth: 180,
  },
  customer: {
    flex: 2,
    justifyContent: 'flex-start',
    flexDirection: 'column',
    maxWidth: 150,
  },
  customerName: {
    fontFamily: theme.fonts.regular,
    color: theme.colors.black,
    fontSize: 16,
    marginBottom: 8,
  },
  customerContact: {
    fontSize: 12,
    paddingTop: 4,
    fontFamily: theme.fonts.regular,
    color: theme.colors.grey7,
  },
  duration: {
    fontSize: 12,
    color: theme.colors.grey7,
    paddingTop: 4,
  },
  reservationTime: {
    flex: 2,
    justifyContent: 'flex-start',
    maxWidth: 180,
  },
  reservationTimeText: {
    fontSize: 16,
    fontFamily: theme.fonts.regular,
    color: theme.colors.black,
  },
  notes: {
    flex: 3,
    justifyContent: 'flex-start',
    minWidth: 180,
  },
  notesText: {
    fontFamily: theme.fonts.regular,
    color: theme.colors.black,
    fontSize: 16,
  },
  status: {
    width: 100,
    paddingHorizontal: 12,
    paddingVertical: 4,
    marginRight: 32,
    borderRadius: theme.radius.l,
    justifyContent: 'center',
    alignItems: 'center',
  },
  statusText: {
    color: theme.colors.white,
    fontFamily: theme.fonts.semibold,
  },
  statusARRIVED: {
    backgroundColor: theme.colors.green,
  },
  statusPARTIAL_ARR: {
    backgroundColor: theme.colors.orange,
  },
  statusCONFIRMED: {
    backgroundColor: theme.colors.blue,
  },
  statusBOOKED: {
    backgroundColor: theme.colors.red,
  },
});
