/* eslint-disable react-native/no-inline-styles */
import React from 'react';
import { View } from 'react-native';
import {
  useTranslation,
  getBusinessIdentifierType,
} from '@oolio-group/localization';
import theme from '../../../../../../common/default-theme';
import InputText from '../../../../../../components/Shared/Inputs/InputText';
import InputEmail from '../../../../../../components/Shared/Inputs/InputEmail';
import InputPhone from '../../../../../../components/Shared/Inputs/InputPhone';
import TreatPicker from '../../../../../../components/Shared/Select/Picker';
import { Store } from '@oolio-group/domain';
import { styles } from './DetailsForm.styles';
import {
  isNotEmpty,
  isValidBusinessIdentifier,
  isValidEmail,
  isValidPostCode,
} from '../../../../../../utils/validator';

interface DetailsFormProps {
  testID?: string;
  form: Store;
  onChange: (key: string, value: string) => void;
  onChangeAddress: (key: string, value: string) => void;
}

export const DetailsForm: React.FC<DetailsFormProps> = ({
  form,
  onChange,
  onChangeAddress,
}) => {
  const { translate } = useTranslation();
  const businessIdentifierType = getBusinessIdentifierType(
    form.address?.country || '',
  );

  const formValidationStatus: Record<string, boolean> = {
    // business identifier Details
    businessIdentifier: isValidBusinessIdentifier(
      form.businessIdentifier || '',
      form.address?.country,
    ),
    // Contact Details
    email: isValidEmail(form.email || ''),
    // Location Details
    line1: isNotEmpty(form.address?.line1),
    line2: isNotEmpty(form.address?.line2),
    city: isNotEmpty(form.address?.city),
    state: isNotEmpty(form.address?.state),
    postalCode: isValidPostCode(
      form.address?.postalCode || '',
      form.address?.country,
    ),
    country: isNotEmpty(form.address?.country),
  };

  const isUK = form.address?.country === 'GB';
  return (
    <>
      <View style={theme.forms.row}>
        <InputText
          testID="input-name"
          title={translate('form.name')}
          value={form.name || ''}
          placeholder={translate('form.name')}
          onChangeText={onChange.bind(null, 'name')}
          maxLength={50}
          containerStyle={theme.forms.inputHalf}
        />
        <InputText
          testID="input-businessIdentifier"
          title={translate('form.businessNumber', {
            type: businessIdentifierType,
          })}
          value={form.businessIdentifier || ''}
          placeholder={translate('form.businessNumber', {
            type: businessIdentifierType,
          })}
          errorMessage={
            form.businessIdentifier && !formValidationStatus.businessIdentifier
              ? 'Enter valid identifier'
              : undefined
          }
          onChangeText={onChange.bind(null, 'businessIdentifier')}
          maxLength={isUK ? 9 : 11}
          containerStyle={theme.forms.inputHalf}
        />
      </View>
      <View style={theme.forms.row}>
        <InputEmail
          testID="input-email"
          title={translate('form.email')}
          value={form?.email}
          placeholder={translate('form.email')}
          errorMessage={
            form?.email && !formValidationStatus.email
              ? 'Enter valid email address'
              : undefined
          }
          onChangeText={onChange.bind(null, 'email')}
          containerStyle={theme.forms.inputHalf}
        />
        <InputPhone
          testID="input-phone"
          title={translate('form.phoneNumber')}
          value={form?.phoneNumber}
          defaultCountry={form.address?.country}
          placeholder={translate(
            isUK ? 'form.phoneNumberHintUK' : 'form.phoneNumberHint',
          )}
          onChangeText={text => onChange('phoneNumber', text)}
          onPressCountry={country => onChange('countryCode', country)}
          containerStyle={theme.forms.inputHalf}
        />
      </View>
      <View style={theme.forms.row}>
        <InputText
          testID="input-line1"
          title={translate(isUK ? 'form.streetAddress' : 'form.addressLine1')}
          value={form.address?.line1 || ''}
          placeholder={translate(
            isUK ? 'form.streetAddress' : 'form.addressLine1',
          )}
          errorMessage={
            form.address?.line1 && !formValidationStatus.line1
              ? 'Enter valid address'
              : undefined
          }
          onChangeText={onChangeAddress.bind(null, 'line1')}
          containerStyle={
            !isUK ? theme.forms.inputHalf : theme.forms.inputFluid
          }
        />
        {!isUK ? (
          <InputText
            testID="input-line2"
            title={translate('form.addressLine2')}
            value={form.address?.line2}
            placeholder="Optional"
            onChangeText={onChangeAddress.bind(null, 'line2')}
            containerStyle={theme.forms.inputHalf}
          />
        ) : null}
      </View>
      <View style={theme.forms.row}>
        <InputText
          testID="input-city"
          title={translate(isUK ? 'form.town' : 'form.city')}
          value={form.address?.city}
          placeholder={translate(isUK ? 'form.town' : 'form.city')}
          onChangeText={onChangeAddress.bind(null, 'city')}
          errorMessage={
            form.address?.city && !formValidationStatus.city
              ? 'Enter valid city'
              : undefined
          }
          containerStyle={theme.forms.inputHalf}
        />
        <InputText
          testID="input-state"
          title={translate(isUK ? 'form.county' : 'form.state')}
          value={form.address?.state}
          placeholder={translate(isUK ? 'form.county' : 'form.state')}
          errorMessage={
            form.address?.state && !formValidationStatus.state
              ? 'Enter valid state'
              : undefined
          }
          onChangeText={onChangeAddress.bind(null, 'state')}
          containerStyle={{ width: 150 }}
        />
        <InputText
          testID="input-code"
          title={translate('form.postCode')}
          value={form.address?.postalCode}
          placeholder={translate(
            isUK ? 'form.postalCodeHintUK' : 'form.postalCodeHint',
          )}
          errorMessage={
            form.address?.postalCode && !formValidationStatus.postalCode
              ? 'Enter valid postal code'
              : undefined
          }
          onChangeText={onChangeAddress.bind(null, 'postalCode')}
          containerStyle={{ width: 90 }}
        />
      </View>
      <View style={theme.forms.row}>
        <TreatPicker
          testID="select-country"
          title={translate('form.country')}
          selectedValue={form.address?.country}
          options="countries"
          onValueChange={code => onChangeAddress('country', code)}
          containerStyle={theme.forms.inputHalf}
        />
      </View>
      <View>
        <InputText
          testID="merchant-input-name"
          title={translate('form.merchantCode')}
          value={form.merchantCode}
          placeholder={translate('form.merchantCode')}
          onChangeText={onChange.bind(null, 'merchantCode')}
          containerStyle={styles.inputContainer}
        />
      </View>
    </>
  );
};
