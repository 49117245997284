import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import LoadingIndicator from '../../../../components/LoadingIndicator/LoadingIndicator';
import { Helmet } from 'react-helmet';
import { Styles } from '../styles/ProductsSummary.styles';
import { View, ScrollView } from 'react-native';
import { useTranslation } from '@oolio-group/localization';
import {
  DateRangeFilter,
  Filters,
  FilterValue,
  NO_DATA,
  Shift,
} from '@oolio-group/domain';
import { sortShiftByPaymentType } from '@oolio-group/client-utils';
import { ReportHeader } from '../UIComponents/ReportHeader';
import { Table, TableRef } from './UIComponents/Table';
import { Filters as ReportFilter } from '../UIComponents/Filters';
import {
  GRANULARITY_FORMATS,
  SHIFT_REPORT_COLUMNS,
  CHART_COLOR_SCALE,
  BAR_VARIANCE_COLOR_SCALE,
  PREVIEW_COLUMN,
} from '../reportsHelper';
import {
  IMap,
  ExportType,
  Search,
  ReportTableColumn,
  VarianceColor,
  DropDownFilter,
  ShiftPreview,
} from '../types';
import { StackedBarChart } from './UIComponents/StackedBarChart';
import { MultiBarChart } from './UIComponents/MultiBarChart';
import SecondarySidePanel from '../../../../components/SecondarySidePanel/SecondarySidePanel';
import { DataPreview } from './UIComponents/DataPreview';

interface ReportProps {
  options: { loading: boolean };
  filterOptions: DropDownFilter[];
  filters: FilterValue;
  search: Search;
  dateRangeFilter: DateRangeFilter;
  shifts: Shift[];
  updateFilters: (filter: string, value: string[]) => void;
  resetFilters: () => void;
  updateDateRangeFilters: (value: DateRangeFilter) => void;
  updateReport: () => void;
  updateSearch: (filter: string, value: string) => void;
  allFilters: Filters;
}

interface SidePanel {
  show: boolean;
  data: Shift | undefined;
}

const TABLE_GRANULARITY_FORMATS: IMap<string> = {
  ...GRANULARITY_FORMATS,
  day: 'dd/MM/yy hh:mm aaa',
};

export const ShiftReport: React.FC<ReportProps> = ({
  options: { loading },
  filters,
  search,
  filterOptions,
  dateRangeFilter,
  shifts,
  updateFilters,
  resetFilters,
  updateDateRangeFilters,
  updateReport,
  updateSearch,
  allFilters,
}) => {
  const { translate } = useTranslation();
  const styles = Styles();
  const [showFilters, setShowFilters] = useState<boolean>(false);
  const [sidePanel, setSidePanel] = useState<SidePanel>({
    show: false,
    data: undefined,
  });

  const toggleFilters = useCallback(() => {
    setShowFilters(value => !value);
  }, []);

  const [selectedColumns, setSelectedColumns] = useState<ReportTableColumn[]>(
    [],
  );

  const summaryData = useMemo(() => {
    const toReturn = {
      stackBarOne: {
        name: 'SHIFT AMOUNT & PAYMENT TYPES',
        legends: [] as IMap<string | number>[],
        data: [] as IMap<string | number>[][],
      },
      stackBarTwo: {
        name: 'SHIFT VARIANCES',
        legends: [
          { name: 'Recorded' },
          { name: 'Counted' },
          { name: 'Counted (+)' },
          { name: 'Counted (-)' },
        ] as IMap<string | number>[],
        data: [] as IMap<string | number>[][],
      },
      table: {
        columns: SHIFT_REPORT_COLUMNS,
        data: [] as IMap<string | number>[],
      },
    };

    if (shifts && shifts.length > 0) {
      const shiftPaymentArr: IMap<Array<IMap<string | number>>> = {};
      const shiftVarianceTotals: IMap<Array<IMap<string | number>>> = {
        recorded: [],
        counted: [],
      };

      shifts.forEach(summary => {
        toReturn.table.data.push({
          shiftNumber: summary.shiftNumber,
          status: summary.shiftStatus,
          createdAt: new Date(summary.createdAt).toISOString(),
          closedAt:
            summary.closedAt === null
              ? ''
              : new Date(summary.closedAt).toISOString(),
          store: summary.store.name,
          device: summary.closedByDevice
            ? summary.closedByDevice?.name
            : summary.createdByDevice?.name || NO_DATA,
          createdBy: summary.createdBy.name,
          closedBy: summary.closedBy?.name || NO_DATA,
          totalCounted:
            typeof summary.totalCounted === 'number'
              ? summary.totalCounted.toFixed(2)
              : 0,
          totalRecorded:
            typeof summary.totalRecorded === 'number'
              ? summary.totalRecorded.toFixed(2)
              : 0,
          variance:
            typeof summary.difference === 'number'
              ? summary.difference.toFixed(2)
              : 0,
        });

        let countedColor;

        if (summary.difference > 0) {
          countedColor = VarianceColor.POSITIVE;
        } else if (summary.difference < 0) {
          countedColor = VarianceColor.NEGATIVE;
        } else {
          countedColor = VarianceColor.DEFAULT;
        }

        shiftVarianceTotals.recorded.push({
          x: summary.shiftNumber as string,
          y:
            typeof summary.totalRecorded === 'number'
              ? parseFloat(summary.totalRecorded.toFixed(2))
              : 0,
          text: 'Recorded',
          color: VarianceColor.RECORDED,
        });

        shiftVarianceTotals.counted.push({
          x: summary.shiftNumber as string,
          y:
            typeof summary.totalCounted === 'number'
              ? parseFloat(summary.totalCounted.toFixed(2))
              : 0,
          text: 'Counted',
          color: countedColor,
        });

        const totalPaymentAmount = summary.salesByPaymentType.reduce(
          (acc, payment) => {
            return acc + payment.amount;
          },
          0,
        );

        summary.salesByPaymentType.forEach(payment => {
          const name = payment.paymentType.name;
          if (shiftPaymentArr[name] === undefined) shiftPaymentArr[name] = [];
          shiftPaymentArr[name].push({
            x: summary.shiftNumber as string,
            y: parseFloat((payment.amount || 0).toFixed(2)),
            text: payment.paymentType.name,
            percent:
              totalPaymentAmount > 0
                ? `${parseFloat(
                    (
                      ((payment.amount || 0) / totalPaymentAmount) *
                      100
                    ).toFixed(2),
                  )}%`
                : '0%',
          });
        });
      });

      toReturn.stackBarTwo.data.push(shiftVarianceTotals.recorded);
      toReturn.stackBarTwo.data.push(shiftVarianceTotals.counted);

      for (const paymentType in shiftPaymentArr) {
        toReturn.stackBarOne.legends.push({ name: paymentType });
        toReturn.stackBarOne.data.push(shiftPaymentArr[paymentType]);
      }

      toReturn.stackBarOne.data.sort((a, b) => b.length - a.length);
    }

    return toReturn;
  }, [shifts]);

  const tableWidgetRef = useRef<TableRef>({} as TableRef);
  const exportReport = useCallback((type = ExportType.CSV) => {
    if (type === ExportType.CSV)
      tableWidgetRef.current.exportData &&
        tableWidgetRef.current.exportData(`ShiftsReport-${Date.now()}.csv`);
  }, []);

  useEffect(() => {
    setSelectedColumns(
      summaryData.table.columns.filter(column => column.showByDefault),
    );
  }, [summaryData.table.columns]);

  const updateColumns = useCallback(
    (columnKeys: string[]) => {
      setSelectedColumns(
        summaryData.table.columns.filter(col => columnKeys.includes(col.title)),
      );
    },
    [summaryData.table.columns],
  );

  const allColumns = useMemo(() => {
    return [...selectedColumns, PREVIEW_COLUMN];
  }, [selectedColumns]);

  const onTableRowPressFn = useCallback(
    item => {
      let data: ShiftPreview | undefined;
      if (item) {
        const shiftData = shifts.find(d => d.shiftNumber === item.shiftNumber);
        const sortedData = sortShiftByPaymentType(shiftData);
        data = {
          ...shiftData,
          device: shiftData?.closedByDevice
            ? shiftData?.closedByDevice.name
            : shiftData?.createdByDevice?.name || NO_DATA,
          salesByPaymentType: sortedData?.salesByPaymentType || [],
          totalGrossRefund: Math.abs(shiftData?.totalGrossRefund || 0),
        } as ShiftPreview;
      } else {
        data = undefined;
      }
      setSidePanel({
        show: !sidePanel.show,
        data,
      });
    },
    [shifts, sidePanel.show],
  );

  const renderContainer = useMemo(
    () => <DataPreview previewData={sidePanel.data} />,
    [sidePanel.data],
  );

  return (
    <>
      <Helmet>
        <title>
          {translate('navigation.generalSettingsPageTitle', {
            appName: translate('appName'),
          })}
        </title>
      </Helmet>

      <ScrollView
        scrollEnabled={!(showFilters || sidePanel.show)}
        style={styles.pageStyle}
      >
        {showFilters ? (
          <View style={styles.filtersStyle}>
            <ReportFilter
              toggleFilters={toggleFilters}
              filterOptions={filterOptions}
              filters={filters}
              updateFilters={updateFilters}
              resetFilters={resetFilters}
            ></ReportFilter>
          </View>
        ) : null}
        <View style={styles.mainSectionStyle}>
          <View style={styles.headersStyle}>
            <ReportHeader
              allFilters={allFilters}
              filters={filters}
              search={search}
              updateSearch={updateSearch}
              updateFilters={updateFilters}
              toggleFilters={toggleFilters}
              updateReport={updateReport}
              exportReport={exportReport}
              dateRangeFilter={dateRangeFilter}
              updateDateRangeFilters={updateDateRangeFilters}
              columns={{
                all: summaryData.table.columns,
                updateColumns,
                selectedColumns,
              }}
              translationParentKey={'backOfficeShifts'}
            />
          </View>
          <View style={styles.chartRowStyle}>
            {loading ? (
              <LoadingIndicator />
            ) : (
              <>
                <StackedBarChart
                  chartName={summaryData.stackBarOne.name}
                  legends={summaryData.stackBarOne.legends}
                  data={summaryData.stackBarOne.data}
                  width={'50%'}
                  colorScale={CHART_COLOR_SCALE}
                />
                <MultiBarChart
                  chartName={summaryData.stackBarTwo.name}
                  legends={summaryData.stackBarTwo.legends}
                  data={summaryData.stackBarTwo.data}
                  width={'50%'}
                  colorScale={BAR_VARIANCE_COLOR_SCALE}
                />
              </>
            )}
          </View>
          <View style={styles.tableStyle}>
            {loading ? (
              <LoadingIndicator />
            ) : (
              <Table
                data={summaryData.table.data}
                columns={allColumns}
                pageSize={5}
                granularityFormats={TABLE_GRANULARITY_FORMATS}
                ref={tableWidgetRef}
                onTableRowPress={onTableRowPressFn}
              ></Table>
            )}
          </View>
        </View>
      </ScrollView>
      <SecondarySidePanel
        showPanel={sidePanel.show}
        onPressHideShowPanel={() => onTableRowPressFn(undefined)}
        headerText={translate('backOfficeShifts.rowPreview.panelTitle')}
        renderContainer={renderContainer}
      />
    </>
  );
};

export default ShiftReport;
