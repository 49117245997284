import {
  Device,
  Organization,
  Store,
  UpdateDeviceInput,
  Venue,
} from '@oolio-group/domain';
import { useSsoProfile } from '@oolio-group/hooks';
import { useFocusEffect, useNavigation } from '@react-navigation/native';
import jwt_decode from 'jwt-decode';
import pick from 'lodash/pick';
import { REACT_APP_OOLIO_DOMAIN } from 'react-native-dotenv';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useNotification } from '../../../hooks/Notification';
import { useDeviceAssignment } from '../../../hooks/app/useDeviceAssignment';
import { useDeviceId } from '../../../hooks/app/useDeviceId';
import { useDeviceInfo } from '../../../hooks/app/useDeviceInfo';
import { useSession } from '../../../hooks/app/useSession';
import { useVenues } from '../../../hooks/app/useVenues';
import { navigateToBackOfficeParams } from '../../../state/navigation';
import { tokenUtility } from '../../../state/tokenUtility';
import { userUtility } from '../../../state/userUtility';
import { stripProperties } from '../../../utils/stripObjectProps';
import DeviceList from './DeviceList';
import Layout from './Layout';
import OrganizationsList from './OrganizationsList';
import VenueAndStoreList from './VenueAndStoresList';

export enum Steps {
  ORGANIZATION = 'Organization',
  STORE = 'Store',
  DEVICE = 'Device',
  PRINTER_PROFILE = 'Printer Profile',
}

const userInfoEndpoint =
  'https://' +
  (process.env['REACT_APP_OOLIO_DOMAIN'] || REACT_APP_OOLIO_DOMAIN) +
  '/userinfo';

const AssignToDeviceProfile: React.FC = () => {
  const navigation = useNavigation();
  const [session, setSession] = useSession();
  const { showNotification } = useNotification();
  const [step, setStep] = useState<Steps>(Steps.ORGANIZATION);
  const { deviceId } = useDeviceId();

  const [venuesData, setVenuesData] = useState<Array<Venue>>([]);

  const [searchString, setSearchString] = useState('');
  const [, setOrgId] = useState<string>();
  const { user, loading: userLoading } = useSsoProfile(
    userInfoEndpoint,
    tokenUtility.token as string,
  );

  const {
    venues,
    getVenues,
    searchVenues,
    loading: getVenuesLoading,
    error: getVenuesError,
  } = useVenues();

  const allVenues = useMemo(() => Object.values(venues), [venues]);

  const [isBackPressed, setIsBackPressed] = useState<boolean>(false);

  const navigateToBackOffice = useCallback(() => {
    setSession({
      ...session,
      currentVenue: undefined,
      currentStore: undefined,
    });
    navigation.reset(navigateToBackOfficeParams);
  }, [navigation, session, setSession]);
  const onPressBack = useCallback(() => {
    setIsBackPressed(true);
    if (step === Steps.STORE) {
      navigateToBackOffice();
    } else {
      setStep(Steps.STORE);
      setSession({
        ...session,
        currentStore: undefined,
      });
    }
  }, [step, navigateToBackOffice, session, setSession]);

  const {
    updateDevice,
    loading: deviceAssignmentLoading,
    error: deviceAssignmentError,
  } = useDeviceAssignment();

  const deviceInfo = useDeviceInfo();

  const loading = deviceAssignmentLoading || getVenuesLoading;
  const error = deviceAssignmentError || getVenuesError;

  useEffect(() => {
    if (error) {
      showNotification({
        error: true,
        message: error,
      });
    }
  }, [error, showNotification]);

  useFocusEffect(
    useCallback(() => {
      getVenues();
    }, [getVenues]),
  );

  useEffect(() => {
    async function updateData() {
      const searchResult = await searchVenues(searchString);
      setVenuesData(searchResult);
    }
    updateData();
  }, [searchVenues, searchString]);

  const onUpdateDevice = useCallback(
    async (item: Device) => {
      const prevOrderNumber = item.previousOrder?.orderNumber;
      userUtility.clearPosUser();
      const input = pick(
        {
          ...item,
          ...stripProperties(deviceInfo, 'versionNum'),
          name: item.name,
          deviceProfile: item.deviceProfile.id,
          cashDrawer: item.cashDrawer || 'default',
          paymentTerminal: item?.paymentTerminal?.id || 'default',
          isPaired: true,
          ...(Boolean(prevOrderNumber) && { lastOrderNumber: prevOrderNumber }),
          uuid: deviceId.toString(),
        },
        [
          'id',
          'name',
          'details',
          'uuid',
          'salesPrefix',
          'returnPrefix',
          'deviceProfile',
          'cashDrawer',
          'isPaired',
          'lastOrderNumber',
        ],
      ) as unknown as UpdateDeviceInput;
      updateDevice(input);
      //TODO : unAssignDevice Existing Device
    },
    [deviceInfo, updateDevice, deviceId],
  );

  const onSelectOrganization = useCallback(
    (orgnization: Pick<Organization, 'id' | 'name'>) => {
      setOrgId(orgnization.id);
      const currentOrganization = session.availableOrganizations?.find(
        org => org.id === orgnization.id,
      );
      setSession({
        ...session,
        currentOrganization: currentOrganization,
      });
      getVenues();
      setStep(Steps.STORE);
    },
    [session, setSession, getVenues],
  );

  const onSelectStore = useCallback(
    (venue: Partial<Venue>, store: Store) => {
      const currentVenue = session.user?.venues?.find(
        venueData => venueData.id == venue.id,
      );
      const currentStore = currentVenue?.stores?.find(
        storeData => storeData.id == store.id,
      );
      setIsBackPressed(false);
      setSession({
        ...session,
        currentVenue: currentVenue,
        currentStore: currentStore,
      });
      setStep(Steps.DEVICE);
    },
    [session, setSession],
  );

  useEffect(() => {
    if (!user) return;
    switch (step) {
      case Steps.ORGANIZATION:
        if (user?.organizations?.length === 1) {
          onSelectOrganization(user?.organizations[0]);
        } else {
          // backward compitaple
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          const tokenPayload = jwt_decode<{ app_metadata: any }>(
            tokenUtility.token as string,
          );
          onSelectOrganization({
            id: tokenPayload.app_metadata.organization_id,
            name: tokenPayload.app_metadata.organization_name,
          } as Organization);
        }
        break;
      case Steps.STORE:
        if (
          !isBackPressed &&
          allVenues.length === 1 &&
          allVenues[0]?.stores?.length === 1
        )
          onSelectStore(allVenues[0], allVenues[0].stores[0]);
        break;
      case Steps.DEVICE:
        //TODO :printer setup
        break;
    }
    // On selectStore callback change does not trigger the effect to rerun
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [step, isBackPressed, allVenues, user]);

  return (
    <Layout
      leftIcon={step === Steps.STORE ? 'Times' : ' arrow-left'}
      onLeftIconPress={onPressBack}
    >
      <>
        {step === Steps.ORGANIZATION && (
          <OrganizationsList
            organizations={user?.organizations ?? []}
            onSelect={onSelectOrganization}
            loading={userLoading}
          />
        )}
        {step === Steps.STORE && (
          <VenueAndStoreList
            venuesData={venuesData}
            onSelect={onSelectStore}
            onSearch={searchString => setSearchString(searchString)}
            loading={loading}
          />
        )}
        {step === Steps.DEVICE && (
          <DeviceList onSelect={onUpdateDevice} loading={loading} />
        )}
      </>
    </Layout>
  );
};

export default AssignToDeviceProfile;
