import React from 'react';
import { View, Text, TouchableOpacity } from 'react-native';
import { Catalogue, CatalogueType } from '@oolio-group/domain';
import { useNavigation } from '@react-navigation/native';
import styles from './Menu.styles';
import theme from '../../../../common/default-theme';
import Icon from '../../../../components/Icon/Icon';
import { sentenceCase } from 'change-case';

interface MenuRowProps {
  menu: Catalogue;
}

export const MenuRow: React.FC<MenuRowProps> = ({ menu }: MenuRowProps) => {
  const navigation = useNavigation();

  const getRowColour = (type: CatalogueType) => {
    switch (type) {
      case CatalogueType.POS:
        return theme.colors.deepPurple;
      case CatalogueType.KIOSK:
        return theme.colors.deepOrange;
      case CatalogueType.ONLINE:
        return theme.colors.blue;
      default:
        return theme.colors.primary;
    }
  };

  return (
    <TouchableOpacity
      style={theme.tables.row}
      onPress={(): void =>
        navigation.navigate('MenuSettings', {
          menuId: menu.id,
          name: menu.name,
          type: menu.catalogueType,
        })
      }
    >
      <View
        testID="mode-color"
        style={[
          styles.rowLight,
          // eslint-disable-next-line react-native/no-inline-styles, react-native/no-color-literals
          {
            backgroundColor: getRowColour(menu.catalogueType),
          },
        ]}
      />
      <Text style={styles.cellName}>{menu.name}</Text>
      <Text style={styles.cellType}>
        {menu?.catalogueType === CatalogueType.POS
          ? menu.catalogueType
          : sentenceCase(menu.catalogueType)}
      </Text>
      <Text style={styles.cellCount}>{menu?.venues.length || 0}</Text>
      <View style={theme.tables.disclosure}>
        <Icon name="angle-right" size={20} color={theme.colors.grey5} />
      </View>
    </TouchableOpacity>
  );
};
