import React, { useEffect, useCallback, useState, useMemo } from 'react';
import { View, Text, TouchableOpacity } from 'react-native';
import {
  DEFAULT_PRICING_GROUP,
  PricingGroup as PricingGroupDefault,
} from '@oolio-group/domain';
import { useNotification } from '../../../../hooks/Notification';
import { useModal } from '@oolio-group/rn-use-modal';
import { useTranslation } from '@oolio-group/localization';
import { usePricingGroups } from '../../../../hooks/app/usePricingGroups';
import { useNavigation, useIsFocused } from '@react-navigation/native';
import { Operation } from '../../../../types/Operation';
import ConfirmationDialog from '../../../../components/Modals/ConfirmationDialog';
import ScreenLayout from '../../../../components/Office/ScreenLayout/ScreenLayout';
import Section from '../../../../components/Office/Section/Section';
import Icon from '../../../../components/Icon/Icon';
import CreateButton from '../../../../components/Office/CreateButton/CreateButton';
import InputText from '../../../../components/Shared/Inputs/InputText';
import InputToggle from '../../../../components/Shared/Inputs/InputToggle';
import ButtonIcon from '../../../../components/Shared/TreatButton/ButtonIcon';
import theme from '../../../../common/default-theme';
import styles from './PriceLists.styles';
import CreatePriceListModal from './CreatePriceList/CreatePriceListModal';

interface PricingGroup extends PricingGroupDefault {
  isSelected?: boolean;
  isChanged?: boolean;
}

export const PriceLists: React.FC = () => {
  const isFocused = useIsFocused();
  const navigation = useNavigation();
  const { translate } = useTranslation();
  const { showModal, closeModal } = useModal();
  const { showNotification } = useNotification();

  const [priceListsData, setPriceListsData] = useState<
    Record<string, PricingGroup>
  >({});

  const {
    pricingGroups,
    defaultPricingGroup,
    error,
    loading,
    operation,
    updatePricingGroups,
    clonePricingGroup,
    getAllPricingGroups,
    createPricingGroup,
    createdId,
  } = usePricingGroups();

  useEffect(() => {
    if (isFocused) {
      getAllPricingGroups();
    }
  }, [isFocused, getAllPricingGroups]);

  useEffect(() => {
    if (error) {
      showNotification({
        error: true,
        message: error,
      });
    }
  }, [error, showNotification]);

  useEffect(() => {
    if (!loading && !error && operation === Operation.DELETE) {
      closeModal();
      showNotification({
        success: true,
        message: translate('pricings.pricingGroupsDeletedSuccessfully'),
      });
    }
  }, [loading, operation, closeModal, showNotification, translate, error]);

  useEffect(() => {
    if (!loading && !error && operation === Operation.UPDATE) {
      closeModal();
      showNotification({
        success: true,
        message: translate('pricings.pricingGroupsSavedSuccessfully'),
      });
    }
  }, [loading, operation, closeModal, showNotification, translate, error]);

  useEffect(() => {
    if (!loading && !error && operation === Operation.CREATE && createdId) {
      closeModal();
      showNotification({
        success: true,
        message: translate('pricings.pricingGroupCopiedSuccessfully'),
      });
      navigation.navigate('PriceListSettings', {
        pricingGroupId: createdId,
        isNavigatedFromCreate: true,
      });
    }
  }, [
    loading,
    operation,
    closeModal,
    showNotification,
    translate,
    error,
    createdId,
    navigation,
  ]);

  useEffect(() => {
    if (!error && !loading && pricingGroups) {
      setPriceListsData(pricingGroups);
    }
  }, [error, loading, pricingGroups]);

  const priceListsArray = useMemo(() => {
    if (defaultPricingGroup) {
      const data = Object.values(priceListsData || {}).filter(
        obj => obj.id !== defaultPricingGroup?.id,
      );
      [defaultPricingGroup, ...data] as PricingGroup[];
      return [defaultPricingGroup, ...data] as PricingGroup[];
    } else {
      return Object.values(priceListsData || {}) as PricingGroup[];
    }
  }, [priceListsData, defaultPricingGroup]);

  const onPressCreate = useCallback(() => {
    showModal(<CreatePriceListModal onCreate={createPricingGroup} />);
  }, [createPricingGroup, showModal]);

  const onPressCopy = useCallback(
    (id: string): void => {
      if (id && pricingGroups[id]) {
        const selectedPricingGroup = pricingGroups[id];
        showModal(
          <ConfirmationDialog
            type="neutral"
            title={translate('pricings.copyPricingGroup')}
            message={translate('pricings.pricingGroupCopyDescription', {
              name: selectedPricingGroup.name,
            })}
            onConfirm={() => {
              clonePricingGroup({
                id: selectedPricingGroup.id,
              });
            }}
            confirmLabel={translate('pricings.copy')}
            onCancel={() => closeModal()}
          />,
        );
      }
    },
    [pricingGroups, showModal, translate, closeModal, clonePricingGroup],
  );

  const onPressStatusChange = useCallback(
    (id: string, status: boolean) => {
      showModal(
        <ConfirmationDialog
          title={
            status
              ? translate('pricings.enable')
              : translate('pricings.disable')
          }
          message={
            status
              ? translate('pricings.enablePricingGroup')
              : translate('pricings.disablePricingGroup')
          }
          onConfirm={() => {
            closeModal();
            updatePricingGroups([{ id, isActive: status }]);
          }}
        />,
      );
    },
    [showModal, , updatePricingGroups, closeModal, translate],
  );

  return (
    <ScreenLayout hideFooter loading={loading} title="Price Lists | Oolio">
      <Section
        title={translate('pricings.pricingGroups')}
        subtitle={translate('pricings.pricingGroupsDescription')}
        layoutWidth="medium"
      >
        <View style={styles.tableContainer}>
          <View style={theme.tables.header}>
            <Text style={theme.tables.headerText}>
              {translate('pricings.priceListsName')}
            </Text>
          </View>
          <View>
            {priceListsArray.length > 0 ? (
              priceListsArray.map((list: PricingGroup, i: number) => (
                <TouchableOpacity
                  key={i}
                  testID="row-list"
                  style={theme.tables.row}
                  onPress={(): void =>
                    navigation.navigate('PriceListSettings', {
                      pricingGroupId: list.id,
                      title: list.name,
                    })
                  }
                >
                  <InputToggle
                    testID="toggle-active"
                    type="switch"
                    isToggled={list.isActive || false}
                    onToggle={(): void =>
                      onPressStatusChange(list.id, !list.isActive)
                    }
                    disabled={list.name === DEFAULT_PRICING_GROUP}
                  />
                  <InputText
                    testID="input-name"
                    editable={false}
                    value={list.name}
                    placeholder={list.name}
                    containerStyle={styles.cellName}
                  />
                  <ButtonIcon
                    testID="btn-copy"
                    icon="copy"
                    type="neutralLight"
                    onPress={() => onPressCopy(list.id)}
                  />
                  <View style={theme.tables.disclosure}>
                    <Icon
                      size={20}
                      name="angle-right"
                      color={theme.colors.grey5}
                    />
                  </View>
                </TouchableOpacity>
              ))
            ) : (
              <View style={theme.tables.emptyView}>
                <Text testID="empty-text" style={theme.tables.emptyText}>
                  {translate('pricings.noPricingGroupsMessage')}
                </Text>
              </View>
            )}
          </View>
        </View>
        <CreateButton onPress={onPressCreate} />
      </Section>
    </ScreenLayout>
  );
};
