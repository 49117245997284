import { StyleSheet } from 'react-native';
import theme from '../../../../common/default-theme';

const styles = StyleSheet.create({
  filters: {
    marginBottom: 20,
    flexDirection: 'row',
    alignItems: 'center',
  },
  tableContainer: {
    marginBottom: 20,
  },
  cellName: {
    width: 200,
    marginRight: 10,
  },
  cellOptions: {
    flex: 1,
    marginRight: 10,
  },
  cellCopy: {
    marginLeft: 10,
  },
  cellPrice: {
    width: 120,
    marginRight: 10,
  },
  cellTax: {
    width: 180,
  },
  // Option Groups
  headerName: {
    width: 200,
  },
  headerAddOnPrice: {
    width: 120,
    marginRight: 32,
    textAlign: 'center',
  },
  // Modifiers
  headerModifier: {
    width: 186,
    marginRight: 10,
  },
  headerGroups: {
    flex: 1,
    paddingLeft: 12,
    marginRight: 10,
  },
  headerPrice: {
    width: 120,
    textAlign: 'center',
  },
  headerTax: {
    width: 268,
    paddingLeft: 22,
  },
  // Empty List View
  emptyListView: {
    height: 200,
    width: '100%',
    marginTop: 10,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: theme.radius.s,
    backgroundColor: theme.colors.grey1,
  },
  emptyListText: {
    color: theme.colors.grey6,
  },
});

export default styles;
