import {
  getFormattedAddress,
  ROW_ONE_SEGMENTS,
  ROW_TWO_SEGMENTS,
} from '../../utils/places.util';
import { Session } from '../../state/Session';
import { isStoreAddressExist } from '@oolio-group/client-utils';

export const generateAddress = (session: Session): string => {
  const address = isStoreAddressExist(session.currentStore?.address)
    ? session.currentStore?.address
    : session.currentVenue?.address;
  const formattedStoreAddress = address
    ? [
        [getFormattedAddress(address, ROW_ONE_SEGMENTS)],
        [getFormattedAddress(address, ROW_TWO_SEGMENTS)],
      ]
    : [[undefined], [undefined]];

  return formattedStoreAddress.join('\n') + '\n';
};
