import { StyleFn } from '@oolio-group/domain';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useFela } from 'react-fela';
import {
  TextInput,
  View,
  ViewStyle,
  TouchableOpacity,
  Platform,
  ActivityIndicator,
} from 'react-native';
import Icon from '../Icon/Icon';
import IconButton from '../Button/IconButton';
import scale from '../../common/theme';

const defaultContainerStyle: StyleFn = ({ theme, secondary }) => ({
  borderColor: secondary ? theme.colors.boxBorder : theme.colors.primaryDarkest,
  backgroundColor: secondary ? theme.colors.white : theme.colors.primaryDarkest,
  borderWidth: scale.moderateScale(0.8),
  height: theme.input.height,
  width: scale.moderateScale(180),
  borderRadius: theme.radius.small,
  paddingHorizontal: scale.moderateScale(10),
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
});

const titleStyle: StyleFn = ({ theme, secondary }) => ({
  height: theme.input.height,
  width: '100%',
  paddingHorizontal: scale.moderateScale(5),
  color: secondary ? theme.colors.primary : theme.colors.heading1,
  textAlign: 'left',
  ...theme.font14RegularCharcoal,
  ...Platform.select({
    web: {
      outlineWidth: 0,
    },
  }),
});

const defaultCancelIconStyle: StyleFn = () => ({
  paddingHorizontal: scale.moderateScale(10),
});

const iconContainerStyle: StyleFn = ({ theme }) => ({
  backgroundColor: theme.colors.white,
  alignSelf: 'center',
  marginHorizontal: scale.moderateScale(2),
});

export interface SearchBarProps {
  testID?: string;
  value?: string;
  isLoading?: boolean;
  onChange?: (text: string) => void;
  placeholder?: string;
  onSubmitEditing?: () => void;
  containerStyle?: ViewStyle;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  textInputStyle?: any;
  iconColor?: string;
  placeholderColor?: string;
  showCancel?: boolean;
  onCancel?: () => void;
  autoFocus?: boolean;
  showFindIcon?: boolean;
  secondary?: boolean;
  onPressFind?: (value: string) => void;
  maxLength?: number;
  onFocus?: () => void;
  onBlur?: () => void;
}

const SearchBar: React.FC<SearchBarProps> = ({
  testID,
  value: valueProp,
  onChange,
  placeholder,
  onSubmitEditing,
  containerStyle,
  textInputStyle,
  iconColor,
  placeholderColor,
  showCancel,
  onCancel,
  autoFocus,
  showFindIcon,
  secondary,
  onPressFind,
  maxLength,
  onFocus,
  onBlur,
  isLoading,
}) => {
  const { css, theme } = useFela({ secondary });
  const textInputRef = useRef<TextInput>(null);
  const [value, setValue] = useState('');

  const onPressSearch = useCallback(() => {
    onPressFind && onPressFind(value);
  }, [onPressFind, value]);

  const onChangeSearch = useCallback(
    (value: string) => {
      setValue(value);

      if (onChange) {
        // controlled mode
        onChange(value);
      }
    },
    [onChange],
  );

  const onCancelSearch = useCallback(() => {
    onChangeSearch('');
    onCancel && onCancel();
  }, [onCancel, onChangeSearch]);

  useEffect(() => {
    if (valueProp === undefined) return;

    // controlled mode
    setValue(valueProp);
  }, [valueProp]);

  useEffect(() => {
    let autoFocusTimeOut: number | null;
    if (autoFocus) {
      autoFocusTimeOut = setTimeout(() => {
        textInputRef.current?.focus();
      }, 100);
    }
    return () => {
      autoFocusTimeOut && clearTimeout(autoFocusTimeOut);
    };
  }, [autoFocus, textInputRef]);

  return (
    <View style={[css(defaultContainerStyle), containerStyle]}>
      <TextInput
        maxLength={maxLength}
        testID={testID}
        value={value}
        ref={textInputRef}
        onChangeText={onChangeSearch}
        placeholder={placeholder}
        onSubmitEditing={onSubmitEditing}
        style={[css(titleStyle), textInputStyle]}
        placeholderTextColor={
          placeholderColor
            ? placeholderColor
            : secondary
            ? theme.colors.paragraph
            : theme.colors.heading1
        }
        autoFocus={autoFocus}
        onFocus={onFocus}
        onBlur={onBlur}
      />
      {showCancel && !!value ? (
        <TouchableOpacity onPress={onCancelSearch} testID={`${testID}-cancel`}>
          <Icon
            style={css(defaultCancelIconStyle)}
            name="times"
            size={20}
            color={
              iconColor
                ? iconColor
                : secondary
                ? theme.colors.paragraph
                : theme.colors.white
            }
          />
        </TouchableOpacity>
      ) : null}
      {((!isLoading && showFindIcon) || value?.length == 0) && (
        <IconButton
          icon={'Search'}
          iconSize={20}
          containerStyle={css(iconContainerStyle)}
          iconColor={
            iconColor
              ? iconColor
              : secondary
              ? theme.colors.paragraph
              : theme.colors.white
          }
          onPress={onPressSearch}
          disabled={!onPressFind}
          testID={`${testID}-button`}
        />
      )}
      {isLoading && showFindIcon && value && value?.length > 0 && (
        <ActivityIndicator size="small" color={theme.colors.paragraph} />
      )}
    </View>
  );
};

SearchBar.defaultProps = {
  showFindIcon: true,
};

export default SearchBar;
