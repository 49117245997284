import React, { useEffect, useState } from 'react';
import {
  Text,
  View,
  ViewStyle,
  TextInput,
  TouchableOpacity,
  StyleSheet,
  TextInputProps,
  FlatList,
  ActivityIndicator,
} from 'react-native';
import theme from '../../../common/default-theme';
import inputStyles from '../Inputs/Inputs.styles';
import { Icon } from '../../Icon/Icon';
import { Address } from '@oolio-group/domain';
import { getFormattedAddress } from '../../../utils/places.util';
import {
  MIN_CHARS_TO_TRIGGER_SEARCH,
  usePlaces,
} from '../../../hooks/app/usePlaces';
import { useTranslation } from '@oolio-group/localization/dist';

export interface SelectAddressProps extends TextInputProps {
  title?: string;
  testID: string;
  placeholder: string;
  errorMessage?: string;
  containerStyle?: ViewStyle | ViewStyle[];
  onChangeAddress: (address: Address) => void;
  address?: Address;
}

const localStyles = StyleSheet.create({
  options: {
    width: '100%',
    position: 'absolute',
    borderRadius: theme.radius.m,
    backgroundColor: theme.colors.light,
    ...theme.shadow.light,
    borderWidth: 1,
    borderColor: theme.colors.grey3,
  },
  option: {
    padding: 12,
    borderBottomWidth: 1,
    borderStyle: 'solid',
    borderBottomColor: theme.colors.grey3,
  },
  noResultsText: {
    lineHeight: 60,
    textAlign: 'center',
    color: theme.colors.grey5,
  },
});

const SelectAddress: React.FC<SelectAddressProps> = ({
  title,
  testID,
  placeholder,
  errorMessage,
  containerStyle,
  address,
  onChangeAddress,
  ...props
}: SelectAddressProps) => {
  const { translate } = useTranslation();
  const [hidePlacesList, setHidePlacesList] = useState<boolean>(true);
  const [searchText, setSearchText] = useState<string>('');
  const { placesList, handlePlaceSelect, placeDetails, loading } = usePlaces(
    searchText,
    hidePlacesList,
  );

  useEffect(() => {
    if (address) {
      setSearchText(text => {
        if (text.replace(/\s/g, '').length > 0) {
          return text;
        }
        return getFormattedAddress(address);
      });
    }
  }, [address]);

  useEffect(() => {
    if (placeDetails) {
      onChangeAddress(placeDetails.address);
      setSearchText(placeDetails.formattedAddress);
    }
  }, [placeDetails, onChangeAddress]);

  const _onSelectPlace = (placeId: string) => {
    setHidePlacesList(true);
    handlePlaceSelect(placeId);
  };

  const _onChangeSearchText = (text: string) => {
    setSearchText(text);
    setHidePlacesList(text.length < MIN_CHARS_TO_TRIGGER_SEARCH);
    if (text.length < MIN_CHARS_TO_TRIGGER_SEARCH) {
      onChangeAddress(undefined as unknown as Address);
    }
  };

  return (
    <View style={containerStyle}>
      {!!title && <Text style={inputStyles.inputTitleText}>{title}</Text>}
      <View>
        <View
          style={[
            inputStyles.inputWrapper,
            props.editable === true
              ? theme.containers.enabled
              : theme.containers.disabled,
          ]}
        >
          <TextInput
            testID={testID}
            placeholder={placeholder}
            placeholderTextColor={theme.colors.grey5}
            style={inputStyles.inputContainer}
            onChangeText={_onChangeSearchText}
            value={searchText}
            {...props}
          />
          <View style={inputStyles.accessory}>
            {loading ? (
              <ActivityIndicator />
            ) : (
              <Icon size={20} color={theme.colors.grey5} name="Search" />
            )}
          </View>
        </View>
      </View>
      {!hidePlacesList && (
        <FlatList
          scrollEnabled
          data={placesList}
          // eslint-disable-next-line react-native/no-inline-styles
          style={[localStyles.options, { top: !!title ? 72 : 50 }]}
          renderItem={({ item }) => {
            return (
              <TouchableOpacity
                testID="placeItem"
                style={localStyles.option}
                onPress={() => _onSelectPlace(item.placeId)}
              >
                <Text>{item.description}</Text>
              </TouchableOpacity>
            );
          }}
          keyExtractor={item => item.placeId}
          ListEmptyComponent={() => (
            <Text style={localStyles.noResultsText}>
              {translate('placesInput.noResults')}
            </Text>
          )}
        />
      )}
      {errorMessage && (
        <Text style={inputStyles.errorText}>{errorMessage}</Text>
      )}
    </View>
  );
};

export default SelectAddress;

SelectAddress.defaultProps = {
  editable: true,
};
