import { Platform } from 'react-native';

export const isSafari = () =>
  /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

export const downloadFile = (url: string, fileName: string) => {
  if (Platform.OS !== 'web') {
    return;
  }

  try {
    const link = document.createElement('a');
    const body = document.body || null;

    if (link && body) {
      link.href = url;
      link.download = fileName;
      body.appendChild(link);
      link.click();
      body.removeChild(link);
    }
  } catch (e) {
    console.log('Error initiating file download. fallback to window.open', e);
    if (window && typeof window.open === 'function') {
      window.open(url, '_blank');
    }
  }
};
