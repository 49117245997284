import { Subject } from 'rxjs';

export class SyncAppUtility {
  private subject = new Subject();

  syncApp = (state: number) => {
    this.subject.next(state);
  };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  get getSubscriptionState$(): Subject<any> {
    return this.subject;
  }
}

export const syncAppUtility = new SyncAppUtility();
