import React from 'react';
import { useApolloClient } from '@apollo/client/react/hooks';
import { useModal } from '@oolio-group/rn-use-modal';
import { useCallback } from 'react';
import { Platform } from 'react-native';
import { ServiceConfiguration, logout } from 'react-native-app-auth';
import { OrderSyncAlertModal } from '../../components/Modals/OrderSyncAlert/OrderSyncAlert';
import { APOLLO_CACHE_PERSIST_KEY, auth0Config } from '../../constants';
import { getQueue, resetQueue } from '../../events/eventsQueue';
import { onboardingUtility } from '../../state/onboardingUtility';
import { setSession, setUserActivity } from '../../state/preferences';
import { tokenUtility } from '../../state/tokenUtility';
import { userUtility } from '../../state/userUtility';
import Database from '../../storage/database';
import { analyticsService } from '../../analytics/AnalyticsService';
import { useOrderNumber } from '../orders/useOrderNumber';
import { useSyncOrderEvents } from './useSyncOrderEvents';
import { delay } from '../../utils/delay';

/**
 * This hook only be used within protected screens / components
 * It will listen to authState changes, and redirects user back to login screen
 *
 * @returns
 */
export const useLogout = () => {
  const client = useApolloClient();
  const { setOrderCounter } = useOrderNumber();
  const { showModal } = useModal();
  const { syncAllOrderEvents } = useSyncOrderEvents();

  const resetStorage = useCallback(async () => {
    const dbInstance = await Database.getInstance();
    await Promise.all([
      dbInstance.collections.order_events?.remove(),
      dbInstance.collections[APOLLO_CACHE_PERSIST_KEY]?.remove(),
    ]);
  }, []);

  const resetLocalAuthState = useCallback(async () => {
    // Reset client cache and store
    await delay(1000);
    await client.clearStore();
    userUtility.clearUserActivity();
    setUserActivity({
      posUser: undefined,
      recentUserId: undefined,
      officeUsers: {},
    });
    onboardingUtility.clearOnboardingInfo();
    tokenUtility.clearToken();
    // Clear order queued events
    resetQueue();
    setOrderCounter('-0');
    setSession({});
  }, [client, setOrderCounter]);

  const performLogout = useCallback(async () => {
    try {
      const idToken = tokenUtility.idToken;
      const query = new URLSearchParams({
        ...(idToken && { id_token_hint: idToken as string }),
        rd: auth0Config.redirectUrl,
      });
      if (Platform.OS === 'web') {
        // Need to clean state before redirect away
        await resetStorage();
        await resetLocalAuthState();
        window.location.href = `${
          auth0Config.serviceConfiguration.endSessionEndpoint
        }?${query.toString()}`;
      } else {
        // System will show a popup to prompt for open browser
        // Display popup first then clean state
        const config = {
          issuer: auth0Config.issuer,
          clientId: auth0Config.clientId,
          serviceConfiguration: {
            authorizationEndpoint:
              auth0Config.serviceConfiguration.endSessionEndpoint,
            tokenEndpoint: auth0Config.serviceConfiguration.endSessionEndpoint,
            endSessionEndpoint:
              auth0Config.serviceConfiguration.endSessionEndpoint,
            revocationEndpoint:
              auth0Config.serviceConfiguration.endSessionEndpoint,
            registrationEndpoint:
              auth0Config.serviceConfiguration.endSessionEndpoint,
          } as ServiceConfiguration,
        };
        await logout(config, {
          idToken: idToken as string,
          postLogoutRedirectUrl: auth0Config.redirectUrl,
        });

        await resetStorage();
        await resetLocalAuthState();
      }
    } catch (e) {
      console.log('err', e);
    }
  }, [resetLocalAuthState, resetStorage]);

  const syncAndLogout = useCallback(async () => {
    await syncAllOrderEvents();
    await performLogout();
  }, [syncAllOrderEvents, performLogout]);

  const onLogout = useCallback(async () => {
    const data = await getQueue();
    if (!data || !data.length) {
      analyticsService.reset();
      performLogout();
    } else {
      showModal(<OrderSyncAlertModal onSync={syncAndLogout} />);
    }
  }, [performLogout, showModal, syncAndLogout]);

  return {
    logout: onLogout,
  };
};
