import { noopHandler, parseApolloError } from '../../utils/errorHandlers';
import { useCallback, useMemo, useState } from 'react';
import { useLazyQuery, useMutation } from '@apollo/client/react/hooks';
import {
  GET_ORGANIZATION_DETAILS,
  UPDATE_ORG_BASIC_DETAILS,
} from '../../graphql/settings';
import { Organization, UpdateOrganizationInput } from '@oolio-group/domain';
import { getError, isLoading } from '../../utils/apolloErrorResponse.util';
import { useSession } from './useSession';

export interface UseOrganization {
  loading: boolean;
  error: string | undefined;
  organization?: Organization;
  updateOrganization: (input: UpdateOrganizationInput) => Promise<boolean>;
  getOrganizationById: (id: string) => void;
}

export function useOrganization(): UseOrganization {
  const [organization, setOrganization] = useState<Organization>();
  const [session, updateSession] = useSession();

  const onOrganizationDetailsRetrieved = useCallback(
    data => {
      // formatted response
      if (data && data.getOrganizationById) {
        setOrganization(data.getOrganizationById);
      }
    },
    [setOrganization],
  );

  const [getOrganizationRequest, getOrganizationResponse] = useLazyQuery(
    GET_ORGANIZATION_DETAILS,
    {
      fetchPolicy: 'cache-and-network',
      onCompleted: onOrganizationDetailsRetrieved,
      onError: noopHandler,
    },
  );

  const [updateOrganizationRequest, updateOrganizationResponse] = useMutation(
    UPDATE_ORG_BASIC_DETAILS,
    {
      onError: noopHandler,
    },
  );

  const RESPONSE_ENTITIES = [
    getOrganizationResponse,
    updateOrganizationResponse,
  ];

  const updateOrganization = useCallback(
    async (input: UpdateOrganizationInput): Promise<boolean> => {
      const response = await updateOrganizationRequest({
        variables: {
          input,
        },
      });
      // formatted response
      if (response?.data && response?.data?.updateOrganization) {
        setOrganization(response.data.updateOrganization);
        updateSession({
          ...session,
          currentOrganization: {
            ...session.currentOrganization,
            currencyCode: response?.data?.updateOrganization
              .currencyCode as string,
            country: response?.data?.updateOrganization.country as string,
            businessIdentifier: response?.data?.updateOrganization
              .businessIdentifier as string,
          },
        });
        return true;
      }

      return false;
    },
    [updateOrganizationRequest, session, updateSession],
  );

  const getOrganizationById = useCallback(
    (id: string): void => {
      // fetch organization details
      getOrganizationRequest({
        variables: {
          orgId: id,
        },
      });
    },
    [getOrganizationRequest],
  );

  const loading = isLoading(RESPONSE_ENTITIES);
  const error = getError(RESPONSE_ENTITIES);

  return useMemo(
    () => ({
      loading,
      error: error ? parseApolloError(error) : undefined,
      organization,
      updateOrganization,
      getOrganizationById,
    }),
    [loading, error, organization, updateOrganization, getOrganizationById],
  );
}
