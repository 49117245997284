/* eslint-disable react-native/no-inline-styles */
import React, { useCallback, useEffect, useState, useMemo } from 'react';
import { View, Text, TouchableOpacity } from 'react-native';
import { useTranslation } from '@oolio-group/localization';
import styles from './ManageCustomers.styles';
import {
  CreateCustomerRequest,
  Customer,
  FeatureIDs,
} from '@oolio-group/domain';
import { useCustomers } from '../../../../hooks/orders/useCustomers';
import { useNotification } from '../../../../hooks/Notification';
import { useIsFocused, useNavigation } from '@react-navigation/native';
import { useCheckFeatureEnabled } from '../../../../hooks/app/features/useCheckFeatureEnabled';
import theme from '../../../../common/default-theme';
import ScreenLayout from '../../../../components/Office/ScreenLayout/ScreenLayout';
import Section from '../../../../components/Office/Section/Section';
import Search from '../../../../components/Shared/Search/Search';
import Icon from '../../../../components/Icon/Icon';
import CreateButton from '../../../../components/Office/CreateButton/CreateButton';
import CreateCustomerModal from './CreateCustomerModal/CreateCustomerModal';
import ButtonIcon from '../../../../components/Shared/TreatButton/ButtonIcon';
import Pagination from '../../../../components/POS/Pagination/Pagination';
import { debounce } from 'lodash';
import LoadingIndicator from '../../../../components/LoadingIndicator/LoadingIndicator';
import ModalWrapper from '../../../../hooks/ModalWrapper';
import { useExportCustomers } from '../../../../hooks/app/exportEntities/useExportCustomers';

const itemsPerPage = 10;
const ManageCustomers: React.FC = () => {
  const isFocused = useIsFocused();
  const navigation = useNavigation();
  const { translate } = useTranslation();
  const { showNotification } = useNotification();
  const isFeatureEnabled = useCheckFeatureEnabled();
  const [searchString, setSearchString] = useState<string>('');
  const [currentPage, setCurrentPage] = useState(1);
  const [showCreateModal, setShowModal] = useState(false);
  const { processCustomersExport, isExportEntityRunning } =
    useExportCustomers();

  const {
    paginatedCustomers: customers,
    addNewCustomer,
    loading,
    error,
    getPaginatedCustomers,
    onFetchMore,
  } = useCustomers();

  const pageItems = useMemo(() => {
    return customers?.slice(
      (currentPage - 1) * itemsPerPage,
      currentPage * itemsPerPage,
    );
  }, [customers, currentPage]);

  const isLoyaltyEnabled = isFeatureEnabled(FeatureIDs.LOYALTY);

  const debouncedSearch = React.useRef(
    debounce(async (searchValue: string) => {
      setSearchString(searchValue);
    }, 300),
  ).current;

  useEffect(() => {
    if (!isFocused && searchString.length <= 2) return;
    getPaginatedCustomers(searchString);
    setCurrentPage(1);
  }, [isFocused, getPaginatedCustomers, searchString]);

  useEffect(() => {
    if (error) {
      showNotification({
        error: true,
        message: error,
      });
    }
  }, [error, showNotification]);

  const onSearchTextChange: (value: string) => void = useCallback(
    (value: string) => {
      debouncedSearch(value);
    },
    [debouncedSearch],
  );

  const createCustomer = useCallback(
    async (customerInfo: Partial<CreateCustomerRequest>) => {
      const createdCustomer = await addNewCustomer(customerInfo);
      if (createdCustomer) {
        const { firstName, lastName } = customerInfo;
        const customerName = `${firstName} ${lastName}`;
        showNotification({
          success: true,
          message: translate('backOfficeCustomers.successMessage', {
            customerName,
          }),
        });
        setShowModal(false);
        setSearchString('');
        getPaginatedCustomers('');
        setCurrentPage(1);
      }
    },
    [addNewCustomer, getPaginatedCustomers, showNotification, translate],
  );

  const onPressCreate = useCallback(() => {
    setShowModal(true);
  }, []);

  const navigateToManageCustomers: (customer: Customer) => void = useCallback(
    (customer: Customer) => {
      navigation &&
        navigation.navigate('ManageCustomersTab', {
          customer: customer,
        });
    },
    [navigation],
  );

  const exportDataToCSV = useCallback(async () => {
    processCustomersExport();
  }, [processCustomersExport]);

  const onPageChange = useCallback(
    (page: number) => {
      setCurrentPage(page);
      // calculate to fetch more
      const totalPagesRequired = Math.ceil(customers.length / itemsPerPage);
      if (page >= totalPagesRequired - 1) {
        onFetchMore();
      }
    },
    [customers.length, onFetchMore],
  );

  const renderCustomerItems = useMemo(() => {
    if (loading && !showCreateModal)
      return (
        <View style={styles.loadingContainer}>
          <LoadingIndicator />
        </View>
      );

    return (
      <View>
        {pageItems.map((customer: Customer, i: number) => {
          return (
            <TouchableOpacity
              key={i}
              testID="data-row"
              style={theme.tables.row}
              onPress={() => navigateToManageCustomers(customer)}
            >
              <Text numberOfLines={1} style={[styles.rowText, styles.cellName]}>
                {`${customer.firstName} ${customer.lastName}`}
              </Text>
              <Text style={[styles.rowText, styles.cellEmail]}>
                {customer.email}
              </Text>
              <Text style={[styles.rowText, styles.cellPhone]}>
                {customer.phone}
              </Text>
              {isLoyaltyEnabled && (
                <Text
                  style={[
                    styles.rowText,
                    styles.cellPoints,
                    { color: theme.colors.primary },
                  ]}
                >
                  {customer.loyaltyPointsBalance}
                </Text>
              )}
              <View style={styles.cellDisclosure}>
                <Icon size={20} name="angle-right" color={theme.colors.grey4} />
              </View>
            </TouchableOpacity>
          );
        })}
        <Pagination
          page={currentPage}
          onPageChange={onPageChange}
          dataLength={customers.length}
          pageLength={pageItems.length}
        />
      </View>
    );
  }, [
    currentPage,
    customers.length,
    isLoyaltyEnabled,
    loading,
    navigateToManageCustomers,
    onPageChange,
    pageItems,
    showCreateModal,
  ]);

  return (
    <ScreenLayout
      hideFooter={true}
      title={translate('navigation.generalSettingsPageTitle', {
        appName: translate('appName'),
      })}
    >
      <Section title="Manage Customers" layoutWidth="medium">
        <View style={styles.filtersContainer}>
          <Search
            testID="search-customers"
            onChangeText={onSearchTextChange}
            value={searchString}
            containerStyle={styles.searchContainer}
            placeholder={
              translate(
                'backOfficeCustomers.SearchCustomersByNameEmailOrPhone',
              ) + '...'
            }
          />
          <CreateButton onPress={onPressCreate} />
          <ButtonIcon
            testID="download-csv"
            type="neutral"
            size={44}
            icon="cloud-download"
            onPress={() => exportDataToCSV()}
            containerStyle={{ marginLeft: 10 }}
            disabled={isExportEntityRunning}
          />
        </View>
        <View style={styles.tableContainer}>
          <View style={theme.tables.header}>
            <Text style={[theme.tables.headerText, styles.cellName]}>
              {translate('form.customerName')}
            </Text>
            <Text style={[theme.tables.headerText, styles.cellEmail]}>
              {translate('form.email')}
            </Text>
            <Text style={[theme.tables.headerText, styles.cellPhone]}>
              {translate('form.phone')}
            </Text>
            {isLoyaltyEnabled && (
              <Text style={[theme.tables.headerText, styles.cellPoints]}>
                Points
              </Text>
            )}
            <View style={styles.cellDisclosure} />
          </View>
        </View>
        {renderCustomerItems}
      </Section>
      <ModalWrapper isVisible={showCreateModal}>
        <CreateCustomerModal
          onSubmit={createCustomer}
          onDismiss={() => setShowModal(false)}
          loading={loading}
        />
      </ModalWrapper>
    </ScreenLayout>
  );
};

export default ManageCustomers;
