/* eslint-disable react-native/no-inline-styles */
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { View } from 'react-native';
import { useCurrency, useTranslation } from '@oolio-group/localization';
import {
  useIsFocused,
  useNavigation,
  useRoute,
} from '@react-navigation/native';
import {
  Adjustment,
  AdjustmentType,
  AdjustmentUnit,
} from '@oolio-group/domain';
import { useAdjustments } from '../../../../../hooks/app/useAdjustments';
import { isEqual } from 'lodash';
import adjustmentStyle from './Adjustments.style';
import ScreenLayout from '../../../../../components/Office/ScreenLayout/ScreenLayout';
import Section from '../../../../../components/Office/Section/Section';
import CreateButton from '../../../../../components/Office/CreateButton/CreateButton';
import Search from '../../../../../components/Shared/Search/Search';
import { useVenues } from '../../../../../hooks/app/useVenues';
import Discounts from './Discounts';
import Surcharges from './Surcharges';

export type ErrorState = Record<'name' | 'amount', boolean>;

export interface AdjustmentRowItem extends Partial<Adjustment> {
  amountStr: string;
}

const Adjustments: React.FC = () => {
  const route = useRoute();
  const { translate } = useTranslation();
  const { formatCurrency, formatMoneyValue } = useCurrency();
  const navigation = useNavigation();
  const {
    loading: venueLoading,
    getStoresByVenue,
    storesByVenue,
  } = useVenues();

  const isFocused = useIsFocused();
  const [searchString, setSearchString] = useState<string>('');

  const [customAdjustments, setCustomAdjustments] = useState<
    AdjustmentRowItem[]
  >([]);
  const originalAdjustments = useRef<AdjustmentRowItem[]>([]);

  const { venueId, type: routeType } = route.params as {
    venueId: string;
    type?: AdjustmentType;
  };

  useEffect(() => {
    getStoresByVenue(venueId);
  }, [getStoresByVenue, venueId]);

  const getStoreNames = useCallback(
    (storeIds: string[]) => {
      const storeNames = storesByVenue
        ?.filter(s => storeIds.includes(s?.id as string))
        .map(s => s?.name);
      return storeNames?.join(', ');
    },
    [storesByVenue],
  );

  const { adjustments, getAllAdjustments, loading } = useAdjustments({
    venueId,
  });

  useEffect(() => {
    isFocused && getAllAdjustments(routeType);
  }, [getAllAdjustments, isFocused, routeType]);

  useEffect(() => {
    if (!adjustments) return;
    const customAdjustmentData = adjustments.map(item => ({
      ...item,
      amountStr: `${item.amount}`,
    }));
    setCustomAdjustments(customAdjustmentData);
    originalAdjustments.current = customAdjustmentData;
  }, [adjustments]);

  const navigateToAdjustmentScreen = useCallback(
    (adjustment?: Adjustment) => {
      navigation.navigate(routeType ? routeType.toLowerCase() : 'Adjustment', {
        adjustmentId: adjustment?.id,
        venueId,
        ...(routeType && { routeType }),
      });
    },
    [navigation, venueId, routeType],
  );

  const getFormattedAmount = useCallback(
    (amount: number, type: AdjustmentUnit) => {
      const absValue = routeType ? Math.abs(amount) : amount;
      if (type === AdjustmentUnit.PERCENTAGE)
        return `${formatMoneyValue(absValue)}%`;
      else return formatCurrency(absValue);
    },
    [formatCurrency, formatMoneyValue, routeType],
  );

  const onSearchTextChange: (value: string) => void = useCallback(
    (value: string) => {
      setSearchString(value);
    },
    [],
  );

  const filteredAdjustments = useMemo(
    () =>
      adjustments.filter(
        a => !!a.name?.toLowerCase()?.match(searchString?.toLowerCase()),
      ),
    [searchString, adjustments],
  );

  const hasDataChanged = !isEqual(
    originalAdjustments.current,
    customAdjustments,
  );

  const adjustmentLocales = useMemo(() => {
    let title = '',
      description = '',
      searchDescription = '';
    switch (routeType) {
      case AdjustmentType.DISCOUNT:
        title = translate('backofficeVenueSettingAdjustments.discounts');
        description = translate(
          'backofficeVenueSettingAdjustments.discountDescription',
        );
        searchDescription = translate(
          'backofficeVenueSettingAdjustments.searchDiscountPlaceholder',
        );
        break;
      case AdjustmentType.SURCHARGE:
        title = translate('backofficeVenueSettingAdjustments.surcharges');
        description = translate(
          'backofficeVenueSettingAdjustments.surchargeDescription',
        );
        searchDescription = translate(
          'backofficeVenueSettingAdjustments.searchSurchargePlaceholder',
        );
        break;
      default:
        title = translate('backofficeVenueSettingAdjustments.adjustment');
        description = translate(
          'backofficeVenueSettingAdjustments.adjustmentDescription',
        );
        searchDescription = translate(
          'backofficeVenueSettingAdjustments.searchAdjustmentPlaceholder',
        );
    }
    return { title, description, searchDescription };
  }, [routeType, translate]);

  const isActiveSchedule = (adjustment: Adjustment) => {
    return (
      // This check is for backward compatibility
      ((adjustment?.schedule?.length &&
        adjustment?.schedule?.every(item => item.isActive !== false) &&
        adjustment.isScheduledEnabled !== false) ??
        false) ||
      // This check is for toggle turn on and days toggled on as well
      (adjustment.isScheduledEnabled &&
        adjustment?.schedule?.length &&
        adjustment?.schedule?.every(item => item.isActive !== false)) ||
      // This check is for toggle turn on and dates added
      (adjustment.isDateRangeEnabled && adjustment?.dateTimeRange?.length)
    );
  };

  return (
    <ScreenLayout
      loading={loading || venueLoading}
      hideFooter={!hasDataChanged && loading}
      title="Adjustments | Oolio"
    >
      <Section
        layoutWidth="medium"
        title={adjustmentLocales.title}
        subtitle={adjustmentLocales.description}
      >
        <View style={adjustmentStyle.filtersContainer}>
          <Search
            testID="search-customers"
            onChangeText={onSearchTextChange}
            containerStyle={adjustmentStyle.searchContainer}
            placeholder={adjustmentLocales.searchDescription}
          />
          <CreateButton onPress={() => navigateToAdjustmentScreen()} />
        </View>

        {routeType === AdjustmentType.DISCOUNT ? (
          <Discounts
            discounts={filteredAdjustments}
            getFormattedAmount={getFormattedAmount}
            isActiveSchedule={isActiveSchedule}
            navigateToScreen={navigateToAdjustmentScreen}
            getStoreNames={getStoreNames}
          />
        ) : (
          <Surcharges
            surcharges={filteredAdjustments}
            getFormattedAmount={getFormattedAmount}
            isActiveSchedule={isActiveSchedule}
            navigateToScreen={navigateToAdjustmentScreen}
            getStoreNames={getStoreNames}
            routeType={routeType}
          />
        )}
      </Section>
    </ScreenLayout>
  );
};

export default Adjustments;
