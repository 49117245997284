import React from 'react';
import { View, Text, StyleSheet, ViewStyle } from 'react-native';
import theme from '../../../common/default-theme';

export interface SectionProps {
  title?: string;
  subtitle?: string;
  children: React.ReactNode | React.ReactNode[];
  layoutWidth?: 'small' | 'medium' | 'large' | 'full';
}

const getLayoutWidth = (width: string): ViewStyle => {
  switch (width) {
    case 'small':
      return { maxWidth: theme.layoutWidth.s };
    case 'medium':
      return { maxWidth: theme.layoutWidth.m };
    case 'large':
      return { maxWidth: theme.layoutWidth.l };
    case 'full':
      return { flex: 1 };
    default:
      return { maxWidth: theme.layoutWidth.s };
  }
};

const Section: React.FC<SectionProps> = ({
  title,
  subtitle,
  children,
  layoutWidth,
}: SectionProps) => {
  return (
    <View
      style={[styles.sectionContainer, getLayoutWidth(layoutWidth || 'small')]}
    >
      {!!title && (
        <View testID="section-title" style={styles.sectionTitleContainer}>
          <Text style={styles.sectionTitleText}>{title}</Text>
          {!!subtitle && (
            <Text style={styles.sectionSubtitleText}>{subtitle}</Text>
          )}
        </View>
      )}
      <View style={styles.sectionFormContainer}>{children}</View>
    </View>
  );
};

export default Section;

const styles = StyleSheet.create({
  sectionContainer: {
    width: '100%',
    alignItems: 'center',
    minWidth: theme.layoutWidth.s,
  },
  sectionFormContainer: {
    width: '100%',
    marginVertical: 20,
  },
  sectionTitleContainer: {
    padding: 12,
    width: '100%',
    borderRadius: theme.radius.s,
    backgroundColor: theme.colors.primaryLight,
  },
  sectionTitleText: {
    lineHeight: 14,
    color: theme.colors.primary,
  },
  sectionSubtitleText: {
    marginTop: 8,
    lineHeight: 20,
    color: theme.colors.grey6,
  },
});
