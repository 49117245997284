import React, { useMemo } from 'react';
import { View, Text } from 'react-native';
import { ProductPricing as ProductPricingDefault } from '@oolio-group/domain';
import { useCurrency } from '@oolio-group/localization';
import theme from '../../../../../../common/default-theme';
import styles from '../../PriceLists.styles';
import InputToggle from '../../../../../../components/Shared/Inputs/InputToggle';
import InputText from '../../../../../../components/Shared/Inputs/InputText';
import ButtonIcon from '../../../../../../components/Shared/TreatButton/ButtonIcon';

interface ProductPricing extends ProductPricingDefault {
  isSelected?: boolean;
  tax?: string;
  price?: string;
}

interface ProductPricingRowProps {
  productPricing: ProductPricing;
  defaultPricing: ProductPricing;
  isDefault?: boolean;
  onChange: (
    id: string,
    prop: string,
    value: string | boolean | string[],
  ) => void;
  onDeleteRow: (id: string) => void;
  rowIndex: number;
}

export const ProductsRow: React.FC<ProductPricingRowProps> = ({
  productPricing,
  defaultPricing,
  isDefault,
  onChange,
  onDeleteRow,
  rowIndex,
}) => {
  const { appendCurrency, currencySymbol } = useCurrency();
  const prices = useMemo(() => {
    const defaultPrice =
      (defaultPricing?.sellingPrice && defaultPricing?.sellingPrice.amount) ||
      0;
    const productPrice =
      productPricing?.sellingPrice && productPricing?.sellingPrice.amount;
    const change = (productPrice || 0) - defaultPrice;
    return {
      defaultPrice: defaultPrice,
      productPrice: productPrice,
      change: change.toFixed(2),
    };
  }, [defaultPricing, productPricing]);

  return (
    <View testID="row-product" key={rowIndex} style={theme.tables.row}>
      {!isDefault && (
        <InputToggle
          testID="toggle-product"
          isToggled={productPricing?.isSelected || false}
          onToggle={() =>
            onChange(
              productPricing.product.id,
              'isSelected',
              !productPricing?.isSelected,
            )
          }
        />
      )}
      <Text style={styles.cellProduct}>{productPricing.product?.name}</Text>
      <View style={styles.cellPriceView}>
        <Text testID="price-default" style={styles.cellPriceText}>
          {appendCurrency(`${prices.defaultPrice.toFixed(2) || '0.00'}`)}
        </Text>
      </View>
      {!isDefault && (
        <>
          <InputText
            testID="input-price"
            label={currencySymbol}
            value={prices.productPrice?.toString() || '0.00'}
            placeholder="0.00"
            onChangeText={onChange.bind(
              null,
              productPricing.product.id,
              'sellingPrice',
            )}
            alignText="right"
            containerStyle={styles.cellPrice}
          />
          <View style={styles.cellPriceView}>
            <Text testID="price-delta" style={styles.cellPriceText}>
              {appendCurrency(`${prices.change || '0.00'}`)}
            </Text>
          </View>
          <ButtonIcon
            testID="btn-delete"
            icon="trash-alt"
            type="negativeLight"
            onPress={() => onDeleteRow(productPricing.product.id)}
          />
        </>
      )}
    </View>
  );
};
