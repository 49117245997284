import React, { useState, useEffect } from 'react';
import {
  Text,
  View,
  ViewStyle,
  TextInput,
  StyleSheet,
  TextInputProps,
  TouchableOpacity,
  Dimensions,
  ScrollView,
} from 'react-native';
import theme from '../../../common/default-theme';
import { Icon } from '../../Icon/Icon';
import {
  usePhoneNumber,
  listCountries,
  Country,
} from '@oolio-group/localization';
import { isValidNumber } from '../../../utils/validator';
import Popover, { PopoverPlacement } from 'react-native-popover-view';
import styles from './Inputs.styles';

const { height: deviceHeight } = Dimensions.get('window');

export interface InputPhoneProps extends TextInputProps {
  title?: string;
  testID: string;
  placeholder: string;
  value?: string | undefined;
  defaultCountry?: string;
  onPressCountry: (country: string) => void;
  containerStyle?: ViewStyle | ViewStyle[];
}
interface CountryOption {
  name: string;
  code: string;
  emoji: string;
}

const InputPhone: React.FC<InputPhoneProps> = ({
  title,
  value,
  testID,
  placeholder,
  containerStyle,
  onPressCountry,
  defaultCountry,
  ...props
}: InputPhoneProps) => {
  const { getFullFormattedPhoneNumber } = usePhoneNumber();
  const [showOptions, setShowOptions] = useState<boolean>(false);
  const [selectedCountry, setSelectedCountry] = useState<string>(
    defaultCountry || 'AU',
  );

  useEffect(() => {
    setSelectedCountry(defaultCountry || 'AU');
  }, [defaultCountry]);

  const onChangeCountry = (code: string) => {
    setSelectedCountry(code);
    onPressCountry(code);
    setShowOptions(false);
  };

  const countries: CountryOption[] = listCountries()
    .map((item: Country) => ({
      name: item.name,
      code: item.code,
      emoji: item.emoji,
    }))
    .sort((a, b) => a.name.localeCompare(b.name));

  return (
    <View style={containerStyle}>
      {!!title && <Text style={styles.inputTitleText}>{title}</Text>}
      <View>
        <View
          style={[
            styles.inputWrapper,
            props.editable === true
              ? theme.containers.enabled
              : theme.containers.disabled,
          ]}
        >
          <Popover
            isVisible={showOptions}
            verticalOffset={6}
            popoverStyle={localStyles.options}
            backgroundStyle={localStyles.backdrop}
            onRequestClose={() => setShowOptions(false)}
            placement={PopoverPlacement.LEFT}
            from={
              <TouchableOpacity
                style={localStyles.btnFlag}
                onPress={() => setShowOptions(!showOptions)}
              >
                <Text style={localStyles.flag}>
                  {countries.find(item => item.code === selectedCountry)
                    ?.emoji || '?'}
                </Text>
              </TouchableOpacity>
            }
          >
            <ScrollView style={localStyles.options}>
              {countries.map(item => (
                <TouchableOpacity
                  key={item.name}
                  style={localStyles.option}
                  onPress={() => onChangeCountry(item.code)}
                >
                  <Text>{item.emoji + '  ' + item.name}</Text>
                </TouchableOpacity>
              ))}
            </ScrollView>
          </Popover>
          <TextInput
            value={value}
            testID={testID}
            keyboardType="phone-pad"
            placeholder={placeholder}
            placeholderTextColor={theme.colors.grey5}
            style={[
              styles.inputContainer,
              // eslint-disable-next-line react-native/no-inline-styles
              { paddingLeft: 44 },
            ]}
            autoCapitalize="none"
            autoComplete="tel"
            autoCorrect={false}
            {...props}
          />
        </View>
        <View style={styles.accessory}>
          {value && value.length > 0 ? (
            <Icon
              size={20}
              color={
                isValidNumber(
                  getFullFormattedPhoneNumber(selectedCountry, String(value)),
                )
                  ? theme.colors.states.positive
                  : theme.colors.states.negative
              }
              name={
                isValidNumber(
                  getFullFormattedPhoneNumber(selectedCountry, String(value)),
                )
                  ? 'Check'
                  : 'ExclamationTriangle'
              }
            />
          ) : undefined}
        </View>
      </View>
      {!isValidNumber(
        getFullFormattedPhoneNumber(selectedCountry, String(value)),
      ) &&
        String(value).length > 9 && (
          <Text style={styles.errorText}>Enter valid phone number</Text>
        )}
    </View>
  );
};

export default InputPhone;

InputPhone.defaultProps = {
  editable: true,
  value: '',
};

const localStyles = StyleSheet.create({
  btnFlag: {
    position: 'absolute',
    left: 0,
    bottom: 0,
    width: 42,
    height: 42,
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 2,
  },
  flag: {
    fontSize: 20,
  },
  options: {
    width: 280,
    ...theme.shadow.light,
    borderRadius: theme.radius.m,
    backgroundColor: theme.colors.light,
  },
  option: {
    padding: 12,
    borderBottomWidth: 1,
    borderStyle: 'solid',
    borderBottomColor: theme.colors.grey3,
  },
  backdrop: {
    height: deviceHeight,
    width: '100%',
    position: 'absolute',
  },
});
