import React, { useCallback } from 'react';
import { View, Text, TouchableOpacity, ViewStyle } from 'react-native';
import {
  Customer,
  LoyaltySettings,
  OrderPayment,
  RewardRule,
} from '@oolio-group/domain';
import { useCurrency, useTranslation } from '@oolio-group/localization';
import { useModal } from '@oolio-group/rn-use-modal';
import { getOnAccountPayment } from '@oolio-group/order-helper';
import { analyticsService } from '../../../analytics/AnalyticsService';
import theme from '../../../common/default-theme';
import styles from './AssignCustomer.styles';
import Icon from '../../Icon/Icon';
import SearchCustomer from '../../Modals/Customer/SearchCustomer';
import Loading from '../../LoadingIndicator/LoadingIndicator';
import { useCartContext as useCart } from '../../../hooks/CartProvider';
import { useRewards } from '../../../hooks/orders/useRewards';

export interface AssignCustomerProps {
  name?: string;
  assignedCustomer?: Customer;
  orderId: string;
  credit?: string | number;
  payments: OrderPayment[] | undefined;
  onAssign: (customer: Customer) => void;
  onUnassign: () => void;
  loyaltySettings: Partial<LoyaltySettings>;
  rewardRules: RewardRule[];
  secondary?: boolean;
  containerStyles?: ViewStyle | ViewStyle[];
  loading?: boolean;
}

const AssignCustomer: React.FC<AssignCustomerProps> = ({
  name,
  assignedCustomer,
  credit,
  payments = [],
  onAssign,
  onUnassign,
  loyaltySettings,
  rewardRules,
  secondary = false,
  containerStyles,
  loading,
}) => {
  const { translate } = useTranslation();
  const { formatCurrency } = useCurrency();
  const { showModal, closeModal } = useModal();
  const { order } = useCart();
  const { rewardMap, redeemRewards } = useRewards(rewardRules);

  const onPressCustomer = useCallback(() => {
    analyticsService.capture('assign_customer', {
      location: 'Payment Screen',
    });

    const isOnAccountPayment = getOnAccountPayment(payments);
    if (!isOnAccountPayment) {
      showModal(
        <SearchCustomer
          assignCustomerToOrder={onAssign}
          assignedCustomer={assignedCustomer}
          assignedRewardMap={rewardMap}
          unassignCustomerToOrder={onUnassign}
          rewardRules={rewardRules}
          loyaltySettings={loyaltySettings}
          onRewardRedeem={redeemRewards}
          orderItems={order?.orderItems}
          orderId={order?.id}
        />,
        { onBackdropPress: closeModal },
      );
    }
  }, [
    payments,
    showModal,
    onAssign,
    assignedCustomer,
    rewardMap,
    onUnassign,
    rewardRules,
    loyaltySettings,
    redeemRewards,
    order?.orderItems,
    order?.id,
    closeModal,
  ]);

  const customerId = assignedCustomer?.id;

  return (
    <TouchableOpacity
      testID="btn-assignCustomer"
      onPress={onPressCustomer}
      style={[
        styles.container,
        containerStyles,
        {
          backgroundColor: secondary
            ? theme.colors.teritiary2
            : theme.colors.white,
        },
      ]}
    >
      <View style={styles.icon}>
        <Icon
          size={20}
          name="user-circle"
          color={secondary ? theme.colors.white : theme.colors.dark}
        />
      </View>
      <Text
        testID="customer-name"
        style={[
          styles.text,
          {
            color:
              customerId && secondary
                ? theme.colors.white
                : customerId
                ? theme.colors.dark
                : theme.colors.grey6,
          },
        ]}
      >
        {name || translate('form.assignCustomer')}
      </Text>
      {credit ? (
        <Text testID="customer-points">{formatCurrency(+credit || 0)}</Text>
      ) : null}
      <View style={styles.icon}>
        {loading ? (
          <Loading color={theme.colors.white} />
        ) : (
          <Icon
            size={20}
            name={customerId ? 'times' : 'plus'}
            color={
              customerId
                ? theme.colors.states.negative
                : theme.colors.states.positive
            }
          />
        )}
      </View>
    </TouchableOpacity>
  );
};

export default AssignCustomer;
