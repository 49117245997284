import {
  StackNavigationState,
  PartialState,
  LinkingOptions,
} from '@react-navigation/native';
import { App, FeatureIDs, FeatureSetting } from '@oolio-group/domain';
import { SsoClient } from '@oolio-group/pkce-sso-sdk';
import { auth0Config } from '../constants';

export interface NavMenuProps {
  title: string;
  icon: string;
  children: string[];
  route?: Array<{
    title: string;
    routing: string;
    feature?: FeatureSetting;
    webOnly?: boolean;
  }>;
  feature?: {
    featureId: FeatureIDs;
  };
  beta?: boolean;
  openLink?: string;
}

type ParamsList = Record<string, object | undefined>;

export const navigateToBackOfficeParams:
  | StackNavigationState<ParamsList>
  | PartialState<StackNavigationState<ParamsList>> = {
  index: 0,
  routes: [
    {
      name: 'BackOffice',
      state: {
        routes: [{ name: 'Home', state: { routes: [{ name: 'Overview' }] } }],
      },
    },
  ],
};

export const redirectToSsoProvider = (initialURL: string) => {
  const url = new URL(initialURL);
  const ssoClient = new SsoClient(
    {
      issuer: auth0Config.issuer,
      clientId: auth0Config.clientId,
      scope: auth0Config.scopes.join(' '),
      audience: auth0Config.additionalParameters.audience,
      redirectUrl: location.origin + '/onboarding',
    },
    () => undefined,
  );
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const params: any = {};
  url.searchParams.forEach((value, key) => {
    params[key] = value;
  });
  ssoClient.authenticate(params);
  return undefined;
};

export const navigateToLockScreen = (
  app: App = App.POS_APP,
  notAutoRedirect?: boolean,
):
  | StackNavigationState<ParamsList>
  | PartialState<StackNavigationState<ParamsList>> => ({
  index: 0,
  routes: [
    {
      name: 'POS',
      state: {
        routes: [
          {
            name: 'Lock',
            params: {
              app,
              notAutoRedirect,
            },
          },
        ],
      },
    },
  ],
});

export const navigateToCallback = (
  link: string,
):
  | StackNavigationState<ParamsList>
  | PartialState<StackNavigationState<ParamsList>> => {
  const url = new URL(link);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const params: any = {};
  url.searchParams.forEach((value, key) => {
    params[key] = value;
  });
  return {
    index: 0,
    routes: [
      {
        name: 'callback',
        params: params,
      },
    ],
  };
};

export const navigateToOnboardingScreen = (
  link: string,
):
  | StackNavigationState<ParamsList>
  | PartialState<StackNavigationState<ParamsList>> => {
  const url = new URL(link);
  const state = url.searchParams.get('state') ?? '';
  const code = url.searchParams.get('code') ?? '';
  let params = {};
  try {
    params = JSON.parse(atob(state));
  } catch (error) {}
  return {
    index: 0,
    routes: [
      {
        name: 'onboarding',
        params: {
          code: code,
          state: state,
          ...params,
        },
      },
    ],
  };
};

export const OfficeNavigationMenuTabs: Array<NavMenuProps> = [
  {
    title: 'Home',
    icon: 'estate',
    children: [],
    route: [{ title: 'Home', routing: 'Home' }],
  },
  {
    title: 'Dashboard',
    icon: 'tachometer-fast',
    children: ['Sales Overview'],
  },
  {
    title: 'Products',
    icon: 'tag-alt',
    children: [
      'Manage',
      'Modifiers',
      // 'Courses',
      'Price Lists',
      'Menus',
      // 'Product Tags',
    ],
    route: [
      { title: 'Products', routing: 'Manage' },
      { title: 'Menus', routing: 'Menus' },
      { title: 'Categories', routing: 'Categories' },
      { title: 'Options', routing: 'Options' },
      {
        title: 'Courses',
        routing: 'Courses',
        feature: {
          featureId: FeatureIDs.COURSES,
        },
      },
      {
        title: 'Price Lists',
        routing: 'PriceLists',
        feature: {
          featureId: FeatureIDs.PRICE_LIST,
        },
      },
      // { title: 'Product Tags', routing: 'ProductTags' },
      // { title: 'Side Groups', routing: 'SideGroups' },
    ],
  },
  {
    title: 'Customers',
    icon: 'users-alt',
    children: [
      'Manage',
      // 'Groups'
    ],
    route: [
      { title: 'Manage', routing: 'Manage' },
      // { title: 'Groups', routing: 'Groups' },
    ],
  },
  {
    title: 'Loyalty',
    icon: 'star',
    children: ['Overview', 'Activity Log', 'Customers', 'Settings'],
    route: [{ title: 'Settings', routing: 'Settings' }],
    feature: {
      featureId: FeatureIDs.LOYALTY,
    },
  },
  {
    title: 'Reports',
    icon: 'analytics',
    children: [
      'Sales',
      'Payments',
      'Shift Summaries',
      'Users',
      'Voids & Refunds',
      // 'Inventory',
      // 'Customers', 'Users'
    ],
    route: [
      { title: 'Sales', routing: 'Sales' },
      { title: 'Payments', routing: 'Payments' },
      { title: 'Shift Summaries', routing: 'ShiftSummaries' },
      { title: 'Users', routing: 'Users' },
      { title: 'Voids & Refunds', routing: 'VoidsRefunds' },
      // { title: 'Inventory', routing: 'Inventory' },
      // { title: 'Customers', routing: 'Customers' },
    ],
  },
  {
    title: 'ReportsBeta',
    icon: 'chart',
    children: [],
    route: [],
    beta: true,
    openLink: process.env.REACT_APP_INSIGHTS_URL,
  },
  {
    title: 'Reservations',
    icon: 'calendar-alt',
    children: ['Reservations'],
    route: [{ title: 'Reservations', routing: 'Reservations' }],
  },
  {
    title: 'Users',
    icon: 'user',
    children: ['Manage', 'Job Roles'],
    route: [
      { title: 'Manage', routing: 'ManageUsers' },
      { title: 'Job Roles', routing: 'JobRoles' },
    ],
  },
  {
    title: 'Features',
    icon: 'create-dashboard',
    children: ['Manage'],
    route: [{ title: 'Manage', routing: 'ManageFeatures' }],
  },
  {
    title: 'Settings',
    icon: 'cog',
    children: [
      'Organisation',
      'Venues & Stores',
      'My Store',
      'Taxes',
      'Payment Types',
      'Sales Channels',
      'Printing',
      'Other',
    ],
    route: [
      { title: 'Organisation', routing: 'Organisation' },
      {
        title: 'Venues & Stores',
        routing: 'VenuesStores',
        feature: {
          featureId: FeatureIDs.MULTI_VENUE,
        },
      },
      {
        title: 'My Store',
        routing: 'MyStore',
        feature: {
          featureId: FeatureIDs.MULTI_VENUE,
          showTabIfDisabled: true,
        },
      },
      { title: 'Taxes', routing: 'TaxesFees' },
      { title: 'Payment Types', routing: 'PaymentTypes' },
      { title: 'Sales Channels', routing: 'SalesChannels' },
      { title: 'Printing', routing: 'Printing' },
      { title: 'Other', routing: 'Other' },
    ],
  },
  {
    title: 'Logs',
    icon: 'history',
    children: [],
    route: [],
    openLink: process.env.REACT_APP_ORDERS_URL,
  },
];

export const deepLinkConfig: LinkingOptions['config'] = {
  screens: {
    LoginTypeSelection: 'login-type-selection',
    Onboard: 'onboarding',
    Logout: 'logout',
    Signup: 'signup',
    POS: {
      path: 'pos',
      screens: {
        AssignToDevice: 'assign-to-device',
        Orders: {
          screens: {
            TakeOrder: 'order/:id?',
            OpenOrders: 'orders/open-orders',
            Payment: 'orders/payment',
            SearchCustomer: 'orders/:id/search-customer',
            AddCustomer: 'orders/:id/add-customer',
            PrintJobs: 'orders/print-queue',
            OnlineOrders: 'orders/online-orders',
            FloorView: {
              path: 'orders/floor-view',
              screens: {
                Section: ':name?',
              },
            },
          },
        },
        POSSettings: {
          path: 'settings',
          screens: {
            Account: 'account',
            POSMenuEditor: 'menu-editor',
          },
        },
        Lock: 'unlock',
        ManageMoney: 'manage-money',
        OrderHistory: 'orders/history',
        Customers: {
          screens: {
            Customers: 'customers',
            OnAccountOrders: 'customers/on-account',
          },
        },
        Shift: 'shift',
      },
    },
    BackOffice: {
      path: 'backoffice',
      initialRouteName: 'Home',
      screens: {
        Home: {
          initialRouteName: 'Overview',
          path: 'home',
          screens: {
            Overview: 'overview',
          },
        },
        Dashboard: {
          initialRouteName: 'SalesOverview',
          path: 'dashboard',
          screens: {
            SalesOverview: 'sales-overview',
          },
        },
        Products: {
          initialRouteName: 'Manage',
          path: 'products',
          screens: {
            Menus: 'menus',
            MenuSettings: {
              initialRouteName: 'CreateMenu',
              path: 'menus/:menuId',
              screens: {
                CreateMenu: 'create-menu',
              },
            },
            Pages: 'pages',
            PageSettings: {
              initialRouteName: 'PageDetail',
              path: 'page/:pageId',
              screens: {
                PageDetail: 'page-detail',
              },
            },
            Products: 'manage',
            Modifiers: {
              initialRouteName: 'ModifiersTabs',
              path: 'modifiers',
              screens: {
                ModifiersTabs: {
                  initialRouteName: 'ModifierGroupsTab',
                  path: 'groups',
                  screens: {
                    ModifierGroupsTab: 'modifier-groups-tab',
                    ModifierTab: 'modifier-list',
                  },
                },
                CreateModifierGroupTab: {
                  initialRouteName: 'CreateModifierGroup',
                  path: 'modifierGroup/:modifierGroupId',
                  screens: {
                    CreateModifierGroup: 'modifierGroup',
                  },
                },
              },
            },
            Courses: 'courses',
            // Categories: 'categories',
            PriceLists: 'price-lists',
            ProductTags: 'product-tags',
            SideGroups: 'side-groups',
            PriceListSettings: {
              initialRouteName: 'Details',
              path: 'price/:pricingGroupId',
              screens: {
                Details: 'details',
                Products: 'products',
              },
            },
            ProductSettings: {
              initialRouteName: 'Availability',
              path: 'product/:productId',
              screens: {
                Availability: 'availability',
                General: 'general',
                Options: 'options',
                Pricing: 'pricing',
                Variants: 'variants',
                Modifiers: 'modifiers',
                // Sides: 'sides',
              },
            },
          },
        },
        Customers: {
          initialRouteName: 'ManageCustomers',
          path: 'customers',
          screens: {
            ManageCustomers: {
              initialRouteName: 'ManageCustomersTab',
              path: 'manage',
              screens: {
                ManageCustomersTab: 'manage-customers-tab',
              },
            },
          },
        },
        Loyalty: {
          initialRouteName: 'Overview',
          path: 'Loyalty',
          screens: {
            Overview: 'overview',
            ActivityLog: 'activity-log',
            Customers: 'customers',
            Settings: 'settings',
          },
        },
        Users: {
          initialRouteName: 'ManageUsers',
          path: 'users',
          screens: {
            ManageUsers: {
              initialRouteName: 'ManageUsersTab',
              path: 'manage',
              screens: {
                ManageUsersTab: 'manage-users-tab',
              },
            },
            UserDetails: {
              initialRouteName: 'General',
              path: 'details',
              screens: {
                General: 'general',
                Venues: 'venues',
              },
            },
            JobRoles: 'job-roles',
            JobRoleSettings: {
              initialRouteName: 'CreateOrEditRole',
              path: 'job-roles/:roleId',
              screens: {
                CreateOrEditRole: 'details',
              },
            },
          },
        },
        Features: {
          initialRouteName: 'ManageFeatures',
          path: 'features',
          screens: {
            ManageFeatures: {
              initialRouteName: 'FeaturesTab',
              path: 'manage',
              screens: {
                FeaturesTab: {
                  initialRouteName: 'FeaturesOverview',
                  path: 'features-tab',
                  screens: {
                    ManageFeature: 'manage-feature',
                    FeaturesOverview: 'overview',
                  },
                },
                IntegrationsTab: 'integrations-tab',
              },
            },
            FeaturesSettings: {
              initialRouteName: 'AboutTab',
              path: 'about-feature/:featureId',
              screens: {
                AboutTab: 'about-feature',
              },
            },
            DoshiiSettings: {
              initialRouteName: 'AboutTab',
              path: 'doshii',
              screens: {
                AboutTab: 'doshii-about',
                SettingsTab: 'doshii-settings',
              },
            },
            DeliveritSettings: {
              initialRouteName: 'AboutTab',
              path: 'deliverit',
              screens: {
                AboutTab: 'deliverit-about',
                SettingsTab: 'deliverit-settings',
              },
            },
            XeroSettings: {
              initialRouteName: 'AboutTab',
              path: 'xero',
              screens: {
                AboutTab: 'xero-about',
                SettingsTab: 'xero-settings',
              },
            },
            DeputySettings: {
              initialRouteName: 'AboutTab',
              path: 'deputy',
              screens: {
                AboutTab: 'deputy-about',
                SettingsTab: 'settings',
                DeputyStoreTab: {
                  initialRouteName: 'DeputyStoreTab',
                  path: 'about-feature/:storeName',
                  screens: {
                    DeputyStoreTab: 'about-feature',
                  },
                },
              },
            },
            OolioStoreSettings: {
              initialRouteName: 'AboutTab',
              path: 'oolio-online-store',
              screens: {
                AboutTab: 'about',
                SettingsTab: 'settings',
              },
            },
            OnlineOrderIntegrationsSettings: {
              initialRouteName: 'AboutTab',
              path: 'online-order-integration/:app',
              screens: {
                AboutTab: 'about',
                SettingsTab: 'settings',
              },
            },
          },
        },
        Reports: {
          initialRouteName: 'SalesFeed',
          path: 'reports',
          screens: {
            Sales: {
              path: 'sales',
              screens: {
                SalesFeed: 'sales-feed',
                SalesSummary: 'sales-summary',
                Devices: 'devices',
                Products: 'products',
                HourlySales: 'hourly-sales',
                Staff: 'staff',
                Taxes: 'taxes',
                Customers: 'customers',
                ProductsSummary: 'products-summary',
                Dashboard: 'dashboard',
                Adjustments: 'adjustments',
              },
            },
            Payments: {
              path: 'payments-summary',
              screens: {
                Payments: 'payments',
              },
            },
            ShiftSummaries: {
              path: 'shift-summaries',
              screens: {
                Shifts: 'shifts',
              },
            },
            Users: {
              path: 'users-summary',
              screens: {
                Attendance: 'attendance',
              },
            },
            VoidsRefunds: {
              path: 'voids-refunds',
              screens: {
                Voids: 'voids',
              },
            },
          },
        },
        Reservations: {
          initialRouteName: 'Reservations',
          path: 'reservations',
          screens: {
            Reservations: {
              path: 'reservations',
              screens: {
                Reservations: 'reservations',
              },
            },
          },
        },
        Settings: {
          initialRouteName: 'General',
          path: 'settings',
          screens: {
            General: 'general',
            Organisation: 'organisation',
            VenuesStores: 'venues-and-stores',
            Printing: {
              initialRouteName: 'PrinterProfile',
              path: 'printing',
              screens: {
                PrinterProfile: 'printer-profile',
                ReceiptTemplate: 'receipt-template',
              },
            },
            Other: {
              initialRouteName: 'RefundReasons',
              path: 'other',
              screens: {
                RefundReasons: 'refund-reasons',
                ProductTypes: 'product-types',
                ReportSettings: 'report-settings',
              },
            },
            TaxesFees: {
              initialRouteName: 'Taxes',
              path: 'taxes-fees',
              screens: {
                Taxes: 'taxes',
                Fees: 'fees',
              },
            },
            PaymentTypes: 'payment-types',
            Discount: {
              path: 'discount',
              screens: {
                CreateOrEditAdjustment: '',
              },
            },
            Surcharge: {
              path: 'surcharge',
              screens: {
                CreateOrEditAdjustment: '',
              },
            },
            Adjustment: {
              screens: {
                CreateOrEditAdjustment: 'adjustment',
              },
            },
            VenueSettings: {
              initialRouteName: 'Details',
              path: 'venue/:venueId',
              screens: {
                Details: 'details',
                Adjustments: 'adjustments',
                Discounts: 'discounts',
                Surcharges: 'surcharges',
                CashManagement: 'manage-cash',
                FunctionMaps: 'function-maps',
                SectionManagement: 'manage-section',
                AccountSales: 'account-sales',
              },
            },
            StoreSettings: {
              initialRouteName: 'Details',
              path: 'venue/:venueId/store/:storeId',
              screens: {
                Details: 'details',
                Checkout: 'checkout',
                Devices: 'devices',
                DeviceProfiles: 'device-profiles',
                Tables: 'tables',
                Printers: 'printers',
                ProfitMargins: 'profit-margins',
                DevicePrinterSetup: 'printing-setup',
              },
            },
            DeviceProfileSettings: {
              initialRouteName: 'Details',
              path: 'store/:storeId/device-profile/:deviceProfileId/venue/:venueId?',
              screens: {
                Details: 'details',
                Options: 'options',
                Workflow: 'workflow',
              },
            },
            DeviceSettings: {
              initialRouteName: 'Details',
              path: 'store/:storeId/device/:deviceId',
              screens: {
                Details: 'details',
                PrintingSetup: 'printing-setup',
              },
            },
            SectionSettings: {
              initialRouteName: 'Details',
              path: 'venue/:venueId/section/:sectionId',
              screens: {
                Details: 'details',
              },
            },
            SalesChannels: 'sales-channels',
            PrinterDetails: {
              initialRouteName: 'Details',
              path: 'store/:storeId/printer/:printerId',
              screens: {
                Details: 'details',
              },
            },
            KioskDetailsSettings: {
              initialRouteName: 'Details',
              path: 'store/:storeId/kiosk/:kioskId/venue/:venueId?',
              screens: {
                Details: 'details',
              },
            },
          },
        },
      },
    },
  },
};
