import React from 'react';
import { Text, ScrollView, TouchableOpacity, Platform } from 'react-native';
import { MaterialTopTabBarProps } from '@react-navigation/material-top-tabs';
import styles from '../../FloorView.styles';
import theme from '../../../../../../common/default-theme';

export interface TabBarProps {
  tabBar: MaterialTopTabBarProps;
}

const SectionTabs: React.FC<TabBarProps> = ({ tabBar }) => {
  const { width: vw } = theme.useResponsiveDimensions();
  const { state, descriptors, navigation } = tabBar;

  const handlePress = (isFocused: boolean, routeName: string): void => {
    if (!isFocused) navigation.navigate(routeName);
  };

  return (
    <ScrollView horizontal style={[styles.sections, { maxWidth: vw - 40 }]}>
      {state.routes.map((route, index) => {
        const isFocused = state.index === index;
        const { options } = descriptors[route.key] as {
          options: { tabBarLabel?: string };
        };
        const label: string = (options.tabBarLabel as string) || route.name;

        const backgroundColor = isFocused
          ? theme.colors.primary
          : theme.colors.white;
        const textColor = isFocused ? theme.colors.white : theme.colors.dark;
        const additionalStyle =
          index === state.routes.length - 1 && Platform.OS === 'ios'
            ? { marginRight: 20 }
            : undefined;

        return (
          <TouchableOpacity
            key={index}
            testID="btn-section"
            style={[styles.btnSection, { backgroundColor }, additionalStyle]}
            onPress={(): void => handlePress(isFocused, route.name)}
          >
            <Text style={[styles.actionText, { color: textColor }]}>
              {label}
            </Text>
          </TouchableOpacity>
        );
      })}
    </ScrollView>
  );
};

export default SectionTabs;
