import { StyleSheet } from 'react-native';
import theme from '../../../../common/default-theme';

const styles = StyleSheet.create({
  filtersContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  searchContainer: {
    flex: 1,
    marginRight: 10,
  },
  tableContainer: {
    width: '100%',
    marginTop: 20,
  },
  rowText: {
    paddingHorizontal: 12,
    color: theme.colors.black1,
  },
  cellName: {
    width: 200,
  },
  cellEmail: {
    flex: 1,
  },
  cellPhone: {
    width: 144,
    textAlign: 'right',
  },
  cellPoints: {
    width: 74,
    textAlign: 'right',
  },
  cellDisclosure: {
    width: 34,
  },
  loadingContainer: {
    marginTop: 80,
  },
});

export default styles;
