/* eslint-disable react-native/no-inline-styles */
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { View } from 'react-native';
import { useNavigation, useRoute } from '@react-navigation/native';
import {
  useTranslation,
  getFullFormattedPhoneNumber,
} from '@oolio-group/localization';
import {
  Customer,
  FeatureIDs,
  UpdateCustomerRequest,
} from '@oolio-group/domain';
import { DEFAULT_COUNTRY_CODE } from '../../../../../../constants';
import { Operation } from '../../../../../../types/Operation';
import { useNotification } from '../../../../../../hooks/Notification';
import { useCustomers } from '../../../../../../hooks/orders/useCustomers';
import ConfirmationModal from '../../../../../../components/Modals/ConfirmationDialog';
import { useModal } from '@oolio-group/rn-use-modal';
import { getCurrencySymbol } from '@oolio-group/localization';
import { useSession } from '../../../../../../hooks/app/useSession';
import { countries } from 'countries-list';
import theme from '../../../../../../common/default-theme';
import ScreenLayout from '../../../../../../components/Office/ScreenLayout/ScreenLayout';
import Section from '../../../../../../components/Office/Section/Section';
import InputText from '../../../../../../components/Shared/Inputs/InputText';
import InputPhone from '../../../../../../components/Shared/Inputs/InputPhone';
import InputEmail from '../../../../../../components/Shared/Inputs/InputEmail';
import TreatPicker from '../../../../../../components/Shared/Select/Picker';
import InputToggle from '../../../../../../components/Shared/Inputs/InputToggle';
import { isValidPostCode } from '../../../../../../utils/validator';
import { useCheckFeatureEnabled } from '../../../../../../hooks/app/features/useCheckFeatureEnabled';

interface MenuRowProps {
  onChange: (id: string, prop: string, value: string | boolean) => void;
  onPressCopy: (id: string) => void;
  customerInfo?: Customer;
  action: string;
  checkEmailExists: (email?: string, customerId?: string) => boolean;
}
interface ICustomerForm {
  id?: string;
  firstName: string;
  lastName: string;
  line1: string;
  line2?: string;
  email: string;
  city: string;
  suburb?: string;
  postalCode: string;
  country: string;
  phone: string;
  maxOrderLimit: number;
  maxBalanceLimit: number;
  currentBalance: number;
  accountPayment: boolean;
  state: string;
}

export const CustomerDetails: React.FC<MenuRowProps> = ({}: MenuRowProps) => {
  const [paymentEnabled, setPaymentEnable] = useState(false);
  const [accountPaymentEnabled, setAccountPayment] = useState(false);
  const { showNotification } = useNotification();
  const navigation = useNavigation();
  const { translate } = useTranslation();
  const route = useRoute();
  const { showModal, closeModal } = useModal();
  const [session] = useSession();
  const { country = DEFAULT_COUNTRY_CODE } = session?.currentOrganization || {};

  const routeParams = route.params as {
    customer: Customer;
  };
  const { customer: customerProp } = routeParams;
  const [form, setForm] = useState<ICustomerForm>({
    id: '',
    firstName: '',
    lastName: '',
    line1: '',
    line2: '',
    email: '',
    city: '',
    suburb: '',
    postalCode: '',
    country,
    phone: '',
    maxOrderLimit: 0,
    maxBalanceLimit: 0,
    currentBalance: 0,
    accountPayment: false,
    state: '',
  });
  const isFeatureEnabled = useCheckFeatureEnabled();
  const isCustomerAccountEnabled = isFeatureEnabled(
    FeatureIDs.CUSTOMER_ACCOUNT,
  );

  const action = useRef('');
  const customerInfo = customerProp;
  const {
    updateCustomer,
    loading,
    error,
    deleteCustomer,
    getCustomerById,
    customerMaps,
  } = useCustomers();

  const isUK = form.country === 'GB';

  const customer = customerMaps[customerProp.id];
  const removeCustomer = useCallback(
    async (customerId: string) => {
      action.current = Operation.DELETE;
      await deleteCustomer(customerId);
      navigation.navigate('ManageCustomers');
    },
    [deleteCustomer, navigation],
  );

  useEffect((): void => {
    getCustomerById(customerProp.id, true);
  }, [customerProp.id, getCustomerById]);

  const currencyCode = countries[country]?.currency;

  useEffect((): void => {
    if (customerProp?.customerAccountDetails?.accountPayment === true) {
      setPaymentEnable(true);
      setAccountPayment(true);
    } else {
      setPaymentEnable(false);
      setAccountPayment(false);
    }
  }, [customerProp?.customerAccountDetails?.accountPayment]);

  const togglePayment = useCallback((): void => {
    setPaymentEnable(prevCheck => !prevCheck);
    setAccountPayment(prevCheck => !prevCheck);
  }, []);

  useEffect(() => {
    if (customer) {
      const { id, firstName, lastName, email, preferredAddress, phoneNumber } =
        customer;
      setForm({
        id,
        firstName,
        lastName,
        line1: (preferredAddress && preferredAddress.line1) || '',
        line2: (preferredAddress && preferredAddress.line2) || '',
        city: (preferredAddress && preferredAddress.city) || '',
        suburb: (preferredAddress && preferredAddress.suburb) || '',
        state: (preferredAddress && preferredAddress.state) || '',
        postalCode: (preferredAddress && preferredAddress.postalCode) || '',
        country: preferredAddress?.isoCountryCode || country,
        phone: phoneNumber || '',
        email,
        maxOrderLimit: customer?.customerAccountDetails
          ?.maxOrderLimit as number,
        maxBalanceLimit: customer?.customerAccountDetails
          ?.maxBalanceLimit as number,
        currentBalance: customer?.customerAccountDetails
          ?.currentBalance as number,
        accountPayment: customer?.customerAccountDetails
          ?.accountPayment as boolean,
      });
    }
  }, [customer, country]);

  useEffect(() => {
    if (customerInfo && customerInfo.preferredAddress) {
      const { isoCountryCode, ...address } = customerInfo.preferredAddress;
      setForm({
        id: customerInfo.id,
        firstName: customerInfo.firstName,
        lastName: customerInfo.lastName,
        line1: address.line1,
        line2: address.line2,
        city: address.city,
        suburb: address.suburb,
        state: address.state,
        postalCode: address.postalCode,
        country: isoCountryCode ? isoCountryCode : DEFAULT_COUNTRY_CODE,
        phone: customerInfo.phone,
        email: customerInfo.email,
        maxOrderLimit: customerInfo?.customerAccountDetails
          ?.maxOrderLimit as number,
        maxBalanceLimit: customerInfo?.customerAccountDetails
          ?.maxBalanceLimit as number,
        currentBalance: customerInfo?.customerAccountDetails
          ?.currentBalance as number,
        accountPayment: customerInfo?.customerAccountDetails
          ?.accountPayment as boolean,
      });
    }
  }, [action, customerInfo]);

  useEffect(() => {
    if (error) {
      showNotification({
        error: true,
        message: error,
      });
    }
  }, [showNotification, error]);

  const onChangeFormInput = useCallback((prop: string, value: string) => {
    setForm(form => ({
      ...form,
      [prop]: value,
    }));
  }, []);

  const onDelete = useCallback(
    (customerId: string, customerName: string): void => {
      const customer = customerMaps[customerId];
      if (
        customer.customerAccountDetails?.accountPayment &&
        customer.customerAccountDetails?.currentBalance
      ) {
        showNotification({
          error: true,
          message: translate('backOfficeCustomers.customerDeleteWarning'),
        });
        return;
      }
      showModal(
        <ConfirmationModal
          title={translate('backOfficeCustomers.deletePopUpPromptHeader')}
          message={translate('backOfficeCustomers.deletePopUpPromptBody', {
            customerName,
          })}
          onConfirm={() => {
            closeModal();
            removeCustomer(customerId);
          }}
        />,
      );
    },
    [
      showModal,
      translate,
      closeModal,
      removeCustomer,
      customerMaps,
      showNotification,
    ],
  );

  const onSaveCustomer = useCallback(async () => {
    const { firstName, lastName, email, phone, country } = form;
    const preferredAddress = {
      line1: form.line1,
      city: form.city,
      suburb: form.suburb,
      postalCode: form.postalCode,
      isoCountryCode: form.country,
    };
    const customerAccountDetails = {
      maxOrderLimit: Number(form.maxOrderLimit),
      maxBalanceLimit: Number(form.maxBalanceLimit),
      currentBalance: Number(form.currentBalance),
      accountPayment: paymentEnabled,
    };
    const customerInput = {
      id: form.id,
      firstName,
      lastName,
      email,
      phone: getFullFormattedPhoneNumber(country, phone),
      phoneNumber: phone,
      preferredAddress,
      customerAccountDetails,
    } as UpdateCustomerRequest;
    await updateCustomer(customerInput);
  }, [form, paymentEnabled, updateCustomer]);

  return (
    <ScreenLayout
      loading={loading}
      title="Customers | Oolio"
      onSave={onSaveCustomer}
      onDelete={() => onDelete(customer?.id || '', customer?.name || '')}
    >
      <Section title={translate('form.customerDetails')} layoutWidth="small">
        <View style={theme.forms.row}>
          <InputText
            testID="input-firstName"
            title={translate('form.firstName')}
            value={form?.firstName}
            placeholder={translate('form.firstName')}
            onChangeText={text => {
              onChangeFormInput('firstName', text);
            }}
            containerStyle={theme.forms.inputHalf}
          />
          <InputText
            testID="input-lastName"
            title={translate('form.lastName')}
            value={form?.lastName}
            placeholder={translate('form.lastName')}
            onChangeText={text => {
              onChangeFormInput('lastName', text);
            }}
            containerStyle={theme.forms.inputHalf}
          />
        </View>
        <View style={theme.forms.row}>
          <InputPhone
            testID="input-phone"
            title={translate('form.phone')}
            value={form?.phone}
            defaultCountry={form?.country}
            placeholder={translate(
              isUK ? 'form.phoneNumberHintUK' : 'form.phoneNumberHint',
            )}
            onChangeText={onChangeFormInput.bind(null, 'phone')}
            onPressCountry={onChangeFormInput.bind(null, 'country')}
            containerStyle={theme.forms.inputHalf}
          />
          <InputEmail
            testID="input-email"
            title={translate('form.email')}
            value={form?.email}
            placeholder={translate('form.email')}
            onChangeText={onChangeFormInput.bind(null, 'email')}
            containerStyle={theme.forms.inputHalf}
          />
        </View>
        <View style={theme.forms.row}>
          <InputText
            testID="input-line1"
            title={translate(isUK ? 'form.streetAddress' : 'form.addressLine1')}
            value={form?.line1}
            placeholder={translate(
              isUK ? 'form.streetAddress' : 'form.addressLine1',
            )}
            onChangeText={onChangeFormInput.bind(null, 'line1')}
            containerStyle={
              !isUK ? theme.forms.inputHalf : theme.forms.inputFluid
            }
          />
          {!isUK ? (
            <InputText
              testID="input-line2"
              title="Address Line 2"
              value={form?.line2}
              placeholder="Optional"
              onChangeText={onChangeFormInput.bind(null, 'line2')}
              containerStyle={theme.forms.inputHalf}
            />
          ) : null}
        </View>
        <View style={theme.forms.row}>
          <InputText
            testID="input-city"
            title={translate(isUK ? 'form.town' : 'form.city')}
            value={form.city}
            placeholder={translate(isUK ? 'form.town' : 'form.city')}
            onChangeText={onChangeFormInput.bind(null, 'city')}
            containerStyle={theme.forms.inputHalf}
          />
          <InputText
            testID="input-state"
            title={translate(isUK ? 'form.county' : 'form.state')}
            value={form.state}
            placeholder={translate(isUK ? 'form.county' : 'form.state')}
            onChangeText={onChangeFormInput.bind(null, 'state')}
            containerStyle={theme.forms.inputHalf}
          />
        </View>
        <View style={theme.forms.row}>
          <InputText
            testID="input-postCode"
            title={translate('form.postCode')}
            value={form.postalCode}
            placeholder={translate(
              isUK ? 'form.postalCodeHintUK' : 'form.postalCodeHint',
            )}
            onChangeText={onChangeFormInput.bind(null, 'postalCode')}
            containerStyle={theme.forms.inputHalf}
            errorMessage={
              (!!form?.postalCode &&
                isValidPostCode(form?.postalCode, form?.country)) ||
              !form?.postalCode
                ? undefined
                : 'Error'
            }
          />
          <TreatPicker
            testID="select-country"
            title={translate('form.country')}
            selectedValue={form.country}
            options="countries"
            onValueChange={onChangeFormInput.bind(null, 'country')}
            containerStyle={theme.forms.inputHalf}
          />
        </View>
      </Section>
      {isCustomerAccountEnabled && (
        <Section layoutWidth="small" title={translate('form.accountDetails')}>
          <View style={theme.forms.row}>
            <InputToggle
              type="switch"
              testID="toggle-payment"
              onToggle={togglePayment}
              isToggled={paymentEnabled}
              title={translate('form.enableAccountPayment')}
              containerStyle={theme.forms.inputFluid}
            />
          </View>
          <View style={theme.forms.row}>
            <InputText
              editable={false}
              testID="input-currentBalance"
              title={translate('form.totalOutstanding')}
              value={
                form?.currentBalance ? form?.currentBalance.toString() : '0.00'
              }
              placeholder={translate('form.totalOutstanding')}
              onChangeText={onChangeFormInput.bind(null, 'currentBalance')}
              label={getCurrencySymbol(currencyCode)}
              containerStyle={theme.forms.inputHalf}
            />
            <InputText
              testID="input-state"
              title={translate('form.maxOrderLimit')}
              value={
                form.maxOrderLimit ? form.maxOrderLimit.toString() : undefined
              }
              placeholder={translate('form.maxOrderLimit')}
              onChangeText={onChangeFormInput.bind(null, 'maxOrderLimit')}
              label={getCurrencySymbol(currencyCode)}
              containerStyle={theme.forms.inputHalf}
            />
          </View>
          <View style={theme.forms.row}>
            {accountPaymentEnabled && (
              <InputText
                testID="input-maxBalanceLimit"
                title={translate('form.maxCreditLimit')}
                value={
                  form?.maxBalanceLimit
                    ? form?.maxBalanceLimit.toString()
                    : undefined
                }
                placeholder={translate('form.maxCreditLimit')}
                onChangeText={onChangeFormInput.bind(null, 'maxBalanceLimit')}
                label={getCurrencySymbol(currencyCode)}
                containerStyle={theme.forms.inputHalf}
              />
            )}
          </View>
        </Section>
      )}
    </ScreenLayout>
  );
};
