import { StyleSheet } from 'react-native';
import theme from '../../../common/default-theme';

const styles = StyleSheet.create({
  screenContainer: {
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
  },
  modalContainer: {
    padding: 40,
    borderRadius: 6,
    alignItems: 'center',
    backgroundColor: theme.colors.white,
  },
  logo: {
    marginTop: 20,
    marginBottom: 40,
    alignSelf: 'center',
  },
  formContainer: {
    width: 580,
    flexWrap: 'wrap',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  formInput: {
    width: 280,
    marginBottom: 20,
  },
  formInputAddress: {
    width: 580,
    marginBottom: 20,
    zIndex: 999,
  },
  actionsContainer: {
    width: 580,
    marginTop: 20,
    flexDirection: 'row',
    justifyContent: 'space-between',
    zIndex: -1,
  },
  formButton: {
    width: 280,
  },
  insightsContainer: {
    width: 480,
    marginBottom: 20,
  },
  insightsTitle: {
    lineHeight: 20,
    textAlign: 'center',
  },
  insightsText: {
    textAlign: 'center',
    lineHeight: 20,
    marginTop: 40,
    marginBottom: 20,
  },
  passwordTooltip: {
    top: 72,
    padding: 12,
    fontSize: 12,
    position: 'absolute',
    paddingHorizontal: 12,
    ...theme.shadow.light,
    borderRadius: theme.radius.s,
    backgroundColor: theme.colors.white,
    zIndex: 999,
  },
});

export default styles;
