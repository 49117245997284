import {
  Adjustment,
  Order,
  OrderItem,
  OrderTax,
  AdjustmentType,
  AdjustmentUnit,
  RewardAdjustment,
  OrderPayment,
  OrderItemStatus,
} from '@oolio-group/domain';
import { table, getBorderCharacters, TableUserConfig } from 'table';
import {
  computeOrderItemTotal,
  getAdjustmentValue,
  getItemAdjustmentLabel,
  REFUND_ORDER_ITEM,
  getItemRewardValue,
  getPaymentSurchargeValueAndPaidAmount,
  getNetSubTotal,
} from '@oolio-group/order-helper';
import _ from 'lodash';
import { formatMoneyValue } from '@oolio-group/localization';

const adjustmentRowHelper = (
  totalAdjustmentAmount: number,
  adjustmentType: AdjustmentType,
  currency: string,
  order: Order,
) => {
  const adjustments = order.adjustments || [];
  const rows: FixedTuple[] = [];
  const name =
    adjustmentType === AdjustmentType.SURCHARGE ? 'Surcharge' : 'Discount';
  const filteredAdjustments = adjustments.filter(
    adjustment =>
      adjustment.adjustmentType === adjustmentType &&
      !adjustment.allowOnPaymentType,
  );
  const flatAdjustments = filteredAdjustments.filter(
    adjustment => adjustment.adjustmentUnit === AdjustmentUnit.FLAT,
  );
  flatAdjustments.forEach(adjustment => {
    rows.push([
      '',
      adjustment.displayNameOnReceipt || adjustment.name || name,
      `${formatMoneyValue(adjustment.amount, currency)}`,
    ]);
  });
  /*
  netSubTotal has the value after deducting the adjustments from the subTotal,
  This is be used for the Service charge/Tax
  */
  const netSubTotal = getNetSubTotal(order.subTotal, adjustments);
  const percentageAdjustments = filteredAdjustments.filter(adjustment => {
    if (adjustment.adjustmentUnit === AdjustmentUnit.PERCENTAGE) {
      return true;
    }
    return false;
  });
  percentageAdjustments.forEach(adjustment => {
    const subTotal = adjustment.doNotIncludeInSalesAmount
      ? netSubTotal
      : order.subTotal;
    const adjVal = +((adjustment.amount / 100) * subTotal).toFixed(2);
    const text = adjustmentsFormatted(
      [adjustment],
      currency,
      adjustment.adjustmentType || AdjustmentType.SURCHARGE,
    );
    rows.push([
      '',
      text.trim() !== ''
        ? ` ${
            adjustment.displayNameOnReceipt || adjustment.name || name
          }(${text})`
        : adjustment.name || name,
      `${formatMoneyValue(adjVal, currency)}`,
    ]);
  });
  return rows;
};
/**
 * Order items section has three columns and `n` row(s)
 */
export const config: TableUserConfig = {
  columns: {
    0: {
      width: 5,
    },
    1: {
      width: 29,
    },
    2: {
      alignment: 'right',
    },
  },
  border: getBorderCharacters('void'),
  columnDefault: {
    // TODO get these from api / our custom hook
    paddingLeft: 0,
    paddingRight: 1,
  },
  drawHorizontalLine: () => {
    return false;
  },
};
export const printModifiers = (
  acc: FixedTuple[],
  item: OrderItem,
  currency: string,
) => {
  if (item.modifiers && item.modifiers?.length > 0) {
    item.modifiers.forEach(modifier => {
      let priceExists = '';
      const printQuantity = item.quantity * modifier.quantity;
      if (modifier?.unitPrice) {
        const printPrice = item.quantity * modifier.unitPrice;
        priceExists = formatMoneyValue(
          +printPrice * +modifier.quantity,
          currency,
        );
      }
      if (printQuantity == 1) {
        acc.push(['', ` ${modifier.name}`, priceExists]);
      } else {
        acc.push([` ${printQuantity}`, `  ${modifier.name}`, priceExists]);
      }
    });
  }
};

export const adjustmentsFormatted = (
  adjustments: Adjustment[],
  currency: string,
  type: AdjustmentType,
) => {
  adjustments = adjustments?.filter(
    adjustment =>
      adjustment.adjustmentType == type &&
      adjustment?.allowOnPaymentType == false,
  );

  if (
    Array.isArray(adjustments) &&
    adjustments.length === 1 &&
    adjustments[0].adjustmentUnit === AdjustmentUnit.FLAT
  ) {
    return '';
  } else {
    return (adjustments || [])
      .map(x =>
        x.adjustmentUnit === AdjustmentUnit.PERCENTAGE
          ? `${x.amount}%`
          : `${formatMoneyValue(x.amount, currency)}`,
      )
      .join(', ');
  }
};

export const paymentTypeSurchargeName = (adjustments: Adjustment[]) => {
  adjustments = adjustments?.filter(
    adjustment => adjustment?.allowOnPaymentType == true,
  );
  return (adjustments || []).map(x => x.name).join(', ');
};

export const paymentTypeSurchargeAmount = (payments: OrderPayment[]) => {
  const { paymentSurcharge } = getPaymentSurchargeValueAndPaidAmount(payments);

  return paymentSurcharge;
};

export const printItemDiscount = (
  acc: FixedTuple[],
  item: OrderItem,
  currency: string,
) => {
  if (item.discountAmount && !isRewardDiscount(item)) {
    acc.push([
      '',
      getItemAdjustmentLabel(item.adjustments, currency, true),
      `-${formatMoneyValue(item.discountAmount, currency)}`,
    ]);
  }
};

export const printItemSurcharge = (
  acc: FixedTuple[],
  item: OrderItem,
  currency: string,
) => {
  if (item.surchargeAmount) {
    acc.push([
      '',
      getItemAdjustmentLabel(item.adjustments, currency, true),
      `${formatMoneyValue(item.surchargeAmount, currency)}`,
    ]);
  }
};

export const printItemNotes = (acc: FixedTuple[], item: OrderItem) => {
  if (item.notes) {
    acc.push(['', `**${item.notes}`, '\n']);
  }
};

export const printItemReward = (
  acc: FixedTuple[],
  item: OrderItem,
  currency: string,
) => {
  const reward = item.adjustments?.find(
    adj => adj.adjustmentType === AdjustmentType.REWARD,
  );
  if (reward) {
    acc.push(getRewardPrintItem(reward, currency, item, undefined));
  }
};

const isRewardDiscount = (item: OrderItem) => {
  return (
    item.adjustments?.some(
      adj => adj.adjustmentType === AdjustmentType.REWARD,
    ) || false
  );
};

const getRewardPrintItem = (
  reward: Adjustment,
  currency: string,
  orderItem?: OrderItem,
  total?: number,
): FixedTuple => {
  const quantity = reward.itemQuantity || reward.quantity;
  const displayQuantity = Number(quantity) > 1 ? `${quantity}` : '';
  const rewardName = `Reward: ${reward.name}`;
  const discountAmount =
    typeof total === 'number'
      ? getAdjustmentValue(total, [reward])
      : !!orderItem
      ? getItemRewardValue(orderItem, [reward])
      : 0;
  return [
    `${displayQuantity}`,
    rewardName,
    `${formatMoneyValue(discountAmount, currency)}`,
  ];
};

export interface ExtraValueTransformationOptions {
  currency: string;
  order: Order;
}

export type FixedTuple = [string, string, string];

/**
 * Row definitions
 */
const orderItemRows: Array<{
  description?: string;
  path: string; // object path
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  valueTransformationFn: (...args: any) => FixedTuple[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  canShow?: (...args: any) => boolean; // any transformation required on result
}> = [
  {
    description: 'Order Items qty, name, and price',
    path: 'orderItems',
    valueTransformationFn: (
      orderItems: OrderItem[],
      { currency }: ExtraValueTransformationOptions,
    ) => {
      return orderItems.reduce((acc, item) => {
        const variant = item.variant;
        const product = item.product;
        const variantOptions = product?.optionValues || [];
        const productRow: FixedTuple = [
          item.quantity + '',
          `${
            variant?.name ||
            product?.name ||
            (item.status === OrderItemStatus.REFUNDED
              ? REFUND_ORDER_ITEM.name
              : '')
          }${variantOptions.map((v: { value: string }) => `\n${v.value}`)}`,
          formatMoneyValue(computeOrderItemTotal(item), currency),
        ];
        acc.push(productRow);
        printModifiers(acc, item, currency);
        printItemDiscount(acc, item, currency);
        printItemSurcharge(acc, item, currency);
        printItemReward(acc, item, currency);
        printItemNotes(acc, item);
        return acc;
      }, [] as FixedTuple[]);
    },
    canShow: (orderItems: OrderItem[]) => orderItems?.length > 0,
  },
  {
    path: 'adjustments',
    valueTransformationFn: (
      adjustments: Adjustment[],
      { currency, order }: ExtraValueTransformationOptions,
    ) => {
      // showing reward item on cart
      const rows: FixedTuple[] = (
        adjustments.filter(
          adj => adj.adjustmentType === AdjustmentType.REWARD,
        ) as RewardAdjustment[]
      ).map(reward =>
        getRewardPrintItem(reward, currency, undefined, order.subTotal),
      );

      return rows;
    },
    canShow: (adjustments: Adjustment[]) => {
      return adjustments?.some(
        adjustment => adjustment.adjustmentType == AdjustmentType.REWARD,
      );
    },
  },
  {
    description: 'Order Notes',
    path: 'orderNote',
    valueTransformationFn: (value = '') => {
      const rows: FixedTuple[] = [
        ['', '', ''],
        ['', `**${value}`, ''],
        ['', '', ''],
      ];
      return rows;
    },
    canShow: (value: string) => value?.length > 0,
  },
  {
    path: 'subTotal',
    valueTransformationFn: (
      value = 0,
      { currency }: ExtraValueTransformationOptions,
    ) => {
      const rows: FixedTuple[] = [
        ['', '', ''],
        ['', 'Sub Total', formatMoneyValue(value, currency)],
      ];
      return rows;
    },
    canShow: () => true,
  },
  {
    path: 'adjustments',
    valueTransformationFn: (
      _: Adjustment[],
      { currency, order }: ExtraValueTransformationOptions,
    ) => {
      //should not perform recalculation here, instead should use stored surcharge value

      const paymentSurcharge = paymentTypeSurchargeAmount(order.payments ?? []);

      const nonPaymentSurchargeAmount =
        (order?.surchargeAmount || 0) - paymentSurcharge;

      const rows: FixedTuple[] = adjustmentRowHelper(
        nonPaymentSurchargeAmount,
        AdjustmentType.SURCHARGE,
        currency,
        order,
      );

      return rows;
    },
    canShow: (adjustments: Adjustment[]) => {
      return adjustments?.some(
        adjustment =>
          (adjustment.adjustmentType == AdjustmentType.SURCHARGE &&
            adjustment?.allowOnPaymentType == false) ||
          null,
      );
    },
  },
  {
    path: 'adjustments',
    valueTransformationFn: (
      _: Adjustment[],
      { currency, order }: ExtraValueTransformationOptions,
    ) => {
      const discountAmount = order?.discountAmount || 0;

      const rows: FixedTuple[] = adjustmentRowHelper(
        discountAmount,
        AdjustmentType.DISCOUNT,
        currency,
        order,
      );

      return rows;
    },
    canShow: (adjustments: Adjustment[]) => {
      return adjustments?.some(
        adjustment =>
          adjustment.adjustmentType == AdjustmentType.DISCOUNT &&
          adjustment?.allowOnPaymentType == false,
      );
    },
  },
  {
    path: 'adjustments',
    valueTransformationFn: (
      adjustments: Adjustment[],
      { order, currency }: ExtraValueTransformationOptions,
    ) => {
      const rows: FixedTuple[] = [
        [
          '',
          'Payment Surcharge',
          `${formatMoneyValue(
            paymentTypeSurchargeAmount(order.payments),
            currency,
          )}`,
        ],
      ];
      return rows;
    },
    canShow: (adjustments: Adjustment[]) => {
      return adjustments?.some(adjustment => adjustment?.allowOnPaymentType);
    },
  },
  {
    path: 'roundingAmount',
    valueTransformationFn: (
      value = 0,
      { currency }: ExtraValueTransformationOptions,
    ) => {
      const rows: FixedTuple[] = [
        ['', 'Cash Rounding', formatMoneyValue(value || 0, currency)],
      ];
      return rows;
    },
    canShow: (value: string) => !!value,
  },
  {
    // TODO: do we need to show taxes individually
    path: 'taxes',
    valueTransformationFn: (
      taxes: OrderTax[] = [],
      { currency }: ExtraValueTransformationOptions,
    ) => {
      const rows: FixedTuple[] = taxes.map(tax => [
        '',
        tax.tax?.name || '',
        formatMoneyValue(tax.amount || 0, currency),
      ]);

      if (taxes.length > 1) {
        rows.push([
          '',
          'Total Tax',
          formatMoneyValue(
            taxes.reduce((total, tax) => total + tax.amount, 0),
            currency,
          ),
        ]);
      }
      return rows;
    },
    canShow: () => true,
  },
  {
    path: 'deliveryFee',
    valueTransformationFn: (
      deliveryFee,
      { currency }: ExtraValueTransformationOptions,
    ) => {
      const rows: FixedTuple[] = [
        ['', 'Delivery Fee', formatMoneyValue(+deliveryFee, currency)],
      ];
      return rows;
    },
    canShow: (value: string) => !!value,
  },
  {
    path: 'serviceCharge',
    valueTransformationFn: (
      serviceCharge,
      { currency }: ExtraValueTransformationOptions,
    ) => {
      const rows: FixedTuple[] = [
        ['', 'Service Charge', formatMoneyValue(+serviceCharge, currency)],
      ];
      return rows;
    },
    canShow: (value: string) => !!value,
  },
  {
    path: 'tip',
    valueTransformationFn: (
      tip = 0,
      { currency }: ExtraValueTransformationOptions,
    ) => {
      const rows: FixedTuple[] = [
        ['', 'Tips', formatMoneyValue(tip, currency)],
      ];
      return rows;
    },
    canShow: (value: string) => !!value,
  },
];
export const generateOrderItems = (order: Order, currency: string) => {
  const itemsTable = orderItemRows.reduce((acc, row) => {
    const value = _.get(order, row.path);
    if (row.canShow && row.canShow(value)) {
      const rowWithData: FixedTuple[] = row.valueTransformationFn(value, {
        currency,
        order,
      });
      return [...acc, ...rowWithData];
    }
    return acc;
  }, [] as FixedTuple[]);
  return table(itemsTable, config);
};

export const generateOrderItemsHeaders = (filter: { refundOrder: boolean }) => {
  let data = [[' ', ' ', ' ']];
  if (filter.refundOrder) {
    data = [[' ', ' ', ' ']];
  }
  return table(data, config);
};
