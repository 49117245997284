/* eslint-disable react-native/no-inline-styles */
import React, { useCallback, useEffect, useState } from 'react';
import {
  View,
  Text,
  Linking,
  TouchableOpacity,
  ActivityIndicator,
  Platform,
} from 'react-native';
import { useCurrency, useTranslation } from '@oolio-group/localization';
import { useNavigation } from '@react-navigation/native';
import { useIntercom } from '../../../hooks/Intercom/useIntercom';
import { useSession } from '../../../hooks/app/useSession';
import format from 'date-fns/format';
import { IMap } from '../Reports/types';
import fetch from 'cross-fetch';
import styles from './OverviewScreen.styles';
import theme from '../../../common/default-theme';
import { Icon } from '../../../components/Icon/Icon';
import ScreenLayout from '../../../components/Office/ScreenLayout/ScreenLayout';
import Section from '../../../components/Office/Section/Section';
import { analyticsService } from '../../../analytics/AnalyticsService';
import { tokenUtility } from '../../../state/tokenUtility';
import { URL_INTERCOM } from '../../../constants';

export const OverviewScreen: React.FC = () => {
  const [session] = useSession();
  const Intercom = useIntercom();
  const navigation = useNavigation();
  const { translate } = useTranslation();
  const { formatCurrency } = useCurrency();
  const [salesDailyData, setSalesDailyData] = useState<
    IMap<string | undefined> | undefined
  >(undefined);
  const [salesMonthlyData, setSalesMonthlyData] = useState<
    IMap<string | undefined> | undefined
  >(undefined);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const REACT_APP_ANALYTICS_API_URL =
    process.env['REACT_APP_ANALYTICS_API_URL'] ||
    'http://localhost:4020/api/analytics/v1';

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    async function getSalesData(source: any, duration: string) {
      setIsLoading(true);
      const query = {
        measures: ['Orders.count', 'Orders.totalRevenue'],
        timeDimensions: [
          {
            dimension: 'Orders.createdAt',
            dateRange: duration,
          },
        ],
        order: {
          'Orders.createdAt': 'asc',
        },
        timezone: session.currentOrganization?.timezone,
      };

      const queryUrl = `${REACT_APP_ANALYTICS_API_URL}/load?query=${JSON.stringify(
        query,
      )}`;

      const request = await fetch(queryUrl, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          organization: session?.currentOrganization?.id || '',
          Authorization: `Bearer ${tokenUtility.token || ''}`,
        },
      });

      const response = await request.json();
      setIsLoading(false);
      source(response?.data?.[0]);
    }

    if (!salesDailyData) getSalesData(setSalesDailyData, 'Today');

    if (!salesMonthlyData) getSalesData(setSalesMonthlyData, 'This month');
  }, [
    salesDailyData,
    salesMonthlyData,
    session.currentOrganization?.timezone,
    session.currentOrganization?.id,
    REACT_APP_ANALYTICS_API_URL,
  ]);

  // Sales Data Formatter

  const formatTotalSalesData = (number: string | undefined) => {
    if (number === undefined) {
      return 'N/A';
    } else {
      return formatCurrency(parseFloat(number));
    }
  };

  const formatOrdersData = (number: string | undefined) => {
    if (number === undefined) {
      return 'N/A';
    } else {
      return number;
    }
  };

  const onPressContact = useCallback(() => {
    analyticsService.capture('home_link', {
      button: 'Contact Us',
    });

    const IntercomUser = {
      email: session?.user?.email || '',
      userId: session?.user?.id || '',
      name: session?.user?.name || '',
      customAttributes: {
        app: session?.activeApp || '',
      },
      companies: [
        {
          ...Platform.select({
            web: {
              companyId: session?.currentOrganization?.id || '',
            },
            native: {
              id: session?.currentOrganization?.id || '',
            },
          }),
          name: session?.currentOrganization?.name || '',
          customAttributes: {
            venue: session?.currentVenue?.name || '',
            store: session?.currentStore?.name || '',
            abn: session?.currentOrganization?.businessIdentifier || '',
          },
        },
      ],
    };
    Intercom.start(IntercomUser, Intercom.show);
  }, [Intercom, session]);

  const onPressSupport = () => {
    analyticsService.capture('home_link', {
      button: 'Support Center',
    });

    if (Platform.OS === 'web') {
      window.open(URL_INTERCOM, '_blank');
    } else {
      Linking.openURL(URL_INTERCOM);
    }
  };

  return (
    <ScreenLayout hideFooter loading={isLoading} title="Office | Oolio">
      <Section layoutWidth="medium">
        <View style={styles.sectionContainer}>
          <View style={styles.sectionTitleContainer}>
            <Text style={styles.sectionTitle}>
              {translate('home.quickLinksTitle')}
            </Text>
            <Text style={styles.sectionSubtitle}>
              {translate('home.quickLinksSubtitle')}
            </Text>
          </View>
          <View style={[styles.sectionContent, styles.quickLinksSection]}>
            <TouchableOpacity
              onPress={() => {
                analyticsService.capture('home_link', {
                  button: 'Add Product',
                });
                navigation.navigate('Products', { screen: 'Manage' });
              }}
              style={[
                styles.quickLinksBtn,
                { backgroundColor: theme.colors.purple },
              ]}
            >
              <Text style={styles.quickLinksBtnLabel}>
                {translate('home.addProduct')}
              </Text>
              <Icon name="AngleRight" color={theme.colors.white} size={20} />
            </TouchableOpacity>
            <TouchableOpacity
              // ENHANCEMENT: Navigate to Modifier Tab
              onPress={() => {
                analyticsService.capture('home_link', {
                  button: 'Add Modifier',
                });
                navigation.navigate('Products', {
                  screen: 'Options',
                  params: {
                    screen: 'ModifierTab',
                  },
                });
              }}
              style={[
                styles.quickLinksBtn,
                { backgroundColor: theme.colors.indigo },
              ]}
            >
              <Text style={styles.quickLinksBtnLabel}>
                {translate('home.addModifier')}
              </Text>
              <Icon name="AngleRight" color={theme.colors.white} size={20} />
            </TouchableOpacity>
            <TouchableOpacity
              onPress={() => {
                analyticsService.capture('home_link', {
                  button: 'Add User',
                });
                navigation.navigate('Users', { screen: 'ManageUsers' });
              }}
              style={[
                styles.quickLinksBtn,
                { backgroundColor: theme.colors.lightBlue },
              ]}
            >
              <Text style={styles.quickLinksBtnLabel}>
                {translate('home.addUser')}
              </Text>
              <Icon name="AngleRight" color={theme.colors.white} size={20} />
            </TouchableOpacity>
            <TouchableOpacity
              onPress={() => {
                analyticsService.capture('home_link', {
                  button: 'Edit Menu',
                });
                navigation.navigate('Products', { screen: 'Menus' });
              }}
              style={[
                styles.quickLinksBtn,
                { backgroundColor: theme.colors.green },
              ]}
            >
              <Text style={styles.quickLinksBtnLabel}>
                {translate('home.editMenu')}
              </Text>
              <Icon name="AngleRight" color={theme.colors.white} size={20} />
            </TouchableOpacity>
          </View>
        </View>
        <View style={styles.sectionContainer}>
          <View style={styles.sectionTitleContainer}>
            <Text style={styles.sectionTitle}>
              {translate('home.salesSnapshotTitle')}
            </Text>
            <Text style={styles.sectionSubtitle}>
              {translate('home.salesSnapshotSubtitle')}
            </Text>
          </View>
          <View style={[styles.sectionContent, styles.snapshotStats]}>
            <View style={styles.snapshotStatContainer}>
              <View style={styles.snapshotStatTitleText}>
                <Text style={styles.snapshotStatTitle}>
                  {translate('home.today')}
                </Text>
                <Text style={styles.snapshotStatSubtitle}>
                  {format(new Date(), 'EEEE, MMMM do')}
                </Text>
              </View>
              <View style={styles.statContainer}>
                <View>
                  <Text style={styles.statValue}>
                    {isLoading ? (
                      <ActivityIndicator color={theme.colors.grey6} />
                    ) : (
                      formatTotalSalesData(
                        salesDailyData?.['Orders.totalRevenue'],
                      )
                    )}
                  </Text>
                  <Text style={styles.statLabel}>
                    {translate('home.totalRevenue')}
                  </Text>
                </View>
                <View>
                  <Text style={[styles.statValue, { textAlign: 'right' }]}>
                    {isLoading ? (
                      <ActivityIndicator color={theme.colors.grey6} />
                    ) : (
                      formatOrdersData(salesDailyData?.['Orders.count'])
                    )}
                  </Text>
                  <Text style={[styles.statLabel, { textAlign: 'right' }]}>
                    {translate('home.orders')}
                  </Text>
                </View>
              </View>
            </View>
            <View style={styles.snapshotStatContainer}>
              <View style={styles.snapshotStatTitleText}>
                <Text style={styles.snapshotStatTitle}>
                  {translate('home.thisMonth')}
                </Text>
                <Text style={styles.snapshotStatSubtitle}>
                  {format(new Date(), 'MMMM, yyyy')}
                </Text>
              </View>
              <View style={styles.statContainer}>
                <View>
                  <Text style={styles.statValue}>
                    {isLoading ? (
                      <ActivityIndicator color={theme.colors.grey6} />
                    ) : (
                      formatTotalSalesData(
                        salesMonthlyData?.['Orders.totalRevenue'],
                      )
                    )}
                  </Text>
                  <Text style={styles.statLabel}>
                    {translate('home.totalRevenue')}
                  </Text>
                </View>
                <View>
                  <Text style={[styles.statValue, { textAlign: 'right' }]}>
                    {isLoading ? (
                      <ActivityIndicator color={theme.colors.grey6} />
                    ) : (
                      formatOrdersData(salesMonthlyData?.['Orders.count'])
                    )}
                  </Text>
                  <Text style={[styles.statLabel, { textAlign: 'right' }]}>
                    {translate('home.orders')}
                  </Text>
                </View>
              </View>
            </View>
          </View>
        </View>
        <View style={styles.sectionContainer}>
          <View style={styles.sectionTitleContainer}>
            <Text style={styles.sectionTitle}>
              {translate('home.doMoreTitle', {
                appName: translate('appName'),
              })}
            </Text>
            <Text style={styles.sectionSubtitle}>
              {translate('home.doMoreSubtitle')}
            </Text>
          </View>
          <View style={styles.sectionContent}>
            <View style={styles.cardRow}>
              <TouchableOpacity
                onPress={() => {
                  analyticsService.capture('home_link', {
                    button: 'Features',
                  });
                  navigation.navigate('Features', {
                    screen: 'ManageFeatures',
                  });
                }}
                style={styles.cardContainer}
              >
                <View
                  style={[
                    styles.cardIconContainer,
                    {
                      backgroundColor: theme.colors.orange,
                    },
                  ]}
                >
                  <Icon
                    name="CreateDashboard"
                    color={theme.colors.white}
                    size={20}
                  />
                </View>
                <Text style={styles.cardTitle}>
                  {translate('home.featuresTitle')}
                </Text>
                <Text style={styles.cardSubtitle}>
                  {translate('home.featuresSubtitle')}
                </Text>
              </TouchableOpacity>
              <TouchableOpacity
                onPress={() => {
                  analyticsService.capture('home_link', {
                    button: 'Integrations',
                  });
                  navigation.navigate('Features', {
                    screen: 'ManageFeatures',
                    params: {
                      screen: 'IntegrationsTab',
                    },
                  });
                }}
                style={styles.cardContainer}
              >
                <View
                  style={[
                    styles.cardIconContainer,
                    {
                      backgroundColor: theme.colors.pink,
                    },
                  ]}
                >
                  <Icon name="BoltAlt" color={theme.colors.white} size={20} />
                </View>
                <Text style={styles.cardTitle}>
                  {translate('home.integrationsTitle')}
                </Text>
                <Text style={styles.cardSubtitle}>
                  {translate('home.integrationsSubtitle')}
                </Text>
              </TouchableOpacity>
            </View>
            <View style={styles.cardRow}>
              <TouchableOpacity
                onPress={onPressContact}
                style={styles.cardContainer}
              >
                <View
                  style={[
                    styles.cardIconContainer,
                    {
                      backgroundColor: theme.colors.green,
                    },
                  ]}
                >
                  <Icon
                    name="PhoneVolume"
                    color={theme.colors.white}
                    size={20}
                  />
                </View>
                <Text style={styles.cardTitle}>
                  {translate('home.contactTitle')}
                </Text>
                <Text style={styles.cardSubtitle}>
                  {translate('home.contactSubtitle')}
                </Text>
              </TouchableOpacity>
              <TouchableOpacity
                onPress={() => onPressSupport()}
                style={styles.cardContainer}
              >
                <View
                  style={[
                    styles.cardIconContainer,
                    {
                      backgroundColor: theme.colors.blue,
                    },
                  ]}
                >
                  <Icon
                    name="QuestionCircle"
                    color={theme.colors.white}
                    size={20}
                  />
                </View>
                <Text style={styles.cardTitle}>
                  {translate('home.supportTitle')}
                </Text>
                <Text style={styles.cardSubtitle}>
                  {translate('home.supportSubtitle', {
                    appName: translate('appName'),
                  })}
                </Text>
              </TouchableOpacity>
            </View>
          </View>
        </View>
      </Section>
    </ScreenLayout>
  );
};
