import { FlexAlignType, StyleSheet } from 'react-native';
import theme from '../../../../../common/default-theme';

const styles = StyleSheet.create({
  menuScheduleRowContainer: {
    flexDirection: 'column',
    paddingVertical: 10,
    borderBottomColor: theme.colors.grey3,
    borderBottomStyle: 'solid',
    borderBottomWidth: 1,
  },
  menuScheduleRowMainDetail: {
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
  },
  menuScheduleRowSubDetailContainer: {
    paddingVertical: 20,
  },
  menuScheduleRowSubDetailRow: {
    flexDirection: 'row',
    alignItems: 'start' as FlexAlignType,
    marginBottom: 5,
  },
  menuScheduleRowSubDetailDateRangeContainer: {
    marginRight: 30,
  },
  menuScheduleRowSubDetailTimeBlockDay: {
    marginRight: 30,
  },
  menuScheduleRowSubDetailRowText: {
    color: theme.colors.grey6,
  },
  menuScheduleRowSubDetailDateRangeRowContainer: {
    flexDirection: 'column',
  },
  menuScheduleRowSubDetailRoutineContainer: {
    flexDirection: 'row',
  },
  scheduleRowEditIcon: {
    borderRadius: theme.radius.s,
    marginLeft: 'auto',
  },
  scheduleRowActive: {
    width: 75,
    marginLeft: 20,
  },
});

export default styles;
