import React, { useCallback, useState } from 'react';
import { View } from 'react-native';
import Button from '../../Button/Button';
import InputPhone from '../../Shared/Inputs/InputPhone';
import InputEmail from '../../Shared/Inputs/InputEmail';
import IconButton from '../../Button/IconButton';
import PopupView from '../../PopupView/PopupView';
import TitleBar from '../../TitleBar/TitleBar';
import { DEFAULT_COUNTRY_CODE } from '../../../constants';
import { useNotification } from '../../../hooks/Notification';

import { StyleFn, Order, Customer } from '@oolio-group/domain';
import { isValidEmail, isValidNumber } from '../../../utils/validator';

import { useModal } from '@oolio-group/rn-use-modal';
import { useFela } from 'react-fela';
import { useSession } from '../../../hooks/app/useSession';
import { usePhoneNumber, useTranslation } from '@oolio-group/localization';
import { useSyncOrderEvents } from '../../../hooks/app/useSyncOrderEvents';
import { userUtility } from '../../../state/userUtility';
import { styles } from './style';
import { generateNotificationEvent } from '../../../utils/sendReceiptHelper';

interface ReceiptPrintFormProps {
  email: string;
  phone: string;
  countryCode: string;
}

const iconButtonStyle: StyleFn = () => ({
  position: 'absolute',
  left: 8,
});

const containerStyle: StyleFn = ({ theme }) => ({
  width: 320,
  alignSelf: 'center',
  justifyContent: 'center',
  borderRadius: theme.radius.small,
  flex: 1,
});

const popupStyle: StyleFn = () => ({
  paddingTop: 8,
});

const buttonStyle: StyleFn = () => ({
  marginTop: 40,
});

export interface SendOrderReceiptModalProps {
  onSendSuccess: () => void;
  onSendFailed: () => void;
  order: Order;
  assignedCustomer?: Customer;
}

const SendOrderReceiptModal: React.FC<SendOrderReceiptModalProps> = ({
  onSendSuccess,
  onSendFailed,
  order,
  assignedCustomer,
}) => {
  const { css } = useFela();
  const { closeModal } = useModal();
  const { translate } = useTranslation();
  const [session] = useSession();
  const { country = DEFAULT_COUNTRY_CODE } = session?.currentOrganization || {};
  const { showNotification } = useNotification();

  const { getFullFormattedPhoneNumber } = usePhoneNumber();
  const { syncOrderEvents } = useSyncOrderEvents(onSendSuccess, onSendFailed);

  const [form, setForm] = useState<ReceiptPrintFormProps>({
    email: assignedCustomer?.email || '',
    phone: assignedCustomer?.phoneNumber || '',
    countryCode: assignedCustomer?.preferredAddress?.isoCountryCode || country,
  });

  const isPhoneValid = isValidNumber(
    getFullFormattedPhoneNumber(form.countryCode, form.phone),
  );
  const isEmailValid = isValidEmail(form.email);

  const onChangeText = (prop: string, text: string): void => {
    setForm(preForm => ({ ...preForm, [prop]: text }));
  };

  const sendOrderReceipt = useCallback(
    async (email, phone) => {
      if (session && order?.id) {
        const event = generateNotificationEvent(
          session,
          order?.id,
          userUtility.userActivity.posUser?.id || '',
          email,
          phone,
        );

        if (event) {
          syncOrderEvents([event]);
        }
      }
    },
    [session, order.id, syncOrderEvents],
  );

  const handleCloseModal = useCallback(() => {
    closeModal();
  }, [closeModal]);

  const titleLeft = (
    <IconButton
      containerStyle={css(iconButtonStyle)}
      icon="times"
      onPress={handleCloseModal}
    />
  );

  const handleSendReceipt = useCallback(() => {
    if (!form.email && !form.phone) {
      showNotification({
        error: true,
        message: translate('sendReceipt.emptyEmailAndPhoneMsg'),
      });
      return;
    }

    if (form.email && !isValidEmail(form.email)) {
      showNotification({
        error: true,
        message: translate('sendReceipt.invalidEmailMsg'),
      });
      return;
    }

    sendOrderReceipt(form.email, {
      countryCode: form.countryCode,
      number: form.phone,
    });
    closeModal();
  }, [
    form.email,
    form.phone,
    form.countryCode,
    sendOrderReceipt,
    closeModal,
    showNotification,
    translate,
  ]);

  const canSend = isEmailValid || isPhoneValid;

  return (
    <View style={css(containerStyle)}>
      <TitleBar
        titleLeft={titleLeft}
        primary
        title={translate('modal.emailReceipt')}
      />
      <PopupView containerStyle={css(popupStyle)}>
        <InputPhone
          containerStyle={css(styles.phoneTextInputStyle)}
          testID="phone-number"
          placeholder="0423 456 789"
          value={form.phone}
          defaultCountry={form.countryCode}
          onPressCountry={onChangeText.bind(null, 'countryCode')}
          onChangeText={text => onChangeText('phone', text)}
        />
        <InputEmail
          testID="email-address"
          containerStyle={[css(styles.phoneTextInputStyle)]}
          placeholder={translate('common.emailPlaceholder')}
          value={form.email || ''}
          onChangeText={text => onChangeText('email', text)}
        />
        <Button
          title={translate('button.sendReceipt')}
          success
          disabled={!canSend}
          size="medium"
          testID="send-receipt"
          containerStyle={css(buttonStyle)}
          onPress={handleSendReceipt}
        />
      </PopupView>
    </View>
  );
};
export default SendOrderReceiptModal;
