import React, { FC } from 'react';
import { View, Text, TouchableOpacity } from 'react-native';
import { useTranslation } from '@oolio-group/localization';
import styles from './RequiredOptionsModal.styles';
import theme from '../../../../common/default-theme';
import Icon from '../../../Icon/Icon';

export interface OptionGroupTabProps {
  name: string;
  subtitle?: string;
  status?: 'required' | 'optional' | 'selected' | 'active';
  onPress: () => void;
}

const OptionGroupTab: FC<OptionGroupTabProps> = ({
  name,
  subtitle,
  status,
  onPress,
}) => {
  const { translate } = useTranslation();

  const isActive = status === 'active';

  const getGroupIcon = () => {
    switch (status) {
      case 'active':
        return <Icon name="angle-right" size={20} color={theme.colors.white} />;
      case 'selected':
        return (
          <Icon
            size={20}
            name="check-circle"
            color={theme.colors.states.positive}
          />
        );
      case 'optional':
        return (
          <Icon
            size={20}
            name="check-circle"
            color={theme.colors.states.neutral}
          />
        );
      default:
        return (
          <Icon
            size={20}
            name="exclamation-triangle"
            color={theme.colors.states.negative}
          />
        );
    }
  };

  return (
    <TouchableOpacity
      onPress={onPress}
      style={[styles.group, isActive && styles.groupActive]}
    >
      <View style={styles.text}>
        <Text
          numberOfLines={2}
          style={[styles.title, isActive && styles.activeText]}
        >
          {name}
        </Text>
        <Text style={[styles.subtitle, isActive && styles.activeText]}>
          {subtitle || translate('modifiers.notSelected')}
        </Text>
      </View>
      <View style={styles.icon}>{getGroupIcon()}</View>
    </TouchableOpacity>
  );
};

export default OptionGroupTab;
