import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { InteractionManager, TouchableOpacity, View } from 'react-native';
import { FelaComponent, useFela } from 'react-fela';
import { groupBy, isNumber, keyBy, sortBy } from 'lodash';
import {
  AllergensKey,
  CatalogueItem,
  DEFAULT_MAX_LIMIT_FOR_ZERO_SELECTION,
  DEFAULT_PRODUCT_MODIFIER_GROUP_NAME,
  EntityType,
  FeatureIDs,
  FunctionMapActions,
  Modifier,
  ModifierAlias,
  OrderItem,
  Page,
  PageItem,
  PageItemMaps,
  Product as ProductAlias,
  Product,
  ProductModifierGroup,
  RenderProps,
  StyleFn,
  Variant,
} from '@oolio-group/domain';
import {
  getLocaleEntity,
  isDeselectDefaultOption,
  isStoreProductAvailable,
} from '@oolio-group/client-utils';
import { translate, useLocalization } from '@oolio-group/localization';
import { useSession } from '../../hooks/app/useSession';
import { useNotification } from '../../hooks/Notification';
import { useCheckFeatureEnabled } from '../../hooks/app/features/useCheckFeatureEnabled';
import { changeDueVar } from '../../App';
import styles from '../POS/Menu/Menu.styles';
import Icon from '../Icon/Icon';
import CatalogGridView from './CatalogGridView';
import MenuPages from '../POS/Menu/Pages/MenuPages';
import Functions from '../POS/Menu/Functions/Functions';
import CatalogItem, { CatalogItemProps } from './CatalogItem';
import RequiredOptionsModal, {
  SelectedOptionGroup,
  SelectedOptions,
} from '../POS/Modals/RequiredOptionsModal/RequiredOptionsModal';

export interface Action {
  name: string;
  color?: string;
  onPress: () => void;
  isGroup: boolean;
  feature?: FeatureIDs;
  priority?: number;
  action?: FunctionMapActions;
}

export type CartSelectionState = {
  item: string;
  modifier?: string;
  variant?: string;
  product?: string;
  modifierGroup?: string;
};

export interface CatalogDisplayProps {
  testID?: string;
  allProducts: Record<string, Product>;
  variantMaps: Record<string, Variant>;
  selectedProduct: Product | Variant | undefined;
  setSelectedProduct: React.Dispatch<
    React.SetStateAction<Product | Variant | undefined>
  >;
  selectedVariantKey?: string;
  actions?: Action[];
  selectedItem?: CartSelectionState;
  isCartItemDeleted?: boolean;
  selectedModifiers?: { [key: string]: string[] };
  orderId?: string;
  addProductToCart: (item: Product, isLongPress?: boolean) => void;
  addModifierToProduct: (
    item: Partial<Product>,
    selectedModifiers: CatalogModifier[],
    orderItemId?: string,
  ) => void;
  addVariantToProduct: (
    selectedOptions: string[],
    item: Variant,
    isLongPress?: boolean,
  ) => void;
  removeItemFromCart: (
    entityId: string,
    type: 'Variant' | 'Product',
    isForce?: boolean,
  ) => void;
  replaceVariant: (
    orderItem: string,
    selectedProduct: Variant,
    selectedOptions: string[],
  ) => void;
  unselectCartItem: () => void;
  setCartItemDeleted?: (value: boolean) => void;
  allPageItemMaps: PageItemMaps;
  sortedMenuItems: CatalogueItem[];
  allergens?: AllergensKey[];
  onUpdateOptionsToItem: (
    selectedOptionGroups: SelectedOptionGroup[],
    defaultOptions: SelectedOptions,
  ) => void;
  activeOrderItem?: OrderItem;
}

export interface CatalogModifier {
  id: string;
  name: string;
  type: ModifierAlias | 'BACK' | 'CHECK';
  modifierGroup?: string;
  modifierGroupPriority?: number;
  isSelected: boolean;
  isDefault: boolean;
  priority?: number;
}

export interface CatalogModifierGroup {
  id: string; // id of mod group
  modifiers: string[]; // stores selected / default modifiers
  isSelected: boolean; // true when selection is done
  isQualified: boolean; // true when it satifies the limit of mod group
  isChanged: boolean; // to track if it is changed
  isGrouped?: boolean; // modifier group is grouped or not
  minLimit: number;
  maxLimit: number;
  defaultMods: string[];
  selectedDefaultMods: number;
}

const modifierSelectionCompleted = 'COMPLETED';

const grid = {
  flexWrap: 'wrap',
  flexDirection: 'row',
  justifyContent: 'space-between',
  marginBottom: 2,
};

const productsContainer: StyleFn = () => ({
  flex: 5,
  ...grid,
});

const optionsContainer: StyleFn = () => ({
  flex: 3,
  ...grid,
});

const tileContainer: StyleFn = ({ rows, columns }) => ({
  height: `${100 / rows}%`,
  width: `${98.2 / columns}%`,
  paddingBottom: 4,
});

const backLabelStyling: StyleFn = () => ({
  fontSize: 14,
});

const allergensLabelStyle: StyleFn = ({ theme }) => ({
  color: theme.colors.black,
});

export const StyledCatalogItem: React.FC<
  CatalogItemProps & { rows: number; columns: number; containerStyle?: StyleFn }
> = ({ rows, columns, containerStyle, ...props }) => (
  <FelaComponent
    rows={rows}
    columns={columns}
    style={[tileContainer, containerStyle]}
  >
    {({ style }: RenderProps) => (
      <CatalogItem {...props} containerStyle={style} />
    )}
  </FelaComponent>
);

const Catalog: React.FC<CatalogDisplayProps> = ({
  allProducts,
  variantMaps,
  actions = [],
  addProductToCart,
  addModifierToProduct,
  addVariantToProduct,
  selectedProduct,
  selectedVariantKey,
  replaceVariant,
  removeItemFromCart,
  selectedItem,
  unselectCartItem,
  selectedModifiers,
  orderId,
  allPageItemMaps,
  sortedMenuItems,
  onUpdateOptionsToItem,
  allergens,
  setSelectedProduct,
  activeOrderItem,
}) => {
  const { css, theme } = useFela();
  const [selectedPageId, setSelectedPageId] = useState<string>('');
  const [currOptionIndx, setCurrOptionIndx] = useState(0);
  const [selectedOptions, setSelectedOptions] = useState<string[]>([]);
  const [selectedModGroup, setSelectedModGroup] = useState<string>('');
  const [showRequiredModifierPrompt, setShowRequiredModifierPrompt] =
    useState(false);
  const [selectedProductModifierGroups, setSelectedProductModifierGroups] =
    useState<Record<string, CatalogModifierGroup>>({});
  const { locale } = useLocalization();
  const [session] = useSession();
  const currentStore = session.currentStore?.id || '';

  const isFeatureEnabled = useCheckFeatureEnabled();
  const { showNotification } = useNotification();

  useEffect(() => {
    // un select product when a new order initiated
    if (orderId) {
      setSelectedProduct(undefined);
    }
  }, [orderId, setSelectedProduct]);

  useEffect(() => {
    if (selectedProduct) {
      setSelectedProductModifierGroups({});
      setSelectedModGroup('');
    }
  }, [selectedProduct]);

  useEffect(() => {
    if (
      !!selectedVariantKey &&
      selectedProduct &&
      (selectedProduct as Variant).options
    ) {
      const optionsIndex = (selectedProduct as Variant).options?.findIndex(
        elem => elem.key === selectedVariantKey,
      );
      setCurrOptionIndx(optionsIndex >= 0 ? optionsIndex : 0);
    } else {
      setCurrOptionIndx(0);
    }
  }, [selectedProduct, selectedVariantKey]);

  const productModifierGroupsMap: {
    [key: string]: ProductModifierGroup;
  } = useMemo(() => {
    return keyBy((selectedProduct as ProductAlias)?.modifierGroups || [], 'id');
  }, [selectedProduct]);

  useEffect(() => {
    const lengthOfSelectedProdModGroups = Object.keys(
      selectedProductModifierGroups,
    ).length;
    if (
      !lengthOfSelectedProdModGroups &&
      (selectedProduct as ProductAlias)?.modifierGroups?.length
    ) {
      const tempSelectedProdModGroups = { ...selectedProductModifierGroups };
      const modGroups = (selectedProduct as ProductAlias)?.modifierGroups || [];
      modGroups.forEach(eachModGroup => {
        const defaultMods =
          eachModGroup.modifiers.filter(x => x && x.isDefault) || [];

        const maxLimit =
          productModifierGroupsMap?.[eachModGroup?.id]?.selectionLimit?.max ||
          0;

        const { isRequired } = productModifierGroupsMap?.[eachModGroup?.id];
        const actualLimit =
          productModifierGroupsMap?.[eachModGroup?.id]?.selectionLimit?.min ||
          0;
        const minLimit = isRequired && actualLimit < 1 ? 1 : actualLimit;

        const isQualified =
          (minLimit <= defaultMods.length && maxLimit >= defaultMods.length) ||
          eachModGroup.name === DEFAULT_PRODUCT_MODIFIER_GROUP_NAME;
        // selects all modifier groups except selected item modifier group in cart
        const isSelected =
          selectedItem && !(selectedItem?.modifierGroup === eachModGroup.id);
        /**
         * updates state variable of selected product mod group
         * id: id of mod group;
         * modifiers: stores selected / default modifiers
         * isSelected: true when selection is done
         * isQualified: true when it satifies the limit of mod group
         * isChanged:  to track if it is changed by user
         */
        tempSelectedProdModGroups[eachModGroup.id] = {
          id: eachModGroup.id,
          isQualified: isQualified,
          isSelected: isSelected || false,
          isChanged: false,
          minLimit: minLimit,
          maxLimit: maxLimit,
          isGrouped: eachModGroup?.isGrouped,
          defaultMods: defaultMods.map(x => x && x.id),
          selectedDefaultMods: defaultMods.length,
          modifiers:
            (defaultMods.length && defaultMods.map(x => x && x.id)) || [],
        };

        if (!eachModGroup?.isGrouped) {
          // if not grouped min and max limit will not be applied and hence qualified
          tempSelectedProdModGroups[eachModGroup.id] = {
            ...tempSelectedProdModGroups[eachModGroup.id],
            isQualified: true,
          };
        }

        const defaultModifiersMap =
          (defaultMods.length && keyBy(defaultMods, 'id')) || {};

        if (selectedModifiers && selectedModifiers[eachModGroup.id]) {
          // if default mod group there in seleccted mod list then we have to remove
          selectedModifiers[eachModGroup.id].forEach(x => {
            if (defaultModifiersMap[x]) {
              const index =
                tempSelectedProdModGroups[eachModGroup.id].modifiers.indexOf(x);
              if (index >= 0) {
                tempSelectedProdModGroups[eachModGroup.id].modifiers.splice(
                  index,
                  1,
                );
                // if default mod removed then its selection count also should decrease
                tempSelectedProdModGroups[eachModGroup.id].selectedDefaultMods =
                  tempSelectedProdModGroups[eachModGroup.id]
                    .selectedDefaultMods - 1;
              }
            } else if (
              !tempSelectedProdModGroups[eachModGroup.id].modifiers.includes(x)
            ) {
              tempSelectedProdModGroups[eachModGroup.id].modifiers.push(x);
              tempSelectedProdModGroups[eachModGroup.id].isQualified = true;
              tempSelectedProdModGroups[eachModGroup.id].isChanged = true;
            }
          });
        }
      });
      setSelectedProductModifierGroups(tempSelectedProdModGroups);
    }
  }, [
    selectedProduct,
    selectedProductModifierGroups,
    productModifierGroupsMap,
    selectedModifiers,
    addModifierToProduct,
    selectedItem,
  ]);

  const onSelectPage = useCallback(
    (pageId: string): void => {
      setSelectedPageId(pageId);
    },
    [setSelectedPageId],
  );

  const modifierGroupsToDisplay: ProductModifierGroup[] = useMemo(() => {
    const prodModGroupDetails = productModifierGroupsMap[selectedModGroup];
    if (
      selectedModGroup &&
      (prodModGroupDetails?.isRequired || prodModGroupDetails?.isGrouped)
    ) {
      // returns modifiers of selected modifier groups
      return [prodModGroupDetails] as ProductModifierGroup[];
    }

    // returns all modifier groups if not selected any modifier group
    return (selectedProduct as ProductAlias)?.modifierGroups || [];
  }, [productModifierGroupsMap, selectedModGroup, selectedProduct]);

  const selectedPage = allPageItemMaps[selectedPageId] as Page;

  const pageContent = useMemo(() => {
    if (!selectedPage?.items) return [];
    const sortedByPriority = sortBy(
      selectedPage.items || [],
      item => item.priority,
    );
    return sortedByPriority;
  }, [selectedPage?.items]);

  useEffect(() => {
    if (selectedItem?.modifier && selectedItem?.item) {
      const product = allProducts[selectedItem?.product as string];
      setSelectedProduct(product);
      setSelectedModGroup(selectedItem?.modifierGroup || '');
      setSelectedProductModifierGroups({});
    } else if (selectedItem?.product) {
      const product = allProducts[selectedItem?.product as string];
      if (product?.modifierGroups?.length) {
        setSelectedProduct(product);
        setSelectedModGroup(DEFAULT_PRODUCT_MODIFIER_GROUP_NAME);
        setSelectedProductModifierGroups({});
      }
    }
  }, [selectedItem, allProducts, setSelectedProduct]);

  useEffect(() => {
    if (selectedItem?.variant && selectedItem?.item) {
      const variantProduct = variantMaps[selectedItem.variant];
      if (
        selectedProduct &&
        (selectedProduct as Variant).id !== selectedItem.variant
      ) {
        setSelectedOptions([]);
      }
      if (variantProduct) setSelectedProduct(variantProduct);
    }
  }, [selectedItem, selectedProduct, setSelectedProduct, variantMaps]);

  useEffect(() => {
    if (!sortedMenuItems?.length) return;
    const firstValidPageId = sortedMenuItems.find(
      menuItem => menuItem?.page?.id,
    )?.page?.id as string;
    setSelectedPageId(firstValidPageId);
  }, [sortedMenuItems]);

  /**
   * Filters out the available variant options to render
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const productContent: any[] = useMemo((): any[] => {
    if (selectedProduct && (selectedProduct as Variant).options?.length) {
      let options = (selectedProduct as Variant).options[currOptionIndx]
        ?.values;

      let productOptions = (selectedProduct as Variant).products
        .filter(x => x.isSellable)
        .reduce((accumulator: string[], prod) => {
          prod.optionValues.forEach(optionValue =>
            accumulator.push(optionValue.value),
          );
          return accumulator;
        }, []);

      productOptions = [...new Set(productOptions)];

      if (selectedOptions && selectedOptions.length) {
        options = options.reduce((filteredOptions, op) => {
          const isProductExist = (selectedProduct as Variant).products
            .filter(x => x.isSellable)
            .some(prod => {
              if (selectedVariantKey) {
                return prod.optionValues.find(
                  optionValue =>
                    optionValue.key === selectedVariantKey &&
                    optionValue.value === op,
                );
              }
              const prodOptions = prod.optionValues.map(
                optionValue => optionValue.value,
              );
              return (
                prodOptions.includes(op) &&
                selectedOptions.every(selected =>
                  prodOptions.includes(selected),
                )
              );
            });

          if (isProductExist) {
            filteredOptions.push(op);
          }

          return filteredOptions;
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
        }, [] as any);
      } else {
        options = options.filter(op => {
          return productOptions.includes(op);
        });
      }
      return options;
    }
    return [];
  }, [selectedProduct, currOptionIndx, selectedOptions, selectedVariantKey]);

  const variantProducts = useMemo<Product[]>(() => {
    if (selectedProduct && (selectedProduct as Variant).products?.length) {
      return (selectedProduct as Variant).products.filter(
        x => x && x?.isSellable,
      );
    }
    return [];
  }, [selectedProduct]);

  // Update variant items availability if changed from different device
  useEffect(() => {
    if (
      selectedProduct &&
      variantProducts.length &&
      variantMaps[selectedProduct?.id]
    ) {
      const isVariantItemAvailabilityUpdated = variantProducts.some(product => {
        const selectedProductQty =
          product?.storesInventory?.[currentStore]?.availableQuantity;
        const updatedProductQty =
          allProducts[product.id]?.storesInventory?.[currentStore]
            ?.availableQuantity;
        if (
          isNumber(selectedProductQty ?? updatedProductQty) &&
          selectedProductQty !== updatedProductQty
        )
          return true;
        else return false;
      });
      isVariantItemAvailabilityUpdated &&
        setSelectedProduct(variantMaps[selectedProduct?.id]);
    }
  }, [
    allProducts,
    variantProducts,
    currentStore,
    setSelectedProduct,
    selectedProduct,
    variantMaps,
  ]);

  const productModifierGroups: CatalogModifier[] = useMemo(() => {
    if (
      selectedProduct &&
      (selectedProduct as ProductAlias)?.modifierGroups?.length
    ) {
      const result: CatalogModifier[] = [];

      if (selectedModGroup === modifierSelectionCompleted) {
        // when done with selection of mod or mod group returns empty array
        return [];
      }

      const modifierGroupsToShow: ProductModifierGroup[] =
        modifierGroupsToDisplay;
      let isModifierGroupsQualifiedToBeChecked = true;

      modifierGroupsToShow.forEach(modGroup => {
        const isSelected =
          selectedProductModifierGroups?.[modGroup.id]?.isSelected;

        isModifierGroupsQualifiedToBeChecked =
          isModifierGroupsQualifiedToBeChecked &&
          selectedProductModifierGroups?.[modGroup.id]?.isQualified;

        // when not grouped or when group is default group or when its mod group is required and selection not completed we show only modifiers
        if (
          (!modGroup?.isGrouped ||
            modGroup.name === DEFAULT_PRODUCT_MODIFIER_GROUP_NAME ||
            (selectedModGroup === modGroup.id && modGroup.isRequired)) &&
          !(isSelected && (modGroup?.isGrouped || modGroup?.isRequired))
        ) {
          // loads required and non grouped modifier groups's modifiers
          modGroup.modifiers.forEach(mod => {
            const isSelectedMod = selectedProductModifierGroups[
              modGroup.id
            ]?.modifiers?.includes(mod.id);
            const modDetails = getLocaleEntity(
              mod,
              locale?.languageTag,
            ) as Modifier;
            result.push({
              ...modDetails,
              id: modDetails.id,
              name: modDetails.name,
              type: ModifierAlias.Modifier,
              modifierGroup: modGroup.id,
              modifierGroupPriority: modGroup.priority,
              isSelected: isSelectedMod || false,
              isDefault: modDetails?.isDefault || false,
            });
          });
        } else if (
          (!selectedModGroup ||
            selectedModGroup === DEFAULT_PRODUCT_MODIFIER_GROUP_NAME) &&
          !modGroup?.isRequired
        ) {
          // loads non required and grouped modifier groups
          const translatedModGroup = getLocaleEntity(
            modGroup,
            locale?.languageTag,
          );

          result.push({
            id: modGroup.id,
            name: translatedModGroup?.name,
            type: ModifierAlias.ModifierGroup,
            isSelected: false,
            isDefault: false,
          });
        } else if (
          selectedModGroup &&
          selectedModGroup === modGroup.id &&
          modGroup?.isGrouped
        ) {
          // load modifiers when selected is optional mod group
          !modGroup?.isRequired &&
            result.push({
              id: 'BACK',
              name: '<-',
              type: 'BACK',
              isSelected: false,
              isDefault: false,
            });
          modGroup.modifiers.forEach(mod => {
            const isSelecteddMod = selectedProductModifierGroups[
              modGroup.id
            ]?.modifiers?.includes(mod.id);

            const translatedMod = getLocaleEntity(
              mod,
              locale?.languageTag,
            ) as Modifier;
            result.push({
              id: mod.id,
              name: translatedMod.name,
              type: ModifierAlias.Modifier,
              modifierGroup: modGroup.id,
              modifierGroupPriority: modGroup?.priority,
              isSelected: isSelecteddMod || false,
              isDefault: mod?.isDefault || false,
            });
          });
        }
      });
      if (isModifierGroupsQualifiedToBeChecked && result.length >= 13) {
        // adding check button
        result.splice(13, 0, {
          id: 'CHECK',
          name: '',
          type: 'CHECK',
          isSelected: false,
          isDefault: false,
        });
      }
      return result;
    }
    return [];
  }, [
    selectedProduct,
    selectedProductModifierGroups,
    selectedModGroup,
    modifierGroupsToDisplay,
    locale?.languageTag,
  ]);

  // TODO: what is the function is about ?
  const notifyBeforeUpdateState = useCallback(
    (prevState, newState: Product | Variant) => {
      // pre-conditions while changing state
      if ((prevState as ProductAlias)?.modifierGroups?.length) {
        prevState?.id && removeItemFromCart(prevState?.id, 'Product');
      }
      if (
        (prevState as Variant)?.products?.length &&
        !(prevState as Variant)?.products?.find(prod => prod.isDefault) // to check if having default variant
      ) {
        const localeName = getLocaleEntity(
          prevState,
          locale?.languageTag,
        )?.name;
        showNotification({
          error: true,
          message: translate('order.variantRemoved', {
            name: localeName,
          }),
        });
      }
      return newState;
    },
    [removeItemFromCart, showNotification, locale?.languageTag],
  );

  const onPressItem = useCallback(
    (
      item: Product | Page | Variant,
      entityType: EntityType,
      isLongPress = false,
    ): void => {
      changeDueVar(null);
      unselectCartItem();
      setSelectedOptions([]);
      setSelectedProductModifierGroups({});
      // if product clicked on is a page with variants
      // replace modifiers wit the variants
      switch (entityType) {
        case EntityType.Variant:
          notifyBeforeUpdateState(selectedProduct, item as Variant);
          addVariantToProduct([], item as Variant, isLongPress);
          setCurrOptionIndx(0);
          break;
        case EntityType.Product:
          notifyBeforeUpdateState(selectedProduct, item as Product);
          addProductToCart(item as Product, isLongPress);
          break;
        case EntityType.Page:
          onSelectPage(item.id);
          break;
        default:
          break;
      }
    },
    [
      unselectCartItem,
      notifyBeforeUpdateState,
      selectedProduct,
      addVariantToProduct,
      addProductToCart,
      onSelectPage,
    ],
  );

  const onPressOption = useCallback(
    (index: number, isLongPress = false): void => {
      const item = productContent[index];

      const optionLength = (selectedProduct as Variant).options.length;
      let newSelectedOptions = [...new Set([...selectedOptions, item])];
      if (selectedItem?.variant) {
        if (selectedItem.product && selectedVariantKey) {
          const selectedProd = allProducts[selectedItem.product];
          newSelectedOptions = selectedProd.optionValues
            .filter(optionValue => optionValue.key !== selectedVariantKey)
            .map(optionValue => optionValue.value)
            .concat(item);

          replaceVariant(
            selectedItem.item,
            selectedProduct as Variant,
            newSelectedOptions,
          );
        }

        setSelectedOptions(newSelectedOptions);
      } else if (currOptionIndx >= optionLength - 1) {
        setSelectedOptions(newSelectedOptions);
        addVariantToProduct(
          newSelectedOptions,
          selectedProduct as Variant,
          isLongPress,
        );
      } else {
        setSelectedOptions(newSelectedOptions);
        setCurrOptionIndx(currOptionIndx => currOptionIndx + 1);
      }
    },
    [
      productContent,
      selectedProduct,
      selectedOptions,
      selectedItem?.variant,
      selectedItem?.product,
      selectedItem?.item,
      currOptionIndx,
      selectedVariantKey,
      allProducts,
      replaceVariant,
      addVariantToProduct,
    ],
  );

  const onPressModifierGroup = useCallback((modGroupId: string) => {
    // when modifier group is pressed then it updates selectedModGroup state with its id
    setSelectedModGroup(modGroupId);
  }, []);

  const onPressModifier = useCallback(
    (item: CatalogModifier) => {
      setSelectedProductModifierGroups(prev => {
        // when modifier got clicked updates state
        const tempPrev = { ...prev };

        if (item?.modifierGroup && tempPrev[item.modifierGroup]) {
          const modIndex = tempPrev[item.modifierGroup].modifiers.indexOf(
            item.id,
          );

          const isItemDefaultMod =
            tempPrev[item.modifierGroup]?.defaultMods?.includes(item.id) &&
            true;
          // we dont check for min and max rules when there is no actual modifier group
          const isQualifiedToAddModifier =
            item.modifierGroup !== DEFAULT_PRODUCT_MODIFIER_GROUP_NAME &&
            tempPrev[item.modifierGroup].modifiers?.length + 1 >
              (tempPrev[item.modifierGroup].maxLimit ||
                DEFAULT_MAX_LIMIT_FOR_ZERO_SELECTION) +
                tempPrev[item.modifierGroup].selectedDefaultMods &&
            !(modIndex >= 0);

          if (isQualifiedToAddModifier && !isItemDefaultMod) {
            // throw error that he can not add more than # number of modifiers
            showNotification({
              error: true,
              message: translate('order.modifiersExceeded', {
                min: tempPrev[item.modifierGroup].minLimit,
                max: tempPrev[item.modifierGroup].maxLimit,
              }),
            });
          } else {
            modIndex >= 0
              ? tempPrev[item.modifierGroup].modifiers.splice(modIndex, 1)
              : tempPrev[item.modifierGroup].modifiers.push(item.id);
            if (modIndex >= 0 && isItemDefaultMod) {
              tempPrev[item.modifierGroup].selectedDefaultMods -= 1;
            } else if (modIndex < 0 && isItemDefaultMod) {
              tempPrev[item.modifierGroup].selectedDefaultMods += 1;
            }
            selectedProduct &&
              addModifierToProduct(selectedProduct, [item], selectedItem?.item);
          }

          const modifiersLength =
            (item.modifierGroup &&
              tempPrev[item.modifierGroup]?.modifiers?.length) ||
            0;
          const selectedModsCount =
            tempPrev[item.modifierGroup]?.selectedDefaultMods || 0;
          const minLimit = tempPrev[item.modifierGroup]?.minLimit || 0;
          const maxLimit =
            tempPrev[item.modifierGroup]?.maxLimit ||
            DEFAULT_MAX_LIMIT_FOR_ZERO_SELECTION;
          let isQualified =
            (minLimit <= modifiersLength &&
              maxLimit + selectedModsCount >= modifiersLength) ||
            item?.modifierGroup === DEFAULT_PRODUCT_MODIFIER_GROUP_NAME;

          if (!tempPrev[item.modifierGroup]?.isGrouped) {
            // if not grouped then it is qualified with out min and max limit
            isQualified = true;
          }

          tempPrev[item.modifierGroup] = {
            ...tempPrev[item.modifierGroup],
            isQualified,
            isChanged: true,
            // if the modifiers were changed and reached max selection then the mod group should be in selected state
            isSelected:
              tempPrev[item.modifierGroup].modifiers.length ===
                maxLimit + selectedModsCount || false,
          };

          if (tempPrev[item.modifierGroup]?.isSelected) {
            // if the modifiers were changed and reached max selection then the mod group should be in selected state and selected modifier group is removed
            setSelectedModGroup('');
          }
        }
        return tempPrev;
      });
    },
    [addModifierToProduct, selectedProduct, selectedItem, showNotification],
  );

  const onPressBackToModifiersGroup = useCallback(() => {
    setSelectedModGroup('');
  }, []);

  const markRequiredModGroupAsSelected = useCallback(
    (modGroupId?: string) => {
      if (modGroupId) {
        setSelectedProductModifierGroups(prev => {
          const tempPrev = { ...prev };
          if (tempPrev[modGroupId]) {
            tempPrev[modGroupId] = {
              ...tempPrev[modGroupId],
              isSelected: true,
            };
          }
          return tempPrev;
        });
        setSelectedModGroup('');
      } else {
        setSelectedModGroup(modifierSelectionCompleted);
        setSelectedProduct({} as Product);
      }
    },
    [setSelectedProduct],
  );

  const isItemAllergic = useCallback(
    (entity: Product | Variant) => {
      return allergens?.some(
        a =>
          entity.allergens?.includes(a) ||
          (entity as Variant)?.products?.some(p => p.allergens?.includes(a)),
      );
    },
    [allergens],
  );

  const renderPageItem = useCallback(
    (index: number, item?: PageItem): React.ReactNode => {
      const entityId = item?.entityId || '';
      const entity = allPageItemMaps[entityId];
      if (!item || !entity) {
        return <StyledCatalogItem rows={5} columns={5} disabled={true} />;
      }

      const { entityType } = item;
      const translatedEntity = getLocaleEntity(
        entity,
        locale?.languageTag,
      ) as Product;
      let markedAsAllergic: boolean | undefined,
        color = item?.color || theme.colors.deepPurpleDark;
      if (
        (entity as Product)?.allergens?.length ||
        ((entity as Variant)?.products?.length &&
          (entity as Variant).products.some(p => p.allergens?.length))
      ) {
        markedAsAllergic = isItemAllergic(entity as Product | Variant);
        if (markedAsAllergic) color = theme.colors.orangeLight;
      }

      return (
        <StyledCatalogItem
          rows={5}
          columns={5}
          key={item.id}
          testID={`product-${index}`}
          title={translatedEntity?.name}
          color={color}
          isGroup={((entity as Page)?.products?.length || 0) > 0}
          trackedItemQuantity={
            !(entity as Product)?.storesInventory?.[currentStore]
              ?.isBeingTracked
              ? undefined
              : (entity as Product)?.storesInventory?.[currentStore]
                  ?.availableQuantity
          }
          markedAsAllergic={markedAsAllergic}
          onPress={(): void => onPressItem(entity, entityType)}
          onLongPress={() => onPressItem(entity, entityType, true)}
          isAvailable={isStoreProductAvailable(entity as Product, currentStore)}
          labelStyle={markedAsAllergic && css([allergensLabelStyle])}
        />
      );
    },
    [
      allPageItemMaps,
      locale?.languageTag,
      theme.colors,
      currentStore,
      onPressItem,
      isItemAllergic,
      css,
    ],
  );

  const renderVariants = useCallback(
    (index: number, item?: Product): React.ReactNode => {
      const variant = selectedProduct as Variant;

      let markedAsAllergic: boolean | undefined,
        color = item && theme.colors.white;
      if (item && (item.allergens?.length || variant?.allergens?.length)) {
        markedAsAllergic = isItemAllergic(
          item.allergens?.length ? item : variant,
        );
        if (markedAsAllergic) color = theme.colors.orangeLight;
      }

      return (
        <StyledCatalogItem
          rows={3}
          columns={5}
          key={item ? item.id : `variant${index}`}
          testID={`variant-${index}`}
          type={'variant'}
          title={productContent[index]}
          color={color}
          markedAsAllergic={markedAsAllergic}
          labelStyle={markedAsAllergic && css([allergensLabelStyle])}
          disabled={!item}
          trackedItemQuantity={
            !(item as Product)?.storesInventory?.[currentStore]?.isBeingTracked
              ? undefined
              : (item as Product)?.storesInventory?.[currentStore]
                  ?.availableQuantity
          }
          isAvailable={isStoreProductAvailable(item as Product, currentStore)}
          onPress={(): void => onPressOption(index)}
          onLongPress={(): void => onPressOption(index, true)}
        />
      );
    },
    [
      currentStore,
      onPressOption,
      productContent,
      theme.colors.white,
      selectedProduct,
      theme.colors.orangeLight,
      css,
      isItemAllergic,
    ],
  );

  const renderModifierGroups = useCallback(
    (index: number, item?: CatalogModifier) => {
      // renders modifier group item of catalogue
      const isChanged =
        item?.id && selectedProductModifierGroups[item?.id]?.isChanged;
      const selectedModGroups =
        item?.id && selectedProductModifierGroups[item?.id]?.modifiers;

      let typeOfModGroup = 'modifierGroupDanger';

      /**
       * shows red indicator when default mod in mod group is selected and user did not acknowledged
       * shows yellow indicator when user not acknowledged and have no default mod
       * shows green indicator when user acknowleged
       */

      if (isChanged) {
        typeOfModGroup = 'modifierGroup';
      } else if (!isChanged && !selectedModGroups?.length) {
        typeOfModGroup = 'modifierGroupNotSelected';
      } else if (selectedModGroups?.length && !isChanged) {
        typeOfModGroup = 'modifierGroupDanger';
      }
      return (
        <StyledCatalogItem
          rows={3}
          columns={5}
          key={item ? item.id : `modifier-group${index}`}
          testID={`modifier-group-${index}`}
          type={typeOfModGroup}
          title={item && item.name}
          color={item && theme.colors.white}
          disabled={!item}
          selected
          onPress={() => onPressModifierGroup(item?.id || '')}
        />
      );
    },
    [theme, onPressModifierGroup, selectedProductModifierGroups],
  );

  const renderModifier = useCallback(
    // renders modifier item of catalogue
    (index: number, item: CatalogModifier) => {
      const defaultModSelected = item.isDefault && item.isSelected;
      const color = item ? theme.colors.white : undefined;
      return (
        <StyledCatalogItem
          rows={3}
          columns={5}
          key={`modifier-${index}`}
          testID={`modifier-${index}`}
          type={
            item.isDefault && !defaultModSelected
              ? 'modifierDeSelect'
              : 'modifier'
          }
          title={item && item.name}
          color={color}
          disabled={!item}
          selected={item.isSelected || item.isDefault}
          onPress={() => onPressModifier(item)}
        />
      );
    },
    [onPressModifier, theme.colors.white],
  );

  const renderModifiersBackButton = useCallback(
    // renders back button when modifier group is selected and modifiers were listed
    (index: number, item: CatalogModifier) => {
      return (
        <StyledCatalogItem
          rows={3}
          columns={5}
          key={item ? item.name : `modifier-back-button${index}`}
          testID={`modifier-back-${index}`}
          type={''}
          title={'BACK'}
          color={item && theme.colors.white}
          disabled={!item}
          selected
          onPress={onPressBackToModifiersGroup}
          labelStyle={css(backLabelStyling)}
        />
      );
    },
    [theme, onPressBackToModifiersGroup, css],
  );

  const renderEmptyTile = useCallback((index: number) => {
    return (
      <StyledCatalogItem
        rows={3}
        columns={5}
        key={`empty-tile-button${index}`}
      />
    );
  }, []);

  const renderTickButton = useCallback(
    (rows: number, columns: number, modGroup?: string) => {
      return (
        <View style={tileContainer({ theme, rows, columns })}>
          <TouchableOpacity
            testID={'tick'}
            style={styles.tileDone}
            onPress={() => markRequiredModGroupAsSelected(modGroup)}
          >
            <Icon name="check" size={24} color={theme.colors.green} />
          </TouchableOpacity>
        </View>
      );
    },
    [theme, markRequiredModGroupAsSelected],
  );

  const renderModifierOrModifierGroup = useCallback(
    (index: number, item?: CatalogModifier): React.ReactNode => {
      if (item?.type === ModifierAlias.Modifier) {
        return renderModifier(index, item);
      } else if (item?.type === 'BACK') {
        return renderModifiersBackButton(index, item);
      } else if (
        selectedModGroup &&
        index === 14 &&
        productModifierGroups.length &&
        selectedProductModifierGroups?.[selectedModGroup]?.isQualified
      ) {
        return renderTickButton(3, 5, selectedModGroup);
      } else if (
        (!selectedModGroup &&
          index % 14 === 0 &&
          !(item?.type === ModifierAlias.ModifierGroup) &&
          productModifierGroups.length) ||
        item?.type === 'CHECK'
      ) {
        return renderTickButton(3, 5);
      } else {
        return renderModifierGroups(index, item);
      }
    },
    [
      productModifierGroups,
      renderModifier,
      renderModifierGroups,
      renderModifiersBackButton,
      renderTickButton,
      selectedProductModifierGroups,
      selectedModGroup,
    ],
  );

  const onCloseRequiredOptionsModal = useCallback(() => {
    setSelectedProduct(undefined);
    setShowRequiredModifierPrompt(false);
  }, [setSelectedProduct]);

  const onCancelRequiredOptionsModal = useCallback(() => {
    onCloseRequiredOptionsModal();
    if (selectedItem) {
      unselectCartItem();
    } else {
      removeItemFromCart(selectedProduct?.id as string, 'Product', true);
    }
  }, [
    onCloseRequiredOptionsModal,
    removeItemFromCart,
    selectedItem,
    selectedProduct?.id,
    unselectCartItem,
  ]);

  const hasRequiredOptionGroup = useMemo(() => {
    return (selectedProduct as Product)?.modifierGroups?.some(
      modGroup => modGroup.isRequired,
    );
  }, [selectedProduct]);

  useEffect(() => {
    if (!selectedProduct || (selectedItem && !selectedItem?.modifier)) return;
    InteractionManager.runAfterInteractions(() => {
      if (hasRequiredOptionGroup) {
        setShowRequiredModifierPrompt(true);
      } else {
        setShowRequiredModifierPrompt(false);
      }
    });
  }, [
    hasRequiredOptionGroup,
    selectedItem,
    selectedItem?.modifier,
    selectedProduct,
  ]);

  const onSubmitAddRequiredOptions = useCallback(
    (
      selectedOptionGroups: SelectedOptionGroup[],
      defaultOptions: SelectedOptions,
    ) => {
      onUpdateOptionsToItem(selectedOptionGroups, defaultOptions);
      onCloseRequiredOptionsModal();
      unselectCartItem();
    },
    [onUpdateOptionsToItem, onCloseRequiredOptionsModal, unselectCartItem],
  );

  const selectedRequiredOptions = useMemo<SelectedOptions | undefined>(() => {
    if (
      !showRequiredModifierPrompt ||
      !selectedItem?.item ||
      !(selectedProduct as Product)?.modifierGroups
    )
      return;

    const { modifiers = [] } = activeOrderItem || {};
    // ignore deselect default option
    const validModifiers = modifiers.filter(
      mod => !isDeselectDefaultOption(mod?.name),
    );
    const selectedModifierGroups = groupBy(validModifiers, 'modifierGroupId');

    const selectedOptionGroups = (
      selectedProduct as Product
    )?.modifierGroups?.reduce((maps, modGroup) => {
      const modifierQuantityMaps = (
        selectedModifierGroups[modGroup.id] || []
      ).reduce((maps, mod) => {
        const modId = mod.id as string;
        maps[modId] = (maps[modId] || 0) + mod.quantity;
        return maps;
      }, {} as Record<string, number>);

      maps[modGroup.id] = modGroup.modifiers
        .filter(mod => modifierQuantityMaps[mod.id])
        .map(mod => ({
          ...mod,
          quantity: modifierQuantityMaps[mod.id],
        }));
      return maps;
    }, {} as SelectedOptions);
    return selectedOptionGroups;
  }, [
    activeOrderItem,
    selectedItem?.item,
    selectedProduct,
    showRequiredModifierPrompt,
  ]);

  const filteredActions = actions?.filter(
    action => !action.feature || isFeatureEnabled(action.feature),
  );

  return (
    <View testID="catalog" style={styles.container}>
      <View style={styles.main}>
        <CatalogGridView
          testID="products"
          rows={5}
          columns={5}
          paginate={true}
          data={pageContent}
          renderItem={renderPageItem}
          containerStyle={productsContainer}
        />
        {variantProducts?.length ? (
          <CatalogGridView
            testID="variants"
            rows={3}
            columns={5}
            paginate={true}
            data={variantProducts}
            renderItem={renderVariants}
            containerStyle={optionsContainer}
          />
        ) : (
          <CatalogGridView
            testID="modifierGroupsAndModifiers"
            rows={3}
            columns={5}
            ignorePositionByPriority
            paginate={productModifierGroups.length > 15}
            data={hasRequiredOptionGroup ? [] : productModifierGroups}
            renderItem={
              hasRequiredOptionGroup
                ? renderEmptyTile
                : renderModifierOrModifierGroup
            }
            containerStyle={optionsContainer}
          />
        )}
        <Functions functions={filteredActions} />
      </View>
      <MenuPages
        data={sortedMenuItems}
        onPressPage={onSelectPage}
        selectedPage={selectedPageId}
      />
      {selectedProduct && showRequiredModifierPrompt ? (
        <RequiredOptionsModal
          product={selectedProduct.name}
          optionGroups={(selectedProduct as ProductAlias)?.modifierGroups || []}
          onCancel={onCancelRequiredOptionsModal}
          onAddOptionsToItem={onSubmitAddRequiredOptions}
          selectedOptions={selectedRequiredOptions}
          selectedOptionGroupId={selectedItem?.modifierGroup}
        />
      ) : null}
    </View>
  );
};

export default Catalog;
