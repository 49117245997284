import React, { useState } from 'react';
import { useTranslation } from '@oolio-group/localization';
import { useModal } from '@oolio-group/rn-use-modal';
import TreatPicker from '../../../../../components/Shared/Select/Picker';
import TreatModal from '../../../../../components/Shared/Modals/Modal/Modal';

interface AssignTaxModalProps {
  taxOptions: { label: string; value: string }[];
  onSubmit: (selectedTax: string) => void;
  countOfproducts?: number;
}

export const AssignTaxModal: React.FC<AssignTaxModalProps> = ({
  onSubmit,
  taxOptions,
}: AssignTaxModalProps) => {
  const { closeModal } = useModal();
  const { translate } = useTranslation();

  const defaultTax = taxOptions?.[0]?.value || '';
  const [selectedTax, setSelectedTax] = useState(defaultTax);

  return (
    <TreatModal
      title={translate('productBulkOperations.assignTax')}
      onConfirm={{
        label: translate('productBulkOperations.assign'),
        action: () => onSubmit(selectedTax),
      }}
      onDismiss={{ action: closeModal }}
    >
      <TreatPicker
        testID="select-tax"
        title={translate('productBulkOperations.selectTax')}
        options={taxOptions}
        selectedValue={selectedTax}
        onValueChange={val => setSelectedTax(val)}
      />
    </TreatModal>
  );
};
