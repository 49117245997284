import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  cellJobRole: {
    width: 240,
    paddingLeft: 12,
  },
  cellLocations: {
    flex: 1,
    paddingLeft: 22,
    paddingRight: 10,
  },
  tableFooter: {
    width: '100%',
    marginTop: 20,
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
});

export default styles;
