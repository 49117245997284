import React from 'react';
import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs';
import { MaterialTopTabBarProps } from '@react-navigation/material-top-tabs/src/types';
import TabBar from '../../../../../components/TabBar/TabBar';
import { useTranslation } from '@oolio-group/localization';
import { CustomerDetails } from './CustomerDetails/CustomerDetails';
import { useRoute } from '@react-navigation/native';
import { CustomerLoyalty } from './CustomerLoyalty/CustomerLoyalty';
import { Customer } from '@oolio-group/domain';

const Tab = createMaterialTopTabNavigator();
interface RouteParams {
  customer: Customer;
}
const ManageCustomerTabs: React.FC = () => {
  const { translate } = useTranslation();
  const route = useRoute();
  const params = route.params as RouteParams;

  return (
    <Tab.Navigator
      tabBar={(props): React.ReactNode => (
        <TabBar
          tabBar={props as MaterialTopTabBarProps}
          previousRoute={'ManageCustomers'}
        />
      )}
      initialRouteName="ManageCustomersTab"
    >
      <Tab.Screen
        name="CustomerDetailsTab"
        component={CustomerDetails}
        options={{
          tabBarLabel: translate('backOfficeCustomers.tabNames.details'),
        }}
        initialParams={params}
      />
      <Tab.Screen
        name="LoyaltyTab"
        component={CustomerLoyalty}
        options={{
          tabBarLabel: translate('backOfficeCustomers.tabNames.loyalty'),
        }}
        initialParams={params}
      />
    </Tab.Navigator>
  );
};

export default ManageCustomerTabs;
