import React, { useCallback } from 'react';
import { useTranslation } from '@oolio-group/localization';
import { useModal } from '@oolio-group/rn-use-modal';
import { SetPrepTimeModal } from './SetPrepTimeModal';
import { SetBusyModal } from './SetBusyModal';
import ButtonActions from '../../../../../components/Shared/TreatButton/ButtonActions';

interface OrderSettingsOptionsProps {
  setPreparationTime?: (time: number) => void;
  setBusyTime?: (time: number) => void;
}

export const OrderSettingsOptions: React.FC<OrderSettingsOptionsProps> = ({
  setBusyTime,
  setPreparationTime,
}: OrderSettingsOptionsProps) => {
  const { translate } = useTranslation();
  const { showModal } = useModal();

  const setPrepTimeModal = useCallback(() => {
    showModal(<SetPrepTimeModal setPreparationTime={setPreparationTime} />);
  }, [showModal, setPreparationTime]);

  const setBusyModal = useCallback(() => {
    showModal(<SetBusyModal setBusyTime={setBusyTime} />);
  }, [showModal, setBusyTime]);

  return (
    <ButtonActions
      actions={[
        {
          label: translate('onlineOrders.setPrepTime'),
          action: setPrepTimeModal,
        },
        {
          label: translate('onlineOrders.setAsBusy'),
          action: setBusyModal,
        },
      ]}
      icon="clock"
      type="neutral"
      label="Configure"
      testID="btn-timeOptions"
    />
  );
};
