import React from 'react';
import { RefundReasons } from './RefundReasons/RefundReasons';
import { ProductTypes } from './ReportingGroups/ReportingGroups';
import { ReportSettings } from './ReportSettings/ReportSettings';
import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs';
import { MaterialTopTabBarProps } from '@react-navigation/material-top-tabs/src/types';
import TabBar from '../../../../components/TabBar/TabBar';
import { useTranslation } from '@oolio-group/localization';
import { FeatureIDs } from '@oolio-group/domain';
import { useCheckFeatureEnabled } from '../../../../../src/hooks/app/features/useCheckFeatureEnabled';

const Tab = createMaterialTopTabNavigator();

const OtherSettingsStack: React.FC = () => {
  const isFeatureEnabled = useCheckFeatureEnabled();
  const isRefundReasonsEnabled = isFeatureEnabled(FeatureIDs.REFUND_REASON);
  const { translate } = useTranslation();

  return (
    <Tab.Navigator
      tabBar={(props): React.ReactNode => (
        <TabBar showBack={false} tabBar={props as MaterialTopTabBarProps} />
      )}
      initialRouteName={
        isRefundReasonsEnabled ? 'RefundReasons' : 'ProductTypes'
      }
    >
      {isRefundReasonsEnabled ? (
        <Tab.Screen
          name="RefundReasons"
          component={RefundReasons}
          options={{
            tabBarLabel: translate('backOfficeRefundReasons.refundReasons'),
          }}
        />
      ) : null}
      <Tab.Screen
        name="ProductTypes"
        component={ProductTypes}
        options={{
          tabBarLabel: translate('backOfficeReportingGroups.reportingGroups'),
        }}
      />
      <Tab.Screen
        name="ReportSettings"
        component={ReportSettings}
        options={{
          tabBarLabel: translate('backOfficeReportSettings.reportSettings'),
        }}
      />
    </Tab.Navigator>
  );
};

export default OtherSettingsStack;
