import React, { useState, useCallback, useMemo, ReactElement } from 'react';
import {
  View,
  Text,
  TouchableOpacity,
  ViewStyle,
  FlatList,
  TextStyle,
} from 'react-native';
import { useFela, FelaComponent } from 'react-fela';
import { StyleFn, RenderProps } from '@oolio-group/domain';
import IconButton from '../Button/IconButton';
import Button from '../Button/Button';
import SearchBar from '../SearchBar/SearchBar';
import scale from '../../common/theme';
import Popover, { PopoverPlacement } from 'react-native-popover-view';
import { translate } from '@oolio-group/localization';
import { useTimeout } from '../../hooks/useTimeout';
import { useIsMounted } from './../../useIsMounted';

export type MultipleSelectOption = {
  value: string;
  label: string;
  isSelected?: boolean;
};
interface LayoutType {
  x: number;
  y: number;
  width: number;
  height: number;
}
export interface MultipleDropDownProps {
  orderBySelection?: boolean;
  testID?: string;
  label?: string;
  values: MultipleSelectOption[];
  collapsedView?: React.ReactElement;
  selectedValues?: string[];
  searchLabel?: string;
  onValueChange?: (selectedValues: string[]) => void;
  containerStyle?: ViewStyle;
  touchableStyle?: ViewStyle;
  searchPlaceHolder?: string;
  contentStyle?: ViewStyle;
  showCheckboxes?: boolean;
  scrollEnabled?: boolean;
  toggleIcon?: React.ReactElement;
  maxItems?: number;
  onAddItem?: (name: string) => void;
  placeholder?: string;
  placeHolderStyle?: TextStyle;
  textStyle?: TextStyle;
  isDisabled?: boolean;
  collapseOnAddItem?: boolean;
  showSearch?: boolean;
  selectedItemStyle?: ViewStyle;
  deleteRequestModal?: (
    functionCallback: Function,
    isSelected: boolean | undefined,
    value: string,
  ) => void;
  addRequestModal?: (
    functionCallback: Function,
    isSelected: boolean | undefined,
    value: string,
  ) => void;
  arrowVisible?: boolean;
  itemRender?: (
    item: MultipleSelectOption,
    isSelected: boolean,
    onChange: (status: boolean, value: string) => void,
  ) => React.ReactElement;
  PopoverViewPlacement?:
    | 'top'
    | 'right'
    | 'bottom'
    | 'left'
    | 'center'
    | 'auto';
  fluid?: boolean;
}

interface LabelProps {
  textStyle?: TextStyle;
  title?: String;
}

const searchContainerStyle: StyleFn = ({ theme }) => ({
  width: '90%',
  height: 38,
  justifyContent: 'center',
  backgroundColor: theme.colors.white,
  paddingLeft: theme.padding.small,
  borderColor: theme.colors.boxBorder,
  borderWidth: 1,
  alignSelf: 'center',
  marginBottom: theme.padding.small,
});

const searchTextInputStyle: StyleFn = ({ theme }) => ({
  width: '100%',
  height: theme.input.height,
  paddingLeft: theme.padding.medium,
  ...theme.font14RegularCharcoal,
});

const containerForFlatListPreventOverflowStyle: StyleFn = () => ({
  width: '100%',
  minHeight: 200,
  maxHeight: 300,
  overflow: 'scroll',
});

const flatListContentStyle: StyleFn = ({ height }) => ({
  height: height || 200,
  minHeight: 200,
});

const touchContainerStyle: StyleFn = ({ theme }) => ({
  borderColor: theme.colors.boxBorder,
  backgroundColor: theme.colors.white,
  borderWidth: 0.5,
  width: '100%',
  height: theme.input.height,
  borderRadius: theme.radius.small,
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',
});

const labelStyle: StyleFn = ({ theme, title }) => ({
  paddingHorizontal: scale.moderateScale(5),
  paddingVertical: title ? scale.moderateScale(7) : 0,
  textAlign: 'left',
  ...theme.font14RegularDarkGrey,
});

const viewStyle: StyleFn = ({ fluid }) => ({
  flexDirection: 'column',
  height: 'auto',
  width: fluid ? '100%' : 160,
});

const popoverStyle: StyleFn = ({ theme }) => ({
  width: 260,
  borderRadius: theme.radius.large,
  backgroundColor: theme.colors.white,
});

const dropDownStyle: StyleFn = ({ theme }) => ({
  width: 260,
  backgroundColor: theme.colors.blackTransparent01,
  borderRadius: theme.radius.large,
  flexDirection: 'column',
});

const dropDownInnerContainer: StyleFn = ({ theme }) => ({
  flex: 1,
  backgroundColor: theme.colors.white,
  ...theme.shadow30,
  paddingVertical: theme.padding.medium,
  borderRadius: theme.radius.large,
});
const iconContainerStyle: StyleFn = ({ theme }) => ({
  backgroundColor: theme.colors.white,
  marginLeft: 'auto',
  marginRight: theme.spacing.small,
});

const checkBoxContainerStyle: StyleFn = ({ theme }) => ({
  width: '90%',
  justifyContent: 'flex-start',
  alignSelf: 'center',
  borderBottomColor: theme.colors.boxBorder,
  borderBottomWidth: 1,
  borderRadius: 0,
  height: 45,
});

const checkBoxTitleStyle: StyleFn = ({ theme }) => ({
  textTransform: 'none',
  ...theme.font14RegularCharcoal,
});

const checkIconContainer: StyleFn = ({ theme }) => ({
  borderColor: theme.colors.green,
  borderWidth: 2,
  marginRight: theme.spacing.big / 2,
  height: 20,
  width: 20,
  justifyContent: 'center',
  alignSelf: 'center',
});

const rightIconStyle: StyleFn = () => ({
  height: 34,
  width: 20,
  justifyContent: 'center',
  alignSelf: 'center',
  marginLeft: 'auto',
});

const unCheckContainer: StyleFn = ({ theme }) => ({
  borderColor: theme.colors.paragraphLight,
  borderWidth: 2,
  marginRight: theme.spacing.big / 2,
  height: 20,
  width: 20,
});

const dropDownTitleContainerStyle: StyleFn = () => ({
  alignSelf: 'center',
  alignItems: 'center',
});

const dropDownTitleLabelStyle: StyleFn = ({ theme }) => ({
  color: theme.colors.primaryLightest,
  textAlign: 'center',
  fontFamily: theme.font.medium,
  fontSize: theme.fontSize.medium,
  lineHeight: 24,
  letterSpacing: 0,
  paddingBottom: theme.padding.small,
});

const selectedItemContainerStyle: StyleFn = ({ theme }) => ({
  backgroundColor: theme.colors.lightBlueSelect,
  marginLeft: theme.spacing.small * 0.7,
  flexDirection: 'row',
  borderRadius: theme.radius.small,
  marginVertical: theme.padding.small,
  maxWidth: 90,
  minWidth: 60,
  justifyContent: 'center',
});

const placeholderContainerStyle: StyleFn = ({ theme }) => ({
  color: theme.colors.paragraph,
  paddingHorizontal: theme.spacing.small,
  ...theme.font14Regular,
  justifyContent: 'center',
});

const selectedItemTextStyle: StyleFn = ({ theme }) => ({
  color: theme.colors.blue,
  letterSpacing: -0.5,
  fontFamily: theme.font.medium,
  paddingHorizontal: theme.padding.small,
  paddingVertical: theme.padding.small,
});

export const textLabelStyle: StyleFn = ({ theme }) => ({
  ...theme.font14RegularDarkGrey,
  textAlign: 'left',
});
export const backgroundStyle: StyleFn = ({ theme }) => ({
  height: scale.deviceHeight,
  width: '100%',
  backgroundColor: theme.colors.blackTransparent01,
  position: 'absolute',
});
const hideToolTipArrowStyle: StyleFn = () => ({
  width: 0,
  height: 0,
});

const footerSpace: StyleFn = () => ({
  height: 30,
});

export const Label: React.FC<LabelProps> = (props: LabelProps) => (
  <FelaComponent style={labelStyle} {...props}>
    {({ style }: RenderProps): React.ReactNode => (
      <Text
        style={props.textStyle ? [style, props.textStyle] : style}
        {...props}
      >
        {props.title}
      </Text>
    )}
  </FelaComponent>
);

interface SelectedItem {
  name: string;
  onRemove: () => void;
  style?: ViewStyle;
}

export const SelectedItem: React.FC<SelectedItem> = ({
  name,
  onRemove,
  style,
}) => {
  const { css } = useFela();
  return (
    <TouchableOpacity
      onPress={onRemove}
      style={[css(selectedItemContainerStyle), style]}
    >
      <Text numberOfLines={1} style={css(selectedItemTextStyle)}>
        {name}
      </Text>
    </TouchableOpacity>
  );
};

const FooterSpace: React.FC = () => {
  const { css } = useFela();
  return <View style={css(footerSpace)} />;
};

const MultipleDropDown: React.FC<MultipleDropDownProps> = ({
  orderBySelection = false,
  label,
  values,
  searchLabel,
  containerStyle,
  onValueChange,
  searchPlaceHolder,
  contentStyle,
  collapsedView,
  selectedValues,
  showCheckboxes,
  scrollEnabled,
  showSearch = true,
  toggleIcon,
  maxItems,
  onAddItem,
  placeholder,
  placeHolderStyle,
  isDisabled,
  deleteRequestModal,
  addRequestModal,
  touchableStyle,
  textStyle,
  arrowVisible = true,
  PopoverViewPlacement = 'auto',
  selectedItemStyle,
  collapseOnAddItem = false,
  itemRender,
  fluid,
}) => {
  const { css, theme } = useFela({ fluid });
  const [showOptions, setShowOptions] = useState(false);
  const [searchString, setSearchString] = useState('');
  const [availableSpace, setAvailableSpace] = useState(3);
  const isMounted = useIsMounted();

  const addItem = useCallback((): void => {
    if (searchString && onAddItem) {
      onAddItem(searchString);
    }
  }, [onAddItem, searchString]);

  const addItemWithTimeout = useTimeout(addItem);

  const handleAddItem = useCallback(() => {
    if (collapseOnAddItem) {
      setShowOptions(false);
      addItemWithTimeout.start(500);
    } else {
      addItem();
    }
  }, [addItem, collapseOnAddItem, addItemWithTimeout]);

  const onSearchTextChange = useCallback((value): void => {
    setSearchString(value);
  }, []);

  const searchResults = useMemo(() => {
    if (searchString !== '' && values && values.length) {
      const searchLabel = values.filter((eachValue: MultipleSelectOption) =>
        eachValue.label.toLowerCase().includes(searchString.toLowerCase()),
      );
      return searchLabel;
    } else {
      return values;
    }
  }, [searchString, values]);

  const onToggleOptions = useCallback((): void => {
    !isDisabled && setShowOptions(!showOptions);
    setSearchString('');
  }, [showOptions, isDisabled]);

  const closeDropDown = useCallback((): void => {
    setShowOptions(false);
    setSearchString('');
  }, []);

  const onBlur = useCallback(
    (e): void => {
      const targetEvent = (
        e.nativeEvent as unknown as {
          relatedTarget: unknown;
        }
      )?.relatedTarget;
      !targetEvent && closeDropDown();
    },
    [closeDropDown],
  );

  const valuesFiltered = useMemo(() => {
    return searchResults;
  }, [searchResults]);

  const onChangeValues = useCallback(
    async (selected, id) => {
      let newSelectedValues = [] as string[];
      if (selectedValues) {
        newSelectedValues = [...selectedValues];
        const index = newSelectedValues.indexOf(id);
        if (selected && index <= -1) {
          newSelectedValues.push(id);
        } else {
          newSelectedValues.splice(index, 1);
        }
      } else {
        newSelectedValues.push(id);
      }

      onValueChange && (await onValueChange(newSelectedValues));
      if (showOptions && !showCheckboxes) {
        setShowOptions(false);
      }
    },
    [selectedValues, onValueChange, showOptions, showCheckboxes],
  );

  const onChange = useCallback(
    async (selected, id) => {
      if (isDisabled) return;
      let newSelectedValues = [] as string[];
      if (selectedValues) {
        newSelectedValues = [...selectedValues];
        const index = newSelectedValues.indexOf(id);

        if (selected && index <= -1) {
          addRequestModal
            ? addRequestModal(onChangeValues, selected, id)
            : newSelectedValues.push(id);
        } else {
          deleteRequestModal
            ? deleteRequestModal(onChangeValues, selected, id)
            : newSelectedValues.splice(index, 1);
        }
      } else {
        newSelectedValues.push(id);
      }

      if (!addRequestModal && !deleteRequestModal) {
        onValueChange && (await onValueChange(newSelectedValues));
      }

      if (showOptions && !showCheckboxes) {
        setShowOptions(false);
      }
    },
    [
      isDisabled,
      selectedValues,
      addRequestModal,
      deleteRequestModal,
      showOptions,
      showCheckboxes,
      onChangeValues,
      onValueChange,
    ],
  );

  const selectedItems = useMemo(() => {
    const itemsCanFit = Math.floor(availableSpace);
    let selectedObjects = [] as MultipleSelectOption[];
    if (orderBySelection) {
      if (selectedValues && selectedValues.length > 0) {
        selectedValues.forEach(selectedSection => {
          values.forEach(section => {
            if (section.value === selectedSection) {
              selectedObjects.push(section);
            }
          });
        });
      }
    } else {
      selectedObjects = values.filter(obj =>
        selectedValues?.includes(obj.value),
      );
    }
    if (!selectedObjects.length && placeholder) {
      return (
        <Text style={[css(placeholderContainerStyle), placeHolderStyle]}>
          {placeholder}
        </Text>
      );
    } else if (
      selectedObjects.length > 0 &&
      selectedObjects.length <= itemsCanFit
    ) {
      return selectedObjects.map((item, index) => (
        <SelectedItem
          name={item.label}
          key={index}
          onRemove={() => onChange(item.isSelected, item.value)}
          style={selectedItemStyle}
        />
      ));
    } else if (selectedObjects.length > itemsCanFit) {
      const otherItemText = `+${
        selectedObjects.length - (itemsCanFit - 1)
      } ${translate('dropdown.other')}`;
      return (
        <>
          {selectedObjects.slice(0, itemsCanFit - 1).map((item, index) => (
            <SelectedItem
              name={item.label}
              key={index}
              onRemove={() => onChange(item.isSelected, item.value)}
              style={selectedItemStyle}
            />
          ))}
          <View style={css(selectedItemContainerStyle)}>
            <Text style={css(selectedItemTextStyle)}>{otherItemText}</Text>
          </View>
        </>
      );
    } else return;
  }, [
    availableSpace,
    orderBySelection,
    placeholder,
    selectedValues,
    values,
    css,
    placeHolderStyle,
    selectedItemStyle,
    onChange,
  ]);

  const maxItemsToShow = useMemo(() => {
    let maxItemsPerScroll = maxItems || valuesFiltered.length;
    if (maxItems && valuesFiltered.length < maxItems) {
      maxItemsPerScroll = valuesFiltered.length;
    }
    return maxItemsPerScroll;
  }, [valuesFiltered, maxItems]);
  const onRequestClosePopover = useCallback(() => {
    setShowOptions(false);
  }, []);
  const getPopoverPlacement = useCallback(() => {
    switch (PopoverViewPlacement) {
      case 'top':
        return PopoverPlacement.TOP;
      case 'bottom':
        return PopoverPlacement.BOTTOM;
      case 'center':
        return PopoverPlacement.CENTER;
      case 'left':
        return PopoverPlacement.LEFT;
      case 'right':
        return PopoverPlacement.RIGHT;
      case 'auto':
        return PopoverPlacement.AUTO;
      default:
        break;
    }
  }, [PopoverViewPlacement]);

  const getDimensions = (layout: LayoutType) => {
    const { width } = layout;
    const numberOfItemCanFit = width / 100;
    isMounted() && setAvailableSpace(numberOfItemCanFit);
  };

  return (
    <View style={[css(viewStyle), containerStyle]}>
      <View
        testID="test_onchange_values_id"
        onMagicTap={() => onChangeValues(true, 'TEST')}
      >
        {!!label && <Label textStyle={textStyle} title={label} />}
      </View>
      <Popover
        arrowSize={!arrowVisible ? css(hideToolTipArrowStyle) : undefined}
        placement={getPopoverPlacement()}
        popoverStyle={css(popoverStyle({ theme }))}
        from={
          <View>
            <TouchableOpacity
              style={[css(touchContainerStyle), touchableStyle]}
              onPress={onToggleOptions}
              onBlur={onBlur}
              onLayout={event => {
                getDimensions(event.nativeEvent.layout);
              }}
            >
              {collapsedView || selectedItems}
              {toggleIcon || (
                <IconButton
                  icon={'AngleDown'}
                  primary
                  containerStyle={css(iconContainerStyle)}
                  disabled
                />
              )}
            </TouchableOpacity>
          </View>
        }
        backgroundStyle={css(backgroundStyle)}
        isVisible={showOptions}
        onRequestClose={() => onRequestClosePopover()}
      >
        <View style={css(dropDownStyle({ theme, label }))}>
          <View style={css(dropDownInnerContainer)}>
            <View style={css(dropDownTitleContainerStyle)}>
              <Text style={css(dropDownTitleLabelStyle)}>{searchLabel}</Text>
            </View>

            {showSearch && (
              <SearchBar
                placeholder={searchPlaceHolder || ''}
                containerStyle={css(searchContainerStyle)}
                textInputStyle={css(searchTextInputStyle)}
                iconColor={theme.colors.paragraph}
                placeholderColor={theme.colors.paragraph}
                onChange={onSearchTextChange}
                showFindIcon={false}
              />
            )}
            <View style={css(containerForFlatListPreventOverflowStyle)}>
              <FlatList
                scrollEnabled={scrollEnabled}
                data={valuesFiltered}
                style={[
                  css(
                    flatListContentStyle({
                      theme,
                      height: 60 * maxItemsToShow,
                    }),
                  ),
                  contentStyle,
                ]}
                renderItem={({ item, index }): ReactElement => {
                  const isSelected =
                    (selectedValues &&
                      selectedValues.indexOf(item.value) !== -1) ||
                    false;
                  if (itemRender) return itemRender(item, isSelected, onChange);
                  return showCheckboxes ? (
                    <Button
                      title={item.label}
                      labelStyle={css(checkBoxTitleStyle)}
                      fluid
                      key={item.value}
                      onPress={(): Promise<void> =>
                        onChange(!item.isSelected, item.value)
                      }
                      iconPosition={'left'}
                      containerStyle={css(checkBoxContainerStyle)}
                      iconContainerStyle={
                        isSelected
                          ? css(checkIconContainer)
                          : css(unCheckContainer)
                      }
                      icon={isSelected ? 'check' : 'null'}
                      iconProps={{
                        color: theme.colors.success,
                        size: 15,
                      }}
                    />
                  ) : (
                    <Button
                      title={item.label}
                      labelStyle={css(checkBoxTitleStyle)}
                      fluid
                      key={index}
                      onPress={(): Promise<void> =>
                        onChange(!item.isSelected, item.value)
                      }
                      iconPosition={'right'}
                      containerStyle={css(checkBoxContainerStyle)}
                      iconContainerStyle={css(rightIconStyle)}
                      icon={'AngleRight'}
                      iconProps={{
                        color: theme.colors.paragraph,
                        size: 24,
                      }}
                    />
                  );
                }}
                ListFooterComponent={<FooterSpace />}
                keyExtractor={item => item.value}
              />
            </View>
            {onAddItem && searchString ? (
              <Button
                title={`Create '${searchString}'`}
                labelStyle={css(checkBoxTitleStyle)}
                fluid
                onPress={() => handleAddItem()}
                iconPosition={'right'}
                containerStyle={css(checkBoxContainerStyle)}
                iconContainerStyle={css(rightIconStyle)}
                icon={'plus'}
                iconProps={{
                  color: theme.colors.success,
                  size: 18,
                }}
              />
            ) : null}
          </View>
        </View>
      </Popover>
    </View>
  );
};

export default MultipleDropDown;
