import React, {
  useEffect,
  useCallback,
  useState,
  useRef,
  useMemo,
} from 'react';
import { Text, TouchableOpacity, View } from 'react-native';
import { Category } from '@oolio-group/domain';
import { useTranslation, useLocalization } from '@oolio-group/localization';
import { getLocaleEntity } from '@oolio-group/client-utils';
import { useCategories } from '../../../../hooks/app/categories/useCategories';
import { useNavigation } from '@react-navigation/native';
import { differenceWith, isEmpty, isEqual, sortBy } from 'lodash';
import { useNotification } from '../../../../hooks/Notification';
import ScreenLayout from '../../../../components/Office/ScreenLayout/ScreenLayout';
import Section from '../../../../components/Office/Section/Section';
import CreateButton from '../../../../components/Office/CreateButton/CreateButton';
import Search from '../../../../components/Shared/Search/Search';
import Icon from '../../../../components/Icon/Icon';
import theme from '../../../../common/default-theme';
import styles from './Categories.styles';

const validateDuplicationName = (
  categories: Category[],
): Record<string, boolean> => {
  const errors: Record<string, boolean> = {};
  const duplicateNameMap = categories.reduce((nameObj, current) => {
    const key = current.name?.toLowerCase().trim() || '';
    nameObj[key] = (nameObj[key] || 0) + 1;
    return nameObj;
  }, {} as Record<string, number>);
  categories.forEach(({ name, id }) => {
    const formattedName = name?.toLowerCase().trim() || '';
    if (duplicateNameMap[formattedName] > 1 || formattedName === '') {
      errors[id] = true;
    }
  });
  return errors;
};

const Categories: React.FC = () => {
  const { translate } = useTranslation();
  const navigation = useNavigation();
  const { locale } = useLocalization();
  const { showNotification } = useNotification();
  const [categoryMaps, setCategoryMaps] = useState<Record<string, Category>>(
    {},
  );
  const originalCategoryRef = useRef<Record<string, Category>>({});
  const [searchValue, setSearchValue] = useState('');

  const {
    getCategories,
    loading,
    error,
    categoryMaps: categoryMapsFromServer,
    batchUpdateCategories,
  } = useCategories({ nextFetchPolicy: 'cache-and-network' });

  useEffect(() => {
    getCategories();
  }, [getCategories]);

  const handleCreateCategory = useCallback((): void => {
    navigation.navigate('CategorySettings', {
      title: translate('backOfficeProducts.createCategory'),
    });
  }, [navigation, translate]);

  const onEditCategory = useCallback(
    (item: Category) => {
      navigation.navigate('CategorySettings', {
        categoryId: item.id,
        title: item.name,
      });
    },
    [navigation],
  );

  const onSearchValueChange = useCallback(value => {
    setSearchValue(value);
  }, []);

  const categories = useMemo(() => Object.values(categoryMaps), [categoryMaps]);

  const onPressSave = useCallback((): void => {
    const errors = validateDuplicationName(categories);
    if (isEmpty(errors)) {
      const categoriesToUpdate = differenceWith(
        categories,
        Object.values(originalCategoryRef.current),
        isEqual,
      ).map(({ name, id }) => ({ id, name }));
      batchUpdateCategories(categoriesToUpdate);
    } else {
      const hasEmptyValue = categories.find(
        category => !category?.name?.trim(),
      );
      const message = hasEmptyValue
        ? 'backOfficeProducts.emptyCategoryNameValidation'
        : 'backOfficeProducts.duplicatedCategoryName';
      showNotification({
        message: translate(message),
        error: true,
      });
    }
  }, [categories, batchUpdateCategories, showNotification, translate]);

  useEffect(() => {
    setCategoryMaps(categoryMapsFromServer);
    originalCategoryRef.current = categoryMapsFromServer;
    setSearchValue('');
  }, [categoryMapsFromServer]);

  useEffect(() => {
    if (!error) return;
    showNotification({ error: true, message: error });
  }, [error, showNotification]);

  const filteredCategoryIds = useMemo(() => {
    if (!Object.values(categoryMaps).length) return [];
    const sortedCategories = sortBy(
      Object.values(originalCategoryRef.current),
      category => (category?.name || '').toLowerCase(),
    );

    return sortedCategories
      .filter(category =>
        category.name.toLowerCase().includes((searchValue || '').toLowerCase()),
      )
      .map(category => category.id);
  }, [categoryMaps, searchValue]);

  return (
    <ScreenLayout
      loading={loading}
      hideFooter
      title="Categories | Oolio"
      onSave={onPressSave}
    >
      <Section
        title={translate('backOfficeProducts.categories')}
        subtitle={translate('backOfficeProducts.categoryDescription')}
      >
        <View style={styles.filters}>
          <Search
            testID="search-categories"
            placeholder={translate(
              'backOfficeProducts.searchCategoriesPlaceHoler',
            )}
            onChangeText={e => onSearchValueChange(e)}
            containerStyle={styles.search}
          />
          <CreateButton onPress={handleCreateCategory} />
        </View>
        <View>
          <View style={theme.tables.header}>
            <Text style={[theme.tables.headerText, styles.headerCategory]}>
              {`${translate('backOfficeProducts.category')} (${translate(
                'backOfficeProducts.categoryProducts',
              )})`}
            </Text>
          </View>
          <View>
            {filteredCategoryIds.map((id, i: number) => {
              const category = categoryMaps[id];
              return (
                <TouchableOpacity
                  key={i}
                  onPress={onEditCategory.bind(null, category)}
                  style={theme.tables.row}
                >
                  <Text
                    testID="text-name"
                    numberOfLines={1}
                    style={styles.cellCategory}
                  >
                    {`${getLocaleEntity(category, locale.languageTag)?.name} (${
                      category?.products?.length + category?.variants?.length
                    })`}
                  </Text>
                  <View style={theme.tables.disclosure}>
                    <Icon
                      size={20}
                      name="angle-right"
                      color={theme.colors.grey5}
                    />
                  </View>
                </TouchableOpacity>
              );
            })}
          </View>
        </View>
      </Section>
    </ScreenLayout>
  );
};

export default Categories;
