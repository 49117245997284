import { gql } from '@apollo/client';

const worklogFragment = `
  isWorking
  lastWorklog {
    status
    workTime
    breakTime
    totalTime
    clockInTime
    clockOutTime
    breaks {
      start
      end
    }
  }
`;

export const FRAGMENTS = {
  storeDetails: gql`
    fragment StoreDetails on Store {
      id
      name
      address {
        line1
        line2
        city
        state
        postalCode
        country
      }
      phoneNumber
      email
      businessIdentifier
      emailPreferences {
        emailType
        isEnabled
        emails
      }
      inventoryOptions {
        allowSellingWithNegativeInventory
      }
      shiftType
      showHourlySplit
      merchantCode
      operatingHours {
        id
        name
        timeBlocks {
          isActive
          day
          timeSlots {
            startTime
            endTime
          }
        }
      }
    }
  `,
  profitMargins: gql`
    fragment ProfitMargins on Store {
      profitMargins {
        margin
        productTypeId
      }
    }
  `,
};

export const GET_STORE_BY_ID_QUERY = gql`
  query storeById($id: ID!) {
    store(id: $id) {
      ...StoreDetails
      ...ProfitMargins
      venue {
        id
      }
      catalogues {
        id
        name
        catalogueType
      }
      pricingGroups {
        id
        name
      }
      devices {
        id
        name
      }
    }
  }
  ${FRAGMENTS.storeDetails}
  ${FRAGMENTS.profitMargins}
`;

export const GET_MERCHANT_DETAIL_STORE_QUERY = gql`
  query storeById($id: ID!) {
    store(id: $id) {
      id
      name
      merchantCode
    }
  }
`;

export const GET_STORE_VENUE_ORG_SLUGS_BY_STORE = gql`
  query store($storeId: ID!) {
    store(id: $storeId) {
      id
      name
      slug
      venue {
        id
        name
        slug
        organization {
          id
          name
          urlFriendlyName
        }
      }
    }
  }
`;

// FIXME: for some reason this fragments part is not working with
// getStores -> sets stores data when query is successful test case
// for now added the fields directly in here
export const GET_STORES_QUERY = gql`
  query stores {
    me {
      stores {
        id
        name
        address {
          line1
          line2
          city
          state
          postalCode
          country
        }
        phoneNumber
        email
        businessIdentifier
        emailPreferences {
          emailType
          isEnabled
          emails
        }
        showHourlySplit
        inventoryOptions {
          allowSellingWithNegativeInventory
        }
        shiftType
        merchantCode
        checkoutOptions {
          enableRoundOff
          enableTips
          roundOffValue
        }
        operatingHours {
          id
          name
          timeBlocks {
            isActive
            day
            timeSlots {
              startTime
              endTime
            }
          }
        }
        venue {
          id
        }
        catalogues {
          id
          name
        }
        pricingGroups {
          id
          name
        }
      }
    }
  }
`;

export const GET_MINIMAL_STORES_QUERY = gql`
  query stores {
    me {
      stores {
        id
        name
      }
    }
  }
`;

export const GET_STORE_DETAILS = gql`
  query storeDetails($id: ID!) {
    store(id: $id) {
      ...StoreDetails
    }
  }
  ${FRAGMENTS.storeDetails}
`;

export const UPDATE_STORE_DETAILS = gql`
  mutation updateStoreDetails($input: UpdateStoreDetailsInput!) {
    updateStoreDetails(input: $input) {
      id
      name
      showHourlySplit
      address {
        line1
        line2
        city
        state
        postalCode
        country
      }
      phoneNumber
      email
      businessIdentifier
      emailPreferences {
        emailType
        isEnabled
        emails
      }
    }
  }
`;
export const UPDATE_STORE_CHECKOUT_OPTIONS = gql`
  mutation updateStoreCheckoutOptions(
    $input: UpdateStoreCheckoutOptionsInput!
  ) {
    updateStoreCheckoutOptions(input: $input) {
      id
      checkoutOptions {
        enableRoundOff
        roundingMethod
        tipCalculation
        withdrawTipAtClosureTime
        enableTips
        roundOffValue
      }
    }
  }
`;

export const GET_CHECKOUT_OPTIONS_QUERY = gql`
  query checkoutByStoreId($id: ID!) {
    store(id: $id) {
      checkoutOptions {
        enableRoundOff
        roundingMethod
        tipCalculation
        withdrawTipAtClosureTime
        enableTips
        roundOffValue
      }
    }
  }
`;

export const CREATE_STORE = gql`
  mutation createStore($input: CreateStoreInput!) {
    createStore(input: $input) {
      id
      name
    }
  }
`;

export const UPDATE_STORE = gql`
  mutation updateStore($input: UpdateStoreInput!) {
    updateStore(input: $input) {
      ...StoreDetails
      ...ProfitMargins
    }
  }
  ${FRAGMENTS.storeDetails}
  ${FRAGMENTS.profitMargins}
`;

export const UPDATE_STORE_PROFIT_MARGINS = gql`
  mutation updateStoreProfitMargins($input: UpdateStoreProfitMarginsInput!) {
    updateStoreProfitMargins(input: $input) {
      id
      ...ProfitMargins
    }
  }
  ${FRAGMENTS.profitMargins}
`;

export const DELETE_STORE = gql`
  mutation deleteStore($id: ID!) {
    deleteStore(id: $id)
  }
`;

export const GET_STORE_USERS_QUERY = gql`
  query storeUsers($storeId: ID!) {
    store(id: $storeId) {
      id
      users {
        id
        pin
        name
        skipPin
        roles {
          role {
            id
          }
          id
          overridePermissions {
            id
            operations
          }
          apps {
            BACKOFFICE
            POS_APP
          }
        }
        ${worklogFragment}
      }
    }
  }
`;
