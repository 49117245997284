import React, { useCallback } from 'react';
import { TableStyles } from '../styles/Component.styles';
import IconButton from '../../../../components/Button/IconButton';

interface TableRowPreviewProps {
  onTableRowPress: () => void;
}

export const TableRowPreview: React.FC<TableRowPreviewProps> = ({
  onTableRowPress,
}) => {
  const styles = TableStyles();

  const previewData = useCallback(() => {
    onTableRowPress();
  }, [onTableRowPress]);

  return (
    <IconButton
      testID="preview-table-row"
      icon={'angle-right'}
      containerSize={30}
      containerStyle={styles.angleButtonStyle}
      iconStyle={styles.angleIconStyle}
      iconSize={25}
      onPress={() => previewData()}
    />
  );
};
