import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { View } from 'react-native';
import {
  ProductPricing as ProductPricingDefault,
  Product,
} from '@oolio-group/domain';
import { useTranslation } from '@oolio-group/localization';
import { useProducts } from '../../../../../../hooks/app/products/useProducts';
import debounce from 'lodash/debounce';
import styles from '../../PriceLists.styles';
import SelectSearch from '../../../../../../components/Shared/Select/SelectSearch';
import ButtonActions from '../../../../../../components/Shared/TreatButton/ButtonActions';

export interface ProductPricing extends ProductPricingDefault {
  isSelected?: boolean;
  isChanged?: boolean;
  isNew?: boolean;
  tax?: string;
  price?: string;
  productId: string;
}

interface Data {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [k: string]: any;
}

interface ProductsFiltersProps {
  isDefault?: boolean;
  defaultPricing: Record<string, ProductPricing>;
  onPressAdd: (data: Data) => void;
  onPressAddAll: (data: ProductPricing[]) => void;
  setPrices: () => void;
  modifyPrices: () => void;
  resetPrices: () => void;
}

export const ProductsFilters: React.FC<ProductsFiltersProps> = ({
  defaultPricing,
  onPressAdd,
  onPressAddAll,
  setPrices,
  modifyPrices,
  resetPrices,
}) => {
  const { products, getProductsByName, loading } = useProducts();
  const { translate } = useTranslation();
  const [productData, setProductData] = useState<Record<string, Product>>({});

  useEffect(() => {
    if (products) {
      setProductData(products);
    }
  }, [products]);

  const searchProduct = debounce((productName: string) => {
    if (productName) {
      getProductsByName(productName);
    }
  }, 500);

  const productOptions = useMemo(() => {
    return Object.values(productData).map((product: Product) => ({
      title: product.name,
      value: product.id,
      subtitle: product.category?.name || 'Product',
    }));
  }, [productData]);

  const onSearchTextChange = useCallback(
    (value: string) => {
      searchProduct(value);
    },
    [searchProduct],
  );

  const handlePress = useCallback(() => {
    setProductData({});
  }, []);

  const handleCreate = useCallback(
    (productId: string) => {
      onPressAdd({ ...defaultPricing[productId] });
      handlePress();
    },
    [defaultPricing, onPressAdd, handlePress],
  );

  const handleAddAllProduct = useCallback(() => {
    onPressAddAll(Object.values(defaultPricing));
  }, [defaultPricing, onPressAddAll]);

  return (
    <View style={styles.filters}>
      <SelectSearch
        testID="search-products"
        placeholder={translate('pricings.searchProductByName')}
        options={[
          {
            title: 'All Products',
            value: 'all',
            subtitle: '',
          },
          ...productOptions,
        ]}
        onSearchChange={e => onSearchTextChange(e)}
        onChangeOption={(s, i) =>
          i.value === 'all' ? handleAddAllProduct() : handleCreate(i.value)
        }
        containerStyle={styles.search}
        loading={loading}
      />
      <ButtonActions
        type="neutral"
        label={translate('pricings.bulkOptions')}
        actions={[
          { label: 'Set Price', action: setPrices },
          { label: 'Modify Price', action: modifyPrices },
          { label: 'Reset Price', action: resetPrices },
        ]}
      />
    </View>
  );
};
