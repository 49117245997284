import React from 'react';
import {
  View,
  Text,
  ActivityIndicator,
  ScrollView,
  TouchableOpacity,
} from 'react-native';
import { Reservation } from '@oolio-group/domain';
import styles from './ReservationSidePanel.styles';
import theme from '../../../common/default-theme';
import Icon from '../../Icon/Icon';
import Sticker from '../../Shared/Sticker/Sticker';
import { useTranslation } from '@oolio-group/localization';
import { formatReservationTimeAndDate } from '../../../screens/POS/Reservations/utils';
import { capitalCase } from 'change-case';

export interface ReservationSidePanelProps {
  loading?: boolean;
  reservation: Reservation;
  showPanel: boolean;
  disableBtn?: boolean;
  onClose: () => void;
  onOrderView?: (orderId: string) => void;
}

interface DetailsTileProps {
  title: string;
  value: string | number;
  testID: string;
}

export const DetailRow: React.FC<DetailsTileProps> = ({
  title,
  value,
  testID,
}) => {
  return (
    <View style={styles.row}>
      <Text style={styles.rowTitle}>{title}</Text>
      <Text testID={testID} style={styles.rowValue}>
        {value}
      </Text>
    </View>
  );
};

const ReservationSidePanel: React.FC<ReservationSidePanelProps> = ({
  loading,
  reservation,
  showPanel,
  onClose,
}: ReservationSidePanelProps) => {
  const safeHeight = theme.useSafeHeight();
  const { translate } = useTranslation();
  const { reservationDateTime } = formatReservationTimeAndDate(
    reservation.real_datetime_of_slot,
    reservation.duration,
  );
  const detailsData: DetailsTileProps[] = [
    {
      testID: 'reservation-duration',
      title: translate('reservations.reservationTime'),
      value: reservationDateTime,
    },
    {
      testID: 'reservation-status',
      title: translate('reservations.status'),
      value: capitalCase(reservation.status || ''),
    },
    {
      testID: 'reservation-note',
      title: translate('reservations.reservationNote'),
      value: reservation.notes || '-',
    },
    {
      testID: 'reservation-deposit',
      title: translate('reservations.deposit'),
      value: reservation.deposit || '-',
    },
    {
      testID: 'reservation-customerGuests',
      title: translate('reservations.customerGuests'),
      value: `${reservation.full_name} (x ${reservation.max_guests})`,
    },
    {
      testID: 'reservation-customerContact',
      title: translate('reservations.customerContact'),
      value: `${reservation.phone_number}\n${reservation.email}`,
    },
    {
      testID: 'reservation-integration',
      title: translate('reservations.integration'),
      value: 'sevenrooms',
    },
  ];

  if (!showPanel) {
    return <></>;
  }

  return (
    <View style={[styles.container, { height: safeHeight }]}>
      {loading ? (
        <View style={styles.placeholder}>
          <ActivityIndicator size={20} color={theme.colors.grey5} />
        </View>
      ) : reservation ? (
        <>
          <View style={styles.title}>
            <TouchableOpacity
              testID="btn-close"
              style={styles.btnClose}
              onPress={onClose}
            >
              <Icon name="times" size={20} color={theme.colors.dark} />
            </TouchableOpacity>
            <Text testID="reservation-no" style={styles.titleText}>
              {reservation?.reference_code}
            </Text>
            <Sticker
              testID="sticker-tableNo"
              type={'primaryLight'}
              label={
                reservation?.table_numbers
                  ? reservation.table_numbers.join(', ')
                  : ''
              }
              containerStyle={styles.tableNo}
            />
          </View>
          <ScrollView style={styles.content}>
            <View testID="reservation-details">
              {detailsData.map((data, index) => (
                <DetailRow
                  testID={data.testID}
                  title={data.title}
                  value={data.value}
                  key={`${index} ${data.value}`}
                />
              ))}
            </View>
          </ScrollView>
        </>
      ) : null}
    </View>
  );
};

export default ReservationSidePanel;
