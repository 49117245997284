import { useTranslation } from '@oolio-group/localization';
import { RenderProps, StyleFn, VoidReason } from '@oolio-group/domain';
import React, { useCallback, useMemo, useState } from 'react';
import { FelaComponent, useFela } from 'react-fela';
import { StyleSheet, View, ViewProps, ViewStyle, Text } from 'react-native';
import { useNotification } from '../../hooks/Notification';
import IconButton, { IconButtonProps } from '../Button/IconButton';
import InputDisplay from '../InputDisplay/InputDisplay';
import NumberInput from '../NumberInput/NumberInput';
import { getCounterKeyPadMaxValue } from '../../utils/validator';
import DropDown from '../FormInput/DropDown';
export interface CounterKeypadProps {
  value?: number;
  minValue?: number;
  maxLength?: number;
  title: React.ReactNode;
  containerStyle?: ViewStyle;
  onSubmit: (value: number, reason?: VoidReason) => void;
  maxNumberOfDigit?: number;
  showReason?: boolean;
}

const styles = StyleSheet.create({
  numberInput: {
    width: '100%',
  },
  inputDisplay: {
    height: 100,
  },
});

const fullWidth: StyleFn = ({ theme }) => ({
  justifyContent: 'space-between',
  width: '100%',
  height: theme.input.height,
  alignSelf: 'center',
});

const inputDisplayStyle: StyleFn = ({ theme }) => ({
  height: 100,
  marginBottom: theme.spacing.small,
});

const keypadRowStyle: StyleFn = () => ({
  marginTop: '2%',
});

const cancelKeyStyle: StyleFn = ({ theme }) => ({
  backgroundColor: theme.colors.danger2,
  borderWidth: 0,
});

const successKeyStyle: StyleFn = ({ theme }) => ({
  backgroundColor: theme.colors.successLight,
  borderWidth: 0,
});

const counterButtonStyle: StyleFn = ({ theme }) => ({
  width: 44,
  height: 44,
  borderRadius: theme.radius.small,
  backgroundColor: theme.colors.primaryDarkest,
});

interface CounterButtonProps extends ViewProps {
  testID?: string;
  icon: string;
  onPress: IconButtonProps['onPress'];
}

const CounterButton: React.FC<CounterButtonProps> = ({
  icon,
  onPress,
  testID,
}) => (
  <FelaComponent style={counterButtonStyle}>
    {({ style }: RenderProps) => (
      <IconButton
        icon={icon}
        testID={testID}
        onPress={onPress}
        containerStyle={style}
      />
    )}
  </FelaComponent>
);

export const dropdownWrapper: StyleFn = ({ theme }) => ({
  width: '100%',
  backgroundColor: theme.colors.danger2,
  height: 90,
  padding: theme.padding.medium,
  justifyContent: 'center',
  alignItems: 'center',
  alignSelf: 'center',
  borderRadius: theme.radius.small,
});

export const dropdownViewStyle: StyleFn = ({ theme }) => ({
  alignSelf: 'center',
  justifyContent: 'center',
  width: '100%',
  borderRadius: theme.radius.small,
  ...theme.border.borderSolid,
});

export const labelTextStyle: StyleFn = ({ theme }) => ({
  paddingBottom: theme.padding.small,
  color: theme.colors.danger,
  textTransform: 'uppercase',
  ...theme.font14SemiBold,
});

export const textInputContainer: StyleFn = () => ({
  marginTop: 10,
  width: '100%',
  height: 150,
});

export const CounterKeypad: React.FC<CounterKeypadProps> = ({
  title,
  onSubmit,
  minValue,
  maxLength,
  containerStyle,
  value: defaultValue,
  maxNumberOfDigit = 9,
  showReason,
}) => {
  const { translate } = useTranslation();
  const [input, setInput] = useState('');
  const [value, setValue] = useState(defaultValue || 0);
  const { showNotification, closeAllNotifications } = useNotification();
  const [reason, setReason] = useState(VoidReason.OTHER);
  const { css } = useFela();

  const reasonOptions = useMemo(
    () =>
      Object.values(VoidReason).map(value => ({
        value,
        label: translate(`enums.${value}`),
      })),
    [translate],
  );

  const isReasonRequired = useMemo(() => {
    return defaultValue && value < defaultValue;
  }, [defaultValue, value]);

  const onPressClear = useCallback(() => {
    setInput('');
    setValue(defaultValue || 0);
  }, [defaultValue]);

  const onPressSubmit = useCallback(() => {
    if (value < (minValue || 0)) {
      showNotification({
        error: true,
        message: translate('order.minQuantityWarning', { minValue }),
      });
      setInput('');
    } else {
      if (isReasonRequired) {
        onSubmit(value, reason);
      } else {
        onSubmit(value);
      }
      closeAllNotifications();
    }
  }, [
    value,
    minValue,
    showNotification,
    translate,
    isReasonRequired,
    closeAllNotifications,
    onSubmit,
    reason,
  ]);

  const onPressKey = useCallback(
    (key: string): void => {
      if (value.toString().length < maxNumberOfDigit) {
        setInput(input => {
          const value = input + key;
          setValue(parseInt(value.substr(0, maxLength || Infinity)));
          return value;
        });
      }
    },
    [maxNumberOfDigit, maxLength, value],
  );

  const onIncrement = useCallback(() => {
    const maxValue = getCounterKeyPadMaxValue(maxNumberOfDigit);
    if (value < maxValue) {
      setInput('');
      setValue(value =>
        parseInt(`${value + 1}`.substr(0, maxLength || Infinity)),
      );
    }
  }, [maxLength, value, maxNumberOfDigit]);

  const onDecrement = useCallback(() => {
    setInput('');
    setValue(value => Math.max(minValue || 0, value - 1));
  }, [minValue]);

  return (
    <View style={containerStyle}>
      {title}
      <InputDisplay
        value={(input || value).toString()}
        left={
          <CounterButton
            icon="AngleDown"
            onPress={onDecrement}
            testID="decrement"
          />
        }
        right={
          <CounterButton
            icon="AngleUp"
            onPress={onIncrement}
            testID="increment"
          />
        }
        containerStyle={css(inputDisplayStyle)}
      />
      {showReason && isReasonRequired && (
        <View style={css(dropdownWrapper)}>
          <Text style={css(labelTextStyle)}>
            {translate('order.voidReasonLabel')}
          </Text>
          <DropDown
            fluid
            values={reasonOptions}
            selectedValue={reason}
            onValueChange={setReason}
            style={css(fullWidth)}
            extraStyle={css(fullWidth)}
            extraMainViewStyle={css(dropdownViewStyle)}
          />
        </View>
      )}
      <NumberInput
        keyWidth={82}
        keyHeight={70}
        onPressKey={onPressKey}
        rowStyle={css(keypadRowStyle)}
        containerStyle={styles.numberInput}
        keys={[
          { value: '1' },
          { value: '2' },
          { value: '3' },
          { value: '4' },
          { value: '5' },
          { value: '6' },
          { value: '7' },
          { value: '8' },
          { value: '9' },
          {
            name: 'times',
            danger: true,
            onPress: onPressClear,
            containerStyle: cancelKeyStyle,
          },
          { value: '0' },
          {
            name: 'check',
            success: true,
            onPress: onPressSubmit,
            containerStyle: successKeyStyle,
          },
        ]}
      />
    </View>
  );
};
