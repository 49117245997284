/* eslint-disable react-native/no-inline-styles */
import React, { useCallback } from 'react';
import { View } from 'react-native';
import { Tax } from '@oolio-group/domain';
import {
  useTranslation,
  getCountryCodeByCountryName,
  getCountry,
} from '@oolio-group/localization';
import { useModal } from '@oolio-group/rn-use-modal';
import ConfirmationDialog from '../../../../../../components/Modals/ConfirmationDialog';
import { useNotification } from '../../../../../../hooks/Notification';
import { isNotEmpty, isValidPostCode } from '../../../../../../utils/validator';
import { VenueDetail } from '../DetailsContainer';
import theme from '../../../../../../common/default-theme';
import ScreenLayout from '../../../../../../components/Office/ScreenLayout/ScreenLayout';
import Section from '../../../../../../components/Office/Section/Section';
import InputText from '../../../../../../components/Shared/Inputs/InputText';
import InputEmail from '../../../../../../components/Shared/Inputs/InputEmail';
import InputPhone from '../../../../../../components/Shared/Inputs/InputPhone';
import TreatPicker from '../../../../../../components/Shared/Select/Picker';

interface Props {
  testID?: string;
  form: VenueDetail;
  taxes: Tax[];
  onChange: (prop: string, value: string) => void;
  onChangeAddress: (prop: string, value: string) => void;
  onChangePrintOptions: (prop: string, value: boolean) => void;
  onChangeEmailReceipt: (value: string) => void;
  onPressDelete: () => void;
  onPressSave: () => void;
}

export const Details: React.FC<Props> = ({
  form,
  taxes,
  onChange,
  onChangeAddress,
  onPressDelete,
  onPressSave,
}) => {
  const { translate } = useTranslation();
  const { showNotification } = useNotification();
  const { showModal, closeModal } = useModal();
  const countryCode = getCountryCodeByCountryName(form.address?.country);

  const isUK = form.countryCode === 'GB';

  const onDelete = useCallback((): void => {
    showModal(
      <ConfirmationDialog
        title={translate('dialog.deleteTitle')}
        message={translate('dialog.deleteConfirmation', {
          label: form.name,
        })}
        onConfirm={() => {
          closeModal();
          onPressDelete();
        }}
      />,
    );
  }, [showModal, onPressDelete, closeModal, translate, form]);

  const onSave = useCallback((): void => {
    if (!form.name || !isNotEmpty(form.name)) {
      showNotification({
        error: true,
        message: translate('backOfficeSettings.VenueNameIsRequired'),
      });
      return;
    }
    onPressSave();
  }, [showNotification, translate, form, onPressSave]);

  return (
    <ScreenLayout
      title={`${form.name || 'Venue'} | Oolio`}
      onSave={onSave}
      onDelete={onDelete}
    >
      <Section title="Details">
        <View style={theme.forms.row}>
          <InputText
            testID="input-name"
            title={translate('form.venueName')}
            value={form.name || ''}
            placeholder={translate('form.venueName')}
            onChangeText={onChange.bind(null, 'name')}
            maxLength={50}
            containerStyle={theme.forms.inputHalf}
          />
          <TreatPicker
            testID="select-tax"
            title={translate('form.defaultTax')}
            options={taxes.map(value => ({
              value: value.id,
              label: value.name,
            }))}
            selectedValue={form.defaultTax || ''}
            onValueChange={onChange.bind(null, 'defaultTax')}
            containerStyle={theme.forms.inputHalf}
          />
        </View>
        <View style={theme.forms.row}>
          <InputEmail
            testID="input-email"
            title={translate('form.email')}
            value={form.email || ''}
            placeholder={translate('form.email')}
            onChangeText={onChange.bind(null, 'email')}
            containerStyle={theme.forms.inputHalf}
          />
          <InputPhone
            testID="input-phone"
            title={translate('form.phoneNumber')}
            value={form.phoneNumber || ''}
            defaultCountry={form.countryCode}
            placeholder={translate(
              isUK ? 'form.phoneNumberHintUK' : 'form.phoneNumberHint',
            )}
            onChangeText={text => onChange('phoneNumber', text)}
            onPressCountry={country => onChange('countryCode', country)}
            containerStyle={theme.forms.inputHalf}
          />
        </View>
        <View style={theme.forms.row}>
          <InputText
            testID="input-line1"
            title={translate(isUK ? 'form.streetAddress' : 'form.addressLine1')}
            value={form.address?.line1 || ''}
            placeholder={translate(
              isUK ? 'form.streetAddress' : 'form.addressLine1',
            )}
            onChangeText={onChangeAddress.bind(null, 'line1')}
            containerStyle={
              !isUK ? theme.forms.inputHalf : theme.forms.inputFluid
            }
          />
          {!isUK ? (
            <InputText
              testID="input-line2"
              title={translate('form.addressLine2')}
              value={form.address?.line2 || ''}
              placeholder="Optional"
              onChangeText={onChangeAddress.bind(null, 'line2')}
              containerStyle={theme.forms.inputHalf}
            />
          ) : null}
        </View>
        <View style={theme.forms.row}>
          <InputText
            testID="input-city"
            title={translate(isUK ? 'form.town' : 'form.city')}
            value={form.address?.city || ''}
            placeholder={translate(isUK ? 'form.town' : 'form.city')}
            onChangeText={onChangeAddress.bind(null, 'city')}
            containerStyle={theme.forms.inputHalf}
          />
          <InputText
            testID="input-state"
            title={translate(isUK ? 'form.county' : 'form.state')}
            value={form.address?.state || ''}
            placeholder={translate(isUK ? 'form.county' : 'form.state')}
            onChangeText={onChangeAddress.bind(null, 'state')}
            containerStyle={theme.forms.inputHalf}
          />
        </View>
        <View style={theme.forms.row}>
          <InputText
            testID="input-code"
            title={translate('form.postCode')}
            value={form.address?.postalCode || ''}
            placeholder={translate(
              isUK ? 'form.postalCodeHintUK' : 'form.postalCodeHint',
            )}
            onChangeText={onChangeAddress.bind(null, 'postalCode')}
            errorMessage={
              isValidPostCode(form.address?.postalCode, form.countryCode)
                ? undefined
                : 'Error'
            }
            containerStyle={theme.forms.inputHalf}
          />
          <TreatPicker
            testID="select-country"
            title={translate('form.country')}
            selectedValue={countryCode}
            options="countries"
            onValueChange={code =>
              onChangeAddress('country', getCountry(code)?.name)
            }
            containerStyle={theme.forms.inputHalf}
          />
        </View>
      </Section>
    </ScreenLayout>
  );
};
