import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { ActivityIndicator, Text, View } from 'react-native';
import {
  AddOrderSurcharge,
  AssignCustomerEvent,
  Customer,
  OrderAction,
  OrderPaymentStatus,
  PaymentAction,
  PaymentMode,
  PaymentSubScreen,
  PaymentType,
  DefaultPaymentTypes,
  Resource,
  FeatureIDs,
  Order,
  TempPaymentInfo,
  AdditionalPaymentInfo,
  PAYMENT_INPROGRESS_MESSAGES,
  OrderEvent,
  OnAccountEvent,
  UpdateOrderAdjustmentsEvent,
  UpdateCustomerRequest,
} from '@oolio-group/domain';
import {
  getAdjustmentValue,
  isOrderEditable,
  limitDecimalCount,
  sumDecimals,
} from '@oolio-group/order-helper';
import { useTranslation } from '@oolio-group/localization';
import { useModal } from '@oolio-group/rn-use-modal';
import { useRoute } from '@react-navigation/native';
import { v4 as uuidv4 } from 'uuid';
import { customAlphabet } from 'nanoid';
import { findLast, round } from 'lodash';
import { alphanumeric } from 'nanoid-dictionary';
import {
  DEFAULT_PAYMENT_CURRENCY,
  SKIP_PAYMENT_TYPES_ON_PAY_NOW,
} from '../../../types/Common';
import { userUtility } from '../../../state/userUtility';
import { stripProperties } from '../../../utils/stripObjectProps';
import { usePayments } from '../../../hooks/app/usePayments';
import { useSession } from '../../../hooks/app/useSession';
import { useNotification } from '../../../hooks/Notification';
import { usePaymentTypes } from '../../../hooks/app/usePaymentTypes';
import { useCartContext as useCart } from '../../../hooks/CartProvider';
import { useNetworkStatusVar } from '../../../hooks/app/useNetworkStatusVar';
import usePOSUserAuthorization from '../../../hooks/app/users/usePOSUserAuthorization';
import { useCheckFeatureEnabled } from '../../../hooks/app/features/useCheckFeatureEnabled';
import {
  OrderPaymentDetails,
  PaymentActionProp,
} from '../../../screens/POS/Payments/PaymentScreen';
import styles from './PaymentOptions.styles';
import SplitPayment from './SplitPayment';
import KeypadModal from '../Modals/Keypad/KeypadModal';
import SplitPaymentOptions from './SplitPaymentOptions';
import PaymentAmount from './PaymentAmount/PaymentAmount';
import PaymentHeader from './PaymentHeader/PaymentHeader';
import PaymentButtons from './PaymentButtons/PaymentButtons';
import TreatButton from '../../Shared/TreatButton/TreatButton';
import SplitBySeatOptions from './SplitBySeat/SplitBySeatOptions';
import SegmentControl from '../../Shared/SegmentControl/SegmentControl';
import PaymentSurcharge from '../Modals/PaymentSurcharge/PaymentSurcharge';
import EnableOnAccountModal from '../Modals/EnableOnAccount/EnableOnAccountModal';
import CustomerAccountIdentityModal from '../Modals/ConfirmAccountPayment/ConfirmAccountPaymentModal';

const nanoid = customAlphabet(alphanumeric, 10);

export const SPLIT_EQUAL_OPTIONS = [
  {
    splitNumber: 2,
    label: '2',
  },
  {
    splitNumber: 3,
    label: '3',
  },
  {
    splitNumber: 4,
    label: '4',
  },
  {
    splitNumber: undefined,
    label: 'Other',
  },
];

export interface PaymentOptionsProps {
  onPressPay?: (
    paymentType: PaymentType,
    amount: number,
    surchargeAmount?: number,
    customer?: Customer,
    additionalInfo?: AdditionalPaymentInfo,
    eventId?: string,
  ) => void;
  onPaymentProcessed?: (events: OrderEvent[]) => void;
  onAccountPaymentProcessed?: (events: OnAccountEvent[]) => void;
  onPaymentInitiate?: () => void;
  onPaymentCancel?: () => void;
  enableRoundOff?: boolean;
  roundOffValue?: number;
  customer?: Customer;
  orderPaymentDetails: OrderPaymentDetails;
  dispatch: React.Dispatch<PaymentActionProp>;
  customerBalancePayment?: boolean;
  customerAmount?: number;
  assignedCustomer?: Customer;
  updateCustomerOnAccount: (input: UpdateCustomerRequest) => Promise<void>;
  customerLoading?: boolean;
}

export interface OtherPaymentType extends PaymentType {
  color?: string;
  disabled?: boolean;
}

const PaymentOptions: React.FC<PaymentOptionsProps> = ({
  onPressPay,
  onPaymentProcessed,
  onAccountPaymentProcessed,
  onPaymentInitiate,
  onPaymentCancel,
  enableRoundOff,
  roundOffValue,
  customer,
  dispatch,
  customerAmount,
  orderPaymentDetails,
  assignedCustomer,
  customerLoading,
  updateCustomerOnAccount,
}) => {
  const paymentDetailsRef = useRef<TempPaymentInfo | undefined>(undefined);

  const route = useRoute();
  const [session] = useSession();
  const { translate } = useTranslation();
  const { canI } = usePOSUserAuthorization();
  const networkStatus = useNetworkStatusVar();
  const { showModal, closeModal } = useModal();
  const { showNotification } = useNotification();
  const isFeatureEnabled = useCheckFeatureEnabled();
  const { order, updateCart, openOrderCart } = useCart();
  const { paymentTypes, status } = usePaymentTypes({
    fetchPolicy: 'cache-first',
  });

  const [, setAssignedCustomer] = useState<Partial<Customer>>();

  const isLoyaltyEnabled = isFeatureEnabled(FeatureIDs.LOYALTY);
  const isCustomerAccountEnabled = isFeatureEnabled(
    FeatureIDs.CUSTOMER_ACCOUNT,
  );

  // Using useRef here because callback sent to showModal is getting previous state
  // State was not getting updated in the callback function sent to showModal
  const networkStatusRef = useRef<boolean>(true);
  networkStatusRef.current = networkStatus;
  const routeParams = route.params as {
    customerAmount: number;
    customerId: string;
    orderId: string;
  };

  const {
    loading: isPaymentLoading,
    cancellationLoading,
    error: paymentError,
    paymentResponse,
    cancellationResponse,
    initiatePayment,
    verifyPayment,
    resetPaymentResponse,
    cancelPayment,
    resetCancellationResponse,
  } = usePayments();

  const {
    paymentMode,
    processingAmount,
    remainingDueAmount,
    nthPayment,
    activeScreen,
    isCustomerBalancePayment,
    customerPaymentTransactionId,
    isSplitPayment,
    isSplitBySeat,
    nthSplitPayment,
    numberOfSplitPayment,
  } = orderPaymentDetails;

  const otherPaymentTypes: OtherPaymentType[] = useMemo(
    () =>
      paymentTypes
        ?.filter(
          (x: { name: string }) =>
            (!SKIP_PAYMENT_TYPES_ON_PAY_NOW.includes(x.name) &&
              x.name !== DefaultPaymentTypes.ON_ACCOUNT) ||
            (x.name === DefaultPaymentTypes.ON_ACCOUNT &&
              isCustomerAccountEnabled),
        )
        .map(paymentType => {
          const disabled =
            isCustomerBalancePayment &&
            paymentType.name === OrderAction.ON_ACCOUNT_PAYMENT_TYPE;
          return { ...paymentType, disabled };
        }) || [],
    [isCustomerBalancePayment, paymentTypes, isCustomerAccountEnabled],
  );

  const getPaymentTypeByName = useCallback(
    (name: string): PaymentType => {
      return paymentTypes.find(
        (paymentType: { name: string }) =>
          paymentType.name.toLowerCase() == name.toLowerCase(),
      ) as PaymentType;
    },
    [paymentTypes],
  );

  useEffect((): void => {
    if (status.error) {
      showNotification({
        error: true,
        message: status.error,
      });
    }
  }, [status.error, showNotification]);

  useEffect((): void => {
    if (paymentError) {
      showNotification(
        {
          error: true,
          message: paymentError,
        },
        false,
      );
    }
  }, [paymentError, showNotification]);

  const applySurcharge = useCallback(
    (paymentType, surcharge) => {
      if (isCustomerBalancePayment) {
        onPressPay && onPressPay(paymentType, customerAmount as number);
        return;
      }
      if (order && surcharge?.amount) {
        updateCart<AddOrderSurcharge>(OrderAction.ORDER_ADD_ADJUSTMENT, {
          adjustment: surcharge,
        });
        const surchargeAmount = getAdjustmentValue(
          processingAmount,
          [surcharge],
          { adjustmentType: surcharge.adjustmentType },
        );

        onPressPay &&
          onPressPay(
            paymentType,
            processingAmount + surchargeAmount,
            surchargeAmount,
          );
      } else {
        onPressPay && onPressPay(paymentType, processingAmount);
      }
    },
    [
      isCustomerBalancePayment,
      order,
      onPressPay,
      customerAmount,
      updateCart,
      processingAmount,
    ],
  );

  const checkAllowAdjustmentPermission = useCallback(() => {
    const allowAdjustment = canI([{ onResource: Resource.ALLOW_ADJUSTMENTS }], {
      prompt: true,
    });
    return allowAdjustment;
  }, [canI]);

  const onPressPaymentOption = useCallback(
    (paymentTypeName: string, amount: number) => {
      const paymentType = getPaymentTypeByName(paymentTypeName);
      const paymentTypeInput = stripProperties(
        { ...paymentType },
        'label',
        'value',
      );
      closeModal();
      if (paymentType.adjustmentPrompt) {
        showModal(
          <PaymentSurcharge
            orderTotal={processingAmount || (customerAmount as number)}
            onSubmit={applySurcharge}
            paymentType={paymentTypeInput}
            checkAllowAdjustmentPermission={checkAllowAdjustmentPermission}
          />,
        );
      } else {
        onPressPay && onPressPay(paymentTypeInput, amount || 0, 0, customer);
      }
    },
    [
      getPaymentTypeByName,
      closeModal,
      showModal,
      processingAmount,
      customerAmount,
      applySurcharge,
      checkAllowAdjustmentPermission,
      onPressPay,
      customer,
    ],
  );

  const customerAccountSettings =
    session?.currentVenue?.customerAccountSettings;

  const assignCustomerToOrder = useCallback(
    customer => {
      updateCart<AssignCustomerEvent>(OrderAction.ORDER_ASSIGN_CUSTOMER, {
        customerId: customer.id,
        firstName: customer.firstName,
        lastName: customer.lastName,
        email: customer.email,
        phone: customer.phone,
        loyaltyMember: customer.loyaltyMember,
        customerAccountDetails: {
          accountPayment: true,
          currentBalance: customer?.customerAccountDetails?.currentBalance,
          maxOrderLimit:
            customer?.customerAccountDetails?.maxOrderLimit ||
            customerAccountSettings?.defaultMaxOrderLimit ||
            100,
          maxBalanceLimit:
            customer?.customerAccountDetails?.maxBalanceLimit ||
            customerAccountSettings?.defaultMaxBalanceLimit ||
            1000,
        },
        isLoyaltyApplied: isLoyaltyEnabled && customer.loyaltyMember,
      });
      setAssignedCustomer(customer);
    },
    [
      customerAccountSettings?.defaultMaxBalanceLimit,
      customerAccountSettings?.defaultMaxOrderLimit,
      isLoyaltyEnabled,
      updateCart,
    ],
  );

  const reAssignCustomerToOrder = useCallback((): void => {
    // trigger assign order to customer event
    assignCustomerToOrder(order?.customer);
  }, [assignCustomerToOrder, order?.customer]);

  const onUpdateProcessingAmount = useCallback(
    (type: 'SET' | 'UPDATE') => {
      const onSubmitAmount = (amount: number) => {
        if (amount === 0) {
          showNotification({
            message: translate('payment.amountCannotBeZero'),
            error: true,
          });
        } else if (amount > remainingDueAmount) {
          showNotification({
            message: translate('payment.amountCannotGreaterThanDueAmount'),
            error: true,
          });
        } else {
          dispatch({
            type:
              type == 'SET'
                ? PaymentAction.SET_PAYMENT_AMOUNT
                : PaymentAction.UPDATE_PAYMENT_AMOUNT,
            payload: { processingAmount: amount },
          });
          closeModal();
        }
      };

      const step = type == 'SET' ? nthPayment + 1 : nthPayment;

      return showModal(
        <KeypadModal
          title={
            step
              ? translate('payment.setSplitPaymentStep', { step })
              : translate('payment.setPaymentAmount')
          }
          mode="currency"
          onConfirm={onSubmitAmount}
          onDismiss={closeModal}
          remainingAmount={remainingDueAmount}
        />,
      );
    },
    [
      nthPayment,
      showModal,
      translate,
      closeModal,
      remainingDueAmount,
      showNotification,
      dispatch,
    ],
  );

  const allowOnAccountAmount = useMemo(() => {
    const { currentBalance = 0, maxBalanceLimit = 0 } =
      order?.customer?.customerAccountDetails || {};
    return sumDecimals([maxBalanceLimit, -currentBalance]);
  }, [order?.customer?.customerAccountDetails]);

  const allowOnAccountAmountOrderLimit = useMemo(() => {
    const { maxOrderLimit = 0 } = order?.customer?.customerAccountDetails || {};
    return maxOrderLimit;
  }, [order?.customer?.customerAccountDetails]);

  const onChangeOption = useCallback(
    (paymentMode: string) => {
      dispatch({
        type: PaymentAction.UPDATE_PAYMENT_MODE,
        payload: {
          paymentMode: paymentMode as PaymentMode,
        },
      });
    },
    [dispatch],
  );

  const onSubmitPaymentByOnAccount = useCallback(() => {
    onPressPaymentOption(OrderAction.ON_ACCOUNT_PAYMENT_TYPE, processingAmount);
  }, [onPressPaymentOption, processingAmount]);

  const handleOnPressOnAccount = useCallback(() => {
    if (!order?.customer) {
      showNotification({
        message: translate('payment.notificationSelectCustomer'),
        error: true,
      });
      return;
    }
    const onAccountPaidAmount = limitDecimalCount(
      order.payments.reduce((accumulator, p) => {
        return sumDecimals([
          p.paymentType?.name === DefaultPaymentTypes.ON_ACCOUNT ? p.amount : 0,
          accumulator,
        ]);
      }, processingAmount),
    );

    if (!assignedCustomer?.customerAccountDetails?.accountPayment) {
      showModal(
        <EnableOnAccountModal
          order={order as Order}
          onConfirm={() => {
            closeModal();
            reAssignCustomerToOrder();
          }}
          loading={customerLoading}
          updateCustomerOnAccount={updateCustomerOnAccount}
        />,
      );
    } else if (
      onAccountPaidAmount <= allowOnAccountAmount &&
      onAccountPaidAmount <=
        (allowOnAccountAmountOrderLimit || allowOnAccountAmount)
    ) {
      showModal(
        <CustomerAccountIdentityModal
          customer={assignedCustomer}
          processingAmount={processingAmount}
          onConfirmPay={onSubmitPaymentByOnAccount}
        />,
      );
    } else {
      showNotification({
        message: translate('payment.onAccountLimitNotification', {
          customerName: order.customer.firstName,
        }),
        error: true,
      });
    }
  }, [
    allowOnAccountAmount,
    allowOnAccountAmountOrderLimit,
    closeModal,
    onSubmitPaymentByOnAccount,
    order,
    processingAmount,
    reAssignCustomerToOrder,
    showModal,
    showNotification,
    translate,
    assignedCustomer,
    updateCustomerOnAccount,
    customerLoading,
  ]);

  const ifNoNetwork = useCallback(() => {
    if (!networkStatusRef.current) {
      showNotification({
        error: true,
        message: translate('settings.noInternet'),
      });
      return true;
    }
    return false;
  }, [showNotification, translate]);

  useEffect(() => {
    if (cancellationResponse) {
      showNotification({
        success: true,
        message: translate('payment.oolioPayCancellationSuccess'),
      });

      resetCancellationResponse();
    }
  }, [
    cancellationResponse,
    resetCancellationResponse,
    showNotification,
    translate,
  ]);

  useEffect(() => {
    if (paymentResponse) {
      // TODO: Cancel original "initiatePayment" request, if success is provided by Verify.
      const isPaymentStillInProgress = PAYMENT_INPROGRESS_MESSAGES.includes(
        paymentResponse.message,
      );

      if (!paymentResponse.success) {
        showNotification(
          {
            ...(!isPaymentStillInProgress && { error: true }),
            ...(isPaymentStillInProgress && { info: true }),
            message: paymentResponse.message,
          },
          false,
        );
      }

      resetPaymentResponse();

      if (!isPaymentStillInProgress) {
        if (!isCustomerBalancePayment) {
          onPaymentProcessed &&
            onPaymentProcessed(paymentResponse.events as OrderEvent[]);
        } else {
          onAccountPaymentProcessed &&
            onAccountPaymentProcessed(
              paymentResponse.events as OnAccountEvent[],
            );
        }

        if (!isPaymentLoading) paymentDetailsRef.current = undefined;
      }
    }
  }, [
    getPaymentTypeByName,
    isCustomerBalancePayment,
    isPaymentLoading,
    onAccountPaymentProcessed,
    onPaymentProcessed,
    onPressPaymentOption,
    paymentResponse,
    processingAmount,
    resetPaymentResponse,
    showNotification,
    translate,
    updateCart,
  ]);

  const verifyOolioPayment = useCallback(() => {
    if (!ifNoNetwork()) {
      verifyPayment({
        deviceId: session.device?.deviceCode as string,
        terminalId: session.device?.paymentTerminal?.uuid as string,
        requestId: paymentDetailsRef.current?.requestId as string,
        orderInfo: {
          orderId: paymentDetailsRef.current?.orderId as string,
          lastEventId: order?.prevEventId as string,
          activeUser: (userUtility.posUser?.id ||
            userUtility?.recentUserId) as string,
        },
      });
    }
  }, [ifNoNetwork, order?.prevEventId, session.device, verifyPayment]);

  const pendingPayment = useMemo(() => {
    if (
      order?.payments &&
      order?.payments?.length > 0 &&
      isOrderEditable(order?.status)
    ) {
      const pendingPayment = order?.payments.find(
        payment =>
          payment.paymentType.name !== OrderAction.ON_ACCOUNT_PAYMENT_TYPE &&
          payment.status === OrderPaymentStatus.PENDING,
      );

      if (pendingPayment) {
        paymentDetailsRef.current = {
          paymentType: pendingPayment.paymentType as PaymentType,
          orderAmount: pendingPayment.amount,
          requestId: pendingPayment.paymentRequestId,
          orderId: order?.id,
        };

        if (!isPaymentLoading) onPaymentInitiate && onPaymentInitiate();
      }

      return pendingPayment;
    }

    return undefined;
  }, [
    isPaymentLoading,
    onPaymentInitiate,
    order?.id,
    order?.payments,
    order?.status,
  ]);

  const initiateOolioPayment = useCallback(() => {
    if (!ifNoNetwork()) {
      if (pendingPayment) {
        paymentDetailsRef.current = {
          paymentType: pendingPayment.paymentType as PaymentType,
          orderAmount: pendingPayment.amount,
          requestId: pendingPayment.paymentRequestId,
          orderId: order?.id as string,
        };

        showNotification({
          info: true,
          message: translate('payment.verifyPendingPayment'),
        });

        setTimeout(() => {
          if (!isPaymentLoading) verifyOolioPayment();
        }, 2000);
      }

      onPaymentInitiate && onPaymentInitiate();

      if (pendingPayment) return;

      const requestId = nanoid();
      const newEventId = uuidv4();

      initiatePayment({
        deviceId: session.device?.deviceCode as string,
        terminalId: session.device?.paymentTerminal?.uuid as string,
        requestId: requestId,
        transactionId: isCustomerBalancePayment
          ? (customerPaymentTransactionId as string)
          : (order?.orderNumber as string),
        amount: paymentDetailsRef.current?.orderAmount as number,
        currency:
          session.currentOrganization?.currencyCode || DEFAULT_PAYMENT_CURRENCY,
        includeTips: session.currentStore?.checkoutOptions?.enableTips,
        ...(!isCustomerBalancePayment
          ? {
              orderInfo: {
                orderId: paymentDetailsRef.current?.orderId as string,
                lastEventId: newEventId,
                activeUser: (userUtility.posUser?.id ||
                  userUtility?.recentUserId) as string,
                lastEventTimestamp: Date.now(),
                integrationApp: order?.integrationInfo?.app,
              },
            }
          : {
              customerPaymentInfo: {
                activeUser: (userUtility.posUser?.id ||
                  userUtility?.recentUserId) as string,
                customerId: routeParams.customerId,
                lastEventTimestamp: Date.now(),
                ...(routeParams?.orderId && { orderId: routeParams.orderId }),
              },
            }),
      });

      onPressPay &&
        onPressPay(
          paymentDetailsRef.current?.paymentType as PaymentType,
          paymentDetailsRef.current?.orderAmount as number,
          0,
          undefined,
          {
            paymentRequestId: requestId,
            paymentStatus: OrderPaymentStatus.PENDING,
          },
          newEventId,
        );

      paymentDetailsRef.current = {
        ...(paymentDetailsRef.current as TempPaymentInfo),
        requestId,
      };
    }
  }, [
    ifNoNetwork,
    pendingPayment,
    onPaymentInitiate,
    initiatePayment,
    session.device?.deviceCode,
    session.device?.paymentTerminal?.uuid,
    session.currentStore?.checkoutOptions?.enableTips,
    session.currentOrganization?.currencyCode,
    isCustomerBalancePayment,
    customerPaymentTransactionId,
    order?.orderNumber,
    order?.id,
    order?.integrationInfo,
    routeParams.customerId,
    routeParams?.orderId,
    onPressPay,
    showNotification,
    translate,
    isPaymentLoading,
    verifyOolioPayment,
  ]);

  const cancelOolioPayment = useCallback(() => {
    if (!ifNoNetwork()) {
      /**
       * Cancel payment is acknowledgement only.
       * The actual cancellation is verified by original request or
       * verify status.
       */
      cancelPayment({
        deviceId: session.device?.deviceCode as string,
        terminalId: session.device?.paymentTerminal?.uuid as string,
        requestId: paymentDetailsRef.current?.requestId as string,
      });

      openOrderCart(order?.id as string);
    }

    /**
     * In offline scenarios, original request stays loading.
     * With this flag we are unblocking user to close the order with payment.
     * The current one still stays in pending.
     */
    setTimeout(() => {
      paymentDetailsRef.current = {
        ...(paymentDetailsRef.current as TempPaymentInfo),
        cancellationRequested: true,
      };

      onPaymentCancel && onPaymentCancel();
    }, 4000);
  }, [
    ifNoNetwork,
    cancelPayment,
    session.device?.deviceCode,
    session.device?.paymentTerminal?.uuid,
    openOrderCart,
    order?.id,
    onPaymentCancel,
  ]);

  const addSurchargeToAmount = useCallback(
    (paymentType, surcharge) => {
      if (order && surcharge?.amount) {
        updateCart<UpdateOrderAdjustmentsEvent>(
          OrderAction.ORDER_UPDATE_ADJUSTMENTS,
          {
            adjustments: [...(order.adjustments ?? []), surcharge],
          },
        );

        const surchargeAmount = getAdjustmentValue(
          processingAmount,
          [surcharge],
          surcharge.adjustmentType,
        );

        paymentDetailsRef.current = {
          paymentType,
          orderAmount: processingAmount + surchargeAmount,
          surchargeAmount: surchargeAmount,
          orderId: order?.id,
        };

        initiateOolioPayment();
      } else {
        paymentDetailsRef.current = {
          paymentType,
          orderAmount: processingAmount,
          orderId: order?.id,
        };

        initiateOolioPayment();
      }
    },
    [order, updateCart, processingAmount, initiateOolioPayment],
  );

  const handleOnPressOolioPay = useCallback(() => {
    // if there are any payments and the amount due is 0 then we can not pay , we can complete order
    if (order && order?.payments?.length && processingAmount === 0) {
      showNotification({
        message: translate('payment.payZeroError'),
        error: true,
      });
      return;
    }
    if (session.device?.paymentTerminal) {
      const paymentType = getPaymentTypeByName(DefaultPaymentTypes.CARD);
      const paymentTypeInput = stripProperties(
        { ...paymentType },
        'label',
        'value',
      );

      if (paymentType.adjustmentPrompt) {
        showModal(
          <PaymentSurcharge
            orderTotal={processingAmount}
            onSubmit={addSurchargeToAmount}
            paymentType={paymentTypeInput}
            checkAllowAdjustmentPermission={checkAllowAdjustmentPermission}
          />,
        );
      } else {
        paymentDetailsRef.current = {
          paymentType: paymentTypeInput,
          orderAmount: processingAmount,
          orderId: order?.id,
        };

        initiateOolioPayment();
      }
    } else {
      showNotification({
        message: translate('payment.paymentTerminalNotLinked'),
        error: true,
      });
    }
  }, [
    addSurchargeToAmount,
    checkAllowAdjustmentPermission,
    getPaymentTypeByName,
    initiateOolioPayment,
    order,
    processingAmount,
    session.device?.paymentTerminal,
    showModal,
    showNotification,
    translate,
  ]);

  const onPressOtherPaymentType = useCallback(
    (paymentTypeName: string) => {
      switch (paymentTypeName) {
        case OrderAction.ON_ACCOUNT_PAYMENT_TYPE:
          handleOnPressOnAccount();
          break;

        case DefaultPaymentTypes.CARD:
          handleOnPressOolioPay();
          break;

        default:
          onPressPaymentOption(
            paymentTypeName,
            processingAmount || (customerAmount as number),
          );
          break;
      }
    },
    [
      customerAmount,
      handleOnPressOnAccount,
      handleOnPressOolioPay,
      onPressPaymentOption,
      processingAmount,
    ],
  );

  useEffect(() => {
    if (isSplitPayment && numberOfSplitPayment !== Infinity) {
      if (nthSplitPayment < numberOfSplitPayment) {
        dispatch({
          type: PaymentAction.UPDATE_PAYMENT_AMOUNT,
          payload: {
            processingAmount: round(
              remainingDueAmount / (numberOfSplitPayment + 1 - nthSplitPayment),
              2,
            ),
          },
        });
      } else {
        dispatch({
          type: PaymentAction.UPDATE_PAYMENT_AMOUNT,
          payload: {
            processingAmount: remainingDueAmount,
          },
        });
      }
    }
  }, [
    isSplitPayment,
    numberOfSplitPayment,
    nthSplitPayment,
    dispatch,
    remainingDueAmount,
  ]);

  const lastSplitPaymentBy = useMemo(() => {
    return findLast(
      order?.payments || [],
      p =>
        p.paymentType.name === OrderAction.ON_ACCOUNT_PAYMENT_TYPE ||
        p.status === OrderPaymentStatus.COMPLETE,
    )?.splitPaymentBy;
  }, [order?.payments]);

  const onClosePayBySeatOption = useCallback(() => {
    dispatch({ type: PaymentAction.CANCEL_PAY_BY_SEAT, payload: {} });
  }, [dispatch]);

  const payInFullOption = useMemo(
    () => (
      <PaymentButtons
        processingAmount={processingAmount}
        enableRoundOff={enableRoundOff}
        roundOffValue={roundOffValue}
        otherPaymentTypes={otherPaymentTypes}
        onPressPayOut={onPressPaymentOption}
        onPressOtherPayOut={onPressOtherPaymentType}
      />
    ),
    [
      enableRoundOff,
      onPressOtherPaymentType,
      onPressPaymentOption,
      otherPaymentTypes,
      processingAmount,
      roundOffValue,
    ],
  );

  const splitByPaymentsOption = useMemo(() => {
    // Removing payment type surcharges from surcharge amount.
    // In order helper for adjustments we only maintain one payment surcharge (which was last)
    // Because of that reason we are picking from "adjustments" rather than picking from "payments".
    const paymentSurcharge =
      (order?.adjustments || []).reduce((acc, adjustment) => {
        if (adjustment?.allowOnPaymentType) acc = acc + adjustment?.amount;
        return acc;
      }, 0) || 0;

    const surchargeAmount = (order?.surchargeAmount || 0) - paymentSurcharge;

    if (isSplitBySeat)
      return (
        <SplitBySeatOptions
          orderItems={order?.orderItems || []}
          dispatch={dispatch}
          paymentState={orderPaymentDetails}
          subTotal={order?.subTotal || 0}
          discountAmount={order?.discountAmount || 0}
          surchargeAmount={surchargeAmount}
        />
      );

    return (
      <SplitPaymentOptions
        dispatch={dispatch}
        orderItems={order?.orderItems || []}
        orderPaymentDetails={orderPaymentDetails}
        orderTypeId={order?.orderType?.id}
        onUpdateProcessingAmount={onUpdateProcessingAmount}
        lastSplitPaymentBy={lastSplitPaymentBy}
      />
    );
  }, [
    order?.adjustments,
    order?.surchargeAmount,
    order?.orderItems,
    order?.subTotal,
    order?.discountAmount,
    order?.orderType?.id,
    isSplitBySeat,
    dispatch,
    orderPaymentDetails,
    onUpdateProcessingAmount,
    lastSplitPaymentBy,
  ]);

  if (
    !paymentDetailsRef.current?.cancellationRequested &&
    (isPaymentLoading || pendingPayment)
  ) {
    return (
      <View style={styles.container}>
        <PaymentHeader
          title={
            activeScreen === PaymentSubScreen.MAIN_SCREEN
              ? translate('payment.paymentAmount')
              : translate('payment.processingCardPayment')
          }
        />
        <View style={styles.content}>
          <PaymentAmount
            value={paymentDetailsRef.current?.orderAmount as number}
          />
          <View style={styles.cardProcessState}>
            <ActivityIndicator size="large" />
            <Text style={styles.statusText}>
              {translate('payment.processingPayment')}
            </Text>
          </View>
          {/* eslint-disable-next-line react-native/no-inline-styles */}
          <View style={[styles.actionRow, { marginBottom: 0 }]}>
            <TreatButton
              testID="btn-cancelPayment"
              height={60}
              type="negativeLight"
              label={translate('payment.cancelOolioPay')}
              onPress={cancelOolioPayment}
              disabled={cancellationLoading}
              // eslint-disable-next-line react-native/no-inline-styles
              containerStyle={{ flex: 1, marginRight: 5 }}
            />
            <TreatButton
              testID="btn-verifyPayment"
              height={60}
              type="neutralLight"
              label={translate('payment.verifyOolioPay')}
              onPress={verifyOolioPayment}
              // eslint-disable-next-line react-native/no-inline-styles
              containerStyle={{ flex: 1, marginLeft: 5 }}
            />
          </View>
        </View>
      </View>
    );
  }

  if (activeScreen === PaymentSubScreen.PAYOUT_SCREEN)
    return (
      <SplitPayment
        enableRoundOff={enableRoundOff}
        roundOffValue={roundOffValue}
        onPressPayOut={onPressPaymentOption}
        orderPaymentDetails={orderPaymentDetails}
        dispatch={dispatch}
        onPressOtherPayOut={onPressOtherPaymentType}
        onUpdateProcessingAmount={onUpdateProcessingAmount}
      />
    );

  return (
    <View style={styles.container}>
      <PaymentHeader
        title={
          isSplitBySeat
            ? translate('payment.splitBySeat')
            : translate('payment.paymentAmount')
        }
        onClose={isSplitBySeat ? onClosePayBySeatOption : undefined}
      />
      <View style={styles.content}>
        <PaymentAmount value={processingAmount} />
        <SegmentControl
          secondary
          testID="segments-options"
          selectedTab={paymentMode}
          onPress={onChangeOption}
          tabs={[
            {
              label: translate('payment.payInFull'),
              value: PaymentMode.PAY_IN_FULL,
            },
            {
              label: translate('payment.splitPayment'),
              value: PaymentMode.SPLIT_PAYMENT,
            },
          ]}
          containerStyle={styles.marginTop10}
        />
        {paymentMode === PaymentMode.PAY_IN_FULL
          ? payInFullOption
          : splitByPaymentsOption}
      </View>
    </View>
  );
};
export default PaymentOptions;
