import React from 'react';
import { View, Text, TouchableOpacity, ViewStyle } from 'react-native';
import styles from './SegmentControl.styles';
import theme from '../../../common/default-theme';

export interface SegmentTab {
  label: string;
  value: string;
  badge?: boolean;
}

export interface SegmentControlProps {
  testID?: string;
  selectedTab: string;
  secondary?: boolean;
  tabs: Array<SegmentTab>;
  containerStyle?: ViewStyle;
  onPress: (value: string) => void;
}

const SegmentControl: React.FC<SegmentControlProps> = ({
  selectedTab,
  secondary,
  tabs,
  testID,
  containerStyle,
  onPress,
}) => {
  return (
    <View
      testID={testID}
      style={[
        styles.container,
        containerStyle,
        secondary && { backgroundColor: theme.colors.grey2 },
      ]}
    >
      {tabs.map((tab: SegmentTab, i: number) => {
        const isSelected = tab.value === selectedTab;
        return (
          <TouchableOpacity
            key={i}
            onPress={() => onPress(tab.value)}
            style={[styles.tab, isSelected && styles.activeTab]}
          >
            {tab.badge && <View style={styles.badge} />}
            <Text
              style={[
                styles.text,
                {
                  color: isSelected ? theme.colors.dark : theme.colors.grey5,
                },
              ]}
            >
              {tab.label}
            </Text>
          </TouchableOpacity>
        );
      })}
    </View>
  );
};

export default SegmentControl;
