/* eslint-disable react-native/no-inline-styles */
import React, { useCallback, useEffect, useState } from 'react';
import {
  App,
  AppAccess,
  PermittedLocation,
  UserRoleDef,
} from '@oolio-group/domain';
import { useNavigation } from '@react-navigation/native';
import { useTranslation } from '@oolio-group/localization';
import { useModal } from '@oolio-group/rn-use-modal';
import { useUserRoles } from '../../../../../../../hooks/app/users/useUserRoles';
import { RouteProp, useIsFocused, useRoute } from '@react-navigation/native';
import { useVenues } from '../../../../../../../hooks/app/useVenues';
import { useNotification } from '../../../../../../../hooks/Notification';
import useRolesContext from '../../../../../../../hooks/app/users/useRolesContext';
import { jobRoleSettingsUtility } from '../../../../../../../state/jobRoleSettingsUtility';
import SearchPermissionInput from '../../../../ManageRoles/Components/SearchPermissionInput';
import PermissionSection from '../../../../ManageRoles/Components/PermissionSection';
import OfficePermissions from '../../../../ManageRoles/Components/OfficePermissions';
import POSAppPermissions from '../../../../ManageRoles/Components/POSAppPermissions';
import {
  computeAppAccess,
  getUpdateUserRoleInput,
  groupPermissionsByIdAndOperations,
} from '../userRoleInputUtility';
import ConfirmationDialog from '../../../../../../../components/Modals/ConfirmationDialog';
import Section from '../../../../../../../components/Office/Section/Section';
import TreatPicker from '../../../../../../../components/Shared/Select/Picker';
import ScreenLayout from '../../../../../../../components/Office/ScreenLayout/ScreenLayout';
import SelectLocation, {
  OptionsSectionProps,
  SelectLocationOptionsProps,
} from '../../../../../../../components/Shared/Select/SelectLocation';

type CustomizeUserRolesParamList = {
  PermissionOverride: { userRoleId: string; userId: string };
};

export type CustomizeUserRolesRouteProp = RouteProp<
  CustomizeUserRolesParamList,
  'PermissionOverride'
>;

export const CustomizeUserRole: React.FC = () => {
  const navigation = useNavigation();
  const { translate } = useTranslation();
  const { showModal, closeModal } = useModal();
  const { showNotification } = useNotification();
  const { rolesById, permissions } = useRolesContext();
  const { deleteUserRoles } = useUserRoles();
  const { params } = useRoute<CustomizeUserRolesRouteProp>();
  const {
    venues,
    loading: venuesLoading,
    error: venuesError,
    getVenues,
  } = useVenues();
  const [roleOptions, setRoleOptions] = useState<
    Array<{ label: string; value: string }>
  >([]);
  const [selectedRole, setSelectedRole] = useState<string>();
  const [selectedLocations, setSelectedLocations] = useState<
    PermittedLocation[]
  >([]);
  const [venuesOptionsList, setVenuesOptionsList] = useState<
    SelectLocationOptionsProps[]
  >([]);
  const [userRole, setUserRole] = useState<UserRoleDef>();
  const isFocused = useIsFocused();

  const onCreateOrUpdateComplete = useCallback(() => {
    showNotification({
      success: true,
      message: translate('backOfficeUsers.userRolesCreatedUpdatedSuccessfully'),
    });
  }, [showNotification, translate]);

  const {
    userRoles: userRolesById,
    fetchUserRole,
    batchCreateOrUpdateUserRoles,
    loading,
    error,
  } = useUserRoles({ userId: params.userId, onCreateOrUpdateComplete });

  const updateRolePermissions = useCallback(
    (roleId: string) => {
      const role = rolesById[roleId];

      jobRoleSettingsUtility.selectedPermissions = {
        ...groupPermissionsByIdAndOperations(role.permissions),
        ...groupPermissionsByIdAndOperations(
          userRole?.overridePermissions || [],
        ),
      };
      const userRoleApps = userRole?.apps as AppAccess;
      const roleApps = role.apps as AppAccess;

      jobRoleSettingsUtility.appAccessSettings = {
        [App.BACKOFFICE]: computeAppAccess(
          userRoleApps,
          App.BACKOFFICE,
          roleApps,
        ),
        [App.POS_APP]: computeAppAccess(userRoleApps, App.POS_APP, roleApps),
      };
      jobRoleSettingsUtility.isEditable = true;
      setSelectedRole(roleId);
    },
    [rolesById, userRole?.apps, userRole?.overridePermissions],
  );

  const onSubmit = useCallback(() => {
    const payload = getUpdateUserRoleInput(
      jobRoleSettingsUtility.selectedPermissions,
      rolesById,
      jobRoleSettingsUtility.appAccessSettings,
      selectedLocations,
      selectedRole as string,
      userRole as UserRoleDef,
    );

    batchCreateOrUpdateUserRoles([payload]);
  }, [
    selectedLocations,
    userRole,
    batchCreateOrUpdateUserRoles,
    selectedRole,
    rolesById,
  ]);

  const onChangeRole = useCallback(
    (roleId: string) => {
      updateRolePermissions(roleId);
    },
    [updateRolePermissions],
  );

  const onChangeLocation = useCallback((sections: OptionsSectionProps[]) => {
    const selectedLocations = sections.reduce<PermittedLocation[]>(
      (acc, venue) => {
        const locations = venue.data
          .filter(store => store.checked === true)
          .map(store => {
            return [
              {
                venueId: venue.id,
                storeId: store.id,
              },
            ];
          })
          .flat();
        return [...acc, ...locations];
      },
      [],
    );
    setSelectedLocations(selectedLocations);
  }, []);

  useEffect(() => {
    if (userRole && venues) {
      setVenuesOptionsList(
        Object.values(venues).map(venue => {
          const checkedStores = userRole.locations.map(
            location => location.store.id,
          );
          const hasAccessToAllStores = venue.stores.every(store =>
            checkedStores.includes(store.id),
          );
          return {
            id: venue.id,
            label: venue.name,
            checked: hasAccessToAllStores,
            data: venue.stores.map(store => ({
              id: store.id,
              label: store.name,
              checked: checkedStores.includes(store.id),
            })),
          };
        }),
      );
      setSelectedLocations(
        userRole.locations.map(location => ({
          storeId: location.store.id,
          venueId: location.venue.id,
        })),
      );
    }
  }, [venues, userRole]);

  useEffect(() => {
    setRoleOptions(
      Object.values(rolesById).map(role => ({
        value: role.id,
        label: role.name,
      })),
    );
  }, [rolesById]);

  useEffect(() => {
    if (isFocused) {
      getVenues();
    }
  }, [getVenues, isFocused]);

  useEffect(() => {
    if (isFocused && params?.userRoleId) {
      fetchUserRole(params.userRoleId);
    }
  }, [fetchUserRole, params?.userRoleId, isFocused]);

  useEffect(() => {
    if (error || venuesError) {
      showNotification({
        error: true,
        message: (error || venuesError) as string,
      });
    }
  }, [error, venuesError, showNotification]);

  useEffect(() => {
    jobRoleSettingsUtility.permissions = Object.values(permissions);
  }, [permissions]);

  useEffect(() => {
    if (userRolesById && params.userRoleId) {
      setUserRole(userRolesById[params.userRoleId]);
    }
  }, [params.userRoleId, userRolesById]);

  useEffect(() => {
    if (rolesById && userRole) {
      const role = rolesById[userRole.role.id];
      updateRolePermissions(role.id);
    }
  }, [userRole, rolesById, updateRolePermissions]);

  useEffect(() => {
    return () => {
      jobRoleSettingsUtility.reset();
    };
  }, []);

  const onDeleteComplete = useCallback(() => {
    showNotification({
      success: true,
      message: translate('backOfficeUsers.userRoleDeletedUpdatedSuccessfully'),
    });
    navigation.navigate('Permissions', {
      userId: userRole?.user?.id,
    });
  }, [showNotification, translate, navigation, userRole?.user?.id]);

  const onDeleteConfirm = useCallback(() => {
    closeModal();
    if (userRole) {
      deleteUserRoles([userRole.id]);
      onDeleteComplete();
    }
  }, [closeModal, deleteUserRoles, onDeleteComplete, userRole]);

  const onPressDelete = useCallback((): void => {
    showModal(
      <ConfirmationDialog
        title={translate('dialog.deleteTitle')}
        message={translate('backofficeManageRoles.deleteRoleMessage')}
        onConfirm={onDeleteConfirm}
      />,
    );
  }, [translate, showModal, onDeleteConfirm]);

  return (
    <ScreenLayout
      loading={loading || venuesLoading}
      title={translate('navigation.jobRoleSettingsPageTitle', {
        appName: translate('appName'),
      })}
      onSave={onSubmit}
      onDelete={onPressDelete}
      onDeleteDisabled={userRole === undefined}
    >
      <Section layoutWidth="small" title="Details">
        <TreatPicker
          testID="roleNameDropdown"
          options={roleOptions}
          selectedValue={selectedRole}
          onValueChange={onChangeRole}
          title="Job Role"
          containerStyle={{
            width: 260,
            height: 62,
            marginBottom: 20,
          }}
        />
        <SelectLocation
          title="Locations"
          sectionHeaderLabelSuffix={`(${translate(
            'backOfficeUsers.allStores',
          )})`}
          options={venuesOptionsList}
          onValueChange={onChangeLocation}
          testID="locationNameDropdown"
          placeholder={'Select location...'}
          containerStyle={{ marginBottom: 20 }}
        />
      </Section>
      <Section
        layoutWidth="small"
        title={translate('backOfficeUsers.manageRolesAndPermissions')}
      >
        <SearchPermissionInput />
        <PermissionSection app={App.BACKOFFICE}>
          <OfficePermissions />
        </PermissionSection>
        <PermissionSection app={App.POS_APP}>
          <POSAppPermissions />
        </PermissionSection>
      </Section>
    </ScreenLayout>
  );
};
