import { Order } from '@oolio-group/domain';
import { isOnlineOrder } from './generateOnlineOrderDetail';

export const getOrderChannel = (order: Order): {} => {
  if (!isOnlineOrder(order)) {
    // if non online order we do not show channel as it is always IN STORE
    return '';
  }

  return `${order?.salesChannel?.name || ''} (${
    order.integrationInfo?.channelOrderId || order.orderNumber
  })`;
};
