import { StyleSheet } from 'react-native';
import theme from '../../../../common/default-theme';

const styles = StyleSheet.create({
  filters: {
    padding: 20,
    flexDirection: 'row',
  },
  search: {
    flex: 1,
  },
  dropdown: {
    width: 180,
    marginRight: 10,
  },
  noteContainer: {
    width: 380,
    padding: 20,
    alignSelf: 'center',
    borderRadius: theme.radius.s,
    backgroundColor: theme.colors.white,
  },
  note: {
    marginTop: 20,
    marginBottom: 40,
    alignItems: 'center',
    fontFamily: theme.fonts.medium,
  },
  noteTitle: {
    fontSize: 16,
    lineHeight: 24,
    marginBottom: 20,
    fontFamily: theme.fonts.medium,
  },
  table: {
    marginBottom: 100,
    paddingHorizontal: 20,
  },
  tableHeader: {
    height: 38,
    flexDirection: 'row',
    alignItems: 'center',
    borderRadius: theme.radius.s,
    backgroundColor: theme.colors.white,
  },
  tableBody: {
    marginVertical: 8,
  },
  tableRow: {
    height: 50,
    marginBottom: 4,
    flexDirection: 'row',
    alignItems: 'center',
    borderRadius: theme.radius.s,
    backgroundColor: theme.colors.white,
  },
  headerAmount: {
    width: 70,
    textAlign: 'right',
    marginRight: 186,
  },
  cellType: {
    width: 70,
    marginLeft: 12,
    marginRight: 8,
    textAlign: 'center',
  },
  cellAge: {
    width: 60,
    marginRight: 16,
    textAlign: 'center',
  },
  cellOrder: {
    width: 110,
    marginRight: 16,
  },
  cellDate: {
    width: 110,
    marginRight: 16,
  },
  cellName: {
    width: 120,
    marginRight: 16,
  },
  cellTime: {
    width: 80,
    marginRight: 16,
    textAlign: 'right',
  },
  cellNote: {
    flex: 1,
    marginRight: 16,
  },
  cellAmount: {
    width: 70,
    marginRight: 16,
    textAlign: 'right',
  },
  cellNoteBtn: {
    marginRight: 8,
  },
  cellVoid: {
    marginLeft: 8,
  },
});

export default styles;
