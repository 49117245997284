import React from 'react';
import { General } from './Details/UserDetails';
import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs';
import { useRoute } from '@react-navigation/native';
import { MaterialTopTabBarProps } from '@react-navigation/material-top-tabs/src/types';
import TabBar from '../../../../../components/TabBar/TabBar';
import { UserPermissions } from './Permissions/UserPermissions';

const Tab = createMaterialTopTabNavigator();

const UserSettingsTab: React.FC = () => {
  const route = useRoute();
  const params = route.params as {
    userId: string;
  };

  return (
    <Tab.Navigator
      tabBar={(props): React.ReactNode => (
        <TabBar
          tabBar={props as MaterialTopTabBarProps}
          previousRoute={'ManageUsersTab'}
        />
      )}
      initialRouteName="General"
    >
      <Tab.Screen
        name="General"
        component={General}
        options={{ tabBarLabel: 'User Details' }}
        initialParams={params}
      />
      <Tab.Screen
        name="Permissions"
        component={UserPermissions}
        initialParams={params}
      />
    </Tab.Navigator>
  );
};

export default UserSettingsTab;
